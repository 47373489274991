import { useState } from "react";

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("userToken");
    return tokenString;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem(
      "userToken", JSON.stringify(userToken)
    );
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
