import React, { useState, useEffect, useRef } from "react";
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Slider } from 'primereact/slider';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import StatsTabPanel from './StatsTabPanel'
// import TabbedSection from './TabbedSection'
// import CustomTabs from './CustomTabs'
// import DynamicTab from './DynamicTab'
// import ReactApp from './StaticTab'
import { TabComponent,  } from '@syncfusion/ej2-react-navigations';
import axios from "axios";
import qs from 'qs';
import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
import './indexClone.css'
import './DataTableDemo.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { dummyCampaigns } from "./dummyCampaigns";
import {
  filterDate, progressConverter, timeToStr, campaignDeletedStateTemplate, campaignStateTemplate, campaignStates, campaignDeletedStates,
  selectedFreqTemplate, selectedSignupTemplate, freqOptionTemplate, signupOptionTemplate, langOptionTemplate, selectedProgTemplate, progOptionTemplate,
  selectedOrgTemplate, orgOptionTemplate, languageIdentifier, isFormFilled, booleanChecker, dateConverter, campaignIdGen, daysConverter, findIndexById, onInputChange, onMsgChange,
  programIdsToStr, programNamesParser
} from "./tableFunctions/utilityFunctions"
import * as expandedRow from './ExpandedRow';
import { emptyCampaign, columns, msgFrequencyDays, msgDaysBefore, msgSignup, msgLangIds, templateMsgDetailOptions } from "./campaignObjects"
//import { FALSE } from "node-sass";
const config = require("../config.json");




const TableComponent = ({ campaignData }) => {

  const [campaigns, setCampaigns] = useState([]);
  const [campaignsResponseRecieved, setCampaignsResponseRecieved] = useState(false);
  const [campaign, setCampaign] = useState(emptyCampaign);
  const [expandedRows, setExpandedRows] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [campaignDialog, setCampaignDialog] = useState(false);
  const [deleteCampaignDialog, setDeleteCampaignDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState(null);
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isEditMode, toggleEditMode] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [selectedCampaignState, setSelectedCampaignState] = useState(null);
  const [showDeletedCampaigns, setShowDeletedCampaigns] = useState(false);
  const [deleteCampaignsDialog, setDeleteCampaignsDialog] = useState(false);
  const [nothingMsg, setNothingMsg] = useState("Nothing here... yet.");
  const [isInvalidRequest, setIsInvalidRequest] = useState(false);
  const [selectedDeleteState, setSelectedDeleteState] = useState(null);
  const [orgsReceived, setOrgsReceived] = useState(false);
  const [orgsList, setOrgsList] = useState([]);
  const [orgChosen, setOrgChosen] = useState(false);
  const [chosenOrg, setChosenOrg] = useState(null);
  const [courseList, setCourseList] = useState([])
  const [progressRange, setProgressRange] = useState([0, 100]);
  const [estUsers, setEstUsers] = useState("Try setting a range");
  const [isRetrievingUserCount, setIsRetrievingUserCount] = useState(false);
  const [orgCleared, setOrgCleared] = useState(false);
  const [activeElement, setActiveElement] = useState(null);
  //const [selectedLangTab, setSelectedLangTab] = useState(null);
  const dt = useRef(null);
  const toast = useRef(null);
  const isMounted = useRef(false);


  // simulates component.onMount
  useEffect(() => {
    isMounted.current = true;
    // setCampaigns(campaignData);
    fetchCampaigns();
    // fetchOrgs();
  }, [])

  // triggered when ccertain org is chosen. 
  useEffect(() => {
    fetchOrgs("org_courses");
  }, [chosenOrg]);

  useEffect(() => {

  }, [campaign])

  // textarea manual focus
  useEffect(() => {
    console.log(campaign);

    
    // var tabObj = document.getElementById("languageTabs")
    // if (tabObj) {
    //   var selectedIndex = tabObj.ej2_instances[0].selectedItem;
    //   var selectedLangTab = msgLangIds[selectedIndex].value;

    //   var activeTextArea = document.getElementById("Area" + selectedLangTab);
    //   console.log("Active: " + document.ac)
    //   activeTextArea.focus();
    //   activeTextArea.setSelectionRange(1000,1000);
    // }

    if (activeElement) {
      console.log("Active element " + activeElement.id)
      var activeTextArea = document.getElementById(activeElement.id)
      activeTextArea.focus();
      activeTextArea.setSelectionRange(1000, 1000);
    }

    // checkOrgCleared();

  }, [campaign])

  useEffect(() => {
    console.log(orgCleared);
    // if(orgCleared){

    // }

  }, [orgCleared]);

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
    setSelectedColumns(orderedSelectedColumns);
  }


  const hideDialog = () => {
    setActiveElement(null);
    setSubmitted(false);
    setCampaignDialog(false);
    setCampaign(emptyCampaign)
    toggleEditMode(false);
    setOrgChosen(false);
    setProgressRange([0, 100]);
    console.log("Reverted to non edit mode");
  }

  // holds the count of deleted campaigns
  let deletedCampaignCount = 0;

  const filterDeletedCampaigns = item => {
    if (!Number.isFinite(Date.parse(item.Deleted))) {
      // console.log(item.Deleted);
      return true
    }
    else {
      deletedCampaignCount++;
      return showDeletedCampaigns;
    }
  }


  // fetches orgs/courses and stores them in state variables
  const fetchOrgs = retrieveOrgsAndCourses(chosenOrg, setOrgsReceived, setOrgsList, setCourseList)
  // retrieves the campaigns. API request to a aws lambda function that gets campaigns from the mysql database
  const fetchCampaigns = retrieveCampaignsRequest(setCampaignsResponseRecieved, setCampaigns, setNothingMsg, setErrorMsg, setErrorOccured);
  // resets some state variables to prep for creating new campaign
  const openNew = () => {
    setCampaign({ ...emptyCampaign, CampaignID: campaignIdGen(campaigns) });
    setSubmitted(false);
    setCampaignDialog(true);
    toggleEditMode(false);
    setProgressRange([0, 100]);
  }
  // function that will send request to delete multiple selected campaigns
  const deleteSelectedCampaigns = deleteMultipleCampaignsRequest(campaigns, selectedCampaigns, setErrorOccured, errorOccured, setErrorMsg, setDeleteCampaignsDialog, setSelectedCampaigns, setCampaigns, setCampaign, setIsInvalidRequest)
  // function that is triggered when delete button is campaign row is pressed. preps the rows information so its ready for deletion
  const confirmDeleteCampaign = deleteCampaignActionHandler(setCampaign, setDeleteCampaignDialog);
  // function that will send request to delete campaign
  const deleteCampaign = deleteCampaignRequest(campaign, setIsInvalidRequest, setCampaignDialog, setCampaign, setErrorMsg, setErrorOccured, campaigns, setCampaigns, toast, setDeleteCampaignDialog)
  // function that is triggered when the edit button in a campaign row is pressed. preps that rows information so that it can be displayed in the campaign editing form
  const editCampaign = updateCampaignActionHandler(setChosenOrg, setCampaign, setProgressRange, setCampaignDialog, toggleEditMode)
  // function to publish the campaign to dynamodb ( add or update request )
  const saveCampaign = publishCampaignRequest(setSubmitted, campaign, isEditMode, setIsInvalidRequest, setCampaignDialog, setCampaign, setErrorMsg, setErrorOccured, errorOccured, setCampaigns, campaigns, toast, setOrgChosen, setProgressRange)

  // handler for when an org option is selected in the org dropdown
  const onOrgChange = orgDropdownOptionHandler(setOrgChosen, chosenOrg, setChosenOrg, campaign, setCampaign)
  // handler for when a prog option is selected in the program dropdown
  const onProgChange = progDropdownOptionHandler(campaign, setCampaign)
  // handler for when a language option is selected in the lang dropdown
  const onLangChange = langDropdownOptionHandler(campaign, setCampaign)
  // handler for when a freq option is selected in the freq dropdown
  const onFreqChange = freqDropdownOptionHandler(campaign, setCampaign)
  // handler for when a signup option is selected in the signup dropdown
  const onSignupChange = signupDropdownOptionHandler(campaign, setCampaign)
  // handler for when active switch is toggled
  const onActiveChange = activeToggleHandler(campaign, setCampaign)
  // handler for when a day checkbox is checked
  const onDayChange = dayCheckboxToggleHandler(campaign, setCampaign)
  // handler for when a preset message option is clicked
  const onDetailSelect = MultiMsgOptionClickHandler(campaign, setCampaign)
  // function to determine how selected lang is shown by the dropbox
  const selectedLangTemplate = generatedDropdownSelectedLangTemplate()
  // handler for when progress slider is changed
  const handleProgressChange = progressSliderChangeHandler(campaign, setProgressRange, progressRange, setCampaign)
  // function that gets the number of providers in a given org/course/language combination
  const getProviderCount = () => {

    setIsRetrievingUserCount(true);

    let getProviderCountMode = {
      "function": "count_providers",
      "organization_id": campaign.Org.OrgID,
      "course_id": campaign.Program.ProgramID,
      "progress_min": campaign.MinProgress,
      "progress_max": campaign.MaxProgress,
      "lang_id": campaign.MultiLangId,
    }

    if (campaign.Programs) {
      getProviderCountMode["programs"] = campaign.Programs
    }


    let getProvidersCountModeStr = JSON.stringify(getProviderCountMode)


    var axiosConfig = {
      method: 'post',
      url: 'https://hp349afqk1.execute-api.us-east-2.amazonaws.com/prod/orgs',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': config.api.apiKey,
      },
      data: getProvidersCountModeStr
    };

    axios(axiosConfig)
      .then(response => {
        // console.log(response);
        let responseCount = (response).data[0]["COUNT(*)"];
        setEstUsers(`${responseCount} users`);
        setIsRetrievingUserCount(false);
      })
      .catch(function (error) {
        console.log(error);
        setEstUsers(`Couldn't retrieve data`);
        setIsRetrievingUserCount(false);
      });
  }

  const onRowExpand = (event) => {
    // toast.current.show({severity: 'info', summary: 'Campaign Expanded', detail: event.data.CampaignName, life: 3000});
  };

  const onRowCollapse = (event) => {
    // toast.current.show({severity: 'success', summary: 'Campaign Collapsed', detail: event.data.CampaignName, life: 3000});
  };

  // renders the expanded row section for each campaign row
  const rowExpansionTemplate = expandedRow.renderRowExpanded(findIndexById, campaigns, StatsTabPanel)

  const panelFooterTemplate = () => {
    const selectedCourses = campaign.Programs;
    const length = selectedCourses ? selectedCourses.length : 0;
    return (
      <div className="p-py-2 p-px-3">
        <b>{length}</b> courses{length > 1 ? 's' : ''} selected.
      </div>
    );
  }


  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-button-raised" onClick={openNew} />
        <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-button-raised" disabled={!selectedCampaigns || !selectedCampaigns.length || showDeletedCampaigns} onClick={() => { setDeleteCampaignsDialog(true); /*console.log(campaigns); console.log(selectedCampaigns);*/ }} />
        <Button icon="pi pi-refresh" className="p-ml-2 p-button-raised" tooltip="Refresh list of campaigns." disabled={!campaignsResponseRecieved} loading={!campaignsResponseRecieved} onClick={() => { fetchCampaigns(); }} />
      </>
    )
  }

  const onHiddenToggle = (e) => {
    setShowDeletedCampaigns(e.value)
  }

  const rightToolbarTemplate = () => {
    return (
      <>
        {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="p-mr-2 p-d-inline-block" /> */}
        <span className="p-mr-5 p-pr-2 p-mt-3 p-d-inline-block ">
          <InputSwitch
            disabled={!(campaigns.length > 0)}
            checked={showDeletedCampaigns}
            onChange={onHiddenToggle}
            tooltip="You can hide or show deleted campaigns"
            tooltipOptions={{ position: "left" }}
          />
          <p>Show deleted campaigns ({deletedCampaignCount})</p>
        </span>
        <Button label="Export" icon="pi pi-upload" className="p-button-help p-button-raised" disabled={!campaigns || !campaigns.length} onClick={exportCSV} />
      </>
    )
  }

  const exportCSV = () => {
    dt.current.exportCSV();
  }

  const actionBodyTemplate = rowData => {
    return (
      <>
        
        <Button  icon="pi pi-pencil" disabled={showDeletedCampaigns && deletedCampaignCount > 0 ? true : false} className="p-button-rounded btn-edit p-mr-2 p-button-outlined p-button-raised" onClick={() => editCampaign(rowData)} />
        <Button icon="pi pi-trash" disabled={showDeletedCampaigns && deletedCampaignCount > 0 ? true : false} className="p-button-rounded p-button-danger p-button-outlined p-button-raised"
          onClick={() => confirmDeleteCampaign(rowData)}
        />
      </>
    )
  }

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">Campaigns</h5>
      <MultiSelect value={selectedColumns} options={columns} optionLabel="header" onChange={onColumnToggle} style={{ width: '20em' }} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
      </span>
    </div>
  );

  const campaignDialogFooter = (
    <>
      {/* <Button label="Test" className="p-button-text" onClick={testCampaign} disabled={isFormFilled(campaign)} /> */}
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={(e) => {setActiveElement(null); saveCampaign(e)}} disabled={isFormFilled(campaign)}
      />
    </>
  );

  const onCampaignStateSelect = e => {
    dt.current.filter(e.value, 'Active', 'equals');
    // console.log(e);
    let selectedStateObj;
    switch (e.value) {
      case true:
        selectedStateObj = 'Yes'
        break;
      case false:
        selectedStateObj = 'No'
        break;
      case null:
        selectedStateObj = 'All'
    }
    setSelectedCampaignState(selectedStateObj);
  }

  const onDeleteStateSelect = e => {
    dt.current.filter(e.value, 'Deleted', 'custom');
    let selectedStateObj;
    switch (e.value) {
      case true:
        selectedStateObj = 'Yes'
        break;
      case false:
        selectedStateObj = 'No'
        break;
      case null:
        selectedStateObj = 'All'
    }
    setSelectedDeleteState(selectedStateObj);
  }

  const booleanFilter = (
    <Dropdown value={selectedCampaignState} options={campaignStates} itemTemplate={campaignStateTemplate} onChange={onCampaignStateSelect}
      placeholder={selectedCampaignState === null ? "Select a state" : selectedCampaignState}
      showClear
      // optionLabel="name" optionValue="value" placeholder="All" 
      className="p-column-filter"
    />
  )

  const deletedFilter = (
    <Dropdown value={selectedDeleteState} options={campaignDeletedStates} itemTemplate={campaignDeletedStateTemplate} onChange={onDeleteStateSelect}
      placeholder={selectedDeleteState === null ? "Select a delete state" : selectedDeleteState}
      showClear
      className="p-column-filter"
    />
  )

  // rendering the columns for the table
  const columnComponents = generateTableColumns(selectedColumns, booleanFilter, deletedFilter);

  const onHide = (name) => {
    setErrorOccured(false);
    setErrorMsg('');
    setIsInvalidRequest(false);
    console.log("Its false now");
  }

  const renderErrorDialogFooter = (name) => {
    return (
      <div>
        {/* <Button label="OK" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" /> */}
        <Button label="Ok." icon="pi pi-check" disabled={isInvalidRequest} onClick={() => onHide(name)} autoFocus />
      </div>
    );
  }

  const deleteCampaignDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => { setDeleteCampaignDialog(false); setCampaign(emptyCampaign) }} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCampaign} />
    </>
  )

  const deleteCampaignsDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteCampaignsDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCampaigns} />
    </>
  )

  // useEffect(() => {
  //   console.log()
  // })

  const onSlideEnd = value => {
    console.log(value);
  }



  const setCampaignTime = (time) => {
    console.log(JSON.stringify(time))
    let _campaign = { ...campaign }
    _campaign.Time = time
    setCampaign(_campaign)
  }

  class ReactApp extends React.Component {
    constructor(props) {
      // super(...arguments);
      super(props);
      // this.state = {MultiLangId: "266"}
    }
    
    render() {
      //for loop to get itemText from campaignObject.js
      var tabItems = [];
      var tabHeaders = [];
      //console.log("LANG ID: " + this.state.MultiLangId)
      // console.log("Organization: " + campaign.Org.OrgID)

      msgLangIds.forEach((lang, index, ) => {
	      console.log("Loading lang", lang, index);

      //  if (lang.value != "null") {
      //    if (campaign.MultiMsg[`${lang.value}`] == null)
         
      //      {campaign.MultiMsg[`${lang.value}`] = campaign.PresetMsg}
          //console.log("lang",lang)
          //console.log("MultiLang", campaign.MultiMsg)
          
          var textArea = 
          <InputTextarea
              id={"Area" + lang.value}
              key={lang.value}
              rows={8}
              cols={30}
              placeholder="Enter Your Text Message"
              autoResize
              value={campaign.MultiMsg[`${lang.value}`] ? campaign.MultiMsg[`${lang.value}`] : ""}  // check if its undefined. if it is, show "none"
              disabled= {!campaign.MultiLangId.includes(lang.value) }
              //take on the default value on language preference setting
              //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
              onChange={(e) => { setActiveElement(document.activeElement); setCampaign(onMsgChange(e, lang.value + "", campaign)); console.log("Active element " + document.activeElement.id); }}
              tooltip="Pick and choose desired details and adjust your message."
              tooltipOptions={{ position: "right" }}
              />

          var textSMS = 
            <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
              No. of SMS required (Estimated):{" "}
              {campaign.MultiMsg.length > 160
                ? Math.ceil(campaign.MultiMsg[`${lang.value}`] ? campaign.MultiMsg[`${lang.value}`].length / 160 : 0)
                : 1}
            </h6>

          var textCounters = 
            <h6 className="text-muted p-my-auto p-text-right">
              Total character count: {campaign.MultiMsg[`${lang.value}`] ? campaign.MultiMsg[`${lang.value}`].length : 0}
            </h6>
          
          tabItems.push(<div>{textArea}{textSMS}{textCounters}</div>)
          tabHeaders.push(<div>{lang.itemText}</div>)
        //}
         
      })

      //enablePersistence let the text input to be remembered after opening another tab
      return (<TabComponent id="languageTabs" heightAdjustMode='Auto' enablePersistence={true}>
        <div className="e-tab-header">
          {tabHeaders}
        </div>
        
        <div className="e-content">
          {tabItems}
          {/* <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                    No. of SMS required (Estimated):{" "}
                    {campaign.MultiMsg.length > 160
                      ? Math.ceil(campaign.MultiMsg.length / 160)
                      : 1}
                  </h6>
                  <h6 className="text-muted p-my-auto p-text-right">
                    Total character count: {campaign.MultiMsg.length}
                  </h6> */}
        </div>
      </TabComponent>);
    }
  }


  
  return (
    <div>
      {campaignsResponseRecieved ?
        <div className="datatable-crud-demo">
          <Toast ref={toast} position="top-left" />
          <div className="card">
            <Toolbar
              className="p-mb-4"
              left={leftToolbarTemplate}
              right={rightToolbarTemplate}
            ></Toolbar>
            <DataTable
              ref={dt}
              value={campaigns.length > 0 ? campaigns.filter(filterDeletedCampaigns) : []}
              selectionMode="checkbox"
              selection={selectedCampaigns}
              onSelectionChange={(e) => setSelectedCampaigns(e.value)}
              dataKey="CampaignID"
              removableSort
              sortField={"CampaignID"}
              stripedRows
              resizableColumns
              columnResizeMode="fit"
              rows={50}
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              onRowExpand={onRowExpand}
              onRowCollapse={onRowCollapse}
              rowExpansionTemplate={rowExpansionTemplate}
              rowsPerPageOptions={[5, 10, 25, 50]}
              paginator
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} campaigns"
              globalFilter={globalFilter}
              header={header}
            >
              {/* {dynamicColumns} */}
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              />
              <Column expander style={{ width: "3em" }} />
              {columnComponents}
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </div>

          {/* Campaign editor/creator form */}
          <Dialog
            visible={campaignDialog}
            style={{ width: "60%" }}
            header={
              isEditMode ? "Update campaign details" : "Enter campaign details"
            }
            modal
            className="p-fluid"
            footer={campaignDialogFooter}
            onHide={hideDialog}
          >
            <div>
              <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-lg-6">
                  <h5>Choose an organization</h5>
                  <Dropdown
                    value={campaign.Org}
                    options={!orgsReceived ? [
                      { id: "000", name: 'Loading...' }
                    ] : orgsList}
                    onChange={(e) => {setActiveElement(null); onOrgChange(e)}}
                    onFocus={orgsReceived ? () => console.log('Orgs already loaded') : () => fetchOrgs("orgs")}
                    optionLabel="name"
                    filter
                    showClear
                    filterBy="name"
                    placeholder="Select an agency"
                    valueTemplate={selectedOrgTemplate}
                    itemTemplate={orgOptionTemplate}
                    tooltip="You must choose an orgnaization"
                    tooltipOptions={{ position: "bottom" }}
                  />
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <h5>Choose courses</h5>
                  {/* onFocus={() => fetchOrgs("org_courses")} */}
                  {/* disabled={ isEditMode ? false : !orgChosen} */}
                  <MultiSelect value={campaign.Programs} disabled={isEditMode ? false : !orgChosen}
                    onFocus={() => fetchOrgs("org_courses")} options={courseList.length === 0 ? [{ id: "000", name: 'Loading...' }] : courseList}
                    onChange={(e) => {setActiveElement(null); onProgChange(e)}} optionLabel="name" placeholder="Select courses" className="multiselect-custom"
                    showClear itemTemplate={progOptionTemplate} selectedItemTemplate={selectedProgTemplate} panelFooterTemplate={panelFooterTemplate} />
                  {/* <Dropdown
                  value={campaign.Program}
                  options={courseList}
                  onChange={onProgChange}
                  optionLabel="name"
                  disabled={ isEditMode ? false : !orgChosen}
                  filter
                  showClear
                  filterBy="name"
                  placeholder="Select a program"
                  valueTemplate={selectedProgTemplate}
                  itemTemplate={progOptionTemplate}
                  
                /> */}
                </div>
              </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col-12 p-md-1">
                <label htmlFor="CampaignID">
                  <h5>ID</h5>
                </label>
                <InputText
                  id="CampaignID"
                  value={campaign.CampaignID}
                  onChange={(e) => {setActiveElement(document.activeElement); setCampaign(onInputChange(e, "CampaignID", campaign))}}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !campaign.CampaignID,
                  })}
                  // disabled={true}
                  tooltip="This is automatically generated for you, but you can modify it."
                  tooltipOptions={{ position: "right" }}
                />
                {submitted && !campaign.CampaignID && (
                  <small className="p-error">Campaign ID is required.</small>
                )}
              </div>
              <div className="p-field p-col-12 p-lg-11">
                <label htmlFor="CampaignName">
                  <h5>Campaign Name</h5>
                </label>
                <InputText
                  id="CampaignName"
                  value={campaign.CampaignName}
                  onChange={(e) => {setActiveElement(document.activeElement); setCampaign(onInputChange(e, "CampaignName", campaign))}}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !campaign.CampaignName,
                  })}
                  tooltip="The name of you campaign."
                  tooltipOptions={{ position: "right" }}
                />
                {submitted && !campaign.CampaignName && (
                  <small className="p-error">Campaign Name is required.</small>
                )}
              </div>
            </div>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col-12 p-lg-2">
                <h5>Enable Campaign?</h5>
                <InputSwitch
                  checked={campaign.Active}
                  onChange={(e) => {setActiveElement(null); onActiveChange(e)}}
                  tooltip="You can choose to enable or disable the campaign. Disabled campaigns will be ignored"
                  tooltipOptions={{ position: "right" }}
                />
                <p>{campaign.Active ? "Enabled" : "Disabled"}</p>
              </div>
              <div className="p-field p-col-12 p-lg-7">
                <h5>Select progress range: </h5>
                <div className="p-fluid p-formgrid p-grid">
                  <div className="p-field p-col-2">
                    {/* <InputText className="p-inputtext-sm" value={progressRange[0]} placeholder="Min"/> */}
                    <InputNumber inputId="minmax" value={campaign.MinProgress} mode="decimal" min={0} max={campaign.MaxProgress} onValueChange={(e) => {setActiveElement(null); handleProgressChange(e.value, "min", "text")}} tooltip="Min. progress of user" />
                  </div>
                  <div className="p-field p-col-4 text-center">
                    {/* <Button label="Est. # of users:" disabled={campaign.Org.OrgID === "" || campaign.Org.OrgID === undefined || campaign.Program.ProgramID === "none" || campaign.Program.ProgramID === undefined || isRetrievingUserCount} className="p-button-success p-mx-2" onClick={() => { getProviderCount() }} /> */}
                    <Button label="Est. # of users:" disabled={campaign.Org.OrgID === "" || campaign.Org.OrgID === undefined || isRetrievingUserCount} className="p-button-success p-mx-2" onClick={() => { getProviderCount() }} />
                  </div>
                  <div className="p-field p-col-4 text-center p-pt-2">
                    <p> <b>{estUsers}</b></p>
                  </div>
                  <div className="p-field p-col-2">
                    <InputNumber inputId="minmax" value={campaign.MaxProgress} mode="decimal" min={campaign.MinProgress} max={100} onValueChange={(e) => handleProgressChange(e.value, "max", "text")} tooltip="Max. progress of user" />
                  </div>
                </div>
                <Slider value={progressRange} onChange={e => { handleProgressChange(e.value, "none", "slider") /*setProgressRange(e.value);*/ }} onSlideEnd={e => { onSlideEnd(e) }} range />
              </div>
              <div className="p-field p-col-12 p-lg-3">
                <h5>Select messaging frequency</h5>
                <Dropdown
                  value={campaign.FrequencyInDays}
                  options={msgFrequencyDays}
                  onChange={(e) => {setActiveElement(null); onFreqChange(e)}}
                  optionLabel="itemText"
                  filter
                  showClear
                  filterBy="itemText"
                  placeholder="Select messaging frequency"
                  valueTemplate={selectedFreqTemplate}
                  itemTemplate={freqOptionTemplate}
                  tooltip="This setting ensures users don't receive messages too often."
                  tooltipOptions={{ position: "right" }}
                />
              </div>
              <div className="p-field p-col-12 p-lg-3">
                <h5>Select languages</h5>
                <MultiSelect
                  value={campaign.MultiLangId}
                  options={msgLangIds}
                  onChange={(e) => {setActiveElement(null); onLangChange(e)}}
                  // disabled = {isEditMode}
                  optionLabel="itemText"
                  filter
                  showClear
                  filterBy="itemText"
                  placeholder="Select Language"
                  // valueTemplate={selectedLangTemplate}
                  itemTemplate={langOptionTemplate}
                  className="multiselect-custom"
                  tooltip="Choose primary language for this campaign. (Preset message should match the chosen language)"
                  tooltipOptions={{ position: "right" }}
                />
                {/* <MultiSelect value={campaign.Programs} 
                disabled={isEditMode ? false : !orgChosen}
                    onFocus={() => fetchOrgs("org_courses")} 
                    options={courseList.length === 0 ? [{ id: "000", name: 'Loading...' }] : courseList}
                    onChange={onProgChange} 
                    optionLabel="name" 
                    placeholder="Select courses" 
                    className="multiselect-custom"
                    showClear
                    itemTemplate={progOptionTemplate}
                    selectedItemTemplate={selectedProgTemplate}
                    panelFooterTemplate={panelFooterTemplate} /> */}
              </div>
              <div className="p-field p-col-12 p-lg-3">
                <h5>Select time (EST)</h5>
                <Calendar
                  id="time12"
                  value={campaign.Time ? new Date(campaign.Time) : null}
                  onChange={e => {setActiveElement(null); setCampaignTime(e.value)}}
                  hourFormat="12"
                  timeOnly
                  tooltip="Schedule the time you want the campaign to run at"
                  tooltipOptions={{ position: "right" }}
                />
              </div>
              <div className="p-field p-col-12 p-lg-3">
                <h5>Days before</h5>
                <InputText
                  id="DaysBefore"
                  value={campaign.DaysBefore}
                  onChange={(e) => {setActiveElement(document.activeElement); setCampaign(onInputChange(e, "DaysBefore", campaign))}}
                  tooltip=""
                  tooltipOptions={{ position: "right" }}
                />
              </div>
              <div className="p-field p-col-12 p-lg-3">
                <h5>Signed Up</h5>
                <Dropdown
                  id="Signup"
	          value={campaign.Signup}
                  options={msgSignup}
	      	  onChange={(e) => {setActiveElement(null); onSignupChange(e); setCampaign(onInputChange(e, "Signup", campaign))}}
//                  onChange={(e) => {setActiveElement(null); onSignupChange(e); }}
                  optionLabel="itemText"
                  filter
                  showClear
                  filterBy="itemText"
                  placeholder="Select "
                  valueTemplate={selectedSignupTemplate}
                  itemTemplate={signupOptionTemplate}
                  tooltip="x"
                  tooltipOptions={{ position: "right" }}
                />
              </div>
            </div>
            <h5>Schedule Campaign runs</h5>
            <h6 className="text-muted p-mt-1 p-mb-3">
              The days the campaign will run
            </h6>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field-checkbox p-mx-auto">
                <Checkbox
                  inputId="sun"
                  name="days"
                  value="sun"
                  onChange={(e) => {setActiveElement(null); onDayChange(e)}}
                  checked={campaign.DaysOfWk.sun}
                />
                <label htmlFor="sun">Sunday</label>
              </div>
              <div className="p-field-checkbox p-mx-auto">
                <Checkbox
                  inputId="mon"
                  name="days"
                  value="mon"
                  onChange={(e) => {setActiveElement(null); onDayChange(e)}}
                  checked={campaign.DaysOfWk.mon}
                />
                <label htmlFor="mon">Monday</label>
              </div>
              <div className="p-field-checkbox p-mx-auto">
                <Checkbox
                  inputId="tue"
                  name="days"
                  value="tue"
                  onChange={(e) => {setActiveElement(null); onDayChange(e)}}
                  checked={campaign.DaysOfWk.tue}
                />
                <label htmlFor="tue">Tuesday</label>
              </div>
              <div className="p-field-checkbox p-mx-auto">
                <Checkbox
                  inputId="wed"
                  name="days"
                  value="wed"
                  onChange={(e) => {setActiveElement(null); onDayChange(e)}}
                  checked={campaign.DaysOfWk.wed}
                />
                <label htmlFor="wed">Wednesday</label>
              </div>
              <div className="p-field-checkbox p-mx-auto">
                <Checkbox
                  inputId="thurs"
                  name="days"
                  value="thurs"
                  onChange={(e) => {setActiveElement(null); onDayChange(e)}}
                  checked={campaign.DaysOfWk.thurs}
                />
                <label htmlFor="thurs">Thursday</label>
              </div>
              <div className="p-field-checkbox p-mx-auto">
                <Checkbox
                  inputId="fri"
                  name="days"
                  value="fri"
                  onChange={(e) => {setActiveElement(null); onDayChange(e)}}
                  checked={campaign.DaysOfWk.fri}
                />
                <label htmlFor="fri">Friday</label>
              </div>
              <div className="p-field-checkbox p-mx-auto">
                <Checkbox
                  inputId="sat"
                  name="days"
                  value="sat"
                  onChange={(e) => {setActiveElement(null); onDayChange(e)}}
                  checked={campaign.DaysOfWk.sat}
                />
                <label htmlFor="sat">Saturday</label>
              </div>
            </div>
            <h5>Create template message for campaign users</h5>
            <h6 className="text-muted p-mt-1 p-mb-3">
              Choose which user details the message will use. Messages will be
              customized using this template.
            </h6>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col-12 p-lg-6">
                <h6 className="text-muted p-mb-3"> Pick desired user details</h6>
                <div className="p-fluid p-formgrid p-grid ">
                  {templateMsgDetailOptions.map((detail, idx) => (
                    <div key={idx} className="p-field p-my-1 p-mx-1">
                      <Button
                        label={detail.title}
                        className="p-button-rounded p-button-help "
                        onClick={() => onDetailSelect(detail.templetePlaceholder)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="p-field p-col-12 p-lg-6">
                <h6 className="text-muted p-mb-3">Customize your message here</h6>
                <div id='container'>
                  <div id='element'>
                   
                    {/* <div id='loader'>Loading</div> */}
                    
                  </div>
                </div>
                <ReactApp />
                {/*<TabComponent id="languageTabs" heightAdjustMode='Auto' enablePersistence={true}>
                  <div className="e-tab-header">
                    <div>None</div>
                    <div>Mandarin</div>
                    <div>English</div>
                    <div>Creole</div>
                    <div>Korean</div>
                    <div>Polish</div>
                    <div>Russian</div>
                    <div>Spanish</div>
                    <div>Cantonese</div>
                  </div>
                  
                  <div className="e-content">
                    <div>
                    <InputTextarea
                      id="null"
                      key={0}
                      rows={8}
                      cols={30}
                      autoResize
                      autoFocus
                      placeholder="Enter Your Text Message"
                      value={campaign.MultiMsg[`null`] ? campaign.MultiMsg[`null`] : ""}  // check if its undefined. if it is, show "none"
                      disabled= {!campaign.MultiLangId.includes(`null`) }
                      //take on the default value on language preference setting
                      //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                      onChange={(e) => setActiveElement(document.activeElement); setCampaign(onMsgChange(e, `null` + "", campaign))}
                      tooltip="Pick and choose desired details and adjust your message."
                      tooltipOptions={{ position: "right" }}
                      />
                    <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                      No. of SMS required (Estimated):{" "}
                      {campaign.MultiMsg[`null`] 
                        ? campaign.MultiMsg[`null`].length > 160
                          ? Math.ceil(campaign.MultiMsg[`null`].length / 160) : 1
                        : 1}
                    </h6>
                    <h6 className="text-muted p-my-auto p-text-right">
                      Total character count: {campaign.MultiMsg[`null`] ? campaign.MultiMsg[`null`].length : 0}
                    </h6>
                    </div>
                    <div>
                    <InputTextarea
                      id="30"
                      key={1}
                      rows={8}
                      cols={30}
                      autoResize
                      autoFocus
                      placeholder="Enter Your Text Message"
                      value={campaign.MultiMsg[`30`] ? campaign.MultiMsg[`30`] : ""}  // check if its undefined. if it is, show "none"
                      disabled= {!campaign.MultiLangId.includes(30) }
                      //take on the default value on language preference setting
                      //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                      onChange={(e) => setActiveElement(document.activeElement); setCampaign(onMsgChange(e, 30 + "", campaign))}
                      tooltip="Pick and choose desired details and adjust your message."
                      tooltipOptions={{ position: "right" }}
                      />
                    <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                      No. of SMS required (Estimated):{" "}
                      {campaign.MultiMsg[`30`] 
                        ? campaign.MultiMsg[`30`].length > 160
                          ? Math.ceil(campaign.MultiMsg[`30`].length / 160) : 1
                        : 1}
                    </h6>
                    <h6 className="text-muted p-my-auto p-text-right">
                      Total character count: {campaign.MultiMsg[`30`] ? campaign.MultiMsg[`30`].length : 0}
                    </h6>
                    </div>
                    <div>
                    <InputTextarea
                      id="41"
                      key={2}
                      rows={8}
                      cols={30}
                      autoResize
                      autoFocus
                      placeholder="Enter Your Text Message"
                      value={campaign.MultiMsg[`41`] ? campaign.MultiMsg[`41`] : ""}  // check if its undefined. if it is, show "none"
                      disabled= {!campaign.MultiLangId.includes(41) }
                      //take on the default value on language preference setting
                      //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                      onChange={(e) => setActiveElement(document.activeElement); setCampaign(onMsgChange(e, 41 + "", campaign))}
                      tooltip="Pick and choose desired details and adjust your message."
                      tooltipOptions={{ position: "right" }}
                      />
                    <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                      No. of SMS required (Estimated):{" "}
                      {campaign.MultiMsg[`41`] 
                        ? campaign.MultiMsg[`41`].length > 160
                          ? Math.ceil(campaign.MultiMsg[`41`].length / 160) : 1
                        : 1}
                    </h6>
                    <h6 className="text-muted p-my-auto p-text-right">
                      Total character count: {campaign.MultiMsg[`41`] ? campaign.MultiMsg[`41`].length : 0}
                    </h6>
                    </div>
                    
                    <div>
                    <InputTextarea
                      id="56"
                      key={3}
                      rows={8}
                      cols={30}
                      autoResize
                      autoFocus
                      placeholder="Enter Your Text Message"
                      value={campaign.MultiMsg[`56`] ? campaign.MultiMsg[`56`] : ""}  // check if its undefined. if it is, show "none"
                      disabled= {!campaign.MultiLangId.includes(56) }
                      //take on the default value on language preference setting
                      //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                      onChange={(e) => setActiveElement(document.activeElement); setCampaign(onMsgChange(e, 56 + "", campaign))}
                      tooltip="Pick and choose desired details and adjust your message."
                      tooltipOptions={{ position: "right" }}
                      />
                    <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                      No. of SMS required (Estimated):{" "}
                      {campaign.MultiMsg[`56`] 
                        ? campaign.MultiMsg[`56`].length > 160
                          ? Math.ceil(campaign.MultiMsg[`56`].length / 160) : 1
                        : 1}
                    </h6>
                    <h6 className="text-muted p-my-auto p-text-right">
                      Total character count: {campaign.MultiMsg[`56`] ? campaign.MultiMsg[`56`].length : 0}
                    </h6>
                    </div>
                    <div>
                    <InputTextarea
                      id="86"
                      key={4}
                      rows={8}
                      cols={30}
                      autoResize
                      autoFocus
                      placeholder="Enter Your Text Message"
                      value={campaign.MultiMsg[`30`] ? campaign.MultiMsg[`86`] : ""}  // check if its undefined. if it is, show "none"
                      disabled= {!campaign.MultiLangId.includes(86) }
                      //take on the default value on language preference setting
                      //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                      onChange={(e) => setActiveElement(document.activeElement); setCampaign(onMsgChange(e, 86 + "", campaign))}
                      tooltip="Pick and choose desired details and adjust your message."
                      tooltipOptions={{ position: "right" }}
                      />
                    <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                      No. of SMS required (Estimated):{" "}
                      {campaign.MultiMsg[`86`] 
                        ? campaign.MultiMsg[`86`].length > 160
                          ? Math.ceil(campaign.MultiMsg[`86`].length / 160) : 1
                        : 1}
                    </h6>
                    <h6 className="text-muted p-my-auto p-text-right">
                      Total character count: {campaign.MultiMsg[`86`] ? campaign.MultiMsg[`86`].length : 0}
                    </h6>
                    </div>
                    <div>
                    <InputTextarea
                      id="127"
                      key={5}
                      rows={8}
                      cols={30}
                      autoResize
                      autoFocus
                      placeholder="Enter Your Text Message"
                      value={campaign.MultiMsg[`127`] ? campaign.MultiMsg[`127`] : ""}  // check if its undefined. if it is, show "none"
                      disabled= {!campaign.MultiLangId.includes(127) }
                      //take on the default value on language preference setting
                      //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                      onChange={(e) => setActiveElement(document.activeElement); setCampaign(onMsgChange(e, 127 + "", campaign))}
                      tooltip="Pick and choose desired details and adjust your message."
                      tooltipOptions={{ position: "right" }}
                      />
                    <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                      No. of SMS required (Estimated):{" "}
                      {campaign.MultiMsg[`127`] 
                        ? campaign.MultiMsg[`127`].length > 160
                          ? Math.ceil(campaign.MultiMsg[`127`].length / 160) : 1
                        : 1}
                    </h6>
                    <h6 className="text-muted p-my-auto p-text-right">
                      Total character count: {campaign.MultiMsg[`127`] ? campaign.MultiMsg[`127`].length : 0}
                    </h6>
                    </div>
                    <div>
                    <InputTextarea
                      id="134"
                      key={6}
                      rows={8}
                      cols={30}
                      autoResize
                      autoFocus
                      placeholder="Enter Your Text Message"
                      value={campaign.MultiMsg[`134`] ? campaign.MultiMsg[`134`] : ""}  // check if its undefined. if it is, show "none"
                      disabled= {!campaign.MultiLangId.includes(134) }
                      //take on the default value on language preference setting
                      //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                      onChange={(e) => setActiveElement(document.activeElement); setCampaign(onMsgChange(e, 134 + "", campaign))}
                      tooltip="Pick and choose desired details and adjust your message."
                      tooltipOptions={{ position: "right" }}
                      />
                    <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                      No. of SMS required (Estimated):{" "}
                      {campaign.MultiMsg[`134`] 
                        ? campaign.MultiMsg[`134`].length > 160
                          ? Math.ceil(campaign.MultiMsg[`134`].length / 160) : 1
                        : 1}
                    </h6>
                    <h6 className="text-muted p-my-auto p-text-right">
                      Total character count: {campaign.MultiMsg[`134`] ? campaign.MultiMsg[`134`].length : 0}
                    </h6>
                    </div>
                    <div>
                    <InputTextarea
                      id="149"
                      key={7}
                      rows={8}
                      cols={30}
                      autoResize
                      autoFocus
                      placeholder="Enter Your Text Message"
                      value={campaign.MultiMsg[`149`] ? campaign.MultiMsg[`149`] : ""}  // check if its undefined. if it is, show "none"
                      disabled= {!campaign.MultiLangId.includes(149) }
                      //take on the default value on language preference setting
                      //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                      onChange={(e) => setActiveElement(document.activeElement); setCampaign(onMsgChange(e, 149 + "", campaign))}
                      tooltip="Pick and choose desired details and adjust your message."
                      tooltipOptions={{ position: "right" }}
                      />
                    <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                      No. of SMS required (Estimated):{" "}
                      {campaign.MultiMsg[`149`] 
                        ? campaign.MultiMsg[`149`].length > 160
                          ? Math.ceil(campaign.MultiMsg[`149`].length / 160) : 1
                        : 1}
                    </h6>
                    <h6 className="text-muted p-my-auto p-text-right">
                      Total character count: {campaign.MultiMsg[`149`] ? campaign.MultiMsg[`149`].length : 0}
                    </h6>
                    </div>
                    <div>
                    <InputTextarea
                      id="185"
                      key={8}
                      rows={8}
                      cols={30}
                      autoResize
                      autoFocus
                      placeholder="Enter Your Text Message"
                      value={campaign.MultiMsg[`185`] ? campaign.MultiMsg[`185`] : ""}  // check if its undefined. if it is, show "none"
                      disabled= {!campaign.MultiLangId.includes(185) }
                      //take on the default value on language preference setting
                      //onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                      onChange={(e) => setActiveElement(document.activeElement); setCampaign(onMsgChange(e, 185 + "", campaign))}
                      tooltip="Pick and choose desired details and adjust your message."
                      tooltipOptions={{ position: "right" }}
                      />
                    <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                      No. of SMS required (Estimated):{" "}
                      {campaign.MultiMsg[`185`] 
                        ? campaign.MultiMsg[`185`].length > 160
                          ? Math.ceil(campaign.MultiMsg[`1857`].length / 160) : 1
                        : 1}
                    </h6>
                    <h6 className="text-muted p-my-auto p-text-right">
                      Total character count: {campaign.MultiMsg[`185`] ? campaign.MultiMsg[`185`].length : 0}
                    </h6>
                    </div>
                  </div>
                </TabComponent>*/}
                {/* <InputTextarea
                  rows={8}
                  cols={30}
                  autoResize
                  value={campaign.MultiMsg}
                  onChange={(e) => setActiveElement(document.activeElement); setCampaign(onInputChange(e, "MultiMsg", campaign))}
                  tooltip="Pick and choose desired details and adjust your message."
                  tooltipOptions={{ position: "right" }}
                /> */}
                {/* <h6 className="text-muted p-mt-2 p-mb-auto p-text-right">
                  No. of SMS required (Estimated):{" "}
                  {campaign.MultiMsg.length > 160
                    ? Math.ceil(campaign.MultiMsg["149"].length / 160)
                    : 1}
                </h6>
                <h6 className="text-muted p-my-auto p-text-right">
                  Total character count: {campaign.MultiMsg["149"].length}
                </h6> */}
              </div>
              
            </div>
          </Dialog>

          <Dialog
            header="Alert!"
            visible={errorOccured}
            style={{ width: "50vw" }}
            footer={renderErrorDialogFooter("displayBasic")}
            onHide={() => onHide("displayBasic")}
            closeOnEscape={false}
          >
            <p>{errorMsg === "" ? "Placeholder tezt" : errorMsg}</p>
          </Dialog>

          <Dialog
            visible={deleteCampaignDialog}
            style={{ width: "450px" }}
            header="Confirm delete"
            modal
            footer={deleteCampaignDialogFooter}
            onHide={() => { setDeleteCampaignDialog(false); setCampaign(emptyCampaign) }}
          >
            <div
            // className="confirmation-content"
            >
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {campaign && (
                <span>
                  Are you sure you want to delete <b>{campaign.CampaignName}</b>?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteCampaignsDialog}
            style={{ width: "450px" }}
            header="Confirm delete"
            modal
            footer={deleteCampaignsDialogFooter}
            onHide={() => setDeleteCampaignsDialog(false)}
          >
            <div
            // className="confirmation-content"
            >
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {campaign && (
                <span>
                  Are you sure you want to delete the selected campaigns?
                </span>
              )}
            </div>
          </Dialog>
        </div>
        : <>
          <Dialog
            header="Alert!"
            visible={errorOccured}
            style={{ width: "50vw" }}
            footer={renderErrorDialogFooter("displayBasic")}
            onHide={() => onHide("displayBasic")}
            closeOnEscape={false}
          >
            <p>{errorMsg === "" ? "Placeholder tezt" : errorMsg}</p>
          </Dialog>
          <div>
            <ProgressSpinner style={{ width: '100px', height: '100px' }} strokeWidth="4" fill="#FFFFFF" />
            <br />
            {nothingMsg}
          </div>

        </>}
    </div>
  );
};

export default TableComponent;

// TODO: MOVE THESE FUNCTIONS TO ANOTHER FILE

// ReactDOM.render(<ReactApp />, document.getElementById('element'));

//END OF SET STATE PERSISTANCE OF TAB COMPONENT


function generateTableColumns(selectedColumns, booleanFilter, deletedFilter) {
  return selectedColumns.map(col => {
    switch (col.field) {
      case "DaysOfWk":
        return <Column key={col.field} field={col.field} header={col.header}
          body={daysConverter}
          sortable />;
        break;
      case "Active":
        return <Column key={col.field} field={col.field} header={col.header}
          body={booleanChecker}
          filter filterElement={booleanFilter}
          sortable />;
        break;
      case "Deleted":
        return <Column key={col.field} field={col.field} header={col.header}
          body={dateConverter}
          filter filterElement={deletedFilter}
          filterFunction={filterDate}
          sortable />;
        break;
      case "MaxProgress":
        return <Column key={col.field} field={col.field} header={col.header}
          body={progressConverter}
          sortable />;
        break;
      case "MultiLangId":
        return <Column key={col.field} field={col.field} header={col.header}
          body={languageIdentifier}
          sortable />;
        break;
      case "Time":
        return <Column key={col.field} field={col.field} header={col.header}
          body={timeToStr}
          sortable />;
        break;
      case "ProgramID":
        return <Column key={col.field} field={col.field} header={col.header}
          body={programIdsToStr}
          sortable />
        break;
      case "ProgramName":
        return <Column key={col.field} field={col.field} header={col.header}
          body={programNamesParser}
          sortable />
        break;
      default:
        return <Column key={col.field} field={col.field} header={col.header}
          sortable />;
        break;
    }
  });
}

function retrieveOrgsAndCourses(chosenOrg, setOrgsReceived, setOrgsList, setCourseList) {
  return async (mode) => {

    console.log(mode);

    // TODO: Comment this afterwards
    // let chosenOrg = 124;
    let chosenMode;
    switch (mode) {
      case 'orgs':
        chosenMode = JSON.stringify({
          "function": "organizations"
        });
        break;
      case 'org_courses':
        chosenMode = JSON.stringify({
          "function": "courses",
          "organization_id": chosenOrg
        });
    }

    var axiosConfig = {
      method: 'post',
      url: `${config.api.invokeUrl}/orgs`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': config.api.apiKey,
      },
      data: chosenMode
    };

    axios(axiosConfig)
      .then((response) => {
        console.log(response.data);

        if (mode === 'orgs') {
          setOrgsReceived(true);
          setOrgsList(response.data);
        }
        else if (mode = 'org_courses') {
          setCourseList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        // setOrgsReceived(true);
        // setOrgsList([
        //   {
        //     "id": 124,
        //     "name": "SI Homecare Agency"
        //   }
        // ]);
      });
  };
}

function retrieveCampaignsRequest(setCampaignsResponseRecieved, setCampaigns, setNothingMsg, setErrorMsg, setErrorOccured) {
  return async () => {
    setCampaignsResponseRecieved(false);
    console.log("Fetching...");

//CHANGE 'true' TO ENTER OR 'false' EXIT DEBUG
    const DEBUG_MODE = config.DEBUG_MODE;

    if (DEBUG_MODE) {
    //loading dummy campaigns for testing
    console.log(dummyCampaigns);
    setCampaigns(dummyCampaigns);
    setCampaignsResponseRecieved(true);

    } else {

    // TODO: change this back
      var axiosConfig = {
        method: "get",
        url: `${config.api.invokeUrl}/campaigns`,
        withCredentials: true,
        headers: {
          "x-api-key": config.api.apiKey,
        },
      };

      await axios(axiosConfig)
        .then((response) => {
          let resObj = JSON.parse(JSON.stringify(response));
          const campaignsResponse = resObj.data.campaigns;

          const transformedCampaigns = campaignsResponse.map(({
            CampaignID, OrgID, OrgName, ProgramID, ProgramName, CampaignName, Deleted, Active, DaysOfWk, Signup, DaysBefore, FrequencyInDays, MultiMsg, CampaignUrl, MinProgress, MaxProgress, campTime, MultiLangId, CreatedBy, TimeCreated, ModifiedBy, TimeUpdated, Stats, Programs }) => ({
              CampaignID: CampaignID,
              OrgID: OrgID,
              OrgName: OrgName,
              ProgramID: ProgramID,
              ProgramName: ProgramName,
              CampaignName: CampaignName,
              Deleted: Deleted,
              Active: Active,
              DaysOfWk: DaysOfWk,
              FrequencyInDays: FrequencyInDays,
              DaysBefore: DaysBefore,
              Signup: Signup,
              MultiMsg: MultiMsg,
              CampaignUrl: CampaignUrl,
              MinProgress: MinProgress,
              MaxProgress: MaxProgress,
              Time: campTime,
              MultiLangId: MultiLangId,
              CreatedBy: CreatedBy,
              TimeCreated: TimeCreated,
              ModifiedBy: ModifiedBy,
              TimeUpdated: TimeUpdated,
              Stats: Stats,
              Programs: Programs
            }));

          setCampaigns(transformedCampaigns);
          setCampaignsResponseRecieved(true);
          // console.log(campaigns);
        })
        .catch((error) => {
          console.log(error);
          setNothingMsg("An error occurred.");
          setErrorMsg(`${error.name}: ${error.message}\n Verbose: \n${JSON.stringify(error)}`);
          setErrorOccured(true);
        });
    }
  };
}

function deleteMultipleCampaignsRequest(campaigns, selectedCampaigns, setErrorOccured, errorOccured, setErrorMsg, setDeleteCampaignsDialog, setSelectedCampaigns, setCampaigns, setCampaign, setIsInvalidRequest) {
  return async () => {
    let _campaigns = [...campaigns];
    let _deletedCampaigns = campaigns.filter(val => selectedCampaigns.includes(val));

    let axiosConfig;
    let resultsArray = [];
    let params;
    let currentTime = new Date();

    let operationResponse = "undefined";

    for (let index = 0; index < selectedCampaigns.length; index++) {

      params = JSON.stringify({
        id: selectedCampaigns[index].CampaignID,
        requestMode: "Delete",
        timeDeleted: currentTime
      });

      axiosConfig = {
        method: "patch",
        url: `${config.api.invokeUrl}/campaigns/${selectedCampaigns[index].CampaignID}`,
        withCredentials: true,
        headers: {
          "x-api-key": config.api.apiKey,
        },
        data: params,
      };

      await axios(axiosConfig)
        .then((response) => {
          console.log(response);
          if (response.data.message === 'INVALID_REQUEST') {
            operationResponse = response.data.message;
          }
          else {
            if (response.data.message === "CAMPAIGN_DELETED" && response.status === 200) {
              console.log(`Campaign ${selectedCampaigns[index].CampaignName} was deleted successfully`);
              setErrorOccured(false);
              // setTimeout(console.log(errorOccured), 100);
              resultsArray.push(true);
            }
            else {
              console.log(`Unable to delete the campaign ${selectedCampaigns[index].CampaignName}`);
              resultsArray.push(false);
            }
          }
        })
        .catch((err) => {
          console.log(`Unable to delete campaign ${selectedCampaigns[index].CampaignName}: ${err}`);
          resultsArray.push(false);
        });

      if (operationResponse === 'INVALID_REQUEST') {
        break;
      }

    }

    // if operation was valid (aka not INVALID_REQUEST)
    if (operationResponse === "undefined") {
      // if any of the campaigns werent deleted (indicated by false in the array)
      if (resultsArray.includes(false)) { // some campaigns didnt get deleted
        // Error occured with atleast one of the campaigns when trying to delete them.
        let errorMsgBaseStr = "The following campaigns werent deleted.";
        let nonDeletedCampaignsList = [];
        resultsArray.forEach((result, idx) => {
          if (!result) {
            nonDeletedCampaignsList.push(selectedCampaigns[idx].CampaignName);
          }
        });
        nonDeletedCampaignsList.forEach(element => {
          errorMsgBaseStr = errorMsgBaseStr + `\n${element}`;
        });

        setErrorMsg(errorMsgBaseStr);
        setErrorOccured(true);
        setDeleteCampaignsDialog(false);
        setSelectedCampaigns(null);
      }
      else { // ALL Campaigns got deleted
        console.log("everything got deleted");
        let deletedCampaign;

        _deletedCampaigns.forEach(element => {
          deletedCampaign = {
            CampaignID: element.CampaignID,
            CampaignName: element.CampaignName,
            Active: element.Active,
            DaysOfWk: element.DaysOfWk,
            FrequencyInDays: element.FrequencyInDays,
            DaysBefore: element.DaysBefore,
            Signup: element.Signup,
            MultiLangId: element.MultiLangId,
            MultiMsg: element.MultiMsg,
            CampaignUrl: element.CampaignUrl,
            Deleted: currentTime,
            OrgID: element.OrgID,
            OrgName: element.OrgName,
            ProgramID: element.ProgramID,
            ProgramName: element.ProgramName,
            MinProgress: element.MinProgress,
            MaxProgress: element.MaxProgress,
            CreatedBy: element.CreatedBy,
            TimeCreated: element.TimeCreated,
            ModifiedBy: element.ModifiedBy,
            TimeUpdated: element.TimeUpdated,
            Stats: element.Stats,
            Time: element.Time,

          };

          // if campaign has a Programs field (for multiple program selection)
          if (element.Programs) {
            deletedCampaign["Programs"] = element.Programs
          }
          // if it does not, then make a Programs field using the ProgramID and ProgramName
          else {
            deletedCampaign["Programs"] = [{ id: element.ProgramID, name: element.ProgramName }]
          }


          var index = findIndexById(element.CampaignID, campaigns);
          _campaigns[index] = deletedCampaign;
        });
        setCampaigns(_campaigns);

        setDeleteCampaignsDialog(false);
        setSelectedCampaigns(null);
        setCampaign(emptyCampaign);
        setErrorMsg("All campaigns were succesfully deleted");
        setErrorOccured(true);
      }
    }

    else if (operationResponse === 'INVALID_REQUEST') {
      setIsInvalidRequest(true);
      setDeleteCampaignsDialog(false);
      setSelectedCampaigns(null);
      setCampaign(emptyCampaign);
      setErrorMsg("The action was not authenticated. Please reload the page or sign out.");
      setErrorOccured(true);
    }
  };
}

function deleteCampaignActionHandler(setCampaign, setDeleteCampaignDialog) {
  return (specificCampaign) => {
    let reformattedCampaign = {
      CampaignID: specificCampaign.CampaignID,
      Org: {
        OrgID: specificCampaign.OrgID,
        OrgName: specificCampaign.OrgName,
      },
      Program: {
        ProgramName: specificCampaign.ProgramName,
        ProgramID: specificCampaign.ProgramID,
      },
      CampaignName: specificCampaign.CampaignName,
      Active: specificCampaign.Active,
      DaysOfWk: specificCampaign.DaysOfWk,
      MultiLangId: specificCampaign.MultiLangId,
      FrequencyInDays: specificCampaign.FrequencyInDays,
      DaysBefore: specificCampaign.DaysBefore,
      Signup: specificCampaign.Signup,
      MultiMsg: specificCampaign.MultiMsg,
      CampaignUrl: specificCampaign.CampaignUrl,
      Deleted: specificCampaign.Deleted,
      MinProgress: specificCampaign.MinProgress,
      MaxProgress: specificCampaign.MaxProgress,
      CreatedBy: specificCampaign.CreatedBy,
      ModifiedBy: specificCampaign.ModifiedBy,
      TimeCreated: specificCampaign.TimeCreated,
      TimeUpdated: specificCampaign.TimeUpdated,
      Stats: specificCampaign.Stats,
      Time: specificCampaign.Time
    };

    // if it has program field
    if (specificCampaign.Programs) {
      console.log("already has programs field")
      reformattedCampaign["Programs"] = specificCampaign.Programs
    }
    else {
      console.log(" has no programs field. making new one")
      if (specificCampaign.ProgramID !== "none") {
        reformattedCampaign["Programs"] = [
          {
            id: specificCampaign.ProgramID,
            name: specificCampaign.ProgramName,
          }
        ]
      }
    }

    setCampaign(reformattedCampaign);
    console.log(reformattedCampaign)
    setDeleteCampaignDialog(true);
    console.log("In delete mode.");
  };
}

function deleteCampaignRequest(campaign, setIsInvalidRequest, setCampaignDialog, setCampaign, setErrorMsg, setErrorOccured, campaigns, setCampaigns, toast, setDeleteCampaignDialog) {
  return async () => {
    // let _campaigns = campaigns.filter(val => val.CampaignID !== campaign.CampaignID);
    let _campaign = campaign;

    let currentTime = new Date();
    let params = JSON.stringify({
      id: _campaign.CampaignID,
      requestMode: "Delete",
      timeDeleted: currentTime
    });
    var axiosConfig = {
      method: "patch",
      url: `${config.api.invokeUrl}/campaigns/${_campaign.CampaignID}`,
      withCredentials: true,
      headers: {
        "x-api-key": config.api.apiKey,
      },
      data: params,
    };

    await axios(axiosConfig)
      .then((response) => {
        console.log(response);

        if (response.data.message === 'INVALID_REQUEST') {
          setIsInvalidRequest(true);
          setCampaignDialog(false);
          setCampaign(emptyCampaign);
          setErrorMsg("The action was not authenticated. Please reload the page or sign out.");
          setErrorOccured(true);
        }
        else {
          if (response.data.message === "CAMPAIGN_DELETED" && response.status === 200) {
            console.log("The campaign was deleted successfully");

            let deletedCampaign = {
              CampaignID: _campaign.CampaignID,
              CampaignName: _campaign.CampaignName,
              Active: _campaign.Active,
              DaysOfWk: _campaign.DaysOfWk,
              MultiLangId: _campaign.MultiLangId,
              FrequencyInDays: _campaign.FrequencyInDays,
              DaysBefore: _campaign.DaysBefore,
              Signup: _campaign.Signup,
              MultiMsg: _campaign.MultiMsg,
              CampaignUrl: _campaign.CampaignUrl,
              Deleted: currentTime,
              OrgID: _campaign.Org.OrgID,
              OrgName: _campaign.Org.OrgName,
              ProgramName: _campaign.Program.ProgramName,
              ProgramID: _campaign.Program.ProgramID,
              MinProgress: _campaign.MinProgress,
              MaxProgress: _campaign.MaxProgress,
              CreatedBy: _campaign.CreatedBy,
              TimeCreated: _campaign.TimeCreated,
              ModifiedBy: _campaign.ModifiedBy,
              TimeUpdated: _campaign.TimeUpdated,
              Stats: _campaign.Stats,
              Time: _campaign.Time,
              Programs: _campaign.Programs
            };

            // console.log(deletedCampaign);
            let _campaigns = [...campaigns];
            const index = findIndexById(_campaign.CampaignID, campaigns);
            _campaigns[index] = deletedCampaign;
            setCampaigns(_campaigns);

            setErrorOccured(false);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Campaign deleted', life: 3000 });
          }
          else {
            console.log("Unable to delete the campaign");
            toast.current.show({ severity: 'error', summary: 'Error occured', detail: 'Campaign did not delete successfully', life: 3000 });
          }
        }
        setDeleteCampaignDialog(false);
        setCampaign(emptyCampaign);
      })
      .catch((err) => {
        console.log(`Unable to delete campaign: ${err}`);
        toast.current.show({ severity: 'error', summary: 'Error occured', detail: 'Delete operation request could not be made', life: 4000 });
        setDeleteCampaignDialog(false);
        setCampaign(emptyCampaign);
      });
  };
}

function updateCampaignActionHandler(setChosenOrg, setCampaign, setProgressRange, setCampaignDialog, toggleEditMode) {
  return (specificCampaign) => {

    console.log(specificCampaign);

    setChosenOrg(specificCampaign.OrgID);

    let scheduledDays = specificCampaign.DaysOfWk;

    var matches = scheduledDays.match(/\d+/g);

    for (let index = 0; index < matches.length; index++) {
      const day = matches[index];
      matches[index] = parseInt(day);
    }

    let daysObj = {
      "sun": false,
      "mon": false,
      "tue": false,
      "wed": false,
      "thurs": false,
      "fri": false,
      "sat": false
    };

    let idx = 0;
    for (let prop in daysObj) {
      if (matches.includes(idx)) {
        daysObj[prop] = true;
      }
      idx++;
    }

    let reformattedCampaign = {
      CampaignID: specificCampaign.CampaignID,
      Org: {
        OrgID: specificCampaign.OrgID,
        OrgName: specificCampaign.OrgName
      },
      Program: {
        ProgramName: specificCampaign.ProgramName,
        ProgramID: specificCampaign.ProgramID,
      },
      CampaignName: specificCampaign.CampaignName,
      Active: specificCampaign.Active,
      DaysOfWk: daysObj,
      MultiLangId: specificCampaign.MultiLangId,
      FrequencyInDays: specificCampaign.FrequencyInDays,
      DaysBefore: specificCampaign.DaysBefore,
      Signup: specificCampaign.Signup,
      MultiMsg: specificCampaign.MultiMsg,
      CampaignUrl: specificCampaign.CampaignUrl,
      Deleted: specificCampaign.Deleted,
      MinProgress: specificCampaign.MinProgress,
      MaxProgress: specificCampaign.MaxProgress,
      CreatedBy: specificCampaign.CreatedBy,
      TimeCreated: specificCampaign.TimeCreated,
      ModifiedBy: specificCampaign.ModifiedBy,
      TimeUpdated: specificCampaign.TimeUpdated,
      Stats: specificCampaign.Stats,
      Time: specificCampaign.Time
    };

    // if it has program field
    if (specificCampaign.Programs) {
      console.log("already has programs field")
      reformattedCampaign["Programs"] = specificCampaign.Programs
    }
    else {
      console.log(" has no programs field. making new one")
      if (specificCampaign.ProgramID !== "none") {
        reformattedCampaign["Programs"] = [
          {
            id: specificCampaign.ProgramID,
            name: specificCampaign.ProgramName,
          }
        ]
      }
    }

    setCampaign(reformattedCampaign);
    setProgressRange([specificCampaign.MinProgress, specificCampaign.MaxProgress]);
    setCampaignDialog(true);
    toggleEditMode(true);
    console.log("In edit mode.");
    setTimeout(() => console.log(reformattedCampaign), 100);
  };
}

function publishCampaignRequest(setSubmitted, campaign, isEditMode, setIsInvalidRequest, setCampaignDialog, setCampaign, setErrorMsg, setErrorOccured, errorOccured, setCampaigns, campaigns, toast, setOrgChosen, setProgressRange) {
  return async () => {
    setSubmitted(true);
    console.log(campaign);
    // save the campaign
    let _campaign = campaign;

    console.log(_campaign);

    let daysArr = [
      _campaign.DaysOfWk.sun,
      _campaign.DaysOfWk.mon,
      _campaign.DaysOfWk.tue,
      _campaign.DaysOfWk.wed,
      _campaign.DaysOfWk.thurs,
      _campaign.DaysOfWk.fri,
      _campaign.DaysOfWk.sat,
    ];

    let time = _campaign.Time;

    let scheduledDays = "";
    let indexMarker = -1;

    daysArr.forEach((day) => {
      indexMarker += 1;
      if (day) {
        scheduledDays = scheduledDays + " " + indexMarker;
      }
    });

    // console.log(scheduledDays);
    let preDeterminedDeletedState = isEditMode ? _campaign.Deleted : false;
    let modifiedBy;
    let createdBy;
    let timeCreated;
    let timeUpdated;
    let currentSession = JSON.parse(localStorage.getItem("userToken"));
    let currentTime = new Date();

    //create metdata for the campaign
    if (isEditMode) {
      createdBy = _campaign.CreatedBy;
      modifiedBy = currentSession.user;
      timeCreated = _campaign.TimeCreated;
      timeUpdated = currentTime;
    }
    else {
      createdBy = currentSession.user;
      modifiedBy = null;
      timeCreated = currentTime;
      timeUpdated = null;
    }

    let paramsObj = {
      CampaignID: _campaign.CampaignID,
      CampaignName: _campaign.CampaignName,
      Active: _campaign.Active,
      DaysOfWk: scheduledDays,
      MultiLangId: _campaign.MultiLangId,
      FrequencyInDays: _campaign.FrequencyInDays,
      DaysBefore: _campaign.DaysBefore,
      Signup: _campaign.Signup,
      MultiMsg: _campaign.MultiMsg,
      Url: _campaign.CampaignUrl,
      Deleted: preDeterminedDeletedState,
      Org: _campaign.Org,
      ChosenProgram: _campaign.Program,
      MinProgress: _campaign.MinProgress,
      MaxProgress: _campaign.MaxProgress,
      CreatedBy: createdBy,
      TimeCreated: timeCreated,
      ModifiedBy: modifiedBy,
      TimeUpdated: timeUpdated,
      requestMode: "Update",
      Time: time
    }

    // if there are multiple selected programs
    if (_campaign.Programs) {
      paramsObj["Programs"] = _campaign.Programs
    }

    //TODO: if there are multiple selected languages - MultiLang 'if' statement should move here?

    let params = JSON.stringify(paramsObj);

    console.log(JSON.parse(params));
    // setCampaign(emptyCampaign);

    let axiosConfig;

    if (isEditMode) {
      // do the update operation
      console.log("Update operation executing...");
      axiosConfig = {
        method: "patch",
        url: `${config.api.invokeUrl}/campaigns/${_campaign.CampaignID}`,
        withCredentials: true,
        headers: {
          "x-api-key": config.api.apiKey,
          "Content-Type": "application/json",
        },
        data: params,
      };
    }
    else {
      //  do the add operation
      console.log("Add operation executing...");
      axiosConfig = {
        method: "post",
        url: `${config.api.invokeUrl}/campaigns/${_campaign.CampaignID}`,
        withCredentials: true,
        headers: {
          "x-api-key": config.api.apiKey,
          "Content-Type": "application/json",
        },
        data: params,
      };
    }

    await axios(axiosConfig)
      .then((response) => {
        console.log(response);

        if (response.data.message === 'INVALID_REQUEST') {
          setIsInvalidRequest(true);
          setCampaignDialog(false);
          setCampaign(emptyCampaign);
          setErrorMsg("The action was not authenticated. Please reload the page or sign out.");
          setErrorOccured(true);
        }
        else {
          if (response.data.message === "CAMPAIGN_ADDED" && response.status === 200) {
            console.log("The campaign was added successfully");
            setErrorOccured(false);
            setTimeout(console.log(errorOccured), 100);

            // creating the new campaign object to be added 
            let newCampaign = {
              CampaignID: _campaign.CampaignID,
              CampaignName: _campaign.CampaignName,
              Active: _campaign.Active,
              DaysOfWk: scheduledDays,
              MultiLangId: _campaign.MultiLangId,
              FrequencyInDays: _campaign.FrequencyInDays,
              DaysBefore: _campaign.DaysBefore,
              Signup: _campaign.Signup,
              MultiMsg: _campaign.MultiMsg,
              CampaignUrl: _campaign.CampaignUrl,
              Deleted: preDeterminedDeletedState,
              OrgID: _campaign.Org.OrgID,
              OrgName: _campaign.Org.OrgName,
              ProgramName: _campaign.Program.ProgramName,
              ProgramID: _campaign.Program.ProgramID,
              MinProgress: _campaign.MinProgress,
              MaxProgress: _campaign.MaxProgress,
              CreatedBy: createdBy,
              TimeCreated: timeCreated,
              ModifiedBy: modifiedBy,
              TimeUpdated: timeUpdated,
              Stats: _campaign.Stats,
              Time: _campaign.Time,
              Programs: _campaign.Programs
            };

            console.log(newCampaign);
            // then add the newly created campaign to the current list of campaigns.
            setCampaigns([...campaigns, newCampaign]);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Campaign added', life: 3000 });
          }
          else if (response.data.message === "ERROR_OCCURED" && response.status === 200) {
            let errMsg = response.data.details;
            console.log(errMsg);
            setErrorMsg(`Error occured: \n${JSON.stringify(errMsg)}`);
            setIsInvalidRequest(false);
            setErrorOccured(true);

            toast.current.show({ severity: 'error', summary: 'Error occured', detail: 'The add operation didnt complete', life: 3000 });
          }
          else if (response.data.message === "CAMPAIGN_UPDATED" && response.status === 200) {
            console.log("The campaign was updated successfully");
            setErrorOccured(false);
            setTimeout(console.log(errorOccured), 100);
            // creating the updated campaign object
            let updatedCampaign = {
              CampaignID: _campaign.CampaignID,
              CampaignName: _campaign.CampaignName,
              Active: _campaign.Active,
              DaysOfWk: scheduledDays,
              MultiLangId: _campaign.MultiLangId,
              FrequencyInDays: _campaign.FrequencyInDays,
              DaysBefore: _campaign.DaysBefore,
              Signup: _campaign.Signup,
              MultiMsg: _campaign.MultiMsg,
              CampaignUrl: _campaign.CampaignUrl,
              Deleted: preDeterminedDeletedState,
              OrgID: _campaign.Org.OrgID,
              OrgName: _campaign.Org.OrgName,
              ProgramName: _campaign.Program.ProgramName,
              ProgramID: _campaign.Program.ProgramID,
              MinProgress: _campaign.MinProgress,
              MaxProgress: _campaign.MaxProgress,
              CreatedBy: createdBy,
              TimeCreated: timeCreated,
              ModifiedBy: modifiedBy,
              TimeUpdated: timeUpdated,
              Stats: _campaign.Stats,
              Time: _campaign.Time,
              Programs: _campaign.Programs
            };

            // update the object in the campaign
            let _campaigns = [...campaigns];

            const index = findIndexById(_campaign.CampaignID, campaigns);
            // console.log(`Index of campaign to update: ${index}`);
            // console.log("---------------Campaign to update---------------");
            // console.log(_campaigns[index]);
            // console.log("---------------Updated campaign---------------");
            // console.log(updatedCampaign);
            _campaigns[index] = updatedCampaign;
            setCampaigns(_campaigns);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Campaign Updated', life: 3000 });
          }
          else {
            // toast.current.show({severity: 'error', summary: 'Error occured', detail: 'The operation did not complete succesfully', life: 3000});
            setErrorOccured(true);
            setErrorMsg(response.data.message);
            setTimeout(console.log(errorOccured), 100);
            toast.current.show({ severity: 'error', summary: 'Error occured', detail: 'The operation did not complete succesfully', life: 3000 });
          }
          setCampaignDialog(false);
          setCampaign(emptyCampaign);
          setOrgChosen(false);
          setProgressRange([0, 100]);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg(JSON.stringify(err));
        setErrorOccured(true);
        setCampaignDialog(false);
        setCampaign(emptyCampaign);
        setOrgChosen(false);
        toast.current.show({ severity: 'error', summary: 'Error occured', detail: 'Operation request could not be made', life: 7000 });
      });
  };
}

function progressSliderChangeHandler(campaign, setProgressRange, progressRange, setCampaign) {
  return (value, mode, inputType) => {
    let _campaign = { ...campaign };
    if (inputType === "text") {
      if (mode === "min") {
        console.log(`MIN: ${value}`);
        setProgressRange([value, progressRange[1]]);
        _campaign["MinProgress"] = value;
      }
      else if (mode === "max") {
        console.log(`MAX: ${value}`);
        setProgressRange([progressRange[0], value]);
        _campaign["MaxProgress"] = value;
      }
    }
    else if (inputType === "slider") {
      setProgressRange(value);
      _campaign["MinProgress"] = value[0];
      _campaign["MaxProgress"] = value[1];

    }
    setCampaign(_campaign);
  };
}

function generatedDropdownSelectedLangTemplate() {
  return (options, props) => {
    if (props.value === "" || props.value === undefined || props.value === null) {
      return (
        <span>
          {props.placeholder}
        </span>
      );
    }
    else {
      let matchedLang = (msgLangIds.filter((lang) => {
        return lang.value == props.value;
      }));
      return (
        <div>
          <div>{`${matchedLang[0]["itemText"]}`}</div>
        </div>
      );
    }
  };
}

function MultiMsgOptionClickHandler(campaign, setCampaign) {
  return detailType => {
    var tabObj = document.getElementById("languageTabs")
    var selectedIndex = tabObj.ej2_instances[0].selectedItem;
    var selectedLangTab = msgLangIds[selectedIndex].value;

    let _campaign = { ...campaign };
    let MultiMsgProp = (_campaign.MultiMsg[`${selectedLangTab}`] ? _campaign.MultiMsg[`${selectedLangTab}`] : "") + detailType;
    _campaign.MultiMsg[`${selectedLangTab}`] = MultiMsgProp;

    setCampaign(_campaign);
  };
}


function dayCheckboxToggleHandler(campaign, setCampaign) {
  return e => {
    // e.value = the day
    // e.checked is the boolean value for that day
    let _campaign = { ...campaign };
    let daysOfWkProp = _campaign[`DaysOfWk`];
    daysOfWkProp[`${e.value}`] = e.checked;
    _campaign[`DaysOfWk`] = daysOfWkProp;

    setCampaign(_campaign);
  };
}

function activeToggleHandler(campaign, setCampaign) {
  return e => {
    let val = e.value;
    console.log(val);
    let _campaign = { ...campaign };
    _campaign['Active'] = val;

    setCampaign(_campaign);
  };
}

function freqDropdownOptionHandler(campaign, setCampaign) {
  return e => {
    let val = (e.target && e.target.value) || '';
    let freqVal = val;
    let _campaign = { ...campaign };
    _campaign['FrequencyInDays'] = freqVal;

    setCampaign(_campaign);
  };
}
function signupDropdownOptionHandler(campaign, setCampaign) {
  return e => {
    let val = (e.target && e.target.value) || '';
    let signupVal = val;
    let _campaign = { ...campaign };
    _campaign['Signup'] = signupVal;

    setCampaign(_campaign);
  };
}


function langDropdownOptionHandler(campaign, setCampaign) {
  return e => {
    let val = (e.target && e.target.value) || '';
    let langVal = val;
    let _campaign = { ...campaign };
    // if (val.length == 6) {
    //   if (_campaign['MultiLangId'].length == 5) {
    //     langVal = ["null"]
    //   } else {
    //     langVal = langVal.filter((val) => {
    //       return val != "null"
    //     });
    //   }
    // } else if (val.includes("null")) {
    //   langVal = ["null"];
    // }
    _campaign['MultiLangId'] = langVal;

    setCampaign(_campaign);
  };
}

// function langDropdownOptionHandler(campaign, setCampaign) {
//   return e => {
//     let val = (e.target && e.target.value) || '';
//     let langVal = val;
//     let _campaign = { ...campaign };
//     if (val.length == 6) {
//       if (_campaign['MultiLangId'].length == 5) {
//         langVal = [5]
//       } else {
//         langVal = langVal.filter((val) => {
//           return val != 5
//         });
//       }
//     } else if (val.includes(5)) {
//       langVal = [5];
//     }
//     _campaign['MultiLangId'] = langVal;

//     setCampaign(_campaign);
//   };
// }

function progDropdownOptionHandler(campaign, setCampaign) {
  return e => {
    let val = (e.target && e.target.value) || '';
    console.log(val);
    // setSelectedPrograms(val);
    let _campaign = { ...campaign };
    _campaign["Programs"] = val
    setCampaign(_campaign)
  };
}

function orgDropdownOptionHandler(setOrgChosen, chosenOrg, setChosenOrg, campaign, setCampaign) {
  return e => {
    const val = (e.target && e.target.value) || '';
    // console.log(val);
    if (val.name === 'Loading...') {
      console.log("Invalid organization chosen");
    }
    else {
      let reformattedOrgObj = {
        OrgID: val.id,
        OrgName: val.name
      };
      setOrgChosen(true);
      // in the event that the same org was chosen, dont do the request for that org's courses.
      if (chosenOrg === val.id) {
        console.log("Same org id was chosen as last time");
      }
      else {
        setChosenOrg(val.id);
        // make request to api gateway
      }
      let _campaign = { ...campaign };
      _campaign['Org'] = reformattedOrgObj;

      setCampaign(_campaign);
    }
  };
}
