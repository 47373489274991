import React, { useState } from 'react';
import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';

const StatsTabPanel = (props) => {

  const [activeIndex, setActiveIndex] = useState(null)

  const determineCampaignError = (errCode) => {
    let returnReason;
    switch (errCode) {
      case "TABLE_NOT_ACTIVE":
        returnReason = "Table for the campaign was unavailable at that moment";
        break;
      case "TABLE_CREATION_FAILED":
        returnReason = "Table for the campaign failed to create.";
        break;
      case "UNEXPECTED_LAMBDA_ERR":
        returnReason = "Unexpected error occured in the lambda function";
        break;
      case "USERS_RETRIEVE_FAIL":
        returnReason = "Providers in the campaign coulnd't be retrieved";
        break;
      default:
        returnReason = "Undetermined error.";
        break;
    }

    return returnReason;
  };

  const onClick = (itemIndex) => {
      let _activeIndex = activeIndex ? [...activeIndex] : [];

      if (_activeIndex.length === 0) {
          _activeIndex.push(itemIndex);
      }
      else {
          const index = _activeIndex.indexOf(itemIndex);
          if (index === -1) {
              _activeIndex.push(itemIndex);
          }
          else {
              _activeIndex.splice(index, 1);
          }
      }

      setActiveIndex(_activeIndex);
  }

  return (
    <TabView scrollable>
      {props.stats.map((run, index) => {
        return (
          <TabPanel key={index} header={run.date}>
            <h5>
              Status:{" "}
              <b>
                {run.code === "CAMPAIGN_RUN_SUCCESS"
                  ? "Campaign run success"
                  : ( run.code === "NO_USERS_CAMPAIGN" ? "No users in this campaign!" : "Campaign run failed")}
              </b>
            </h5>
            {run.code === "CAMPAIGN_RUN_SUCCESS" ? (
              
              <div className="">
                <div className="p-d-flex p-jc-start p-pt-2 p-pb-4">
                    <Button icon={activeIndex && activeIndex.some((index) => index === 0) ? 'pi pi-minus' : 'pi pi-plus'} label="Pre-message delivery stats" onClick={() => onClick(0)} className="p-button-text" />
                    <Button icon={activeIndex && activeIndex.some((index) => index === 1) ? 'pi pi-minus' : 'pi pi-plus'} label="Twilio message delivery stats" onClick={() => onClick(1)} className="p-button-text p-ml-2" />
                </div>
                <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                  <AccordionTab header="Pre- Message delivery stats">
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Number of providers processed this run:{" "}
                        <b>{run.details["numOfUsers"]}</b>
                      </h5>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Message requests attempted:{" "}
                        <b>{run.details["msg_req_attempted"]}</b>
                      </h5>
                      <p>Number of message API requests that were sent to Twilio</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Message requests sent: <b>{run.details["msg_req_sent"]}</b>
                      </h5>
                      <p>Number of message API requests that were succussfully received by Twilio</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Message requests failed:{" "}
                        <b>{run.details["msg_req_failed"]}</b>
                      </h5>
                      <p>Number of message API requests that were not succussfully sent to Twilio</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Users add attempts:{" "}
                        <b>{run.details["user_add_attempts"]}</b>
                      </h5>
                      <p>Number of new providers that needed to be added to the campaign</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Users add failed: <b>{run.details["user_add_fail"]}</b>
                      </h5>
                      <p>New providers that failed to be added to the campaign</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Users add suceeded: <b>{run.details["user_add_success"]}</b>
                      </h5>
                      <p>New providers that were successfully added to the campaign</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        User query fail: <b>{run.details["user_query_fail"]}</b>
                      </h5>
                      <p>Providers that couldnt be found in the campaign table.</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Users skipped: <b>{run.details["user_skipped"]}</b>
                      </h5>
                      <p>Number of providers that have been skipped for this campaign run</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        User update attempts:{" "}
                        <b>{run.details["user_update_attempts"]}</b>
                      </h5>
                      <p>Number of providers that needed to be updated in the campaign table</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        User update failed: <b>{run.details["user_update_fail"]}</b>
                      </h5>
                      <p>Number of providers could not be updated in the campaign table</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        User update succeeded:{" "}
                        <b>{run.details["user_update_success"]}</b>
                      </h5>
                      <p>Number of providers that succesfully updated in the campaign table</p>
                    </div>
                  </AccordionTab>
                  <AccordionTab header="Twilio message delivery stats">
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Messages accepted <span><a href="https://www.twilio.com/docs/sms/api/message-resource#message-status-values">(Twilio)</a></span>:{" "}
                        <b>{run.details["msg_delivered"]}</b>
                      </h5>
                      <p>Twilio has received a API request to send a message. The initial stage of sending a message</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Messages delivered <span><a href="https://www.twilio.com/docs/sms/api/message-resource#message-status-values">(Twilio)</a></span>:{" "}
                        <b>{run.details["msg_queued"]}</b>
                      </h5>
                      <p>Twilio has received confirmation of message delivery from the upstream carrier, and, where available, the destination handset.</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Messages queued <span><a href="https://www.twilio.com/docs/sms/api/message-resource#message-status-values">(Twilio)</a></span>:{" "}
                        <b>{run.details["msg_accepted"]}</b>
                      </h5>
                      <p>The message is queued to be sent out.</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Messages scheduled <span><a href="https://www.twilio.com/docs/sms/api/message-resource#message-status-values">(Twilio)</a></span>:{" "}
                        <b>{run.details["msg_scheduled"]}</b>
                      </h5>
                      <p>The message is scheduled to be sent.</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Messages sending <span><a href="https://www.twilio.com/docs/sms/api/message-resource#message-status-values">(Twilio)</a></span>:{" "}
                        <b>{run.details["msg_sending"]}</b>
                      </h5>
                      <p>Twilio is in the process of dispatching the message to the nearest upstream carrier in the network.</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Messages sent <span><a href="https://www.twilio.com/docs/sms/api/message-resource#message-status-values">(Twilio)</a></span>: <b>{run.details["msg_sent"]}</b>
                      </h5>
                      <p>The nearest upstream carrier accepted the message.</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Messages undelivered <span><a href="https://www.twilio.com/docs/sms/api/message-resource#message-status-values">(Twilio)</a></span>:{" "}
                        <b>{run.details["msg_undelivered"]}</b>
                      </h5>
                      <p>Twilio has received a delivery receipt indicating that the message was not delivered due to reasons such as content filtering</p>
                    </div>
                    <Divider/>
                    <div className="p-mx-auto p-my-auto">
                      <h5>
                        Messages failed <span><a href="https://www.twilio.com/docs/sms/api/message-resource#message-status-values">(Twilio)</a></span>:{" "}
                        <b>{run.details["msg_failed"]}</b>
                      </h5>
                      <p>The message could not be sent by Twilio</p>
                    </div>
                  </AccordionTab>
                </Accordion>
               
              </div>
            ) : (
              <div>
                <p>
                  Reason: <b>{determineCampaignError(run.code)}</b>
                </p>
                <p>
                  Details:{" "}
                  <b>{determineCampaignError(JSON.stringify(run.details))}</b>
                </p>
              </div>
            )}
          </TabPanel>
        );
      })}
    </TabView>
  );
};

export default StatsTabPanel;
