import React, { Component } from 'react'
import {Navbar, Nav, Button, Link} from 'react-bootstrap';
import logo from '../nevvon.png';
import axios from "axios";
const config = require("../config.json");



export default function Navibar({onStatusChange}) {


  // static contextTypes = {
  //   router: PropTypes.object,
  // }

  const DEBUG_MODE = config.DEBUG_MODE;

  const signOut = async () => {
    var axiosConfig = {
      method: 'get',
      url: `${config.authApi.invokeUrl}/login?signOut=true`,
      withCredentials: true,
      headers: { 
        'x-api-key': config.api.apiKey
      }
    };
    
    await axios(axiosConfig)
    .then(function (response) {
      // console.log(response);
      if(response.data.wereCookiesDeleted){
        console.log("Deleting cookies...");
        console.log("Signing out...");
        console.log("Redirecting back to login page...");

        localStorage.removeItem("userToken");
        onStatusChange('NO_SESSION_EXIST');
        // redirect to the homepage
        // this.props.history.push("/login");
        // this.props.history.pushState("/");
        // this.context.router.history.push("/");
        // history.push("/");
        // location.reload();
      }
      else {
        console.log("Error signing out!");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
    
  }


  return (
    <>
    <Navbar sticky="top" bg="light">
      <Navbar.Brand >
        <img src={logo} width="200px" height="auto" alt="Nevvon Logo" />
      </Navbar.Brand>
      <Nav className="mr-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/campaigns">Campaigns</Nav.Link>
        <Nav.Link href="/execution-logs">Logs</Nav.Link>
      </Nav>
      <h4>Hello { (!DEBUG_MODE) ? JSON.parse(localStorage.getItem("userToken")).user : ""} &nbsp;</h4>
      <Button 
      // href="/" 
      onClick={() => {
        // localStorage.removeItem("userToken");
        // console.log("token from local storage 'removed'");
        signOut();
      }} variant="danger" size="lg">
        Sign out
      </Button>
    </Navbar>
    
    </>
  )
}
