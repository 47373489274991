import axios from "axios";
import { dummyCampaigns } from "../dummyCampaigns";
import {emptyCampaign, columns, msgFrequencyDays, msgSignup, msgDaysBefore, msgLangIds, templateMsgDetailOptions} from "../campaignObjects"
import { arrow } from "@popperjs/core";
const config = require("../../config.json");

export const campaignDeletedStates  = [
  {name: 'All', value: null},
  {name: 'Yes', value: true},
  {name: 'No', value: false},
]

export const campaignStates = [
  {name: "All", value: null},
  {name: "Yes", value: true},
  {name:"No", value: false},
];

export const filterDate = (value, filter) => {
  if(filter){
    return value !== 'false';
  }
  else if (!filter){
    return value === 'false';
  }
}

export const progressConverter = (rowData, item) => {
  // console.log(rowData);
  // console.log(item);
  let progressString = `${rowData.MinProgress}% - ${rowData.MaxProgress}% progress.`;
  return progressString;
}

export const timeToStr = (rowData, item) => {
  let timeFormatOptions = { timeZone: 'EST', hour12: true, hour: '2-digit', minute:'2-digit' };
  if(rowData.Time){
    let date = new Date(rowData.Time)
    return date.toLocaleTimeString('en-US', timeFormatOptions)
  }
  else {
    return "No time specified"
  }
}

export const programIdsToStr = (rowData, item) => {
  // if the number of programs in programs arr is more than one, show as multiple
  if(rowData.Programs) {
    if(rowData.Programs.length > 1) {
      return "Multiple"
    }
    else {
      return rowData.Programs[0].id
    }
  }
  else {
    return rowData.ProgramID
  }
}

export const programNamesParser = (rowData, item) => {
  // if the number of programs in programs arr is more than one, show as multiple
  if(rowData.Programs) {
    if(rowData.Programs.length > 1) {
      return "Multiple"
    }
    else {
      return rowData.Programs[0].name
    }
  }
  else {
    return rowData.ProgramName
  }
}

export const campaignDeletedStateTemplate = (option) => {
  return(
    <div>
      <p>{option.name}</p>
    </div>
  );
}

export const campaignStateTemplate = (option) => {
  // console.log(option);
  return(
    <div>
      <p>{option.name}</p>
    </div>
  );
}



export const selectedFreqTemplate = (options, props) => {
  if(props.value === 0) {
      return(
          <span>
              {props.placeholder}
          </span>
      );
  }
  else {
      return (
          <div className="country-item country-item-value">
              <div>{props.value > 1 ? `${props.value} days` : `${props.value} day`}</div>
          </div>
      );
  }
}

export const freqOptionTemplate = (option)=> {
  return (
      <div >
          <div>{option.itemText}</div>
      </div>
  );
}

export const selectedSignupTemplate = (options, props) => {
  if(props.value === 0 || options == null) {
      return(
          <span>
              {props.placeholder}
          </span>
      );
  }
  else {
      return (
          <div className="country-item country-item-value">
	      {console.log("props", props)}
	      {console.log("options", options)}
              <div>{options.itemText}</div>
          </div>
      );
  }
}

export const signupOptionTemplate = (option)=> {
  return (
      <div >
          <div>{option.itemText}</div>
      </div>
  );
}

export const langOptionTemplate = (option)=> {
  return (
      <div >
          <div>{option.itemText}</div>
      </div>
  );
}

export const selectedProgTemplate = (options, props) => {
  if(options) {
    return(
      <div>
        {/* <div>{(`${campaignObj.Programs.length} items selected`)}</div> */}
        <div>{options.name}</div>
      </div>
    )
  }
  // else if(campaignObj.Program) {
  //   let tempProgramsArr =  [{
  //     id: campaignObj.Program.ProgramID,
  //     name: campaignObj.Program.ProgramName
  //   }];
  //   return(
  //     <div>
  //       <div>{(`${tempProgramsArr.length} items selected`)}</div>
  //     </div>
  //   )
  // }
  // else if(props) {
  //   console.log(props)
  // }
  // if(props.value.ProgramName === "none") {
  //     return(
  //         <span>
  //             {props.placeholder}
  //         </span>
  //     );
  // }
  // else {
  //     return (
  //         <div>
  //             <div>{props.value.ProgramName}</div>
  //         </div>
  //     );
  // }
}

export const progOptionTemplate = (option)=> {
  return (
      <div>
          <div>{option.id}</div>
          <div>&nbsp;{option.name}</div>
      </div>
  );
}

export const selectedOrgTemplate = (option, props) => {
// console.log(props.value);
  if(props.value.OrgName === ""){
      return (
          <span>
              {props.placeholder}
          </span>
      );
  }else {
      return (
          <div>
              <div>{props.value.OrgID}</div>
              <div>&nbsp;{props.value.OrgName}</div>
          </div>            
      );
  }
}

// works
export const orgOptionTemplate = (option) => {
  return (
      <div className="country-item">
          <div>{option.id}</div>
          <div>&nbsp;{option.name}</div>
      </div>
  );
}

export const languageIdentifier = (rowData, item) => {
  if(rowData.MultiLangId){
    let langFilterResults = msgLangIds.filter((lang) => {
      return lang.value === rowData.MultiLangId;
    });

    // console.log(langFilterResults)

    if(langFilterResults.length > 0) {
      return langFilterResults[0].itemText;
    }
    else{
      return "N/A"; // not specified in the msgLangIds array
    }
  }
  else if(rowData.MultiLangId === null) {
    return "None" // the MultiLangId attribute is "null"
  }
  else {
    return "N/A" // if its undefined
  }
  
}

// TODO: uncomment org id
// export const isFormFilled = (campaign) => {
//   return (campaign.CampaignID === (null || '') ||
//               campaign.CampaignName === '' ||
//               campaign.OrgID === '' ||
//               campaign.FrequencyInDays === 0 ||
//               campaign.MultiMsg === '' ||
//               // campaign.CampaignUrl === '' ||
//               campaign.DaysOfWk === '')
// }

//DUPLICATE FOR BACKUP
export const isFormFilled = (campaign) => {
  console.log("UTILITY TEST: " + campaign);
  return (campaign.CampaignID === (null || '') ||
              campaign.CampaignName === '' ||
              campaign.Org.OrgID === '' ||
              campaign.FrequencyInDays === 0 ||
              campaign.MultiMsg === '' ||
              // campaign.CampaignUrl === '' ||
              (campaign.DaysOfWk.sun ||
                  campaign.DaysOfWk.mon ||
                  campaign.DaysOfWk.tue ||
                  campaign.DaysOfWk.wed ||
                  campaign.DaysOfWk.thurs ||
                  campaign.DaysOfWk.fri ||
                  campaign.DaysOfWk.sat) === false)
}

export const booleanChecker = (rowData, item) => {
  if (typeof rowData[item.field] === "boolean") {
    return rowData[item.field] ? "Yes" : "No";
  } else {
    return rowData[item.field];
  }
};   


export const dateConverter = (rowData, item) => {
  let campaignDeleted = rowData[item.field].toString();
  if((!(campaignDeleted === (false || "false")))) {
    // console.log()
    let date = new Date(rowData[item.field]);
    return date.toString();
  } else if (campaignDeleted === (false || "false")){
    return "No"
  }
}

// used to generate the campaignId for a new campaign
export const campaignIdGen = (campaigns) => {
  let campaignIdNumArr =[];
  let campaignIdSplitArr;
  campaigns.forEach(campaign => {
    // splits the campaign id to an array (eg. "C181" => ["C", "181"])
    campaignIdSplitArr = campaign.CampaignID.split(/(\d+)/); 
    campaignIdNumArr.push(parseInt(campaignIdSplitArr[1]));
  })
  console.log(campaignIdNumArr);
  // find the biggest number in campaignIdNumArr and use that plus 1 for the new campaign's id
  var largest = 0;
  for (var i =0; i< campaignIdNumArr.length; i++){
    if(largest < campaignIdNumArr[i]) {
      largest = campaignIdNumArr[i]
    }
  }
  console.log(largest)
  let idStr = "C" + (largest + 1);
  return idStr;
};


export const daysConverter = (rowData, item) => {
  // console.log(item.field);
  var matches = rowData[item.field].match(/\d+/g);
  // console.log(matches);
  let daysArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let daysArrStr = "";
  matches.forEach((idx) => {
    daysArrStr = daysArrStr + " " + daysArr[idx];
  });
  return daysArrStr;
} 

export const findIndexById = (id, campaigns) => {
  let index = -1;
  for (let i = 0; i < campaigns.length; i++) {
      if (campaigns[i].CampaignID === id) {
          index = i;
          break;
      }
  }

  return index;
}


export const onInputChange = (e, name, campaign) => {
  const val = (e.target && e.target.value) || '';
  let _campaign = {...campaign};
  _campaign[`${name}`] = val;
  return _campaign;
}

//New language tab edit using onMsgChange
export const onMsgChange = (e, name, campaign) => {
  const val = (e.target && e.target.value) || '';
  let _campaign = {...campaign};
  _campaign.MultiMsg[`${name}`] = val;
  return _campaign;
}


// TODO: fix api gateway 502 error
// old course id: 166, org id: 128
// const testCampaign = () => {
//   let encodedData = qs.stringify({
//     'From': '+18446861133',
//     'To': '+16479747780',
//     'Parameters': JSON.stringify({
//       "campaignDetails": JSON.stringify({
//         "campaignId": campaign.CampaignID,
//         "orgId": 123,  
//         "courseId": 642, 
//         "progress_min": campaign.MinProgress, 
//         "progress_max": campaign.MaxProgress, 
//         "msg_freq": campaign.FrequencyInDays,
//         "MultiLangId": campaign.MultiLangId, 
//         "campaignName" : campaign.CampaignName, 
//         "orgName": "fakeOrg", 
//         "campaignMsg": campaign.MultiMsg,
//         "courseName": "fakeCourse"
//       }),
//       "test_mode": true
//     })
//   });

//   var testingConfig = {
//     method: 'post',
//     url: 'https://studio.twilio.com/v2/Flows/FWdaf01b6abbe2a3c04da68a5d0c808340/Executions',
//     headers: { 
//       'Authorization': 'Basic QUNhOWY2MzQyNDFmZDhkYTdkZTUzNjRjM2EyN2EyNjI3ZTo0MzIzMTU1NDY5OTE0ZTJiOWI2NGVkZTY4MTdiZTA3Yg==', 
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     data : encodedData
//   };

//   axios(testingConfig)
//   .then( response => {
//     console.log(response.data);
//     setErrorMsg(JSON.stringify(response.data.sid));
//     setErrorOccured(true);
//   })
//   .catch( error => {
//     console.log(error);
//     setErrorMsg(error);
//     setErrorOccured(true);
//   });
// }
