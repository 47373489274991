import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { uid } from "rand-token";
import { Alert } from "react-bootstrap";
import { ProgressSpinner } from 'primereact/progressspinner';
import "./indexClone.css";
const config = require("../config.json");

export default function Login({ setToken, onStatusChange }) {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [processingLoginRequest, setProcessingLoginRequest] = useState(false);

  const loginUser = (credentials) => {
    const url = config.authApi.invokeUrl;

    const obfuscator = ( str ) => {
      return window.btoa(unescape(encodeURIComponent( str )));
    }
    
    const unobfuscator = ( str ) => {
      return decodeURIComponent(escape(window.atob( str )));
    }

    const { userName, password } = credentials;

    var data = JSON.stringify({
      username: userName,
      password: password,
    });

    var axiosConfig = {
      method: "post",
      url: url + "/login",
      withCredentials: true,
      // credentials: 'include',
      // origin: "http://127.0.0.1:3000",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": config.api.apiKey,  
      },
      data: data,
    };

    axios(axiosConfig)
      .then((response) => {
        // console.log(response);
        if (response.data.returnStatement === "This user does not exist") {
          console.log("Sorry, that account doesnt exist");
          setErrorMsg("Email or password was incorrect");
          setProcessingLoginRequest(false);
          setErrorOccured(true);
        } else {
          const responseToken = response.data.seshToken;
          // const seshId = response.data.sId;
          if (responseToken === "REAL_TOKEN") {
            // let sessionCookie = unobfuscator(response.data.body.sessionId);
            // let sessionCookieObj = JSON.parse(sessionCookie);
            // console.log(sessionCookieObj);
            // document.cookie =`sessionId=${sessionCookieObj.sessionId}; Expires=${sessionCookieObj.Expires};  Path=/; SameSite=${sessionCookieObj.SameSite}; HttpOnly;`;
            // let tokenRandStr =  uid(16);
            let userToken = {
              token: responseToken,
              user: response.data.sessionIdOwner,
            }       
            setToken(userToken);
            console.log("REAL TOKEN set");
            onStatusChange('SESSION_EXIST');
            setProcessingLoginRequest(false);
          } else {
            console.log("it's a fake token");
            if(response.data.returnStatement === "User has been found but wrong password"){
                console.log(response.data.returnStatement);
                setErrorMsg("Email or password was incorrect");
                setErrorOccured(true);
                setProcessingLoginRequest(false);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        return JSON.stringify(error);
      });
  };

  const handleSubmitCredentials = async (e) => {
    e.preventDefault();
    setProcessingLoginRequest(true);
    await loginUser({
      userName,
      password,
    });
  };

  return (
    <div className="container">
      <Alert variant="danger" show={errorOccured} onClose={() => setErrorOccured(false)} dismissible>
        <Alert.Heading>{errorMsg}</Alert.Heading>
      </Alert>
      <div className="row">
        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div className="card card-signin my-5">
            <div className="card-body">
              <h1 className="card-title text-center ">Please Log In</h1>
              <form onSubmit={handleSubmitCredentials} className="form-signin">
                <div className="form-label-group">
                  <div className="form-group">
                    <label>
                      <p className="text-muted">Email</p>
                      <input
                        type="email"
                        className="form-control"
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="form-label-group">
                  <div className="form-group">
                    <label>
                      <p className="text-muted">Password</p>
                      <input
                        type="password"
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-info btn-block text-uppercase"
                    disabled={
                      (userName || password) === undefined ||
                      (userName || password) === "" ||
                      userName === undefined ||
                      password === "" ||
                      userName === "" ||
                      password === undefined ||
                      processingLoginRequest
                    }
                  >
                    Log in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {processingLoginRequest? <ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="4" fill="#FFFFFF"/> : <br/>}          
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
