const getAllCampaignsResponse =  
{
    "campaigns": [
        {
            "MaxProgress": 99,
            "ProgramID": 509,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T18:43:40.578Z",
            "PresetMsg": {
                "null":"REMINDER! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
                },
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:00:44.914Z",
            "CampaignName": "Elite NC2 Class 2 2021 English None",
            "LangId": ["null"],
            "FrequencyInDays": 5,
            "CampaignID": "C52",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NC2- Class 2 2021 English",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true,
            "Programs": [ 
                {"id": 509, "name": "NC2- Class 2 2021 English"}, 
                {"id": 510, "name": "NC2- Clsdasass 2 2021 Spanish"} 
            ]
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 285,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:33:12.135Z",
            "OrgName": "BrightStar Care of the North Hills",
            "MultiMsg" : {
                "149": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
                "41": "Reminder! This is a warning!",
                //"default" : "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP."
            },
            "PresetMsg" : "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP.",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 4 - Experienced Aides - Spanish",
            "FrequencyInDays": 4,
            "MultiLangId": [149,41],
            "LangId": 149,
            "CampaignID": "C491",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 4- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 100,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 6,
                        "msg_req_sent": 6,
                        "msg_scheduled": 0,
                        "user_add_success": 6,
                        "user_add_attempts": 6,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNbd1e3487e384d00ddfe73603f6e06f10",
                            "FNc08947f33ed64d51aa69f5f6596d9e5b",
                            "FN3ac68cee95137ec8af93904146815c05",
                            "FNd6f5fabe74fe6d6b90355340f0d559c6",
                            "FN47eceddc91f78e3e006ee84244521a55",
                            "FN82653ed1d40b4c7162048148c92d7a6e"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 6,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 481,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:24:02.021Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 2 Spanish 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C408",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 2 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 317,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:22:28.667Z",
            "PresetMsg":  {
                "56": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
                "41": "Reminder! This is a warning!"
            },
            "OrgName": "Assisting Hands Orlando",
            "campTime": "2021-12-09T17:00:15.615Z",
            "CampaignName": "Thriving At Home Certification - Haitian Creole",
            "LangId": [56],
            "FrequencyInDays": 1,
            "CampaignID": "C266",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Thriving At Home Certification",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 248,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 477,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:11:39.859Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:44.461Z",
            "CampaignName": "SI Homecare- PCA 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 5,
            "CampaignID": "C595",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 719,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:11:09.803Z",
            "PresetMsg": "REMINDER! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:38.678Z",
            "CampaignName": "Greater Love In-service2 2021 English",
            "LangId": 41,
            "FrequencyInDays": 1,
            "CampaignID": "C186",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 290,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:37:19.789Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 1 - Inexperienced Aides - Haitian Creole",
            "FrequencyInDays": 4,
            "LangId": 56,
            "CampaignID": "C495",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1-  Inexperienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 522,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T22:58:44.281Z",
            "PresetMsg": "RECORDATORIO! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T16:58:01.947Z",
            "CampaignName": "Sophie Q3 Patient ES 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C287",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q3 Patient Rights ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 2,
                        "msg_req_sent": 2,
                        "msg_scheduled": 0,
                        "user_add_success": 2,
                        "user_add_attempts": 2,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN7830bbd4ad4bf598e3816ab096d19d42",
                            "FN33e009361b58f7306ad9bdd3568112fe"
                        ],
                        "msg_queued": 2,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 2,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 678,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:59:28.683Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:38.954Z",
            "CampaignName": "2 Hours - In-Service Program - Haitian Creole",
            "FrequencyInDays": 3,
            "LangId": 56,
            "CampaignID": "C426",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 861,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:14:29.106Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Orlando",
            "campTime": "2021-12-09T17:00:42.925Z",
            "CampaignName": "Assisting Hands- Orlando - Program 1 - Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C261",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands - Orlando - Program 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 248,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 624,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:18:41.321Z",
            "PresetMsg": "\"URGENT From FADMO:\nComplete ALL In-Service ASAP. All training is due by 12/31/2021. Get started: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:10.400Z",
            "CampaignName": "FADMO Q2 In-service EN 2021 None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C135",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 13,
                        "msg_req_sent": 13,
                        "msg_scheduled": 0,
                        "user_add_success": 13,
                        "user_add_attempts": 13,
                        "msg_delivered": 5,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNdf0e461686646ccc8edeb785aa634ea6",
                            "FNcd9968da2a86259faa01c37a023b9d51",
                            "FN983d7c8e0f95943ffaaa715fb1652c48",
                            "FNa41823957d7fdb57f2ef23bd09d635c2",
                            "FN49381b06926d66c8059df449140c2d07",
                            "FN956eea7eddb6cea037a1419b106356e5",
                            "FN581e3ebc0c9dbef3c5edd7b60be120d9",
                            "FNeb28a6df776162520975ddccf66d369f",
                            "FNd58541bf1c2b69fd14b512c8571a24e4",
                            "FNeed089a13a962c001f5692d9f2d240aa",
                            "FN74bbd2899d9fa7051dae60fbe5526622",
                            "FNccb3e66fb8f539be1ec1c71906793c32",
                            "FNa7adce9c38aa6249caac8bac82505e4c"
                        ],
                        "msg_queued": 5,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 13,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 532,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:29:03.651Z",
            "OrgName": "Bryan Skilled Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Bryan Skilled Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Bryan Skilled Home Care - Aid Mandatory 1 ES 2021 - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C372",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T15:37:15.907Z",
            "ProgramName": "Bryan Skilled Home Care- Aide Mandatory 1 ES 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 87
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 42,
                        "msg_req_sent": 42,
                        "msg_scheduled": 0,
                        "user_add_success": 42,
                        "user_add_attempts": 42,
                        "msg_delivered": 17,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN1b92d51b7125bc9c414ca4f3123db513",
                            "FN7cd1a4ecde8dc754109d92516a1a8dca",
                            "FN909cdb9f4404b60d4b3118b2ac00b1f5",
                            "FNf0e7e1f5d5066d212a8d0b1b17c0e515",
                            "FN349e01a8307400f6d833d742d31dcc84",
                            "FN5baf766379619ed21007bd275960aaf2",
                            "FNaca30e240374d61a41a498e16cf87f55",
                            "FN7385e5fa9fc989939f70181692932ecc",
                            "FN3f78d6597d4569b7aaf6a7b344959948",
                            "FN32da7285a121d1ed71ddaf91642d5597",
                            "FN3fef816081010e453e8a699a2e6a5a8e",
                            "FN701e2080dcb871699ac47b7bdf8a402c",
                            "FN921aefe910a18b700319946add1bc45a",
                            "FNc396c6836a38ca667cf8c8d294f81dff",
                            "FN1fb052b3bf41c52555030b6b21f14964",
                            "FN6334c11a3d554a652ecbd35a00b57d77",
                            "FN22922137c3acc8fb52e176fb2fd96be3",
                            "FNb083971e2309eac59e806ca362b88484",
                            "FNaa6f1170d7988cd37df7cb141f6fb82f",
                            "FN9f4e464f85748f607c5494eacb0dc639",
                            "FN0168b77d1b851786010ab5b964e18c60",
                            "FN366eb105b76f16de2f20b2e0a68d5649",
                            "FN163bd9728e4ee9a86b17dbc696115e33",
                            "FNab29d0f27fcde6f409795f22dea218c1",
                            "FNb4c37e5cf2f5cbfc1421cfcfc16e474d",
                            "FNbcc155a5c34978a98b80963706aa481f",
                            "FNf2cc9c5e76f36b0613e2ea47f4244588",
                            "FNfe1bb97967e8f383f27c94d49c64d463",
                            "FNff0c2b6784c16001f905955553d961c9",
                            "FN16e62727b8d30fd48ae9e836de3b5e34",
                            "FN6d2d3f62d83436c014134479ef80a3bc",
                            "FN7b9d2ab6e03d4fbad66c8f9f0739c824",
                            "FNf756f69560d51681360e8964bdf2fda9",
                            "FN4c7c48ef097ac837e8c40c9762b83647",
                            "FN24315cdc82126c8018d2fe444f37e3e7",
                            "FNd1d89a9ea96b5037e01180a4b2f474be",
                            "FNbed546cd5f520dbb7f3876a7be5aa404",
                            "FN22d25000c3cb1956c04c5b09812f9132",
                            "FNe030a6bd6c9d84951c70f2ae7ad26343",
                            "FNc57a8fd65cb55c2bc1c23effe7f8b4ab",
                            "FNb980dc1db320c31b17c1a5e331cb0fe1",
                            "FN15798f72d9a68e226727ba938f1f4bef"
                        ],
                        "msg_queued": 20,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 5,
                        "numOfUsers": 42,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 531,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:25:10.743Z",
            "OrgName": "Bryan Skilled Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Bryan Skilled Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Bryan Skilled Home Care - Aid Mandatory 1 EN 2021 - English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C370",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T15:27:42.387Z",
            "ProgramName": "Bryan Skilled Home Care- Aide Mandatory 1 EN 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 87
        },
        {
            "MaxProgress": 99,
            "ProgramID": 624,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:21:05.650Z",
            "PresetMsg": "НАПОМИНАНИЕ! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:25.777Z",
            "CampaignName": "FADMO Q2 In-service EN 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C138",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 506,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T17:32:18.926Z",
            "PresetMsg": "RECORDATORIO! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:15:17.673Z",
            "CampaignName": "Elite NCM Class 1 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 5,
            "CampaignID": "C44",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NCM- Mandatories Class 1 2021 Spanish",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 8,
                        "msg_req_sent": 8,
                        "msg_scheduled": 0,
                        "user_add_success": 8,
                        "user_add_attempts": 8,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN83c6e55aca8b08a2ebf28a7bc531208f",
                            "FN444800d8c3a40eb15a1bf3e564b3e7e9",
                            "FN3bc7fe756e455f6aef795b0911ae7e7f",
                            "FN7c51824bc11fd051a30f147f75004b9b",
                            "FN70e88666338eeabffe4120d125200f4a",
                            "FNaaa38b52c340b9dde13063cc37c7f471",
                            "FNe8fedcc0789262ec317cea7e682fd333",
                            "FNbdef19b99e882d1c3f00edec2b0d83a1"
                        ],
                        "msg_queued": 8,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 8,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 735,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:25:31.690Z",
            "OrgName": "Gurwin Homecare Agency",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Gurwin Homecare Agency: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Part 2 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C447",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Part 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 107
        },
        {
            "MaxProgress": 99,
            "ProgramID": 467,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:48:38.724Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:25.365Z",
            "CampaignName": "JFS Care- Mandatory 2021 - RUS",
            "LangId": 134,
            "FrequencyInDays": 5,
            "CampaignID": "C548",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Mandatory 2021- RUS",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 921,
                        "msg_req_sent": 921,
                        "msg_scheduled": 0,
                        "user_add_success": 921,
                        "user_add_attempts": 921,
                        "msg_delivered": 743,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN70332f17c9ef12aa091c311a3c06f424",
                            "FN689435cc7be9bbf71744773c75586f03",
                            "FN53bf06de5dbb62327942c942d39491a6",
                            "FNad661b006931cc7c9c72d3301d938d2b",
                            "FNdf4455dabab722f634e055436c7a2467",
                            "FNd5d5d063429936f1a56e11d91040cd86",
                            "FN98f629bd195f82da693e5a3b44eb8f75",
                            "FNf51d2eaf63ce40af0dc21c4f4fbc789b",
                            "FNe25f2eb6319171637f43a0d3135f17f1",
                            "FN399197ca27abd14ae6f1fcd966dc021b",
                            "FN73d0070b6e8429b1bf75cc6b90b9f5d5",
                            "FN9d30e33a44890a884c26ddd1e801191a",
                            "FNe2d93518ec86494e8d951f1049b7c2a2",
                            "FN0b7493d26551afa935b45027852e2e36",
                            "FNd26639770e1c3e13bc833e226789004a",
                            "FNb4f63979b3599adb789f790851700471",
                            "FNac55cb59cb6217642bd42e20cae9cb85",
                            "FN72e6ca63b3296ac422677e23ec4df433",
                            "FN4e4ea630ec9a3181b2952938d53011c6",
                            "FN8bbedce131e825f7fa38b654c531f5c4",
                            "FN7b8375468253eda013a64f311dc83643",
                            "FN2b62bc5ebad4d48d78987b66a37cace9",
                            "FN8979f20129af8d8652385b027f587027",
                            "FN761e1510772c3c32a813eb6923305714",
                            "FN74be33cb6e2c5f53c88e734319fe59d9",
                            "FNb917ff1f4da6eaf2f2d5844fe6fcbfa1",
                            "FN8cad74bbbf9c6d31f2b1f7dbb98973ca",
                            "FN08d404db9fb18c934712834dcc845626",
                            "FNbdc3d9b3033b75996d45cbf18575c69f",
                            "FNce9f497f0fd8be02003b50c0d2fd4d62",
                            "FN205b0061f8e6dff59d6e5288da724386",
                            "FN6069a01c23ef8d062478bba03040eb18",
                            "FNc3deb9107b1ece29ebe371c3a2b495a5",
                            "FNbf8c222b9874cb665c9dfe922996d5e8",
                            "FN96176ad23ea8b3c0503157b1aa5c543d",
                            "FN4a15c209ba6547a0822c424f850b651e",
                            "FNf1a7ff45ad5174add634d0dc066e8280",
                            "FNab0940492f1daa70d883fbf9fc7ade1d",
                            "FNbeddc6595e42fc763c9006b07bab3f68",
                            "FN715c248b9f86248beab8ef66e7046883",
                            "FNbee425b8b8683abf7560b88704560a2c",
                            "FN9b36e0021f88450a85a43c8d975307f8",
                            "FN6efa695c61b7d4145078105fc50fee48",
                            "FNaf33862a294ab75b5a1167f62b8abcc1",
                            "FN5a1efa0cd7fc9b2542cae724f0d5f7c7",
                            "FNe120e7a5e0519f0532588cb769f2f905",
                            "FNfb078f46fb0beb0380668fbf2edab85d",
                            "FN222d3abdf2136593ca7547e339cdb29b",
                            "FN2e527d68fbd0f97f696a096bf368a511",
                            "FNb776140700c711ba563a26c2833f9e60",
                            "FNea84441428aa7cdac097e230b2b5f13d",
                            "FN7a316fe3f2e760ddb9ed4109a4cc49be",
                            "FN6e8abaac0e2bc571add8de8dad3d74b3",
                            "FNf8176c02665f21cffddbeb0af99327e7",
                            "FN862df26e98a3b4df778d805d88184978",
                            "FNa54ea5cdc74a29610e3e59125659909f",
                            "FNdae23a5f3907c2ea014e37c36f0d2ddd",
                            "FN2da5519be27d797f4302a1f5dc0405c2",
                            "FN420d53f9f9fffb505a99451a77292a2f",
                            "FNc014c1eb2daa2c066f56b261a24e931f",
                            "FN67266a2c57266e1d799a71805f48dd1a",
                            "FNbfc15a1c771863a2efb3370d91fb9d14",
                            "FN1960d806dc0d14e876466207ad1694be",
                            "FN3640313cfccd3dde4617d9fbd13ef997",
                            "FN8d0596add7ca6366e7812a4bddeb1695",
                            "FN626acc4e16c341a87c71b1a667676445",
                            "FN5e81806825b25890fe3574ef709db330",
                            "FN5f4f0bcc1a23c277ff96a7fb93fe1c5d",
                            "FN5b2709da61956b0850e0325703b6a5f6",
                            "FN39af007be9e32afd3c3ffaa9c55d4d3f",
                            "FNbf8d8cf7b7769b5cf4d6769675cdaa32",
                            "FN725c59f6354cc94e3e880579827c51ab",
                            "FNeb94b1804c58fedb75bfe38ea5e333b5",
                            "FNafb817a1975a314efae43aa16cac03c5",
                            "FN0bd0cb10f3623fbb2c40026d02a737e5",
                            "FNd452a8724a6b1de1e4504a7d8a269dc2",
                            "FNbaa1098e1cbcd83b5ded0d072bfa774d",
                            "FN7b3dee5aa63a844d67a88c1d8b6cdb25",
                            "FN1560489c68bc3a6c59d2a3c20f3f365b",
                            "FNf656c1379479b88a418e672e1fb8d8d7",
                            "FNa0b8ded162b76c04d80a7c332e753e5a",
                            "FNf6424ba4f46973c8e6d2a128ff0df38c",
                            "FNe22a1d690490a03687e66e3191a393f4",
                            "FN2265634557a85ad1b434f5674171af51",
                            "FNad20eaeec42891fa68a44a84be360daa",
                            "FN99a2d877c8a93a7447afb5d291743959",
                            "FN60881bb720d1fb9fd7010fcd5a0df504",
                            "FN689b91f5d3d1fd2e5d98e9265e49fe60",
                            "FNa902914c1c3002e41760da7d5f4f5fb2",
                            "FN2f29e15b8b632a618bf4cf5d65f98a42",
                            "FN66ca06613928efe91d1e96fdda17036d",
                            "FNb6c594b2e469e361f0568b993bc9095d",
                            "FN98421b346ce55d6de922b5712bbb3a4d",
                            "FNa060d466a7b4d026faf5cff1ad28561f",
                            "FNfdb68b2cac2bbbcdcba92d6cfa6f94d8",
                            "FN90b0487f0c351b6af7f3dfee84df8648",
                            "FN3c62f982774799d1c5706cc50e5eaff7",
                            "FN0a277832225fd8f950c03e52287638fa",
                            "FN06690068ad7c0c1fdc1eb2e1166087e7",
                            "FN23cfcc5f004c756233ee4162c7496946",
                            "FN434f6ea7202e8205795056994a427359",
                            "FN919c1fd92ec37399a5800ddf025a6d73",
                            "FN887aba2936185cff51ff44e6f88b2358",
                            "FNa3f580191beaed5b4c5dfc05e7a25b34",
                            "FN101ef9bbfe21448ee0d61837cdb82c7f",
                            "FNf54fba1bc4be14274b8a44bd600db4d6",
                            "FNedccf011ba6c2caf4c209170f9566b93",
                            "FNef58787233e64825a451037d912a8e22",
                            "FN07ad81086d9fb5986001191b31820b42",
                            "FN7b2fa570462d80eb8fb0d1d6da335872",
                            "FNa444e9a85497ace3ad4eec928d822f42",
                            "FN2d42a003a77406a27183710cbaf448c1",
                            "FNfb932e4bc8b25747549f2ef1f01ce52d",
                            "FN75ffd3aac4a8d84dd510d3cdbe18a2d8",
                            "FNed9d81c6f4c11c2b816943a16625502b",
                            "FNbf79c6038e142e7166c509bc0eaefcdc",
                            "FNdaecbabbe7b0ab39d280850a30a2c33a",
                            "FNf82ede240fcd60d6711d6d31e050e694",
                            "FN403ef7bc3150aabc4e60c56091654987",
                            "FN603e4590bc8cd0b6409107a364a652fc",
                            "FN2571f20f3380c606207d3fe9c2e3ff8a",
                            "FNca4a6d4e51d6f989220283a7b0ab57b8",
                            "FN59ddf1855209c3a1920f18682ffe3ba8",
                            "FNae0b4f9a67d4b23ebc341b5bfafe11cd",
                            "FN9f2d5661ec198592d100aeb5209321d4",
                            "FNcec17f9a5d37cc20b2e694384bdf6771",
                            "FNbc4f6544c3e16a83d2e24e21599000d1",
                            "FN4454075a7d9b644315ad9a25ccbfe07d",
                            "FN62bf61ed153b7f71baf3b1a85f664f0b",
                            "FN2b7d2c0e22f89023f6cb21c2b0221259",
                            "FN002d8c1576eaca70eebce1f6beec7387",
                            "FNd27d0943949a879f622527260a05b8af",
                            "FN33010cf903a90d4476321465e9b1444a",
                            "FN7b62983a05b7a8f47063945dbc929b39",
                            "FN37d3633d495c7cfaa91695cd54c58e7b",
                            "FN1a13e79d67e9bb3b63dbe242ff94e715",
                            "FNa068c1323a784fafabfb42b714d1e6ac",
                            "FNba1472fdb2f988a64069eb40b94bca30",
                            "FNc2e7bc4e222cc0048ecb825629aaa71c",
                            "FNc1ca7ddf77b05b310a7a33d184643c60",
                            "FNb3e800d970d161e9bec2966b0754a75b",
                            "FN68e4f51dd25dd88eb4d53d5875c85baf",
                            "FN8b637579540e4d1c59a0dc4017ad1c79",
                            "FNcec46844e923d7494724926950931890",
                            "FN2b3e3a07437b7eb2619e641431ff33c6",
                            "FN467fd9d78333955d9e7a978930fa24d0",
                            "FN93793585c3e4fcab8d46b9c20f714548",
                            "FNa4cd08fb86d28e86131f189c5ba87cbd",
                            "FN06f3c8a17f44231264bf983a3478f5e5",
                            "FN66e4f0cdbf0f29bf236f66dd7051148a",
                            "FNc368a196bc0f853b988e52a71efb3541",
                            "FN6256393524ed28702a1d76158f2221c5",
                            "FNd80ab3bec6b03f0d946683bc309123f2",
                            "FN5461ec4eec7931210a717f0429200b29",
                            "FN594c2cee8177740b4c4edd0efb852756",
                            "FN68a18df2d6bca804a71ce8b4e281287b",
                            "FN44e6cc9c764abbd158990e7a989d9825",
                            "FNfdbd3c86baf7511b0ec2a840e1f073ff",
                            "FNe2780b89cf99a344cbe27cd690d93368",
                            "FN7fc1bd0840bd6f03dbf29db4b5e7a3e5",
                            "FN1ffd4d76f89e8294a3587aa9b14cc6c6",
                            "FN31cde5055d6cf39145f1d9211b3139f2",
                            "FNafd0441f9fdf972cfa6ea039acb78077",
                            "FN148c6cb01509387407d892187c2cbb69",
                            "FNec703076206a3d5f4c8433ffa93fac6b",
                            "FNcab1fe4f6701312bee60bfa272f485e1",
                            "FN40b35c8ebf7c5cf5f0f1dacd3700c066",
                            "FN0a4b07d805eb2686f03f4ef92e5a50ff",
                            "FNba042b43f0fe7b4c7071024bdb29e566",
                            "FN562099253cbd07c4fc0ddaed403db009",
                            "FN2d190a7feba5a09a2dfc4e4fa2d5695b",
                            "FNbbfbd31ed724fdcf2b7cc899e943b085",
                            "FNad6dd722117134ff747d9134b062830e",
                            "FN91ae61a3528e835d57dd92c7edae3371",
                            "FNabc86b6e6d90e7090f9dcd700413244e",
                            "FNb90cd429c7d88a4202980254da9bcf9b",
                            "FNfb6996b4662d5be2120bac36d0db8436",
                            "FN1a70a4f281bcde3c08569888bf757098",
                            "FN01930ae47888be7d00ac74c7d1ca312b",
                            "FN310a70dc68910fcff0d5192277a204fc",
                            "FN153cc7e4036935628ee21839bbbe0b59",
                            "FN6ef01ba82adf408bf37cf2b9c25944b4",
                            "FN6a8c6da772f714a99b8b349eb6bc56e9",
                            "FN3f54d33dd9bce9b2954de39e1b92abae",
                            "FN26462582a8636b49a3d437af1e55e78f",
                            "FN7837a0060e582417b5b9965328bd6ffe",
                            "FN99628328613097571204f0e2c8da32b9",
                            "FN77a24bf92cac5e48161aac1df6c704da",
                            "FN8cf5b7955cc70665634414fe028f322f",
                            "FN4528f8e23fbd3ddfd67db54b612dda77",
                            "FN77314f5953c54e858347e68cc75a191e",
                            "FN383dd2ead0a6121f6aedbf480c2d4399",
                            "FN61c8fd1f1286a68b4c1d2241caef6173",
                            "FN4250551390c4843e4ebf378dd599ff8a",
                            "FNcce8d19f7b73bda8fded56510898dcc1",
                            "FNf766a8289a0c882ed6468cbdc66150dd",
                            "FN3e731db4bd6cbfb764c274bd2bada0b2",
                            "FN10c05fe6ebd43b2232d6629c1b724414",
                            "FN8ffd7f8aaae869026f4c3d156ac5f622",
                            "FN6ebf685c6e6665108e53d00abcca7935",
                            "FN48515025eb5501b369c772859c13470a",
                            "FN9b19fec77aafa7b880a818af70f13d44",
                            "FN76b349af251d3a9e8bd626d6a5982a51",
                            "FNbc0a853d04d0bc85757d16cf5901d387",
                            "FN352cb0248e87e03bfec98dfc74b306df",
                            "FN7aa082e3d98aa15b1b635826d04f3942",
                            "FN1757da4ac893f79b07fa3356784f4fc3",
                            "FNbcc69ee0f14cff7ce20740dab1319c09",
                            "FN6cff4b2a4c83f45eb311405c20c3ad76",
                            "FN312ffd713a258bd68c44ca2fb9d65fb0",
                            "FNc973d602dbf0aabdf470c2dcc2d4e80d",
                            "FN1d392f3f4166f38ffa8c8c07d72804e3",
                            "FN890b8bfdc7a0b6f4a6ab12e76e685d98",
                            "FN3b825361369d0588f6778681d93564d3",
                            "FNfd79638d1d3b9c5a0570a5a64cc95c18",
                            "FN0f69a63fca201e54dd1641b3c7e2af41",
                            "FN3a2a6c8cb31784134d420ea5d5d0d8fe",
                            "FNba5feb0a6bddf3c4c9aecccf1806ed7e",
                            "FN4f5ed9ddd4c746824ef56756e39d3b77",
                            "FN530a0e4754b231347bcc7928d2781c0d",
                            "FNfd2efd204b543d10955055d826cdb61d",
                            "FNcf24772eebdb38ca6eb506c97a1419a1",
                            "FNa8e254bfbc9e27a5977f438cb8fa7f25",
                            "FN5e08ecaf0d3f68023877863663d6db1c",
                            "FN8335c8bdb8b7734be60b195817c8bc3d",
                            "FN43febbc2f595613173284e1be8dbcf72",
                            "FNb3944de5594c4698de178ff178ace586",
                            "FN2367bbb1bd749d52a07c41a060d6fae7",
                            "FN6c4e8a21ba008399b4c83ffee89ef962",
                            "FNdb35a139dd98d3160a420cde4ac53c9f",
                            "FN3509a9de68712768509c2a2548e08796",
                            "FN26e03ecbcb9b9968b1f14d0700643a83",
                            "FN0e9b1aafdc9febc8cc89ed4866f75a58",
                            "FN0532e3f80e7a740a517ee77483875e15",
                            "FNeffecf8b506f4ef5487ccddfa2331b5e",
                            "FN56423723d811f22ad75de6e956b5ec51",
                            "FNab13c130c81be708dd5d53818afe97f4",
                            "FNb09bb1460e8d096bf94846e338d16ec8",
                            "FN7a9513034214639b3fab08601e36b529",
                            "FNee92e1f6a23141525fc3afb164153e6d",
                            "FN80a841f7ff34ba98e958c5a4005cfd62",
                            "FN73dce43d90c34f95d594e88c4d0d60f3",
                            "FN51bb7234d95ebf27b18c7feb32e3739c",
                            "FN50db6ffcab558cc8b4a8d9850d5b7267",
                            "FNc72a0d39afa17a97dc9661cf184d707b",
                            "FNe3e7539dc36f224627e245c3147ddc76",
                            "FN7bdd9e079b6db4c6f4a80f693a10ce98",
                            "FNb2edbc96fc36d9ccd5084e5d0dfb6c89",
                            "FN3aa7ae7e8fb26330c6deedec5477fc7d",
                            "FNe849f79a7b1b5eaf959a7edba8d3312e",
                            "FNdd23e4fd5f5874f8091658222fdef4ce",
                            "FN492153410f4768660139b342989f2f67",
                            "FN1b3b23ff64dff5f58aac1164bc3b2ac8",
                            "FN8bd6474774adfd86fe288682a5bcbe84",
                            "FN379327fd7bd57355ccabcf6d2c2f94f9",
                            "FNfb040e8b8a441e9c22dbf640b55f2116",
                            "FNcd5af6065bfc293c24d1e27d606c39c6",
                            "FNb7f995bc14b157914995620b30f23caa",
                            "FN1c263ed78719f390f4f2e25d659a0885",
                            "FNd3160d73ab90fa22b7f08db469da261d",
                            "FN9f3feb04f6cc678412f6e06671d4b139",
                            "FN1bb492b1f8bfa20526c6e72317e6863b",
                            "FN1b616b6150a3cd94bd77a8acbdbaadd8",
                            "FNfd48d5ac5dad2999ca364769f2902d51",
                            "FNd4d74d11ef82a2789ee87be8eb0871d0",
                            "FN2f8e2d1d89702470a749cd53e0cd43d5",
                            "FN5e578fe7a2a58de45cec094a59f786a0",
                            "FNe7100615322abc432c49040ca31442a6",
                            "FNf057ff0e048980f3178e6d9314a6feef",
                            "FN09777d7c994499c80160bf184f661f30",
                            "FN7cda38ed716e49fc8a074e5ebc23e513",
                            "FN16e7a2a1ee63f4201e37fa7a985c7692",
                            "FN21c2f1d527a55fd4f067a5505b5748e8",
                            "FN86a0b791e916d959ce455b7ae60042c1",
                            "FNd5ce8a45c490e17792ea22c77e865164",
                            "FNe55f3aad5bea67d1a54e61cc11decb65",
                            "FN51bd5569bb7517afdd1f65774c0c1682",
                            "FNc15b1d9f43aae00a04f97138f53243f5",
                            "FN5c61336ef3f48f5b9fa98a4c7eeef765",
                            "FN8c5bb516d6c1715264e49519b6310757",
                            "FN72196300ab5d16b8740814d4c9bec349",
                            "FNd5e0c856516c51ac5bfe5d71c79a4d4a",
                            "FN9f651f99dd2737d39317bb0cd70a9ba2",
                            "FNc1277db4617ca1ae075bcb6200b1496e",
                            "FNcb232306cae2d8737a0cb08c35ff2793",
                            "FN5c0532b1e66bc9ac88043f37c6da1fa0",
                            "FN856c7137dc079404ab3e5d14fb4a214b",
                            "FN3f8fd60cbcad37108b00856ab59a1724",
                            "FNd203dcc28801a29b36f16d279c75ff16",
                            "FN5d8db1e398d520387fadfeb50cdb82fb",
                            "FN7ad5fcabcb148868204f475a582ed442",
                            "FNde3766cfe4d475fe0d16842835e903c6",
                            "FNdd2f651f6e6afe5a8a7864c95c59d604",
                            "FN0b6c416b1e66044422e53d1272297e5d",
                            "FNfeaff1263079a7c6121ece93b2efc293",
                            "FNf0d6fb6937b5ba11b98e13c08aba4ebe",
                            "FNaf560b93b9336c65b89a2ffc4a3428b2",
                            "FN5d115a2ff4ea5ab9524e633e06af82eb",
                            "FN463bdaaa44f880a6370fc45a31abb800",
                            "FNca54dd45e7d117c118d260addcb9a538",
                            "FN4a255125f419b0c4a132d0f502acee49",
                            "FNf90ee698b58b9827e3cb9c1aea5c7a34",
                            "FN75d08e1f532937761f6924e3b3f9397f",
                            "FNc291baf402a64c0a6e894e7c8c61efcc",
                            "FN2b5765aeb4b8e605fc78c6164e6312cd",
                            "FN73d4003d43abca95960cb39ef4dbb032",
                            "FN1c9b79caf09f1ea24af46c6897533615",
                            "FN4eb8a3c2b06d2e584415ba080543a8d9",
                            "FN88f3eb3f8e24f1b8fd269a2372a3f168",
                            "FN85bb885b6b6a3b1708952a42c5dd4d93",
                            "FNbc2b6ca2738fe7702fdb8b837d1b5cae",
                            "FNa5ebb490a05c03d0e2a49f8e8ef52653",
                            "FNbbfb99bbf92bf782ab34119b38cce92a",
                            "FN0424601897e8989b15ee48b80b3a0c44",
                            "FN190b630f98cfc71e854dbb54a5c86d24",
                            "FN06d586fcb9111c83e3e9ac76c18f0f9f",
                            "FN206c441a757cece2e63b9d8c11dc4b3e",
                            "FNf1aac1b9b93c3ba7241f184630116ff0",
                            "FN053503901bb4c0ff37502dadd0642daf",
                            "FN8283bcaf2813650ec8aaa25841ba5c2b",
                            "FNf4c148daef0c308ef0fbab964dc06516",
                            "FN70da4e61353ff64af599bc2695bcd99f",
                            "FNf733750a19b9eead8c0d62c29524d178",
                            "FNb928db9a6dc469861990300155237d36",
                            "FNdd7a16641edfeeb99d9d84f0ade6f8a4",
                            "FNff4ccd189bfa220fd2c08d604cb1971a",
                            "FN4a395307f383e755eee1cd57e463bfdd",
                            "FN71a5f118590f82e8ab41e33e93456fc0",
                            "FN3203be548ffe918579edd5e1086e6906",
                            "FN21ab5806dbc5aea4ab4c4402c52775e1",
                            "FN5e3ad919fd4fa79e755f5158267ef6b8",
                            "FN3dad66293693ef6b7c35a9e1a96e49d5",
                            "FNcb8b4e4d5c4d57dcf1c5325ad325812c",
                            "FN94cb28eb68e292ad53e3cc78ebcf9db0",
                            "FN9e04ff10567cf241714455784443748a",
                            "FN9e9d6a1f6b147bfe831fde9109102bb7",
                            "FN12c6c6f9e5ebe8eb9501f4fd71e1ca6a",
                            "FNdefc13fe7767a8323e45a2c64f1a85ae",
                            "FN2a881862c514657759d5c0640680a24c",
                            "FN6b50485a61073169ef1ae765e8a7098a",
                            "FN2d9afe7cd29de83ec7897eb07d8d925a",
                            "FN9f9da56c6786e4a840da2df57b58e21d",
                            "FNf1e33b9ba63bd75dbb0ec1db25f9d202",
                            "FN6a327bcfab95d0dbad13e30109b23257",
                            "FN2f007a72877da9bb78fc6b7b99d269bb",
                            "FN0bcfde608f74df9fd38fcef7b204e70f",
                            "FN64e43cfcde7bcf129f2d71a2cb95a1b6",
                            "FN5d2a5b00a2c8473556e6c81cf7844ec8",
                            "FN19dd80186a265e520b7a20248ddac19d",
                            "FN0fae7b92d4c729f425aeb5b32c90ad55",
                            "FN9e1baa802c0d58633551f9257e0d83fd",
                            "FNa354fa04793c931be672920be87e0f29",
                            "FN0672f1fa5fcd42a6353c8bc4173d2c62",
                            "FN5af93bb9835708cfb50ae8671c068eef",
                            "FN26c9a997954970c3cbb15748cdcffd88",
                            "FNf33c482bbc61427223165688e40a6e80",
                            "FN3bdbeda319036e150872fa4d9e1fd3b1",
                            "FNc0cb1e80bc200a3a9b21b8290d51e42f",
                            "FN0bec6211b172fc9eae4c23fe1f8fa1cc",
                            "FN704a9b2b2c29f0f24b1c2d5b1e83b1a7",
                            "FN130d86e4bf407b861f3a9d6d3bcef3be",
                            "FN716224aa987ed9cc2746f2e3cc35d167",
                            "FN7d029f4160fecf83a4d792dce2e3f372",
                            "FN802e65bee81477ef87f8a48e7988238b",
                            "FN4508aefa1988c3c11189ef4222d17c25",
                            "FNe46ae96a614ad17f8d036602f7ac728d",
                            "FN580dba5ca4ab261e395cf52562093c07",
                            "FN678139c4faa6cfb8046e3446f27530c0",
                            "FN5605b54c5503351c55d04584efd7c4fd",
                            "FNfca6ccaf96d1c1146fa5bfe2d06c7eb1",
                            "FN51a2957c23bb9d8198888f38c46578ae",
                            "FNbceef971386c8f5c72e07feb869f494c",
                            "FNdab12debb17e27cb67e76dceebc7cd8a",
                            "FNf420800576baca38530c5d666ef16901",
                            "FN29964fa5054e002fecae0fd2047337c2",
                            "FNb2b3250960273b82490b06ef523a66a9",
                            "FN256c08b9a011bf4fcd613db5989ea01c",
                            "FNdf957cebc065cea37e0a41c9f3fc985a",
                            "FNf381b4e2edc5bc505af88ec918106daf",
                            "FN979f1b0d937a6261a3e7410467d65362",
                            "FN9837d47d1ea063b637fb789d4b5f6a89",
                            "FNc79f8e715a0de29f4ea5b90d27409ca2",
                            "FNa186f37f5b2c30fa19851398a5e121a4",
                            "FN5717c0439413e91819e37381f8042dae",
                            "FN2e5f4e68f446be94734d85af6329638a",
                            "FN94107d2f8ee38d3d65922f5784013c19",
                            "FNc20410c0bcec157dbdf4c6bc3ecd3afe",
                            "FNd659e48090e20ecba265bda883e1a453",
                            "FN326bdf162338d5e1a4c13a12214132dd",
                            "FNcbe7d2096b7f8a724a2651ead681d06d",
                            "FN6528f30c1869a3d96e7d2e48a0031d0f",
                            "FN7ddbeb3fb4ca98a1ab4fd945de4b8498",
                            "FNf2c1c29cbde0c826639ad847bbe5f317",
                            "FNd1bbfb74cbcdeccdb780320122ea60ad",
                            "FNaa25a1c656c474c4aea633e22e70cfeb",
                            "FN45b7b6517cb36d3dafce07c71b46e284",
                            "FN1d21967578226502026ba1958ec08f0b",
                            "FNfc88babfb211f4ffebe1de18136c4093",
                            "FNfecf49967a9217a6a4f38e236b4cd870",
                            "FN397207db9cb474a93dbdb281e253202e",
                            "FN1a71e8d520f6a5eace419f5560a441e3",
                            "FNa0dab85c4be7e91ec17b07b3ee275f7a",
                            "FN149e74f98436a2a2e9d2fa737748075a",
                            "FN272f2f89662b039deacf556d25b69b93",
                            "FNe95f9c052992ed4186c00739b6eb9682",
                            "FNd1d9fc0c09a61b954fadc8bbdb2e803e",
                            "FNb6e4108372b05ee44c7414f78eca8a53",
                            "FNb4aefeced59e2279602242e58b9a73ed",
                            "FN8648c84714cde92cd7ca2e1a9113a2c9",
                            "FN06d7c5250cfbb6a2d9f2271b9f4b81cd",
                            "FN5da776ce6ead545301a3a55f779076e8",
                            "FNd284596e9be049b71d7564abba4ba5de",
                            "FNa2a162d799d6949bef9ff11891b41981",
                            "FN8680788e44f33cc7041af268c19ae6a0",
                            "FNa8e486d725ebb8871d52dcf632d286c3",
                            "FN592c6c8fbdd0fa98438c72adce3ee471",
                            "FN060f5ef08f62bd87a9ee9b9b254f6ad7",
                            "FNf7f0279e27645682858a46ef2fc37d2c",
                            "FNfca867368890ee33cb8bb340e0a0c4b9",
                            "FNa062a17b79e7860e28d217fc378ad003",
                            "FN5c550f36fee0048144cfecdd6c85dd66",
                            "FNcb81cf982a54b522012f6e5c00cd37e5",
                            "FNed149aeccaa51e09b0d5daec46c3d26d",
                            "FNe26d6f6a9506e4c354c31ea141e74e59",
                            "FN1d1baac11d77a0218a933174a38644f3",
                            "FNd1b04def99ae3b2c389967e5137fff8a",
                            "FNabf3fbcc3472be3003c1a9e709926c2e",
                            "FN06b9e97706ed43cb93256b1b81e98f35",
                            "FNefd59276bea81294d8985a9ac5781f14",
                            "FN377522f008eeeced4e67a668012453ea",
                            "FNe425ca7903780d4bc868c7995c0016f6",
                            "FN39676831e495a671c7dc3c66a2a4d711",
                            "FNc8128d05d60b3fc09060919260a6ccf9",
                            "FNacb17c74ab29e7a9068ea3aff9b1e5cb",
                            "FNd969c1d80b6a1382424f780ad070e3e8",
                            "FN195769cee67eb0c09f10ad7545d64055",
                            "FN5b60ccc3d1a1d86842a15cdefc894dc4",
                            "FNb1ae4f6638eac22c8cd790d051f7b7c2",
                            "FN56315f0c51d6e3b2c17f78cb133aeac6",
                            "FN538ae84aef98c1a18df5faf4863bf017",
                            "FN0cddfebd2fb797ba06f9c7d6bc55d140",
                            "FN2bbc96d3be55a000ea307cfed3b95b7b",
                            "FN8ccb5f4557b63985e8b25755b1cfe6cb",
                            "FN1631aff44723c4fe072ec6555989fa39",
                            "FN3e6ffcf10c3f094e654d47feb8a7d9d5",
                            "FNd99727bb3b561bc4c565c9424c21a410",
                            "FN05a6ca83e3e450195ebf3d40c8927f69",
                            "FNf73780132dfbf57273914ff5ffcbb6e9",
                            "FN2d1e1f69139f1e0822644c67bcf8a28c",
                            "FNa848529d6defcc7257ba29d149b41d7b",
                            "FN0255463ba7ee4bdf45d1363534dd4ac4",
                            "FN603ac0863096e369c3e82d6bd5bc4f6e",
                            "FN92ddba79c5e2a921daa26d633071319d",
                            "FN9e0530bab193cf9bf2fb8f36cdd01f2d",
                            "FN403a739ca2681eda423444b8a25e4f12",
                            "FNc96906c33550428ab3099843d8f59c34",
                            "FNd278d1d1b6084b864594e7732b893ca5",
                            "FN06839c66f56c479dac6c05551aff079c",
                            "FNa7a52b336b8d4df3d8939b6802729187",
                            "FN2e2fb1c7c6b523d41667e371f4511965",
                            "FN56e5c619329a8305a38c65976196824e",
                            "FNb61c4a90f77b572dacc6cab240777d21",
                            "FNa5a9340b72d0b08a5d8606a717750278",
                            "FN231a1e2d5951a32287fa98bc4445f957",
                            "FN38d9e60540e78e0b01fee5ea0c9269dc",
                            "FN99f8e2a7ebf4fe9d5ede5655386889d8",
                            "FN75a6ebcc0cd60605e24827434ac4600c",
                            "FN975aaa72f7caafa0b1f909b69c028035",
                            "FNe112627eb350ccfda178ee80f0545a0f",
                            "FN3ee526461026decb2268a838ef12664b",
                            "FN3707b15c55bbccf797ca0143bfd69db0",
                            "FN1e5d2bf5ff0e011cf5d7bea4f9fcb0d6",
                            "FN16de18131576131fc047d78f114d754e",
                            "FNf97c875ad00c7b3a7174a534b50d54d0",
                            "FNb601da15c475aeda35aa1afeebc3881d",
                            "FNfb60c23d3a3c10f53e794414d6955198",
                            "FN0c7fc95205b0d622d820578c029aa072",
                            "FNc46e5ae4dfa0fe84fe2099c735e965ab",
                            "FN11c0a3f263b60e8fd801f416492a21ae",
                            "FNa28e2aeda288a40137acf17e6c3ab988",
                            "FN201e5b1cacddb8d1c77d24c9ba53e01e",
                            "FNcd82103fc69cd3e5f64e3ab5eb281c06",
                            "FN076b1859eee44272c3572be8b30fe88b",
                            "FNe0a7bf40720afbaaaba167f551613a72",
                            "FN575261d3fb53ba1a34f7e09a821563d9",
                            "FNff58ad5cc7443b9c39867168141a4e7c",
                            "FN1ffb1c56622ac2ca7cfd607823c204e0",
                            "FN29db0be0080afe8d6ce22b583bc6fd2f",
                            "FNf14a2f6dd0461e1b0df450f6a94e270d",
                            "FN99fbe4928696c5ae8f1c3366a0ef0c19",
                            "FN77a1aede7992226f3803660d99c5f128",
                            "FNa5927ced78d8ecb93ce340c68d2dd422",
                            "FN90723b48e2424c73c974f983290c7b34",
                            "FN650cd27f0662e5114e0a105160f3a99f",
                            "FN4fcbb5e483b62b47c2758468d7ae8a59",
                            "FN22c96f793f65943e4428f439d4d82b19",
                            "FN385d68f89993d9f2f1a62ec53161d6b5",
                            "FN68d13c9dbc5fbb2f6a36bcfe28b1876a",
                            "FNf5d378a347ba8bc2182a58b27d06a960",
                            "FN9132c0a0b2d28fa8e4be856930a7a6eb",
                            "FNd0e0775a8711c59911235fbe80915083",
                            "FNf60e54700568f8d9f58760c15aa9a164",
                            "FN51017c1327035df7f01a8fd3c789a893",
                            "FN4b58bbee98af3aad8d48d60bbed4e134",
                            "FN35cc6f98255af124053911f8feabd9b9",
                            "FN7dc8546994be870ced2e3585158f3744",
                            "FNaa8195057fdf691d5517596936da3d36",
                            "FN0d9dc8214db628a47ec0e0ab56b5a223",
                            "FN2602d5933441ea67db51d7fecaf02036",
                            "FN81fea4987ee2cb4bfb57baae41a4e417",
                            "FN8b141720a95e86cfc8473f6a9d32518a",
                            "FN12dffdfdbc1307207e49f9e063b8ab1e",
                            "FN10934960ef61aef824a6961ddf671ef2",
                            "FNddfbc1b93452f42f28ea1af586c9fd75",
                            "FNff1feef6dfdb0408d8f0ad234415dbe9",
                            "FN5205e1b5c0d44157c40cc4cf1ea28c6c",
                            "FN05989940cced496020fe9991a8dabc38",
                            "FN4b178568784ac07e29f01e16f770acd6",
                            "FN5582b4bde1397274738aa42c285dae01",
                            "FN797b858856946a7261847a2578bbb406",
                            "FN15223e3155f64cdb3eb239fc23262b29",
                            "FN4fe4a235a4b5742b5060af67d830f1d9",
                            "FN2ef185c628d476dc98598a10a572fa3f",
                            "FNf05bb63924c1e0198f1e9c41acf66f37",
                            "FN4eb7793d551bd86b05cbe63fec2a1d3c",
                            "FN35f56f164fc18b69a69976e76c26f447",
                            "FNa947b24626796c8c50e6d3cba59b0af2",
                            "FN14f9f673610b417c9481767a3b34bc92",
                            "FN9c1ac9795565376ac084a46264bd9aec",
                            "FN61402338ca197e3528b2ee770db80569",
                            "FN9b419904695d9355d403ee681b7b3525",
                            "FN0c6f5c14eea37bf7d4b7eb550f62bbb4",
                            "FNf0e21bd2c03dbb04dde216a0c86714f3",
                            "FN5c6fbcb61fa00e3b21567a118a8d2e93",
                            "FN3df22834d2faa53e6a06a60d3db2f64f",
                            "FNc98c5e5402f17ea8fd48676a7cf28d12",
                            "FN773b97e8c93e7edf0f03b42f0c5573c2",
                            "FN1b5b955d99645f0bc45fccb1e515783e",
                            "FNd97e192103cc62fcb0ba60144cab1bf7",
                            "FN27d4537dac19b0473b4287f81c896ad4",
                            "FN5751c4b7fed0acf62c4288782c36558e",
                            "FN1c7309dba7a151a99533992b9b6d74f3",
                            "FNab115263f1c1453088e8de54059f4882",
                            "FN7fcb659ade4431b728abd7510cdd4d02",
                            "FN5ce828c93f8f60c039bb58e558962a9a",
                            "FNd040ca27a3697ac29c0268a4d134c9d0",
                            "FN0ad9b13c9d6399703c95aca029d070f9",
                            "FN9e8d3f52433c95caf4550e55dbaeea15",
                            "FNac428802b9cbeff1acd3e528a8d9a382",
                            "FN5bb1113837dee294891ecdfe62c31bc7",
                            "FNec6f13ff15376905aa02cecfb99f5e1f",
                            "FN78b633c41ff015cf0db0fb164dad0ad8",
                            "FN2a259f9f9a1531e008fb1f23f3a8c975",
                            "FN8affa33262d363eae893741624bf6068",
                            "FN020ba4c4b4edabbcd01c8903039e373f",
                            "FN7ea75b99d4a6be71d08a0da8bb99fcdb",
                            "FN5c04ecccd59ef4c68eda376b4e138edd",
                            "FN51311e4ffe06a27f6b9c63712508bddb",
                            "FN635259a14bb79343fd2afa29a95cf8a9",
                            "FNe3cd9bf3c23dc4a4bd004081fe6f7b6c",
                            "FNe8c6d0fb71b83a627f3a3a39961a0f82",
                            "FN28c91e29ea55694b2ec782fa6078e288",
                            "FN865fe0a1042178ccc02da04e9cc5636a",
                            "FN787c5d16bf8e276e99f6f1d69fcdbce0",
                            "FN77a154cb6e93a2040c9c7c5708919cac",
                            "FN1291411b28fab238c0320a769b56ec25",
                            "FN7fe321edce414ba7821a8ff2280b7a8f",
                            "FN11d7b03ae3adf20de9d20175fc97bdec",
                            "FNf54558b340cb4cc3bad3cb719f0de8de",
                            "FNadc3f8258dea531f5993aefb2f4e30a9",
                            "FN293cd6e95bc830cb08d8e0cecd7bd0a9",
                            "FN733279dd884de30d16df9706254f8fa4",
                            "FN51ef9f29d25523eb42fe7eb4aaeb8ac9",
                            "FN4547cfe711fe843258733fbdcb42acf3",
                            "FN442f5ac8fee006e38982e8ae79f6f804",
                            "FNe78ac3d8bb418b36962ea0f40d6ee949",
                            "FNf8a64b4f96eef0e964d5fd589990da6a",
                            "FNbe60b0957e1329b287da5b16b896c5c2",
                            "FN90a38c2f28137c42bef8f488526d8ac6",
                            "FN9b5919fd2a5ef03ce3029abf658f012f",
                            "FN86214673cbe3a5b04aa73702893d7412",
                            "FN15d5ce45d6857627efd4a4d00521f6bb",
                            "FN2dc5becef8db504c83650544689fc774",
                            "FN9c32e5841d3b5d40f347fe22be30007c",
                            "FN3605e37c4c7e74ae4bcb7c114fe3e744",
                            "FN6d71407dd896e209ca71df97b9c1b949",
                            "FN7bd58894dc4c6e4323ca504a78b2723f",
                            "FN44cf2b8731296457c4f5cc6d11f8692c",
                            "FN99a5dfbc06547bb2e54b78853a96650a",
                            "FNb1d3cf9af78f6e04920a9885e0e53b69",
                            "FNcfee37eaf5843fc1a8b08ac8554b61f1",
                            "FN9ff765ed27446ab5cc2acaa81b81cb83",
                            "FN384583800a012fdc2cb0052ec859367e",
                            "FN65e52d1ff4d413dd46c812f5951c0c86",
                            "FN253ccfdb784bec240a7722df26b8f696",
                            "FN695bf1fab6d1429f56eb5356ac0c3a50",
                            "FN71158c40c0c24d49bad68e2a45e36188",
                            "FN671c86abda9867768c92f5b3442ac214",
                            "FNbd6aeb2db4eec1acf3e0d09872cf1a41",
                            "FN6ddfd37bee9fb7e3b1b88a1411591dbb",
                            "FNed9f955843fd58d55df74bd43d4d1ad7",
                            "FN7565d90dd546dd5efdcafce259e27b34",
                            "FNf92ba4658d1689d90ac6867651e400bc",
                            "FN7b94769c53357e1537b7112c788d44f9",
                            "FN6109946acbf794de7e5249f5c02b0a31",
                            "FNb36b03d242cc75158dd117bc02a91051",
                            "FNc3806abbdd3d65fa84ddd1b86ad83675",
                            "FN917c30d02d66d9cb9e6ab91abfe2b374",
                            "FN56efe64384596107a8bc9c8f31d7babe",
                            "FN20afa272e37106e649f00fbccbadd017",
                            "FNdfa37104b04aaeb87b3e6c4c410dc812",
                            "FN77d9bd2a726cf1c25d13b268809de438",
                            "FNa6ab6fc4980aa57dbdbc7261d01d1615",
                            "FN835ed323a68451460a430e37971080f8",
                            "FN6fb90e0602e16f10ec009bce37877fee",
                            "FNf56fe792e202d8cc313a087d7fee9c8a",
                            "FN51de2b207d2b73f0d3a933d45d7dc24c",
                            "FN6540de690ddac6e05a0d3519dedba946",
                            "FNf67a8809d3c2341acb59acb55c1daf13",
                            "FNefe866583a49149c872b429fbd8ffd54",
                            "FNca922df49867b4ab492f73e181986b6c",
                            "FNcde688ba18ccafbabdf008402b933beb",
                            "FNf6f14a1d27da2decaf3e9bb850636a23",
                            "FN08658fb2034f96dda56ef399689e248e",
                            "FNca48ffed72afab08982cd5df82eca6fe",
                            "FNe08acbcc1e5f6eb853eb883b996deb2c",
                            "FN58e2c9da757fce98524744045f8d357b",
                            "FNd89cb465e16fad25aba266327a145de4",
                            "FN9f32dee322c38e641c64ebdae886a2d1",
                            "FN84aec2643a35ebf1b46b953fb9997104",
                            "FN5fbe5f1e49d96c4f64bc419ce46c327d",
                            "FNb9c8ec28d5e486de9ec8396eda65393c",
                            "FN30cba9f86199b67437b154d57ca44cc6",
                            "FNa2c5ee7d2abbb0d41a871779f6170bc2",
                            "FN0e03f5dbcb2f616dd58883b4b0dcd6d6",
                            "FN7a4558f691b87506c217695b483d7a90",
                            "FNebdf3b105cb9874c4499af60e5f9c8bc",
                            "FN16036daa905756ba7ad2a5f14baa1209",
                            "FN161c6daa4e3f66b6a2293adc993e2f88",
                            "FNef292f1b191d56069833dacafe82c4fb",
                            "FN9fc220480357524a430192669fd14932",
                            "FN62cd28a38bfd98cc1584bc8a83285da4",
                            "FN7ac069f2aae7eb29704241540bad74b6",
                            "FNd104b5c2baca7dca20538460ed4674ac",
                            "FN5bed6e9696754a69933157883f8d6b1f",
                            "FNe225f970708caa6c13e51db6389362ca",
                            "FNbc0aa90bd1e5188176673683ecc6a0bf",
                            "FNd548e77842a98ec433fe33bf7f3f4752",
                            "FNc50d16fc274b0fe805cd0e8f586c0f0b",
                            "FN6f5aa2bb3e3f52e4a36dc61fe57ccce4",
                            "FN9751bcee04e8ddff43f1c2802a3b89bb",
                            "FNfdd034fee14794435ff69f0e44d7bb4e",
                            "FN73c9d49cf552b5ca4eebecb3bd4dbcdc",
                            "FN58a7e8062d0ee4d483f40b69c76c7d8e",
                            "FN47ad769790bb2f3c2f1e3642b48a7d76",
                            "FNffa2964d63f7202709eb97d0b773cb5d",
                            "FN6991a054badeb24e55ab036abc2b7726",
                            "FNbacd0bf5ca0e919391edfdec79f605a4",
                            "FN0b92ba89f89de7e50b9c8503faee827e",
                            "FN9f0cf1939e21901ac9fb6ff8f451e8aa",
                            "FN611ce50ad06bca2aaa334309f4675148",
                            "FNce0eec05fbf632c85462b5c4f8a22f3b",
                            "FN8685b428e2243ced5017d598427c323a",
                            "FN469be93bc9a93425f8271e0b32058f59",
                            "FNba63b228e8e73f78d2ee642b92904a00",
                            "FN0bf82da54301bd06bb611ec0220041e5",
                            "FN9ca60b519e9d8688653f2416a395db4d",
                            "FN14660dda978db8aa8e345609d350e191",
                            "FN161f29858335011b53a5f310d3661386",
                            "FN330268714d1679fa3998e6946b41fc28",
                            "FNcfc989c93f2f9bbd3bdf58b714d4251e",
                            "FN886e65102633860572ba3532cf6e0479",
                            "FNfd47d5ace3c514b936514e464820ea6b",
                            "FNfdc9ef796807335bb96e53509328b2f8",
                            "FN215d0e4efe6d40a78d638dd5dd61fe71",
                            "FNd47aadf8fbd6afc2a337c9015fe4fec1",
                            "FNe56c5eb8352501123c9b4848d8633144",
                            "FNc8291f86f3ef3c597cbc078ca7092a98",
                            "FN866413e99a906972ba5a074f6a854d27",
                            "FNa8e6fb3a457b3bb11c95951be88874da",
                            "FN053dcb0730dc13b88fbab791ebb4d5e8",
                            "FN5e7a8f5ebca819abdebeec78fbf3dbd7",
                            "FNc6f6a43a0953ee7e4374157d5f98a202",
                            "FN451927f7a4f357470520f4a39b6ef739",
                            "FN08c101f8b433ffcbcbaedb8b5be7e37c",
                            "FN7c02d7a42d7c57d86c1f53f3f3ef14c3",
                            "FN147ba2cb69c7a7f7691ee79f0f8cab8c",
                            "FN245dda550451cd0f9c7f65eb88659ef9",
                            "FN7c340cdb3e614d012395f25fbb90762f",
                            "FN84f3e5cc0e29eb8ecca5b921d08fcf14",
                            "FNff99681bedf5d7ac96aa4fc03e050eeb",
                            "FN03b4ab0b18acab5b21e039c712676859",
                            "FNbd183ca05a45b72626d87c692dab9fc0",
                            "FNfd4d2caa7098936e5524db63999f4251",
                            "FNb590b6293d5bac13cc9631f64e99fe95",
                            "FNfab295a56c99fac0e19339fd8edc2a92",
                            "FNbcf87dddd4aa6c8c7593d8d5b0d75062",
                            "FNbafd6b9ce6e5c51836ae6d1aac17e3d4",
                            "FN0c94af78686277b9ee12e3f42ef313a7",
                            "FN5f55f55b5e01841991b16b56cd28ef8c",
                            "FN32437f54b663c90a4dbf1359150f6b26",
                            "FN58cd337762738f722ec45589f33f56d2",
                            "FN05f4fb78c5523927a6663887959d71d8",
                            "FNe6d0be8f2dbf4260ad5a89d522c67798",
                            "FNfaba3e310e2ecaf6e1c6e02f42a7ea2e",
                            "FN58307ca3a6f628c953f486977a64e695",
                            "FNb0aa67d329d25e8a3b1f3b88d131b53b",
                            "FN1f1a333f618f199b4beafd2a73a69558",
                            "FN56f635ff31b6223817628bddffcd2515",
                            "FN21c99f11135a47ebaa82927e773ca13d",
                            "FN65fa1dcef757257b0da37e1e3015b086",
                            "FNffc00e39f79ce55906810639bbefd113",
                            "FN9f35abd406cd9fa0fba80b910cf9af41",
                            "FN4b11e753d30302723c24001b29ece558",
                            "FNa8d61f2fc5d3b722dc44486fd2441ca0",
                            "FN70bccaf4334f6823bcf7d626de801a24",
                            "FNbeb6c1359c7d2cea0260c1c283587f85",
                            "FNd12689299789bf21a70667b1ac4e1488",
                            "FN23bfaed2bfb47deef075db3bcfd9b239",
                            "FNcd5652351676161ebb6779d019eaf7d3",
                            "FN8c7024aebef3cc545b04ac6732698a35",
                            "FN2a598d89b11070cf88837748a3efb644",
                            "FN68e46d4aefd50f4237f0e9c4e926c98c",
                            "FN8f6b471b5628885822c3bdd611e4dfc7",
                            "FN78d167551d1b22e8fa56019a40264970",
                            "FNa143219aace256f822bb5a92afe75b6b",
                            "FNbd71129aabca97e7b34ddb75d9075562",
                            "FN268d392de145fe6eceac328bbd7962b8",
                            "FN04f7ab233509d313ec51beded540e1b1",
                            "FNd573fed782d53cd02d26c327a7e90a51",
                            "FNd1df9b85a4ae38114069d192295ebb04",
                            "FNb42338077b500e3868240a221814f7e7",
                            "FN4def5d5f804e220acdd92363ff36bb85",
                            "FN159228b3d6c196d38ad9247b9d3865fe",
                            "FNfcb7ea39f68e6051e519b0efda028b23",
                            "FN3e7d67ecd9c2b5379a9621c04c2262ab",
                            "FN4d9388735bda918db0f1e80edffa6067",
                            "FNdddf9138b7659157ac846417623fd4c0",
                            "FN3d10d8f0ca06128366aa347a62cef2e1",
                            "FNa2c0bf015d8e95ea407448135ac26984",
                            "FNa1f8f90c1012f77b0fef8d4e79495eb3",
                            "FN4fa6360b5c301a780cfcbded19f2f66f",
                            "FN8fc0ed17607a0ac60786d1197e663fe8",
                            "FN6c940b04a68077418bdcee887ff066f3",
                            "FN48200979914e907b6b5a8d52188881db",
                            "FNe93c9e7d51ecf5c74fc45a2fccdadb7f",
                            "FN3d9f152688d2e2edcb742132484367ed",
                            "FNd9f2cb6407d82b73d264112df350dc02",
                            "FNe1759edf500af121d39e74be281af586",
                            "FNcb4022faaf60ed9d44fdb0dbf9cac495",
                            "FN104f71776128856eae98d831d9a4e9aa",
                            "FNa9322b4aa407161bdcd2ad656b14bb96",
                            "FN1be5a5336873136fe1f0dd9198f2e4a3",
                            "FNa3b5af9eaca5bb490009a75f54c7da11",
                            "FNce46a73ee9470efb48b4b4a43635ad73",
                            "FN6945f3c2ab4aa0ce8998cca0d18a1fa2",
                            "FNe1ff5f735994bbfd8e4469c8a861b26e",
                            "FN69ed6b3582e367b67dfcfb9a42ed8039",
                            "FNce7c796f9556f42ab8cfaa0108c9c0a6",
                            "FN72e3e629299a147c0e5602d52a23bd54",
                            "FNff01a0c6a842be102f689bc365acd393",
                            "FN51a028dba2e958d4239ae737d2a44852",
                            "FN8a6ebd450693ec0cf609d48da5d012e4",
                            "FN224928aa7dbf533105124a86d44be4a7",
                            "FNb8036ed2f45ecd04db235d04e1714eb7",
                            "FN4df72c2e96cdfa787f44223578988362",
                            "FN78991ff36f54a62802ae1909a8895ecb",
                            "FN3b019203691fb8dd4d74e050aa0763f6",
                            "FN326e0410eabc97f8926a2f2b1c6c89db",
                            "FNc775aa90f9acbdc0950fd989f6420437",
                            "FN7fb2b8bd86a50f93410c3143f9b8e22f",
                            "FNf7e6fcdf3e8b44652a487b53dbfb9ba4",
                            "FN307d5bcdf595ec29eee695f04120d8ea",
                            "FN2c5db827b509cfade0ebfb2bf7df9526",
                            "FN8eb4ce77504b8ef57ed1fb30cf4d6ff4",
                            "FNa36d89e084938f6585e49c0599e7a41a",
                            "FN249388e74853fb7c27d8f42b3e55b261",
                            "FNbf5472c2046b82fcb7f7f33c3725dce9",
                            "FNb50c017553870be4de08fdf782399ece",
                            "FNb919cacb2db5c649452609721ac3ba30",
                            "FNb7d7854b70fd69888648752b232926fd",
                            "FN3eb9c0e009b82bf04f05c717a21752eb",
                            "FN4297361baa42e5935469ea80bd043fb7",
                            "FN80d9c478fe815171ea27b4d2c9692378",
                            "FN7a8e1591cdca5632e493967c3bb2f0de",
                            "FN194222e807c8ca04cc96416486372263",
                            "FNd3f622f6e766729b21be29fd227aff3f",
                            "FNdea3813375278e6a79977c315290f4f4",
                            "FNe669629a112cc543e7e9ba3a45bf3613",
                            "FN8632cc82f15119159f97ed34f5469b2b",
                            "FN11ea11a552efcbcd436a670200f68bda",
                            "FN8e10a032b788534eb84faaecbaa5873b",
                            "FN6898df2831604dd91e27cdbc9fdfa1b0",
                            "FN42cadb82a9c9e24741ab20c5662ddff8",
                            "FN787d15499849f4e8406a447cd43a629c",
                            "FN3e0196d7cb476204ffde75e11499c347",
                            "FNd5bd2e472b24c6a0a1617fbc5efabf35",
                            "FN401ef9712052abb62a7577afacfe1e2c",
                            "FNa8bb3733ad2b418538965eb5a46aeb77",
                            "FNf44e2321dea568a51712fb857e53ae19",
                            "FN3acb47680153d1868659d6cbd03682e4",
                            "FN97de0939df713f6ec68de94197253265",
                            "FN82b8fb512046b3d6551aaa82955830d0",
                            "FN681df380fcb555628a220ecbab9c350e",
                            "FN1fa38efa8c3a26106efacde874ed50cb",
                            "FN24f9741518590c0f30239947e1967519",
                            "FNc117f39faf2580d9cfb230a4738ac359",
                            "FN0edc4720ed713af2fa907fe4fa25deef",
                            "FN450bb12be60584f4cfd819642e6304d8",
                            "FN8bf6e7290488c53019e50bb05d006c64",
                            "FN67cb307b2ef1ece38e0fce643210c580",
                            "FN2bdfb796ee05d74a266f6b9a49f03eb4",
                            "FN49d553324508ee33a5bbc431658dbd2e",
                            "FN875fa4be95922ee15bc980ae6946fd05",
                            "FN1f251aa6df128eef4eb2ff68e9880237",
                            "FN0746c3f63638ff47ce02a3bd5213e198",
                            "FNb7666b30e1f14ddf79bbe07faac94531",
                            "FN1d274f49584661a21927747e7ed649b6",
                            "FNf96802033ffd5dee8605d5141f0ef946",
                            "FNed791d0c901c11a9e4f481eba177a9e1",
                            "FN8934dd29c9db39d03797cae6fbf0695b",
                            "FN6d3448c7320daa486147c0edb3fd441b",
                            "FNba369702a904decacbdf2a2442e4d3ea",
                            "FNc15899a95579468eec0441fb4b7f3626",
                            "FN5d66b0978d8a66727e0466d342dcca10",
                            "FN367b7a5fc6942da0d5c778dcbcbf521b",
                            "FN5a0ba9c2dd48b3365d83a4f2f37f6632",
                            "FN11b71ae68a814e974f78fc4461a5f7b4",
                            "FN69325b74695b43f79a14033fd5ac4b06",
                            "FN01450c0ee7293839cf801a59bea74ac1",
                            "FN51f93daebd305b99eb0e9fa451fed57d",
                            "FN97bb6a5bc402178674aebdb691c9fadc",
                            "FN9c62bc3593d73050616442820eb17c6d",
                            "FNab794c72054283c10680c0798cbc511b",
                            "FNa49cc7f16603e31640798c942e9f6f5a",
                            "FN0f3734e8560720e30ce45704dc00538c",
                            "FNc59def5dad52bbe4e3655fb1e7b8d5c0",
                            "FN48202fe5fc26de95d11be26526252920",
                            "FN04138737ab7b8c3d708a435e75c480e2",
                            "FNa5c0082a06eaa1182b0a3fa0084be7bd",
                            "FNdbad186b1c581f13fe37c88b90815eea",
                            "FN5a8da1962000331a0885879be8e1053b",
                            "FNd42c1b22e91e3dbc2f35ff3e86b7a7ad",
                            "FNf9f5710283b11d17b2c75ec81d8225f9",
                            "FNfb0c37aad478ecf09c64a79e2071953a",
                            "FN6aa1bcfa9f7f03f945664a906823a1be",
                            "FN01414f6b108638f8d81426cf038d1648",
                            "FN1eb8d4614241d67f3e3982e5ab0ffe90",
                            "FNbcdde582f10c4fbb1f65051c628f37e0",
                            "FN134016b1f62240590a65ad1165004f84",
                            "FN0fd011d0f36eb447b9ebed59ac7d9ada",
                            "FN6a18676a09f13927023a9233968453d6",
                            "FNd4ef3aae6899a1bb0dcebffd147c8378",
                            "FNc8ea135b984c05cc0b29d9231ba8b19e",
                            "FN6c5638107e4bb67ac27b2512649f10a4",
                            "FNb9c5107c40178b0ba80847d4481a540b",
                            "FN1b9e731462b8168a91797625205d7a3f",
                            "FNd2be48adf4f7d75473017944e3598ef1",
                            "FNb8d1c488ffccd1fd4d4b0e7553f2c533",
                            "FN3b1641bc4a264b7406e6d2c2a36b6077",
                            "FNa1766db272e5cf4126a0ef30f60f14aa",
                            "FNbad0e8541457353430bb6df41137dab0",
                            "FN6d485cb7b28de41b5e934a497a8635c1",
                            "FN9c2ab63cc36ac7cce43e56b77b2a09a0",
                            "FNf599b03a2b5d6a2b71f6d536d071bb77",
                            "FNc20e1f54b57281d08fac0c6ce6678917",
                            "FN46cc189b65a93e3a4725ec15d9486dd6",
                            "FNebbf0c3aa61a35d3c6b74f1ac18e85a7",
                            "FNd93af122653e9f7a378916042818361f",
                            "FN2aa894d516c7521bc16a19dcfaa12a3a",
                            "FN56edd5542a5b47f4ea9969aa9cf35f1f",
                            "FNa94908b67a4a1688c95e0893b97d67f0",
                            "FN7bba1cb23a30e08f19c3eb76e7faf6e6",
                            "FN9ed136ff5475b1664c546d3b4fd68462",
                            "FN66b5c101dd30ca2c4d1742afaeb6edec",
                            "FNebe9df970824f5f6ada29b512b9e0a86",
                            "FN66b113964bffdf7cf340b56705a86b25",
                            "FN2e0ae74f6637494542135c5ba957660b",
                            "FN31549bada31da5b5fff4d817a867cf98",
                            "FN6cf6cb397b4343d39756ecdfc553590a",
                            "FN886864b0e276542505fce054e9d47a2c",
                            "FN0f8273471177aebcd107aaa594c9263c",
                            "FNd1210ae156c48435fbe8ca339b6ed123",
                            "FN13267cfd8bcc2f140da7a4e115983be9",
                            "FNb942ca0dd0f30ee37fd9c0fcf6e1c0b5",
                            "FN10353620aaa44f93dcbe5bca7a13c11a",
                            "FN88acf51b238b551dd8695d2a911678a5",
                            "FN7f177b024696e5afffdb36f8179b2a22",
                            "FNb6225d108346c83e3bbbbe1827ed5319",
                            "FN420d43a426d7ba82d31c9ad41e34adc1",
                            "FN64c20ad6b34ae2ef65dcb41333c0b66f",
                            "FN13c402525578664684a54aff114e0d0f",
                            "FN6cad976c93e4314a24bdf3f62297e614",
                            "FN2eb9cac739838f8f4919c3d009810a85",
                            "FN7b863d7a7955bd36e10691ecb3f9ec7c",
                            "FN7e1b15c6a22897fe62f0d66136eda0a9",
                            "FNc7951123f85bfe9563dc71df0eb8c7c2",
                            "FNef7c485e9db50db057f6b5d31ae56dd9",
                            "FN53af9b91c19123b3d8ab61cb66854849",
                            "FNc1effd7a2583fd721d0cb3ee9db20ade",
                            "FNd5a67c99198c28c67145e927cb3e50ef",
                            "FN6cf85b51e4626b16883eeb946165fcc0",
                            "FN5b43d6dd0fa7206bf2d9dad67d5ead7d",
                            "FN2d0ca37859c86511e31b2f8aa9af5d99",
                            "FN165c36629bb29d7ce35419cd9e794420",
                            "FN8ce03e3692cb2703d577e9a1b17611ef",
                            "FNc31ba9bac9b80e433057a6982526b63a",
                            "FNc1d9b5eca5dc78f470af13c1dc047c3c",
                            "FN0fcc15c685a205e22c4ef1aff475629b",
                            "FNb3dd2cc1975e852535065a5189723fb8",
                            "FNa3c7bb2a180527c4b6da4140caf95e5c",
                            "FN012ad860519eaf5f848d24d3e9f2dd16",
                            "FNb606e81a7a1fb6cfcf682ced97b411bc",
                            "FN0b422e7e39bbef2b95355fa2087b73cf",
                            "FN6cce654659962c68e3460dacc8a5161d",
                            "FN30a286064c0ff8d231043d7152b143eb",
                            "FNe7e9a51fe73e101d99b50ddd65edb650",
                            "FNb03e2393099ea3e5f280bdfadbb6a5d9",
                            "FN8e17c8b90acec2cee708b304384bfeec",
                            "FNaeed68f653a481c107dc3e3598a4f300",
                            "FN2ec9219bb04925863f5e6d3edeebfa95"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 91,
                        "numOfUsers": 921,
                        "msg_undelivered": 81,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 705,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T00:20:43.462Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "REMINDER! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:20:03.501Z",
            "CampaignName": "Ultimate In-service 2021 None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C344",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-service",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 406,
                        "msg_req_sent": 406,
                        "msg_scheduled": 0,
                        "user_add_success": 406,
                        "user_add_attempts": 406,
                        "msg_delivered": 258,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN4dc12b1747dc399684bca941ec1f5fc5",
                            "FNd91eaee8eb574bf9c90c8f989c147058",
                            "FNc51af9fb013467a8657307ab02c1ad30",
                            "FNc40efac7810500f30af44cbab21a5efa",
                            "FNc848064466a3d7b55d7e2a0f7d93b104",
                            "FNdadc0f6ff67299602179cc62b20b3ba3",
                            "FN4eac795abae1ad0269b95833ef30863b",
                            "FN8815a20e6391110a9622932f94cfedef",
                            "FNa6eb15f74ef758aae9d59c13618dbbc4",
                            "FN873cc4b737cb65d1b2e093d73fee06fe",
                            "FN3179017616caa09f0ee9713e04f074d9",
                            "FNa5949fe97c002c8423697cd5e941f298",
                            "FN27dba1bc9a4fe196332092aba75c844f",
                            "FNd744a5dc5de627d2498932bd5b9febb7",
                            "FN68070cb47a13724e88b2a8e9b3ed7d1f",
                            "FN493fbac6e0b888ff034eb3b657cf24a7",
                            "FNfbec6f96faa7b83a39ead2488cb8563a",
                            "FNb0e380a582835550a1d487a2481e79b9",
                            "FNb5b755f6eabc84fd774fb703f41fcf3a",
                            "FNed0e1bd948eb92176c16300c4b31472e",
                            "FN65f38a9e037b6cfeba8ad3a9ef100ace",
                            "FN734056b5ee8b7c4073dcbf7137a9ba96",
                            "FNefdcd88afb7c3318fec1165fb9537cc6",
                            "FN75fa6b43287b67c53f260fb17648fa34",
                            "FN72ca9883da5b73f4d4041a02b4fcb2b0",
                            "FNadadd423e6c9207ee9f7c6be8b60a2eb",
                            "FNe3186495a86f9b3109fb1071ac20fa52",
                            "FN61f744c8ac0438ab1de343ea084c625a",
                            "FN519031fa4d40beb08d026c5cfceea2be",
                            "FNcb59059a8c85aa33d8f101688ff16a05",
                            "FNed1e61cd12be428ed35447a681385170",
                            "FN8090cb8ea3dbea1508d897a3ea695775",
                            "FN2c02bd8dfeffd43f5114c56b8959444f",
                            "FN7d418bd04f2641c4b69f35f2c08c61b5",
                            "FN8769b8fa816979b98956e31b9330b0b5",
                            "FN5024e69fa3a2bb2b8b75d5b04f755f6a",
                            "FNfb571e0eaf5411465ccad8ceaf65b1d4",
                            "FN75fcca3aa57b13f9589294165921442f",
                            "FNc6b03c5f6d01da3b49b97a41b18d0505",
                            "FNcfb2217084b87ee092a50aa954ff2911",
                            "FN8fba3efe83c9031277d610a8641b8312",
                            "FN894a1a5038d405b8eaea0c4c941b621f",
                            "FN0c74d523c8af10fbef2112b94bf9a43c",
                            "FNc35c5852b71d0dc40479095fad89d089",
                            "FN8a4272cd93674d64227fed0ebad6179e",
                            "FN875aa0cb66894b258257709d6eb28035",
                            "FN5be446cfbe62fb1a034c6d233fbe7034",
                            "FNa5c1dc0a80b604e5001bacb60ceb2aff",
                            "FNd39a6ed39e4ca53a7860c7beefde8c97",
                            "FN8437c674f84218e7128062abcc57d04d",
                            "FNb62e57367ea91d6afda9cb45f16075c0",
                            "FNe07b05262a20da9c8fbb470ef8b352a3",
                            "FN291b0718d8ffa404a11e48738bc58ddb",
                            "FNa36bd62d694575ed5bd742ef7be0bf03",
                            "FNa30066449f998deca974c08e4189754f",
                            "FN241be6e41798e808e35f454dae01b951",
                            "FN2d9482c024e6701f31f29ade074fd520",
                            "FN6dacf7c14dad52988564954faaea48d9",
                            "FNf4817161ff3becfb34733af8c8b36506",
                            "FN43d268d2aa74197cb9bedbe948beb79c",
                            "FN6cc013e5de3080ff0b224ecb86368cf0",
                            "FNce573038d339c38830398ae758503353",
                            "FNaae82ae98165f3df06d5445b21c2157a",
                            "FNb439c746b60898b4f4a745e1df4c91e4",
                            "FN871428f803c2e4ff783a180699c87876",
                            "FN562b8de8451ce1a2691a9266b717c6e5",
                            "FN3d3016be79ba1990d5fca9c5c1a93fc0",
                            "FN0325f0848873095b16bbfd2f3f6fc852",
                            "FN283b4b665164e444a307193396d7e7b6",
                            "FN15b9a748658f89c0a8ea37d43e20963d",
                            "FNe04f38584727128859fb366180f4f7a4",
                            "FNe08bbe94147b50e6a65907fe0d485d58",
                            "FN4acca5e16731941189af73693334e871",
                            "FN57675dd5b415365919105399f9c277a0",
                            "FN5e3be07867b9e5761528c88b927db4ed",
                            "FN150febf0bcd3ee5609f61b16ff047ed7",
                            "FN6f78d2d0fcb4f511cdbf389f4e638f61",
                            "FNe9908ae7e957e478c95ca12d6db50358",
                            "FN39a07fe873ac607c8cc6306bd111e0b5",
                            "FN188aea4950e705a34851e78414598662",
                            "FN2225c7e19633140387ca0034360ab1c7",
                            "FN7aaad1d722423844f5609f248c7d2f72",
                            "FNeb0cda8d07e03f53505caed4712fab81",
                            "FN4475f921ed3e7f62be644df04ba86de1",
                            "FNc575709eabf16c2fae5c1a395674e561",
                            "FNe679b21b60901c65cb1a9ff685df5c2f",
                            "FN97a337ba7f054b108e7a170381899c8a",
                            "FNdb1b6837de2a148022a0e50eb89d0d61",
                            "FNaa59af8a5bbecc1fe9cf80dca853b636",
                            "FN185d75bcf78687cf396fc10d4e3bfbc0",
                            "FNfeca37a5aa76bce6a50db565705d6938",
                            "FNfa93d4b9617c9ddec278b8ee1371da61",
                            "FNa4240a0bd0c4a7f2908921a268832bb4",
                            "FN3efb63a3ef7ffeeb80a0bea97e15101b",
                            "FN2f74d8f1ed441bc4b556ec3ae0b1b643",
                            "FN3dac25fd5255f4fda0618c5403b8473c",
                            "FNf6896613b9601cd868100d6ba6211bb6",
                            "FNce65fcc3e44df20d13137a8a9ed1dfb9",
                            "FNdf916b97a6844a0ffb67b86bbb364900",
                            "FNe701a94e93b4dc8193f7ccc6d9576035",
                            "FNd9d13c656c92773961579c5b9398e8eb",
                            "FN6b4656672cdc41fff5072bdcf5f68440",
                            "FN5c2b1c8cbdc4a38de8fba64b47ba754d",
                            "FNf21255969d8bc7ed5a225a40b2188e25",
                            "FNd959d6a1fc233c1d1e81faf056704066",
                            "FN6a797bfa01905e39cf12a2c47b569e30",
                            "FN4f57109f43c6129de20aa5cfd3303302",
                            "FN63e935f8796e03d2a75606281e866935",
                            "FN25c4ae6cd559807dcc22518b69b60455",
                            "FN85861fc83745432cd2a1d8406e8201e5",
                            "FN07b156ea97f2e23382556f312b466f60",
                            "FN0a54ed2075d53caf6215f9fbaaf67129",
                            "FN15329ddb737080e9fad16c6bb47daa6e",
                            "FNf21737f5b4769db13be601e999bee59c",
                            "FNb1a2c3e14a9017833a1bf496e08c0584",
                            "FNba6b57a3fc82fabf02d17f632d3b5254",
                            "FN324972051236c5e20646b3d5aa1392a5",
                            "FNc323f61ad36520f44c9cfd51c98da6ab",
                            "FN0a43fe39d727b82840d6efaddb54b1aa",
                            "FN2858022ac8f4cf77ad8f1cb57101d05a",
                            "FN476cf8310b2320ca702136c48342d366",
                            "FNde0ff778980ebc71251eb1f71c01a36a",
                            "FN590a54aa6eacde4174bbd51cb9d56898",
                            "FNbf6139a34e3922c039e7303c1ec7d878",
                            "FN42f8e1b2e509cdad01fca2a9a2d1d704",
                            "FN29e13597b246f186c7e4623b1fbc9389",
                            "FNce8b3f7acd84da2e9d0544e189261439",
                            "FNcae0d79eb5e90e0916337e09fcb8e663",
                            "FN59cfc4a95bbcc3ff8aea2d102fc1d4bc",
                            "FN0aff719e2e2a41b4123312e96962bb96",
                            "FN9a2ab34e1e9b86322b30779b1d36ab0e",
                            "FN976d565aabb63efcf0460adcdafc2165",
                            "FNdfe6518d5b745f3ea684b6c931ce5e92",
                            "FNa73990989d1fb33141209516a26fab2a",
                            "FNe4872540d776415704b603c26cec27bf",
                            "FN82bd567b3b9dbf9d1630b2c18d15baea",
                            "FN96988dc1d3f27878123798e3b169d70c",
                            "FNd31e1740c82a39ac225a720cbc9fdf20",
                            "FN2e33d3a762d85f6673de266983ceaf14",
                            "FN2f7ebd64c3682f83e50c81e72647eb57",
                            "FN7fdf7726ff279be768bace451d8417f4",
                            "FN3f4e2ce2bee1d39b3ee785cd303bc6b3",
                            "FN8fb7a02236787d04db6fb545e4316685",
                            "FN596967c81aea6e424e9c6b04f51e918b",
                            "FN5dbbc322e3758945a3855985e74fd6c2",
                            "FN624934f1f5044a7e8bb7d198e3bce21d",
                            "FN4e38737c1dffc55d23e4d0b4794b634c",
                            "FN2e72be6286f8c62fe7524d265c7cc347",
                            "FN9d0e7b9f6bc30f1956d3ff5eeae5c575",
                            "FNc6614cf7fa6599137ccd7c4343ceffb0",
                            "FN2ea46be755ed0a627a21afc400a5b3d3",
                            "FNd5cd94f443c2609b2c0ddd2b2011f04b",
                            "FN3006c3dfd466b6e441540393f32d64f0",
                            "FN59b611133d40585096ff0d4c42ee958b",
                            "FN1c83108a59e195242f014dd3c0177c6b",
                            "FNc2d07affbd932b83222ea0bd644c3acc",
                            "FNac3501dbef0e250858b2272ab597b632",
                            "FNb62af55b93966cac760f8b4b36d87316",
                            "FNaf41118ad97bc68bdae4d01fb34fb804",
                            "FN4026bed9b5be5f4be5bfa5279f552a89",
                            "FN27f56748274ff35f50f6c7b94246f97c",
                            "FN73b3a3ff9a77c81ebc19ae145c2c0f9a",
                            "FNf78ad394b2fe73f48132a06c54afa768",
                            "FN9a513a7edc4bd705fd4472b18bb1d03e",
                            "FNa428a4f1318e16c745566aa7596724a4",
                            "FNe0b2039256303b7387d9a867bd9f7b8d",
                            "FN9169f5a0b106f47a6471f2b1cae11679",
                            "FNb8474d1aa45500411ec28b59a66e78d1",
                            "FN0e438d03d53d93525c918e368a57d0ea",
                            "FNb115726dd3af9d9896041aada6a08dbd",
                            "FN732f7b126fae492f00b14585dadd7248",
                            "FN594b7b29d10f21b3f0b410fca5214ca8",
                            "FNd201d58d670358fc4b38cae73e1c3c74",
                            "FNcb5cec76e880263f4972410037b5ae03",
                            "FN182e9de417d8eb2ca7f285c449cca6b3",
                            "FN7f9a0fa59937a63e59cace5a5868b30b",
                            "FNddc300e8ce5b711e289ba05db9719a01",
                            "FNf003d11a03dc4bea71e3203ae21bf0e6",
                            "FNc1920d4a8a419059d40b0befbb453e2c",
                            "FNa3afb4333aba0f252b07c5aa6adfcadb",
                            "FNefad98386c9529db4dc045d49e6f18ef",
                            "FN9bf4c4cbe045d9c83d6984a90ce13365",
                            "FN1e44ffd71f25e65474540999a60aa074",
                            "FNe054f741014fb0cd835db2127fd3d959",
                            "FN2b69077556271f28cb5aa12327bffa70",
                            "FN62064cf06a678c621ce94b6b049a8ba1",
                            "FN00b6e81eb3fb646b8bd3b539b178171a",
                            "FN079d658d4d45e984bf628658b0e72cba",
                            "FN3ad939fdef043b1f284dd91b9c65b106",
                            "FN05141eead4777f4dfe30b1bc1f68552f",
                            "FN5f4ec77e0885495484a2e19f7d7693f3",
                            "FNe7bb1ce6d8b1f18e0bb18f17734d0aff",
                            "FN3b3929be0a5abf2a4e535491347efeb3",
                            "FNd94314ed1dbcdc2ad09a8a6fa97abc0a",
                            "FN3dbb7522c459099065473c4e20e5e6aa",
                            "FN07ddc0149f2d6b7e8348e9a2719cee52",
                            "FN70fba1c92dfa7410208c4c7a03418c79",
                            "FN10317c4d0d90dba5f7734b5d40391dc0",
                            "FN62a1515551fa3cc8119da0fceb7d87d7",
                            "FN87dbe3d46d2b6da2e280d0bc76c83909",
                            "FN45d95b098fda39c2607a5e3246a80c19",
                            "FNebe2fa34f0737e6e9b5ac16391ac99f7",
                            "FN20cd3e09877c431916c0351a0dcb0f7b",
                            "FNaa5b289ad6b2827db4bfa911e24a32d8",
                            "FNb87f969e7efe22e2b3c7ae881cc45eba",
                            "FNa4f13bc3953f4b65585d333c3c6413a2",
                            "FN12790e356993c06e5330d5863e0215e4",
                            "FN5ffe8e84be8712363585692fff185e7c",
                            "FN36433a3563b4bc42935d0896c4431e4c",
                            "FN51258cc8d3d91bc5983d22f2f9e90e7f",
                            "FNc2cfb1ab875fc65d291cf606bc90273c",
                            "FNee86e7ca713aa64ba6427667110c1b7a",
                            "FNcf0a74817fcd2d004a6baec430ae172f",
                            "FN3c7045642e60fd917f09cce39d482bb3",
                            "FN5be6847d08e6680a0910298590d161b5",
                            "FN4299ac8129591756d5ec8fa5ce45e412",
                            "FN99174ba35574c62946626a1341ee0b3f",
                            "FNa4959e17411bedef0004897c10d5bb9a",
                            "FNb67d4c46f41c21996aedf2cb5f0718cb",
                            "FN4f6f24b185df496957bcecc497affd36",
                            "FN38716a11ebda8d2029ace46f50221278",
                            "FNbfca86b0508cae4ed25366a09cfca02d",
                            "FNf2ddc2ed5b785809bf960a3453a29cb7",
                            "FNc3a7e74ff0215df498374b6af245a506",
                            "FNa882f6bf15603aec80e6bfee32c5213d",
                            "FN925b5298d15d9f3d313d12a97c7861ea",
                            "FN35d8261463c1f50a6d516be588bd90e2",
                            "FN962d4a92e684263b607cfbd0fcde1186",
                            "FN0af6ff9f60cd2c312ea4d6c81d386dc9",
                            "FN16ffa940aafafd901b55303a8ddc8268",
                            "FNa87bfa1091058da7a564b12416dcc7ea",
                            "FNb496ddbba142146a05fde30867f5f28c",
                            "FN213090211ae947f76ae95ae5195edb02",
                            "FNc4a19fc5c1d8a79eca8d8463240f6210",
                            "FNb0960fc04ebd76f8dba78ae55468bcc0",
                            "FN2c561cb5113a32042ca417150eda5935",
                            "FNf8b2de71bf75d086348e9e628382d335",
                            "FN04786f890e6e8e32b9a63de41f4fd1db",
                            "FN6b989e8bb2560841b743bd9481392463",
                            "FN17bb339b81156dc4b78bace52e800408",
                            "FN75adc30fd90c1adb7fb8bf99151afef1",
                            "FNc2d64a2fa98f056eacfc5bdea733c6e9",
                            "FN4c963be82ecf21106f6be69199c8845a",
                            "FN918c32658721f29130f8f121c6573007",
                            "FN347e626e6344e53d2c71eb4f7921c8d1",
                            "FN311f733299cb85886b5ac0439fd54727",
                            "FN455fa8de9077d75b260bc638d6d69a4e",
                            "FN2011ab26c73a32ac20b89c4cfcc0f304",
                            "FN3ebf49f7607f5d383a586d027a83608f",
                            "FN63dae52b1bd6c1b30432e22c5e229ccf",
                            "FN4916a8af35f1caf42a5186b842035ef3",
                            "FN7a02fb8d7854578bd75f57c9ff15440b",
                            "FN5233077dcac501d0e1cdc84e25569b83",
                            "FN81fed29568a8c6dd458afe807ff8afe2",
                            "FNba17e4ae2b6623493c5267f5d96c38a2",
                            "FN35c2fa2e8dded674e21c34389e34e3a6",
                            "FN56130d2d854e6d80ce45f7e0b75abf48",
                            "FNaa24b66d65b3dfcab6de42ed6b79bb41",
                            "FN558c2289586003e4ca163547a62d3194",
                            "FN70075100fc1450cabb4d322a46ab9623",
                            "FNa049316f17b087171d03361eca3a5953",
                            "FNc714f79b8d73c4524982082814c556bc",
                            "FN6d55d3c04488a5a2fcff630a83422ac5",
                            "FN9992f00918f99a12af25336da8c2b42d",
                            "FN2d35a6e3d2039614c47e111e305c52fc",
                            "FNea79bdfdce423a67489b3342f26bcc4b",
                            "FN4bf5a197dff12d220286a7c4262b2110",
                            "FNfac0a48710dd0599e448506fd2c9d7d4",
                            "FN958a57e7e4b726ec7a53839d494c43f3",
                            "FNe5bd82859c4b9ec9c7161e1789839617",
                            "FN6679259533d4b5ecb9b79dc02da05ca4",
                            "FNee55d680a7b646a4242bf3e7b7d2354f",
                            "FNc334a1ce02a3d45f120d47eb09e527cc",
                            "FN9ace680000c22908539b739bce352401",
                            "FN211708af7554760d077d19ed09a790f3",
                            "FN61c96f17e549c005b4b51be0c0a508c6",
                            "FN22ab76560d8c6993fe584b4934b1136b",
                            "FN78861f24229da6029a5a988f4f454ab2",
                            "FN7f07a4fd795bcd0f4aaafaa2cda0966e",
                            "FNd18c45cfa6e81bac4926c821e1ca7bd8",
                            "FNd88e590a3853c1b0ab866d710f9180e6",
                            "FN455aededa670770ca34b9e220a7f86cd",
                            "FNdcd7131478992eb54b5d5973709cc1de",
                            "FN0a60a0e1901428b20dc85bc99141c27c",
                            "FN09a4a6e69a7c3205fd83cb5cc6d86f28",
                            "FN24e3352fe56a11a4a41186b82c5e9ddd",
                            "FN2aac801e7a8b4decd01a9465d19ce403",
                            "FN0623a697181e63eb090214ded47e410d",
                            "FN8090ba1e7296ba652ec73d9c221631f4",
                            "FNe5828434dec10098f3b59694e8f51613",
                            "FN76253d7d5fb409cb236eb32ad5d1a315",
                            "FNcabc56b55dbffcbcae09e7cf534d7da9",
                            "FNad3471124eaa4ce43633a441ce885c5a",
                            "FN8697f027a63cac68033c23c3acd7afc6",
                            "FN79a6e9408ebe6d2652ad18af6195aeb9",
                            "FN772308a08d33dfbc088646f1b5981a32",
                            "FN7baef6ee142bc17856cbfba207da83d3",
                            "FNe7ec7389859da5268228aa79d5ccb4d6",
                            "FN9b61b9e69d36c39dd4b655bd25f4ca53",
                            "FN73171aa2c354f16a3564c0c5a4c65f71",
                            "FNac9e09081d789e53600e4f4150b2b9e8",
                            "FNd52007e66fb1dbc3d50fd705d0aa3b9e",
                            "FNa7b3cc13aa1fe130369b38eeabf494e0",
                            "FNa595476c274f0d365e3dcd1afddf404e",
                            "FNec92069cc1ed24fb6b55ee1e3b87bebe",
                            "FNaa1345068cf09d331cf7ff7ad7d91029",
                            "FN01dce82cc3d542f6fd9ad73cfe340928",
                            "FN0da66620c07beb31ed3b115b9acbf19c",
                            "FNa6ac2613da8cde729246a743e9163d7a",
                            "FN8567eec5eefa99e8e7589c0974493679",
                            "FN76bcfa6b84055e6d4e41848940e298ad",
                            "FN4fba9a069da50e869e737195c82fb24d",
                            "FNc41304f97bfdb097cccb1297484d56f4",
                            "FNf11fb4374b514c010ad04f0f7e2f7652",
                            "FN35608b2abe76590e755e2eaa1e9f7ffc",
                            "FN033cab727f8d8baea3720ec95ebe5c74",
                            "FN35b823b4cafa9f4ae33eb5c3a43760a0",
                            "FNc31cf8e916f591498b893fa6fe365b71",
                            "FNb98456232b067fd67e5329c4a3edfe72",
                            "FN565276967b724f8706bf3400b9951c22",
                            "FN3a9e6f29173e76fd475c82a41f075616",
                            "FN3d82f0d05105239ea58609c30825b637",
                            "FN12b9e5529a892977d2ccd9852cd1118f",
                            "FN909b7071a575fe327a45b37c654409af",
                            "FN05912a50cc24aa969667f0c2795e9e13",
                            "FNd64037f787a94a1cce7a4d725ef09134",
                            "FN0ff01da48b62eb0a9240ab68f8a06642",
                            "FN0d37af867476d3695effde92de29174e",
                            "FNc94ba377615eb9e89051c6d752e5ec23",
                            "FN47f711aeb9f84702b86518048aea4323",
                            "FNf79c47936f1ab3eaf1c85c352cab1b2e",
                            "FN1099c0a915ed73040f071ff86a859066",
                            "FNc7bb54e6ff15f09ef2518068471acd80",
                            "FN3a4d004b47cee72a2812510352e22465",
                            "FN51691e0638436c4d8f74506903cd4145",
                            "FN4dae8dedb1ecd8c40ad7c1a2fc09aa87",
                            "FNb2c362154478cf1281c3aa0f1dea65ff",
                            "FN2c4b1e735edb24af878428822405aa09",
                            "FNd93bcbfcbfdad330bc2e9f97c2961f4d",
                            "FN900d85066a2180471b559e6ad54f179f",
                            "FNd83808ea4b0d2adaa6cb6f8f3ce83d94",
                            "FN8d0db3af490599e1313e3cafe0509e5a",
                            "FN61035fbd95d6d00b0a480d53345dfe25",
                            "FN3cdfa7861760e23cfa25ed6ee1f762b2",
                            "FNe355da204cbcb015d6b22af70f808952",
                            "FN29d0545f9029ed5936dcd89524dbd1cf",
                            "FNa99657303eae9f39c36f03568f94e997",
                            "FN6e29fde1b8542fcf79cd304946a2d343",
                            "FNc09390eede1ad5b200bd191ab62b8898",
                            "FNe5a3914b1df7bec16b1c857cd7468395",
                            "FN1c216c2ae0eaaf495c2529dbfac8c091",
                            "FN2c45d109780793a067b2b77d4125c347",
                            "FNbb88ff548588768538327b034363b402",
                            "FNd04219e9bc4797102f08367b64bf6c47",
                            "FN8292a5d3c406d8116740bfeca103a7d8",
                            "FN8a4de4c2dd6bdc9e0b0f2c7870f757ea",
                            "FN555cab92e258201bf72f67ca601130af",
                            "FNe329f83786041b9034bf12333788280e",
                            "FN314cdd42b82b40d0702deb9d89d72ae9",
                            "FNfca89c62a02e2ab350cce2a76c154b34",
                            "FN47ae7827ad5fdaff8686fa93e1bfe82d",
                            "FN4e5c281409d4d645b3d88de35b131ff0",
                            "FN902c30650bf6dd63a3599f45cf6b9df9",
                            "FN0f5098c94c5fadf9490e504d8165fa9e",
                            "FNe771ac04d58e60056b1e9feb35b6792f",
                            "FN400e60b6d4a4bce441cc01d7a542c26c",
                            "FNdded87226d9105e35aeef4c71fa97023",
                            "FNbaf0a725c48ef1e93534beca44586816",
                            "FNfe1e674c6a4ca7f53e41ea31b3802ddb",
                            "FN8dd01314a59e8f86797436a6ce4d8fce",
                            "FNec5be4b583fa7efe0b10c8b6be4d51c9",
                            "FNf6c9a93d98dd95ac97c7846bd358c560",
                            "FN911e1c2c4046acf33071e99aad930cab",
                            "FN1f5fda7144ea1e48962a782531501a9f",
                            "FNa4b606a7d233cb03748b96ed30c5938c",
                            "FN9a3c2b3cbf8c68a9734c2ecacedde998",
                            "FNd91fe5a003a9b73a3c147d0bc2c8ee38",
                            "FN34af14d6799b8896509bb03d516ee291",
                            "FNa12d745def2fe0776950e84504310078",
                            "FN25264d63013f7d13601c28bc0af77ff8",
                            "FN523117aa3433622de1da0656f457226c",
                            "FNcffa1117e4af6e48cc986c8bbe29d155",
                            "FNee14e6b20e4b3f17732a7b769167b392",
                            "FNe1fd74c223850bd1dd4625e189588d98",
                            "FN4f6672e33a79958aedcc162122f01c1d",
                            "FN67c02a9ca51aa6281bdde78692ff039e",
                            "FN5f79fc69a2cd38b01ecfa1d584a26aac",
                            "FN020a36bd1dc5f539f64a4d256d01beae",
                            "FNbdf2ccf40bf41045445cf87306897c70",
                            "FNb7941f009aafc92df6c9663230c2607e",
                            "FNaafbfed12ea6a6701083336d1039a5c1",
                            "FN429b7219f8f8d776dfdceb84426d1aaa",
                            "FNd95d9f0067b269b29b8718f5d1ae580f",
                            "FN514d242bb5cc098f6b40321b8bddf2bd",
                            "FN667011eab63f7b895206914d9f222a85",
                            "FN70fb52f81c97ce3d8ce881b3d51a6146",
                            "FN5e95c59050719db71bad64e9499732e4",
                            "FN1099dc1c2f66e49529a083937caaacb2",
                            "FN99b65eda6cb928c405e6ebbe468940bc",
                            "FNbf34601cc610186597428141c744eef7",
                            "FN7900ce11b7d8f89492d66f75ed189eb2",
                            "FN59b0fe88e10fb0a3e23b84bb8c897a93",
                            "FN7a9565420ea45c563f9731156f06d0e9",
                            "FN7d27fbbb236de8685199a93d0b156965",
                            "FN0e34bc23a54e65b06fafde1882e0dc3b",
                            "FNaa7c09c7955e2216c1e202ff09e2a730"
                        ],
                        "msg_queued": 83,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 29,
                        "numOfUsers": 406,
                        "msg_undelivered": 35,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 403,
                        "msg_req_sent": 403,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 256,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN91de03e374ea8e16b690d810051d9f4b",
                            "FN7533910f6999b8b32babae25d8067ae0",
                            "FN66e43841efb5ec728d8341b32cb51130",
                            "FN3cd03afb86158e7d070db60b12948149",
                            "FN4ece33600c26bd7941f1f420f317818b",
                            "FN87d3363bca4e8771075c4094de70f668",
                            "FNc2e1abe6d66e4d45c08fbcba90468340",
                            "FNb569b9ba88df606ef0bb40cd78f0c974",
                            "FN748c76e19727fa1a61551877406214fb",
                            "FNfaf17ca21ec772c7d02a03401663c1d7",
                            "FN2ed2844595332debcaf0a6a9a9e110a0",
                            "FN3843d467ebc9be68858ce101b07337f2",
                            "FNf3594e1e977f5c1b2662df7ac4c1c25c",
                            "FN5176589f24a79a0371ce8aaf4f2f3d62",
                            "FNf5fe84164f0865823b7206374ad48a60",
                            "FN818df12ba20edb06762e5caa552055c0",
                            "FNb8d30bf1ca509b5ec4954fae305886a0",
                            "FN081ccee7c97c6b6ceac23da9abb82e18",
                            "FNae332364170bb8354c1c829b3fa1bfa0",
                            "FNa07cf7e51e2ca01c8c8073d764e977d8",
                            "FNda13e2a7493f60b9d5ea968ea31b38e1",
                            "FNc4f71522d7f25cc91bb382e23b11eef1",
                            "FN35f1897638fa836578f0fe09e981ed6d",
                            "FN2bf4c90f638d50581c4a19f67f89ac32",
                            "FN5099f7c0f7c5364dc50fe7b43216bd03",
                            "FN314dcac7b6be3529c346cabdbd91851b",
                            "FN546d8e7d33b07dacbe3ae5836897d14a",
                            "FN9325d15533e3b6c5968bae1f6cecf12f",
                            "FNfa9c756534d905b8ad0b011f5d6a8020",
                            "FNfaa16c66dbdc2deff121ba8c5a250696",
                            "FNa1d8f312e7470538051c38b8cab53403",
                            "FN02676d0408e2ff2969cff51e7cce8313",
                            "FNcd2f5fd8a1ff1e3bef54618c3f9bb121",
                            "FN5b5909b9dcf6c9c48541682594c0e916",
                            "FN23d3fac9dc66c11227d1a13b6e3112f5",
                            "FN3874f23ee714107ed37bcd4e7c3b36b4",
                            "FN8bf93abc8dd1e692df9c00a0948d8d4c",
                            "FN6aaa5a0bd447e83741d39ea162176569",
                            "FN27e5b5ff4d59c90f9bdbf37f2f8ff891",
                            "FNec3751e6131074eb6c56bc1993cde8bb",
                            "FN46f1119547b8af58a1284927847fe989",
                            "FNe8380291a4d055decca3b0f42cc207f0",
                            "FN5198baac6fd02e16a4d87fa89f79004b",
                            "FNc214835147f296f41f1b586f9869c635",
                            "FN0f63ceba0dc4fe96348ec05eb92ac779",
                            "FN4f68303846e55d5d08792bcc28da5f88",
                            "FN1b305e7f488092cc8676c3dee80d0908",
                            "FN97210c10369f9a5ff2458e197531a182",
                            "FN694754c3c72f26f472003cbac3c7accf",
                            "FN6a709456ea73351c4b602edab99b27ce",
                            "FN41fd95f0291b8d77b23dc5c44ac2ed20",
                            "FN2c5c663ff8c5babffb34899a3ffee2aa",
                            "FN723f2e5ff8e62bd70cfdfdecd6b31925",
                            "FNdf360dad54949f10574ed1fda5a6872f",
                            "FNe7c9b122b9ebad2b105c707d880bccf1",
                            "FNb8cb2c9c30c492ed9169ceb638c8a180",
                            "FNbfe130c3aca6811bd4b90f464050b5a0",
                            "FN44f4585bbbfde784c49a6ec794c8f730",
                            "FNad422ebd70d042537316feab913e6cb3",
                            "FN5b30799ae0fdab46d5c3730ea0c358a0",
                            "FN1a4d71740e91563b85a6dc4198f9bb0b",
                            "FN0df79166a2b2553e4d421e97240328eb",
                            "FN19e2b572688ac1a4dc9f4e3300908704",
                            "FN7807ba9c622eccf7772380105f2c32e4",
                            "FN426bfe4049351c745f7ef1767e2a3d1d",
                            "FN0b9433faba09f87839a6306793e2045b",
                            "FN14ca9772fb8b72d6709bf679b6ec8c9d",
                            "FNb76fa03ef42a289afc9a9f848afaa6bf",
                            "FNa90b7af328bb20065c0c8724963517ce",
                            "FN4b7d79328ddcfae6e42178719ab3bdf0",
                            "FNd3ccc0518334ac33f21ef769ff6e76f3",
                            "FN90fe336d5cff1901676d865becac01db",
                            "FNace50f9ed9c7fd1523b70d6f3e0e8363",
                            "FNe83436edca83f3eff8816e9784d96e00",
                            "FNd19fa102a0ad4dbe9cac9d06b9da47e6",
                            "FN885a8cc51e53df03d8436496410b69f3",
                            "FN5eadb1857e18b93c4c7a1e34f6dc2336",
                            "FNad1d5e6f830a4253c81ac53df57cff31",
                            "FN7326248338d8c161cd95b9e14e55502b",
                            "FNd52d47ba0f10bc2a49d6f692b99b40eb",
                            "FNb920e2b21687b4a29bcab39f426bf4c3",
                            "FNd9bd9b0b7cba496117bccf9cbf37942b",
                            "FN99bfe4d2a9a968612727c388c833fe6c",
                            "FN08fb7d2a8b8189cf9452dcf1e09e0db0",
                            "FN410ba4ad2256d02c35f4746a798b2a7d",
                            "FN5a5faa8f4a03e15cf243775c8719eaac",
                            "FN75862408833038c757932126a3cd2810",
                            "FN5effe8f412a608fe71e9b51d3838f871",
                            "FNfd0f005a89b11db990421508d59500f4",
                            "FNa29a5b45a9bc1de85e7664be82c496f5",
                            "FN4a23c4d9893e85a4d604efbb6b9fb88e",
                            "FNbc598d5d910d90cfb5c49704fd2418b3",
                            "FNfb5e49f05f10ba48d8e5a676d46ed881",
                            "FNca2e816d77d59361cee4d48aa0afbdee",
                            "FNe32d6a58248458c399b09c0518e3a772",
                            "FNe3c9e98ef09a2edf178f2873d38e82d8",
                            "FN37be04b2208cd9708a804b30ab223ca7",
                            "FN5929f7c65c3ccb03e0cb10aa351efbe8",
                            "FN326de95f59d32e5d43f65b2f7ba35bb7",
                            "FN46f1d5e6bf7600d86cce7ac9adafd1fe",
                            "FN2a94041c417f899583926084f87d32cb",
                            "FN1500ad7bbe7de6d9da9e68c7954b211d",
                            "FN416e15c4a99e1c9210040f7ff46656e8",
                            "FN6ee3ae2b6a9f22f98d94c4b821d1ecf4",
                            "FN97b5a6c7797d6fca98a1e68ed1f616d2",
                            "FN1d2ea09d933cd9e0668756be635ff412",
                            "FNaf246ba72da4b3859a4643279c5b4ae0",
                            "FN0c5ff4f301bf15060a6faa81ec5c11bf",
                            "FNbd7d78b4f60591f686abfd6217180d45",
                            "FN6ecdd6a85295e9159a0ea3683e1225c1",
                            "FN2e2b8bbd94b65b0042645fb487cd38df",
                            "FNc09ec5de4877a4b99a3138999da24162",
                            "FN821cd44e24c88cae51c6f841b4c6d2bc",
                            "FN7ce789e238704d0a5800bf22a7fd64ad",
                            "FN6d330c9cbac0d3722e05fcde96b41c76",
                            "FN7b72102c03e438b60dddc2cf126d6f61",
                            "FNbf9fd600de7674fc2e58046e4224f09f",
                            "FN35f07000e04265522d73a4ea179142a0",
                            "FN74da72419d8849cba02d5d59ca9722f8",
                            "FN3e042cca9a6a4897e1e80d9912b607b9",
                            "FN09b0c4caf36a9abf9a177df3262e5b40",
                            "FN52661b5fc19fe296409a1038a41091af",
                            "FN0305b18a1f6f9ca66eacb6cb2ad1dc00",
                            "FNa980ad53136ce494fabc64285945d2b1",
                            "FNdcb7493a023ed3792a23bc9d16b92c9d",
                            "FN6e0f7203dda042ea006e2e58166e2509",
                            "FN5152f4cec1eb74b28462df8762c0e670",
                            "FNcf46e0fc9e9ad9e1ed2e73657209290b",
                            "FN388f16f90f988787963a6e9dd059f329",
                            "FN2baf71a84ba9538f409017fec35addb8",
                            "FNa1344540f15bcff6ad12fde4cd2f59d4",
                            "FN6072e3967db337f86d1fe4a528f8d63e",
                            "FN44cc89ba855c9c35fc6abaedef76d113",
                            "FN9e74a664f0e60a15252781bced09347e",
                            "FN3ef9a944ae09121f1a8a423b352d3646",
                            "FNd4445f04bc8d568e6b9dfb89e6148b75",
                            "FNd7dffdf76c6e0f0105ac53d30b15d0b6",
                            "FN8bb29913a479a8ca29d55b97b5c9a2b2",
                            "FNb9ea132c1941a65fdc864520a291950f",
                            "FN0220fea4e161f85705b0859ce0e99eeb",
                            "FN8873a4ae0fb877314be20d307408ff23",
                            "FNddd530dbce81e4afb6cce928ab94c2c6",
                            "FNc83a28d54ab16126a078fc332329ebf9",
                            "FNfa1af32b21bbb750fe5a82f3f5065933",
                            "FN7db57488d530e0d8511839610267a721",
                            "FN73cd63ca10c41fe06c8d3a47ad6b1f65",
                            "FNcd848401d7718237fb64c542d4bddf1c",
                            "FN1e6ba36f21b2bbf6f90c1c75673b6458",
                            "FNc1ab0ef7b19ff3a26cef6bce23ceebbf",
                            "FN9949f2dc9be1c6977656e8d0212ada4b",
                            "FN0ab4dd4cb4a664fbcd469d437fc91219",
                            "FN0cef1e4187b7e559b064d7dbdd677bf8",
                            "FNa057517845dcc7e6b4af0a0ad4951299",
                            "FNa732f6c0861146cfeefd2c843f3a77b4",
                            "FNd4f29f2ad49044260fa612c46b2af2ed",
                            "FN5e02863239eb86f92f9372ca1c067c78",
                            "FNe61d3da0193e5dc365e38ca862253e0d",
                            "FN55da92fdb8dc06d40a4b4f63d6636638",
                            "FN749fcacd5fd618949f9e2cf48f102ddc",
                            "FNbeda7ba24744035501fefd5770e840d1",
                            "FNf37bf3deb7ebb6bd29e3867f530bf99c",
                            "FN859f7e3f9b09bdc4aeecf6cb3489a1bd",
                            "FN7997a7a8bd32f4ce8ef2b47f013f48d5",
                            "FNe304aaae7671dc7dfaf20a4d24e59b6d",
                            "FN018c162cde47433cdd4b5294d9f3accf",
                            "FNc749a8f4f60225bfc1624852fdcf5f63",
                            "FNcf1190d5dd957e4437410c1048be3bfa",
                            "FNc41d7ea8a660c862b9bbbe823662091c",
                            "FN6943d6f1b8020381119ef029d8a8bb37",
                            "FN49db99f52786f36e6f85236a02ba14f3",
                            "FN19fc800cd97b7099aaeb140162512af1",
                            "FNd91704db437e7d64446ac75bc6d807cf",
                            "FN99b3b8126c8831945171447acd70be84",
                            "FN74c14bec0dab666027257c24b14f36e2",
                            "FN1f016488bc13297992a781ffa6402354",
                            "FN7cf9274f179ddd771ec39e94df23065e",
                            "FN4a7751ccd0beebe7234a3899bf3ce706",
                            "FNb7ebab52d376c3b926604d09ef10479a",
                            "FNd29879b218caa04783534d47d7ec8371",
                            "FN9547ff886b2a4ed1ee340ec64e2993a4",
                            "FN0b27b997f76ddc38846f0748997ef92f",
                            "FN49efd92a95b88cf4ba3eb6e6d2df7cb4",
                            "FNd5bfc40afb1e6a71157e963910b5c639",
                            "FN75ad1fbacc7779ba9471a7997639139b",
                            "FN03d4a3308ca2d25894d4f2b22c8de2bb",
                            "FN2219d0665a08d2681bc062b081317f89",
                            "FNfef4db3d83255373033a2d67da499abb",
                            "FN18661081138100ec92d3e64d58fa315b",
                            "FN94427d09d8a03aae797da7e19ca58240",
                            "FNc9c17e91b7561c3de25fe6d5714baaa6",
                            "FNb44e4b048e71d88f0a034f7f6fe77aa1",
                            "FN96cac409c05e56813df80ec359b340e9",
                            "FN49faefc6c0c59cc35661620d6dbf05fc",
                            "FNe1bdaf7dcb66591c87c720c8be464f71",
                            "FN9de22f2ce98b6b05b2ed19ddb93d4604",
                            "FN36e0ed2bebfdcf1aacb3b5bb35db3320",
                            "FN71dc85f894c9642b9e7e010c1cf3d739",
                            "FNee4416e6de05f5fdf96ab02e65b8505c",
                            "FN40b171290aa96e7a1824da3030967244",
                            "FN13ce5878c47a1bb4ab703f18aea5458c",
                            "FNc9439029afc89f543174e5a0316e129b",
                            "FNf77463cf19e0c0f68672bd9aa6a29e7c",
                            "FNe999241731eaea264b4bffb53c784b01",
                            "FN99b9647a6f1af5e8835c5b1b442ab3cf",
                            "FNd19fa88b78ab0e12c7fa02e0d07a3241",
                            "FNe375291b97d0696d4823a8cf1e214acd",
                            "FN6d802b51094305d0a94af6d01d6e9e93",
                            "FNc0c66c6881ba686f304b156d44d506bb",
                            "FNd61c6019a82028b4c9f45780a1133a4c",
                            "FN6ed217c99db7ec9d7dcb58c41469ba9b",
                            "FNa813e0a1c855c6b0d4fdb09ab0f4b332",
                            "FN56f89ebd1b639a9091e6fc79b94da06b",
                            "FNab310002c2a1569962a1d965cfd892c9",
                            "FNc2f77035ae2b14b6b4cfabc6f1495ea8",
                            "FN48c8365dcb327b016fdcdc2be134529d",
                            "FNa83471fc5e54541cff3fb4d09c172f81",
                            "FNa145457b73b5f0414ce715693dd6c5d3",
                            "FN9077b332d98c3fd5750fda2eb8eeb9c7",
                            "FN642cc60be464a436bf742971db9d215e",
                            "FN9f643ec7882749156de992f64ae10dc3",
                            "FN77886cb279639d9fa392c45070fca5d0",
                            "FN03d16d316ad2883dc638f03e2ad4f7ce",
                            "FN7e5d4586942f34e9c0ca3307c65b25e9",
                            "FNa8d3752a7e3bef101f1245487861aae2",
                            "FNb41f35023b3105ca7f7d6c7295c2f76f",
                            "FN965ef9cb23bbb8630b78e0eec4e9f9f0",
                            "FNa34d72a34bcd8388444fbd403f3aa7da",
                            "FN6998bb579cfb59098e6639134b9494a0",
                            "FN6e2554b780321fcceadfc81f47e8b404",
                            "FNf1ec583a90b37e0ca2869a5bb9b61f13",
                            "FNca747abf877482aaf1d0f9eb8c19a4e4",
                            "FN63d967c419dbb26209e82d08b4523962",
                            "FN591d251953610bba1b285689178c8248",
                            "FNd0fc11f01f2046caec71c7e8f0ab52d2",
                            "FNdc39785b6c604c97e9166b457422419b",
                            "FN80c86a4c9f0217bdc05e72812c91a955",
                            "FN284546f0fd275efef30965507644dc71",
                            "FN354e13f0b9f9304ed21ceb16e4e721e5",
                            "FN87ce311ca9c1296af44e83ef236735d7",
                            "FNda368e7829e93df5fdb18f71cbe963c5",
                            "FN3ff920a04f5c430540257deb9a994604",
                            "FNdd6ee2250473cec34eb4fe4a67baad85",
                            "FN2f5338297f194f7906c023bad8a1ae0c",
                            "FN2726d4a7da40b428ac294ff5aeb2a4b0",
                            "FN544f9c1c605568404676a49439720d00",
                            "FNfe9b7188e7df61b4898f71264adabdc1",
                            "FNc3df39de4f16f1fef2fe24e270bdca0e",
                            "FNee1d3f42a491486bd1fc4d3079efb560",
                            "FN20031fa33cd6c7b154f3daecc220e26e",
                            "FN9c0c106b22552633d82f6fb7747bf9c6",
                            "FN94b56cabc43b58990805630ff047c941",
                            "FN9af1aadf0d55b7a85eb8aa78689a0b3c",
                            "FN8db7b67c406416f091648561e82a8a40",
                            "FN9f337531eb3e7266d7cb7cad867b1db4",
                            "FN0d8948f229ca3ca15a32d864bd170f97",
                            "FNa725d18c904aebcf1393fab0043595b6",
                            "FN46768f07e14cf24e8587b02e2237674c",
                            "FN12848dbd017aadefd0f85ed70a5c33db",
                            "FN497165386e79669300577751b2521d02",
                            "FN5e51aa8bb35b419aaa8753cd07ba928e",
                            "FN9b7d4d665d8419ffaf77f8f1c9c76ec7",
                            "FNedcefcdfc50c80e0b23e61d95d9129ae",
                            "FN49a18861ee487548d36c740ab7ac3665",
                            "FN002eb24f3ebbe78b437821f206e859f5",
                            "FNf70e6b2c9fe169e0d9976f607c6751d9",
                            "FN745b6d7d1903486baacfc8fe9ee6d15e",
                            "FN63d0520034df67245cd3d42793c8139c",
                            "FN02a97c36b73bfdc1b7ba5dbf77691c69",
                            "FNf1d0b5bea7a5f84261388108b73db1aa",
                            "FNb294d0893cb0c0a13e15f81b80c47492",
                            "FN585debd57bb968af42f0d7d687406c57",
                            "FN219adbe7186b797d3bfdeedffb5d5287",
                            "FN9f08bce3bbf69e9578f86a95304f56f9",
                            "FN7bed05aa20ee1c07e51ea99a7f036944",
                            "FNd791e9d49ce206570c04e7e8b84b52da",
                            "FNb7cf309120ab8ae3356b2d4eb0bd1cc4",
                            "FN3bf6d7154cbf496fbfc774ad8c0c18e9",
                            "FN612df99908ee5c644e3f31d87b987085",
                            "FN14356abe08c085147d110160857cfe09",
                            "FNde419b99f383ef4b671cb3eb9fd325e0",
                            "FN259418943daf8411acf69d766afde6f5",
                            "FN5ed6a92ae15461bbe582a21c94531455",
                            "FNe27a9a05a3be917f470e2e5d09b5f7f9",
                            "FNa8cc4112ead1ed5c3d607f96fa7fcf2c",
                            "FN108510492487c89fbc793d699b0c5813",
                            "FN78473f6c88062684536ad65bbfaf21d8",
                            "FN6059be27683bf5a585dee8f189f2c72b",
                            "FN472abd52ea7807e34d687ad4c3f65de8",
                            "FN21e73633865aea3c7e6918bc2322d443",
                            "FN7815a48942e0992df0acbfc8a896b0d5",
                            "FN490858b5779706d57e166b658bc7d7d6",
                            "FNe4e44e6e86d6252dce21c765fed5bf02",
                            "FNfa0048fe3190833ae2c9e9ae5ebc64a5",
                            "FNf762d62f0fd22ac55cf5ff5b8c12eb7c",
                            "FN363c44449da15a618bfb488211282d66",
                            "FNd318a82a4a1d19c4d1fbe27837daa681",
                            "FN6729ee5d4bf88842bf3e8b0ddcd97777",
                            "FNb4a61ae801201ee47416da3566629a16",
                            "FNad0318d5241df3695f4d7643d8dbc2ae",
                            "FN45e8661c435e02c8f696dd62912cb28b",
                            "FNeda6b81e05c8ad6d1731bf882ff88a69",
                            "FN927a4f4b76c6eb7948209b144697a086",
                            "FN5d5162893b12f37aa1992f7f4d2911aa",
                            "FNdd88e3c218bcc772caa46b621cdde64e",
                            "FNabe6e4ddc2a630a969f5593edebeac66",
                            "FN32f3b64f0f4cd0d998ec49ccb799d1f4",
                            "FN7dfca7ed15f68bc50ecb6e1bd7ed6324",
                            "FNd87a22bfd443d9a140e2232b5f9c748d",
                            "FNf9d397070bed5446a47831a98f4dadf6",
                            "FN43bd424acddb7b12414003ebf1789a54",
                            "FN978d56ac39e7a8c29e9a723aeabfde41",
                            "FN8b1148357230da1dc432065041556771",
                            "FN5a377bc08d9af81fce832a4054757387",
                            "FN3fb43bd28da3ff4641f4d8d9872361ca",
                            "FN5a6a6af5920fcc2b9997c18b26a4547d",
                            "FN25526c8dbc476ac4d867a4f419ae118e",
                            "FN022b4599abfcd7d679cb8df6d80c2d71",
                            "FNfffbfb5a497af6a2bae03a4145645cd3",
                            "FN336637c5a56fe8b9a54cbd87edc3790f",
                            "FNd441d867c03dc520c5870913b0cc68ee",
                            "FNbeb2b02981ab2363944cfccab096774a",
                            "FNe4f9d4b5167dd1b7b4f32a971aa880db",
                            "FNb7e72a7e6bf39ff6e674f84630aacedb",
                            "FNefd917d5cfc03ffa768bc10a7a702953",
                            "FNbc04ea2715cf0d7d2fad1ff08bf73ac1",
                            "FN098f39cdc6845f1891368c1988331c30",
                            "FN60616c1f35cb07ed2b3ae4a936f2cc99",
                            "FN97ad19944e5f281eaa6056be2290a4ba",
                            "FN17afb621c4232e27839b7c7033ea1807",
                            "FNc65e09a0e012c1b00aa30423a6122d82",
                            "FNd249e96dc2facc1de33b45c70e353d46",
                            "FN29441a44e3d0ea6677a216aa14ad490e",
                            "FN8c8e7d916e12ece8ac4e982ba1c250e8",
                            "FN6d8425e30c778d903603c3e993d6922f",
                            "FN1d187e1fdfb67abc47eda22802c36392",
                            "FN68e437f45730d1b9c02f7f24a2118fcc",
                            "FN07ffb61cc27d08faf4ae4c71cd415bbe",
                            "FN1db474b7792a8e958fe56b7ba3fc4695",
                            "FN0a9e7d48deb6baf224a5d7ac5fb9adda",
                            "FN30c1385b18bef62beae1a597166b8eca",
                            "FN46c798bc467d4834383bc4c7d9d09f3b",
                            "FN7471eb17b7f978ddb3bf63b625c7f4cc",
                            "FN0c82d1465abebdf6d366a70ec01a01fa",
                            "FN6bbfce95f698c08be185847c471d9bec",
                            "FNfa29d5836be5e1db7f5d7a19585725bd",
                            "FNbc5ae421a877b219aa4a15fac0a1ca69",
                            "FN27f06d45500fa6f5ebe902cf93676cc2",
                            "FNcbbba443c7a90e7f33a45b466fcc0181",
                            "FN553cc43a3253ac6c87c925cb9ab2f5d5",
                            "FN74291611958cc0781294e519f0eecd03",
                            "FN3b3e1b1256077a58cd90de1b04f2ccbd",
                            "FNd1e7c693afd92d4cdbd4183df79c9d50",
                            "FNd27269bd7cd5dd6a48eebebde67edfbc",
                            "FN4f3cf229babd334b631c1a3d669b8acc",
                            "FNbf33ae0ea62ca5054a0d1c868fa39389",
                            "FN92f0b3d71c8ba6efce71222af9e32538",
                            "FNab372623e254275e3a6bab6e94250d00",
                            "FNa486abd497cd710c08f0f66f25990ad6",
                            "FN1edc4385452346571b9fe4abd164fa8f",
                            "FN400994182023a6dda36843bbf7892d7a",
                            "FNc3c8ffda10b5a54e8471cc895c81c41f",
                            "FN823be02f23f90db82cf08a907b4ca9ee",
                            "FNdeb65c92e680fb410aee0c294258e919",
                            "FNead9fd2bc3c8e90231394fffbc6e4cf4",
                            "FN5d656c7fb3cbf6aa53b86c7e57fc8bc6",
                            "FN4de1355e04765105dc4dfcb645233e48",
                            "FNdd4a7f38c164aa2117a9e21cf8d36e14",
                            "FN29872f7a47810b03ffb81bbb8bf14100",
                            "FN03a310449fda8d102d8da8b6a203b3fc",
                            "FN79d2be02441a6ee9d14b273c9af880f9",
                            "FNc4ee2d751de6b6b430c4affccfb074e4",
                            "FN1b4fec8a6f71e79fe25c7c5456abf1e5",
                            "FNfcd9d308997a6b950219ac8dc53d6714",
                            "FNcb5759765e34d2897ea70ab080fcf209",
                            "FN6d8ae8381222563dd98fd5beb17d94b7",
                            "FN021ab0dcb2d12c1afcf68fdb189020aa",
                            "FNb9d4435a9de005e0dc8d2efe8a9fb09c",
                            "FNa76884e49b4cc8565bfa75057723e503",
                            "FNbcfc6f571cddc6f67fbf6bb6fd9d87b1",
                            "FNa06d88fb9acfb6cbb2b0f82068f941f7",
                            "FNb170b02a5b906c566c0c5e9488cc2910",
                            "FN265ec7d6226bcacc45da33d75a801677",
                            "FNd52cf43fd437a4658e14f04ff6aef554",
                            "FNe376fc212596d7fe22591c41dc09fd1d",
                            "FNa68be0db117140fc4cd48ce33b148028",
                            "FNead15dcf334318b9c35a299eccb7064f",
                            "FN1ddc1eefcd5fff0731eb45aeb011dc3e",
                            "FN03640522fbf26b7755afed8f13cc182a",
                            "FN1af9b433b20bfbf1a86147009a2f288d",
                            "FN83b80c1ce3b79781646a2e7d03c43622",
                            "FNef8754e1d8a2baeeb1c8bc1a530d33eb",
                            "FNece8f9ccd7e54520f14901ee6971c25d",
                            "FN6009b078f516ddb7b32066f54473f70b",
                            "FN2bb3bb9d220c9403be39e1797a736d54",
                            "FN469a5a7d2c79967e2761bd6983fcf0e3",
                            "FN326e1879e5f2199614208a57a5671690",
                            "FNfaf3f2a2fff3caca83aa9d794ca8ec37",
                            "FNf9b7d6adf532fa9953de2651b69fc1d6",
                            "FN8accc72eb301283cf739034e7f0d7463",
                            "FN677f15577724c1ffe4b1b81cdab25881",
                            "FN358821af10bde1dffb73aa8eeaa6f819",
                            "FN176a7d69763e188b7eb9d80c08a02a68",
                            "FN5bd540fd98fd3fcdec9f7c0818dd6901"
                        ],
                        "msg_queued": 78,
                        "user_skipped": 0,
                        "user_update_attempts": 403,
                        "user_update_success": 403,
                        "msg_sent": 31,
                        "numOfUsers": 403,
                        "msg_undelivered": 37,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 1,
                        "msg_accepted": 0,
                        "msg_req_attempted": 391,
                        "msg_req_sent": 391,
                        "msg_scheduled": 0,
                        "user_add_success": 2,
                        "user_add_attempts": 2,
                        "msg_delivered": 257,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN801d2da7323d334849aec411eaf56f81",
                            "FNa29715e6fdda39b9a82890f312b6e695",
                            "FN41d04612126fb71708aed749882cd600",
                            "FNed07c346faa33e4af47fe01242f4f714",
                            "FNd62a10e897214af7c38e1193f169a651",
                            "FNaf541d67895887663775604111a54fe8",
                            "FN92a0a195a1be2f5e3e4988b62cd41cc4",
                            "FN78cea55fddbcea0d14e6678af78e4589",
                            "FN01fabc7250ea974d99bee4d95ff8f0ec",
                            "FN66011e692010726563fa41b378c697f2",
                            "FNf8369405e1fd886da9df3196ddfa707f",
                            "FNbbb1d454bfd64226436bb1abc49eeea5",
                            "FN9ebfbe03ac15ed54e4ba319e7abb4ca2",
                            "FN2bc82034f92f303c105f4d523b53f64f",
                            "FN75479eb8e0aecc78d706640e4891e1a1",
                            "FN721c2fd6334ebce3a0881208a34fe334",
                            "FN837e39c128f7df78be1acd32f599405c",
                            "FNea32d230f5b58acdf1bb4c1afbf8819a",
                            "FNd1c72eacf9520ce3c03ee8999118c640",
                            "FN12cafd30813b5086ac3a5f264c144ca4",
                            "FNb29f0f38f582996e64f6ad9212ba16b2",
                            "FN2d885da8f17059e39794c8ad9a8d5528",
                            "FN3dd861f308041568bac362e97539e752",
                            "FN6b74c447e99659715002864a27622d2a",
                            "FN38e76fdfec97caf9e831eb2485baa4e5",
                            "FNfc802b189df27b6c0976e71a43e1418d",
                            "FN2521c40ac7e52bad9947f9a8b446106c",
                            "FN5d849edb1fc832e5a17f722540cf097b",
                            "FNcb681cf58229692d70650b6632b9c43d",
                            "FNf39ee2d4386e2c191420516c4d3c7a6a",
                            "FN699fe35b70f47767411e00e782f8ec06",
                            "FN42aff6c03ee52ceff56094bb9cba4423",
                            "FNb82b2f40d41a0a2e743159aab9a31250",
                            "FNb44b1ba122ea58f767977c3bca183b27",
                            "FN29b90f349383b49986dcb1cd428c8f28",
                            "FN4a5ded2c1fd5bb9a91b7e7366d2cbc37",
                            "FN234ae7a7d4f8142284440c892b996037",
                            "FNa3378e74058f6f8fd53a0ed756157e03",
                            "FNdbc2854ce8140d3ab7b685c55a93068d",
                            "FN1d32cafe2e21b039261e5741e32cb7f9",
                            "FNe610251572cee95061e2a3e3de815009",
                            "FNe73ff9c68b9e0ffcd50ec25608118fe4",
                            "FN1fb5661713ca935558b02f077d380286",
                            "FN0c6db253c21ef76cd48e65daf79e4192",
                            "FNcb4921998c98b5da0d37ee8eadb46abe",
                            "FNeaa40a68b6963c693bed35aac75985a5",
                            "FN19e3ac2791a464d1f5c9ec54cd97460b",
                            "FN1020f4aa2e5b2c989021cbe2e9933ffb",
                            "FN73ae2665c8a265da5f61eaf883af8418",
                            "FN84364dc9305c2beb2e4cc71b66b4a0fe",
                            "FN7eb7998a0f2721d9311887d9c9d6fa9e",
                            "FN906e4cb65d7522851c0878c2b9b4d722",
                            "FNa93213b902d44ca7a6ebf2020b3648db",
                            "FN6a8a34edddacf60f0aea611d1735ba7e",
                            "FNabaf55a9a19ffd57194ef7c9fd61e777",
                            "FN6641ad7162fcfbc46367d8e98424be58",
                            "FNa4bae3a2aba7059fad9cda4ffe998705",
                            "FN1a10931b73d8669fbeb0ec70dea9641b",
                            "FN6f737311adc7daa72b27e68a1083fe9b",
                            "FN3aecedce6267aa2979247bbce3da1fdb",
                            "FN9c1bd47fc991f15fcc8b523e901d8398",
                            "FN71c1d0f86dae523ae5c46441bd3aa1da",
                            "FNc686ff67a455983b24960c1e48a2a00a",
                            "FNd41fd339ed516f59f34f274f0f0b7997",
                            "FN849ad008abe09a9874a886d1fe4d4086",
                            "FN231f1600d471c9d6566c1decca102c83",
                            "FNb9ac10d191b235273bbe54adb5abe5cb",
                            "FN4e047839e6ff350cf96ceedadc391223",
                            "FNf02df8486634c2c4018251de3c79d86e",
                            "FN38d93269052d3bca424fe43751e46c02",
                            "FN0ef85232503d2e2c3097cfc08d7641d0",
                            "FN969d7a516b44f33ae5e6a3b9a8e58dd4",
                            "FN7cf759c29b7816aef1c3c224efdef397",
                            "FN15b2b9bc215dc5f1fb6f37ce5da7b283",
                            "FNa90aa43cae7b572f2c4ed95cf9146e4e",
                            "FN5040448c7e00d0142770c53d0eea42a6",
                            "FN32d6fa29e8c908d649cf2e9001ffc45b",
                            "FN5956b2c97ddd311eac5f92a9336e1b23",
                            "FN05fe5b82c9e51ff02301b886c4d2b2f2",
                            "FNef7dcde16de1239958a56a5804b45687",
                            "FN8f8c8744c3b8334e7472b3fe9702f744",
                            "FN711df6a5101388679065c92bb8d5509d",
                            "FN313c4e3e42d3d8aabe25fc8d5b2a673d",
                            "FN35e9db59156eba719a72b1fac916a35b",
                            "FNf56859f3741d6216957586dfadd4b683",
                            "FN58dcb909089d36684b9890299acca0c3",
                            "FN6b185e897d4551e09068fb4b0ae132b5",
                            "FNd49ebc208b88908cdd108c1ba2b11a48",
                            "FN4bee6f65c0b7814aa22f72863d1242d5",
                            "FN24ee10acbafec5083794dac3cb6b89b7",
                            "FN7fb6a9579dcf11855a48539f0ec88cfb",
                            "FN6ca53bdafca38a2a06cf50d72011ad73",
                            "FN5dbb6f653e7d84b0cb1e4e3ce41eb731",
                            "FN2ec03a0f6574c9ce488f2199aee740f4",
                            "FN3a5e0d1077381103ed35f1dc8412a5e4",
                            "FNd4d666bf2578bf84a76340cab3181f7b",
                            "FN1901048e385d8ad267cb50ce2769741b",
                            "FN00e05c7e14d776408d9321de99c69e23",
                            "FNc6f6e10e1cf61644e66373048ea0cbad",
                            "FN7ddea368e2ccae7fe2536908bb3c2fa4",
                            "FN06661bda33a74cb997dfe49ab8262da8",
                            "FNc5773f61e18fc5dfa45d6583fd7e7415",
                            "FN227b5576d845065e0208292d07d3f5ea",
                            "FN0a90e054789aadc0ab2ba4f07e1f5f97",
                            "FNc93a4822f368a68d7a3b45dfddc9fd2f",
                            "FNd2982e9bc0162669f05001150f12d572",
                            "FN706c7b6dfe3f46233955a09dfd2302d7",
                            "FNe5bed559da4aedb4fad6deb66873c643",
                            "FNe5ba41f11d1d3adaf2c49469bafbf9c5",
                            "FN8d35a56e22d82509f0a67ee4717adf19",
                            "FNb8f8ec5d4ee6598b0090b2e53101ff97",
                            "FN7531171ed9670a906239fce6f9ff6f62",
                            "FNecfe6d18ce6730c4028bad0207eb4794",
                            "FNbb950e9091b7dd1a573236d8ee9dc5d4",
                            "FN53ed721f314205487614554b24292ec1",
                            "FN9e8e3ba7d717d98f3e08e25022977938",
                            "FNb77588a44e5d7332508541036574ffe0",
                            "FN8abd3947a79da28cb62364aff6efc2ff",
                            "FNd3e8f453afaf89fc8f9c8ed7c4a18780",
                            "FN0be1a0c8736d67722bf7a88d88a8145a",
                            "FNa27c36f56bb2e7796e7b54660daaa1af",
                            "FN473ffa52462e693a7edc8b4ae823269a",
                            "FNc6de659ae6a25b380b1c1e0e41a00aad",
                            "FN3669e0fa193dd5034ea89e31e136bae5",
                            "FNf3cfc45d05288cf18470e84582f6d353",
                            "FNb9fb03d0e713542f58415bd83a23a926",
                            "FN6476ac3332bbd67a70993062023419ce",
                            "FNa7538c374b6f3abc8a627413128c9616",
                            "FNe0530db39de0793fac0a6210563b1c15",
                            "FN19e4deb1f889dabe8f6347cd6fa817f2",
                            "FN59b44599190af61ef0e6ec9840ae595e",
                            "FN8ee955a62f63e04d76816b3bed1c5dc3",
                            "FNd1421229af43ecc4e2b6585c132db266",
                            "FNfb4334fbfcdd3ff5180b480216aa0208",
                            "FNca4e5d961c9c6824af3e8d0f02051a69",
                            "FN4d690918e1497eb1d9e391c482520f51",
                            "FN6171d398fb4802c00b814517de8e53db",
                            "FN8cb8fa67208d191a331da6ab95379f77",
                            "FNbe1f72ec9d6ef227c1ed087cf616109f",
                            "FN309c4601e2e71cb0079e95bb43476f2f",
                            "FNaa396f4860b079794d23b7e9f29e6cf6",
                            "FNcf074e5b03f793b07fae102ee2d4e473",
                            "FN23de6c9c0f32a0ac714662592da5bfb0",
                            "FN964d28398ba613d5ff43f283d08043d2",
                            "FNdf28c1d91dcb8b2e62c22bd41aec7637",
                            "FN7485920f75646ac124470a40829055c3",
                            "FNbe5be95d45149b0b7daa857b75c98291",
                            "FNebc6322bf218d4700cf582347714f635",
                            "FNdf4198b0c126efcbe4ec48be5d9447cd",
                            "FN010c17babb1d2a647bbcab5c6a1bae04",
                            "FN7699853dbf073e309d050fd5864885e9",
                            "FNb0a74e0be8ef2d7510a9872a74a3d300",
                            "FNe2dea311e7433a456790ee36e2d324a6",
                            "FN92504cbd1569a5c33c75a2fa4a12dd23",
                            "FNbdfe2b9914071cdb8a90c0fba1c3f982",
                            "FN7b4767525102785c21ee922dde62496b",
                            "FNc3fc4a4ef924b4469b5ae59d77aa7bc9",
                            "FN40e68959e3b8922ca765f8e6a0928d42",
                            "FN30b08c6e45d5a25d895ec9ce6cde4afe",
                            "FN22885044aa1ba2a62822363004e8cc95",
                            "FN5bd6840468c89fe0fa81dd0ccae659cd",
                            "FNfe916e6dca9510da12edef4288045c6a",
                            "FN9861fc52b5e9ec68e6c7d3b51525b982",
                            "FN646a80eaca468030522e3f8331e8803d",
                            "FN5d5e8aa29ea259effe4680e318a9d88e",
                            "FNd4a242598fff6644164f440386f62d1a",
                            "FNde6920e7d03fdb1c03dca46ccbc1e50b",
                            "FN99e3f2f44dcb9dd0630465fef15c271f",
                            "FN83666cb7bd6009c853d1b89d49cd7edc",
                            "FN70c5fde88d8639419d3a21a4bacfad6f",
                            "FN2feb4988d3f426658fee5be48f1bf8a2",
                            "FNa63ae1b6bb5c9f3306b8ee9deaddaba2",
                            "FN3453e05ab2abda241fe34931d534150a",
                            "FN58aa9281a9f6b4212b89be1807489baf",
                            "FN1d58f106fa182890ad5fd74ecb79f2be",
                            "FNa237c62abb88f95507811d14155b5075",
                            "FN2bec39f3c0fc8aff3acaa1136ab0b931",
                            "FN73e47b62896e8c6a5ffe8f7f051025e6",
                            "FN51429531f3cad15dbce06d79a54451ae",
                            "FNd092f852aefa1c165f7ff3722f65b27e",
                            "FN90e022f09f33c7edd75de16a4cb25467",
                            "FN22df406e25e47f0696299b4fc8d7cefe",
                            "FN1d4bd94afd2028e3daed0d8be624344c",
                            "FNfa9bf81a6cb398eafc3dc6590bbb3d9c",
                            "FN81f08db59cc968263202d9c02b4b48e7",
                            "FN12d8727470ba6d626234f1ebdc663388",
                            "FNaf6aa381af03ecefc45e3b90b67beeca",
                            "FN918eb4c870f695d83f72c27192462126",
                            "FNb5c7e9f56ca8aba6d457c2fcf647a57f",
                            "FN375188da6c4736f6e84f6ffae5e5a188",
                            "FNcaa298ece2abba14ac11b3abfe06e99f",
                            "FN8cc2396b4fa414a65f4adfcf72cb41c6",
                            "FNf206f1bc3cc12e72f8b19c183e1af228",
                            "FN8f7a569a6d6da07543025b23a35a0194",
                            "FN864cfbf3f63bb7368e87e00eb1e7ff0a",
                            "FNaebe21a9a0e1ed14de5c5cae4b2f9a99",
                            "FNbd82d52d7e5b947b49cabf0647444c06",
                            "FN7b7a63e9c7b8e123c904b283be4d399f",
                            "FN79b48458ec0fd4bb9fe3408cf2f3d00c",
                            "FNd457ddfcddb12a3f93c53e86002b804e",
                            "FN729f5f0d78cf2da7be473864b5c64f24",
                            "FN824439f24a072a1906d6898410321650",
                            "FN345e49769f833a77a77f46c1fca981da",
                            "FN98a6811bfb6f636be07d3819dc83c60f",
                            "FN189e359f34eba757f7ace9ab10a4e23c",
                            "FN848703b86d9ad782a9f4157e9618ab7d",
                            "FNcac84323818df460ac12eca398bb3e61",
                            "FN8fd27be2b500a04e78e7074f438e7e6b",
                            "FN523d0608db24fc333b31b73d2e49aa13",
                            "FNbfd32a45b1136c9478194f4c20fafc02",
                            "FNf23e4946a430c4eaf0e82064cce9225c",
                            "FNcff3469126eec9e0a3f15a7ebe619c06",
                            "FN0da0b78a82f5cc2a784fabf162abc97f",
                            "FN4958b37c83c25bf9808dc238d48de503",
                            "FN96cac0f129f011b6da7242340e093227",
                            "FN07e95076d433b1c9d2c3d1b2ba70a5bc",
                            "FN77eef07e1d67d16caf3b4cec79182594",
                            "FN37d0bae6ddd9be091fb7460f3caf1e09",
                            "FNea0b9c980588dd1ab385843a2a7d90cb",
                            "FN91a9490b939deab9159456d6b054c0ec",
                            "FN479dc68161374e78eae6ed4179333b51",
                            "FN1d61bafc0c45e578c6fca048cece7412",
                            "FN04ab64b6f4cb9562ca0337d3a01b07a7",
                            "FNfd4edd80a74e14246213f944382fbb38",
                            "FN4f905f041d68663b6cb8a010a4581be0",
                            "FN40f04a0e520cc30f4f69ab8b7b734fb9",
                            "FN4d3d90048e983dbe39339f149b96eaf5",
                            "FN6ce3cf50a5d4b2f1c8e217d7ae22a64d",
                            "FN147e9c071e76838d6d7d0d801fd02413",
                            "FNa8eb8429f5d850118bf774abe797bf65",
                            "FNd77681a63e166b9896ee9f02f89a3edb",
                            "FN9b6a2dbf3dc700535056bf2f58cfe244",
                            "FN69f3b37b3fd0897acf3a580152154246",
                            "FNaeb0833a42e7888ee4423aa84cd31464",
                            "FNd1f4cc6e23f19e6dffd4d3f2bea91693",
                            "FN71f3d2541300c239cce9d9aada9df1ba",
                            "FN01c9a67766f2a45dcc9b1f53c7b658d3",
                            "FN2be3acc80529ce5f4cf1a8391e21d007",
                            "FNab55deb220809cd4eb05bf2ea5fcab23",
                            "FN0a63f4e6c5d673413d2342c39edb002b",
                            "FN8251b82074b8c2d5ab25812ce356e88a",
                            "FN9a8c90105ce93f80e2c87f6f143eea81",
                            "FN89182429d1a4c969f8baf18dd85e329e",
                            "FN253384a3c007ad745679bf672b53d38e",
                            "FN05f4b72e52ebaaab4939ebd2c5bf706a",
                            "FN4b04cb0bce2bf149ddbe72e2e59aa756",
                            "FNc2931fe821a3ea5fdeabb36b2c4a7ed8",
                            "FN00f2061b836c5a244cd47d81db6da4d1",
                            "FN6b97550676fa47ff561e464adf6c529c",
                            "FN4060e9ee3a17b80918c7515123ee9a9c",
                            "FNc4ec60e4cf61d24023af7b4d6315ec61",
                            "FN891a1f211cc10153d06d41d855a64602",
                            "FNe17bec0e487f8dbecaf6731920cbabd8",
                            "FN888e012893ce978f5c5b5e28b258fe3a",
                            "FNa1b1d9b4a4a8496479b3390462da62d8",
                            "FNe97ad10cfd0da00f7558cda2ee87eacb",
                            "FNbee5c14d4537d43ec22852d5a7612799",
                            "FNf4b752f05c7e699a8da23356b81c88b6",
                            "FN28e5a05fb20e0e1db366f33ee0975481",
                            "FN35bddaf248545248491b2e95b8df046e",
                            "FNa43e02ef9771927fe6f25125756c2288",
                            "FNf0fdc1ef65848676573b88e9811d9628",
                            "FNa70975c3d9cf3755c8f0f6e01b1b8dc3",
                            "FN9711320fb99af40564440f76bd1b7840",
                            "FN0e14ff38b649c2541679b8b5b5431b97",
                            "FN09159f509b397b677c87e37b719c4749",
                            "FN6f8e222b9175365fc033d926c6cf7e39",
                            "FNa3363594979c16ef3249a88502fcd557",
                            "FNcb59e7140d6a19a7f8f19bdd6ba0021f",
                            "FN5d7b09a4fe44b3a26ceed5585d206e62",
                            "FN4e84eb51621bb65772af05b07a287775",
                            "FN1a4d5c420dff6c632bc8ff8753395941",
                            "FNd1ea33d483f793dd3bf2a93dcf4f46f5",
                            "FNad40258936c8e319000ee6ab1d2d00d4",
                            "FN50f053e3c36b7ce000a03bed2974a4ee",
                            "FNe2199738753023cf5748256e790ccbd1",
                            "FN632913ab0f0a270d1c756a9bebf001d5",
                            "FN5c2c37fa2f8f9789ae50cf50ed06988b",
                            "FN18403b99e3239efa9f2655913d3ff986",
                            "FNe83402f67514cba260fb15cf12901698",
                            "FNc71cfa787e61fd3f9d1abb21137183ab",
                            "FN3e4d207513473bf55463a547505ef659",
                            "FN809ed34c34f9c609f019763ba234cf29",
                            "FN9a2d4e14eedd5b4d7a95e647833d2d32",
                            "FNfd9f44644c64d46f9516fd849b85b15f",
                            "FN89af831340ed02c41280d1649986ea03",
                            "FNa9f93db882fd741d24c00fe2f8f63bae",
                            "FNc3562eaa1d78f12473cad59d2cc0cc8c",
                            "FN0d8beabf94ecabdd2fe7031a332c92ff",
                            "FNbf1577989300e945463b4642a049bff1",
                            "FNfe6ada1706354fd921f5bb39ba9821a5",
                            "FNaff378a4eedcc2054bdeed3f8c24ce22",
                            "FNd57ff2570a48034a722ef0dc40f4239d",
                            "FNde66b8e306669ffd2bcb47ed23b8377d",
                            "FN8ac9cdcc1e0c5df2f9709d96f007797c",
                            "FN157d1e442814682f79122cb02ed1a23e",
                            "FN83b0eff206c41c9299e2bc2414bf0e69",
                            "FN12bd309b89a96fdeee83f6dfb68eb02f",
                            "FNd5b208446c28537d0ad26bcfee9cb2e7",
                            "FN8b2ffedf522fc4f2bf3b458303416eae",
                            "FN422ca49a4f9199342ca45fe6d049b81c",
                            "FN7872946ea9a97881f49367be7403a605",
                            "FN3cc9e1662cd96d7110ad87cf6dde360b",
                            "FNefa1ca009f71a745ea69f9d9c91241ba",
                            "FNb3e73df939f6e0788866186d8a80f328",
                            "FN18a47b64d1e0ea022e0e34eab2b20b3c",
                            "FNf606dcf6d66edfa0fe35473183b5136a",
                            "FN5b94ddbf61058145b2d49ae72a6b0e28",
                            "FNdbd977cf007c0efe66d7892e3c163d81",
                            "FN7167a1f0edc932ebb2b6394fab366008",
                            "FNba61a7708f736b2f9c50cca1150361a4",
                            "FN9b6e0392c3b868ba4023327273033cfa",
                            "FN6c46bda16f95bdef8a2283a17cd9ddca",
                            "FN3a11fe36fa8b2bf9c168e46dd5c28c5d",
                            "FN6504fef8f8530eab77d4541f728867f6",
                            "FN0a14c77c6b7a9fb1b31559a766ce457b",
                            "FNbbcdbbcb4f8097c2eca56886c05b3a77",
                            "FN5202ebbea4de221632f70faa8113febc",
                            "FNbec03029ba1b1d524e399648cd1f0c53",
                            "FNc45add9dd2befb32c755469cb57b37b3",
                            "FNf07b37984c4280235f8d10b86b7e73eb",
                            "FN6d70479daeb164e7acdb80ed536dc97b",
                            "FN547a655c2bc9e15f56239b1f68f54122",
                            "FN363147b2a5af7e3345c3db82eec77b53",
                            "FN27c033afe56d3ddf5a082a6f8d4a01bc",
                            "FN21a136a1e786ca363187898cb7f2f2c8",
                            "FNec657b72e827e287e786b027d83a3a96",
                            "FN0d1479fdd2a973b2e0d1c52137727af7",
                            "FN24e094878c9bcc19af927c27a55de218",
                            "FNc9438de349b6f690d75b9b22bf06900f",
                            "FN9b75f4cba9e8acd7194a78a0f888ad46",
                            "FN7dbb30a0d1a5a8363a3559f9e5c488cf",
                            "FN5837ed729d8ebccc613238eec209abcd",
                            "FN89ae4fe044b8fc3a2cf985eb1dc77343",
                            "FNbfa9238ecc9f921065cb1039b2b45ac7",
                            "FN05ef1fd782a46d7db01343a67a85e526",
                            "FN0a07c27cfcdb46ec3bd77e3cbb5dd010",
                            "FN430f95385f66d204c7d6ca729903bd22",
                            "FN2f2c395dd8b42abafbc01b1a9ec08616",
                            "FNa999d91474cf3a200ff234afe8263339",
                            "FN8d7ea169fe11910165f2cd2d1d132253",
                            "FN001afae83b989114bcb9316af97eb25b",
                            "FN1ddf702cd862f44e9b98fb303a5ae47d",
                            "FNb0f9044ac94aa48c6ea23cfc35416f87",
                            "FN8fa10f25fbb554bfd192f27663cde902",
                            "FN5ab1553d428744a00fbcce67d8af0c3e",
                            "FNf5e2f21c78f1c67940c5bc569bebe479",
                            "FNe1bf71fa1b7c4720270daf44254c1be7",
                            "FNc3fa23eaa3e414b8793a5d632866e0e0",
                            "FNc8f8a73f96be5fcd7ee5125635770709",
                            "FNc622ce69cd8142a74ae9663431eb23fa",
                            "FNa685cb1bdcd62e3de9b2a7068242fe7f",
                            "FN108adf43d129353857e7596009f4bbf8",
                            "FNcf4bd138f383e25d665fc7d154cce7a7",
                            "FN6e111fd8ec5318f7b01e7cd52e559ff2",
                            "FN9d605391867819e0d25ad7190249fb27",
                            "FN1b06d5f3dfc9bde9d6d6ab3a30d2fa86",
                            "FN83c74b7c1bbbe9f053084913ed67b2de",
                            "FN32bc88139029a9dccdbf5244e7fcf12e",
                            "FNf98e8f9ce26cfc7324394e85d2ab0725",
                            "FN28bd061090ae5f08f5b11d5e534d661b",
                            "FN4877021f714e631da5ec36fcd33ed8fa",
                            "FNe43788478aeac7411798ce17e36420bc",
                            "FNe232f8410231fc920cc1c6ecb9c2bd6d",
                            "FNaa5c4df97eb7296051b06090b326e5ca",
                            "FN9207585bce38740abb987483a05858e1",
                            "FNa4fec8ba19d46d0941555bcc5dcb3563",
                            "FNf30ec93966b0f754d4e415db7282cf48",
                            "FNc17270f6f8b9dcd6a6278ecda9d1ba0d",
                            "FN293045ffd4c282f1f8f62f038ef294ee",
                            "FN205e6398eb92fa368565de286d5b8836",
                            "FN4e9a2173f67ac080ae73a61a7b6de504",
                            "FN71a90071e72d42c4deb44ef3c4a0ac3e",
                            "FN4a002e27c1c0ecb822f170174a5433a5",
                            "FNc5dbe5d2ab142d6bc1d9e0dfe8af41be",
                            "FNb4472a78d2ac7419554422cf73a91dc3",
                            "FNfdaa4c8cd88bf4db8467a83c2bc5595b",
                            "FN63ef3707f968dce5d0bee90d979675d3",
                            "FN1f8af2f10fb5c6b09705aaf66dcb0a2f",
                            "FNdb9754c7862fdbf7703d7a55ba11713c",
                            "FN65c189d3801f1c8383763774baf26283",
                            "FN9973b7b7c3b2a4882562bd78fbfb5b10",
                            "FN0ceeb672338567ab25dc0c847e583482",
                            "FNc4ca1ad777e6cc5eba3ae94b75e55f9e",
                            "FN5fbc791cd20b6b4016209f8db67fb135",
                            "FN2837bec2364d34fad7f51fdb5d96370d",
                            "FNfbbe2fe5a8acd39f44be2ef6376af2fd",
                            "FNdc4a9a26948ebeef9f7577adec9f0607",
                            "FN3409e15ffcfa4f917390de51a97dfec5",
                            "FN6a6a458d525a413eb81d6fc3d4fff684",
                            "FN0266867364078afba6faf31178245c3c"
                        ],
                        "msg_queued": 64,
                        "user_skipped": 0,
                        "user_update_attempts": 389,
                        "user_update_success": 389,
                        "msg_sent": 34,
                        "numOfUsers": 391,
                        "msg_undelivered": 34,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 371,
                        "msg_req_sent": 371,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 232,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNa3c9fb01c1ccc9e3ac5443186835cc0d",
                            "FN4656a903c00e857e7d6fb81e53ff14db",
                            "FN27e9e3ee6d9cc05e95599f981a2e484f",
                            "FN1267a384ecb465e010ba332be009286e",
                            "FNacbdd3cc119c7b54f1a704a05fd51ba1",
                            "FN6d10492e3250c9498aaaf364b608b295",
                            "FNd22aaaa6cb5d54423ae78c187356e72b",
                            "FNf9ff83e14e01b1fe6c41f2737423fd20",
                            "FN89a24712fe31779948e87d0dc28fd59c",
                            "FNc0939a3abbaa2365238a83ce961c94cd",
                            "FN308e61f517812fe094a57fe7b5d3ec0c",
                            "FNfe82433dc9aa4a5b6bb446114a7674ae",
                            "FN70a82bfaccea7e323e6cc734454434e3",
                            "FN363b086476e06cd04f882d7c69dad330",
                            "FN0ee313ea6b13f377faebe8753ae4a3d9",
                            "FN8c0ce9d1ad513c23228abe4b15504c7c",
                            "FNccc868a8d5c162898d624bcc2bc522aa",
                            "FN34f989ae94e001280b9da0603e886a4b",
                            "FNfdeb1b10786a24f9cd5f31b7b9609cbc",
                            "FN43c4643fd1ecb904d286aaf9ce9940ce",
                            "FN3634ffec420bff6c1bdfbdd10b3599f7",
                            "FN01f5568f11770794a76cba413a21cdc0",
                            "FNb9f318675b910dd13534b4749950d6fc",
                            "FN6c8edd98aeb92a4fdc4e2a0089f25795",
                            "FN61e34fb7b0e9e7358cdca9e940124dcb",
                            "FNa20a64cc3450342b1bcbd10e8446afdc",
                            "FN68308f4d2f1dfbae99986ded89aa6bb8",
                            "FNa220122e9f440348669422f2bdb155e9",
                            "FN46f21d7abbd7bd578fc35ee4c190d2ad",
                            "FNf480890ad8054c8b59fdfda7e2a0495c",
                            "FNb038cdd874a445c926a73e145b2d7011",
                            "FNdef9430b95649ee6fba141556f1d5cbb",
                            "FNcbb01a98fba728ad8e5489acd4838738",
                            "FN61b0c3128d995c23f8dd875cc3edd920",
                            "FN53cc7383026d34c91e6999db2b871a05",
                            "FNeb9ecf1e69398bb828f33bc5dcc74eca",
                            "FN8bef32e9208c001f6c6697194d82e8bb",
                            "FNc0212593fe2948280f6e670e18610c95",
                            "FN5cbe87287036b1998aed6a3f2560cca6",
                            "FN9f4e43432290bf5357bf049150bd0f6d",
                            "FNc819fff91ee5f13ed959b4e5de4ce736",
                            "FNf46fccf68a0e06d6d9d55fb2c2aed99e",
                            "FN17636749916b6627f8ce964fa74a67b5",
                            "FNa2cfd587f32d183d6f6917e631cfc842",
                            "FN0fd6af9d6498c95f60a10a1e0535705f",
                            "FNdc6edfaa4cfbf2f97074486b26606bbb",
                            "FNb2d450f8d4a6f917b2b5b327a248cf44",
                            "FN36c8b69a2b29e064b455bcf067337a09",
                            "FN5eef22f834e10b98df006f9ccda838e5",
                            "FNeb62ebce2843ad8c9a21bc214cefee9e",
                            "FN6315f23793835080a219bcbd40c27598",
                            "FN920d182fc198f65773a30bbc7bc9a5d3",
                            "FNab9028562e02004150d4786e9caa9d4e",
                            "FNe55ed731c248b1c107a68f400800450c",
                            "FN2041a01b903823ec21f2df5ff3150383",
                            "FNb2ee33a868bef9f835f7eda225c56974",
                            "FN9da977ea5cf0f3016e93f4e03852f130",
                            "FNd13bd9cb39dfc43cecf580eb8504d63b",
                            "FNcada9f961ebe6ba4777afbb43d7405fa",
                            "FNc48bbbf692359e03ca7b0ca79cdb075a",
                            "FNa8fbc04a1879d97c0f6649a7405b1732",
                            "FN890817acb347249a9e0d4affd9889691",
                            "FN2caf841ad441058e61fae8c827f080ba",
                            "FN22d6f4624a3734d6bbc1d2d0fdd1d784",
                            "FN7b20afa4b9d38e323eeb33590d846e2d",
                            "FNefaa9b7ac48a0113900972f4b364a51b",
                            "FN4159bffc8a820e909daea5e69bd8eb8d",
                            "FN9127d3c01487240ab4ce272aea154f84",
                            "FN38ecf1d7e4225d8c2829d3c62a704e54",
                            "FN3996eff59fe59076c8cd1150f0a23859",
                            "FNb9702b673605f9d327ae94dca8064921",
                            "FN2e562e1e32069e907bc231c18058dd8e",
                            "FN80f7ec7e3b3a669a7fecaf9ed309b2d1",
                            "FNdeb4bc66f2d00a379b889bfa0e12b534",
                            "FN3672b1822b2c96db1a89882b2fa36950",
                            "FN128b2b2c9fd6b79c791aa0204ff0494f",
                            "FN3e173d8f8082102f79ccbe7ca3d4bf6d",
                            "FNfb9a04d34f4026b578eb50a62ce4fa77",
                            "FN98f92a946ed6cc0e3a397528aeb09a0d",
                            "FN78d4df355f26eb3aeb115a79c2207e21",
                            "FNd5b05190140bb627f97750dbb5969175",
                            "FN71e2c6e63c9811f82c573a015955a634",
                            "FN8b89cad20bfcd10ef18b7b8eabff7382",
                            "FN963cb4d296b046ecfdb57e3bb52f2e84",
                            "FN16cac370fab7cf56ac560a1586f6b820",
                            "FN001d838693f5517dc92d3f30b72bd6a3",
                            "FN9d70d7c535c268b82f6060a1aeecc9cb",
                            "FNcb55aec914ff825a039b122864d484b9",
                            "FN96c6446d438a7cffd1c347699ef126a4",
                            "FNdb88e1492930b5e527cdff1d83617968",
                            "FN77921bee2e1f2f4c1994c0e630af371e",
                            "FN720d40265540739dc5a51f2189dfb4d7",
                            "FNeef40bc640492bcb41cbf0daddafbe9b",
                            "FN5ce1f1a55a2a7c8327a4c66448e8a2a7",
                            "FNb5354be158a8a1e0d8882a43fc1ed688",
                            "FNe7bdf4b3762e6b1db37e992cd075a6f1",
                            "FN15527e13ec477c0063e01fbae6e62441",
                            "FN808273867956ba9cb0f9b509af29b478",
                            "FN7ffc4e7768a95c3c664c7a5c2607d0a1",
                            "FN6d1d668e59acc1b9656526191ca4b4d2",
                            "FN6f835f59d2f9f2f5b488701f8554ed3f",
                            "FN6eae2f1954a5b95d291164d55807a6cc",
                            "FN8d0cfc3b90d520406e20334b3ee8159f",
                            "FNd2c42a0401ab59fe8e54f8cd06934020",
                            "FN1c68012cffd4ae620263c262ed2cbaaa",
                            "FN92ce60eecf9bd8e8d77e267b73bf460a",
                            "FN2c9257f2f0dd9d3de84eb4b8c44dfc08",
                            "FNd08808662367ac8bb1f79d7d65e68661",
                            "FNee9f9f6443cd9e5fa138cbf0c7a076d3",
                            "FN5c6146736c28ea99a2b5155e48c970c0",
                            "FN35c4f536dbeab7440203ccf8988874b5",
                            "FNd6a105372d91654363417c9bccb3f67f",
                            "FN72b4eb9435c1909e076bf1e49f0c75eb",
                            "FNf3438a73cab22be1dea7f33bdcf4a0c5",
                            "FN21f5af5c71c18457e4f32a479d4d14d8",
                            "FNa7120def60e3ceb03190189b68a07d72",
                            "FN1240320a8c0afc88f91b667ae03517c5",
                            "FN207cd3473c27dc0866eb0a282e8586c7",
                            "FN0d9c5d167f7c108a7bbf62773eb0af09",
                            "FNc8d72d9f8aecfa15c7c8cc6f1ef2a8b2",
                            "FN3c64a83dddb6ff7df556fb5becd31203",
                            "FN4db0030e6e7323a6f0aa91fbefef9e6e",
                            "FNea31d6b415ce2e7443989f3da60241fe",
                            "FN4c9eb6f8f129ab1732b9ce6385de4203",
                            "FNec19124a1afce458e0ef7501390fcf45",
                            "FNb0f0fff91f0253ea4b6e17660206dca4",
                            "FN408f348c00bf8843c5466876456a2104",
                            "FN6bba166be6ed563bf093e1fb6504313e",
                            "FNaeeab51f6085e5a1731bb362808ba92a",
                            "FN35962b0cccd4a78bf751fb5b22565ef9",
                            "FNdb5ef8bf714252935822c7206ef00986",
                            "FNff6a4c996743a2ff8f77fa9dd0211c28",
                            "FN65811db862dc29635df2a15248d52d3b",
                            "FN2ce358a0b1c644962e4060f604f4bc18",
                            "FN576618a455f02f4eb7992454f41d778b",
                            "FNfe633c03d0a56cad579f90973eb675d6",
                            "FN9e13e1cbc492895a669a37223d33ba02",
                            "FN396bc8d3273bf43eeadbd872b9e7a0f4",
                            "FN90fc342de55c5d94db484b2fba8d5e5a",
                            "FNdf5aef485138e480395b47e9e4827cf3",
                            "FNc2f7452e6c0152ce39355e53d872e083",
                            "FNc2849489b41af89b380cca8f3e024ddf",
                            "FN87004fb102ccf2710bb7f8425ba0c4dd",
                            "FNb5bceb157d4c7e137b85595451ff1dfb",
                            "FN0f5ca2f1427b4a41b53b2d5d2db6dbac",
                            "FN9d4b3b40a6699104cf83ee92e36f97a8",
                            "FNf8c56a5b08ec0d4d98dfcc4fcdfa31b6",
                            "FNa53abf1db7092279d251448529449c61",
                            "FNb1197aaeba8be81a2ef529e6454fb4d3",
                            "FN35bfb0bdb4dfe7e3826697ca4dc40f9e",
                            "FN49a6900f3ac64434069a9c8c0659aeb0",
                            "FN5e3a4eb67457a8c344f06c1081e55b92",
                            "FN9331ffc227bf8f539e104971a929a84a",
                            "FN29dee11c4eb76f4c5eeb0966e7f4adb0",
                            "FNe63f163c70aed12d6c38d27fb7e0f27b",
                            "FN5a27ca8e0452323403cd9c6f03154bb2",
                            "FN652b6bcdacb5bf73a1dba1d32a0c248a",
                            "FN79ac7a825ba989056aecc8edf7d1a2d4",
                            "FNb68ce1c9a327b96dadd9418765683723",
                            "FN9d875fa2f449aecfff0a89209f4e918b",
                            "FN145f65ea7c3c246d23a12aff44acc8f6",
                            "FN72b972b4dc7c4004b011407bbd312abd",
                            "FN1a11dae2255bfa8f2066e1e166b6b00a",
                            "FNaf6b4f7808c291836748aed4723866ad",
                            "FNdd459ab1e16b4379c6b959c6f4abed6a",
                            "FNb7b4d91497fc0c23e9f5dbbb1582c815",
                            "FN275e490ff3c5ee2e4345654605599469",
                            "FN49ab4ff3ef3db9882344fc53bd4feba7",
                            "FN0f2cc94ee48cb8445e3afb5e59cb8866",
                            "FN72154326a709106cb226fd7bf381b3ec",
                            "FN53a97d1fb887859e0b0d615137b84dfc",
                            "FN660622f935ba66a75bb8c05e5b5a0360",
                            "FNbeeb5e1dcde5a91bf0bd8a82feb21f77",
                            "FN370ad55f16191471f27ea0beac45cbcc",
                            "FN5bd49f52ffad995ca3387eae2015bff5",
                            "FN1f7b6945e49a42528373553833a42250",
                            "FN306db900378134abda987eff1b3a7fb7",
                            "FN1c2d5435327a4ef24c864e6294aab0ee",
                            "FN48ca5afb03e2a242958c3c18c1c91ce3",
                            "FN97a74a880e594b6e9c8fe389b8669437",
                            "FNb5ca47efa0b0d35d01dc645f7e9234d0",
                            "FN419476b2dcdc13e0eb15070bb04c8ee9",
                            "FN7b729ba1cf738c313b9ed8b1c68c082f",
                            "FN1102f6684f089ff6a42f3312588f3ab9",
                            "FNc75a633b023643e30cfa5d8a76b8ccb4",
                            "FN820fd5139a1d87851c919a3a4ad783eb",
                            "FN51abf7da75c07257ceb4c58d883c4cc5",
                            "FNbbdc914d10194edeed5e9035a6f87241",
                            "FN23ed514d830caa92441b2e4fadc46465",
                            "FN70e72f118e6309f8d163cc24927a9b39",
                            "FNa6a80517f4a693851ccd9d65d05660fb",
                            "FNf6f918a1b59eca0bcde44c1d93c64d1e",
                            "FNe8be79c6b4105d11ee87a8c3a11221fb",
                            "FNfa2395f0630f60f6baa1bd7face77747",
                            "FNc8f3027cdb6806bca1a58c7c0b3dc098",
                            "FN69f0e99895cc5c375719cab94e500ae9",
                            "FN1ead2edbb10d7ae0257d7d869a1b1d49",
                            "FN10fabafecf412faf4cde2ee229503d68",
                            "FN74c92b329840024340c0490b2c18ed28",
                            "FN141a13350962282b9c300454037b5dcf",
                            "FNecc50eb83fe964bfffcc33807d1db7ec",
                            "FNbe5d3bedec197ab4167368f1be878c85",
                            "FN0463dfa54100b66fb76c991a3716f896",
                            "FNbcde854925139733bf853661cfa76f02",
                            "FNbad134afe76184a4681f1caa8efd5151",
                            "FNb1f958fb241ae53156513ca8ace6b58f",
                            "FN5d668cb829dc6e94f16fd4e3c535a11c",
                            "FNafe81337692fa72b36a3ae9e58a00fa4",
                            "FNe5d199674b7c32722c11f196d4bc6280",
                            "FN21d14df81dac7961bee4d8b78d9fe1a2",
                            "FN468f7ad799ffbc3c884ec8d2578a3c42",
                            "FNcf64e4fc47a657d6f2539e88741b3386",
                            "FN858de0dbe325adb0c25500594cf5be76",
                            "FNbeb1ae8427f7bf95c1310f438d74a2b0",
                            "FN28e9cecf3beef092e31e4990228f1c94",
                            "FN588f7a0054d8d1e848af65490cfdade3",
                            "FNbd064c39bed551f1feea7b5d377a73b1",
                            "FNda341de99551281170b5926b4a205519",
                            "FNe5b0f0491d442bad28331d41691653ee",
                            "FN3918f1eedf1623b3f73dc383c8564144",
                            "FN7877ae59c8cc1c7e13bb083fd929a250",
                            "FN77eed6c7f4a02ce1cd38e59de5091060",
                            "FN49eb84c1b0982ecc58ad5dae4d9a3a4e",
                            "FNd3db28e843099aaa7c0a92f9589b9232",
                            "FNfca97c0ea4023a82a09db2b004b2b586",
                            "FN4d6c35e4278d183a18193c279e560eec",
                            "FN16dcb5f8b087383caf41a981529a167a",
                            "FN4a8763d04446e77fc1540b9edea5767f",
                            "FN174e0345cac00001649eb418069e917a",
                            "FNbf91a8a0c5d6329140b45fbea468333a",
                            "FNbcb9f306b98d6a2925cb8c6ba8d0b033",
                            "FN3c3be57043c86bd755aed21ab58a6f81",
                            "FN92e347f8dd1fef4fb7579e6088d39e0c",
                            "FN91e0359f362384109a5079903666aa63",
                            "FN36be9729c054ac300298aa7ad9ee2cb3",
                            "FN5d0151a6f8ec017f815fa232bc12b228",
                            "FN6744f88a412d0901d1ec5e5b674c14b5",
                            "FN7094426d508943eecb918c6edbc3c120",
                            "FN0a287ed7d9417dcb738892a99729aa84",
                            "FN80a95aea38d005f51449e4fb1adb4719",
                            "FN09b1bf259ac48cc62eda475f2d2a9597",
                            "FN9ac52aa48c093e70d009939cc2cc9f75",
                            "FN160be1ae3a2d484a592afb42ec88a7f0",
                            "FN80a545ba6d93ff9ceee73ebd9237ca68",
                            "FN70ca847e2d1d4c03adf74ae9fc4e9459",
                            "FNfd54381d7b2156131fac890c171b4dfd",
                            "FNbd93c3be5b58cd139916af06c5ef5761",
                            "FNb081020d8e71399b5c73509bc77140c0",
                            "FN1d41133400e04ebd8e7bd55fb7dc3a76",
                            "FNc6fd666772c0f62a0b07aa953e9194f1",
                            "FNb5153a852c44d3c93241a59aef22a12f",
                            "FN3132ff005c4b5e05bc1251d690d8569b",
                            "FNfa9f4fe85e968d589913fccd16c2623e",
                            "FNb295a25415b562c34ae890f2a67c7901",
                            "FN48c8dbcb44f80145900631f0982c034c",
                            "FN2aa2417c68e539ea1ce4b90a7a873bb3",
                            "FN637662997707e5f1aadc443f3c21ed21",
                            "FN6c0640068fc6af163314ce92b1421727",
                            "FN7a0bb7ce7a14c89eeec8454164745448",
                            "FN5c8a5be311219e7a3e46e6b9e09d5cb6",
                            "FN65df544f589e27953d5569da0f4d8e24",
                            "FNaac8dcd3e9b21684f00aa5cf277b46f3",
                            "FN91701ba3373f8dfbb5c95fe00b05fead",
                            "FN422657ad12f7426d26724924a24a5829",
                            "FNacb82815594513c2ceb8d0c5b5a78f54",
                            "FN1986c88bf9f06fa2cf644c92cd9acf89",
                            "FNfdb98d323146946e4d28717d3867be31",
                            "FN5cc6e8a516eb221cd884365e8a6e7af2",
                            "FN8c0b665007dba7d3aa472e6737396704",
                            "FN7d2d4ab0477a9fc611275254845fb848",
                            "FN704d1225cf31a93c571aa4998481abae",
                            "FNa147b3ff32a9e9ccb372487e2beb617f",
                            "FNc6b225493f8e0f00c07e168937485b74",
                            "FNb6afdad5381764131aac73509f442d2f",
                            "FN692c8e96970fbe84ba86483d3c7b273b",
                            "FNbee65e930262778fb8fcf2aaad465844",
                            "FN5f43aabdd2206be35554dabd39827fe7",
                            "FN688ceb49ab9107875b2f0777f3461e4e",
                            "FNf26e3438fb5b906347c6c0ff85e9ac09",
                            "FN8be7c747e4e37ce494ce98da3012127a",
                            "FN296e9c5b9e54a5f9518a0421b90a6e94",
                            "FNecbbe87e67852eef63dc0d06549becaf",
                            "FN04c224568ae78a1f87cc026c41aa6c57",
                            "FN864516933535558cf1ce4901772f0a70",
                            "FN4383ec673d7c6afd627d20b4848d62d4",
                            "FNcb121f5e457dbdc4041af1cf9ebf4756",
                            "FN1cc29dfdce83c7ea28d25cc9210099e1",
                            "FN490576605aff4a1029879af461a270ed",
                            "FN52c8ddcd2416fb1e8394241768e13640",
                            "FN15e18dcc0718a6dd324b7aab1abe86e1",
                            "FN529a3e0a2551aca0ab24a63e3a0f77c6",
                            "FN20b29c265edaf4d890ad31875d167532",
                            "FNc15556981d14424a46aedaedd4a6993c",
                            "FN9989e28780ffcfa17d1f6995f1124c45",
                            "FN98aa3a6667726e90922d27e0d1641bab",
                            "FN695aa1bfa73bd37ca0cf49619fa03ba8",
                            "FN709ff20a769152251064a00810358737",
                            "FNb54d0dde668ebe18a80e5bb410d7b7e8",
                            "FNcc5bddd4c0db8b9ae49e8e1f3be2d664",
                            "FNb822e633b785d32fd305ea3b7d8b4313",
                            "FN0e94961cf572ca78e4d2482c05be98cf",
                            "FNc1f589135e74e7381ef411998d22c4ab",
                            "FN118089502795e03f0d5cf4fbc0b48a5f",
                            "FN55e50a0c19d948960ddff1e9fc0d9370",
                            "FNdb149073ad5b61fcb005ae3c3709a768",
                            "FNbf8ceea036095d8a8092e62225d27245",
                            "FNd7552be694c619a8c4cf41294bb435cd",
                            "FNf5b360640c1eb93bd7a80106075de1aa",
                            "FNce7d6af022bf72f1b86a8f1823438cb4",
                            "FNf41db1cb30e1b069d6b7615d20aa83ae",
                            "FN2e66b18d8157c8d65351067506f1e9ff",
                            "FNcf4864304475fdabbb26091a11e3926d",
                            "FN0589830cd99109d89a420c3615892a2a",
                            "FNe6fddb8a8e453c68a242d0a85af1a2df",
                            "FN642b67a9be84e6db734c717f860eb451",
                            "FN072112a24131f24889133e6e4c164313",
                            "FNb0d88b42713121eb41a8307975e4b105",
                            "FNb84297f72cebe7f91adb9dc48bb897e2",
                            "FN76e92cfafe6df2e26212978d6306ecd2",
                            "FN99e24f75d20422eb36022f3d71dd2c56",
                            "FN15eb9174cd185903e7099ae0c34854c3",
                            "FN425efd8aa8f614503dd62e25ff72ad11",
                            "FN47fb592fc7f173d9d16c13cfa2b88b84",
                            "FN77ed51a0f9dc9160a121e4f828ff7fbb",
                            "FN6c661cd2654c0aafc08e76382a89c2dd",
                            "FN164394dc5d1d309bd9d788688b1ade5a",
                            "FN076811009af372893fd9f7f3ea7777be",
                            "FN2944bcd3f8bcdb988efb4deabad65da0",
                            "FNd16652eb66640b62456ed04efeabe929",
                            "FN83e98d3e64eecf87d58fd29c5544166e",
                            "FN0c51b2df2974032e262edce4778f0105",
                            "FN19bd06b54a2ee4366da76901c38a6103",
                            "FN714413443d4ea7c33f24d3f04df8fa35",
                            "FN0006eb4c306dc1e36d38eb501f4efe56",
                            "FN59f5f1e57ae015f7360d1dc188f66bc1",
                            "FN3009fa82626354eeea3de955c1ed716c",
                            "FN3dbd5455ba04566ea05518c910e46205",
                            "FNefb62ad7a00b656fe03b410f1c4a536d",
                            "FN4c299450d35d59fef4090e0afc500986",
                            "FNe0510307a54553148ef67a60e920b03b",
                            "FN599a8d9755a921ec54366fdcf12bce11",
                            "FN878ccdd702d0ab2599edade2d62cc3e9",
                            "FN5d34fdb9697ed98ef024e4026825d208",
                            "FN4eb52c7f7bdb179e5127be0397b21ed3",
                            "FNa39727c540f416c4a0b782dac8f9d831",
                            "FN6482c87bed5226e6b2334452350ea3ce",
                            "FN302cf1e8248d03eac0205ffcc9d728e6",
                            "FN3b155d038e36645c75cc5cda9ef101a7",
                            "FN577d10980c63f3c449f25a1a8b67c2c5",
                            "FN4e07253910ee3246d3507b28c00ad7b3",
                            "FN65d753d752fb31e53b75e9b8ba7370ac",
                            "FNc6bc5d0458576ff0db6c8c363a41c02f",
                            "FN5555ddbb12fd770a4147178032040bc6",
                            "FN32a8194dcb5c46c547dce4be7cd6baa2",
                            "FN9c7d70740c64bbf5b77bbbd2d9c02cf1",
                            "FNa3c094d991e70c379b77f84ffe51eead",
                            "FNebc9dad54a57a28742d805802f114e74",
                            "FN7fb7f9271f377ffd70ebcb097b6b3fc5",
                            "FNbda8463a88d16d3a27570d0aed22839a",
                            "FN19f72a9a13d490d140b6233dd3999b0f",
                            "FN5c73be0dea4ab4e32e38d2769f19a42b",
                            "FN95e5ac7601948b799722fdcfc884f963",
                            "FNd4509228ea691a84299104f357d3746f",
                            "FN9730a3d2ee26278f462ede4a0b5ef5a1",
                            "FN7098d9ff816d4600a4440ecfb3e149d5",
                            "FN5106d996f8bcd4154404b8dea25c00b9",
                            "FNcd9c0d07c29ee9e071bc4d36ad5aec7d",
                            "FNb8dce67f2eb9b644018b9ed1e8486c80",
                            "FNc70a4b7212885218da2f8a5de62c0311",
                            "FNdc6c8dc2801a5bd26a03f6a84b282d15",
                            "FNc6a62bc7e49ffca81c2fd18d65240c6f"
                        ],
                        "msg_queued": 74,
                        "user_skipped": 0,
                        "user_update_attempts": 371,
                        "user_update_success": 371,
                        "msg_sent": 32,
                        "numOfUsers": 371,
                        "msg_undelivered": 32,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 932,
            "DaysOfWk": " 2 4",
            "TimeCreated": "2021-11-29T21:15:45.123Z",
            "OrgName": "Link Homecare",
            "PresetMsg": "URGENT from Link Homecare:\nPlease complete ALL 2021 In-service programs in Nevvon by 12/31/21. Get started here: https://bit.ly/NEV-HHAX",
            "campTime": "2021-11-29T17:15:22.799Z",
            "CampaignName": "Link Class 4 2021 incomplete - English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C33",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-29T21:17:19.230Z",
            "ProgramName": "Class 4 2021",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 94
        },
        {
            "MaxProgress": 99,
            "ProgramID": 477,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:16:47.436Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:03.849Z",
            "CampaignName": "SI Homecare- PCA 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 5,
            "CampaignID": "C598",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 527,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:03:30.899Z",
            "PresetMsg": "НАПОМИНАНИЕ!: FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:02:36.478Z",
            "CampaignName": "FADMO Q1 In-service EN 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C125",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 5,
                        "user_add_attempts": 5,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN919205803093eabaebd5777750a71332",
                            "FN3088a8b5acacc52c122f9bb403413483",
                            "FN520769a6e8639158e8bf97c502929431",
                            "FN16bfc4c780f378efcddff190262d70f6",
                            "FN9a2ae3ae2355c68624c5308668653302"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 5,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 914,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:49:45.971Z",
            "OrgName": "LifeWorx Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: LifeWorx Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "12 Hrs. 2021 - English ",
            "FrequencyInDays": 7,
            "LangId": 41,
            "CampaignID": "C499",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "12 Hrs 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 108
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 3,
                        "user_add_attempts": 3,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN14de4dbec040e3be526102855ac340d2",
                            "FN68924bf45d678e89e0e0943702b29c2e",
                            "FN13847153ad35441e9700471fc08d95c2"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 315,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:54:11.472Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 1 - Spanish  - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C466",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 1- Spanish",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 841,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T17:54:55.713Z",
            "OrgName": "M&N Home Care",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "campTime": "2021-12-08T17:00:09.751Z",
            "CampaignName": "M&N Sexual Harassment Training 2021 English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C50",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T19:16:33.437Z",
            "ProgramName": "Sexual Harassment Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 240
        },
        {
            "MaxProgress": 99,
            "ProgramID": 863,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:58:27.626Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:47.372Z",
            "CampaignName": "Q4 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 4,
            "CampaignID": "C582",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 862,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:06:17.671Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:32.768Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q4 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 7,
            "CampaignID": "C256",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNa3d71312f69ca7788d492c3c74e7d3e9"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:59:37.400Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "НАПОМИНАНИЕ! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:01.796Z",
            "CampaignName": "Ultimate EVV-HHAX 2021 Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C335",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 8,
                        "msg_req_sent": 8,
                        "msg_scheduled": 0,
                        "user_add_success": 8,
                        "user_add_attempts": 8,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN0037f198e0d59235f1b2817781fe6176",
                            "FNf6afea029dd427ba986586fb0c993b8d",
                            "FN2786d3f7909417a7476b056f399066ef",
                            "FNf1d26691cfdf2d5d5f4626ea05e74ffb",
                            "FN189b965e6d438475b16ec17914eb180c",
                            "FNb44ee95157d9895d1c60b08f4c7cb5eb",
                            "FNb04b5e57cc086f8d8bb46172865c53d9",
                            "FN024a1e8b937466e53c8d5955b4901de2"
                        ],
                        "msg_queued": 8,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 8,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 562,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:02:08.847Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:59.439Z",
            "CampaignName": "Assisting Hands Richmond - Q4 2021 - English",
            "FrequencyInDays": 7,
            "LangId": 41,
            "CampaignID": "C364",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:20:37.347Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C216",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:13:30.328Z",
            "OrgName": "M&N Home Care",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "campTime": "2021-12-08T17:00:32.056Z",
            "CampaignName": "M&N EVV-HHAX Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C59",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T19:16:07.526Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 240
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 3,
                        "user_add_attempts": 3,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN5b577d16b9d567f930d384ebba30f5b6",
                            "FN2957168d35237f8a627c958ac7b60899",
                            "FNc100f9c9d9c33d1916d4b1e1c386e12e"
                        ],
                        "msg_queued": 3,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 589,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:19:32.563Z",
            "OrgName": "Open Door NY Home Care Services",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Door NY In-Service EN Spanish",
            "FrequencyInDays": 2,
            "LangId": 149,
            "CampaignID": "C446",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Open Door NY- In-Service H1 Program- EN",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 182
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 283,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:09:56.976Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 2 - Experienced Aides - Haitian Creole",
            "FrequencyInDays": 4,
            "LangId": 56,
            "CampaignID": "C477",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T19:13:15.656Z",
            "ProgramName": "BrightStar Care Program 2- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 630,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T23:07:41.837Z",
            "OrgName": "Caregiver Provider",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:50.022Z",
            "CampaignName": "Caregiver Provider- Annual 2021 - Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C535",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T23:08:01.060Z",
            "ProgramName": "Caregiver Provider- Annual 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 192
        },
        {
            "MaxProgress": 99,
            "ProgramID": 525,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:02:39.685Z",
            "PresetMsg": "RECORDATORIO! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:02:01.457Z",
            "CampaignName": "Sophie Q4 Docs ES 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C292",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q4 Documentation & reporting ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:18:06.844Z",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "OrgName": "M&N Home Care",
            "campTime": "2021-12-08T17:00:29.378Z",
            "CampaignName": "M&N EVV-HHAX 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C63",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 240,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 524,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:01:53.294Z",
            "PresetMsg": "REMINDER! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:01:18.367Z",
            "CampaignName": "Sophie Q4 Docs EN 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C291",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q4 Documentation & reporting EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T16:26:00.999Z",
            "OrgName": "Friends and Family Home Care Services",
            "PresetMsg": "REMINDER! Friends and Family: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T21:00:52.649Z",
            "CampaignName": "Friends and Family EVV-HHAX 2021 English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C37",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T16:52:18.465Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 229
        },
        {
            "MaxProgress": 99,
            "ProgramID": 630,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T23:05:24.373Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:52.918Z",
            "CampaignName": "Caregiver Provider- Annual 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C533",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- Annual 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 265,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:45:40.768Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 2 - Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C461",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 2",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 863,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:57:40.198Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:33.704Z",
            "CampaignName": "Q4 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 4,
            "CampaignID": "C581",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 407,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-09-13T20:28:06.814Z",
            "OrgName": "Summit New Jersey",
            "PresetMsg": "Hi  {firstName}, Please complete your inservice ASAP! Download the Nevvon mobile app https://bit.ly/GetNevvon \nQuestions,? Click: https://bit.ly/NevvonHelp",
            "campTime": "2021-11-26T18:39:13.557Z",
            "CampaignName": "Summit ",
            "FrequencyInDays": 5,
            "LangId": 41,
            "CampaignID": "C13",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-26T11:39:41.413Z",
            "ProgramName": "Summit- Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 99
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 283,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:08:11.905Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 2 - Experienced Aides - Chinese",
            "FrequencyInDays": 4,
            "LangId": 30,
            "CampaignID": "C476",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T19:13:05.334Z",
            "ProgramName": "BrightStar Care Program 2- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 727,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:14:16.109Z",
            "OrgName": "Addus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: ADDUS: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Addus 2021 - Q4 - Haitian Creole",
            "FrequencyInDays": 4,
            "LangId": 56,
            "CampaignID": "C213",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T20:27:44.460Z",
            "ProgramName": "2021- Q4",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 165
        },
        {
            "MaxProgress": 99,
            "ProgramID": 629,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T23:00:47.889Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Caregiver Provider- New Hires 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C530",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- New Hires 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 470,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:56:23.112Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:56.220Z",
            "CampaignName": "JFS Care - Optional 2 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 5,
            "CampaignID": "C551",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Optional 2 2021- Spanish",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:22:10.444Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "EVV- HHAX - English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C217",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-09-03T14:28:28.941Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "Ultimate Care: Stay compliant. Complete training NOW. Open Viventium, click 'LEARNING'. https://bit.ly/ViventiumLearning Questions? -> https://bit.ly/NevvonHelp",
            "CampaignName": "Ultimate EVV",
            "FrequencyInDays": 2,
            "LangId": "",
            "CampaignID": "C7",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-09-15T13:34:06.089Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN1d571f917cef7488bb2b53d64f83cf67"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:37:45.389Z",
            "OrgName": "SelfHelp Community Services",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SelfHelp Community Services: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C417",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 242
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 57,
                        "msg_req_sent": 57,
                        "msg_scheduled": 0,
                        "user_add_success": 57,
                        "user_add_attempts": 57,
                        "msg_delivered": 23,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN065d29aa6fd4793092aa44b1fd0508f5",
                            "FN487ffd9754696c2340b5b2bf09e97200",
                            "FN9e53988c51b58b7d57b53325b09dc481",
                            "FN74d633ac3eedbd12373fd8b1b40f66b9",
                            "FN38f4db4069b736851315553bd25464b7",
                            "FN935043b5b4cc4e5d993a353c16360a46",
                            "FN077437b292d4ed08c1d90accd6a25bb6",
                            "FNcb3253e782c48106c953e0c3d21be867",
                            "FNd5aa370b5b3116995ff00a612ade8e86",
                            "FNd4ec6ef28b78e228ef69be4759625c58",
                            "FNc666e02ab9df6a0fc885d196da2ed533",
                            "FN284d1b8fc44a4a083c07841c7cca94a3",
                            "FN4f1cce2a74d0db6c2251966e6eb496e7",
                            "FN765d6637a51ae49b6c3614dad0f8edc8",
                            "FNb227b054dc0b2be402ae5243ba256de6",
                            "FN9549301e1ed680fcc1d9f8b8d9c5b2b0",
                            "FN7d04b7a5fc027fc14d1534a54340adb7",
                            "FN2472856e65ccda8fd24b3bab5f190f93",
                            "FN271c7ecbeb4e4d7fd02aedcfaa09ac71",
                            "FNab0751afe6a912d4d044dd9f56a38c89",
                            "FN03d1bea63dc996bfa2247ab428c86ef8",
                            "FN4dead917df41f9e73063ee909a0fbac0",
                            "FNee6118e8dbe6e467951f4473431847c9",
                            "FN3de8a6c9010cf898fdbaf82fdd37b769",
                            "FN2f50be30c43c895742adf7498d1a8c70",
                            "FN72c56168f39cc0686cde657d2c88692d",
                            "FN3adc553ecc257c9a3d8d34949e1ef3fd",
                            "FN6869c84e87dc7c20739df512dbddec7e",
                            "FN5212260e2df2e872fd64d5a7ce22a58a",
                            "FNd999c6563177d3980ffbe9fe3a660319",
                            "FN7f029c1101b740d49963775e44f16972",
                            "FN22d45b4bdde6fd978071f27958654199",
                            "FN56f07f538c3415ad4b838fc73fd950d7",
                            "FNbba20fbdefdc9bcf11b3c6b6720227cb",
                            "FN86a508a3419353646c18439f273a176f",
                            "FN310e2b630038c02eaba73f7f47154dcc",
                            "FN55672eecc30314df9cc062e862830550",
                            "FNcb332735629c4fe71da8868ac8997128",
                            "FNfbf25c30437958fe0fa08779df51caab",
                            "FN3402abff995df28df78cd4fbb03cb9a1",
                            "FNf0672d435c52f779aefdf7690e89eb2d",
                            "FNec401b0723d234a709d527ef945d7a9f",
                            "FN7c9ad4b1bc7ffebc28211e2f86db400c",
                            "FN47bc76cbcf35e89f1f8a72b2e4477d1a",
                            "FN2580bb90659ecb2b0312bf8b69293a7c",
                            "FNdaf20d85cb92a35add77f746cdc2416b",
                            "FN843d2dcabc741737c2cd805ae412032f",
                            "FN407c23a4e8951b6dfd10ea6817d73f0a",
                            "FN02b7a061acceb05fc164d26900dc91cc",
                            "FN882b51c6c2a8971186b33df266abb081",
                            "FN6209a044402781752067661894f6fdc9",
                            "FNfa9f425e0cd43b31f5a6cdf4c13b8da6",
                            "FN23150357a02ec868f34d1b701ae75009",
                            "FNb2ff599a1518ae627d68b3fc947fe9c5",
                            "FNea34ff45950c36369cf3d05c97ca1158",
                            "FN839127ad36f8b1f9718aedd7e473e897",
                            "FNdcc67dde0d22bbcdde16dab3d5922e1f"
                        ],
                        "msg_queued": 30,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 57,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 680,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:03:28.642Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:36.146Z",
            "CampaignName": "4 Hours - In-Service Program - English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C430",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "4 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 625,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:27:04.565Z",
            "PresetMsg": "REMINDER! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:09.158Z",
            "CampaignName": "FADMO Q2 In-service ES 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C143",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 614,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:07:36.788Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Penn Home Health Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Penn Universal Program 1 2021 None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C553",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Penn Home Health Care- Universal Program 1 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 190,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 461,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T20:37:37.396Z",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q1 - 2021 - Russian",
            "FrequencyInDays": 4,
            "LangId": 134,
            "CampaignID": "C504",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston Q1- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 136
        },
        {
            "MaxProgress": 99,
            "ProgramID": 725,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:30:21.365Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV Training - Chinese",
            "LangId": 30,
            "FrequencyInDays": 5,
            "CampaignID": "C603",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV Training",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 2,
                        "msg_req_sent": 2,
                        "msg_scheduled": 0,
                        "user_add_success": 2,
                        "user_add_attempts": 2,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNc182c13140b74e8acd784a93a22f292c",
                            "FN746df7cd83e6ad3b09a879735c9b19ef"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 2,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 290,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:35:31.658Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 1 - Inexperienced Aides - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C493",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1-  Inexperienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 204,
                        "msg_req_sent": 204,
                        "msg_scheduled": 0,
                        "user_add_success": 204,
                        "user_add_attempts": 204,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2dc1845e47473443a73b01a9f3e6128e",
                            "FN2007dcee998dcb7d1f3004535c6ab095",
                            "FN9a4d7a24cfee530215833c340de58ffc",
                            "FN58dabd89c96df244e2e74b993bf61bde",
                            "FNafc522407595a74cbf10a956cfe408c9",
                            "FN20a0c7f57328f641b8e87a823600b5db",
                            "FN45116194550c1cec5bbff88dc610779f",
                            "FN52f18779d4ffa24291f83cc6ea6f4023",
                            "FN9d7a18111be7005f092a76f77f09103e",
                            "FN01d57310d9286d79a8b1c721815fed06",
                            "FNd067d51bcf20a5eeaa06e452ef5076e6",
                            "FN50f25141978b7ba8eae0f2cb04760e13",
                            "FNb7dc009d0b84c0088ff7dc668338cf30",
                            "FNc910b3b11d2283f9f07792c49da97ef5",
                            "FN74487e3b100038fa808f1bbb68fe0a47",
                            "FNe47705f18336447ffb251fe556a68676",
                            "FNff405382a436a34acc67ae3ed6ce30a2",
                            "FN243cded23edc8ca33529390cfa713e09",
                            "FN1e1e68119b6aec7e0bb6546e00b6d32d",
                            "FN2fc54f9d2f1d0a1858abbdab084b7eba",
                            "FN5c9562ca5897a13b5232faab9a2959ef",
                            "FN3c3178df94c789c644b35a8eabca4f99",
                            "FNa296a9562a319a19452cd75fd220e2e0",
                            "FN6893aab1044c7238a6f73fb60d912619",
                            "FN6246468655faffca49f3e2e240141687",
                            "FN9564ef876969e4dc5fb469ee1b83d1da",
                            "FN869c51e6ea2e80bf53b731864325b789",
                            "FNb1e3d565e579ec2ce7c838890c20b738",
                            "FN3b4b200fce9553ee9d0dd7c0b89129b4",
                            "FNe964fa79b2534e56d3521c1467f0ef7f",
                            "FN4104243645f73468983bfe7087bf288f",
                            "FN58f9c020182b51aaf7449d7000f510e1",
                            "FNf7e5891b396d2bc5d8a8eb595fee36f8",
                            "FN2b6052ab63b9e16b363a97ae8bb9c834",
                            "FN7c8f7c819947e19f2bb1df6e0c2c7f1c",
                            "FN85865fde89b27b95a253d43dc758faab",
                            "FN8e18ec3b4350ccb160dd8388edfdba20",
                            "FNcae47862624df5cdb955a83e3f48d770",
                            "FNb70b96ab9753d37bffee097da87e8ee0",
                            "FNa2b6fba13fb97a2b50aa8466d7cc155c",
                            "FNe150432bcd4453ab7aa1ef0bb4429642",
                            "FN68c1ceb8d6aa9a67da0a6aa310db4135",
                            "FN2cdce151e6928c4a574a4d57ca2fcbfe",
                            "FN9b803f2b97ef40f4d019feaf3783a35a",
                            "FN89b02fce308edeacb06ed861da0de644",
                            "FN59ba7e4a3551aeefb644d5207b48ad94",
                            "FNb71380f846d44d8cd523196c697e9e66",
                            "FNd4adcc223ab9322071729cee4059003b",
                            "FN3a45369c07f9f88b326daf333be44b9d",
                            "FN08f6b8dd4b456de4f9bd62fc1168d59d",
                            "FN28b390165265bfd7161bf41b3f6093f9",
                            "FN276269a006cf0f64bbe4cfa3c8b73377",
                            "FNd8d756aa1af60d721fcef4cc4aa15dcd",
                            "FNe4d0a7d67115162e66a6c360856d9f7d",
                            "FN674374f6dec8e66511014d987336b7a8",
                            "FN4eb492d852b533474a841fedfb889e97",
                            "FNeda9c1261c0fa42519a8578341e1d7f6",
                            "FN7efb69586280a133277c7a22fcb4fca3",
                            "FN737ee881702327326b0d714cb7a90b8a",
                            "FN463e15df4da003b9432e96f88212dc79",
                            "FN21266ef69a09e931e6fcd2a20adcbf6b",
                            "FN660449fad2afc5f71788c00cb97d0a16",
                            "FN8702e0fd287be9f20a6d9b9d6ecd4113",
                            "FNdbb3b401f1fca1d2cd693f4b91373187",
                            "FN270ea26ce7f16e45a8507995a3c8127b",
                            "FN9fa538f792bff1ed71fa1bb8cc4de021",
                            "FN2df5ce6932873ec9d23c18a539f4ebd9",
                            "FNcc1b9fa08f0c0ccd37010e8300abe7ca",
                            "FN8202fa2d740ef7fe9c1add3828828a0f",
                            "FN6c1a29d42ac912bcb42fee9f1afdb338",
                            "FN7c43fc3be7d4b9464e1266b4f9461799",
                            "FN47935fd3141d81f5d4bf39c795830f12",
                            "FNa03b07f663e6349cbfb172abb8007ef5",
                            "FN4ff77331566b1a15f40639a81b4a6426",
                            "FN12b6104baab2c7e62d73069520cfb099",
                            "FN984bf1947a6e5311cc56e044bff2722e",
                            "FN1a8bcd540675619277965cfd1a920f30",
                            "FN69b73c5b0f0348827d1a748cffd18ac7",
                            "FNb5978e112d260f822fd499c86ef57e6f",
                            "FNeb589053b26a1c98db4961db56567ebf",
                            "FN58efd6aeeb1b7b357ab8e5672457c7bd",
                            "FN35be398d90de5a62ea63be8fe2b6cc7e",
                            "FNd2cff43be594122b22339684265c5e18",
                            "FN036cec857848a34258340bbeb369238a",
                            "FN1bd0f19b5e37e4fcd6e8c7b596a95426",
                            "FN5d1114fed7ce28e52cc9bb418dfdc7b3",
                            "FN34ae65c94fe6090617af6de9278886dd",
                            "FN73c3efb64014a0631833877b5d6fada9",
                            "FN7f01e710d16c327e1ab327160f15bda4",
                            "FN77ce3dd5ad5039b9a1053c3ae9ecf4e5",
                            "FN9a97a251e18f9328ec0654d1f7476083",
                            "FN928c65fd8cc24e21daf569dacb2aabdc",
                            "FN37cc074f2d40b7bf2cc186800402604a",
                            "FNfc3b168dc219cefccaa4249923ead6a3",
                            "FN094de600055b1af187a1d3abe7a7d7e3",
                            "FN0063bb6ae6a04936d8630b940469060d",
                            "FNaf0cf7496284837b1ff17f1a80996792",
                            "FN929606a3d33773946230d4ed46a7170d",
                            "FNe0fc5d750419ba58a538676513279ca1",
                            "FNbd5932fdde5e677978a7698cdb945d26",
                            "FN21733880b640a3ceb60f7034c1fbc6d2",
                            "FN26955774f3ae914b270ce2848baf8348",
                            "FN69e36be64e2dc428e1d354bcbdc58d05",
                            "FN8a871f221c7e631f2ba1a5d292835f67",
                            "FNa72332e7fc79d689564b4c3b91928830",
                            "FNb18dec0878c05a84766b0c4b5b0563a8",
                            "FN2b939fd14555f5108e186970da8947f3",
                            "FN64bbb6c4d161bca50e9ab6531b589011",
                            "FN46084e7f1dff2a52851df6d10805d76f",
                            "FN57d6b1e8e2261793a418fa11881fc07d",
                            "FN95864e7a0247aeebb61000ed9ee3fccc",
                            "FNcc729c596b1cd50d8097f68ce297ff0e",
                            "FN9626ed59e481c93c61c0af92c43696a8",
                            "FN2834431fb87b4fc6c3996026a293857b",
                            "FN70fbeb6ef5be704471338fe4947ee481",
                            "FN6fb056e02a826cc73e4fc2d17eb675ff",
                            "FNce69b8300b06cf45b291b5cc3770bc98",
                            "FN8f650da67758c97a3cdcc3c27cf24fd4",
                            "FN29e30750f9b3d9fa1ae170588d4cd616",
                            "FN9d81c15151e460f03ac1dda730b15713",
                            "FN1495050ed61d6565b22bf49347bc9711",
                            "FN2cb5630109e1299f891821d1eb80d397",
                            "FN80d5a459fec7dec6c678fda9652333f3",
                            "FN1d34e6a88376ba1deb4d7a6b102b70be",
                            "FN215e87cbf64b934f6a0408dfb4b7b082",
                            "FNda9ab9d3c757a0037da02f842ad346b9",
                            "FN8147659d800741df3bae9838bfcfa23e",
                            "FN1758fb1a481c3a5e028901767208b0c5",
                            "FN3d9cc1dde39056ca19a8d5dc54baee45",
                            "FN248fa629374b4c092076ed493ac2914b",
                            "FNdac4f6b5971eb46ae0246e86174f9da0",
                            "FNc76f52501ea1039af257f1860dfa0387",
                            "FN0d0db3e622b573c07f4517a3e84844eb",
                            "FNbafac1b6afc7a645b364f53f5160b18e",
                            "FNfa038760e4c83dee46a5d1c127887212",
                            "FN32d2fbd86ba93da63f327f09a2ccedd3",
                            "FN3e3892517e9175bed0787aaab93db427",
                            "FNc4852323fac0c800e59c4a33829013f1",
                            "FNb8b7b78dd3ca5794c853503d8210af31",
                            "FNe0498894e0fc79b85172eb0ecf1b4934",
                            "FNfcfbbefbbfbd316a9b2d8009c10150d6",
                            "FNcf7f41e6b89820bf4923ec331e1f061e",
                            "FNe4a35e1788843058746a818d8bac13a6",
                            "FNe6df156ddb4f984f0707bf59baa8bae5",
                            "FNd43632a1da6d8e4722c16b7862f2fdb7",
                            "FNcab200166e00a570040f13d3b83ea70a",
                            "FN223a554f0b4410d81cc4f0564751a8ac",
                            "FN42529d901c71cc3815867b78e4c159bf",
                            "FNf12e5d6dec8aca995b3b2f9b22b05fef",
                            "FN16ee5a2f4aa92064e8ded580cada9dd1",
                            "FNe5932c7e127f5d0f54561248f64db50b",
                            "FN78c53699b94504549d7c158a6bdbe120",
                            "FN2fd8a2f4a7d2415238c255275e822b59",
                            "FNe4480c538f57c082a5e074aac6b1ad60",
                            "FNd729c6912c95f0fde53b5958db39e3d2",
                            "FN0c29ace51572a693d8c356d9b7fbfa2a",
                            "FN0f4d2049cc8b5a9101ef00223e3788f0",
                            "FN6e89ca009fd016492c0f9bd6c9e6c242",
                            "FN8d4908d0082b7f7c833a2e8b15cef044",
                            "FNbacb51be58a4ecd4e2d0d41cf1c7b6c5",
                            "FN6fa9f13ac5eba1b6c67ac7a234c75310",
                            "FN4286ee4c684fa7cfd209c9d7c800a710",
                            "FN2f17263ecf4b07504f7b2c508a6f5949",
                            "FNa16a1ed07487312d02f4efda3b7dcd48",
                            "FNc74e4b8a1f2a833987e4f11351f4af83",
                            "FN564da21a98886a826b379e740828f834",
                            "FN54b0d5f5456d5b0f8ff8b06a6e86ccdf",
                            "FN05cf15e6a3dde03862fc4e45761c6846",
                            "FNde6d52f295c87a690a6be36e4e8867f4",
                            "FNa87f575d03929b1396188d9e0764ceaf",
                            "FN32dd66c1563f4481b429bb1c5954267d",
                            "FN1b60d676cff17e22c03a7d9ffed1a001",
                            "FN4cb4d33dbd37a3289e6bb83313afec81",
                            "FN821078355708af12eab2554d112b4cfb",
                            "FNa124d2250f800436895a0a37d9156d1c",
                            "FN2bedad0cc0b349b5919d8abbdc407e9b",
                            "FNb43dd8619729db83f7f6919229756638",
                            "FN7927b44b45a179cd34cda825f6481b3e",
                            "FNf32f2250c601c6e4c34a5f38c4307ef0",
                            "FN682d76e22d46dbf429706b8185340b31",
                            "FNd605667101373ccc26460137072bbfec",
                            "FNcba2745cae4280f8f8df78ada74eaec0",
                            "FN9cfa31b2dd27b4ff3217efed2c6245fb",
                            "FNb52ba75e26ac050b5d5a49f5902d0763",
                            "FNec740c209f9f00784e4cf6cbe77e56fa",
                            "FNefc62a6b3cff1a4b363f64af70b4d7aa",
                            "FN257b275c9d3650106d12c234ea123cf7",
                            "FN19fb853c971da4fd0c94d6dc22c086e7",
                            "FNd10d1ad6e6df5e996fb75a3ec85e9471",
                            "FN51c88088eed4f1a14dda3b48b65d108c",
                            "FN98665452885f373fdd64bc46f19c9893",
                            "FN4cd35c266918663cfa4709b6e1281dae",
                            "FN99bbb972c1c09905ee40a1d66f4b4418",
                            "FN2fa00a399e160a5dabb161cecd746266",
                            "FN432f2d53b7eaff18ec785c7ecdfb49bd",
                            "FN5a5f0e2f15b566d3a5432d5b1b39ffea",
                            "FNf53be29b1d4bc4be1716f4261b0a3cd9",
                            "FN75caba5cc14cdb51e06b9b9824779c8b",
                            "FNec73fafb15e324c54bf06f4afad55b64",
                            "FN95295c546ebdd9df5177071ee236c2e6",
                            "FN77dfe4d8cb11942324b3f7310e087029",
                            "FN7aa300108aeabb5d1219c56c2eda2505",
                            "FNd9709d19dcf11997ad9ba1faf9ab6d50",
                            "FN76d3e2553a5b146d31dea9b14c3b3a64"
                        ],
                        "msg_queued": 202,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 204,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T00:00:21.416Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "RECORDATORIO! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:39.281Z",
            "CampaignName": "Ultimate EVV-HHAX 2021 Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C336",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 2,
                        "msg_req_sent": 2,
                        "msg_scheduled": 0,
                        "user_add_success": 2,
                        "user_add_attempts": 2,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNe8f376ea38324d2ccfd9bf07474f54f3",
                            "FNf7e64532bd16ac0a2b6f0a064155cca0"
                        ],
                        "msg_queued": 2,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 2,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:40:57.397Z",
            "OrgName": "SelfHelp Community Services",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SelfHelp Community Services: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Hatian Creole",
            "FrequencyInDays": 3,
            "LangId": 56,
            "CampaignID": "C420",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 242
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 15,
                        "msg_req_sent": 15,
                        "msg_scheduled": 0,
                        "user_add_success": 15,
                        "user_add_attempts": 15,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN1857b13616408c9d4e9d1d28c900b41f",
                            "FNe760912df62fa7c425d68e957c798ff2",
                            "FNc13fb9a1be5b1a42309b967482e3d85f",
                            "FN7add54a217c2636e77d6e8f2bc8e4aa1",
                            "FN43bcb42e71e82bfdbfb3e63021465516",
                            "FN7e43e9948ab0fb742df94779e7e502af",
                            "FN6fcab8ecc4b0971c69ade81ace8e286b",
                            "FN7c43f86daf3e63e3b60d44264d8998be",
                            "FN5536ddfe087dc709b0f4abdcb42ed005",
                            "FN8b2f2c48f81836693b05dd8c1fa15d9d",
                            "FN98fed7ea2c7af5688d046ac6cf54445d",
                            "FNa9e6a1fc040819535e7a65b3bb84f9f5",
                            "FNaa8f7dd835d76a78a3d16aa239861d87",
                            "FN9cdfedf10d24c699f5dd032756e1d738",
                            "FN564a31976d2115f36bbe9d6be9c34518"
                        ],
                        "msg_queued": 15,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 15,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:18:04.831Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C443",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 863,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T04:01:42.648Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:52.924Z",
            "CampaignName": "Q4 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 4,
            "CampaignID": "C585",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 720,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:14:53.179Z",
            "PresetMsg": "REMINDER! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:23.654Z",
            "CampaignName": "Greater Love Orientation 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C192",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 265,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:47:23.784Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 2 - Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C463",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 2",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 719,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:10:37.364Z",
            "PresetMsg": "REMINDER! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:07.777Z",
            "CampaignName": "Greater Love In-service2 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C185",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 710,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:52:44.910Z",
            "PresetMsg": "НАПОМИНАНИЕ! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:58.819Z",
            "CampaignName": "Compassionate Hearts PA In-service1 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C161",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 464,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:41:03.695Z",
            "OrgName": "JFS Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T22:30:00.570Z",
            "CampaignName": "JFS Care- Optional 2021 - English",
            "FrequencyInDays": 5,
            "LangId": 41,
            "CampaignID": "C544",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Optional 1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 109
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 652,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T16:01:28.397Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "来的温馨提示: A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T16:01:20.398Z",
            "CampaignName": "A-Plus In-service1 ES 2021 Chinese",
            "FrequencyInDays": 5,
            "LangId": 30,
            "CampaignID": "C386",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 ES",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 440,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:37:23.686Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q2 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 4,
            "CampaignID": "C571",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 735,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:27:47.266Z",
            "OrgName": "Gurwin Homecare Agency",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Gurwin Homecare Agency: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Part 2 2021 - Chinese",
            "FrequencyInDays": 4,
            "LangId": 30,
            "CampaignID": "C449",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Part 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 107
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN99fa63e5d890e64bf764a65b3e82fa82"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 481,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:25:55.112Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 2 Spanish 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C410",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T17:26:30.911Z",
            "ProgramName": "Recco- Class 2 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 507,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T17:33:08.254Z",
            "PresetMsg": "НАПОМИНАНИЕ! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:15:21.086Z",
            "CampaignName": "Elite NCM Class 1 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 5,
            "CampaignID": "C45",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NCM- Mandatories Class 1 2021 Russian",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:35:57.328Z",
            "OrgName": "Susquehanna Home Health Care",
            "PresetMsg": "REMINDER! Susquehanna: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:18.878Z",
            "CampaignName": "Susquehanna EVV-HHAX None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C327",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 234
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:38:21.471Z",
            "OrgName": "Susquehanna Home Health Care",
            "PresetMsg": "НАПОМИНАНИЕ! Susquehanna: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:39.861Z",
            "CampaignName": "Susquehanna EVV-HHAX 2021 Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C330",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 234
        },
        {
            "MaxProgress": 99,
            "ProgramID": 479,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:22:18.403Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:09.502Z",
            "CampaignName": "SI Homecare- Support Staff- Medicaid Compliance 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C599",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- Support Staff- Medicaid Compliance 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 4,
                        "user_add_attempts": 4,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNe4ceada87d6006e38ac6da37de011e44",
                            "FN49f1051d56234eec460dcd4c5f0e7ce8",
                            "FN04931d936b6b99bde40f3326527ee778",
                            "FN30c95965b278eb63009659569fa9369f"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 482,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:27:41.111Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 3 Spanish 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C411",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 3 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 623,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:25:42.916Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q2 - 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 4,
            "CampaignID": "C507",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 136,
            "Active": true
        },
        {
            "MaxProgress": 100,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 735,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:31:05.132Z",
            "OrgName": "Gurwin Homecare Agency",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Gurwin Homecare Agency: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Part 2 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C452",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Part 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 107
        },
        {
            "MaxProgress": 86,
            "ProgramID": 16,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-08-25T14:55:29.786Z",
            "OrgName": "Triple A homecare",
            "PresetMsg": " {storeLink} ",
            "CampaignName": "camp1 dummy",
            "FrequencyInDays": 4,
            "LangId": "",
            "CampaignID": "C5",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Tincan test sozpic",
            "CreatedBy": "uri@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 64
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 2,
                        "msg_req_sent": 2,
                        "msg_scheduled": 0,
                        "user_add_success": 2,
                        "user_add_attempts": 2,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNa6ac9aa614e4cbbe9d9a3e614089e976",
                            "FN9f6364f803d2588515c16365e775f2a3"
                        ],
                        "msg_queued": 2,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 2,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 602,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:09:22.531Z",
            "OrgName": "Open Arms Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Arms In-Service 2021 EN English",
            "FrequencyInDays": 2,
            "LangId": 41,
            "CampaignID": "C437",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T22:03:28.789Z",
            "ProgramName": "Open Arms H1- In-Services 2021 EN",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 187
        },
        {
            "MaxProgress": 99,
            "ProgramID": 559,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:34:53.109Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Richmond",
            "campTime": "2021-12-09T17:00:37.800Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 7,
            "CampaignID": "C270",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 141,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 710,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:51:06.196Z",
            "OrgName": "Compassionate Hearts Personal Care",
            "PresetMsg": "REMINDER! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:28.765Z",
            "CampaignName": "Compassionate Hearts PA In-service1 2021 English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C159",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T17:03:18.399Z",
            "ProgramName": "PA In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 220
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 791,
            "DaysOfWk": " 1 3 5",
            "TimeCreated": "2021-12-10T00:04:42.299Z",
            "OrgName": "Paramount Home Care Agency Inc.",
            "PresetMsg": "REMINDER! PCA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:04:13.897Z",
            "CampaignName": "Paramount PCA 2021 Haitian Creole",
            "FrequencyInDays": 1,
            "LangId": 56,
            "CampaignID": "C342",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Paramount PCA Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 260
        },
        {
            "MaxProgress": 99,
            "ProgramID": 629,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T23:03:40.108Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:31.021Z",
            "CampaignName": "Caregiver Provider- New Hires 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C532",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- New Hires 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 439,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:51:43.064Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q1 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 4,
            "CampaignID": "C568",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q1 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 528,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:10:38.041Z",
            "PresetMsg": "\"URGENTE De FADMO:\nComplete TODOS en servicio lo antes posible. Toda la capacitación vence el 31/12/2021. Empezar: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:06.377Z",
            "CampaignName": "FADMO Q1 In-service ES 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C128",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 841,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:14:33.129Z",
            "OrgName": "M&N Home Care",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "campTime": "2021-12-08T17:00:35.048Z",
            "CampaignName": "M&N Sexual Harassment Training 2021 Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C60",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T19:15:57.866Z",
            "ProgramName": "Sexual Harassment Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 240
        },
        {
            "MaxProgress": 99,
            "ProgramID": 505,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T17:31:09.618Z",
            "PresetMsg": "REMINDER! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:10:02.496Z",
            "CampaignName": "Elite NCM Class 1 2021 English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C43",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NCM- Mandatories Class 1 2021 English",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 796,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:23:47.941Z",
            "PresetMsg": "CLASSIC HOMECARE: Complete training by the 20th to keep your certification! Go to the office if you need a computer! start here⇾https://bit.ly/reminder-HHAX",
            "OrgName": "Classic Home Care",
            "campTime": "2021-12-09T17:00:55.190Z",
            "CampaignName": "Classic Compliance 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C196",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compliance Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 239,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 915,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:45:16.606Z",
            "PresetMsg": "НАПОМИНАНИЕ! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:20.407Z",
            "CampaignName": "Community Care 2021 LPN/RN Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C76",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 LPN/RN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 57,
            "ProgramID": 27,
            "DaysOfWk": " 3 4 5",
            "TimeCreated": "2021-07-28T08:40:22.531Z",
            "OrgName": "L'Chaim",
            "PresetMsg": " {programProgress}  {storeLink}  {programName} ",
            "CampaignName": "French course",
            "FrequencyInDays": 5,
            "LangId": "",
            "CampaignID": "C3",
            "MinProgress": 17,
            "ModifiedBy": "uri@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-08-04T13:25:52.341Z",
            "ProgramName": "L'chaim- Annual Training",
            "CreatedBy": "uri@nevvon.com",
            "Deleted": "2021-08-19T21:24:47.986Z",
            "Active": true,
            "OrgID": 103
        },
        {
            "MaxProgress": 100,
            "ProgramID": 875,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-11-26T11:38:25.692Z",
            "PresetMsg": "From Alternate Staffing\nComplete your inservice ASAP. Download the mobile app ---> https://bit.ly/GetNevvon  \nQuestions? Click https://bit.ly/NevvonHelp ",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-11-26T16:36:11.902Z",
            "CampaignName": "Alternate PCA ENG",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C23",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 711,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:57:53.901Z",
            "PresetMsg": "REMINDER! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:20.174Z",
            "CampaignName": "Compassionate Hearts PA In-service2 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C169",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 10,
                        "msg_req_sent": 10,
                        "msg_scheduled": 0,
                        "user_add_success": 10,
                        "user_add_attempts": 10,
                        "msg_delivered": 5,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNcc4a9b25250459f4a1dbb50964cc714a",
                            "FN9012e287036fd7aa200ed3a111065f9c",
                            "FN542fe0f8ec40732a536b119d48e65222",
                            "FN545614a370b42589f560befbc2150d37",
                            "FNb7f486dbf4f89c074cc28ea0676336e6",
                            "FN888d357a63688cd9451455b84f6f1230",
                            "FN990cf626986f1e4d595bd2c0f0e70366",
                            "FN3a753f9f0a3f8bba0ccc39d8602418b4",
                            "FNe2a8074459ca3b48cf4d6e5f0c341e52",
                            "FN514d9b2600f3d2a8d6581d899ea71d25"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 10,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 653,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T16:05:46.499Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "НАПОМИНАНИЕ! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T16:04:15.186Z",
            "CampaignName": "A-Plus In-service1 RUS 2021 None",
            "FrequencyInDays": 5,
            "LangId": "null",
            "CampaignID": "C388",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 RUS",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:58:53.298Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "来的温馨提示: Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:16.412Z",
            "CampaignName": "Ultimate EVV-HHAX Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C334",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 461,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T20:38:30.710Z",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q1 - 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C505",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston Q1- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 136
        },
        {
            "MaxProgress": 99,
            "ProgramID": 727,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:06:11.901Z",
            "OrgName": "Addus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: ADDUS: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:22.518Z",
            "CampaignName": "Addus 2021 - Q4 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C210",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T20:26:10.226Z",
            "ProgramName": "2021- Q4",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 165
        },
        {
            "MaxProgress": 99,
            "ProgramID": 687,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:58:20.772Z",
            "PresetMsg": "REMINDER! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:45.115Z",
            "CampaignName": "Angels 2021 In-service1 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 2,
            "CampaignID": "C91",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 625,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:28:31.374Z",
            "PresetMsg": "НАПОМИНАНИЕ! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:52.659Z",
            "CampaignName": "FADMO Q2 In-service ES 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C145",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 170,
                        "msg_req_sent": 170,
                        "msg_scheduled": 0,
                        "user_add_success": 170,
                        "user_add_attempts": 170,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN95ca803870258007efc06cb9d09905d8",
                            "FNa17c560921fb0c74d762fda8e3249b44",
                            "FN7fd0a3dacbf1ebe0e744c1d1bc3a36dc",
                            "FN223830029c4202373eef6caa7311cb5a",
                            "FN186560165f275e62c139d2bf9582b4d5",
                            "FN9875ecadc414ca95ff628d3912e1d5db",
                            "FNc81e1a715f8b40f1a193e4f2687f918e",
                            "FN62e47300fe8477b10a5d2fd8b35b88d1",
                            "FNcd767295455300b19661396bdcf5939e",
                            "FN608d1b939771ebbab2b2e806d65a277c",
                            "FNc289c98d5aff88476ccc589861f8889b",
                            "FN569c340be8eb8ae985f246a41b66ce5c",
                            "FN0c0aaefcac0df096a316734a00fc4e95",
                            "FN8fd8e7dec3c49e9dd6418acb546fffed",
                            "FNa801b86ff3a3ad15455ef12322ad789f",
                            "FNb6c7c1065640bf8d387a03dbb8102456",
                            "FNb6dbb178eacbcb5b8557476c2d798977",
                            "FN51dcbaaacc1b18ed547dab761fc6ac0b",
                            "FN536cd81d75981d6ec623693780b5dcd5",
                            "FN82a2c46a86fc3d503ad5c19095ca587d",
                            "FN24fae83afdae5f144f7d11120b8e6351",
                            "FN007b476de4325c93d7b928bd33d98151",
                            "FN70eb17ff1ad9bab42ab73abd020c3022",
                            "FN13cfb4595972f1e3b4a5ce9a3f09dfc3",
                            "FN696b1b9ac1135ea1bafc610343470446",
                            "FNc79cc94c227a8ba2be28f0fe4d612642",
                            "FN2ac7fe93bf9ae965836b161f5eb10bdc",
                            "FN95c1756f64f32a407ee67aea2d285962",
                            "FN7bb84bc8a614b85d14a02d1475cf3f21",
                            "FN64c60c3f3f27a3a7b3d850db7d32b917",
                            "FN7f62310c26d6dc028215965330ddf947",
                            "FNf3f3e09f90c282962d2bab32f5f411e0",
                            "FNfbc0a474bff11d5efb7ab705594a220e",
                            "FNf164abbf3f177f9aa3201d73588297da",
                            "FN96351590bbd8e82df11de53503785367",
                            "FNc839524908906a975fa242edface9ffd",
                            "FN2c619d034135eefae0f8d00271867c9b",
                            "FN28216821276d75fe9d7360d3d002efa8",
                            "FN15a91d87560fa1b4aafdf06cc8d62d5b",
                            "FNa6f098158cd15e79a5bafae3b0cf6ff6",
                            "FN05df802c9e6620b9b98dada836bcc5ed",
                            "FN58aefbcf3df0a97de8e7c56d8d3cb806",
                            "FNed5ea12867428abcfd0e430aebf1c200",
                            "FNaa65820bae9abdfa37cffb7f0dd6c4e4",
                            "FNec31e8ca99a7a74998f205632d0013d3",
                            "FN006cf3c5b997cab8d5bb8c69a8fea5f7",
                            "FN24f4ac9b49e1934f92c61c19d1e11ea7",
                            "FN5036fe4d0862b444b74fd892ab4b4776",
                            "FNe97f2e377e1b38f48b17fdd8cd46e6b8",
                            "FN6672c81a3080c4e6023930195fd07c0b",
                            "FNccf542c2a0acacbb07ea5f459823b158",
                            "FN00e58c1ab9f55e61fa51a99e5c75fcaa",
                            "FN9fb5b79455ca218945170f3595786263",
                            "FN03041a5534c912bf8de1465299c01fe4",
                            "FN487645595aba3522ca65a823e0d203d2",
                            "FN5ea1ae6c2838476d9e2b40972f3157e2",
                            "FNfd46fd641d2348b79157129fb592dcf2",
                            "FN857e6d49c8a334d3c16d9f7f9d741e6f",
                            "FN5499c77a76640865d2d7ae0e3130217f",
                            "FNa76b48f62cfdb00614b73f4ae8d5b17d",
                            "FNd9dbb8e1c864c67b26483a8ddd3aa0ac",
                            "FN1e8a2364ff8fa18ea8394f96118dd8f9",
                            "FN6cb5a5abe52658d0fe9cb8993792b354",
                            "FN3b84f4c9afa38a5b5ba288445ea30baa",
                            "FNfe14d08da9cab64619d4a4d6c5108c38",
                            "FN8d6c81f8c15daaa59a247def7f93807f",
                            "FN3338b5cbf7e0f75a037595f473d2d101",
                            "FNde6485c251da592eed0428c22e256046",
                            "FNdb0bf870c7f32c195115549de2374195",
                            "FNfc096f992dbf6cdbbb90ed717b0bc6dc",
                            "FNdacd7023314b88e30ef8b82833833ada",
                            "FNcd7329dbcc2660c8e6a78a9aaa1d3f4c",
                            "FN8481fda33b3d943606abf7342a3c15a9",
                            "FN596d13d5974da42832e6c5bd25294268",
                            "FN5f3ad0d8f415b6f5048413b38b937af9",
                            "FNd263ce584fa7f297df1f6861debf1259",
                            "FNd4aa92c29f1ce67dda563c2cce36c7bf",
                            "FN3610731eb0456c98b34f542fc074fcb4",
                            "FN1cf10fc2d3a1905c6ee7d3941305973c",
                            "FN2b11b0079254819b5b81cf55bec0be79",
                            "FN96e1f590ce787dcdda392409f75a2248",
                            "FNde2400644519bf66e97d13adf7f30405",
                            "FNc8205bb569269075b3d6b4e3eadffae5",
                            "FN8e3de6ef45d4e11f342723311a605ba2",
                            "FNf71bb9e339aabbe504a65963bf5be780",
                            "FN8867b327ee1e42b34aaa68b4c8d9e3ed",
                            "FN454642ce62a89f296ef8e702b4f1ee6a",
                            "FNb15eab2100ed14775ba2fe48084708a7",
                            "FN70556da1120ccecd52212fbcea0ded94",
                            "FN9b56d7a6deacba8a7a0de70a8c06c9fd",
                            "FN8e0b8a198bc68f60a9b530c1f02bb74c",
                            "FN292f66646c55735e3cb10966799e8419",
                            "FNd7c36b219db72cbff2387c2d8d2a6796",
                            "FNc6716c8691ed1c6aa1789aa058ca6072",
                            "FNb22fe42478156c949689455bdd3fc730",
                            "FNf02557c51341e6db3c6d0da1ea211015",
                            "FNbea782b842aa5bdf7fbd0998699b63b7",
                            "FNdcb23c11b930132e933711c5486cd7e5",
                            "FN0a0467ef13de83fad5344a36443349a4",
                            "FN1ee92e9089275508c4989458bf0d664c",
                            "FNb107fe46ec97fc652f367c94745f3f6c",
                            "FN369bd0166471a39dcb7e6d2b03f26461",
                            "FN118fa65430cf5955fa533c31a5ad84cf",
                            "FN07e7ac2fb558bfdca21cac6f2c2e4167",
                            "FN8abc880fd0c39bfe3e0559c4bc8ff6dd",
                            "FNba94576290a41cfc1e8476b186cf6798",
                            "FN0d6930d159e8b7cf65b54d80dd505531",
                            "FN2463f35b61c3d2b81ee617daefa5daf3",
                            "FN15c4793f21060e3f6bb150a3e29c4038",
                            "FN516f7a470ccbfca6aa43e5ddfcbde591",
                            "FNd0d25af332c6afa6818f473b3a5de68b",
                            "FN54367da23cc2549c54a39eab90454dad",
                            "FNc0beef366f7d9e75f113f600c2eeecaf",
                            "FN9ee1699de93ee42e9b36e3604dd26fa5",
                            "FNd30c30efb59b7942b128dcbf01642412",
                            "FN05b76cd004d082aacc316a5b8b6327cd",
                            "FN73c3da78b2c50b1134721ce8951b92e1",
                            "FNc681422385d232fb62c5be34904b09fe",
                            "FN312167edc720917c284ffd7a70fb30f2",
                            "FN71969965a23fb891c6a291d351123adf",
                            "FNfa8905c604bb2f85998850e500a9a07c",
                            "FN050ce4a13148b7d8937b2fd81e154995",
                            "FN129c58d73efba6661885e44940c9d28b",
                            "FN94df456c86448c19bf58e851707b99d2",
                            "FN6940c71228d12bb706a0a090a659b1c3",
                            "FN17ff2ce074a84cfb875104ea3547434c",
                            "FN404e324a021abb3c9e370800b62e11bd",
                            "FN19b7cd85a431260854e6f235715bd0b9",
                            "FNdfe30005ceb295047b4913a7ce05d471",
                            "FN7b8b4393e999e7927688eb593500f6ef",
                            "FNf94539923168cee7b9d10425af8f3af9",
                            "FNfb5224cbf58bea853b638bebce3ecb31",
                            "FNf1fa083188c1ae80998a687ec3823d83",
                            "FN81fb11a0e694b9699f3189691d7ae6af",
                            "FNe85ba1a50286d557944ed0abcf20213f",
                            "FN27e2bd85dc07333ee677e4e85d06d659",
                            "FNb1cc44d6ff3da55bd0b2b310f14b6043",
                            "FN4789182e4e56c6f9529d58ad2badc559",
                            "FN5159f55feb5da948ab8e5b03d6d46a72",
                            "FN3d9fb831bd22b8c7bd6df7497d7ee9d0",
                            "FN0dac772fca359c7ca88b9517471ba202",
                            "FNc6adccc67f1a4433a04f19ce29c90755",
                            "FN6fe1285d74e8c7df6c8f13da45b9a6f0",
                            "FN24e9a1bf1a1e521e8ff7c9b54216a0d5",
                            "FN72b46609fdf159b50c85f6ab1c0c21d2",
                            "FN6d81f6676738f2f5dae1a745aa9a3709",
                            "FN64e903abae9397c25aad6b903878d371",
                            "FNf435739531f62f86c62c192b592b4ddd",
                            "FN57291722b776bd5a3875c506fd7d9106",
                            "FNc7d80f860b1d05c565fadea496693fdb",
                            "FN19e385f08e46ae7dc09fb118c572a725",
                            "FNaed1ffa8694756c613df6c28dc800c6f",
                            "FNae8671129439f746fe43c2e58f5a151b",
                            "FN0a948373ca01ca4b30dec21465ce7b0c",
                            "FN86a646708a11c7338a72d912abf54c9f",
                            "FN57a654a7002638bfec1a127b4cf6b5c8",
                            "FN95c24e28d36a3382009d29bc77a2e82e",
                            "FN54a237c6991acb11cb13747ead46e1c6",
                            "FN421f6babfe702d4cfad7308ab5d4c36e",
                            "FNb716b9e13cc701dc568ec2dc9e1eaba2",
                            "FNbd45ea5471a9242d8dd0f97b4c2f8436",
                            "FN6c80555ac5b3c09c7148de46244f4701",
                            "FN8438d81dc386d7a2cf15410463186c00",
                            "FN392af1098ef4f7ffe4305bbfa2f257f5",
                            "FNa47a4c6699eeb2d560d71f947916747a",
                            "FN9b3501ec237ca46ab68df31b763141af",
                            "FNee65a40f11b965bdb6817a7c03ea4bd4",
                            "FNaaf4eabe12e6c07b4d093c720731b08e",
                            "FN746a0035f0a6ee9d8de625d155ee214b",
                            "FN0710d102887de367c5943e2aa5c476c3"
                        ],
                        "msg_queued": 169,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 170,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 791,
            "DaysOfWk": " 1 3 5",
            "TimeCreated": "2021-12-10T00:04:12.552Z",
            "OrgName": "Paramount Home Care Agency Inc.",
            "PresetMsg": "НАПОМИНАНИЕ! PCA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:03:39.898Z",
            "CampaignName": "Paramount PCA 2021 Russian",
            "FrequencyInDays": 1,
            "LangId": 134,
            "CampaignID": "C341",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Paramount PCA Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 260
        },
        {
            "MaxProgress": 99,
            "ProgramID": 496,
            "DaysOfWk": " 4",
            "TimeCreated": "2021-11-29T20:54:20.869Z",
            "OrgName": "Wyncote Wellness",
            "PresetMsg": "From Wyncote Wellness: Please complete ALL in-service training ASAP to stay compliant. Click here to get started: https://bit.ly/reminder-VIV",
            "campTime": "2021-11-29T17:50:50.718Z",
            "CampaignName": "Wyncote Q1 2021 Incomplete",
            "FrequencyInDays": 1,
            "LangId": "",
            "CampaignID": "C27",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-29T20:59:35.811Z",
            "ProgramName": "Wyncote Wellness- Q1 In-service- 2021",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": "2021-11-29T21:01:39.325Z",
            "Active": true,
            "OrgID": 169
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 10,
                        "msg_req_sent": 10,
                        "msg_scheduled": 0,
                        "user_add_success": 10,
                        "user_add_attempts": 10,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN3d5627bf4d1d60b734c6438185d39362",
                            "FN7915b7c987eb19d029f0949af730df6e",
                            "FN9df9350c46632707696a988f343aac9a",
                            "FNc4c331f1385f41c4f3c27911bf6c5096",
                            "FN5c8777157a61c4bb951e4c69a104def1",
                            "FN50df5f2f82dc9fdba486bacb96985061",
                            "FNb3fbaac0f84601658952d725a3f7e31d",
                            "FNf6a4a3ba5233409735a8022561f835b5",
                            "FNab20e6193dfa2a2a7a7170d33bc7aba1",
                            "FN9ae478e9829568047ee15fe1af3b36bd"
                        ],
                        "msg_queued": 4,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 10,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 532,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:40:31.762Z",
            "OrgName": "Bryan Skilled Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Bryan Skilled Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Bryan Skilled Home Care - Aid Mandatory 1 ES 2021 - Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C374",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Bryan Skilled Home Care- Aide Mandatory 1 ES 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 87
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 29,
                        "msg_req_sent": 29,
                        "msg_scheduled": 0,
                        "user_add_success": 29,
                        "user_add_attempts": 29,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN541421c2cc18f38e4421bf2ff60c9548",
                            "FNa9243b164886da95075db0dcdd269c6d",
                            "FN0e629bd417283ad75f87c81938469af3",
                            "FNc16d6998e86290134e51e500672c122e",
                            "FN12c4b4111a72ba147a941539154afdcb",
                            "FN25ea20e5008017b10db1a824ee2725c3",
                            "FN3e8b3570da3787bad7449dd3afca2980",
                            "FN4477cf492eb17a2df05ae8033ad4a465",
                            "FN4c8e9d157da1e88fa0a5b9cd13be6381",
                            "FN62ad8b946ed88684b46c6be6a59d5324",
                            "FNdb80c9057a896024932b62d836ea9855",
                            "FN3f964796314241bb2b72da46c793b0ca",
                            "FNa132894dde707fb33f149eb45c07a187",
                            "FNb363721a6c9ba6d3514866a4828f495c",
                            "FNcfd2bd51c483d8bb85b1eeeedeb44d64",
                            "FN747c70f22d9a47134a4d1e86bb598586",
                            "FN5f542d3273828f515ce3f69dcf1f85e2",
                            "FNeb7ea3a26b0bce7a9ea9881b5fa2ffce",
                            "FN4453a3bf20e73bdc99714d7250fe55dc",
                            "FN927b39d87db6e7d322fbe6eeb1e84b4b",
                            "FN16ba74d96f60d307166ab859ec9b923b",
                            "FN74d3e04922a1516e55fd42e6b4826b4d",
                            "FNccd55e76e20c2fc7a5e9e7f36adda9ec",
                            "FN8fd5b8b5e04f92081b922829ef56b56f",
                            "FNe9b10b594a4bf691be9834129d93c364",
                            "FN3c855fe08e35bffaaf1720b6140d166e",
                            "FN48c166973ca39d2010eceab5fc53f5fb",
                            "FNc150ac9a93c52ed6ff7bb39e346b6689",
                            "FN5c7a30ee20631db42139ddbb632800f0"
                        ],
                        "msg_queued": 29,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 29,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 408,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:03:29.525Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:30.883Z",
            "CampaignName": "Recco - Class 1 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C394",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 856,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:31:28.509Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q4 - 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 4,
            "CampaignID": "C511",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston Q4- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 136,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 703,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:21:03.096Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care- Hospice",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Hospice Universal Compliance 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 4,
            "CampaignID": "C519",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Hospice Universal compliance 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 214,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 632,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:15:55.390Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Ideal Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Ideal Care Mandatories 2021 English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C560",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Ideal Care- Mandatories 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 194,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 472,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T04:42:10.579Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "SI Homecare- 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C588",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:46:02.496Z",
            "PresetMsg": "REMINDER! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:26.157Z",
            "CampaignName": "BestChoice EVV-HHAX 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C108",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 472,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T04:59:07.818Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:39.046Z",
            "CampaignName": "SI Homecare 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 5,
            "CampaignID": "C592",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 440,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:53:51.495Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q2 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 4,
            "CampaignID": "C569",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 117,
                        "msg_req_sent": 117,
                        "msg_scheduled": 0,
                        "user_add_success": 117,
                        "user_add_attempts": 117,
                        "msg_delivered": 94,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNa931caa66dae0959900c14fb6d0e1ef5",
                            "FN02d10d38ebf32eb5176c00e4784e6d91",
                            "FNf432f6e97f8f6889e7eec790c5a3226c",
                            "FN76dad30375d804f5716ad61c7b2b225d",
                            "FNf6f71703d8586ead41efef22805f1088",
                            "FN9ff489be0ca1d9e7f7dee0df2c6ce6ab",
                            "FN8d5d10baa20b28fa0b2f36ceabfec5d3",
                            "FN98278025bd9c5526b3f8085a42669b42",
                            "FNd791071c5566daa80e4f4ec67950863d",
                            "FN214a7dfd688247654733b8fd1684a8ad",
                            "FN99117a470af06a3360c7170101d8e8d3",
                            "FN45e3e36ce5dd8856bb87db0278d6e0e6",
                            "FN2e322dcf2df1b1ce37f2559011e5362d",
                            "FN08f6d5a657a865157c97c83734c45aed",
                            "FN533bd579c0d2a20aea8292fd6b121455",
                            "FN43002be80a9585c0ba001165db64ca61",
                            "FNa9fafc0400a4499601aa7eafe78c54c8",
                            "FNa9d0a4c80b9440dead457db3cb4a09ec",
                            "FN4bdb372c75af4ba8b2a9a14a6257a77e",
                            "FN9a4c74ac7386d1f27c3338812333c5ab",
                            "FN91ad804ebd0d460979580a2b471fe443",
                            "FN0c0c8d12011707d6d3ceec125a37d31a",
                            "FN4938cc1a1f49086c97829df23e36ac27",
                            "FN29f37d54120b4ead019e92e57eb79de4",
                            "FN4bdde381207710290642460257cabbf0",
                            "FN3e254d83caa03fd1b85083d75d3fde1d",
                            "FNebead923498450e0db5e95c1293d7bc0",
                            "FN67259eb5a04232fd8bcf1ed5c2203303",
                            "FN8c39069254a56116c77682cffc450a90",
                            "FN2b3b388dd79ebbe48ea1a0908f5d39a0",
                            "FN07880f38a9731523aec2e03b5f735f7a",
                            "FNd7811c186f615a06f3aa7b2ffde6fb09",
                            "FN676bbb7bda1a30545ea64e76f24cb9ea",
                            "FNcb49c33c0813182db29305c0d6e2509c",
                            "FN7fea7ab51e9aba31fc7884490c7b850f",
                            "FN5772a6037e8072985e5961cbd574c02b",
                            "FNff49c3a1212d3b7b7590c923f21624eb",
                            "FN17ec522d6117d7ab1ae1741a9de89475",
                            "FN3e999ad3c416eaa2b1c35506c3a2d639",
                            "FNdc8ee525573c726f5048fc6048d7177c",
                            "FN482bc1e41fb54d6dfccc508a5afa8dda",
                            "FNbc89e506eafc9d290213f931e7e9205c",
                            "FN05d10151e7807460bcf9f140d679e9a3",
                            "FN9920fb83773208af8a0ee7e129960ff5",
                            "FN5fa6668b54ecba8e797809a5227f38f1",
                            "FNb366d6bb15445f970a892a79400bec60",
                            "FN9de69fdde0946cfed2bd5c3636b706a0",
                            "FNe7f081ff981b6558b68ddc8f4648aea6",
                            "FN3005064518d51d24b2bc5cebb7d8073c",
                            "FN75e7bf285d562e75fedc3b972c8b72a3",
                            "FNe377837fda080df91616a0bfbeea6211",
                            "FNe09f9be87b3eaa9465578a6b09c23ede",
                            "FN9ab9387cb335876920d64d2e37003a13",
                            "FN64a9c96469e0421738ff97d7581934c8",
                            "FN0d055d256c460283a1411e16df40a579",
                            "FN6711e37bec8c960fdc729b67a34d3b21",
                            "FN91c4c567b8819b8fc0f78a1ebbca47d1",
                            "FN3c866c3f92d2cc7aa129a835adfd1f07",
                            "FN9259338ce3056598d52d295be6390eb2",
                            "FNc0aef96462f0b8642382c2b63dba622c",
                            "FN30222cbc2e4a6ea912171bf6238c2f43",
                            "FNc096d29ebf34f90308ee56fb916b142b",
                            "FNab65b441bde56a9efdb4e0cc16d2bb28",
                            "FNfd10b8690afa891a2754e998b9402b6e",
                            "FN013da35de860332b243525c890ca7299",
                            "FN82e0e59c679cc7414f246b3808a477f3",
                            "FNc16223691449b27fce0775d5e6040736",
                            "FNa1ac589aea06ebdedef0d351113fcde3",
                            "FNaf22252274244044aa6d5991ae2a7488",
                            "FNe744b9525ad7be4a967efb4e0b031c2d",
                            "FNce0a2e02bc888bb8b1c85fce06a6acbc",
                            "FN4d91be4933f6fb05ae50e64c7d5028b6",
                            "FNb75c68606cce56b2693711b15276997a",
                            "FN0c1f3a24e85f396c5ddbe6deebf6bd95",
                            "FNa5da758660e2ae4f242d9286e1096751",
                            "FN24e10529edb417cbe947b6a689732ae7",
                            "FN669e9351488170293b300162c29bea01",
                            "FN000b2827fff9b05b5d5ba9812059dc67",
                            "FNd81ca7f3d13bc5d22ce410e354a28762",
                            "FN16b7686ca454dd353818ba6e20307405",
                            "FN03cd70ba0dd8d4bd9dac3656e1967b91",
                            "FN29f9abc3584239d5db593746b1fc3a90",
                            "FNfa4a3d876320d61005bbc4a82fce5f6c",
                            "FN042a587ea65fa277c9733d630618cc0a",
                            "FN9ede44b8d8a3620e91b2489fa3ec9adf",
                            "FNc3696d0e42d3db88185c1e36cce08a57",
                            "FNa14ba4f92eb4df512b8f223303eb2a85",
                            "FN1d4cf0dd1f70721b68fb7fc3c6febf45",
                            "FN0cb9eb81990a7b7209a8be47a2351832",
                            "FNbf4be9722e4e081555784b454b1bc72f",
                            "FN881c867623d971e6e4b75dadd26a5d1d",
                            "FNd04644fea3d0a55d046e70d1d2cd603a",
                            "FN6694f5849cd0e7d40d8432ace7276a0a",
                            "FNb5c07c019a6780e37cee7bd631f10534",
                            "FN041a29c4b49c0dbfb9e0d94f338a5563",
                            "FNdc88fe4549e571effdaa21a98bdfa75c",
                            "FNaf3a0c9ebd8f4b290ad83166af162d7b",
                            "FNdd11aa5f56218a1798dca32728bae7d7",
                            "FNe58f725f9f27e1ebe9532676beb746ba",
                            "FN7c2be2dcadb285c353b78a9d513b3e6c",
                            "FNfc90cdb92f840a998d6e2366744bb7e5",
                            "FN54d939fb0ed835cd9b08e38f92b27946",
                            "FN04fe8df28ad79ec17751d6dab3fed76e",
                            "FN7c2418af76bfcf99d03716a19dc57eaa",
                            "FN4f0172e185c3085c15df93eb74230edf",
                            "FN13ca66a2bbf9b7bd995fc9568a203bdd",
                            "FN46d20a3c4fc0c35eb584e5bf3c2b61dc",
                            "FN5e018dabd124c546de0316c4defe6a90",
                            "FNb8118bd6d907a242d0d1eadd0b6fd967",
                            "FN9aab3f731472e19b34841558ec4c9b7a",
                            "FNaee3dcf80c3b9d094daa264d36cb8344",
                            "FN818c00ab5c7110ec35ae2e803db7ada1",
                            "FN15f76f9ca40cbdfc75d7f2bbe109351c",
                            "FN9c1050715e22229bd343016d0142037c",
                            "FNe547a09040db44a51478b3abbba0de7d",
                            "FNe2e5d7e23e433757fcd0a0d41a1749e0",
                            "FN312a6ad62839b05e61e77a5d788178e4"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 7,
                        "numOfUsers": 117,
                        "msg_undelivered": 15,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 683,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:13:33.765Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions?https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T20:30:58.392Z",
            "CampaignName": "HDA Class 3 2021 English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C300",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 3- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 15,
                        "msg_req_sent": 15,
                        "msg_scheduled": 0,
                        "user_add_success": 15,
                        "user_add_attempts": 15,
                        "msg_delivered": 4,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNbdd26d852db35a29020d1edd161d5f27",
                            "FN171dbc3975f257bc6d78a45d2916c146",
                            "FNde819a38edc282f78f3ff5f649dac6ea",
                            "FNcf25636f6132dffc14440757d283e55c",
                            "FN6d3134e4f58547cfdd60e15d33a343f3",
                            "FN922112c4ed5bbaa91ddc83e51248487c",
                            "FNad280103cde0f1154267927a2f46c2b8",
                            "FN7a4013aa4888ea146c4f495b28fa1ee9",
                            "FN9be17fe8750499500a4710e5beeacf0c",
                            "FNa3c4daa1919d848a29d5489bc3ec18c1",
                            "FN7d0983125b1a5a9a6dcdb1d1fef9eb6d",
                            "FN4081c0dc3c5424fc73973f2af8a193a6",
                            "FN823ef00fcb4a77bbe06a8217fd9b24c5",
                            "FN4b1eb239de035c468ae743c984ff9cb9",
                            "FN16ba453d248f4ceadb493c21b288311f"
                        ],
                        "msg_queued": 7,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 15,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 680,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:02:21.894Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:27.869Z",
            "CampaignName": "4 Hours - In-Service Program - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C429",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "4 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 625,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:24:26.183Z",
            "PresetMsg": "\"URGENTE De FADMO:\nComplete TODOS en servicio lo antes posible. Toda la capacitación vence el 31/12/2021. Empezar: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:04.146Z",
            "CampaignName": "FADMO Q2 In-service ES 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C140",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 283,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:14:15.057Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 2 - Experienced Aides - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C479",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 2- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 731,
            "DaysOfWk": " 0 1 2 3 4 5",
            "TimeCreated": "2021-12-10T21:28:16.690Z",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q3 - 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C509",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T21:31:50.191Z",
            "ProgramName": "Assisting Hands Boston- Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 136
        },
        {
            "MaxProgress": 99,
            "ProgramID": 589,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:29:15.676Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Open Door NY Home Care Services",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Door NY In-Service EN English",
            "LangId": 41,
            "FrequencyInDays": 2,
            "CampaignID": "C521",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Open Door NY- In-Service H1 Program- EN",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 182,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN49a96fe93147c2f40e7883070b41fc97"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 684,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:29:10.965Z",
            "OrgName": "HDA Care",
            "PresetMsg": "REMINDER! HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:23.081Z",
            "CampaignName": "HDA PCA 2021 Haitian Creole",
            "FrequencyInDays": 1,
            "LangId": 56,
            "CampaignID": "C319",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 56,
                        "msg_req_sent": 56,
                        "msg_scheduled": 0,
                        "user_add_success": 56,
                        "user_add_attempts": 56,
                        "msg_delivered": 32,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNeb3ee8909d2f52fc72245c8ec96b106a",
                            "FNfea697aad36fe3202baa85d92e2ff9ff",
                            "FNb791a0635eb49a139a95320e12202fa5",
                            "FN2e56d8a2cd5ce67e6fcea14475e850b3",
                            "FN3ad060e13843bdb70f87dabd521d77be",
                            "FN203e8e00e84a07f31378cf3c850882c0",
                            "FN8d28760da55332324c242d7e074c87af",
                            "FN3d8ad834a60d64d00f2b386f4068670b",
                            "FN1cbfb86530aec74f7a3e13490a64ea2f",
                            "FNa0d220003b68a24f9d4f2172aabd09d2",
                            "FN602db408475bccf3fa2de4d5bab878d2",
                            "FN2a7d40473cdf08627879edb0ee3a006a",
                            "FN2d4340453b7324cf27acea2fc4233116",
                            "FNe6fac196e2998d3f2be9445c6782ed6b",
                            "FN6fff6d4ee9b3083683fb0c7354163bae",
                            "FNe2f7419f0d5b087d32db0c2ea6204400",
                            "FN26a6d048d07eed026b1824ebd52bb269",
                            "FN28acf36a0b8d495ac4b2cfc15f2df61a",
                            "FNf1bb6d71817cb2c429bf1c1542b2528e",
                            "FN97aa1bc51f8cb6e5a9d0a79b7fa7c0e7",
                            "FNf7c413bb4a3436103503aaeca893e758",
                            "FN4e4b5db76d4a7b2382cc2e3ab3ff344e",
                            "FNa1e747ca35ce19c1eeb0352aebf4091e",
                            "FNf14908c9266851f2e8234b642e1412ff",
                            "FN8b28f3a12e0be11c019c048174b9e5e8",
                            "FNcf581915a4abfd0cc33d8c8ee08646e6",
                            "FN1395dbf15887df2343e6393bf5301839",
                            "FNe4eb87cd75809463e60499b11f2654bd",
                            "FN2fa791906c697af08e5c8bb9f67e5474",
                            "FN0f0c5a5f576b3842aa9cc761a7baa0af",
                            "FN1ba06a2960938bdbc4acd435e13fe6f1",
                            "FNdad145ab568bdc461c514048545b90cf",
                            "FN8f593aff265c242b7b82edc709eea1a2",
                            "FNa32b899ba482160377d8e10aef8828c3",
                            "FN785347761c76b46c5deb42c1fb4a22e9",
                            "FNbc20851d692af8ed1553cba4e67a79bd",
                            "FN96d4a0fac7ff2550463a9cb0e37790db",
                            "FN7232f3a0e94feff3f706c515ead2488f",
                            "FN06994ab80d248c193af3cf07afd34f88",
                            "FN7b561ae74aa2f4a21d72703ba0e3f0cf",
                            "FN2d3b8ad99764efe4f37bab2e8ac48553",
                            "FN5f043f4c8ac0a0f501dafd4c85e09ac3",
                            "FN283c8745429d923312e75075ba29aad6",
                            "FNf3aec9edf258e204e5f1fcc2e1c8e30f",
                            "FNa2600e262434d399fa6931d4af1c9503",
                            "FNee760a62d2293f821c7657410a9a1353",
                            "FN8d1dd4a74585343d1ac9e32a489b6f44",
                            "FN4ac9e610a58794324b625ed8f96a371f",
                            "FNa37a12626e998456244986a7cc789e88",
                            "FN956cb7f8365a79abeeffe67e97d2d690",
                            "FN0039e4be53100daf6e8ac8d098f114e8",
                            "FN8846156eba887c1877c6aa92395e1437",
                            "FN7665c21ea496b912d0aed2bcd37ed412",
                            "FN04a8d31994170cd3603f0bb7bdce4778",
                            "FN1f2ad262dd84d293222544e324e219f2",
                            "FNaf534894cbb1822f9c7926032c1c18ea"
                        ],
                        "msg_queued": 13,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 7,
                        "numOfUsers": 56,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 56,
                        "msg_req_sent": 56,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 33,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN0efe3dc387d57854d50ae591737fc3be",
                            "FNcfb9f3583ab7f2e72eacd5323e77935d",
                            "FN4449d177adb261244d6fcb8a3fa5dcac",
                            "FNc25c3d1af76f8daf1df0ad052a412cc7",
                            "FN3e0465d4b0ac00d6f8d501d337069e92",
                            "FN36a06bc9ae7dfc7a26697cd2bc972264",
                            "FNb826c7a8fba9d9702c8d83ce57805c86",
                            "FN21be3e80a17498fb73f48ba9f46992cf",
                            "FN0b1ef3ca5e1ab7606ce6b8263bc9af53",
                            "FN7759b2a31c2e0f680ff3ae952d9c2f79",
                            "FN3d8e54cf86f5bc2db072dd0aed31a55d",
                            "FN9d45e0bc63ecd7222cfd4a543b5c9390",
                            "FN523ad004a6c938cea4f12fb2fef4afac",
                            "FNf372233ae9bc92a8df8fbdd3975c7b91",
                            "FN93ed6cd3b75d9de386cde26cdd72f71a",
                            "FN3ef7dc5f6a728b4a26033c433838ca23",
                            "FN3831a994cc3a6bc4f6254711d6387132",
                            "FN16ec2d9ad31b163124e40e89ae70c0b5",
                            "FN0816d980b4998e6cfac9f08c8f94ed26",
                            "FNe0b610089ba691de01090eda29183628",
                            "FNf4089c2faa7b2e89ac52f428d87a4a26",
                            "FN05ba014f747cd784b7c636cadc8d5eef",
                            "FN2f750d818c29a66e9c1f167b5b9634ac",
                            "FN5f1866f3bcf6e0702ee20dbd86d69c4d",
                            "FN708edba528555b757c2c1073cecb3695",
                            "FN3e9fc978210b9e61fb8827fbab3d358e",
                            "FN8c665dc3d2a574d47a2a81f80593f54c",
                            "FN639e95f2ce06c3f8022c29327085f004",
                            "FNa660f20209b90f899e34215a88866e30",
                            "FN5aaec2268c1ab0a39eac0f5d8f211e70",
                            "FN86214763e897bff9359dd52aa809a446",
                            "FN3ed463bc61833dbb6001c31dae3b2c74",
                            "FN127af1c35b02d406e5e62f825b223b23",
                            "FN032d0a59c99c987212ff06706c5dc04d",
                            "FNa620f42b3e86ba94f111e32ef51e721f",
                            "FNb897752e97e0569c4024432b9f63b4f7",
                            "FN59e567edecbfdf540566416f71944d26",
                            "FNef79763f416dcbe6d86735aaeb962f78",
                            "FN16b7576c77cec4e7750e0a9463b894bf",
                            "FN3d0b3bdb894a0c5dd7787f7b5d7092de",
                            "FN15112e2e105e9dbc9889ba1b32e2f472",
                            "FN1802cbfcae357f57a16a066ed8b0fb5b",
                            "FN7e5337141c1f65ad7296732113541928",
                            "FN155caefd847dfc7e49ab1bced392b217",
                            "FN3ae0a931f4a4ee32e715adff43647acd",
                            "FNbf116e8c75d72afec6f7e933990a44b4",
                            "FN56b8c563c054dfb38ed1593a0631ada1",
                            "FN6694b7043113275473ca07d3f50ab67b",
                            "FNb6b1d3481456a122b6ac88d4c11ee844",
                            "FN1d84c0c138e78b20395649f49adbbea4",
                            "FNec53d8e64b3fea1f50e1ad185dfcae54",
                            "FNcf54e4b077b74d2a7c1fd25d9e77ab04",
                            "FNeed450cfebc8de152ab8253e28e8b550",
                            "FN5fb542d112c10bb5cc2484af7f02d2fa",
                            "FNedc1beebf61dccec18fe39f2de351f94",
                            "FN475557906c62f9f574a1c3d6c8b27bbc"
                        ],
                        "msg_queued": 11,
                        "user_skipped": 0,
                        "user_update_attempts": 56,
                        "user_update_success": 56,
                        "msg_sent": 8,
                        "numOfUsers": 56,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 55,
                        "msg_req_sent": 55,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 32,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN8962d547b817c9881190dbcc00549bcb",
                            "FNfd579f216d0bc463143871663fc163d1",
                            "FNa6d3f8c3fe3b483e002072bf12837805",
                            "FN7370d46108c2cc6a39ab9a91d7774792",
                            "FNf6cbe02a2b042ba06aaadbbd39751794",
                            "FN9214df90c508711a9861136fe93e8868",
                            "FN5797b4d3e83d227917a87de6566c2b87",
                            "FNe42bc2631c6ecbc062f6f8f4465aec5f",
                            "FN981103c1ff5f1560538a75fb1855ee87",
                            "FN96223b3a588267b2d2bb7849366481fb",
                            "FNc548e2717a500df9fceb7daad9b8a06e",
                            "FN08f52bdae49c3ee8c47b3ed506fae745",
                            "FN8fe15cb3dad9defe48d93c9e135c3c2c",
                            "FNdacd8ce149cefe9842f0487dd4911c31",
                            "FN89dbd8fed07aa4bebfc5a60d257c0577",
                            "FN0d3120586706265f5c2e16853ccfeb2a",
                            "FNee88750554ff65b2b185995d950bff2a",
                            "FNef61136ebbed564eb48b695a4ed0c93a",
                            "FN77b8bafd9c8c0adc9ea908c192ed6df1",
                            "FNfe58bc0317b6160a0c1632dfed1ffe0f",
                            "FN2d0688550f2e04c9778f6fc67346191f",
                            "FNc9599a72e534e36f45039d35978fda70",
                            "FN4035b8b5a40a2e7ef9e1667cf9f96768",
                            "FN59d60b58a578ba3fa1220a3737e2f208",
                            "FNdaa9d547de1e4dd354b7bd0f92c8ca7b",
                            "FN4d3d3f1122b189ae16992395c6ae7eb8",
                            "FNd0160f297cd7ec04c3332f27145d6e8f",
                            "FNb4edb12fb1f6012bbd5936b7e86d8c7b",
                            "FN5e39acf10504c363eb1392c38dc0476d",
                            "FN1621cd8d257c276eac7140620d2044cf",
                            "FN0a7149c6005848e04fa6256fe07d7579",
                            "FN8eb1142954429b005f333cde82830840",
                            "FN3346ff62f1325ab506eb738773a6b9f2",
                            "FNb85e1d7d500a6097d50a8ae1918975e2",
                            "FNf8cf0b617cd55b63ef251fc079e14125",
                            "FN83cabb62c499f3f8cbb83882bdbab0ff",
                            "FN0b2a90b1d41bcbca25254e9e919316b2",
                            "FN4f6b6a7a40141ea5032498d5aba7dde3",
                            "FN726cdd1d072100947bc4bba3ddbd140f",
                            "FNe1d60429699b88d0260b5f5f9f89bc99",
                            "FNb2b2dd6feb42f32d80918a96b0a8f08c",
                            "FN9dd8785851599b8a3ac90b9701a14b3b",
                            "FN275a89b377f2e24494b2a101a46c86a0",
                            "FNf1ece92800ed88758caff0b403ae4fe4",
                            "FN5452c77891488b7de974d6610b43667a",
                            "FN861ffde75cf68f1c54336ad682a0036e",
                            "FN6109a38ae3e22eff9f4f5c4cbda40647",
                            "FN9c05c944cd0b1ada6353e86f97562465",
                            "FNa3f0539d2648d0858ef4a5bc1abb64bb",
                            "FNd1bbc2beda321b838a6965946495bd24",
                            "FN4d9057f002efebce35bb3d5ba7a2eebd",
                            "FNc66d2bbccdffa28457f85eefd1f2af73",
                            "FNa5410b76d05fca6393cf8b1afd8564b4",
                            "FN84dc8b99c3d9ad6c78c498adb58bb52f",
                            "FN44a13ea35b340fd85c6d881f5d99a423"
                        ],
                        "msg_queued": 12,
                        "user_skipped": 0,
                        "user_update_attempts": 55,
                        "user_update_success": 55,
                        "msg_sent": 8,
                        "numOfUsers": 55,
                        "msg_undelivered": 3,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 50,
                        "msg_req_sent": 50,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 31,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN16e237d4acd0319802aa29031185f1bf",
                            "FN063b2d94865d40c7d76589c41edb599b",
                            "FNb461c398166853df81f98dd814a031bb",
                            "FNce01cd8def80ec6437a25643f28a135e",
                            "FN207c29f17fc58175445cd90d21edc3d7",
                            "FNff879213a7c4aaf570fd2f7cb0c9c352",
                            "FN398cd99e09e2ac3410150f14224cd639",
                            "FN9a0db8a6d3f14e353689af1e813df6c8",
                            "FNa535bdd29b01341f02ce3fd963625e40",
                            "FN4c0670607e43480b896762c8f36eae04",
                            "FN3e0576708b8a50dc79499813fcfdd111",
                            "FN49f2d517228de75a1d73d8f008acbd6f",
                            "FNce60155780b77d7167949111c2df26bb",
                            "FN397745674b292d8fa891592cbd57613e",
                            "FN4e1bc4badad94f5f7622069435f42e7c",
                            "FN5c1dc521579043980c93b80dcda5c763",
                            "FNee0f9c9fae5412fa317b975d9f22de39",
                            "FNb536ab9e0f1c203941eac32d046a8432",
                            "FNd9aab8030a685487d021a4e10380b0b6",
                            "FNa56cbcc111a14ea5b540241879e77f28",
                            "FNd9fd2ac858b06b68d58164820b27ff8c",
                            "FN602226378492ca0d114e8e863e99bd2d",
                            "FN1ca9dce0a1b60e3e7b80d85b74beec3e",
                            "FN7954430c3574f77e1b81e10f5a52a589",
                            "FNe78a218be3e1c818dabeada1a2fa8b37",
                            "FN2038dfb9acda78edff30f711e65d9a88",
                            "FN5f3e2a7975d86ece339bf29e7d5bd8dc",
                            "FN101425123f2ee3b73744597cde3d3145",
                            "FNbeb75fdf6384f1979282607e9226574a",
                            "FNc4d64733950b3b0063bdc1498c4f011b",
                            "FNd26d0064bae331c86082ee64a9839a57",
                            "FNf0007f5f9143a804a12baaaf7f52f339",
                            "FNac84149ac47c060fd05508399917de12",
                            "FNcb4a61419d1eb70a7073e559fe8fc391",
                            "FN3db0b0919653893803e62025fd132852",
                            "FN3043528a5697c3fe57625cc5c71752d1",
                            "FNb4772e8e7dc19e930c6929a18150a7ad",
                            "FNf27c12884c9e4c7e0dd4e17dc14086bd",
                            "FN2c4eb9597884858add822dd6f51e5f97",
                            "FN1ae35ed275635f63f2f0be6337bf8acd",
                            "FNb98647a75a07f6c7795797138678c7e2",
                            "FNceccd308c5b41f8959e5c7b2aaa16b5c",
                            "FN34c6033406fc80c11404dc5f51a6638f",
                            "FN46df6c9f8e15ba3b79b2fb630a764922",
                            "FN8af90c959d5ddeb96056041f6fe68117",
                            "FNf058850e75fea561d9000cbb7d8e26ab",
                            "FN50801f623cb1b47afb4b71c75f4d4329",
                            "FN457ad7620bc79a5c417f9869456c4d2d",
                            "FN662787f68d428dfbadbf57b75611786d",
                            "FN85e77e45ee0801f939018b57ac93fd09"
                        ],
                        "msg_queued": 5,
                        "user_skipped": 0,
                        "user_update_attempts": 50,
                        "user_update_success": 50,
                        "msg_sent": 11,
                        "numOfUsers": 50,
                        "msg_undelivered": 3,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 123,
            "DaysOfWk": " 2 4",
            "TimeCreated": "2021-11-29T21:12:04.733Z",
            "OrgName": "Link Homecare",
            "PresetMsg": "URGENT from Link Homecare:\nComplete your 12hr 2020 In-service in Nevvon by 12/31/21. If you do not complete it by this date, you will not be allowed to work until you complete. Get started here: https://bit.ly/NEV-HHAX",
            "campTime": "2021-11-29T17:15:22.302Z",
            "CampaignName": "Link 2020 12HR incomplete - English",
            "FrequencyInDays": 1,
            "LangId": "",
            "CampaignID": "C31",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-06T20:33:37.763Z",
            "ProgramName": "2020 Link Homecare 12hour Inservice",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 94
        },
        {
            "MaxProgress": 99,
            "ProgramID": 861,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:10:27.484Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Orlando",
            "campTime": "2021-12-09T17:00:32.263Z",
            "CampaignName": "Assisting Hands- Orlando - Program 1 - English",
            "LangId": 41,
            "FrequencyInDays": 1,
            "CampaignID": "C258",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands - Orlando - Program 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 248,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 725,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:36:45.882Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV Training - Russian",
            "LangId": 134,
            "FrequencyInDays": 5,
            "CampaignID": "C605",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV Training",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 528,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:16:12.304Z",
            "PresetMsg": "REMINDER! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:36.728Z",
            "CampaignName": "FADMO Q1 In-service ES 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C132",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 715,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:54:18.711Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:04.791Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q3 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 7,
            "CampaignID": "C247",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 630,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T23:06:35.789Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:34.177Z",
            "CampaignName": "Caregiver Provider- Annual 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C534",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- Annual 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 795,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:28:06.432Z",
            "PresetMsg": "REMINDER! Classic Homecare: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Classic Home Care",
            "campTime": "2021-12-09T17:00:36.154Z",
            "CampaignName": "Classic 6H 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C201",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "6 Hour Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 239,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 438,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:46:31.485Z",
            "OrgName": "Accredited Aides-Plus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q1 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C564",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 133
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 17,
                        "msg_req_sent": 17,
                        "msg_scheduled": 0,
                        "user_add_success": 17,
                        "user_add_attempts": 17,
                        "msg_delivered": 15,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN303ec03858c66a9c15d4b690d68f3170",
                            "FN42863cf6cbdcdca835e7b6fdd2111fa3",
                            "FN36897b6a5b573b2bb4659e6dbe7cd3f3",
                            "FN5f9c6f4ee43cb070c878cece4f5de4f5",
                            "FNfb6aec209f63f1df97d2297de31ace99",
                            "FN84a218d4d26c04973a0430229a409b1e",
                            "FNa8e7237f00e9529b804a9ded493a5643",
                            "FNae27d58d9b01d913cb67ea78e6124cee",
                            "FN0f9a0c458c54647cf0871c2ed4fd9d56",
                            "FN4210c0ada4442c52d1bea0a906ad592a",
                            "FN87e650ba9240bea3b8a4acbfc189a20b",
                            "FN55d4c3422a2084a6900eb41ffa0de0d0",
                            "FN69b427b9ab9daaf0e09ae7b28188e9f0",
                            "FN62605552477a463e1e734b71357e6dd6",
                            "FNdf4be6c24602a6d4414a64e5408954dc",
                            "FN01ca13badaeb138b1dcaeb134e27a5d8",
                            "FNf13e551fffd22b386dde8e42112f547a"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 17,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 742,
            "DaysOfWk": " 2 5",
            "TimeCreated": "2021-12-10T15:36:56.960Z",
            "OrgName": "S&A Unified Home Care",
            "PresetMsg": "URGENT - S&A Unified Home Care:\nPlease complete your Part 2 - Inservice 2021 program ASAP & stay compliant.\nClick here: https://bit.ly/reminder-HHAX",
            "campTime": "2021-12-10T17:00:19.386Z",
            "CampaignName": "S&A Inservice Part 2 - language NONE",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C362",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "S&A Unified Home Care- Part 2 In-Service 2021",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 164
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 61,
                        "msg_req_sent": 61,
                        "msg_scheduled": 0,
                        "user_add_success": 61,
                        "user_add_attempts": 61,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNefbd6f3da24b374a53667ef2e2a7c5d3",
                            "FNe49aada53d5797885897b118b7372e02",
                            "FN190a2ced9cda82ba62db5f6070568319",
                            "FN172dfa61b02c6edb25dcfd0071e8c66c",
                            "FN87cadc1ebba7670196b9df33c28293b8",
                            "FNea2ad0a6bac4a991a5c9f70aa4ce06b6",
                            "FN0f2f8ab921a2bff40c58662a18794120",
                            "FNad53f82d0cae8d7f8ed1b5a9aab44221",
                            "FNf9d9ca7afda8e2fe770c3a9d856cc262",
                            "FN795329db29919b72029511149c2312ec",
                            "FN7e164f476eb9e16966c9707822dbac0a",
                            "FN9602f20dcdd354e54c208796ef721693",
                            "FNa8acfc57aba1ff7118777d0a58de7357",
                            "FNfd45878a6170c7a082cc431ae6f01fc0",
                            "FNabe7e9ba6798f72d1c97ca859c39b40f",
                            "FNc241271ed443ced2065d222f678b333b",
                            "FNbffe9a9eca280bbdfcce793e41fefe82",
                            "FN51917d89ecb00a846611360ffcab71af",
                            "FNe564b768f37e6554ac56a79886f57622",
                            "FNf5c85a97f50b2cab1bea52c210272837",
                            "FN9f88b4a2e525355bdbe217ebeeed1dbe",
                            "FNe1a1b1a1a09c675291d4bb4924afe047",
                            "FN2ea4adb2d92330553d23aabf77518608",
                            "FN52af1f7119cd35dc314ee0e532aeca4e",
                            "FN8b6bd532668e9a773a6f67bdc4995438",
                            "FN298c8494b0678974b7d228d22cc7f05a",
                            "FN68379e3b6689943a021bf5f5e7578e73",
                            "FNcb4eb09a8ee9be4667357c08af501dd5",
                            "FNf6a919a641ad2cd125896863ce27143c",
                            "FN9f58d8bd2ec669888028927573f1fc2d",
                            "FN80849f9d2b85b17df9f1917082c2afdf",
                            "FN67eff579853cb86629698ec7ba5d80be",
                            "FNe235a8f3ec59236757d90d93ef6e7f69",
                            "FNa074012d32a6a6a488011d127c11934b",
                            "FNfc41804479a608bab5b458d9ea15bfa1",
                            "FNdf8a1db1884f81ec9a4f8682c5d349ff",
                            "FNcb8447561fbd9512b8e0f07112adb832",
                            "FN4046defdc8eeb4e261f4f72ae59aad16",
                            "FNe7ee7a7e9742c5f0f5a507d1b3d44478",
                            "FNda2f26cd7ed056263454b30a3a13a3e7",
                            "FN1f4964175c789a8290a6b6384e5b6dd9",
                            "FNf9c423888a07e4e8f52e29e397fa3b6d",
                            "FN93022e12565708c697983e2723d1e18e",
                            "FNa49bc81091aa6fc5b1560a6c2ae4bf88",
                            "FN6d5d8207317495d19d57254bba3c4898",
                            "FN20cbc93928c9c5c7e7a8cc8f876b1fff",
                            "FN4a0b15d5ea4ad08298f3dcdc1da058f0",
                            "FN4f5cf96dc3ae55de04541304a845b198",
                            "FNf5bf8db6ea11bbcb0d455feec339deaa",
                            "FNff243f4f4eff9535aba7e65ae25f3161",
                            "FN398b524e891e042b193aa538059928cc",
                            "FNac3b2b61cfaf1e8f8882986fbfbdcfb6",
                            "FN0c8d93a20cc89e848a172a9e0219f539",
                            "FN29694929d29a7097fe46f99d3dcf7040",
                            "FN0ab01836f628095a61683abb19cedaee",
                            "FNd19e14955cdae00e8d008440a5839f87",
                            "FNaa47e7e781a98f6e844c16039c3702e5",
                            "FN30b564b19c975ae2444619568230d6fe",
                            "FN86b7e804d1bdfb74b30ef3758854cd76",
                            "FN3c211536975075c386abe3ac5fd5d9dc",
                            "FN9953ee7f2c58a8770503d9113d29fc01"
                        ],
                        "msg_queued": 54,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 61,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 61,
                        "msg_req_sent": 61,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN281499deaba634760c7bb9b07a018d5a",
                            "FN58a508e92335795222c993809ef71ae4",
                            "FN0d7f018ef8545f06bc4a0a721eea644a",
                            "FN592d80a014ccc4ecf921c8161a85ef73",
                            "FN9fb074947a56e233645e0048b4f88216",
                            "FNc9e82a7c29d114bc2fcd08e876dc8169",
                            "FN787a9d56bbb40909f9789c79d5c3fcaa",
                            "FNafb784a8d8c8a83f2ea9b420bc173593",
                            "FNaa9a43b540a4bc1d39fa4ceb653fa590",
                            "FN946016e6a397d64664452d26f9f7dec4",
                            "FNb8ab59fd96897b98445ed21f2dbe3bc4",
                            "FN834870f7618635b8c76f2b9f84043812",
                            "FNb41b27eb395db9a09d0154118503294a",
                            "FN2bf00a561b04d1b911dabddcadd54dc4",
                            "FNf93705ce188a117a768cebdbddb70265",
                            "FNb23bdbb23836d046f186e0f5a4e398c4",
                            "FN8fb18ab1e9179a5bf60a3932ee9e6622",
                            "FN51359d5a4187b8fcac55922c9a378ddf",
                            "FNd956595846628a587ee6391447949ab7",
                            "FNfa4ab6087e0accc4bed6a52912999337",
                            "FN14bdcf73b4717e1e2697aef74ea7029a",
                            "FNe5a88a2b4c7a0649980b21e80bb08651",
                            "FN8b481d7b0b70df32092979ab773c4745",
                            "FN8a699d84800168b2ab73646c06101349",
                            "FN427634f525d6dc8a021f8141017786bc",
                            "FN20adff6abd9bcccb1f280014897590f9",
                            "FNb1ac0ac5f60a93222cbc28a46c2fa4c6",
                            "FN1c4ea9e962df7bb62d51cc73735a467d",
                            "FN2ce78e9191cdac3f16e2d3a87693c204",
                            "FNe5b775f6d85b4735c076088bd511eb13",
                            "FNdcf6e2c2682c808f1d0dce51937af9ca",
                            "FNd5c081ca997fc67192d2e28ff49a099a",
                            "FN0ef0c84f3fc41559196ea4410277c178",
                            "FNadc240af3b6f5dc243884bc25d2fb07e",
                            "FNa2d55a63891751b02d5300fd7e654b22",
                            "FNc516f7e7f56c9da779577f1d6ea28518",
                            "FNeeaabdd6077de6098b9bdd9fa5ff8d0a",
                            "FNe750759c5f93a053091ef3c56b0d1185",
                            "FN7281250849d812c0ab64cbb3b8cc29c8",
                            "FNab35253f805783ceba2e70dd8fcf1e66",
                            "FNc4b47e7330a78113822a860bd2061c5f",
                            "FNb22172dc4d46f4ac3e1c00eb71a5b6a2",
                            "FN31e783bfef81a3ab68d69f4333f80533",
                            "FN0bb81b50cc6c1cd1c6c30c9486be92e2",
                            "FN42089dc3dbe5e9225402f274dc1279a1",
                            "FNca9118dbf4c44a36f71298e430b7a84b",
                            "FNff29276ec7bb042a4a020637a3470f31",
                            "FN189cb79381f3aac6007419f4b1fa1bfd",
                            "FNe80d05e77f51cabd326ef5f6cbad7e7d",
                            "FN1462d30b153661c7531cb5c5c4004c42",
                            "FN9c19b620b8befa174445afac6f6bc23a",
                            "FN840ed2045bbcde8b7c017aa4d46ac952",
                            "FNf125d38d863d67069e71d773d9da1ec9",
                            "FNac18c9bc3904fbb83be6fdacc5d65f86",
                            "FNc076bdca78583b9b258c5688e34cc7a5",
                            "FNf65b28ee51e817bf5a3ce9c740745f1b",
                            "FN4d9e5050fb883ac6fd233b37a2a40558",
                            "FNd7483d963aa6410ca924f6a909eb9bcb",
                            "FN9818fa7db98c75c88933d3d3bf5ce87d",
                            "FNe98a6d7fe5c7069fb3b9ec7c01d44bc0",
                            "FN9335637e7bb7ffd35b9f679fca4300f5"
                        ],
                        "msg_queued": 54,
                        "user_skipped": 0,
                        "user_update_attempts": 61,
                        "user_update_success": 61,
                        "msg_sent": 0,
                        "numOfUsers": 61,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 61,
                        "msg_req_sent": 61,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN776ce09d671ed6aa5441a7e7e5b22908",
                            "FN595b2ca0b927d22e6ad64b0f79afe5ca",
                            "FNf5868fedb7caee5a8c4bcc945e7b968f",
                            "FNbe80fc10296312e5f158cadc81c98982",
                            "FNac1348453d7a9993a4a9a1032fb65201",
                            "FN2d2e48ba7fb2b75207dff365c0f1ae23",
                            "FN99f7ec0940186b6fcf79f58b4a9d52d0",
                            "FN59a695703daef1d630503cf3e4926d06",
                            "FNd710bde1b63d9975f0a1f785813cbf70",
                            "FNf247df5a6ba27773719760b2409a3148",
                            "FN7ccaf367b6f1a1e2b6dd363794092253",
                            "FN52f99c54fae041be780def9eddade262",
                            "FN877915c921379c5232197dbd53c1535c",
                            "FN480ac3c41a0655a87e34eaa8089eca40",
                            "FN979816a5862c8ef87e5239759efe4f80",
                            "FN043be2334f71f54ada1b167caf8b1655",
                            "FNbed7eebebea10cd7be541fe19106acbe",
                            "FN3c649c3b625893a56152d41ccf50656a",
                            "FN63f89b08c7bea96af79381721500ca82",
                            "FNec5c7bf6b6ee49f48264a7e7c15346d0",
                            "FN09593e0804282827c383a9767a939aae",
                            "FN0731984d3dedc00c9d29359a728706c7",
                            "FN5f4eb385ddee559cc491a52b765f9453",
                            "FNf5a3a9a937a67ef8e91a6cff5f932cd9",
                            "FN4bf01ccb7b979cc1165c67bfc1d3b796",
                            "FNc0fbfeeec9cead6c4cc02a870a24573d",
                            "FN90cd35c4bd6ad43fd16513160f4b090d",
                            "FN3cebdb1eb3c6afaa10a6d543da781ab0",
                            "FN4bd63555cc1fa8e822e20a0a3e35ace6",
                            "FN598361a52e42db54fccdd6f4d8a78339",
                            "FNe9aff05f733e3b68e90c94e6c3773268",
                            "FN6a95530b18cc4af19d5f0555b1fe88fe",
                            "FN9e2b000fa62c5c114808df2ff0788205",
                            "FN404c92c1759a32007f9fe318ecbd739f",
                            "FN20637f56d85bb7758dfdf79d90a762d4",
                            "FN060ee4e576ba2a740d33fa24750b19da",
                            "FN5f88c69985520f496cf3d88a00d439a4",
                            "FNb0f5c4bbbca9556024fddd0713e1e25b",
                            "FN9fc62bbac1eb29c45b99c5b4682d7e23",
                            "FN04b1beadac6e98f5818f1db47113333c",
                            "FN9eb50f7bf7f04201de28b5ec81491528",
                            "FN6eef3c5587a489a0d5331a6c9414960e",
                            "FN60c4d0e544f0f94aa5d3648d851a81e5",
                            "FN477d66f06a6fa435a6fa6bacab452bac",
                            "FN6bca8a1f0c6e7cb9cd8219317766307b",
                            "FNa06e4fd65127ce4ee85692ad6ab38c14",
                            "FNa066ce7cb6e19a9994ba8afb938db7fe",
                            "FNd853b9a0370060b59797fd768a7e24cc",
                            "FNb7465a33b05fd746fa145795805135d7",
                            "FN1d2829eb4529dd56d732608309f9f8f0",
                            "FN2d3ddc15b9fb1e767e998f8d7bcb420e",
                            "FNdab2b4972071743d65a2776cf5408d41",
                            "FN32f3ff5526713507cad488ae57bd83fa",
                            "FN1766fb18432f147e461c1998dbc8a8f9",
                            "FN0c4ed0d57929fac91743e2d9ca605bfe",
                            "FNf2f103c1ac8f75c9b1305c10d82afbe2",
                            "FNffde755557da4df2a9327e3582c50b6a",
                            "FNd75a4437e24e303ecb8b39bb2dc81a91",
                            "FN0ab12e8d1d6c983532094a842c05b035",
                            "FNa46128d9136768cf2ba587b6049e6305",
                            "FNb35f0d0958569bc9578b1a0e3b7553db"
                        ],
                        "msg_queued": 54,
                        "user_skipped": 0,
                        "user_update_attempts": 61,
                        "user_update_success": 61,
                        "msg_sent": 0,
                        "numOfUsers": 61,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 61,
                        "msg_req_sent": 61,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN3061e28a1b7f4b61c436949360a6acc2",
                            "FN4a622ce981ee49033a4e56fc49f916de",
                            "FNea868de6d104438c6df690d3e0537af0",
                            "FN9aa0b1bff8696906d66c8b016a8a364c",
                            "FNfcf8af5644aa21fe6cbc36cb2adea9f3",
                            "FNbb2a82a6f8b523717f8b3d84bc853c42",
                            "FNb1f1ba1486715a2504cee001fcb2e4eb",
                            "FN860949285b7244beb7d62399b1b28c57",
                            "FNbf3cf55ea2ab5fd9e552a91fe1d0a58a",
                            "FN4fb7e17fb443419baa75e679f824c923",
                            "FNd56c66749e97770156ec0aa093b1ce18",
                            "FN994b3a5e0fbbcf610bb778e2954a514b",
                            "FN2904ba3ce48ca32b673dc05edf8297f4",
                            "FNf07704f6eb1b9641c8583f63198d15ed",
                            "FN6d57bbfaaf709c918455521b06a83cf1",
                            "FN8ae7f2780f22192574c69c3aef53bcc9",
                            "FN0b8512a1a91de1bc39bff0802f1cc8bd",
                            "FN8a4cdd932df5fd9b6dc86d27f4d7ba37",
                            "FNdfed9dda0ce4e4f10c10bb31cd640119",
                            "FNe6319320023ccf9398e369d3364690ad",
                            "FN62c070f3d16e82be11ebac0c1bc868f4",
                            "FN9ba26a4125a94a74a758f3d779555482",
                            "FN379176e9d9467838a0caf4217cb4a797",
                            "FN9d31f46aada183bfb6a3a6624f4720a7",
                            "FN92bfcef58122c9415fccc70d02096e60",
                            "FN154d813f4c076a7fe8eece7a7977f1bf",
                            "FN398d222942c8b6c7a96f6b570b5865c7",
                            "FNc0333a40d7def435b0b1124cb99468f5",
                            "FN2aef9087a31fe535e59d6d540a8bb8e4",
                            "FN1d6ed3e82366f948790a682f858af260",
                            "FNa0a5cfd1983c2864d3258b20ff20eb7a",
                            "FN8a50cc20c67f75e440774d5937032fa5",
                            "FNa1e415c68607fb0d0f3406c5cb0271e9",
                            "FN84cef73fee0ea59dbf538da8b9d6cd92",
                            "FNeb4632b89cddf24f2d51398c4ca4dfdd",
                            "FN58ac1ad5548a2542f329bb1205da8b92",
                            "FN78b764e7989303497480cec48f32ade7",
                            "FN89a79d1eaf8594130a28902803e17387",
                            "FN8f04965063564a4522b82fffd9afb94f",
                            "FNa26b38616d149cdb38dd001a33ae30f6",
                            "FN68e6252709855bd9c9761f101fddd0fa",
                            "FNd97eadafda8c27007a6f34854382fd66",
                            "FN6589f4502b8ae2ef5603a2b2a513d6f1",
                            "FNa071f8e156c0e59775525243565ac955",
                            "FN4be7e1753cc1ded99d87d94ec6544f99",
                            "FN1424d451dd3be4a8250ffcd5c5d5c424",
                            "FNe93890417511c90f4e3609ed25ab2f51",
                            "FNcbdcfee93b4ba86a193e948d5ee87b46",
                            "FN557a0b0a87ac205002442881450f9ea6",
                            "FN2d30655681ad396d44eac9877d5bd06d",
                            "FN98115df16bbc3d891d3ee536e38da027",
                            "FN9a7a8ac87ff87c77747cf9be37b06146",
                            "FN403215d1de4c68cd3f918f0234ce4dc1",
                            "FNc0d366118d58791f588896b9fdbed93b",
                            "FN07801f7c04b138f2d400c685c2a73a5a",
                            "FNa626578c23479ffc1a6e4819fc0e62d0",
                            "FN95bc4b28702cb6de661a2b641679c423",
                            "FNa6942a8e8c6d199860d25a215c6fe7e3",
                            "FN2e98d60b6b3f7b631665bd3e3663bf6a",
                            "FN2ae85780234c09e841b75d0b477e5511",
                            "FNc2d31a889154cbc66898dee52782795f"
                        ],
                        "msg_queued": 54,
                        "user_skipped": 0,
                        "user_update_attempts": 61,
                        "user_update_success": 61,
                        "msg_sent": 0,
                        "numOfUsers": 61,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 788,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-06T20:37:36.125Z",
            "OrgName": "Metropolitan Homecare Way Inc",
            "PresetMsg": "REMINDER from {agencyName}:\nPlease complete your mandatory inservice training ASAP to stay compliant.\nGet started here: https://bit.ly/reminder-HHAX ",
            "campTime": "2021-12-06T17:36:25.098Z",
            "CampaignName": "6 Hour Incompletes - Language None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C36",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "6 Hour Program",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 238
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 50,
                        "msg_req_sent": 50,
                        "msg_scheduled": 0,
                        "user_add_success": 50,
                        "user_add_attempts": 50,
                        "msg_delivered": 15,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb2c0d0e03687758c7cbc9eab9b482e69",
                            "FN458ee8a440aecfebc3f8c7f0fd64aa54",
                            "FN0f76dac0c0e8814e8b37bfbe17ed4d78",
                            "FNdfb10633edb1be7fd9e289e42dfdaf3c",
                            "FN1b2463c2c934c99c368c2ff7a9838937",
                            "FN5be55872e8b6095cc63f95b83c2166c5",
                            "FN32addac403cdb99d7c0bc2c05bdb14c7",
                            "FN19546a66c0b7237dce00065b5b078004",
                            "FN500bf140676799bbd986019c9770f255",
                            "FN7887ffffc540f1abc8417130d84e178a",
                            "FNeb862acce4e25983b7048c1d4da8f0c7",
                            "FN95b8bd483cd549726a18ca01d11863e5",
                            "FNba0f017fb13aa1d687ec18d9361fb501",
                            "FN4356b02006b23212954ea27053d4bb8a",
                            "FN089a0dd7d74db2de0cd18ca5f6da2435",
                            "FNc2951c0b438ed53ffc14d8f5f51fac61",
                            "FN1b0169e39feb6ef3bd8291ff002a0e6f",
                            "FN21250da2d9e6fb1231c2b1962b6dc372",
                            "FNa29187efba409039b2f7b489762c6541",
                            "FNe0de20a68888277b93bec8e312b013f9",
                            "FN561af7adffdf49d5b148ba16ce8b6b6b",
                            "FN91b45c11d675b919a5f658d556e4ab9c",
                            "FN372eb4c0a6d8c6ba7bd47c4afaf6881b",
                            "FNf6fe280e0832a656a1a8e8114573fc95",
                            "FN6e3047157496227bcc50bf0e9d0ad580",
                            "FN6d04f123eba9c4f465e01c07b6284ed8",
                            "FN51c9c5778869c2b7c4caa06e302dc1a4",
                            "FNe5e4a3ddb150f162d831a7fd8d181fdd",
                            "FNaf80cbdafbe25a92b51f873328a4f337",
                            "FN78d771a58d5081523dcf019fc2daa79c",
                            "FN49757f68cfa7ddf6d840846839ee4dbd",
                            "FN24bdb32ec6edf5d7799d842b6ac9cc91",
                            "FN826ceda6a74e7f0b6b15ed5fedc888c8",
                            "FN5c2cf6d9d26228faccba0178b1b9734a",
                            "FNd5493052edd8bb94098720995590614e",
                            "FN192cdec6272aebd5aa71e0e181d6ba17",
                            "FN0ad89bb485c49988cf4145a445a47807",
                            "FNe0234428c8b8ddc14fb6ab37d426373b",
                            "FN601e3827773110ea7f82227567a0309e",
                            "FNa05d6952c93919ab50cfc47652fee548",
                            "FNc33d7d3c30cfaf2ea86f61e70bfee2a3",
                            "FN4e0470b5f8838d6a606dd904f972b5d5",
                            "FN2ce41b1a7b8a2961adb37e6581290595",
                            "FN242fcd19d4ef516a13fc78891e8b6603",
                            "FN6f5d08b68ea27221280196798cbc5ecc",
                            "FN32f72cd0c6a66de343c46ce17135040c",
                            "FN997f9844fcaa64b484580430d5cdbcce",
                            "FNd310e2c1104b2725a7be367c2c6d9acb",
                            "FN0dc0455c6e3e99d183d08465c6de39c7",
                            "FN8ac4cb23f60bcc8cd268188eb88bbfb5"
                        ],
                        "msg_queued": 28,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 50,
                        "msg_undelivered": 3,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 680,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:06:31.831Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "4 Hours - In-Service Program - Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C433",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "4 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:10:36.069Z",
            "OrgName": "Angels in Your Home",
            "PresetMsg": "来的温馨提示: Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T17:00:39.053Z",
            "CampaignName": "Angels EVV-HHAX 2021 Chinese",
            "FrequencyInDays": 2,
            "LangId": 30,
            "CampaignID": "C99",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T15:23:13.138Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": false,
            "OrgID": 206
        },
        {
            "MaxProgress": 99,
            "ProgramID": 797,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:25:15.290Z",
            "OrgName": "Classic Home Care",
            "PresetMsg": "CLASSIC HOMECARE: Complete training by the 20th to keep your certification! Go to the office if you need a computer! start here⇾https://bit.ly/reminder-HHAX",
            "campTime": "2021-12-09T17:00:18.905Z",
            "CampaignName": "Classic In-service 2021 None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C198",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T17:25:25.088Z",
            "ProgramName": "In-Service Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 239
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 735,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:29:44.550Z",
            "OrgName": "Gurwin Homecare Agency",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Gurwin Homecare Agency: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Part 2 2021 - Russian",
            "FrequencyInDays": 4,
            "LangId": 134,
            "CampaignID": "C451",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Part 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 107
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN7b00c77df72d134cfcafd2e5c2a86b89"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 602,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:07:39.039Z",
            "OrgName": "Open Arms Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Arms H1 In-Services 2021 EN None",
            "FrequencyInDays": 2,
            "LangId": "null",
            "CampaignID": "C434",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Open Arms H1- In-Services 2021 EN",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 187
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 69,
                        "msg_req_sent": 69,
                        "msg_scheduled": 0,
                        "user_add_success": 69,
                        "user_add_attempts": 69,
                        "msg_delivered": 23,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN79715f5726653c694eef8fcbdc2bba45",
                            "FN3387a7a52b74aaf7e6605c7606edfafd",
                            "FNfc9ce14f409e2339c60ee51709a24a38",
                            "FNe6d3d64b6038b66afac13bbcb3617469",
                            "FN14573e98689d84b797784569db55dcc4",
                            "FNc73d43cb4b27cb349ce8198199326d74",
                            "FN3f36b60c075e78ab8ab52211edd36cbe",
                            "FN5053b5764477719d3477917bf516aa8e",
                            "FN65e28722dc6262c30edd76c112fc97d8",
                            "FN79dc0b42471b2470e22286852c98bc42",
                            "FN9506812392e380c95b9b98ac904db420",
                            "FNd9ccf4a8be29d6486716ff7c27173f3a",
                            "FNfb92fb99f476c29c852e37093f77ce10",
                            "FNd9308b00f27cc8ff797e6c4bb420d184",
                            "FN41acce9e721008db945418aa8a381b5f",
                            "FNa440ff6e60930debbcd288cc2df56b92",
                            "FNe6ec5dda33c2498d625e67edfc61989b",
                            "FN0eb0438a2da879626da69214e9bf7dcc",
                            "FN8e80c13c70d7179e5c50b87d8bbd2c8f",
                            "FN189f0f43b4c68a490f3aad23b343b0db",
                            "FNd0fd797bc4cbc2f279829b6afee4de27",
                            "FNe34a4ca7a954224ba83d88119fbed3b5",
                            "FN9f8eb2d81fca1e3e1e744db40a3b7ca4",
                            "FN76f81043949e2e556ea00f546f488abb",
                            "FNfacae0f9a09ff1ca5537d17a1181c3b9",
                            "FN547b399b58c8d212b403133982add371",
                            "FN73e993cf23c7434c436565b49240a89f",
                            "FN0b0842fd33edb6cc7a95abea9baaeab1",
                            "FN2d697a5ee66ece53d394eee32694a8e9",
                            "FNba75b2821aab6d967d2d64f1e8641bc1",
                            "FNfc06e2c4a1536941a0c69a4d7554e3d2",
                            "FNdc427cc08a8719e7ac2e7c718a23bb7d",
                            "FN14c77096de7217f76ef908f95958a914",
                            "FN4e6ef424ae11a7b7fb0d560afe605461",
                            "FN5c016b37de8b1581bd1a977ae1ff42e5",
                            "FN8456b9372f2e644168ef9a15f0a24643",
                            "FNeb8ea4bb747a3a60fd240fba1ff64630",
                            "FN434bcd9ff88f658955253d8d86019513",
                            "FN0512c6e30551429c97feeede7ffc5273",
                            "FN5e6302b608fd973891bbd75bb172044b",
                            "FNd44e0952198ad9d7e823470bdc4c2845",
                            "FN1a3c226685f1218e987068fbb95d8a90",
                            "FN08807fc765687a166471886d29d4363d",
                            "FNbe48357cdddd225140b33b3b7ba5bd01",
                            "FNd9d0734c0153fdc5d8290b8ea96929fc",
                            "FNa3fe41df1db9975ef2e07c10052fe657",
                            "FN0e8ed491ae2059ceb5b2a150c07209d0",
                            "FNab9488e0cb020c3e5651456e16fb4f52",
                            "FNac5f5d6a8db98bab8db76f2b69c3a0fb",
                            "FNc9f7944fd1e38014fa3dfa4a34fb5e4a",
                            "FNaac08c66ba3a35a4daab8f373a43e56d",
                            "FNb45e658cbdffcb3d2a5259e8c8014a45",
                            "FN675710784dff877e34e4f92d18c4fb88",
                            "FN1dc3615b9cae6b6d9c0625b80a3a86b7",
                            "FN77afb3cccb18a26c7994b4bef069e6c8",
                            "FN290deda6abb1f0545ba2983eb76b8859",
                            "FNee7e204a73c0edb3af46e9aa2b45a83e",
                            "FN78eb2ceea8441d95f566503d37386c17",
                            "FN376684e6a82a890727a7a05a006c71ca",
                            "FN6fd9cc1c469cb54e8305f79ec621d92c",
                            "FN28ad39676dfbff276748a5acab306578",
                            "FN6db0e8620ed0738801449c2d6b6864cd",
                            "FNbe6a110ca3876a1d304071c122af2469",
                            "FNa0e941287818391596dae4c5a481aa49",
                            "FN031317a9212c56fe811c70bac36a13db",
                            "FN621f1fa0bf5e7c9e2951f8dd303442c3",
                            "FN78e61b730d94fbe455309ae09bd0913e",
                            "FN2758533c92c4543ea8c5284d3d70a8b6",
                            "FN7b9cd1ff7d31e7e9269fb685bccbbeb4"
                        ],
                        "msg_queued": 37,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 69,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 411,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:16:45.038Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 4 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C402",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 498,
            "DaysOfWk": " 4",
            "TimeCreated": "2021-11-29T20:59:25.840Z",
            "OrgName": "Wyncote Wellness",
            "PresetMsg": "From Wyncote Wellness: Please complete ALL in-service training ASAP to stay compliant. Click here to get started: https://bit.ly/reminder-VIV",
            "campTime": "2021-11-29T17:50:23.007Z",
            "CampaignName": "Wyncote Q3 2021 Incomplete",
            "FrequencyInDays": 1,
            "LangId": "",
            "CampaignID": "C29",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Wyncote Wellness- Q3 In-service- 2021",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": "2021-11-29T21:03:02.689Z",
            "Active": true,
            "OrgID": 169
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 28,
                        "msg_req_sent": 28,
                        "msg_scheduled": 0,
                        "user_add_success": 28,
                        "user_add_attempts": 28,
                        "msg_delivered": 9,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNf7e9ec9aa755e0922362d998d9f4bffb",
                            "FN5e1362167fa25a39c127033ac9cfef84",
                            "FNf443fe9b751ce0e05b90299a9f116e7d",
                            "FN658f38fab82ed43c2577a5b829b5f4f2",
                            "FN32c903576edf50c8709040ddd5f17220",
                            "FN4d729443f6421221aaea99eacf3a465a",
                            "FNd7cf73240e61524fbbeaee9e9c2cf855",
                            "FNfabbe2778f6527ba73026703049d8b74",
                            "FN146ffc77e289aeac66e394a5767f9ea4",
                            "FNf8a3f0a979f81ecaf7eff85a52817a65",
                            "FN51201abc2c6b70d80783e3cfa4fe08b9",
                            "FN76c3106d41f6f0400c47f1a02e97b0c9",
                            "FNafa88126b6a331139999d20c91fedc41",
                            "FN40f6e3b939a75a3a47441b92a16988ed",
                            "FN9773ad057802d1d4dcde56e61e1c9e73",
                            "FNcf56e047bf4710efea084210c439d729",
                            "FN46d3c03960c76e3ecea600e1066bed4d",
                            "FNfd362de27aff906adc3d6ac4fb560cc0",
                            "FN1153374a1c34aa7a65d44077538f7f14",
                            "FNabe8a266949fa9f9632badf937231946",
                            "FN3256cf94a6e26dc884fe874fa73f77c5",
                            "FN2bfc0b970f1f54200e87bea3c584d767",
                            "FN09dd46a40d60ba193adafc8aa2f1417f",
                            "FNc1788b65f55c7df74c17d2b42c04573b",
                            "FNc2d568da222da2f78f5a1b7c5cfb3b67",
                            "FN1dc5fb502e87981dbcaea7a236370988",
                            "FN3b0b8b0c286ced59f1a26691f96f39d7",
                            "FN3646fad56b502085442b36b34beae1c2"
                        ],
                        "msg_queued": 12,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 5,
                        "numOfUsers": 28,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 682,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:31:13.015Z",
            "OrgName": "HDA Care",
            "PresetMsg": "От HDA: срок вашего INSERVICE - вы не соответствуете требованиям. Войдите на https://bit.ly/GetNevvon или здесь: webtraining.nevvon.com. Вопросов? https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T17:00:36.561Z",
            "CampaignName": "HDA Class 2 2021 Russian",
            "FrequencyInDays": 1,
            "LangId": 134,
            "CampaignID": "C322",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 2- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 739,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:32:33.386Z",
            "PresetMsg": "REMINDER! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:55.059Z",
            "CampaignName": "FADMO Q3 In-service 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C148",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q3 In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 890,
                        "msg_req_sent": 890,
                        "msg_scheduled": 0,
                        "user_add_success": 890,
                        "user_add_attempts": 890,
                        "msg_delivered": 717,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNf53490af1d5635ec3672a0b48d543ce1",
                            "FN6e7102b550c3c8f4264fd5158de2b3fe",
                            "FN5d282b0906eaf0fb554592b3db0ed46a",
                            "FNb4fb566c72fbc28696c070235a55ba66",
                            "FNbfe78918db6e9797cdccc0eafd916ef4",
                            "FNf5b80cb9b2738cf04701c20bef89e20f",
                            "FNffe8850c3754bf6eb2d73016e212f1cc",
                            "FN281a2ae2df7b02b7411047c2f8d4c6e5",
                            "FN4df5e15489c418152685d3efa1bf32a5",
                            "FN73d2b935bc2b2696c7dabf416c5de2c6",
                            "FN569c4e3a4e026e71f71e31b4c9bc5bc4",
                            "FN84239971ac3bc6c9f847e6dc05b53a51",
                            "FNc0489ba2a5977556ada8df6aa7adfc0a",
                            "FNe091f898fb222666c53859903b81ade6",
                            "FNfe7985c5cdf67595241749ff6a98d114",
                            "FN44a6652bc8883c83f394a6d2f3fb0849",
                            "FNf87b789a04122842e6506c0830a99611",
                            "FNe8cf0d19f07e46c7850de700d6ed586f",
                            "FN4e8cfd5dfcdef5e28170e63d02590d26",
                            "FN7adf12401c5a520d5814f961956fdf27",
                            "FNab298dc4cb34ed02b7de019b5862dc9d",
                            "FN6fcde02a3376dfc2ae4a7f7b1e72612a",
                            "FNb8b3dd4e5a09236a72969c613f1f0d75",
                            "FNc2217760a2edd89c4ae7a96909198857",
                            "FNb85d7aef71887faea08e1b7d1e9c70d1",
                            "FNe552962df7684e0f0eb7ce18a9db75f3",
                            "FN4b643da371c363097484afc1aa0acf59",
                            "FNb464ed32f56d8e5a2143b1109374b8ce",
                            "FN0fa950d8b2ec2f8737acb7d129a5e56e",
                            "FN04cbf3bd5e380bc4ad210fc6837ad9da",
                            "FN4ad663c4ec3d461a232678e0f0905abd",
                            "FN1c771686dabcbf5e1974beffcb999d8e",
                            "FN23cf271f7638e5ffad89fb18e992a989",
                            "FN1f3397c82b3cadb7dfae6fa2667ac3c8",
                            "FNbb2b8a68f20f982d758d53fac38891b9",
                            "FN5c06d73f6412e8c7ff4375a86db575f2",
                            "FNabab24f2cdcea08373c15370d1574a6b",
                            "FNf7e508e7262b7caec32d0c46fe57b68e",
                            "FN1217717a0acb2ff30cba726b588f382c",
                            "FN1b0701a9bde466884dfb3af2b47c75fa",
                            "FN17a4adb089a5e4875b0880e2bcb098f8",
                            "FN8c932697bb4b56efabc072d14cdf499e",
                            "FNe257e8325d61abdcdd838028d8556699",
                            "FN7be311797bcdc885030bd8077abd73b0",
                            "FN871005fa0475df4807b63e3c9123820c",
                            "FN411585ffcf4570e785b8f2cea944136c",
                            "FNe3ca63396849b4fef67ea1963ab54d83",
                            "FNc1d8456d68f2e88ab3177f3775357594",
                            "FNd62a97c8447932a65a29537132e48b0f",
                            "FNc15348a335972da2e4116a4e43367a4e",
                            "FN6c90e6f954c6f629a5d755adabea7053",
                            "FN1dac742d18e748512ab4d8072729b23f",
                            "FN82ec7340fed868d52227eaadbb39296b",
                            "FN7c03d9b28ff33526280700ba9ef0a35e",
                            "FNe7143b987c0d28c97b26f25149e4ae29",
                            "FNd427409a6251b7cb7b3d702e7a390e03",
                            "FN06e14dbb5f754d0f20a1e9effee84602",
                            "FN004113dfb2a16b311490dd7c76b4210d",
                            "FN882e5cb350a6fefa7f475528f4d89abb",
                            "FNf01d070e38be2da03b4b880e246b9787",
                            "FN61532528158105a86fb26945b9e0e6a1",
                            "FN7d35dd1c0498818bc9cfa718f31737c0",
                            "FNa61010bf5c17bbe91e43c26ba5300a97",
                            "FN489b31c8e339c816a14cfd1fdf518720",
                            "FN627b95302717f47faeea51bdad992a04",
                            "FNbdc788a2717dc9913b403e95416e01eb",
                            "FN40ce77524f3fcbf374f588de516f25af",
                            "FN02355ea45a58705102e94842ddd1abef",
                            "FNc50a526a95185d6482e0db4f0ca23f8b",
                            "FNbe3cfff9451eab2372132088c72562c6",
                            "FN8509b6578eeee5519253a8f00d6ee0b7",
                            "FN2497ef8812c69b46bbb14276e087991c",
                            "FN6d7e6b682bdd17fa4db000ffbf06608a",
                            "FNaae2b0470ac86d207164e4e7343c9c5c",
                            "FN1adf2c871831a43b71ffc069f0326ec9",
                            "FN97f83ab4abec35bc4ffc2f7fa4045be6",
                            "FN84b2fb9c4d74dc036f3800dce50c270d",
                            "FN5d2e5f35de88da9857418c0c14321633",
                            "FN3d72feae514df42506fef6a808614685",
                            "FNe875e2ce9a24aaf54324b8aec5e26409",
                            "FNe291c7d8af59daaf456abdafcb9fa720",
                            "FN97091ae67668a52b3cec41b4d00c1ad7",
                            "FN969c696cc080243ccc3c843c33d083f1",
                            "FN894e095c9d10bd23b4d2a99c690ba561",
                            "FN82113812ed29d9b4e6f33adf83ba5f1d",
                            "FNd30494842974681f60d5ed516cc6ad9f",
                            "FN2754ad1a5386757e42ca571a9d531762",
                            "FNa842b61df693c18ec5eb83136aaf5067",
                            "FN55e492eab2aceef8944f4a9d4b7fee9c",
                            "FNfa1f4394d07c78d544bbf74560859ba3",
                            "FN20d3081598d22702651edb01fd04c18e",
                            "FNd48455afa17b2abdf6837d0856d6edf3",
                            "FN4e623185abb035498ee705a2834f44f1",
                            "FN9774c72fc7d5438361bb196534f1560a",
                            "FNec83bda187c81d627d4b70080d11afa4",
                            "FN7823a6e922e7b865fcaf64083a9e2fea",
                            "FNf75ee105850fd96933893b88eb3440e4",
                            "FN57129592c63b309e94db13f21b42ea87",
                            "FNab90926e26d9c5070c630af1b4ea44ac",
                            "FN4a54c5a2894ed08700b9aa37db626e2f",
                            "FNc5a458eb2ad30936e9669e9435b33472",
                            "FN51d41d1669d66022c62f28299c578a26",
                            "FN77217bf22560fc4cb300fe7d2015b369",
                            "FN41b2f070a7ca2d034aa064a4bd999d78",
                            "FNd5db843858e33844ef796afe2c07da2d",
                            "FN2b960c707d69f6de46863978a12086bc",
                            "FNad386ea91820bf8ad1612343e478cee6",
                            "FN8ef3a7894b7ca2d7f79e60035a1ab922",
                            "FN7e2ee66c42e32bbe49cbc1ae2134ad21",
                            "FNc6ee1fd4d63d3267559f419884aa3af6",
                            "FN0fbb19759b9d7d77674b21d9702f6859",
                            "FNf229b7784ba7309ba7dda87f3aa86ba4",
                            "FNb2060d021b1aa5c97f59abdc7dfe197f",
                            "FNe806a5efef3f6f700947835c43fc8a20",
                            "FN7ade3b275d6ae1fc93fbeaa1cdf6b22b",
                            "FN243601832b3482f8051a9ab16d0fb70d",
                            "FNb1f5326afa79189266d909b723b7805f",
                            "FN8ed3f2c22e10ce5ef711f2e3cb6255e4",
                            "FNdfbbf3fceacb99c39bbc5e948f7be621",
                            "FNdfc6d986f19f56df11142bdf8c940e0f",
                            "FN25327d6f29c80b304bae3b214136af75",
                            "FN42e2fe8ae0e84c0d2b7f9dc1b223c862",
                            "FN2291ad69904dcd9affe4bf98de2c00ae",
                            "FNcc043159ed7bd858e684df8c58b1d10d",
                            "FNf1294f4fd413f243ad89b9a471cf6fb5",
                            "FN72e980494ebd4ba2e05691a67fa4b3c4",
                            "FNc4473ed42486649a97bb3b9405a53f9f",
                            "FNaa3bd149d9b6e35132be6272a477e532",
                            "FNcab5d70634285d59d7bf3449778306d4",
                            "FN94d9f1042d6a877d540ab51b2dbb1d34",
                            "FN4d931664eda419a5c7103b2301543535",
                            "FN0354e5a8b871ed2d455bbbaca0421675",
                            "FN2cdfffaad406442e6afe3a6210986a8c",
                            "FN2d92c9a82b2a51ff777f875679078bda",
                            "FN29aebc1dbd90d76149e14f573a8b4ccd",
                            "FNd7cb1468d893074868af4f9dd3e1c810",
                            "FNd5e51b78d5aa935867cd4ba991b5803e",
                            "FN5de4b79f9ded1d01c1a2066ce265e59f",
                            "FN676ab64b9d5994632a8632502f90025c",
                            "FNfc466ae12127ace7011b0ca6dada243b",
                            "FN152778dc0d487d7095b7006f0cb452ee",
                            "FN5734cdeea02044deecb05deedd5a3935",
                            "FN50e6bdefaa37666e53c5125e8b1a9f51",
                            "FN63993791e528b856504d1c631ae5cf3d",
                            "FN295583992080c21d94b805133a8ad487",
                            "FN05801766c9cca42bcba2b078f21cd99e",
                            "FN2448ec71ec72e55f64d357b72680a253",
                            "FNdec0045a4d405baf22e20578dac4db04",
                            "FNfa999db17366037ac424c317f67e9a14",
                            "FN37ef0d60a6afe8b31a2ae5659d359678",
                            "FN1cbe925e535fd1b0d99a02be19b5c190",
                            "FN0df92576428e0a7cedd343056b91ed18",
                            "FN051088aadf49e06f0d8158968ff7892a",
                            "FN3e20fc0572e1cc38be2c902f074cc2b8",
                            "FN34280e54b4d13032402bebc32839eecb",
                            "FNc85c2a1f2f27bffaeef1e21321b38391",
                            "FN040c5dd398acf2922e18a92f052954ab",
                            "FNfa0fecf2a9cbd0db71a136c69475189a",
                            "FN7ea6e010b9406bfaa7b692c02d0bf6ac",
                            "FN83916b6e562c6e211223b4a88f1fb893",
                            "FNd6e3003fb5d44390c516d45ee8f235cd",
                            "FN3ede8956f980667503b575f6019953ef",
                            "FNf15ccf0ef0cb5018433c7cf9cdb93f97",
                            "FN0f19d2e03573f6b5a2e43b3a7469d69d",
                            "FN985776577f5e65bd48af9cabad7bf212",
                            "FNbddce0e47e39bd011e1293a666777f81",
                            "FN1949af72cac6508502319a34034252a9",
                            "FNeb19db4ba3f041481116eb5b62d3b402",
                            "FNc095c4d90bfbe00c8cc234379f3eb799",
                            "FN49c7e9ea69354d130c7e96703aea87d5",
                            "FNb0a63fd9020902cce3d036069fee0c86",
                            "FN39d6203aa59d34036eb0e791b98fd52b",
                            "FN7843f47a8661a330967ffeedcc49f9d6",
                            "FN12ce3753f6e2c9f17ef6aa0453b8c6c6",
                            "FN44fcb04a21e6e70e23c3353233dbeab1",
                            "FNfb3d9988c441cee76170b31cc41f61b1",
                            "FNfa69288e898198ea8a1f060b38e53d81",
                            "FN9998e78e3639315999c48b72d86bfd28",
                            "FN4ac09b555595fbfc0c4595152755574b",
                            "FNb60da07809e02dbb3557fb3bc3b3e799",
                            "FN8af139f7b45bb7d301e37319d3b39c38",
                            "FN7d4c400c49475a3cac2704f9a41ea857",
                            "FNf3a197f856d8edabd47fd5efdc9f84e7",
                            "FN01ab1a49394ede498f9d5942def5afc0",
                            "FN43ef1b90f4e1fcaee1b6bb94ad959048",
                            "FNf7d9a692aac0baa42aefb5f7a044d6ce",
                            "FNae5b72108dedea6f69e8e39df578f98e",
                            "FNca2aedce11caa9cd783e84406840f8f8",
                            "FN9fe6628611a7c9a0d729f4500f7ae0ca",
                            "FNc4dc5e1b3b3b6d0dfb9aa5b2f9020684",
                            "FN792e60233cba15da11bbb152ea5e00d7",
                            "FN43b4268c40378a44d315027c5dd29c2b",
                            "FN6e02b6e6a1e83ed4cf17f313213b2f89",
                            "FN346ca450db55b8e5898fba1c81f08210",
                            "FNae5488204652a86077c0dbbe2a86764b",
                            "FNab34eceba82557dc5fc7b9be6dd9d18b",
                            "FNe99f6026f9c810d7a624d75d56660f9a",
                            "FNb3b3810d43506c96ccf80becc9d20260",
                            "FNc834f3dbe209af955bfb59a5d83acf6a",
                            "FN0a07c9eab2c2203dcbc78185baab8410",
                            "FN0e5e04d6ddee077516a946165a868314",
                            "FN2429d2658d4c94ee0c9a6d98c748645f",
                            "FNaffafa632e3631d047fdea0b8d5ab2c0",
                            "FNb929bb90c9ca4cad6a8425911cc1ff85",
                            "FN64beabce590754d89f0b36cb6abc581c",
                            "FN1f9d49280b07f5d9aebbcf02cb33a3bb",
                            "FN389ff3ff39cf81e916866b6fa95678bb",
                            "FN866d8064bdaa2be9b594f55c34652033",
                            "FN30547dd50b12abbadd3c3feac0125f66",
                            "FN966ac07ea074797f6f54b0772cdd228a",
                            "FN2fd1ac64b5ecddd2a2cfd7830bea8618",
                            "FNf6260cd26926b4a89e8a5a82727f1989",
                            "FNdacc5c47cfcdd59e81e26be7e51e404f",
                            "FN6f788995d4e1f870943f41a8215fbeec",
                            "FN2ee69bbdc625a5a21d02050ec983a65a",
                            "FNd2c6374d9e859471204db7fb72df4996",
                            "FN30a0e60b47184b6fd9c2cb90663e9b50",
                            "FN1281dd5d595eb0c05b077fe0f454ece8",
                            "FN7f3da69faf60cf148ae1c57e8ee2e755",
                            "FN2ff433ece26382a7ffe21bf9d73b3aef",
                            "FNf16727c98ec57b2c9bd90670c4e61dac",
                            "FN788316aa85dba430e1f660d273930c86",
                            "FN13d58cdcda123c992d36e2d534442ffe",
                            "FN43fd4b229523d8f73b78b9c981b78d64",
                            "FN4fc6414e91aab5e449252a4f487b4692",
                            "FNe55c674b66b27a69f067f378f899caec",
                            "FN01cbfee301d97c22d428766cc1a4cb06",
                            "FN48c8220b6a442b58015e295fdd46f33c",
                            "FNbedb334ef6b30a12ef02e2be16a0f9b3",
                            "FN25bc46033f118d6fd71ef72931898037",
                            "FNe340f6b5241c66c10ef34d1fabcb0d68",
                            "FNae25402638d84bde44e8a34287fdaf81",
                            "FN1e0050ba16cc230d4c1641f72cb21071",
                            "FN328f20695cde8ff4323093ccc575ed7b",
                            "FNfc4e488d39a208b0495a887e94037df7",
                            "FN22e21ab3b24f3e1cb35bc465e7f02805",
                            "FN221cd7ca9cb12883dcc557d2dafc9c02",
                            "FN18edd9981061495b40c98a972a42c494",
                            "FNc6b893d8e07994fda7abb88857397227",
                            "FNa868c183b65719dd6df7968f49ebb92e",
                            "FNc51a07d2b1437ca096114ff198f19f8f",
                            "FN6f8b7c3e24d4fb2cf3a61b1c0a4ea2d3",
                            "FN16384f3bfcae17a3fe81927581ba4c14",
                            "FNe130329e4938716fb571515152f3d313",
                            "FN12e8180d0ff0f92fa2c7d24a31a289f8",
                            "FN8e3425cdcd7ae784b52b402e4f297f73",
                            "FN6ebcf4b272240d9d152f75b489ceedae",
                            "FN61915c739cf0bd93249f0ce0d0c5177b",
                            "FN466360444df86c6fe0cbf02175121772",
                            "FN7831e14ccc53802d9307e2790c0a8ae1",
                            "FNd3b1680610dc2d1a49a9942c8058953b",
                            "FN5e06c6a5905f19a7f7dd6341e6c0650a",
                            "FN79408d6397a3dfcfebdbab2ec3d23be2",
                            "FN513b2b889f7949802775e898a8280a6d",
                            "FN066d584b84b6d8b16749eb6d8665193f",
                            "FN9f6c471bc4e50db5ce6497391a46a772",
                            "FN4dc8425f1552f5d60c28528c98ba667d",
                            "FN9e4e98f9436c61fcbd3a7eed56ca6925",
                            "FN6498039dc45e1d81f30eae932ff15984",
                            "FN4a49836f086bf4bf3c8c37801553c81a",
                            "FNdc7305d17299471b8b084d205d13b055",
                            "FN7bb78d70088dd37fcdc7a9d1f1dcbd80",
                            "FN10897f00c92329d48954b558a35cc333",
                            "FN78869aefb488780d0f6a6b293d9754cf",
                            "FN9df035721b36367f72ce6e4bece9e541",
                            "FN5ae3e04cac41b0cec467369ca6be5e4f",
                            "FNcd265829fb43ec7bc43c6f0979d9e55c",
                            "FNa9935a202b5266faaadf1f08716f6fea",
                            "FNee7e645de595e75abc89cd0f3e7be96c",
                            "FN9852c092ab83e5b4ad6174800277137a",
                            "FN5636dfa6f55f1b2797a7cc839a4fcf39",
                            "FNfef24973c073ff6f84c71e9b60e3449e",
                            "FNe10b9bc19063cc12d05b177abc245a7c",
                            "FN1a9fae182eb2c75a4f078f45a6a15ea7",
                            "FN4a06017647a4105e2ad39f2e2b46c09d",
                            "FN8e1144a5b6a6d40111015e8b0357e844",
                            "FN94d8c3b7e07cf1cbe88939aaeca14569",
                            "FN9ddef5d489e23a38152989d2a05739cb",
                            "FN07445739870d8d9ff13be6986e468d02",
                            "FN76706e721c7615b7bc1969782e8b2e7f",
                            "FNb1ee0474ce2ad93b8148f6f962574e0e",
                            "FN7ee3b5e9653e6bd73487c56cc7dc0e1d",
                            "FN4115cdf465a484b3c64a78e9ff6ce48d",
                            "FN92908c09895ea66150e1f5a7576cc278",
                            "FNd7d9b2534237ab6e46f6f5f18d427f96",
                            "FNe8bbd79a16b3165820bbe9ff0ee43159",
                            "FN76a7ed37e074c2704ce56c3790bcfc82",
                            "FN51820aed646f20d1fa1eb1c11eb9de7c",
                            "FN6c6df8686fb2eb14d602102771ea56b5",
                            "FNc461a24877f5956a548608d78ac6d27a",
                            "FN2cf69aed7049f2821fa334d7c2b86c96",
                            "FNdf89fcabf53f6fa30ca1fa60df1037df",
                            "FNff4c105ed6a018a3d2199c9003420388",
                            "FN19c529ca17d3742df9ca36268b8d3978",
                            "FN3ac8f2488a4faceca9910a432b1f5e6a",
                            "FNc98b676e089e001a962474a8c14a2859",
                            "FN5bad684fc6ace5256dd11ad4a79e7742",
                            "FNfcfd763936865d65304eacfe89b99fa7",
                            "FN5f6f9ace5be9abea396b6f2edf641172",
                            "FNe456197086494fd5ed7e5b882a578c75",
                            "FN519fe97c69556af12279e4e25692f0ae",
                            "FNf1955fe414be914b1e31d655b4d85cd3",
                            "FN63adf0ba6c7de2799935f5e21532b75e",
                            "FNc0d9dd282edc2068d0f2d62099338e6c",
                            "FNe5f59d3648e6e892249645df826b7902",
                            "FN6b43dce5d1186bcc0b74f8978f51173d",
                            "FN718590f0e9d544b6e3697acd87aac9cb",
                            "FN182b382651973529e1a847b718123834",
                            "FNe36e84346933b187398ed5114ad17896",
                            "FNef4d31df889745f85bd580a7a515c543",
                            "FN794c16ec292841468058e27abedde259",
                            "FN225a6cc2da2922b6aeb5bcedd1f0b879",
                            "FN3b28c43bfe8c3c6d51adad53d905b2da",
                            "FN9eba21fa9e36ff7763710ee4a1a87e94",
                            "FNb0770f8338434f1fbcd22897677650f2",
                            "FN0884e570041b4be1a53d32a31cca9079",
                            "FNe63be8418cf3421498259d6c72749342",
                            "FN96369745a737bcc38161097445d88d46",
                            "FN5c69fa47a5d2f8ba1ac150729a404b91",
                            "FNe2d9751f6fee3d49a177753e4577f228",
                            "FNc408f2c8f35026f145323a78a447027a",
                            "FNc3c280a4df2a0e94f175932d4694bf30",
                            "FNe9951e7f14b1eac2550155717333ebc5",
                            "FN9b9dc6913bbb5ec0791831fe0d7215a9",
                            "FNbd78dddfd1715b03ed72aa527bf2bba9",
                            "FN5e0f18c7e6bbe1df340cd9f219cbc56f",
                            "FN19d0a8031824aa28d39997ca982a22af",
                            "FNbc3e3945e3e6307d8e9c05018364d0c1",
                            "FNeacd876ca933b98c48771021297389ed",
                            "FNecbce5fa60ffe1b8d1c7389ff99b2ace",
                            "FN9e377a68691373d07f36640d144d6c77",
                            "FNd1ffc2eb1a572af9c566e04554a3641e",
                            "FNd166c3bbb6da3aea268c55a067a11771",
                            "FN3af8baa1322be8b86fec2449636cfb00",
                            "FN6215548f98037d3e05ccc719e5b81c7d",
                            "FN5a6375e1f70ab331befcde94a977af13",
                            "FNc400d6b846aec07d0896809dc2bb5349",
                            "FN3d6b8a1fedffe84f89fc91e8a1d39790",
                            "FN71e4c66254e0e421b5698c87073c99ab",
                            "FN759b23357305c9c7556f0e81f9c3ae16",
                            "FN49a4586d8dbfc8e746b2af61ad832f86",
                            "FN6411c9d0c1d7b8979067f86add26ec61",
                            "FN39c8b2e25e42d485af433b786dd509ed",
                            "FN2a6c7719dca40c0c9c0374aa8d98ce81",
                            "FN8666e8b4a7ff303570defcc5ecd1540c",
                            "FNe4d3e790fdcdb9bf478faec2ed8499f0",
                            "FN7e772775e2d68d6b9708a3649619e6c3",
                            "FNf92be3277629b904047538a6f73c2c4c",
                            "FNa929c839d0aa85b5f13a19737c4e7bed",
                            "FN57a9ec418dfce144a91365915ad12e84",
                            "FN8add90c85cff4cea057a207b9d5978f6",
                            "FNd3d48826314a19ad43cd1fc00abc1638",
                            "FN99899fb082c5e3c8317bbddb4f9a122c",
                            "FN2d8b0c60cc2551bf3e9f184fefa80993",
                            "FN0080c55ae823f730b44771333fc8a842",
                            "FNfc8f7e6127c0b1be08afd9cfdd68135a",
                            "FNac1f4d7b82d58ba91b0b2003051ff994",
                            "FN895420ee467e34078e790e3b6e8933eb",
                            "FNd7a00bfc9cb784a676e1a38dcf38a4f1",
                            "FN358cb753702b26c496328b911167e813",
                            "FNda85426fba8e2f9dd1bedeb45eac98e5",
                            "FNa814c291a9db91863d755849562d78d7",
                            "FN813a28af1ba61eb49efa96e2a12e3608",
                            "FN5f819d2a6bcd4bc38f761b20ebeffdb9",
                            "FNa2644a3a17e91f298ee048b0553d0842",
                            "FN965080aa7b3d3c7f9787769f54aa359d",
                            "FN54e76ce5dde31028b53b17682a093d55",
                            "FNeb8e2b482b61884f5839d8f52fbcc090",
                            "FN6848e8a92ed95aea1c0de484c411d680",
                            "FNeb32db1f688cbe444bf00a45cbe25ce2",
                            "FNff524741dd1a688945c3dd08aff4d903",
                            "FN7502e2f7c66d397bf23f572f5b160814",
                            "FN008e374cec009ae1f9a82af8c936d79c",
                            "FNe2f1dfdda4fb6f964ce6cd1098e72bf8",
                            "FNf694224d2424cf70a789eac56e2e8a1f",
                            "FN7d9633fc9069e4329b6ea5a34d077502",
                            "FNc6463ede195f8119fd75060c1497468a",
                            "FNe481f77c658eef36ebb37f416a2123cf",
                            "FN805c9654a67bf4d7bbd46681fdd63043",
                            "FN200147a21d12757e70ea9ddebfc3b633",
                            "FN2814831d5fd40c667d94e24ebc940f40",
                            "FNa33d7145716df7abcf8aa5391d65747c",
                            "FN6b6b9180931b99834aba87d72db16ef3",
                            "FN3b7b27438f01cd1f295f1afd5eb475ee",
                            "FN84eb04aa9557ca70dad69093e22c48ed",
                            "FN0f954ed79b4320fb90a44d3d0ed96e61",
                            "FN84366b048bd39c3174dd5f85339da038",
                            "FNc1919b070b29621e8f0ff00dae0f0367",
                            "FN8d6a3e669d6aeb29b218d427c9909fe5",
                            "FN3113f71358d45b44a2fa50814ad6b5df",
                            "FN35fd6d45632bc6ec96af21cf8b4dab75",
                            "FNdcc16961ef2fd07ec5730658a1a20acc",
                            "FN4fedf8931d91f16f702b286e7da50925",
                            "FN221050a20b5f39e122acfcf8643e9f45",
                            "FN8099ba1224af0091cd922c0d6e6a7cd3",
                            "FN005f3627102b06e0162074343b4fdcb6",
                            "FN513fc93fc711e6fdca733ae42055ccf3",
                            "FN47eee113f06d16d61ad3cd26a70c2fb8",
                            "FN61524bdf592368ac9a5c3a2c7bfd3400",
                            "FN36cd589467a0329a3392ec32bc7c64a5",
                            "FNe7099ff5dd4f535a3ba6b44d7e7a8402",
                            "FN852c5fd3326e2f8ac697213202ebf18e",
                            "FNac4b0c5675784a469ab2787478f3145b",
                            "FN7af302f0cba9a33f235438b6d20af16c",
                            "FN13406e6a122d6717501f11ce61d4e0e7",
                            "FN328630c5df1b37ef86054bf0e0fd552b",
                            "FNe895618f6d9307e74c9a70c883adb4e8",
                            "FN588a5811e5226a0a310a388d4ef5380d",
                            "FN445ad0b9ba34153ee6743410ec9339a6",
                            "FNad2edca65d76cc740cd48d29f1bc6178",
                            "FNadfae87a95dd66c29002c977a4fb5c5a",
                            "FN005c41f2d4b5135867b3deb6c5bdf0cb",
                            "FNd5553348a516b2d6046874848c2a991d",
                            "FN1ab92e90d216a488bb8008005e33d0c9",
                            "FNb4383875765e255baa675c3dc35a674c",
                            "FNe7bfad2865c1afffc94101e05c409705",
                            "FN15571a3c5b2d605ed8d47883fbc39b17",
                            "FN3a71f0f8f71fa9e4751badba124d8574",
                            "FN287184c230e07621de0b7a2232da0088",
                            "FNfc2a117f8b1571868df45d977d2b3dc3",
                            "FNd13fd1e0b8d32c00812ed285e138ef93",
                            "FNb15a5f493f1092868678a25b9fbf0efc",
                            "FN2069db3f1935f3b88f90700324ebb636",
                            "FN47d596d98345697eca2fce7418e40998",
                            "FNa805b6aa4bfaa9ef163d9ae296408645",
                            "FNb7620e1bc224184f7e2db75dcc4f1b80",
                            "FN1dcc5871eef65592f26e675db373734b",
                            "FN59752237a38daf735daf31de5f131d5e",
                            "FNbbaa71a2cd79f0e8e3c9149d604175ca",
                            "FN6a4aac48019dd9ad6f3f998ac4e59c50",
                            "FN4ca203f2c41660357a56c83c0a0c239b",
                            "FN6d5ed9b382de67356a39b0483c613a1b",
                            "FNb448541d98e896d8485e62744fc8f2b2",
                            "FN648494bafed07f6873e88fd3dd86a961",
                            "FN2d19b9bf37e0621a1f9865c7b33f23f8",
                            "FN4d18bbe5a5581911174658e2ec1f9dc9",
                            "FN79ee8d03a0350444f141944f20a94fb5",
                            "FNc2230e16f983cdbcfb4278a0d8f2cb73",
                            "FN3462b7aa4a8d470271c87df102413a66",
                            "FNade2e583be2a776a5b2a8ad9d8852478",
                            "FN506bf4548ef77e6b111a87a5028b88c5",
                            "FN42ced732790a7146b8a693b9b5dcd75c",
                            "FNe74ff3bf451da63810d83d70f04bda01",
                            "FN61aa7d83647db2d344458334a535d688",
                            "FN9fb467be529f6d3c035f6ccf501704c0",
                            "FN211fe12adbb5375d668d89e20b2f0cb4",
                            "FN55a3d703259ad4fcc791097d388a63be",
                            "FN014af681202613c28acc2d9f50622fb7",
                            "FN4070c0e1684665c27c75aa44522769da",
                            "FN724a663f93d8df332b1db35a321d8daf",
                            "FN1b05df19b41b1de4d804c03f88e503dc",
                            "FNe104bec64fa251558e0f2107cbad1aa3",
                            "FNdc6b328596b84b78d71f5630e5a17469",
                            "FNebd76be50e8dbafeb0e5807cab535263",
                            "FNa66007c2f7180bff7911671be0316144",
                            "FN608e0b121a89ebefff2ad1a31beb66cd",
                            "FN9b15b47006ebbd6bae49097f9ecde463",
                            "FNa351325ea1839a9fe53f705ae922599a",
                            "FN157d31008559abcca983364c679c576e",
                            "FNc1b24d3f29803bb15844f9560169bb02",
                            "FNc6ba60f2b2c2eb46df8858dc6d67ac0a",
                            "FNc2b654fa01a1833e92d1b0b392a979ad",
                            "FN6a6b18ea2311e2f8208fe3c7d6180324",
                            "FN36541b6f61209801b6d7ab5c8b7ff8e9",
                            "FN401fb17c6f706610776b6df69dc283e3",
                            "FN03ce677fd1a7f1b608a53afb3362d864",
                            "FNd50815a6195c9ef30ec19878f27b3f1c",
                            "FN1f24cf2c08ee45f049e46cd81f13ad55",
                            "FN87fe02fede0b9744d05864dec5d1b23a",
                            "FNf7ebc5b7616954db638a3b1c968c9e8d",
                            "FN0d386de688ef1bc1da511f1504468e2f",
                            "FN92ce398f03f21f32a47848928da31abd",
                            "FN4abf8a825a9ab466ab9af9973ddf39cb",
                            "FN81ac60c665dbdacf0b661cf8f7a3646a",
                            "FN48f8bc89c7408ae203b12621efbd7987",
                            "FNb9fd9ca499b628d076050998463d38a0",
                            "FNf5af7640ec8f77d46d01f1bf46cdd32f",
                            "FNbdf5c81e85d135d309551984828a20b4",
                            "FNe18b60f320f518d625e9990fa70ea9d1",
                            "FN6edea80a8b4b79197e756c0841fefada",
                            "FNa76dafe911d7c41309e7e91bf3ea743c",
                            "FNe2c97cc8196bbba3f0f06b76f8e79bfe",
                            "FN0b3cc575b71d5d97fc800ad55dc592ce",
                            "FNb5526b3782f14003468b3935f3bebad2",
                            "FN1ea10fdfe55e9ca0ed049691cbc73156",
                            "FN7a4130da185778a4eb42952441489186",
                            "FNbaaa860857a281c1eb758b70106caf0d",
                            "FNc54b963aa6e7725384a82e0c40777ee4",
                            "FN7267682e1c090a99dcf9136bc6ade465",
                            "FN6c281d9bcfafd20ffd11d3aa845eb185",
                            "FN26786d4110bfabf8bc92b9d62a64ba34",
                            "FN5b8c7da416b85d7bf3d868035e2d186e",
                            "FNda27dae6916f668d5e3facbb18a79b62",
                            "FN92d67ce7d809da2c8a3ca4f1708c086a",
                            "FN2dd8a509a58f349c6e9717d057ad4be0",
                            "FN814f26d36c744d225a9b7622d48b4ca8",
                            "FN1d9a3bf8bd984ab5efe40cfa9ab93bfe",
                            "FN6aedebf5ee10aa97c1b59ec821253644",
                            "FN9896e6477184ed34af8bfcfcf7a3b9d7",
                            "FNf92779d2e7ff8890968be58032c4209e",
                            "FNf03e927d70046f638ab1d5c722ceaf31",
                            "FNf3da0fe943e8b00ee97a8b58d8bd34d6",
                            "FN901c102754b8538318f0c22e19a56c34",
                            "FNe7813e8b60cab132efe3b15a1dd75735",
                            "FNd63a3ff027c009ed1bf68dfc8c8359cf",
                            "FNcb73bc4035b3b6d83c47011d67902353",
                            "FN38e83a4ebbd6dab097d1dd494488c4f1",
                            "FN75fd8b9cf4613e22a7dac9c1cfc0e06c",
                            "FNa16aadaade0ab6f2bb3b7ad2214ea7ce",
                            "FN267118d8288480551abd970b635744d5",
                            "FN27e7118643276e266769ca16c65dc7d6",
                            "FN82bb19062a1a3628f1338960efb91613",
                            "FNf91ef28da7bb34775f4315d48ee29281",
                            "FN3d620f7de67867639dcf0c69eee80308",
                            "FN5a96a7ffd53ab2ff91db6e1329528a82",
                            "FN33dbe10329310a71393454f4d74213f4",
                            "FN2fd6069c3c566ece69558438816bc283",
                            "FN7f5635bf5e8fc5f0af37dc9c00c446d0",
                            "FN825cbd7dc76e7fdf83af69bc6a3379be",
                            "FNdf8e73ea2192f4cb8319dbc20779389e",
                            "FN6e32422455d4a9f3d2b30fe48aa01bfa",
                            "FN9657cc91d57146887b635d09190c69ea",
                            "FN96de47bbec6307790467a568bc620056",
                            "FNdcef04563efab2f4fe192cbe4b7fe12b",
                            "FN5f234d588440eef41e1a54de90d933a6",
                            "FNcb464e0aa7fff4e7a3304f0266d39610",
                            "FNe0e19eb879149880bce0a5ee100b670c",
                            "FN9efbc21fde4f336d719c54eb1333dc5d",
                            "FN9fabc01b8835615af33d62f5288e81ed",
                            "FNc0d29864e4b4dea430b8da5c65110d0e",
                            "FN115a7a130a7a5a5e68dbb61a558b95b7",
                            "FN424b9c3213b4366ffcea8984f7952e1c",
                            "FN9401a839969f2502751432d7072de087",
                            "FN787499b0bf63442f02822b9b37bfacd3",
                            "FN5a6a9b0fceb96c496cedecf8b3750e10",
                            "FNe4ad67740c455ca5ebaffcab97f0e267",
                            "FN8d3673742cb5dab6e9f63aeaefd997d7",
                            "FN86eddb281c191c96b4c0f89bc4a8fc6c",
                            "FN6240c6484d8f8bb0424caece6fbf1a2d",
                            "FNb9af6ac1ead26734618cf51c52d311eb",
                            "FNf44ce1be763a0775df6e7afbc76bae0b",
                            "FN19b34f9bf74a8de56b5718cfb02a8ecf",
                            "FN167871aa56e27ec4e10b52e632a66c98",
                            "FN8c5de1696b37c1829715bd01de738e00",
                            "FNf7fa13de5b1a208eee85bf0d11e5393b",
                            "FN2e521ee4b8299eb589f2e40a3f7576f0",
                            "FNe5f8dc931810543f8f72d6d44e80c2a4",
                            "FNbf22c1d9cbc2ff1edc005c90bfbac089",
                            "FNe76e8525b66bc1ea7a91bf01dd3bddbe",
                            "FN598dc3601458905a1765a285667fbc7f",
                            "FNb254b30e5bc9133518a58e434957c905",
                            "FNed261955517043b4ac6e40aa7addc308",
                            "FN17f13f5847bb565e05830f3b1048f111",
                            "FN7212baa6e3f39b104246ff207302d069",
                            "FN565bdd162e07654e29bdbf3f66542a07",
                            "FNbd37736b1ef6b476cc0feeacbe2645df",
                            "FN88b1c0536d78421391439d735dd342d2",
                            "FNaeb9870fa7738c68f38dd69daf69b16a",
                            "FN8fd81a682f331f81c70469466097bda8",
                            "FN330ed21a5e99a9d16ff113dd70c05911",
                            "FN2acba877b1826ce09f05a52e7d17fa07",
                            "FN60f8cf4e04a49a7264958b9421008195",
                            "FNdb8e2531f0de8f4bf0953b421c05255f",
                            "FN14d10b0ce1ca304c9daa8fde6f3f36e1",
                            "FN1eda36eb8d244e6bc17bd99e3323602e",
                            "FNf8b2a71d027a2265c6aeb1be02646dbc",
                            "FN35ad1c27f108fb6bca562fcc6d107471",
                            "FN387b70ac22eb39f27fe5637b3a7dc698",
                            "FNa601c6852cf35231d2a2bd34016da1a7",
                            "FN90e0aba75c2ae6bb08e1c9eba28bab6e",
                            "FNb48d857303c3c69a0e3f2fc6fb705856",
                            "FNd3f49d02ec646805ad8c68030c125fe5",
                            "FNd7ce3feb328d417ada4a5fd2362b4ca2",
                            "FN6a302ce04816a27a2afc6d9953513f75",
                            "FN6ce304e62ef3de39d8497262796075b6",
                            "FNf45960420e2b098c5a501c9a5fc2646b",
                            "FNa7d2e574c213db86da84f73bed235db7",
                            "FN9dd67b24f4150c7ebdee385b45c22c0c",
                            "FNe4885cc9216821f804b918a4020af7f2",
                            "FN663777752f994b7b924816f7e6da3153",
                            "FNdd098ee34b4adda0d222bc711ef384b1",
                            "FN37f2f54f06c8665b01f87100b44fc4f5",
                            "FN236322eea544e3212963e8eec286edb9",
                            "FNd53a5de5265add0cb2d2d848c658c151",
                            "FN667c6e5fcae9a06e83997358650ccf21",
                            "FNeaa7b5988b7ce1b84b394604b6f0f16c",
                            "FN062365c6e487a3130708c32b748b948a",
                            "FNd141495bf53f838851a41be22fe069be",
                            "FN740b41e8ced3650b4571134ab9a8411a",
                            "FNd78a4a9ee98fa9b0d74965c5fdc2a271",
                            "FNc25d9e120b5ad6a63d0e1ec85eefb156",
                            "FN0cf14be45fe06270fcc91c081564df0e",
                            "FNded8b75d41a2b376d310379960c24b7a",
                            "FNc2a1864835b01c3d851d785200694580",
                            "FNd9afe2fd4201541eec1ebf4b181d90d5",
                            "FN85c8f990d140bbaf113861a494c7a627",
                            "FNb036ca635aa9f8a41116371e72e2ea0e",
                            "FNe83cc2a22869d1bc73ecbd00361135f3",
                            "FNffd8008fbc046d7491ac60de5a1e5249",
                            "FN7315c76b64c5ac005f372adc4459e1fc",
                            "FNdee36f19f835a08c4110fef753efc5c1",
                            "FN2d817470754caa953b1813dea5fa20cf",
                            "FN08f05938c587a1cf6c144900c682e7cf",
                            "FN96c6e3a83993f2b7b4e003ccdb5b5b40",
                            "FNe2c711ff29b668d8301933f70336bc3e",
                            "FN546315ebf9fa52deb74097e5b26c092d",
                            "FN3c702e0494b576b58b69f7fc110d5bbf",
                            "FN0bc00abf19c432661344da7d941ea81f",
                            "FN79a39b997265e873841ab28f2f089ad3",
                            "FN4a77a970f9789a8ca1e82665829245be",
                            "FNa1112fff57f3475265d551476f1c6869",
                            "FN9972123d8a4c1cbf6b27bc482e2b9a47",
                            "FN84591b89191a1ce2d073f8dc6ddcc7c1",
                            "FN9ef6e121911f8674fdde1d8148e0e38b",
                            "FN89e6c0245b9396c1b04fd0e6a7d4f94a",
                            "FN28e5a255426592f770c382de8cfceb20",
                            "FN6bdfbdf199b42243f461616092fc0860",
                            "FN58139b7b5cd6131f4e372b831e8bf301",
                            "FNa1705ff8f074f130afc68edebae62b14",
                            "FN848469978620e9e45c14b1b881172528",
                            "FNb2f0e71b432bb5a26436fd32de8cae6c",
                            "FNa738908b115ff28c8b36dcfee351fda9",
                            "FNbd31f68555d0ebcaf47ed1395db60922",
                            "FN34793a7ba18540ff8375b2506fb9820b",
                            "FNe5ee27d19a8d07843cb489e3b2faea71",
                            "FN4ef59356d86e56c2aeb59c2bac628da8",
                            "FN714c8375db3a2580876daadf65374705",
                            "FNc8ad88440734097e7a448a2ebce55a3c",
                            "FN5939ec232d165931f0497e3255a2ad64",
                            "FN6b2a9fee146e66562625dc8b140f1f37",
                            "FN4c0b1478fa1ae7b88b9f5a348fc3dc1b",
                            "FNb91cfc5689c78abc013f1709705b8752",
                            "FNa89e3092132e6e4de11a60ae72b1dc01",
                            "FN6b369b64a3c43bd268fd9254e09c0f46",
                            "FN8358a8e149a1d5a00ec12025c7cc8c5d",
                            "FN72d45648ce98af876e76ea92cb56552c",
                            "FN4352f148bd5e31badd16ebec9da79cd4",
                            "FN79c69db388dcc4fa66c33268bcfbd88c",
                            "FN4f6d30fc7921f4d9f1e6025cb93a40ab",
                            "FN9d751ed5d08e5621018df143dbfa90db",
                            "FN0c0b5096062d2a5c914e7a2c617460cc",
                            "FN9ae2b3490f392524f70e518ed3f5d02c",
                            "FN1d0270b0fcc04f55337c1f2bd87a26b8",
                            "FN744ecd6b5e63c5df565cdc47dbf87078",
                            "FNf9a8368c7ee6287959a1d694d996af11",
                            "FN82aadb2356f840ba089057dcc409e44f",
                            "FN38c5a3154aa3fc9c17573bfca2259d29",
                            "FNbfef9f0192bf879e582e704e24682888",
                            "FNae6c7446a9563bfb08d341a0b03c3913",
                            "FNfc35e7041cdbeb7068e98d0c6eac2f3a",
                            "FNe5daa875fb4e6bef6c13f834c5c195fe",
                            "FN29e7657766f0caa2a523b42b82f8b07c",
                            "FNad936ee7725848e3b791fc4c6d40273b",
                            "FNb1a1c8a3d8b65e6368b5029e6e5de021",
                            "FNf6e8e864930e8e2cc347afe93db6f9ac",
                            "FN6d67c51c44d0759236fddacb202aaa80",
                            "FNdde982a3980aae39e3b13391ac267f4b",
                            "FN981b34521ad148c837c5e80acb56d55f",
                            "FN08cd56c0ee27cb1d5d8847a9807385f6",
                            "FN1f445700df392bb202810f56596544c4",
                            "FNdeb021367d667925b4673d3b8d77c9a8",
                            "FN16b1111ddff9fac5d566008ca35593b8",
                            "FN42062473290cf82757d4512bcb60aba0",
                            "FNae0e714631126003aada2a9a4be1451c",
                            "FN4b19d50b1c37f600c0c5f4ac8c42e0b5",
                            "FNe3b746842c45594993168a1310fea7c0",
                            "FN73adda0d228c8a075e0b514dfdae4b9f",
                            "FNab8f2f455f79fe428b77122994bb71ec",
                            "FNac80b028bc73afb3679ec356b4bc9ba5",
                            "FN626e9c94758cd648d6ca4794d34b64c4",
                            "FN99d9fb37de101c5280e6e6a2125b60ba",
                            "FN77abd62f1f806dfdcda936d26600c9c8",
                            "FN03969a00917d00016cf92595018a8327",
                            "FN94f494e939621cdcf322b8504c0d6a16",
                            "FN0d56991542aa00dabd100346cf0782b9",
                            "FN2367fc48cfba286df52cd5452d1546ac",
                            "FNec7df64e884f99a204aea2a8dbbbd92b",
                            "FN6af266fcd4614df7775feffcf2bbda8e",
                            "FNc79fd7cd503ad403a0177e945c744cc1",
                            "FNfb51098ef7d66973a4613e54e59eec1b",
                            "FNa097b8a0b69eb9307d45f05d2b8666e8",
                            "FNdb849a6469c770767bc3715d076c6ad3",
                            "FNe8e4be5ee4e9edd0b12f707cf979d1cb",
                            "FN9973011b25691545c75cd9810e1bc5ea",
                            "FN28edcc8599ef1e0e7b1057e6aab9e5b1",
                            "FN3c93f97e3422d99b76263b966a974aa7",
                            "FNc6c60e6f9d65902ec799ee535a135ca3",
                            "FNbb2eb7099c6ee0d62edc2b01f5b0b233",
                            "FN8c51fb80805aeccd83b0ec19959a59fc",
                            "FNb0b67e650f002cd4e9e5ddc4dd5a5b34",
                            "FN9ff52ef5470c30284de64725447a3bbe",
                            "FN3b031501512fedf50b76fdf30443369f",
                            "FN870173a838ae457f27c18ea3b9423039",
                            "FN543573345b83ceb9e8177af4d8f9c3b5",
                            "FNd096270b2ac92cfe781977c524a526bf",
                            "FNc763caf7d6a38a28ec002f4f99ddbf92",
                            "FN556e89035b7333abfbb4331f7d9a1fbd",
                            "FNecd7cf9c83f8f7d44767ffdbe8cb95ad",
                            "FN2aa82bf5916d0a2623a079be7d41fa9c",
                            "FNdd8484c90f3ee1e3352a365f960cb88f",
                            "FNff711bc8e1adf9370e0a7cc7d4ab657a",
                            "FNda052bc1246f418ab3affa7c844ad6b1",
                            "FN159ce95e2737d3d669b4f428e9e601ac",
                            "FNe7f1ac84c55aab367c0adacfdab77138",
                            "FN86064e39de8e4631a9fbac73d89ad49b",
                            "FN79a8b6831f7c6c0bb9d7de50e7768eff",
                            "FN42d2b4cf5202baad1bebc028b1ba2101",
                            "FNe1ab3f0aff089d3ad6b9de82b7bc3f0b",
                            "FN13cb929630c155631f17ec93d477fbb1",
                            "FN753a0fe1f40ad1adf06c9950dcb3bc4b",
                            "FNe49d24b8a4d45aca5969b8d50fbd51b8",
                            "FNa93ff8d90ce9e9dca1b444c68ce1eab3",
                            "FNc90e2f13dd66524c3d8c997312917b8c",
                            "FNf642d8b5c8a681db2b46439876e5e39b",
                            "FNc8762239024bf7e742938894f2ee4f68",
                            "FN5510a45d93c7101ca613f73a2eb26800",
                            "FN3384a723bcfe9f8be640d90e4b765572",
                            "FNa082d0159e8599e4de85b72cf0315f3b",
                            "FNfc3c712591fd485bd12398fb89d41911",
                            "FN9dc307a1828db540f487b44f226beaef",
                            "FN9c8b7263606181b240e19b499ddc206b",
                            "FN512cd483c1c5584378e5305f1883600a",
                            "FN65908c423cec023a1f99bdde119e3463",
                            "FNd6f3572c64bfce8f7fbd2fbc0cf3de84",
                            "FNe87c488a6755d62fcf0b232f85bef36a",
                            "FN3805b1d2974a2d71f2835c1a2303ebf0",
                            "FN078791c6d157b75402a369bbff07c614",
                            "FN38fcfa9bdccce89217f2ac4f22e47e62",
                            "FNd24faa074d646f599bd9678c16b9ec83",
                            "FN96a72bbbd89f5ee3730553ff9ef1fe1a",
                            "FNdd3f8b60eb6c0b8f506784e462d7699f",
                            "FN532f700957dcd4022cb9a1bbe9676307",
                            "FN3aa2e56aebbcd19e19fc859d08917043",
                            "FN4a5c84e7a2bc270336623bf479afb180",
                            "FN3a83b59f7bfd65b30c6de9f7aa81b101",
                            "FNb92d8e3cdcfb6c91eea0b43cf0aa3b82",
                            "FN5dcdd76bd8f523ed863b541cfbc1d2d8",
                            "FN13f4be030348b0cc759424029d32ae8c",
                            "FN33c2da9cba1b3112a0e679855fbb7e9c",
                            "FNfda337bdf016d1e0cf1e9a5069175f98",
                            "FNbc81cad23b506634243a71b377dd4c12",
                            "FN8424ad93cb9c7f97bf379deed3daf776",
                            "FNacdc7ff9225e840388fbb2c8d98bff2c",
                            "FNc85f55799b521ebeb70221d734d8d856",
                            "FN153da504747487b5bb2ea4c7aca67fc5",
                            "FNa092c50b579c0690bb6ca21b437a2433",
                            "FNf933e07c5fb5c1fbc77bb7c9b9a50cde",
                            "FNbb30a191aed3425a14d76516cbaff782",
                            "FN2e763a6dc176c30e136c460ed9edc3a4",
                            "FNb0b4274c4cccd7e8c3b368797b1ea471",
                            "FNb04ab7fa30068c6a5297069e2de69ae3",
                            "FNc76fbe687973535d57b91f0ceb61c4f9",
                            "FN2eb5c2d24b1eb832a8b32c20422e1294",
                            "FNcfa5335aac6eb254758402b4ea50dd31",
                            "FN264c82cdff8d6aec9e9a2283f581006b",
                            "FN1b188c19294013af8c1a813841ee3f2a",
                            "FNda3aa1781ef837ea6b1ba4780eb89ef2",
                            "FNc0b47070c49a5ab008917e338d730842",
                            "FN33f4ef6046d19746101cf8452d7d05f5",
                            "FN9610709403ddc994f1e8aa2faf33095d",
                            "FN187adb9e0931bbd94bf2b772ac53ca53",
                            "FN6b5cb0ada7d07d1b903899ead74c32c7",
                            "FN979b247190a66a917f69dc8ec184263f",
                            "FN3912482d094635065c67809c9f422dbf",
                            "FNd1c231c667367ee7795d07a351cc24fd",
                            "FN5b50e11eadf26b9f350037266952f525",
                            "FN5f6cb071bfb35fcbec357d47546a98b5",
                            "FN23bec90bf94fbd21ebb973b9c0b55e73",
                            "FN61eb84ab1b822b0bf63d691dc6e3997d",
                            "FN1b7645b242bd6d7d80bd25c0b7ec40ef",
                            "FNc5ef5456c0da7bf60a23cfa80c4a64ff",
                            "FN63364bdea900e6883c901f51b62210cb",
                            "FNaa64243a92316e2ed1b1f3855acafc27",
                            "FN2b560eb3ca9f9389f10ff9db3aae188b",
                            "FN2255c20b3e5359fa49c03ee66b6a4624",
                            "FN7f948a455604a5f5e5ca6463b67a1978",
                            "FN43e350dc3f4dd191f486cbc133a052ed",
                            "FNa9cb7d9ac35d4ff0e44f44906f2c19a6",
                            "FN9cc6926ec7c7f8902b249e47c2eeebc7",
                            "FN24c1202cd26094bae49d66e69477e145",
                            "FN38d70d77f8bc065f5fb4fc8073b6ac3b",
                            "FNd0a05a138e991608a0b50cf9a00f472a",
                            "FN154bb6921149142ecbf162c0a8156220",
                            "FN4bf438fd72399c116bd7ce4b4e4002f7",
                            "FNfc38845f967bd084cca0e2a45eb32cb9",
                            "FN4cce338c0a3c093d0c5d379f5397f5b5",
                            "FN470e72801b5652463c446ceb976b1b3e",
                            "FN8db300bc03447ecda750c7ca04b5eafa",
                            "FNed647f5e6b18a6cdd3a64fa580d77e89",
                            "FNd8cd0217f6ce0054e835b284e83969ed",
                            "FN05f73d00cd00ddcdf1219f0a3de0352f",
                            "FN25d2195682dd2c2a65f81b531ecf1761",
                            "FNe628ed60e78fb564134f849a2aaa2fbe",
                            "FNaeff3720d3569655a9e5668622bfda68",
                            "FNe5a0e39b97a4cdb1e95f5f71195b2eeb",
                            "FN08f13943fa9942a5c1e07177734ac485",
                            "FN56027f4b8a74d52abb7dde600d0eb241",
                            "FN7feccede399b1c1260a682e4da99ab67",
                            "FN0c5c502db1d8858aff21ca27f68d834e",
                            "FN6f84d37a0050a4b340818ade344a7f10",
                            "FN712d61efafe2af0c3b27184ca0389e23",
                            "FNfc0a4680b94469187c5ccb98d826f56f",
                            "FN65209bad1be50337417f3124cdaf817b",
                            "FN23f3465f3bb304670a591345bd374f1c",
                            "FN2c37b3703f0e7748e4eaa6b9cedec935",
                            "FN692a855ce9874b42e59260278843f5da",
                            "FNebe857804085df18cb25f7a506855b95",
                            "FNca71548536f877aaa3825bcadbe004ce",
                            "FNdde6ff97cce97951be6e800fe69598ac",
                            "FN8c7c024ab0b7a51871de6c51551c7c30",
                            "FN1d898b5cd712b5d46e865f5862677fbe",
                            "FN3d157bb86d97e815da33c4bd31ff1e40",
                            "FN30dcbfd809e5e56d98d57d89b189cbf3",
                            "FNef86f74fac8126d639e1a2822f400ef9",
                            "FNb70c7b70e0280441b4e306b806db6b8b",
                            "FNbe99f8c33852b2d60ca7773cedb5f0de",
                            "FNe9d49b2547b0e10c62063ef42b7bc3da",
                            "FN54d95581e37fbfca91cb1a6c51017572",
                            "FN9967ebd1000f80dd9624a745eae6b4a8",
                            "FNa70e29acf0ee45d511ca37c7c622cb00",
                            "FNaac81469d0fd4f0749fe923011970525",
                            "FN8dd75e32b7682a99e133edcb787a7320",
                            "FN9b020ae43fe9619d90ff0cad9a92bcd8",
                            "FN77308b571875ec470930ee952a18aafe",
                            "FN1f29ea41fc08c114528935c497da9aaf",
                            "FN13930553c83811e7b618f73ab1b14791",
                            "FNa91cf33d637163d4f16e1f24198ecb57",
                            "FN3573df25c662c9e5b2af39406ae7ffc4",
                            "FN8b52231d0af2a118901aec5e300c671d",
                            "FN227af59efb6596538e74f3ad1440575b",
                            "FN12839e8637861bdce1bbff66e42f7874",
                            "FNb0e85864f1af5a171be50c8c792ccb49",
                            "FN5e48462308e144de77667a969561327c",
                            "FN29a94937bdbdab0542eff3c8aab42704",
                            "FN98fbd17e05edf2c7ce4649de161b4b58",
                            "FNc94f739b4c7d7b867c86be33d99ca4a1",
                            "FN7053247267a65ab7176c61f1eefc01d0",
                            "FN1eaf09f2f1beb95c4bc03025c6f9bf4f",
                            "FNf62fe4525f2e709ec50625e446d9b7d8",
                            "FNd416d581c9463cb861f26db905a04ee9",
                            "FNbae3a69e3edc01b69a8b4676d69da727",
                            "FNff20d3793f126ac049177c516a8b8650",
                            "FN147980c3f1c23e2e7052d83924d29eaa",
                            "FN656019833c1452803cea0796b204b75a",
                            "FN59a7084fce57aa15d144c227126d223f",
                            "FNd51bbb2d1dbb063a9b703da501583a7d",
                            "FN00c3c4228a31e17756d6e1cceec48a0b",
                            "FN1a77afe52045a06fa4372a00c8155386",
                            "FNc2a012a79b0c947353dde0ff3bdb9e88",
                            "FNaaa8b63af4d98b37cdd978ce05cf7046",
                            "FN9551e16a691c574acf6e91879efc7b00",
                            "FN8490082bff69a041e443582a340835a4",
                            "FN34db9b41d54a9bdb235dc1675d83ee65",
                            "FNafb647b09f5a97581fe11fceb9e96776",
                            "FN44d6f4e9c51fcb558a2ca9dace8e31b6",
                            "FNc91893110b06b4864b513746fe66f535",
                            "FN18f45b2785ca81c3e51f1ab120f1f3b8",
                            "FN335b02e5a0babce4bc87fa9d7af0fb14",
                            "FN89b93a13555c4c9b0b0cdd7b521d3cd7",
                            "FN5b95f802f8d8e1672700699d15afef4b",
                            "FNc47a5353898d7f5ffc1a1f7d35fa7c4d",
                            "FN6c7cdff35e82a6c8750f834ca6c711a2",
                            "FN7c0ca786f909b51d0ce2abdd8d89b31d",
                            "FNd6faeacff1a2aaa08ff594a095045e3a",
                            "FN24e26a10021e3c9b542deb76cd8c0013",
                            "FNc12429028ebfaebb9843fec8f9126b82",
                            "FN041d1a1245e136d69c03f6624da2afc1",
                            "FN388f8512a00aa07bb07a04530c7dc184",
                            "FN9b257328301f14f164e86b049d600df4",
                            "FN44303e96508c13f63d9bafe158f542d7",
                            "FN339ec69b11677c63bdee31c3ebd8ff77",
                            "FN6b418cbe216a1e71a055c3afacff9d71",
                            "FN5832d530e1012b3abce6b5974c9ae9ee",
                            "FN0c993d45af56d89410f12e2acd2cfa8c",
                            "FN0447e61639d1809ff8635403323392e8",
                            "FN65ea5ca03607fb7a7a495888b9d09d7e",
                            "FN762f72b2e4c96723e548b9b764fac387",
                            "FNf79d01c4975a126c52a3f40a0231bfb5",
                            "FN6e71ed21e4666a59274bb327874bc64d",
                            "FN6a0257f2d566f647eda163a423a0b4ec",
                            "FNf6bad926512f90a1fc65494bfd3e863c",
                            "FN0867524679e52e6ad2ac10dc38d2c0f4",
                            "FN8b4b7b83111d9aefb7e8ae7891bf7035",
                            "FN949e14caacf86ee6b8f808c8c2910393",
                            "FN593c2aba66cae8a48bac8f6a2bc4b57c",
                            "FNae422d5be2420912d7d623267d34f520",
                            "FNf30b5922fbd88cd89b552c6c77a09994",
                            "FN8e977dffa31b57836bafb7f59b666de8",
                            "FN2ad5d900b82e03487ca7f2824554630b",
                            "FNd956163271b73aefaa872aded8bde04d"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 90,
                        "numOfUsers": 890,
                        "msg_undelivered": 78,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:57:28.304Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "REMINDER! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:23.085Z",
            "CampaignName": "Ultimate EVV-HHAX 2021 None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C332",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "28.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 144,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 144,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "29.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 141,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 141,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "30.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 141,
                        "msg_req_sent": 141,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 54,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN49679bceaba90c5284a594d7735b5647",
                            "FN388333d52884acb95758093bf31c94b5",
                            "FN04abdfacf5d57238edbe527e2f155022",
                            "FN4edcd0c2c69ff41ff732e09fa58c390c",
                            "FNc25361bc2a27ef4624ef30a0ab987808",
                            "FN1f01ca8df9928ee633e632bd034cf79a",
                            "FN5db1b0b5b0957ac7824df61d33d66c59",
                            "FNe7d535e608c6b5a70b3d49136080220b",
                            "FNc9a7626c3b5dbba0649e70954fbffd06",
                            "FN25eab4900fa159cea660ebf1f0ea7a27",
                            "FNed18865949326b8da5408ffe3ccd8a9b",
                            "FNb5c0ca273a9a042e6e0b9c825dde9e2f",
                            "FNb7b9df8831663fa4db3c74984d693cda",
                            "FN0c0914cf869c09aeda2a04db68514af2",
                            "FN6f0948fd2db749e85cd473b3dec45ce3",
                            "FN68f93d73f85b206f51e09932f04adbad",
                            "FN77b518c585415d4df89efcb1f192e472",
                            "FN3e76319fc50ca7d5e8bf90a3857c486e",
                            "FN30d1709d8975f05bf7408f5c5f9decdc",
                            "FN3d8b2fad12bb120ac7e686e0ae494aad",
                            "FNfdd14a08e07d20b5dba3c4e8df5c6d78",
                            "FN6bfc519425d20b0143cc0bebae21a727",
                            "FN1e283f41210aeeb9993d80882ebb4b1d",
                            "FNaad5beb52b51983cbdfde238fd201412",
                            "FN6323a18631db6a7de8c2496df1474382",
                            "FN2569898c9bcc7aa81cedda13fa6b2643",
                            "FN7978735a3c9402346818ffba2ce7ada5",
                            "FN5c7b24e4c5d1c7beeb732ca01fc108b7",
                            "FN9ff69b1f961f10b29a4b293805f898f3",
                            "FN8b87cba136b4ff3d59e3c85a073ceb2e",
                            "FN6ff917bb51d459a875c5ba06fc277e39",
                            "FNe9f11a67a55fca339f17569f11504fac",
                            "FN795d5fb0360411c3a052f0ec8a0fab0f",
                            "FN43d6b3f18d2abf36d2802b0446b43037",
                            "FNf08c8d492e7142bafe6428935ae524fd",
                            "FN7c25d52acf2993e9ef9d6c4527fc4d8c",
                            "FN5b5767bbfe408e750309154471db1e48",
                            "FNf39b05b7df22dcdf6406355898e5b250",
                            "FNda3b4ba7125901120f1a35280b9b727c",
                            "FNe796e18d99ad935476f984cb3d15f600",
                            "FN79aed7e034d09b30da646a32c7921c71",
                            "FNed487221dd903a48d3f606f3fa1874bf",
                            "FNd511377462718243da41a923d0ee983b",
                            "FN9516ef10fe0a404888d171000cbba502",
                            "FN269bf5613d20505703cbf143fccd2d12",
                            "FNc902118c7d9629160c5eae9239e6a9e3",
                            "FNfd2d9cd3b4077becf46208b07761cb86",
                            "FNb471c6f7810fb78bb657a5b93c923c1f",
                            "FN735973704402cb1b6799316bab8fd37e",
                            "FNe50563df9052069b1cbedf69fa62a223",
                            "FNb5d60858a6b075ad44448e5937460a39",
                            "FN5fba1b51603d0d147e0d37c37309402a",
                            "FNc0bdef305282e9f5d5b3ea61315d6b45",
                            "FN7cda1f5858d91097c679c8b543a73640",
                            "FNb41ae295785d207f446efbe7c5f72e57",
                            "FN052cd245f0124c638709a68460b94ce4",
                            "FN888292f00c19f5545f50e3d941b5555d",
                            "FNe51f24e67dd3779de7c881b162281d7b",
                            "FN6c32a17f12b7e6a7876352c7d5b3ab64",
                            "FN0e61892c5ef8216e78f904dadc1f40c2",
                            "FN94dda88e9b46e6d928fd8d69901d79e2",
                            "FN2cd5a6d83b99c605fb8ef26b6d526e5a",
                            "FN071eeb02e6eb49f586a45bd6d8d19e08",
                            "FNe35ce11884c4032aa7c9451c9e226e42",
                            "FNc1749217b3e3310d0de2efaa17857fc7",
                            "FNc98109211a93e1da752135a0a6ddf038",
                            "FN9434be146aea952fc78b1e45a5f767fb",
                            "FNe3600bba9581d638787f1817d3fc1301",
                            "FN3df6e7addea371f58834fb267039ad20",
                            "FNd81d2d2960c8f2dd134e78e2e7f7e739",
                            "FN627a4d4669b0cc2e7c9c535461360b0c",
                            "FN6a1d0ff337d32af1224f86ed27f26c08",
                            "FN3e6cfe124fbc93b37a8582748d020d8a",
                            "FN689d26ea411ba52b9cd0f123f610e67c",
                            "FN8db127e4623e4efbe65731d1c715dc72",
                            "FN7c8ac92cb120ee6a90ecc98106b1d415",
                            "FNf8e50885fef4774d91e7a7c966ebb021",
                            "FN4d3b620167fd4441a1cd7c4bb8bdf9b1",
                            "FN25b7c377c2c9e72875cf0f5192a6475e",
                            "FN0b3b1e1389d2a1cf123dd48fac77d2ba",
                            "FN0bc411622919df5572635c2a868dfe72",
                            "FNe260cb234199396f6039991e1f972e96",
                            "FN5f3aea1680dcd255bfb2f05486bc7bd2",
                            "FN5cff94c7030ff34d2e1b598828e0e6cc",
                            "FNf6b17b13194858408c30083ff1d287b5",
                            "FN38d862c2d8db24cdd8647761afc2abc5",
                            "FN0df0701e638592fb0a8a834e15f7f446",
                            "FNc86fdd109a64b784f8f6050ac2622643",
                            "FN1a1f2a75c0118335efc522463fad4951",
                            "FNfafabe9d7822adaa792fd75e3626465d",
                            "FNa10826af325aebe6c78b5523f9761601",
                            "FN1657a802997fbab7f35e2a0243b45753",
                            "FNf3221bf54badae29e26416bd64230e89",
                            "FN6db8fa57d0a7d582c04d03aff4689b52",
                            "FNe70df2e0b54412e8a9e575e6843ee215",
                            "FN2f2020e090a083f9eb8fe98a34a65a62",
                            "FN7c0e551f4b10b56878993e40e9f0320c",
                            "FNaa0fbb8126f88436ed9bb325282a4e86",
                            "FN7248b20f65034083332f1e700642e1d1",
                            "FNc5536292d3876beb0e2919fe62a52cd1",
                            "FN1855244ce68bee7922bdd2305f190e87",
                            "FN5b9f828f3fa5fa931cd8ef18fb2c4e81",
                            "FN4dfe6739fa660af9a24c036878138775",
                            "FN15829f1e06d4213cab0e7af375e34cce",
                            "FN3efc0d7c0ac64d1a0b26a79d218470f8",
                            "FN47d1733a7da5d87f83be565ed4802e86",
                            "FNaa32be726aac0f62cd22f266f36079f8",
                            "FN633a7b1a4792d1e11adcfe299776a30d",
                            "FNa3a4a9237d79487a93dc784a9304a900",
                            "FN1c3ffc4af8b1913562fa35cb35b6d984",
                            "FNb01ead9f4c7b67a349dea5bb7c0ef606",
                            "FNc88e1c5970b781b9f9c61b1502f3d7c9",
                            "FN920247d869f524a76b0ac194fa786746",
                            "FN56e978aa1a4daeb216016127da808cd3",
                            "FN298fd477bcfedccdef06b2723ed2d820",
                            "FNd78dec3abb2d3d1e7b4d44d4d9b67afe",
                            "FNff2c4386bc665208c5a47da923dfd897",
                            "FN20daa6ece1d102b88fdc970de44969a2",
                            "FNa84c10054e9d498484c0a7c89f21aa35",
                            "FN1ba28247ba8fcc073f2506d11efc704a",
                            "FN94b86bc96908b5c8a6213c9489859fdc",
                            "FNcaf313fe6c232dd3c4c3885abb91a12d",
                            "FNdf6ab097097c3af6fcf77116eac5d33e",
                            "FN5c28dfee536f5920a9186f803aa4ed34",
                            "FN7ae44a636d04ab8e650ad4212efea77d",
                            "FNc237b9f6f2aa285030dc11cb7cad4220",
                            "FN6914fcef038366df56c2f8c520aedf07",
                            "FN8d5cf8e7b38e598943caa205bc548e52",
                            "FNd304fd58934fcb3d24fd97ad7c47d9cf",
                            "FNda646f89e45798b080efbc5d4da1715b",
                            "FNc290b141c1b4a3313e22dc5397f93b69",
                            "FN4e5a10614751b8ca08aa48b0b15b05bc",
                            "FNa84d94810de948f6476e3626f1913362",
                            "FN74b9ab5a76f8c6b83638282fd68c53ff",
                            "FN2c1e6af76df821182b84f7c53f12f762",
                            "FNa8ca8e236526f5b32a88104059d2aa85",
                            "FNd254b24162fccbd0b141e64c63c23d6e",
                            "FN27e2a871cb049a40759ae038d72aac59",
                            "FN4fabaf5ae793cf6d056d98f9143eeda2",
                            "FNfee916d643d84abe80aa21c67705c678",
                            "FN305b10c6d3a049b2493171e8913844d3"
                        ],
                        "msg_queued": 71,
                        "user_skipped": 0,
                        "user_update_attempts": 141,
                        "user_update_success": 141,
                        "msg_sent": 10,
                        "numOfUsers": 141,
                        "msg_undelivered": 6,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "01.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 140,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 140,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "02.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 137,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 137,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "03.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 136,
                        "msg_req_sent": 136,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN4e8f94fd2869ba79f219a4e8143cabde",
                            "FN8364dc23cbd6ac67a600c7726e121f29",
                            "FNa16e6a4b5ca80975eec7e34d966f29b2",
                            "FNffa1232f972ff8c1e9271db311c17063",
                            "FN123a14ae25b2c186d674a1d456e79316",
                            "FNe7b10218caf9d4581902936f83db2264",
                            "FN83b82bb965a6d5f9148fdfba05b03640",
                            "FN9a73fc9e4e138c13f5965d238b2b1653",
                            "FN61f89c42c6ef0868c80b07e23faece4b",
                            "FN989c767b1fad5f2e5a53f8c40d4222b4",
                            "FN764ff9c050f895c8dbad62560413bb67",
                            "FNa7715c500bd6466b76f5633c72e22144",
                            "FNff20280a76962eb380c7abbe3868e634",
                            "FN04815a02e2c0d4cdf4fe67827415bd30",
                            "FNf0a30b75eecdb10ddfb279a66836f707",
                            "FN4442df6b0207520ee3acb355dcf7863f",
                            "FN4d07daa618df428249a6e05bd68fe6a5",
                            "FN1a2544376f6df7fbc042cc55e648b707",
                            "FN51eb62d760699308dddd07836a183f44",
                            "FN11db97ae7e0ec2259cad38266c773f86",
                            "FNc29dfdfeca67f4c34cdec22591500464",
                            "FNd5c0f06db2d206e370e6ac737d4522ba",
                            "FN1147257e143466bce03c7ababcb81a33",
                            "FN096f5e3d097ea182f7c5a1c904c1455d",
                            "FN204dad5566d1cb783051e6a7cfefa11b",
                            "FNd949b503e8d1251a880f63ec98bb4c02",
                            "FN294a36b0ac43d1ac6b26a02aa26d9777",
                            "FN6b8d7ba7d9cbca29f50a070470d5baaf",
                            "FN5248a4b7d08e58a7904657e4745840c2",
                            "FN870844d19d90f941178a9b736acadae9",
                            "FN47b843dc3ef9a16db670cc19486b9ea0",
                            "FN36c6dc802043ffd3778da883ad8c4d2b",
                            "FN338cd7c78dc6fa41cdc6e002f77d91e7",
                            "FNb94ffc19fff18125d31f09d3f608149e",
                            "FN6098a876fc03026a845269df3e0648b9",
                            "FN95c76439ba1b12618d36a871e9afca3f",
                            "FN5d951bc4877c7e07eb80de981ab354e9",
                            "FN6998aa8a1d96b14e3c03e9984b7223b3",
                            "FNa93bf319f8cc671e5038723e00c9d8c5",
                            "FN62a069d2bd242c2f07353d4eaac3da30",
                            "FN6d92dab0d5bee010f498ed131ff9d51e",
                            "FN3e69055cfe3c303c5e74ab1235615532",
                            "FNc2eb2a6144e044ff3115d7833379edb3",
                            "FN5b624c2985b0f5b5bd9936171fe3a863",
                            "FN2f1336f4e9c441905bf8cb5d40f6d839",
                            "FN7ccd30cc99557f7d62a98c64b259cad0",
                            "FN97654c6c73c958115eda0859bb07cdc7",
                            "FNd1f113a0e1d445d406f5fd5f6546ab5a",
                            "FN802a3bcdcdc3687182e7f084bd90bb45",
                            "FNeb4538ee9117e604a17740059cbb43e5",
                            "FNe31bbea5a56a1ab8f2b7ed8079215613",
                            "FNbb4ba1d10ba69d66f73aef73492649e0",
                            "FNcbd83a4393483f1c54b97ba952cca3ac",
                            "FN75a252edfda87b6dda4fe2c8bf285873",
                            "FN19b7da954074d104ed3ef952aea5d6b0",
                            "FNf7ea1c5cde71f61c662b37942ca5d8b2",
                            "FNb78dbeb3fef428bfbf6abd5c68f1cef8",
                            "FN1be65c8bc8e2c7ec0aba2c342349306a",
                            "FNa67e9ba95178c337831ee2b4e7c4e3b7",
                            "FN8bcb080fef338d05fc64d1310c88da1f",
                            "FNb1550887e125edbb524ea3cf0def5004",
                            "FN2da1733a0afff819499f91667043dbc7",
                            "FN9d002877ab94a6bcf9cf3d192c6b745c",
                            "FN43e5b7bdd1b9d62bed910272b19c83f5",
                            "FN7ee32d4a067bdee7cc89d972f2f0f1c9",
                            "FN3ad8d48cc5f6b42a8f3b62eba3204bad",
                            "FNc3b5de56fdd60760f78e881d98931657",
                            "FN16b078d9fc26cca21ea3746dd39eb689",
                            "FNbab3da58794bb47c8c25356a2e583c70",
                            "FN634216c0d70d5996044459efca6bc0f2",
                            "FN2753e3b8872edb9682f88ab61828fe8f",
                            "FN8902ee5cb876a07678dfee40da44d84b",
                            "FN6e8c67d8253f8a09402c72ea6403ed6a",
                            "FN85430618127a1b9b0d65cd9b84fe30ce",
                            "FN0196fc41ce9b1f1c9f1e2c8f1be3c5ad",
                            "FN1238c7dd3d163208c0b22cca021b91aa",
                            "FN612e7197f1ed5292b21344767b5334e1",
                            "FN1095b5ff863053f7067b4de9a30810e6",
                            "FNe4f0a885895e403d30b2415c328c1d52",
                            "FNbb758c655103ffff21e115227220ee3b",
                            "FNcb21067aadf5f30cc55fa73823dd8a29",
                            "FNe9662a669ddfa9193efe58ae06eaff73",
                            "FN92b76e4216271c72daf070e86a4baf63",
                            "FN32b32b55117040c2f1c629097b5b7319",
                            "FN41a1a3ec73dd70bc590c9de9a123462d",
                            "FN47f192ce24a6c363493693b7bcf20d54",
                            "FNdc95dae68da558dec895546db0adb85e",
                            "FN070619f2126ab0888b601b829515c49e",
                            "FN0dd666d9d15b7afc75232ea2d4a69a61",
                            "FN75d0b43632e720196a064dc993ff1bbd",
                            "FN814732fc799c5dc877121ef321cbc4a0",
                            "FNe1f707ff6a635e2c4d07d445ab04b2c6",
                            "FN8ef238e7f0952d3741238a6f7ec37ed8",
                            "FNfb4e8900d1f557ef153740ba483a3424",
                            "FNc1a6691b81b9d83235e3a63c22c9dcf4",
                            "FN605b85a9a67c76d5255a83b91ed027b9",
                            "FN5b8b1bc5def76479dba2d5bbea6bce25",
                            "FN5c5fa5a7224a038caac02edc0a1ce0a7",
                            "FN5ca19503da08bf2635b84b929303b2b8",
                            "FNd459346428b3558a5870bdaec8a3a3a7",
                            "FN090df0a9544d08f8f631524c0fe1f12e",
                            "FN9f3fed6220a61d390c0cc1788db729e9",
                            "FN9de5d4f61438ce32999cbc20dd67a8b7",
                            "FN29c8d2c927dacce92e3da598ef62d622",
                            "FNd07b511784145e0173f87cae53ffe431",
                            "FNfb40375a27f1ac808a5fb17ad4e420e0",
                            "FNf8c369c1b766849b43d14f307e9b4fb8",
                            "FN71d4615df44f4de04961f90b7f6e1b23",
                            "FN2985db640da0945dec64dfee3e40f6c1",
                            "FN0d5fbda68b0739eb65cc0dad00ffde8d",
                            "FNf340aa696a7b35ae5d86d90d96b4bf17",
                            "FN820c3110d6b47caa3f3de51280a87c14",
                            "FN4c823a827de14b31a166436cb4bd82f8",
                            "FNbfda828c2c274a4552859cdac2613353",
                            "FN3674558e09e2d87c61ab0f11f7f3327d",
                            "FNbc4594fc837fe8e2eb049a371eac3dca",
                            "FN0711a4ed1c506b03c0e28089abcff3dd",
                            "FN60f8f2948abeb8902489bffbb24d07ee",
                            "FNfdd708218d6faae750bbbbd7b372c11f",
                            "FNdc1eceeb79370a12c06287715774ed14",
                            "FNab94449d2470c52a139363b8c0de7fa0",
                            "FN3ef7b018c3a9418c18e7829766ccfbb6",
                            "FN4ee30bf10c3b68e78e0aaeb39fc64821",
                            "FN6f74add2c294cabbc99d736e31a6dec3",
                            "FN70724c5811943c4fe3dba6c2fcefabff",
                            "FN79a1032cebd005a7ebd2f2b0c8f7099d",
                            "FNcdcbb966055676adde3509903fa4d286",
                            "FNdbecd828495d693c0913d834cc4209da",
                            "FN620cf7e06e9e7ea46075a5c821739398",
                            "FN59f11c0cf33a34747fad12687eba04c1",
                            "FNdc4bbd390d66f245775b4f77ee063f0e",
                            "FNc9b9f2cfa061ff14ebb4c60999a9d8b2",
                            "FN6d13ec940e673e7ecdf6f5d3f9377292",
                            "FNe61a056cb850f751db0750e37f39d6e9",
                            "FNc568fcf957c061a5a3311dd41f116551",
                            "FN175cd6090fd6b88ec229e3a0f9a72883"
                        ],
                        "msg_queued": 136,
                        "user_skipped": 0,
                        "user_update_attempts": 136,
                        "user_update_success": 136,
                        "msg_sent": 0,
                        "numOfUsers": 136,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "04.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 129,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 129,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "05.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 127,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 127,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "06.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 125,
                        "msg_req_sent": 125,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 48,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN5310fdffa515d8045ea43e8767d6446b",
                            "FN8eb2b242a6b1ea170ae8c0ddbaafc3a4",
                            "FNd71d878af45298d88f2a5cf5b3b0362e",
                            "FN4f2121311c33035d5d31e6ab04d4c53a",
                            "FNfd313352b5990f57cd8b08b7802aaa6f",
                            "FN6a0858b602ec999486c84a4277006a91",
                            "FN9981c701b317156fbf46f5be3fd51994",
                            "FN067752aa3bfa4c9da5b0ef38d00430f5",
                            "FNe0bce75276a3c7e7ecbc26f7c3ad956d",
                            "FN15aade4378525fadef55aae38dfb8665",
                            "FN4a19da74abc8b4c1ef4414adbb19b6a5",
                            "FNa2acf85eced47230692cd710d7420288",
                            "FN3d54c671a42fd9b5f5c7281178f8eb93",
                            "FN7d59ee6d55ebc9882b4bac6c6655d167",
                            "FN36e059d4d0f9d70ad900fa21b8bf6465",
                            "FN9a9a3cab00a97747743ecd6473f774c3",
                            "FNe37f1cb2a9a70fe99e8c452dbc55c969",
                            "FN617adeba2c9897f9989db6b7a54b3b4d",
                            "FNe74e7d6b5bc51914a7f5ff1943f8051a",
                            "FNad44b48222efbdf46fbc3388d3e94c9e",
                            "FNf12f71312f073cf2859fb62529f33f3e",
                            "FN5d460c4b1a63e458cbdb160038180dc8",
                            "FNe8bfada35dafddd380609f7c75f734a9",
                            "FNa51d8214bf88ef8804d2fd3e9b2a326d",
                            "FNa7f83a66bdce3a60856634e683b0e347",
                            "FNc7ae18f9c6b7608b8bd6038349be2e38",
                            "FN81aa9968a78df8e7841eb57fba8b76ed",
                            "FN629a6eda3ec0db84623f9191bf281ec5",
                            "FN35dc8004969afef7dd8728544921fc56",
                            "FNdc30d2b77b3ce219cd0541a43c91fac4",
                            "FNbe5bad01eba558a315c2c7e2aa78f375",
                            "FNe113eb3e796640c9d8763cd5e46d7f65",
                            "FN3a58a47b3ef3843110d23cd53cd2114f",
                            "FN9d4dd306ba4094e12213fc1f64641067",
                            "FNd305d03fece2af5e939bd68cef16b4a4",
                            "FNb4a2ded1d775336332146645199901a7",
                            "FN473d49b9a7e6e00cbf8404899dac4c4a",
                            "FNf0a53a8197e8e36e822758241984c104",
                            "FNb49291a708fe668b9ad5550c0029371a",
                            "FN3a058c663a700dbcf79011f0b8b84487",
                            "FNbc62068375ac729be967373c61dcd257",
                            "FNaf8642e0ccea9d248f891f0c55bdfb3c",
                            "FN1aad125f34b42358e22c34bad93c6b54",
                            "FNc5a4b53097103db31f969d723225464f",
                            "FN6cd6d1dee5a9662d1809cdd0f2d6494e",
                            "FN0db9b946b781a1d6382966eff3945843",
                            "FNcb2b6df15f9063c2ec368ed3974bee4d",
                            "FNf1fdcfcd97cc3bbe9c4fe0f536a9ffca",
                            "FN54f309c6f970de13d400046c89bc39fa",
                            "FN0d64a208a260b330e2147062695f051f",
                            "FN2e9147ac74d3e5b7e5aebcdf08fada97",
                            "FNa66218324de9637a21a0a302978f3127",
                            "FN51fd4724398c222d287adbd18b2815a5",
                            "FN260d7b3198b2a0c4749afc1786ae3d38",
                            "FN7f7afc9bb407739270828b4575bc8c45",
                            "FN998facc341512365ab9b55530dc77df0",
                            "FNd89213af08ae1ef2fafa1fcdd8c470d8",
                            "FNc2fa246c79903a83c0d9dbc6fca25a42",
                            "FN8bf471faccd1bd84809e4622dd1bd9d3",
                            "FN4453f01421192983d0c1f268bf062c6d",
                            "FNeb81c96c2e7a559d3ef9fa20534708e6",
                            "FNa9f5c81f5a399dfad9d29f40de7d946c",
                            "FN241d750243f0e685e0a335bdfe386516",
                            "FN2a8961ae7a7518149aef865dfc5887bd",
                            "FN9b0b3c369787817950626599d97cd909",
                            "FNa2ce19d9cf781fecbd29bcb93c008ea5",
                            "FNb00fb096327fdf600a640383bbb6d3bc",
                            "FNe4fd9416bcaff275c2b535d0c5fd2f3e",
                            "FN5b407ebc3a801bec5ac91fbd8a519943",
                            "FN1eb77b014df8f9c9b6037d27ffb9b282",
                            "FNfdba121f6416d0235d6e92d75bb7b7fc",
                            "FNe594316ba72d59826c9fa1959e9c52a9",
                            "FN133da14f3063ca7a4b37b425bf42d198",
                            "FNdaf03b29a1bea6ffbe51d54307cfce9a",
                            "FNea71d894df30b7692d256a3081ecd3c0",
                            "FN75fbb1d13dabe9b599c459cb761a5af9",
                            "FNc48a3dfa89a129c758902b26e6b2f44b",
                            "FN1ed9988e92214e5f382e047e0dcb40c9",
                            "FN65743f1d78dc8a8289cee1ccb2e2140e",
                            "FN17242c8e8ecab7c154cf638aa1fa8962",
                            "FNd4ab993110c6b31d303ad05025000aee",
                            "FNffd1d6d3ee4fefe8af00fb6e10dc1d6d",
                            "FN87d8f24cd749bb631bc53daf0fb1b662",
                            "FNd435db6c2816479b365f10d6739c8a3b",
                            "FNbdae62b7b526367ca3057a00567d69e8",
                            "FN0497dcbd33f9e71919a33b31cd30b187",
                            "FN0b5eb1e1cce01367d1295147d18495e9",
                            "FN2bc832a8db724d7b8b6cde222893a464",
                            "FN395bd26fea2fc0fd43743985de4a23c5",
                            "FN1df61222c467e2ae27b9a65bfc1fc5e0",
                            "FN96af5d352e56d63c14a6e1d9ac436dc3",
                            "FNe8b695c5f30dbd6e4505f203cccff2eb",
                            "FNf6577d49775b61c0747e5449ab572b4a",
                            "FN52bedad8728c26b6c63b5a527b953de8",
                            "FNda76498b12d62b5f9484357a58760a1a",
                            "FN9e2615babd94e586d59311ca067adfad",
                            "FN3dfdab261a536d549c8443fb53ac12db",
                            "FN1e99e0474457187b1d170c7af0425911",
                            "FNec33ea3fe07aa80956ae3263a9d1a4b0",
                            "FNdaf61230c4b1a93f53a5cd8d8ad2d2d6",
                            "FNf122ff3dec5064d5dbc5792979bcef4a",
                            "FN140322d06b32cb8cdeff7934191571d8",
                            "FN980f26c30d378ee22e00a4e4cef83774",
                            "FNb6eec3a4ebd6e1126eb1340c8deee382",
                            "FNbc0ae3ae6b1c6d9d126532a0fafab588",
                            "FN1f0912162011ec73151420e2620d6b7a",
                            "FN39cdc732dc2384559659e042c4f0d625",
                            "FNb3ac540f4524bf6c66b42ed653ac32e8",
                            "FNd36ac1f1d1e261aee92baaa48c794bb9",
                            "FNc8c0f7b3f1a652214c549330d8f3f58b",
                            "FN4b0ad57c6ef9f263d379cfb9372df2fc",
                            "FN4ed6f339a75bcca03bf530682bcb5ed4",
                            "FNf61f39dfb0fcb7c7e01752bb511636c3",
                            "FN4b674d139db527a31775080bb2872df9",
                            "FN0e24c1c2e400d9a8dd525d8c2db6e2a0",
                            "FN73ad065b820009b661dd97f682de4734",
                            "FN2b00d3dbf48882a13cd9fe24bbf15aa8",
                            "FN7921c8ce652f8a1aca6ee135ce8df4dd",
                            "FNbf007432ead8f0b3d81773c113b42f40",
                            "FN4793b17715ab93f9af30b7e869bd366a",
                            "FN5074ecae4e9433997148d26b14a9164d",
                            "FNef7a783576698a4c96a747dfa5e29f77",
                            "FN2ac4a4e06db5e1dab8c110ddc2d0c736",
                            "FN2a5d32a696f25f50a486ae401ed6d59d",
                            "FNedcd79c0c6695e55ee82a5a04698f816"
                        ],
                        "msg_queued": 64,
                        "user_skipped": 0,
                        "user_update_attempts": 125,
                        "user_update_success": 125,
                        "msg_sent": 7,
                        "numOfUsers": 125,
                        "msg_undelivered": 6,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 124,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 124,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 121,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 121,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 121,
                        "msg_req_sent": 121,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 42,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN443bfa3fe528b2c8398c606742d5f2e6",
                            "FNc27cbfa3423d030edd4d8e8f52bce4f3",
                            "FN8dbcc8f4874981c9ced319b7fa92681d",
                            "FNea4e3e4df6eaac0da64e60b244376216",
                            "FNaeb161eec08f57fff7722e078e23bf27",
                            "FN8faaa9ea69799b21826d046fab8d0586",
                            "FNcaa10a49e982b04551ea2fe72d069027",
                            "FNabbe6abea90bc7bb7dff5aaa5168fed0",
                            "FN7d615048b50fa9673e366bd9de6ac8e5",
                            "FNcc512d0a3c601907eabe31b78d5730e4",
                            "FNf664901f7b7ba6a09b2149dc2df909a1",
                            "FN705e8c0080d58013e71fec7e40ba0aa5",
                            "FN4ec69139ae7709103457e217650db25f",
                            "FNf4d9c527651fcaee489e478c56083cb7",
                            "FNe9bfde17fbf83b576d04db66e7386bb5",
                            "FNcd6839ab53ddd64a65a9cffcd4eb5e60",
                            "FN5fdbacabe9e2da7dc0847a7591a99904",
                            "FN7981509c34db6e7d0fc28621b9a779fc",
                            "FNba86db79cbdc9da74362104eb1950f19",
                            "FN942526c055b9b0f64850dc16b0405699",
                            "FN5718039f511884960302c86cb96a3449",
                            "FN8ecc8ca57d375351b9217a41afe0604a",
                            "FN12f915a3cbade2ff53b6a85f2db45245",
                            "FNcfc70716a09de5c1dcfd5f70292ebf84",
                            "FN4189f4dd2e38e0b4285a153f57c7188f",
                            "FN6b239ee2422f00cb5ca1e254789bbdcf",
                            "FN6c818a51cdc3431628f716940fd87be1",
                            "FN30657cfb215b8fb261620ff7403901cd",
                            "FN2dee3b65a55399460ddfcff083bbf458",
                            "FNebb2a5697c0a3a3bdfb7e599b0d7b1f7",
                            "FN5fbf5a6420ead8b5a44c43a465936671",
                            "FN0b53275231ae4c47b70782c18441b6a4",
                            "FNe7902a5959792e23029f24ee6784acea",
                            "FN71f42bd1e4bb74d955e6fa54df4ff65d",
                            "FN926094bb838025a59769d5c9c18cac81",
                            "FN2d882eeccfb7cc6d1a7d2251e1ccee45",
                            "FN6edd06095eae8e0b4a372add16c7e858",
                            "FNcb966a39dd4c3163ac8bb6092d05badd",
                            "FN73e8e79048b7b8b7ae1e74b33cce95b6",
                            "FN5801777acd9cf0795cfac696f68e5c33",
                            "FN393dd5a202e3c83f65caa00e8a4674d8",
                            "FN9ebfed39d3a1801c376fa2c8e567fcae",
                            "FN4e5a3180c92c8c6926708af42df3f9c6",
                            "FN220b669bdf6ae9b2f8e662669842f844",
                            "FN3201aca5864027ef8486fca628444ba6",
                            "FN4503f1e34f1320476de24aada38ebf65",
                            "FN2d415c793997d5b178e7fc667698d455",
                            "FN0da2740e374e892988a9246b14e12aab",
                            "FNd750548ad63b17dee424b63d4a8786a2",
                            "FNab217ba3bc32247baddaebe19a80d58c",
                            "FN9244da798e8c3ddb7d0ce31633f432eb",
                            "FN304014709e3e25a30f6663f79f371563",
                            "FNce79aced996dd440a7261021feebb3b7",
                            "FNc39907e2aacbb267309d0be2e70a534f",
                            "FNc26cf76aae8ee573b997d7cbbb86c80c",
                            "FN950c34f7b063ff3da3cee3933459eae7",
                            "FN50d4797dc43da183eae4670b9783ee17",
                            "FNacbe9beef309713dca8063fb8579c7a4",
                            "FN33345f788b22d54b4648c4980805821a",
                            "FNf6d2f040bedcb02688c219fec1687bfd",
                            "FNafc544d5440568676851e8e44acc4400",
                            "FNa5a278f2ed553662bfe52b229493d5b6",
                            "FN13e44aaea52a9af457160c2e4fa5f87a",
                            "FN1cf7539216f3162f9e3ad4812951cf63",
                            "FN46afe7defb27bc223a2e1cd604b77a60",
                            "FN1f6fea4213262a35f32d7b627a6e5281",
                            "FNa1cb4cfe11774a84e96dbcc306560f44",
                            "FN4206eb165e03cb4f3f159572be5aa6d6",
                            "FNb215e49a6157bdd4ccfbf588e42de26d",
                            "FN524d24178484cbe0c48fd13063771947",
                            "FN26f40d708c328f400b73f8b4a8e2125d",
                            "FN4804b14c46d9fe730439fd2c949ab118",
                            "FN98171949429d515a063c78e5cbd61a85",
                            "FNb8cd4ed1c85e606161d0597fbe39f0e7",
                            "FN9ff2e500ce416341c9e293102d40e3d5",
                            "FN8bdaf2c7d93be6a3274db53b32c81c63",
                            "FNac74d914dbfec60eac9ef7aad6b07aae",
                            "FN9b75a412873dd27c2e44c30492a66d3d",
                            "FN63683166668edda7b2edcfd803c6ce05",
                            "FN061519da07dd837ce27a5c8f449e97da",
                            "FNe8fb09d2f07c3c248141794571d5b00c",
                            "FN02fbcc9b17cfaca67947105f11e971bd",
                            "FNeab049d606b320f32b14aa839518c337",
                            "FNb3c213a230f42d83223eb80907e39ddb",
                            "FNd87119ab0b1b2028eb094d78678af4b8",
                            "FN3ecd46b15c1977244d9b2395065e2a1f",
                            "FN0f5416e2cb1c5a0499c020fc54544b3f",
                            "FN7083e0cdeb10f0ae1b02b868e7c44c89",
                            "FN4c46cf2d46d243dac68d54894749ded7",
                            "FNb64a2014ffa64d4ef016eb2e907d929c",
                            "FNa69c62731edca931e26824d096344e46",
                            "FN2f6ffb904694f40604d34c80d73a7aac",
                            "FN0dacf24b4cab2190e763a4e1ece2381a",
                            "FN71b1bade2989a33dc1279fc17e336d24",
                            "FNa466b9d9dac3e6d48b4ecd127d2d8808",
                            "FN89fb6751793da9c12e8e12a5cfe3b4b2",
                            "FNc7b5c23b5761413ba73f9c7bd95d00e9",
                            "FN81afda221e28920b2e9b8a85d94b90f1",
                            "FNf0d7b49289ac04023801901e3294eb5c",
                            "FN598094a2981dc15ee6c90bcaf6515114",
                            "FNc4572bec5279cd7be602a0af853c066f",
                            "FN19adc279668caf05b26f64ba515e38ce",
                            "FN9d51fca4522d4e3df96e471bd70fb488",
                            "FN40db1d8eb2f5b4bd6604e2120843aa59",
                            "FN1e13fb8edab2129c32965c93ab640df8",
                            "FNeba3fb94b10fdfd4857992a6cd3632a6",
                            "FNa4f7acd8ffee011be655047c2c352d6b",
                            "FN878b784e57cdef53a2217ce019b0273d",
                            "FNa2b702ef0033e03475fca55ccf25130e",
                            "FN45613657c28c3dd1ee49f17aa4732aaa",
                            "FN6e4dfada441bd3812207402f6deae1d6",
                            "FNf6de018e321bfd242ad469b548ac2fb4",
                            "FN3f87ef0970ca8bcab277f7899227c03c",
                            "FNfa18bf889aaa3cf1af66dd433f44a584",
                            "FN4b7e77753c5b77a12333066619fd053c",
                            "FNadedcad16dad98cdf94a44e62e58fcd9",
                            "FNee916a67499de9ae9505d8cdac80c024",
                            "FN4f1d4e74224919884863fbbf7cd0b3ac",
                            "FN60a28f37f7d8380b59445f72fe9897c7",
                            "FN61b61c657b7acd6c379fbd4175b67ee4",
                            "FN95de5caae7d1a7d5ca4e2c525fb41c2d"
                        ],
                        "msg_queued": 58,
                        "user_skipped": 0,
                        "user_update_attempts": 121,
                        "user_update_success": 121,
                        "msg_sent": 14,
                        "numOfUsers": 121,
                        "msg_undelivered": 7,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 119,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 119,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 117,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 117,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 874,
            "DaysOfWk": " 1 2 3 4 5 6",
            "TimeCreated": "2021-11-18T19:21:48.682Z",
            "OrgName": "Alternate Staffing",
            "PresetMsg": "来自 Alternate Staffing 的提醒：\n嗨 %FIRST_NAME%，如果您还没有这样做，请尽快完成您的在职培训以保持合规。在 webtraining.nevvon.com 注册并完成培训或下载移动应用 https://bit.ly/GetNevvon \n如果您有任何疑问，请通过 https://bit.ly/NevvonHelp  联系 Nevvon 支持",
            "campTime": "2021-11-24T16:30:54.757Z",
            "CampaignName": "Alternate HHA",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C21",
            "MinProgress": 0,
            "ModifiedBy": "uri@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-24T17:30:58.872Z",
            "ProgramName": "HHA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 246
        },
        {
            "MaxProgress": 99,
            "ProgramID": 559,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:36:03.106Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Richmond",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 7,
            "CampaignID": "C271",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 141,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:21:12.570Z",
            "OrgName": "HDA Care",
            "PresetMsg": "De HDA: Su INSERVICE es debido, no está en cumplimiento. Empezar https://bit.ly/GetNevvon o aquí: webtraining.nevvon.com ¿Preguntas? https://bit.ly/NevvonHelp\"",
            "campTime": "2021-12-09T17:20:18.247Z",
            "CampaignName": "HDA EVV-HHAX 2021 Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C310",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 463,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:37:21.726Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:04.303Z",
            "CampaignName": "JFS Care- Mandatory 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 5,
            "CampaignID": "C541",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Mandatory 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        },
        {
            "MaxProgress": 100,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:30:08.275Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C218",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 18,
                        "msg_req_sent": 18,
                        "msg_scheduled": 0,
                        "user_add_success": 18,
                        "user_add_attempts": 18,
                        "msg_delivered": 7,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN0f7a7b652c57de7452af0288ac652845",
                            "FN3311a9dc100fdc6b478f5cbfef86b63c",
                            "FN131c22f55af105e8fd734e440cf6f52c",
                            "FNa5d7201cf9c18505a80a23878b2ee5b7",
                            "FN5852ac1328795909b335b30dd2186bce",
                            "FNb707064e1b0cd62043325e331b3dd3d1",
                            "FN01fad965d35325899e75a585491f7a81",
                            "FN09c74c2551de54a8015a4e0527197082",
                            "FN9ff90c546c0adefe409768a9b4b8f594",
                            "FN3311844d4344715f28eeab72943d8ea4",
                            "FN4b0d96f44ff0ed89b3d38f762aad2ce6",
                            "FNd5d14656fee1876e470ab15f9aab096e",
                            "FN4ae49d3495da8e545f9dca0dd2fe7236",
                            "FN5e788e66fc3990a2015c29e4d6ef8ffc",
                            "FN1e108a126e3d5b936c05e7971dfe31ac",
                            "FN008068808e3b0778b010ea53f33f878a",
                            "FN48b29aa9014ea4343cc4ef12ef5e981e",
                            "FN3954a7e1768f566f5f645b70794006f0"
                        ],
                        "msg_queued": 7,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 18,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 678,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:01:09.394Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:25.315Z",
            "CampaignName": "2 Hours - In-Service Program - Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C428",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 282,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:04:28.107Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:39.074Z",
            "CampaignName": "BrightStar Care Program 1 - Experienced Aides - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C473",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 19,
                        "msg_req_sent": 19,
                        "msg_scheduled": 0,
                        "user_add_success": 19,
                        "user_add_attempts": 19,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN32db3e3b4cfc12fa48c2da972fbed8cb",
                            "FN0e802a056dfff7beeeeea172f495bcb6",
                            "FN521af9e624b5adc4fa1cb1ecb907fdcb",
                            "FN8ea77c9d7f89f650b4edf75e0b2085d7",
                            "FN15941bd807c8527bd9d15c4014f43c9c",
                            "FN02ae8a399aa29cda5085f4b7ecc5c1ed",
                            "FN610abae6d10ed84eb193ac8821b9642d",
                            "FN17b90b6da26bfe53f1dc9708fa32c09e",
                            "FN9549ad4b0c047c493fc78209f2ea317f",
                            "FN00040bec00e6cf4cfc5f7f54175ad246",
                            "FN4c89dde6c9d18436f6725e45bb1d89b4",
                            "FNd6890c060ab003bfc3d8751e763b1665",
                            "FN35efdee99badfe7651584ff1e5ad502f",
                            "FN026e7702cd25f7bd54806f8633a70714",
                            "FN9d648e1883584301dde1426c00bf95c6",
                            "FN99b2a90d3645a7d22f4d13c4518e5643",
                            "FN985b5d5d330f99aa63c9491f715cfeb6",
                            "FNfc35a02742d7714615375be0a362a2ea",
                            "FN27d556d7724d7fcddb41f2774b1e237f"
                        ],
                        "msg_queued": 19,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 19,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 735,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:26:49.557Z",
            "OrgName": "Gurwin Homecare Agency",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Gurwin Homecare Agency: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Part 2 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C448",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Part 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 107
        },
        {
            "MaxProgress": 99,
            "ProgramID": 727,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:12:35.038Z",
            "OrgName": "Addus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: ADDUS: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Addus 2021 - Q4 - Chinese",
            "FrequencyInDays": 4,
            "LangId": 30,
            "CampaignID": "C212",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T20:27:17.681Z",
            "ProgramName": "2021- Q4",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 165
        },
        {
            "MaxProgress": 99,
            "ProgramID": 710,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:53:30.050Z",
            "PresetMsg": "REMINDER! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:48.682Z",
            "CampaignName": "Compassionate Hearts PA In-service1 Haitian Creole ",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C162",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:35:14.058Z",
            "OrgName": "Susquehanna Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Susquehanna: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:15.464Z",
            "CampaignName": "Susquehanna EVV-HHAX 2021 English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C326",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 234
        },
        {
            "MaxProgress": 99,
            "ProgramID": 559,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:38:47.298Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - Russian",
            "FrequencyInDays": 7,
            "LangId": 134,
            "CampaignID": "C273",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T06:42:20.157Z",
            "ProgramName": "Assisting Hands Richmond- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 285,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:32:22.474Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 3 - Experienced Aides - Russian",
            "FrequencyInDays": 4,
            "LangId": 134,
            "CampaignID": "C490",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 4- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 688,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:12:08.134Z",
            "OrgName": "Angels in Your Home",
            "PresetMsg": "НАПОМИНАНИЕ! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T17:00:35.144Z",
            "CampaignName": "Angels 2021 In-service2 Russian",
            "FrequencyInDays": 2,
            "LangId": 134,
            "CampaignID": "C101",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T15:22:42.140Z",
            "ProgramName": "2021 In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": false,
            "OrgID": 206
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 38,
                        "msg_req_sent": 38,
                        "msg_scheduled": 0,
                        "user_add_success": 38,
                        "user_add_attempts": 38,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNc7d41ca3c35bbcab14f90fb7c83527d8",
                            "FN675c1a816a4923c052d0beec54e31e9b",
                            "FN8b54af5bbfabbe849272d032bc1a7722",
                            "FN3edcaf2f4f14110b71faba9c52107b01",
                            "FNfc44c15ed7f4fe26b5845beba1134657",
                            "FNe281e4b7add599869015bb17f120b104",
                            "FNa6e8a99f52c41342b3acfe68c5ff3ac3",
                            "FN4181d9e8fb4440a2f42941054ec20767",
                            "FN9bba2ba1d3a520dccefa2ef739763aca",
                            "FN363863236c12b40ea0f9cdb959252b03",
                            "FN53fbd71a0c56d5cb3b099323beebc4b8",
                            "FN74756ca1708283cd79dbc582997fca6e",
                            "FNea191ecd6e1501389adc23126a643130",
                            "FNdd54218ea8932af18b8387f2cc28aa35",
                            "FN5728bc501e611ef33c8e8e679f243176",
                            "FN1be718e316cd611cd43b7e76639986b8",
                            "FN51fab27eb7207a2950c54bd0225bb0ce",
                            "FN3e6e1c6e05de84a6071b9d57f007c787",
                            "FN44698ca5964f69f7c05bda9f2f01440e",
                            "FN47a19369687e75a3ac79bdc8b9d0cc65",
                            "FNdc77e8a9f25c164daec438f1dd93e779",
                            "FNd9bba0af8e3fe17896742e789e176b8e",
                            "FN162bcc28eb227b716c605b9c18624680",
                            "FNbe33580292231622475c8470b86167a6",
                            "FN9045a2551a90eee7d75bc7b46685a84f",
                            "FN5750aa87ff1a34511d3b75f56ec538b5",
                            "FN9e8413ecda77f597a9cc7899b7e9534a",
                            "FN6b53a2f4bc1c3afaf779727630deacc0",
                            "FN69ee33fe8831cd949e6ee3dacd00e2f6",
                            "FNdd0d14de1ea5f92a90e6f9b6cdcca449",
                            "FN9722622a633439c7bcf5f66acfed1135",
                            "FNf81b464638873803b642d97cb1a33154",
                            "FN1c945f16c255a7301f2f7e0f1524de97",
                            "FNc5bc947aed7fc3eb88f7ec737e38c8ca",
                            "FN4e305e3264f3528cba24a6d511a19711",
                            "FN4980a15d6e11c4b864ebd282baad30d3",
                            "FN3a330d6874859602ce85957ba02dc7ac",
                            "FNca6c479d7af59ff3e6c11467e571c222"
                        ],
                        "msg_queued": 31,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 38,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 410,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:14:45.317Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 3 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C400",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 606,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:38:00.512Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Compassionate Care Universal Compliance Program 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 4,
            "CampaignID": "C514",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compassionate Care Universal Compliance Program 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 188,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 518,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T21:43:23.640Z",
            "PresetMsg": "REMINDER! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:00:42.449Z",
            "CampaignName": "Sophie Q2 Safety EN 2021 None",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C278",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q2 Safety EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 782,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:52:32.222Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Q3 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 4,
            "CampaignID": "C577",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 477,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:09:26.859Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:54.764Z",
            "CampaignName": "SI Homecare- PCA 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C594",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 13,
                        "msg_req_sent": 13,
                        "msg_scheduled": 0,
                        "user_add_success": 13,
                        "user_add_attempts": 13,
                        "msg_delivered": 4,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNe34b819c9014d9b336b53c12517304c1",
                            "FN36233b82a1cdd7bc96ba7a8a47679253",
                            "FN698db51c447b9aa05e731e18fda5307f",
                            "FN94bdc903e84fb29497640335ff3ead59",
                            "FNd05c6177a34c2e5725475d64fa909751",
                            "FN6897a20a1e010de1539aaaa4b67335b1",
                            "FNcd2dda4347a95c50a9641f250d73366f",
                            "FN8d1867dd5316b4afb1f983d29410f055",
                            "FNa8e238a99254ea0e7ab48d920aecc029",
                            "FN35293d6635003026e2338aab7e4c804d",
                            "FN80c2e84bda6b24e6b5b44493eee9930c",
                            "FN08da86bc48a7ba9dfcd19a8f2085ba29",
                            "FN18760fd64467fad7f9c09b0a1fb136c2"
                        ],
                        "msg_queued": 5,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 13,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 283,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:05:38.091Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:45.765Z",
            "CampaignName": "BrightStar Care Program 2 - Experienced Aides - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C474",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 2- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 1 2",
            "TimeCreated": "2021-09-13T14:57:01.238Z",
            "OrgName": "Community Care Home Health Services",
            "PresetMsg": "Community offers training online! Open your HHAX app, click the Nevvon banner (see: https://bit.ly/NevvonHHAX). Questions? click https://bit.ly/NevvonHelp ",
            "CampaignName": "Community",
            "FrequencyInDays": 4,
            "LangId": "",
            "CampaignID": "C12",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-09-13T20:29:12.197Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 227
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:08:22.703Z",
            "OrgName": "Angels in Your Home",
            "PresetMsg": "RECORDATORIO! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T17:00:47.457Z",
            "CampaignName": "Angels EVV-HHAX 2021 Spanish",
            "FrequencyInDays": 2,
            "LangId": 149,
            "CampaignID": "C96",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T15:11:03.929Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": false,
            "OrgID": 206
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 8,
                        "msg_req_sent": 8,
                        "msg_scheduled": 0,
                        "user_add_success": 8,
                        "user_add_attempts": 8,
                        "msg_delivered": 4,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN45107d87b43969456b9c72778a171adb",
                            "FN9f00572034bdd93d2729ad22907e622f",
                            "FN87999ba78bd7300845bde8ed90931b36",
                            "FN72a1ded372f57d5fa12433460c323290",
                            "FNa51649e53a2c017b79dbc109ecc677ac",
                            "FNf16940d9aa6519179ab6b02aa5d55150",
                            "FN0800a82169a0c28ec28ebdaacc0d6012",
                            "FN429b1763a6dff3da2dc3802b0e4d0f79"
                        ],
                        "msg_queued": 2,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 8,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 282,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:59:58.899Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:02.027Z",
            "CampaignName": "BrightStar Care Program 1 - Experienced Aides - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C469",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T19:00:12.704Z",
            "ProgramName": "BrightStar Care Program 1- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 527,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:04:32.671Z",
            "PresetMsg": "REMINDER! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:03:34.503Z",
            "CampaignName": "FADMO Q1 In-service EN 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C126",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 915,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:47:20.342Z",
            "PresetMsg": "REMINDER! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:37.145Z",
            "CampaignName": "Community Care 2021 LPN/RN None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C78",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 LPN/RN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 263,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:39:21.243Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 1 - Haitian Creole",
            "FrequencyInDays": 3,
            "LangId": 56,
            "CampaignID": "C456",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 606,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:41:07.448Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Compassionate Care Universal Compliance Program 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 4,
            "CampaignID": "C517",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compassionate Care Universal Compliance Program 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 188,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:33:14.678Z",
            "OrgName": "HDA Care",
            "PresetMsg": "От HDA: срок вашего INSERVICE - вы не соответствуете требованиям. Войдите на https://bit.ly/GetNevvon или здесь: webtraining.nevvon.com. Вопросов? https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T17:00:42.867Z",
            "CampaignName": "HDA EVV-HHAX 2021 Russian",
            "FrequencyInDays": 1,
            "LangId": 134,
            "CampaignID": "C325",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 727,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:17:31.162Z",
            "OrgName": "Addus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: ADDUS: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Addus 2021 - Q4 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C215",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T20:28:41.940Z",
            "ProgramName": "2021- Q4",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 165
        },
        {
            "MaxProgress": 99,
            "ProgramID": 925,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:51:10.617Z",
            "PresetMsg": "RECORDATORIO! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:11.353Z",
            "CampaignName": "Community Care PA 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C83",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA Class 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 4,
                        "user_add_attempts": 4,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN68fd396a35d4c916339ca72ed81d2bb2",
                            "FNe46ce1c70232f451e57b80350a7ef276",
                            "FNc3e686e16a423d67656dc2f8f5580cfb",
                            "FNef4ffe9c061109ccd4570cb9e14fab8c"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 653,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T16:05:05.771Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "НАПОМИНАНИЕ! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T16:00:30.007Z",
            "CampaignName": "A-Plus In-service1 RUS 2021 Russian",
            "FrequencyInDays": 5,
            "LangId": 134,
            "CampaignID": "C387",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 RUS",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 468,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:52:31.738Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:50.868Z",
            "CampaignName": "JFS Care- Optional 1 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 5,
            "CampaignID": "C549",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Optional 1 2021- Spanish",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 629,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T23:02:22.539Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:55.204Z",
            "CampaignName": "Caregiver Provider- New Hires 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C531",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- New Hires 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T16:36:44.672Z",
            "OrgName": "Friends and Family Home Care Services",
            "PresetMsg": "来的温馨提示: Friends and Family: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T21:00:49.402Z",
            "CampaignName": "Friends and Family EVV-HHAX 2021 Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C40",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T16:52:39.464Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 229
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 17,
                        "msg_req_sent": 17,
                        "msg_scheduled": 0,
                        "user_add_success": 17,
                        "user_add_attempts": 17,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNdebfe0c4021a5844b0bff63e9ef364f0",
                            "FNd2a5d5c8cb1234dd3078ce60f2f5ca6e",
                            "FN341bfdf8cd382ff216d38f10dbcfa735",
                            "FN6e8030b8d4962c1336fc1016cb117f61",
                            "FN9bc5674a481592a00216732d42bf7ed0",
                            "FNa499540f11e9c42d7f8d512714633841",
                            "FN047f9bb24e4e37bdbbf0c8de2c8ae8c3",
                            "FN98dae91bca892bdabd7eb967b6fb9652",
                            "FN09d64e2b821dbf0f20c6cc7b1afcf1f7",
                            "FN7bff89d691345debe2db852e1916bf90",
                            "FNb71b44df208a692fd03cdc039351fce2",
                            "FN51b1ce4ba2dea928b1181933abb5468e",
                            "FN3f4bff2df921f134d6c8f2625f588ede",
                            "FN61e41950a1531fd0432554e345916a72",
                            "FN5cacde9f53dc8ad194afeaf1004cd2b9",
                            "FNc870184ebe3e84c8b110388107623d4d",
                            "FNe59fa395a2ec2b176906769973071c45"
                        ],
                        "msg_queued": 17,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 17,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 791,
            "DaysOfWk": " 1 3 5",
            "TimeCreated": "2021-12-10T00:02:31.064Z",
            "OrgName": "Paramount Home Care Agency Inc.",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PCA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:01:45.503Z",
            "CampaignName": "Paramount PCA 2021 None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C338",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Paramount PCA Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 260
        },
        {
            "MaxProgress": 99,
            "ProgramID": 760,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:45:22.078Z",
            "PresetMsg": "REMINDER! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:38.868Z",
            "CampaignName": "BestChoice Sexual Harassment 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C107",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NY -Sexual Harassment - Advanced Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T16:52:04.806Z",
            "PresetMsg": "REMINDER! Friends and Family: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Friends and Family Home Care Services",
            "campTime": "2021-12-08T21:00:00.267Z",
            "CampaignName": "Friends and Family EVV-HHAX 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C42",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 229,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 156,
                        "msg_req_sent": 156,
                        "msg_scheduled": 0,
                        "user_add_success": 156,
                        "user_add_attempts": 156,
                        "msg_delivered": 60,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN88d33d8575f7a58b1a47260b3efa0d30",
                            "FNe5a6deab379633d65b354fbb97e815c8",
                            "FNa297974877f66eca6c61055634627cda",
                            "FNb0e58616d0dbae97f17a5aae57621045",
                            "FNef73ea172ef7ec74120f6f73c62246aa",
                            "FN9c2680d857c1fae68f71fab0e8d9bd99",
                            "FN17974fc20dd7eeb27903dd8fdd275197",
                            "FN3da5d4676cca83d2e760a882d0f14b2d",
                            "FNe74c9bd2fcf5b8e1515f1fa4d7b162b1",
                            "FN3bbd8c706c991a9a7d0682eec1950f79",
                            "FN22f147020b8e46e12517c379980c6ef0",
                            "FN0ce29f778d9705a1d0e6501beca5a5c1",
                            "FN076b06a576b3678af15a024c874533fc",
                            "FNc82f61d7821d9237dfbf1a8ac496c0e9",
                            "FN73693b343440e0fa68fc9da53d9d0a68",
                            "FN6a2de05edb47525bd30be037f3a0f74b",
                            "FN305388bb4e4d286845344c361f917542",
                            "FN703864038857c168c6d98619dbf24f46",
                            "FN4ec8b2185baf91de9b4bfc2c23dfc1f5",
                            "FNe7dff67d3fd8842a87618fbce4b08ea0",
                            "FN7760bb005e6b706cc175614b07f3746a",
                            "FN3bd82dfb02184bf54f5c8177bc1213c2",
                            "FNe41cc3dbc6542369e4db581e8b9a7dde",
                            "FN817e90868ca81348b4e374854e3a7b9e",
                            "FNf079d01146c27250958a89bc980882d3",
                            "FN010eb68282630247aa18970fa153bf41",
                            "FN3c467c1fe8ba691493fe14b442069382",
                            "FN9a60ac8b0191df01bd7ff5995e635147",
                            "FN11b24102e72110d9cd959a3c7e7101fa",
                            "FN60bad28ac47bd6632b10965ead3b222e",
                            "FN877b27db6b207900f1f9b545aaec9344",
                            "FN1f4164a03ec47a424d657703131d0a5e",
                            "FNaba12c87005ee7819a6d53599337a993",
                            "FNbd9115a7ee58be07ec77b2ea09e58a55",
                            "FN66a28e2c3d1febdc0c5c5bc1d69fcfed",
                            "FNd756ea50a18bf6a3abc5c6b72a826075",
                            "FNc7410a7aa631aacbeef2abac6c34b49a",
                            "FN42cf8fb7d66f60603a8db4d0b36e9ef2",
                            "FN534f313551811b56e0075995e424dbd4",
                            "FN6540adc7a0a77a0b66a002b911ba0a3a",
                            "FNa612b8446068ca26d51872ef6d066917",
                            "FNff6f2b14835742b9698ea2ffe7b413a8",
                            "FN6b2a4b8a0c9731c0aa24adb19e849952",
                            "FN6e4d075482f531ad6fb745b7cef3c8c8",
                            "FN6432b60cea368ee1a72e4fda4749c21f",
                            "FN4ea26b469ca3c56dda7c5ffb631f42c1",
                            "FNc2489282ec3d647b412dd718919fd5a4",
                            "FN470a287975f9a8890d4f3c0859cab039",
                            "FN2c4b0675ac6a75dbb39d03a8e0b0b06a",
                            "FN88509d2b9e30916c191a92e28e0cfe45",
                            "FNb99e7e705f68bc85302050998fdeefaa",
                            "FN7cadccb511f66f8f47e3941875dfd7b1",
                            "FNef8f1d960ff4c21c5e3daa5e41d898ba",
                            "FN1c870c951bbc33e2f2163b7b1d7ba682",
                            "FNc9384c4b4527ff0f23086126d3e3230b",
                            "FN66cf8a762727bd4f2a1d721840ca4d0d",
                            "FNff89681032c49d517714ce5dd3752bc5",
                            "FN7afafaf9df86aaf2972fffe76fad0a08",
                            "FNcf37d5b11ffa7c4c7cea72effba01610",
                            "FN930a95cbebd3aa78d6c916ae0fa21cd5",
                            "FN74df1c56d0447fe56e4463a8ef1cf5a3",
                            "FN07c71b9c86c4f43fe0c0bdfaaf07f612",
                            "FN06749f5c17d9c5271bc78caed02145c9",
                            "FN5ef580805efb2470f690dd9d83b9472f",
                            "FN9756c5ecced7fd5693ca61dcd8378dad",
                            "FN4ece0b46a1b39c71c18621e021dcc63e",
                            "FN0283d0c6bd4fcc1927f979558b595189",
                            "FNcaccc3fc982e3542ef65ad3d2aea1c2a",
                            "FNe02a49d0c3d3a08668f91e790075d405",
                            "FNce87e09889922d00d909f8c3e5748dba",
                            "FN03b00ad6525d8832f3fd4fd3cf5fd550",
                            "FN3e9f14c85496b2b19d54328ac5b1772d",
                            "FN2cf46164c637b45a6911016cbf81e287",
                            "FNd435363f2d2cda7a9428867951c57405",
                            "FNc82c34206cb9ee13ac5c04e0ab4c4834",
                            "FN04e8916120ed40d631e6fc98d82f96fb",
                            "FNdbe28ec18d51ac36c3af957960795765",
                            "FNee4d71a3051144fbaab08bc2b1a3aaaa",
                            "FN9ebaf9931e1c05624ecce608dac6bc2b",
                            "FNabd3a8c27a36605241a814949e25826b",
                            "FN73f523f62e1967bd8daccdf711d28c4f",
                            "FN3a4a17e77458d6bb9fa7f72f6d2168b4",
                            "FN64804db05538227c51f475fb531131d8",
                            "FN16f65185753228fbe6477fc55d09940d",
                            "FN2e6024a9326319dae21eb78803cc363b",
                            "FNa72095f9b489305acd7ec0c1ccd0cf90",
                            "FN89796ebbcef255430f646885bc45035a",
                            "FN3be865d1d22c3b4488527b8241eaf3b1",
                            "FNe75c09a431bf29eacbfd6f4755161620",
                            "FN66534807c4483c5b111afe4632bba26b",
                            "FNbe146f16c10472fd63d437ff32873c74",
                            "FN4ed8a1aebbf384d3f013157245688157",
                            "FN813ca20961dafb3a1514a5fbd0de1a63",
                            "FNcc1c49ab824a637b2d17ede8a63b15c3",
                            "FNc87db19917fc2fa763441695044dc72a",
                            "FN1353194008796d6e45f775e13a72b0ba",
                            "FN206714bcd4ac865da366eab59983b757",
                            "FNf2c18b893f73c95febb2836a9fc3019b",
                            "FN27693e36f14b229eba1fa1723d4e34a8",
                            "FN4170c60b5f8afe8e13767b460855a765",
                            "FN33d90c2949dba455cd2e6b6939428c49",
                            "FN49e145209fddb750438a07c0da5da68b",
                            "FN5573fc23cf77d07dcc24efcb1f354327",
                            "FN44ebd15c5bc9dd99f6dcc1b06c152389",
                            "FN16573afcc6b01cd2a386a67546e451de",
                            "FNfd7bab7f58f659364c4a91cdc4ca3e5d",
                            "FN72fdb00609852338d723fb31f1cfe79d",
                            "FNce189d31bbedfdf57af1d95a9192d0f8",
                            "FNcc1c2a68695fe1bb8f69183ff2073930",
                            "FNcba5f8f3ac57b8f15d5b220419e6c7c3",
                            "FN1459e3ab631eb3c125a34b055ff9cb00",
                            "FNd814a14261db8448bfa98e91a9f2f337",
                            "FNf85c13333830f6643ae998ed1ddaa776",
                            "FNe7d612d49ecdd14a9c8fdc674ead5f06",
                            "FN81ba8fcbc5ae8b05635035c7abc65dca",
                            "FNf7f1ab6e2917e15dca1d16610c93de20",
                            "FN34154f20abab8f5de250fe4e1963a088",
                            "FN2ee4fe34986061570e6bae2f3f167ce4",
                            "FNf1733430167b475e91533f302cf3b927",
                            "FNdb34f8fe3f72f9aebe4c614bc1d05f10",
                            "FNc4db456eada2ac8868cd7cfe611126d3",
                            "FNd48129e86df09d61a452812ff504f282",
                            "FNbb82c8b5abe37cd946768f3fa845ac33",
                            "FNd15cb14b170c746727894284d63e4cb6",
                            "FNfde551d15279af4cc4fb62c1dd9043d9",
                            "FN095dc7052ef3a3d8fc5f965d632e2155",
                            "FN699db69f9eefbbda5c782de856f58b35",
                            "FN4724134a6dd07d2f05a899e6a8967359",
                            "FN1a0b97b9a23ab77b20a59ed8e37b4f8f",
                            "FN6d3aa47273e158b4cc8484276532fe8a",
                            "FN96a7fe124623a76ee07dc150cf2225b5",
                            "FNe96dd6c1babdcd31274005961581a0e8",
                            "FN04f78debc3130b1f87f9da4128ef9002",
                            "FN29581ba1d420ef3a9ba619d0df244860",
                            "FNa0d359a6b60ea02ee3b4d120176b0648",
                            "FNd1839b2d54bdcb4c82365e5469b07201",
                            "FNce02b5f43e6d543240dbe109a01744dd",
                            "FN1e4722f33ad3867300694cf9505f31a2",
                            "FNcb449577abb457ddde4786bbc076516b",
                            "FN4fe79ab4dd524b1a12575e5436b17de0",
                            "FN78cbf040f833656637da42e67a30b5d7",
                            "FNcd950622ed501280152fa33b7005dc07",
                            "FN342438d84214b9d1e91bb6a79566fc2e",
                            "FN72dbc2e7742d285bc027f153caa546d2",
                            "FN56adbbffa28044ebb3c23fffff7ee4b4",
                            "FN6ed28c2c3aef5deb01fdbaf52d2216d7",
                            "FN9324bc6a38224a5b423a9ad886a4dc98",
                            "FN9d3e3d3b182beb0f643c94b9db214641",
                            "FN47900fc799e1b4aab41435d1afa554bb",
                            "FN118f7fc69a38877a4bd7a2a79972f346",
                            "FNdc6792d32bfbc2ce03f97e07ae0a7573",
                            "FN6f75a501927ad1020a8c323bbf29a009",
                            "FNbe150de76e401ae650321fcd35b2fc13",
                            "FNe1b5271fdac89d069ce33af162f1ea84",
                            "FN7da81db67ce27f0a871c3b77b71262d8",
                            "FNd1875ae124af95b23ac036acb0d1face"
                        ],
                        "msg_queued": 85,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 8,
                        "numOfUsers": 156,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 791,
            "DaysOfWk": " 1 3 5",
            "TimeCreated": "2021-12-10T00:03:38.498Z",
            "OrgName": "Paramount Home Care Agency Inc.",
            "PresetMsg": "来的温馨提示: PCA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:03:12.554Z",
            "CampaignName": "Paramount PCA 2021 Chinese",
            "FrequencyInDays": 1,
            "LangId": 30,
            "CampaignID": "C340",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Paramount PCA Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 260
        },
        {
            "MaxProgress": 99,
            "ProgramID": 715,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:51:30.897Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q3 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 7,
            "CampaignID": "C246",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 782,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-09-08T16:06:02.481Z",
            "OrgName": "Accredited Aides-Plus",
            "PresetMsg": "From Accredited: Please complete your inservice training ASAP to stay compliant. Sign up- https://bit.ly/GetNevvon  \nQuestions? Click https://bit.ly/NevvonHelp ",
            "CampaignName": "Accredited",
            "FrequencyInDays": 4,
            "LangId": "",
            "CampaignID": "C9",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 133
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 684,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:25:09.741Z",
            "OrgName": "HDA Care",
            "PresetMsg": "来的温馨提示: HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:33.618Z",
            "CampaignName": "HDA PCA 2021 Chinese",
            "FrequencyInDays": 1,
            "LangId": 30,
            "CampaignID": "C314",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:54:29.030Z",
            "PresetMsg": "НАПОМИНАНИЕ! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:06.583Z",
            "CampaignName": "BestChoice EVV-HHAX 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C112",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 615,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:09:16.828Z",
            "OrgName": "Penn Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Penn Universal Program 2 2021 None",
            "FrequencyInDays": 5,
            "LangId": "null",
            "CampaignID": "C555",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-11T01:12:23.381Z",
            "ProgramName": "Penn Home Health Care- Universal Program 2 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 190
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 3,
                        "user_add_attempts": 3,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN8fd865575e67d654540dd9dce41a14ec",
                            "FN4d9ef8aa4d17d1ac8e7004ef29f2403b",
                            "FNfe11dc4ac8e98fb9c0c2f6e0a7ed6643"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 408,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:07:47.287Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:36.199Z",
            "CampaignName": "Recco - Class 1 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C395",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 632,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:15:12.648Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Ideal Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Ideal Care Mandatories 2021 None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C559",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Ideal Care- Mandatories 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 194,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 282,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:03:35.353Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:46.345Z",
            "CampaignName": "BrightStar Care Program 1 - Experienced Aides - Russian",
            "FrequencyInDays": 4,
            "LangId": 134,
            "CampaignID": "C472",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 739,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:35:23.632Z",
            "PresetMsg": "\"URGENT From FADMO:\nComplete ALL In-Service ASAP. All training is due by 12/31/2021. Get started: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:45.054Z",
            "CampaignName": "FADMO Q3 In-service 2021 English",
            "LangId": 41,
            "FrequencyInDays": 1,
            "CampaignID": "C152",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q3 In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 725,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:29:18.492Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV Training - English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C602",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV Training",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 290,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:36:27.019Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 1 - Inexperienced Aides - Chinese",
            "FrequencyInDays": 4,
            "LangId": 30,
            "CampaignID": "C494",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1-  Inexperienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 477,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:12:43.306Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:52.242Z",
            "CampaignName": "SI Homecare- PCA 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 5,
            "CampaignID": "C596",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 874,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:45:27.249Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "HHA 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C226",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HHA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN066a9f55310739d23cf795151b8a07ed"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 705,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T00:23:30.376Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "REMINDER! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:22:48.553Z",
            "CampaignName": "Ultimate In-service 2021 Haitian Creole",
            "FrequencyInDays": 3,
            "LangId": 56,
            "CampaignID": "C348",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-service",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "ProgramID": 760,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:55:25.742Z",
            "PresetMsg": "RECORDATORIO! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:34.381Z",
            "CampaignName": "BestChoice Sexual Harassment 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C113",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NY -Sexual Harassment - Advanced Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 148,
                        "msg_req_sent": 148,
                        "msg_scheduled": 0,
                        "user_add_success": 148,
                        "user_add_attempts": 148,
                        "msg_delivered": 109,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN1e52427c60523d234f89efe8b079aa32",
                            "FN86deb52cf511a921d6b8635e99ef33aa",
                            "FN8f48b58e14cfdc82433c4ab52c5d32db",
                            "FN69ddaa532a8daac9d4596392e470fe5c",
                            "FNe7a8e1da84d88df1de5c3959200d0eb9",
                            "FN02e6ca08dc0b1d0ca4b5575f4117facd",
                            "FN60262f1567f7fd286294200308d7ede5",
                            "FN7a2a7fc7919617dae0d49292d156fec7",
                            "FN7e87f094ed667bf3df9d21b9d6e36da3",
                            "FN08c1dde4e465c9a049e2729ea36bb4a2",
                            "FN2548920f515123fa15485843161ca5bd",
                            "FN02db1629b5494f29d23d51b652ef1689",
                            "FNfaa9ff2b48903d3218794d38ebf9f684",
                            "FNeeb0d96c35fd24a7c8e482031f374284",
                            "FNe1397c11bf1e97cc59ccde340c06c6e1",
                            "FN8ae1cf55fce3740d0167b668aac19c02",
                            "FNeede37aa1da73083bb351cd951c85cca",
                            "FN45c148cb9c60a6b66472cf9a35fae61b",
                            "FNe0b41726909b2e3bfcf6d33eacc02e2c",
                            "FN60dbc2bf8ec1475507c4db426463afef",
                            "FNbf7d597f678dfa0e5446474cd7767540",
                            "FN29389e04459e7ed033abbd52d8e5ee0a",
                            "FN4d19a1e3382a1cb03f3a3004584ed607",
                            "FN1e9b3f636d78cd0b08318dd5b1ab3db1",
                            "FN9aa92443aac7387f0f417b7f3262f8ad",
                            "FN1cd72c8434fa644f7d768997176936ec",
                            "FN725607f6ffd59edc0b86ba43d2a35b04",
                            "FN2b49a8f7984c14c5f856afeccbf08c22",
                            "FN5732f3b6fe1a69e4ddc4eba7bdbb1fa8",
                            "FNe568ee17d26d43162700900f03201f33",
                            "FN2bdd842af50bd4179697ac65191fb69f",
                            "FNe3ad7a5c66bbacd354a2b3d1178fc243",
                            "FN8a2031d109fee3caba30ad17c511680f",
                            "FNf6cbe79ddb1b13163ced726d662f1cf9",
                            "FN5eb95f83706b25988ccfd59ff238c656",
                            "FN951480300e7563196845a4a7171223b0",
                            "FNabd88af38a02ad92c0b090d3c95fec6e",
                            "FNcba48b1e79865f9b1c2baa9ca335e5c4",
                            "FN5bf1fb377622943ef0ef169e0a01d503",
                            "FNcd09db1fd82c897b92af4919b6f48286",
                            "FN70a861afc4c8a5cf5ed41189588e8e1d",
                            "FN4c6e3fbfd808735cb3f0b85ce93b66a8",
                            "FN9f8d98a083374d9f93835f9720dd0c85",
                            "FN667e94db2f8d50628dce25c342b627e8",
                            "FN39f270589cc55c5be5d2dbdff5cb8d18",
                            "FN39f50a14be5d1d934c7528734002bddc",
                            "FNb4754baea67f5bb7a04a950f050a5473",
                            "FN9eac0ebad51b39790b8daa9661bb7ca1",
                            "FN1e70845ac6d6567580252314329edc09",
                            "FN7ddcb16c4553f78b0f799b61455e9898",
                            "FN9168feccfd8f60cb38342729cb0d96b9",
                            "FNb951ddcf7b0e12cfc5455d921fe85d9c",
                            "FNbcd94105d368ff95b85fc61a89f80ba9",
                            "FN3c4a9566f04df566bdf797d658720f3a",
                            "FN0f3b4437c6de50540e645f6e67b09e96",
                            "FN025fb7c1977af32ca84ae29b67bd5cd0",
                            "FN103585748c64c9c5f294c2453ea443dc",
                            "FN79a3d80699928f146858f391717fb923",
                            "FN136b120f71630baa309cb8268f8af157",
                            "FN4ae934534b71b52a0ef8da8cdbc9edf9",
                            "FN086cc0521725343244c7ae9a0673d8b8",
                            "FN44cb68682d927f9fe048b5b9db381e35",
                            "FNd216aa38c57f6db33e86ebdd56ea0ce8",
                            "FN3f2c5375229bacd2bc0aa9719bf41000",
                            "FNe51f92be58137e7b7662afc516cbe094",
                            "FN1d613e2ef687aea7dc2c19853c49fef6",
                            "FNfc6bf9886606101e9daaa7b092edd5ec",
                            "FNd597212469e1925ab70579ba29846669",
                            "FN151b20c4303759c47c698fc99be95431",
                            "FNa098cd6ac5d86ecb170c9f25887eac94",
                            "FNa70e931a83a29b8b19f6ae097f917b22",
                            "FNbd92bf5a7a44e74e4e0c681518c5ba0e",
                            "FN55811553f11e57a82acaf3649960cab4",
                            "FNdc7d703f50d879e93e35a01a893395b8",
                            "FN7711d099bbabe582a9b7c276be2cd5b1",
                            "FNd6fda60c5c27d16aa32b03a85606f435",
                            "FN98ee62a3000f5d39ad0416b24574505e",
                            "FNe217e461febfa4ccb654401b69c7a5eb",
                            "FNe19907427c201321dc3aa805d574cf7e",
                            "FNe0184db5ce2aa191d6556277f8c06f64",
                            "FN6abcecf8b2c0f45e6d2aa170e500897e",
                            "FN59199255ac9195dd745bd483456be46d",
                            "FNaca09ca34ee98edfb0d555035b5f81fc",
                            "FNd11566d55824d1ecf79b0af18678e306",
                            "FN064972bd9de0cfc8eac34f310a89bb2b",
                            "FN3cfcd91ee46fd7801383959b4010b2f4",
                            "FNb44909f4764798c735379fcad25ce2b9",
                            "FNcabaf720f3f77fdc1d2b8a65d63c0662",
                            "FN2d138b4036220ce52e05ea44e4c52ddc",
                            "FN76fed5bd590744f386ad1c2131a91219",
                            "FNc2c661cabb24750a3f398ad13c828ba6",
                            "FNbb7bbd05c72783e0c39bcf8c57efd71d",
                            "FN70911a4fca3084b57fa4bc6750ab96a7",
                            "FN27f524a19f5f59cfcfdb1ec1a072cbc6",
                            "FN4006bcdf331742f7516b0e9e86a2d6dc",
                            "FN496cdc44e51b1dc43a97a33154e99e3c",
                            "FNa4f5d007ff079f76d0a0ab910a41d3b6",
                            "FN3111d7b48646c3c85516c10ace58b12d",
                            "FNd0fb90fffb050f36fe73480b0decc9a9",
                            "FNe537535867d275aa0e5bc9ef3840cbfe",
                            "FNe6d1225b8e63c66f8ccc81f8946f2103",
                            "FN460e6e9643ef57e35bb0b9eb642e1bd1",
                            "FNb46c5328902782ecf589f054a1effcb1",
                            "FN9e767d6e1777bc2e036aaf9609765382",
                            "FNf9f2b46ad0874394e2864a1fd7f99a12",
                            "FN02b554403676cc85290cc8408e6ea466",
                            "FN0fc322facc2669a736c44a623b39b114",
                            "FNfc123094fee9960e089a9eacf5657d7c",
                            "FNfb76d1a69d6ac8a3cf658c16347dfa97",
                            "FN10007735e6f6a22309cb980b796c3928",
                            "FNacbb4bed25285cd0677b97ca2c1930dc",
                            "FN0d096f36ba75a95f71343ce0c024a1b1",
                            "FN25d0435001b744689eeec5054e879766",
                            "FNce9cd4e1a789ec1a7153ca1458e748ef",
                            "FN9af2b97a1282bb9a87208cbd211ca867",
                            "FN235d6d41a9be155bde8ab4608328a918",
                            "FN3edeba99798834e2ec53afae187b5546",
                            "FNe8e7b60b290ec5e2c8edfe43b590e827",
                            "FN257d109761814dc57626dc7ca667db2f",
                            "FN83de6cf0d9087be3645b787baae6c84b",
                            "FNbfad1ea86df1c713fb878e2e90d8e3aa",
                            "FN0cba35daa1d8b4012684bf7567689cc4",
                            "FN9dc2527b68667e9727fa0fd79c8a0e69",
                            "FN55346d994be42558661c250103e21caf",
                            "FNb7819cc9658c33eed0f8d9df115b3b5d",
                            "FN7b39221de20b88138a64b2d238adf222",
                            "FN41370e723af183ab82b987daa0467fb7",
                            "FN64154fa1c4fc73430bd769c5a993741d",
                            "FN11728c8e75044aee68ed86f46ce3c512",
                            "FN904b40c7e4abc954839452f221006951",
                            "FNdb1c20cdc7c7295ac7b3a27aee9f0415",
                            "FNc4fb17086505990d0b7f03e89767240c",
                            "FN20e682d85dd6cd5937e36dd7c7b37b83",
                            "FNc717adaba86ebf34b1ee90297ea41533",
                            "FN8e9deab24e7268c1d2dc2569f2bca61e",
                            "FNc68ff8e15e0970a770529e610ac832a0",
                            "FN9b7f8576eb4874f79d5aca2e399f6d72",
                            "FN9faa405341af4c25155d3234118074e2",
                            "FNe402f74bbb8b27e1ace351708d5d53c8",
                            "FN4ef693fbb2c8abceffc99097fb93b211",
                            "FNa6ca9203a789aa269fb1baca1534581a",
                            "FNaea0674ff9748931de6fa1654536349c",
                            "FN9d9f626ba5af4df9caadb41367330ed5",
                            "FN44cf3b01c868e5d1a400ead5d1bb94dc",
                            "FN7ad9fd4aad3e4942e4e2117166f22aa8",
                            "FN44da59b222974199aeef2ac8916e4bc8",
                            "FN9d4bde8d94bb7689f76da54374141b58",
                            "FN8ad0f4723b40674b376c25584b3c36d1"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 12,
                        "numOfUsers": 148,
                        "msg_undelivered": 20,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 148,
                        "msg_req_sent": 148,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 110,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNd52087684085d4372baeac6093afd38f",
                            "FNeda637a9eed1eb6574f4f448a26d2f8c",
                            "FN3eda3b218b2601b29960d6315ffa42dc",
                            "FNeb1338d7dcf076c5ab768f0d8f1b5b8a",
                            "FN59f5926dcf4a1ef7da0685d5fcb4657a",
                            "FN3e28984cd1e2ea93a1683618abd05e43",
                            "FN72dfb1d3c9f514a31f496860cb79521b",
                            "FN27d3accf5a275d4ab6aba4e8c99e6045",
                            "FNebe6ac525050ec1e8da39e80b3d1bb97",
                            "FN4a4458794a9c9aa28693237cc20b36c9",
                            "FN0aaffb1a8f04769c987c9e4ea062675c",
                            "FN27c330dcaafdfc07730633dc5cf70a07",
                            "FN04d54e2f6f9bedce0a505adcb0a0a010",
                            "FN327c3e06758594ef149dcf8eb372f157",
                            "FN902b100d97d86154f8b81082a09b7c70",
                            "FN9be7a75a7b67e29dca2f542523344060",
                            "FN1c79c3c85569797f30259353c021984b",
                            "FN0923eac03cb9c8ff203535c9302e1f14",
                            "FNa3b40d470aa471b0d6ceb6472514f205",
                            "FN3d1c4f2fac3bed78bd59a0add0f30495",
                            "FNf66b465f20fe7b7c01a3c3091608f705",
                            "FN6a24a01237a681fbabf26465fca012f4",
                            "FN05e487bf9cb1c43c14eb57b8a2e7d39a",
                            "FN1843c876c796916d20d2a669361066cc",
                            "FN5075413b892b5ec0cfdc18d747f776dd",
                            "FN3c8afe04b984e07a2e39150b22412713",
                            "FN1d7e17f004c0ecbc59fd5dc0c202a042",
                            "FN55f67e3e779118ef2338039933f2b246",
                            "FNf5f698cf685ba29ab6d1cc3d80d061fa",
                            "FNc20a9a1ab792401a576fb4d2515646d8",
                            "FNe8127a12ed661ba1e639695b8053a579",
                            "FNe173d4cd45d6e91394ec5959b01c3ed2",
                            "FNd22d6f80c827e455d30536d313aa16b8",
                            "FNff538265bdbeb93b15e6b841ea508865",
                            "FNe5c0df44b1453c9606e4b4b54c5700fd",
                            "FNeb32b629cd6e0165b7697fec801994cc",
                            "FN49045e95289fae4528d4b3cabe86e615",
                            "FNa4e8dc12cd66d6a9514111a69f1af115",
                            "FN8c9efa556ddebd30787ec5ff8bf6bd93",
                            "FN82751801677001a43d6baa98f1a362f6",
                            "FNdb1e1d4027102e1a84a893beb6496ed3",
                            "FN911bea529c66772e35ed025350573c4e",
                            "FN3d044f392f2284b60c86c5583bd0d6b7",
                            "FN6abf526ffba1a98231775d1857e4da2b",
                            "FN057dc53939d9e8d494676012c80e8285",
                            "FN79f4931ddeef0ba93e054c3103c1fba3",
                            "FN05232c4ea06bf374abac10bc52f4beec",
                            "FN82fadffebd0fed6d3e966e9ca5e9b781",
                            "FN2a3b2fc490ed299516e85fc4b22d0873",
                            "FN3aae7ac6312589bea4bbb67428520a8e",
                            "FNc4f9a7d9bbed7dabf30535d95a205054",
                            "FN9b4af3277d6377f068882064058d2efc",
                            "FN07bd1695a8b14f3407a768a0504193b8",
                            "FN0c1f37845311733917e48c6e4daef62b",
                            "FN35de54a2c2ae76e0f7da741584a48718",
                            "FN368bed49df20f4f8ecb8a7e21884fde0",
                            "FNec7be6e1aaf7e7da590e1bf4a983917a",
                            "FN68271f43d6ba649c063ea58148e28f73",
                            "FN368d8870299e47091855d90ba4bd32c4",
                            "FNe2ec6957a051310b28f3491da7f55735",
                            "FN1419bdd655531c832d3039c528c1e782",
                            "FNb494e4d24c67680b78ca906b0b493a59",
                            "FNda640ea60bdd32420f2d5739a92a6fbc",
                            "FN37b53eb5218e8b975847bfd24c0e8522",
                            "FN439ec6b33bd9543d7cdb00208bf7612f",
                            "FNbfc7110ab8a2fe7fcfaf87aeb859c506",
                            "FNfbe1133c0538752509bd5708252938ad",
                            "FN5d5f367373859da4c1cd0a7e35f15b04",
                            "FNca05dab956cb663cae2d1770564d6225",
                            "FN3fae11103123d710afbd21519d8da0fd",
                            "FN62d4b11058b44ade4e695c3d96d4f2a5",
                            "FN086ac562cefd7b44490999e9b4b63f98",
                            "FNb84df2408dbfa55dfd5020d645b0979b",
                            "FN28742540e4e3b8ea551a919b76a01eac",
                            "FNd7ce16f85dd9feb9391e0d793c2e78c1",
                            "FN9c9b75ec255e6137a1f8ee42d8c8f1ca",
                            "FNae7981045e9d619a9e9efab9567ab7f6",
                            "FN32bd5cbf7e061d83edc1f8c07945fdd5",
                            "FNf7fa998abb216f02b4859947938eab79",
                            "FN8f2850e4250c3ff1df51a5c102c54579",
                            "FN140b0a8f33010edc4ca01e0754e58000",
                            "FNac5ef410cc631c526a807f82e0aa3c26",
                            "FNb8abeed1d406e06d41490f35782842f6",
                            "FN195e9378303c68ffa593bbdcbc0c631b",
                            "FN7d7998ecb024d606096ac611886cf40d",
                            "FN4786780dcac2fd831eee396e86d116a3",
                            "FNaaf4caaa0fae0478c5a81ab632787830",
                            "FNf46258d849c90e306b0b7affda52a139",
                            "FN342030a0099096070057e637ed16cc64",
                            "FNaa6b3150bf708493d89933b4c5be7c1e",
                            "FNb4668c4dd2879b3db56a7da80d714e65",
                            "FNb2a658581c101d679230952552e16176",
                            "FN85aa579d4d1a9c77f4e198e1c1ae563a",
                            "FN583f5dcbae22336666d5aa4184b3b312",
                            "FNadfdf193f0d404c02c63203589b4f96b",
                            "FN475af7b29de0ffc12780f327a0571a07",
                            "FN74eb4be52cc35ab63833bcf9f4699fd7",
                            "FN159d70c3102d07dabf7fd8d609d15c1e",
                            "FNac0ed0b57995968e8914a0021fcfcb5f",
                            "FN08dbef3a24a7f8c122f8586478563f49",
                            "FN22a72a24042de961fb39e558c6eb72d1",
                            "FN86abd04e81503d5ed2f4d66e7c60ac54",
                            "FNa8d299ebe8e1519de62a1379eb9350a2",
                            "FN5600bc3b5ded2d4fb554510d3a62b7ef",
                            "FN2d3258af500d851b13991ff081b775ab",
                            "FNeb8ff69982a58788d481d6ba5c40b8cf",
                            "FN1865650bea4d7ced086c4ef01a80c91a",
                            "FN92d450ab484ed8faf3b2088c3f7744fa",
                            "FNb1f01307ceb4ee769d289bbbb94081bd",
                            "FN3c28ca471d1cc22b7602bb9012d0518a",
                            "FN39b0f7c39b25699d7023f0cd6b2d0380",
                            "FN2b2f68144c4ffd37b6a2a330a2c9e64d",
                            "FN51361fea1dc844c686d24f030be0886d",
                            "FN9390503a65b65a10d9b59415a82f7a5e",
                            "FN4fc3d644f11de878e654dcb6988c5ca7",
                            "FN8d188c8c9c9254de286b058710d11296",
                            "FN6cc682073f2f3b43288d15d572b9b83c",
                            "FNbe493338950da0ed3de48fc0896469d0",
                            "FN94238a99c1f281cf90822833edd4f27f",
                            "FNe88903c39afcee26aeafda6111ccaf14",
                            "FNceab3e8a81bfd1978f138b48cfd2510c",
                            "FNa6e4606a193c6340008c6dd5b9ba9a0b",
                            "FN743e1f7ea38800292c9640dcbd9bb58e",
                            "FNc18245c2d28df4bcc0618c9d3ffd9980",
                            "FN64e4cfcacf00faca9cd4e5170d863501",
                            "FNd5bb2ca87d31b80c69d9c17426cdb82c",
                            "FN146be0b4872eaaa216aa9607e98c50fb",
                            "FNbdd22be5a11c41365397a9808b3696bd",
                            "FNfa6f32f552b1600ee2f59616489cba7d",
                            "FN27511a723974041a969e9d43422e2147",
                            "FN632b135f83fa7ccd245b30099f826edd",
                            "FNd1f42547a8d204094cb3d183bdb4ace9",
                            "FNad3593277ca40235c74bcca472838051",
                            "FN0f98796d414b54ddeef00707447d0a0e",
                            "FN372b67e5dffb9b9e6373318ea400fd1a",
                            "FNbd52365ccbc84b3503f37acd32c4eb8c",
                            "FN6cd1921f09ae4c370a975159991702a1",
                            "FN6035266d45cfc7815898bfcb8fc51386",
                            "FN3595f31eee01025ca0517d1ab403d98c",
                            "FNa6a9d5a39d26cf09b1693706365bfc2e",
                            "FN8b86bd16e415f8b20334ef2707d10f4f",
                            "FNb302f8165825bbf8f29529988f8c072b",
                            "FN766df24f8631c9b7fdaf165595b04b54",
                            "FN725f8b2655d0b30dbe8282c379fd7570",
                            "FN74bb3ffc05869963c37a70c77e0ef5f3",
                            "FN24fa0ba24155a0978fab5dbc971e8c13",
                            "FNa9a280915e691ec2f77354952c47eb01",
                            "FNc0ff9095210ba8446d81bb91f27cb69f"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 148,
                        "user_update_success": 148,
                        "msg_sent": 11,
                        "numOfUsers": 148,
                        "msg_undelivered": 20,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 147,
                        "msg_req_sent": 147,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 111,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN34af426d1f8129621a5b25879674c1e7",
                            "FN58d1c87a840ad547e3991f1887078a9c",
                            "FNf4d3e7930b042fd3df8dc7e76165df4a",
                            "FNde304161ac7926656f0b617871fb7c06",
                            "FN7b20f3bf3f2eaa1bccb0e23f7e46e309",
                            "FN193988eef19931b59a8eee91cd576ef3",
                            "FN2348e412053dc7c9ca3e34da0f0a931e",
                            "FN7ef2c86e3a485e9d8c9c4de0391154be",
                            "FNf4107b87161674bccf4f85dee6aca428",
                            "FNc3fba20b7cf10d6e3fb1916c8c40e910",
                            "FN59ea3d61dfcfb6c674c8e1347e30cb6c",
                            "FN4a64faba7c7fe0cbd2a8a87703502f3d",
                            "FN0f8360a3fca74d661d761f07999777d4",
                            "FN57b2d6e2558c1391bb99e3e8567a0627",
                            "FN80770b454eadcd2693fe7e4f057f9949",
                            "FNf12d318e47f2191fbdd5a0f4f1610175",
                            "FN7382be2361799d497046cf0abf7891f8",
                            "FN7731405d99a358fad97cec73d2255ca4",
                            "FN742863aafc05c1456cb55b883a96e47e",
                            "FN5d14445133a2d5e8b60b68191e635c4e",
                            "FN4bf600ef7b03d14b60ccb2249ea5c2aa",
                            "FN79ebbd4d89b9546272ccba77f1623577",
                            "FN9f1b14971b4259254da44598b697d1df",
                            "FN7982a4349749322bddff9a603c6cb9b3",
                            "FNc9d2e4fc6e9b42b9408215c7040cf397",
                            "FN1b913f70b3667488e0d43a7e10b1874d",
                            "FN52e8ac93f46aae731e3138f7e9617d8a",
                            "FN9bfc4e2ba5661599b25c1ad552d23e4f",
                            "FN2ba57b7dcbb8b7115fe5e5dab86aeaf3",
                            "FN6588969746a765c16d3713c9acc9d357",
                            "FN32b35a30ae63fb4143cd52d2e8b258a6",
                            "FN5e66cc9abf62055f5ed28c0562550ac1",
                            "FNe97b527b7bc956374596497f4e6334e9",
                            "FN9e58be2279dbf9e45489fdad8c042983",
                            "FNc6d6b4b2f3d7002d21a94b2bf7a91b59",
                            "FN711a58c235f6f115ca0931764b7e484b",
                            "FN4b5c101700bbc9a28d2ad82aa9b02bf2",
                            "FNa500e6fb5ddd7bf536089093a28e6643",
                            "FN213b70f358d69093515ba75204291306",
                            "FN82da20bee4932d45664effc2857a9c1f",
                            "FNcac8908107690595367047f192a285d1",
                            "FNf4b884b602a7b966ce40d0f59ac15e26",
                            "FNc3cd4453c3df38bea4dc8c19e5a9f68d",
                            "FN1cba9208b8378b92327ef58aa880c9a8",
                            "FN5532a1d4d0f25485edbfa15a20f2fea0",
                            "FN2f1233fd8b8695860590d39e1adb471f",
                            "FN259e37aa709fec92e8920e59d64bfcf9",
                            "FN6dc31f909f2cefac94dd9214aa0fe41b",
                            "FNf31085a98ec9b4f60513414775ee8bba",
                            "FNa503bc90d854a606851d51cac2c15643",
                            "FN5265199c20656e556a1b4ea75e9cdf93",
                            "FNd452f68ff81216a01d3a1fe9e754c9aa",
                            "FN98b84c39552788795b89d6d185caa318",
                            "FNa24c464a1c648be5c7a3f19450d8566b",
                            "FN30d4f4971fec1b7bc8988bab67550ecd",
                            "FN037eb2515ad69dfdb5acca6e2c2c9e73",
                            "FNb48a5cc44765ae8690477a8f58ceebc8",
                            "FN849d574065954061d18e8701d493da68",
                            "FNd3c74a230cfbe6b3d18ccbdc4bdd7c0e",
                            "FNba5dd5f0689fa8295ccb33a9b87033eb",
                            "FN8f7d7c0f9ae432f179905e82583d6b3a",
                            "FN4a2a666f2e23a4a8600e63acdb7328a8",
                            "FN453c972fdff4b2d28b54c1e186eec979",
                            "FN0da7e4335b83b3064d0209455c06b8b6",
                            "FN34e11d2cf3e5b10b80e39e76b91ced5b",
                            "FN6559f04c87b273e2612ad841bd4e2eaa",
                            "FNab18db8b710decdcf7c5c0a10948cd1a",
                            "FN963d4bc2882946e5182ab6a16f76af38",
                            "FN89707c8273101658d4f80b5be92dd3bb",
                            "FNc4ccf5bba052a62a89937ccc3b3ec089",
                            "FN4e72a340c1feb5444f1cf6bb6814ad15",
                            "FN489a21459ae60291f9fa91660b68676c",
                            "FN13242d57335433a430bd0c5322f8385a",
                            "FN534e6341ec637f0f23db9c3219777651",
                            "FN2dc589bbc8e3a904198ab8b1c4863d80",
                            "FNd6a1d9713773dd3d3f2b2bcd3fe0e1ac",
                            "FN1618c71b12428cff64ba956abde3978b",
                            "FN9550527f0bb178ab80ac7297ece89afa",
                            "FN6fe662c5146fa4a755563fe586b3af78",
                            "FN47a23cc9f83829c227dccab4fc8883b3",
                            "FN3d3219cb48d16881103d38e3404c1397",
                            "FN0d960e922c5e30fcd55b92a5ba4d454e",
                            "FN310c3f4c99675fc3bf1c75a584b1acb1",
                            "FN6cb70289f8b6e2134740d94315b11c74",
                            "FN712b55f5e54828a2950c9a3faff0fdcb",
                            "FN6e76f62b2b58509a03458a1de1204206",
                            "FN2e8c9b80069b7f71c252d781db7a0c26",
                            "FN124ecf35ea24f60ea745086e143f2eb8",
                            "FNd2abe7953453f7cf82933bff7db67e3c",
                            "FNac51ff305d9db26863866462ffc9e2c2",
                            "FN8e97d2541ade58f05059471703fbfd4f",
                            "FNf24cdd666a68ce26818f1dc0f662485f",
                            "FNe68722381775c36846be0fda17898085",
                            "FN3f5706d04eba1d1be024e37dd1a9b9d5",
                            "FNfd3ac60762e581dd78c4a10c16349ea6",
                            "FN5ca549c7977d1e2fc1800d93770cba74",
                            "FN75376a5704acfcd552cd9e32da81c98c",
                            "FNe6982866917a674cc293a4179886ab50",
                            "FN33c1394a4c2950e5e071510ad4bdcbb8",
                            "FN018ee5292cd684eea7f8d1b4cdd84006",
                            "FNb560401c0c653594079d0d31dd0ced34",
                            "FN957baa25a7b0cb4649c51699d98339e8",
                            "FNb6b9c0e3b83444e19caf1f6c039d48e1",
                            "FN7cb005b687954e96b81deeee9d5217ef",
                            "FNd5d25ba6b529b7b39473db42951c126f",
                            "FN01f9f1475408bf117f8f375104d934ff",
                            "FNeae577e38d992e53eae2d137b7141bf0",
                            "FNab83588185a2fa35f2d3a59c7cec340c",
                            "FNb588009eb85dbab04f4199e692eba4fb",
                            "FNecad2f484af718bd490767a4c8b397f0",
                            "FN1b1ce03e6a30c6be749addf435039b92",
                            "FNd5e99e14bbba47a3700658641746f9ff",
                            "FNb77718b8a11fc10255762511785b728b",
                            "FNcc0953f47e4aee7ce7bc3b1b44e98c50",
                            "FNb78c57d51169924167587eccae6e2018",
                            "FN8ec065135dabcae2c177c826f84e8e7f",
                            "FNf58d07431061f947babaa27080632dd0",
                            "FNe4d8b219e6b96d263636eafe313f12d9",
                            "FN9457f2897e103230285e5947f91ea62a",
                            "FNd7abe9e34517c1be79afe746bf95445c",
                            "FN5c86f742fc6051a081cbd5d67e0b5226",
                            "FNa43006120fa207e1ba87e972a8d37988",
                            "FN158aa17b18c64c25c6c5c64d00a085a7",
                            "FNba1cef466ac1ca34b7b1b554057a6575",
                            "FNab491cdb049123a91cfe7bf4c714e35c",
                            "FN9a4fbad067d3ef6de3f5d3e06a9852ae",
                            "FNa55ccfb16d7d988f0760a3a06c888c8a",
                            "FNb6000db3503bc7229507bf1e757fb97e",
                            "FNc92ca2dc17859a8b3684251fca315b60",
                            "FN78d058e7a83d2d7c44f11db98e47dc3d",
                            "FN12c58e0d92e5e14f0ffc75492cc3f9f6",
                            "FN9c110ad23f9c5c906a0fc94cb1724551",
                            "FNcdecceba107e593df1cfbaebdedb7344",
                            "FN8e925bf98cdadd5dcde6c4e3c3f259fd",
                            "FNdc3dfb250b3d49a714bd6851146108a5",
                            "FN89c2db61ee5006863109c66412c8ad5e",
                            "FN090d3295861567132d116a9b78e165d4",
                            "FN7d3f84994b75d064c949debe8284dc5d",
                            "FN38590378a6521a398a7e6dee1f72755c",
                            "FNf04f9e94a6247d29bb19a6b4b9cd9cfb",
                            "FN58902c49451145b21278dff7770a7b9e",
                            "FNd6f351cf81652509f73ba7be8876d904",
                            "FN1e5cb3a24b2d23b5137f5cb9ac8d981d",
                            "FNba38881115eafa57836f82b8607a2fea",
                            "FN0e9f48226e6698239b31edfd8ea6507e",
                            "FN3ca68c7f2e4d5e332846b2e7cf36f605",
                            "FN07106a60fd3a9b60d4f439b8e041fa98"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 147,
                        "user_update_success": 147,
                        "msg_sent": 9,
                        "numOfUsers": 147,
                        "msg_undelivered": 20,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 147,
                        "msg_req_sent": 147,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 107,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN474ecb191921f122f5f826b5e91b1e33",
                            "FN1ecf762bf610d8a344f7ac58a7ba66f6",
                            "FN81aa8c812901455e4f800277b486fea5",
                            "FNd414ca2f6007268df4cd6957fe7c384c",
                            "FNf04fb7a43144b7c6bd81d8e95668027c",
                            "FNad1012995c071a8090733cd8435a6491",
                            "FNd81097d23d82bf8daaa02dac6b4179d1",
                            "FNc72c946aa06d34ffd0544fdb6bff9b41",
                            "FNe60a3f1b554e3c283cb04217efd9bb73",
                            "FN11b40f626aa4f85cf5f2c17ebdd04e25",
                            "FNaa470c7b92566c357fbc4c1da36d6661",
                            "FN4fdc73d575c950c4e53cce91c03576b5",
                            "FNcfc616d08912488540a8a3a18447e4d3",
                            "FN748798916c80cc1e4ed6ec6952eb7864",
                            "FN5dda1781ba677aa7f3ba583f6fc46a61",
                            "FN6092c490a89606594302018a262d598c",
                            "FNf09ffe9aa3e6ad941ab3c63a2d21896b",
                            "FN32b9a77d99b3e77809e92ada1e2b4564",
                            "FNfa7278252fe20a9af763b037a847b606",
                            "FN34d60e5067e2cbb239d7b8114c746534",
                            "FNe9d50e4883dd857d28a042b4b49f8509",
                            "FNe6bb3ef61c90f1de13cf79cec7734774",
                            "FNd602b93189ffea125d331dc30a764f6b",
                            "FNc674cead4b4f68013b8864ca1c71c12b",
                            "FN7d8d25b113bad79823e02e8ef9a5973b",
                            "FNe996c46669d2b4d92b6a48d5fb6724e3",
                            "FN110bf991afd45e95f6322cd81b7cf304",
                            "FN49ef1791825358afa78b6e260a9af728",
                            "FN239b95d9397dc6dd87159c1bf3b93a86",
                            "FNa4762f0fe26cd06417f815b7bb8519fe",
                            "FN8d90ac68da961c51177ae79c54a0b954",
                            "FNbed05ebbce36aeb9197ac1020bf73591",
                            "FN51ba9f03073f9f6b7eb14c8b668e3a06",
                            "FN9a861c19181d22c7afea47d911de0ed3",
                            "FN37261df05d8eec675cb43edddc1c921c",
                            "FN199972c2c85f4e3fb357531868c18e84",
                            "FN62fe3c808c84640c78e8d1b62bba0833",
                            "FNc77e6d7e1c7a5bf2c7f18ac03363a827",
                            "FN0de31262c464b1576624eb3c9ed91e87",
                            "FN1a5dcc0df97ae99cec271e8ed201cce5",
                            "FN8606dd1c7fbeeba1f0f1cf1c29635460",
                            "FN6f9725a340b1802d473a288b401659e0",
                            "FNfc0ffdb2a5aa7f87624175f444c7fe6e",
                            "FN0f89c404e8ec64b7cdb34ed4d475cfa3",
                            "FNfcb34b17ddee36c7909c53041b75c039",
                            "FN23eae8d9dbc5d28063da6e56e4c0fe7f",
                            "FN039e43582f5d55e5beaee42be71afd49",
                            "FN820e6857c572b9a2203340a96fefbc4e",
                            "FN31e4f7b26d9be67406acc33f364b3f8e",
                            "FN0679315815d44c252c8160913e39a666",
                            "FNa1bf05cb85df89d28b31bd7498cc39f6",
                            "FN0f2242d093c02ea5ee32ee0616e9213d",
                            "FNdb59632e8a43d5dd303b0ef187805f62",
                            "FNd79d189fbac3a8a2ceef8cd6b1025fee",
                            "FNd7a39ae5a81a3d87c426609bb7e099c4",
                            "FN42ca882f7d1894245ef053b17d94778d",
                            "FN1775d48e83f959230e0cffd00204dd32",
                            "FN358e64d2feb755f84742f31f5281386f",
                            "FNaa3cbdb9d0ee6eefc6bef42c6cccfa20",
                            "FN53c58a360e2af5edf4121cb540c2bbea",
                            "FNa5c74663b972ca62dd8c7f26ae5b549d",
                            "FNeb203b13355da17c3cba63dae734bcd3",
                            "FNa18f6d8b908b9e8cf915a9736bc34451",
                            "FN67a2b45afda4e7114eab248001fd3b1a",
                            "FN6df6f24aa385598917815b4b6633cbdb",
                            "FN574f3f7c2e23f6839ed94492222e1a59",
                            "FNfe990930e3a70b4ac6ff17ea1cabbc2b",
                            "FN849784c22f4b2b4ed683b0db02567e20",
                            "FNa02f5dfc49e8b0ae553bae1b35eb88e4",
                            "FN502c449db402f8d0fe3da8b085698107",
                            "FN9594ab4f7ee6d1edda2a86424c8cbed4",
                            "FNefcdf7b9c825461b2a47a69478110731",
                            "FN55fda0519b1adb50eeff424f2df85b4c",
                            "FN49ce8aa7f9a9d94c050402516582d1c3",
                            "FN5b307574d63fb5918ea55191b49378ea",
                            "FNc0e2d1125d148f440319f38d1b10a4c7",
                            "FNc7902b9826a8f3b271024793a74294dc",
                            "FNbe9142a74f9de919cc93bb25c00c65f5",
                            "FN887720fab8c98a1e902b804ba0df423b",
                            "FN8015d110cc825a777ff5e7b903f455f4",
                            "FN89235e7129b55c8bdbae74c5a4eb16eb",
                            "FNfef6855541bf3e18d0fa6ec84c4527d0",
                            "FN5e8fcec9f9b671698f9b7b1b7f517748",
                            "FN60a716edf7e52b1268a8fcc8ba134a3c",
                            "FNcd378b21c8f09b2aaf714711ed2e2ed1",
                            "FNd1ca5bb3ff49f4dabd62620414252bc2",
                            "FN133e06c088de93d4bf38d1c8ab0f279c",
                            "FNca0694410c496fd4a7c4614e7bfbb276",
                            "FN744e388d955be8d250b62592c4387d92",
                            "FN7050dd532893e7e3aea24bd03838d991",
                            "FN1a1398401dc4bb3052989b7d10ac88d4",
                            "FNafb12362072d288a2374fc9b7dc8e69e",
                            "FN6987c9f2e65c35471c7323a3d3fdec3a",
                            "FN189a23c94e01d73171f9583e98ff9e20",
                            "FNd2633f3d6cc2ac11d722b0937e790670",
                            "FNd069796a3a8eac32579027a3099e86e9",
                            "FNee4002291636b3df6adb154c967b7139",
                            "FN03f73f1845074143f988b66d5d38edd8",
                            "FNb6a564e7d9b76b3b28f75c1c2bb986ba",
                            "FN5431ced358677961acfb8395661bb6e8",
                            "FN1f0507cadcb343ed55d31b65d1c0a00c",
                            "FN47e0d28f9556779389d4c388f5c96cca",
                            "FNc06acf680f5a25d95dcd738f85203c24",
                            "FNc08f806af6f77e095b682b6f642a554e",
                            "FN32ec9e6c8fd456c697ed1c5c2960d54b",
                            "FN88e6eec584cd5e72f70415c6fd498f3a",
                            "FN07f214c681b67d695561e46d0bf40bd7",
                            "FN2827fec776a0f7dcb9d3360aa4539727",
                            "FN947823519472c7c6dfcfc95259291ad4",
                            "FNb79f9a4e55a67eb1497e745b09e41eb7",
                            "FNab679429870bcfd49b2622db3d055248",
                            "FN58e1b695ef385900021c1651f2587c0e",
                            "FN432b3b01ddaa184c1a85e82dda556291",
                            "FN90c631bc64c3121d29d44442354bf879",
                            "FN077f00bd749cb4b7987be6ca55fdc840",
                            "FN5e8b230775293eb6c64de5796d858801",
                            "FNc7d2050b7d827b5dfd1ba18b8b58d9ff",
                            "FN1d66f8faa02777c63086a638eb5ffdaf",
                            "FNc8da0a7ef074d2a6f28a439fa7712b2d",
                            "FNc34e71b6ce210195c36e39ace2aa7a47",
                            "FN84925d0ba74f9826175b0235ef1a5410",
                            "FNdc39d24a47249d1000fd470708dbe881",
                            "FN7a914c10608a30de58a906b90111e9db",
                            "FN219e491a4a5b846f298232fa79e68832",
                            "FN7e52fcc6e66a06e588d526e0d8b354d7",
                            "FN42dc5061039275e611a93bd4ed9f7650",
                            "FNf7c6d0f488d74d92b024b1010458a5d4",
                            "FN72bb7a2d58ea4b7c18ed2fec5c771795",
                            "FNe1cf82eb7b26d297744f787b7688189f",
                            "FN49de90f6b1641c9b17a5ba7c9dbe3592",
                            "FN43475b5180931284ac31a7a680547647",
                            "FNe3eea85419420d1b48979ec4d6db7463",
                            "FNb67a5171ed5983aa2a173b04f3da6040",
                            "FN5130357c50784dc9f3fb50e3c672c2c5",
                            "FN7eb1ea2bcef710d2d5c13072a84fda97",
                            "FNe415901f4daade68a0524097fb772d4a",
                            "FN944eea7cfc2160db7f3dbb7ecb8a8bfc",
                            "FNe7de95a1b7dc7d428322965d3730da28",
                            "FNee9a4c80baa117cc8f8fed653601f182",
                            "FN6a8f52c8746c70e370fcb9ad6403393b",
                            "FN4fa34b60f5b239460669b2b6681e3c0c",
                            "FN80efb9dcaf82d795c8a8f024a97db228",
                            "FN849a6f555a0a5b94e91633c1ebb4e15c",
                            "FN222fcc03faaedacad5add57110a243ab",
                            "FNee27860b31156d941b65da764085069a",
                            "FN52adf025e355a23e43066da85a77b11a",
                            "FN44ba49dc1ef4340b94e92c08321aa916"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 147,
                        "user_update_success": 147,
                        "msg_sent": 12,
                        "numOfUsers": 147,
                        "msg_undelivered": 20,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 499,
            "DaysOfWk": " 4",
            "TimeCreated": "2021-11-29T21:01:13.865Z",
            "OrgName": "Wyncote Wellness",
            "PresetMsg": "From Wyncote Wellness: Please complete ALL in-service training ASAP to stay compliant. Click here to get started: https://bit.ly/reminder-VIV",
            "campTime": "2021-11-29T17:50:41.400Z",
            "CampaignName": "Wyncote Q4 2021 Incomplete",
            "FrequencyInDays": 1,
            "LangId": "",
            "CampaignID": "C30",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-29T21:02:45.604Z",
            "ProgramName": "Wyncote Wellness- Q4 In-service- 2021",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 169
        },
        {
            "MaxProgress": 99,
            "ProgramID": 477,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:04:37.879Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:25.946Z",
            "CampaignName": "SI Homecare- PCA 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C593",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 49,
                        "msg_req_sent": 49,
                        "msg_scheduled": 0,
                        "user_add_success": 49,
                        "user_add_attempts": 49,
                        "msg_delivered": 13,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb98a0cb66ec8f155cbb386d09bd4dc9f",
                            "FN41277d24b1243757197a77dcded2f8b3",
                            "FN1c5ece24c41f19051adbef85d4fa0d95",
                            "FNd2f8c7311e94707558ec0a8eb2868302",
                            "FN916ab253b13535ab2e59a2fac62bbd6f",
                            "FN8ccb3b0c9cbed99d192519af77874d95",
                            "FN0972a8054a004312a875c42a121f6f62",
                            "FN1a6b83be0543c1addb1f7c0fbdb86015",
                            "FNbc53ddbec991c4d5fb6b1a5a2afb6014",
                            "FNdd6b971c6855cc6680b682fc54e0be24",
                            "FN7029a970abf51c20dcc86069cae1d42c",
                            "FN7e4b9627ca08880712fdac91aac50a3a",
                            "FNe58a47b05f8926173825c419b9dd0893",
                            "FNb732683c3990a52c8765af9627f1d5df",
                            "FNaa7781b744ad19b3f4bd769a636cb3ba",
                            "FN995d38d503c85057586dcd7334ab55a6",
                            "FNd3766b813731db6761d087fd3a325964",
                            "FN02d982252d347208ae3626a12596b43d",
                            "FN045f31f7b1f2f81037087360cec7fd75",
                            "FN81d0327621341b71609ea7b8fa5ea763",
                            "FN02d2bb2039e524584fdab7f1b54ce961",
                            "FN37b4538f2b52cb098173a72646f3ba7a",
                            "FNd66cde7058dce17c91101e8849cf6e59",
                            "FN68674910157e6b2ecdb72f8e677cb3c1",
                            "FN62461159793bee3962e8369aec3eb361",
                            "FNec484630827fccd29281f4a44c5dac63",
                            "FNf4bb89908e55e43b9b4a80230749a652",
                            "FNf15112bcf3c6b56fa1ba21b05aa9e476",
                            "FN626c8d5dcb18ebce43580d6e06c67a4a",
                            "FN05a350fa4079f3e0914e7d5ee862e2bc",
                            "FNffd9e4ee52962ea3d8da29f414a81ffa",
                            "FNcc7e0a03188528a06437a128a4525c4a",
                            "FNc340bdb3d87b55b262e4510980ae79f8",
                            "FN2b598a754efe2f0c77a9d0cc97cb4951",
                            "FN064a1e4276be7892e4bdbc27494d2d07",
                            "FNae03705fe1b9d74ce5557eeaab0c66ea",
                            "FN349a15c7dfa916f60d6c86d38ceb69a6",
                            "FNda39b2ef6e6d883dc802abdb9ac0705e",
                            "FN8ed5a98cd650fe9a8368b301e9208cf1",
                            "FN7ebfb150946f0083ecd40c69d245c379",
                            "FNb52cdda05ad82c7c682f9776eaeb58af",
                            "FN336b8d87881d1754431f38cc0a3cc976",
                            "FNbbfa8579bdb2a9fe5a8393e140197f52",
                            "FN919b6798890f1b37425a8f2511bdf245",
                            "FN8c7fa77f0a94f6c4889d704e1d340506",
                            "FNa9a3b3db3808558e16e2b0277d7f2a4b",
                            "FN18c523e9b50076127689202dd041c166",
                            "FN45d39ce5f23c86c7c39eac52720e2a76",
                            "FN711e2752ff93cab711ce10717ec09db1"
                        ],
                        "msg_queued": 30,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 5,
                        "numOfUsers": 49,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 683,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:30:29.796Z",
            "OrgName": "HDA Care",
            "PresetMsg": "От HDA: срок вашего INSERVICE - вы не соответствуете требованиям. Войдите на https://bit.ly/GetNevvon или здесь: webtraining.nevvon.com. Вопросов? https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T20:30:55.475Z",
            "CampaignName": "HDA Class 3 2021 Russian",
            "FrequencyInDays": 1,
            "LangId": 134,
            "CampaignID": "C321",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 3- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 494,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:22:11.782Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:00.583Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q1 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 7,
            "CampaignID": "C239",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 51,
                        "msg_req_sent": 51,
                        "msg_scheduled": 0,
                        "user_add_success": 51,
                        "user_add_attempts": 51,
                        "msg_delivered": 18,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb464f15c88c99377e1682c5e7816be9a",
                            "FN607d12c049333e81ddbb4a7232880624",
                            "FNbb73804e9051d58956a2feb973c8033a",
                            "FNc8d5c6cef3a8b85fceff427bcf95b192",
                            "FN2f817ade5f4ac9e6c8f26f07c12cfc19",
                            "FN5da690085e526eef0fa0fc043d1c4956",
                            "FN4359524b50731828621143e8cb71e985",
                            "FN74ceda95819b1ada56d50bc4667db888",
                            "FNbd68d54152048470cc389fb27713e3c9",
                            "FN7bdb472865938072d2ee5fdc7513b3bc",
                            "FNa4f0fb914766863f185ea3864dce1bb3",
                            "FN85b5cf641801e86dc6428b4e496e1d53",
                            "FNee1fdc104e808778a9b82bfe4cd9da53",
                            "FN2a82bbc1bb8d657fc0246f3781c56a88",
                            "FN6853482ceeb419776743c832a48ccd90",
                            "FN703ddfe5f33f618a07e63229f24cecfd",
                            "FN0ec3ead5a783e394fbf5199277bea9b1",
                            "FNb4f39231ae79fedb3baff03102afd41c",
                            "FNcd7574b4c7407ce34cb16f4273373282",
                            "FNef8cb9c8b90f269bf0c4a3238211ac1a",
                            "FNc0aa83540fe3f3232b132fd04ad7f343",
                            "FNe9ff6696f01e555cf2931796f4459a94",
                            "FNd6e7df11dd3a023c29b7f57b2520ca92",
                            "FNcd8f96b8c889455ea6793add8bb0b202",
                            "FN1eb35d17e5d4cb3f724a56781c22896a",
                            "FNdd89e379e7d30c2ec52542e1db8ee42c",
                            "FN6b42afdbbbb94998fc1cadcf004b7ba0",
                            "FN52dab73ecacd279e4288d52b1b2be34e",
                            "FN09a955643dcbccd3d591ce04d212bba0",
                            "FN9dea471488bdc3af729df7f2b0e7bc9a",
                            "FN5bd897fcee15d54905308ab8e7d00830",
                            "FN1d2794630a4448666a11cd80362d343b",
                            "FNdaf4d054c376083bc563e41432c9b7ff",
                            "FNa6f80b875858eeeac8a1e733c587a6e3",
                            "FN9544fe272a2a9e10bc510a9d252d13b5",
                            "FN2d6678b0296cd1b8342a6c82ea32d550",
                            "FN00d66aafc13970a51232349bdd707fdc",
                            "FN354d08cb26b068fb395b05f4ebe2b32c",
                            "FNfa642fc7132327726e8dc6ee23fbed28",
                            "FNc6b601c345a088aa8453c260dab7f3a7",
                            "FNc9e771dc59dc14dcb608989d273fcf48",
                            "FN2d5772b57ff39ea3d6bf32b7e392efd1",
                            "FN3808f0cf3c41271c672712574c23c28c",
                            "FN714319a8dcabcca82d2e19868b3ad209",
                            "FNf64a5146baf339a98ffbda76589491d0",
                            "FN0fe66e5957e4a7755269a6d2a2ce0f2b",
                            "FNfa6d687344f759425988102c798a4dbe",
                            "FN041ea209644881dedf172b83857c6627",
                            "FN7515f5fc06227f602638935844b99444",
                            "FN43af8ff419023dc5c483e78862d1e402",
                            "FN45c8903702fb017706093d17f256f9d4"
                        ],
                        "msg_queued": 27,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 51,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 678,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:00:21.875Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:44.441Z",
            "CampaignName": "2 Hours - In-Service Program - Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C427",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 440,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:35:55.597Z",
            "OrgName": "Accredited Aides-Plus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q2 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C570",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-11T03:36:13.979Z",
            "ProgramName": "Accredited Aides-Plus- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 133
        },
        {
            "MaxProgress": 99,
            "ProgramID": 711,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:55:55.657Z",
            "PresetMsg": "НАПОМИНАНИЕ! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:22.577Z",
            "CampaignName": "Compassionate Hearts PA In-service2 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C166",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 861,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:09:15.746Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Orlando",
            "campTime": "2021-12-09T17:00:32.659Z",
            "CampaignName": "Assisting Hands- Orlando - Program 1 - None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C257",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands - Orlando - Program 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 248,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 606,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:40:12.927Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Compassionate Care Universal Compliance Program 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 4,
            "CampaignID": "C516",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compassionate Care Universal Compliance Program 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 188,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 687,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:53:55.993Z",
            "PresetMsg": "REMINDER! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:49.099Z",
            "CampaignName": "Angels 2021 In-Service1 English",
            "LangId": 41,
            "FrequencyInDays": 2,
            "CampaignID": "C85",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 100,
            "ProgramID": 322,
            "DaysOfWk": " 4 5",
            "TimeCreated": "2021-10-07T15:59:37.755Z",
            "OrgName": "Customer Success Team",
            "PresetMsg": "russian test",
            "CampaignName": "rsussian test",
            "FrequencyInDays": 1,
            "LangId": 134,
            "CampaignID": "C16",
            "MinProgress": 0,
            "ModifiedBy": "uri@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-18T17:28:35.776Z",
            "ProgramName": "English Program 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 154
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 4,
                        "user_add_attempts": 4,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN6ecea915a5bbd7d0b0979392453c6c91",
                            "FNee12b04318c708ba82a0169643692b8d",
                            "FNe4b8110afc624445eb7764c8ed25a2fd",
                            "FN997aa316bf52ccb100de579fb3e4847f"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 480,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:21:37.283Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 1 Spanish 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C406",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 1 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 35,
                        "msg_req_sent": 35,
                        "msg_scheduled": 0,
                        "user_add_success": 35,
                        "user_add_attempts": 35,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN4fe52321bc17be08ebef112198147f28",
                            "FNd798936e4c2355f914d019ecfd6ce23d",
                            "FN7354be6a186d700db455c54d4eb32ad2",
                            "FN07c9dd5df0f0ce5ab2d65af166dfc8d4",
                            "FNc97d42311550390c9f4ff0afd092a78e",
                            "FN4ed5d134634c8b9d1a386a43ba803bc4",
                            "FN04f39c209816331cd2bebeb7767f2a95",
                            "FNbe9fe6d6c5b67f6540b4fce77ff52510",
                            "FN3948dba2082317458160b8b9efc63aab",
                            "FN068bbd509cffbc81539ce101ecf1a347",
                            "FNcdb3e576f771c3052ff2f9fc95ae8e70",
                            "FNaa873e0f96cc5d93cb6b69b57476b019",
                            "FNf3f91743627fc32ad964bd7a5ade2b2d",
                            "FN4f9cc9a74f1fff529f4af626841d27f8",
                            "FNa8265c235cb09908487e8328595bf003",
                            "FN49d76f9359efec459306629ff438f6f0",
                            "FNb85acc408fb97a1afe9cd40ef0912b3f",
                            "FNb92432281d5f83ee4090b22ef956b436",
                            "FNcdcc59ea21d340c59518b82ae04cb90c",
                            "FN242c3b206b099d6d4fc45792c58e4821",
                            "FN6da9e2453faeae52dc65c7ff30483fc1",
                            "FN2fa9918bcc6b42ed1f2e2f292b7a4560",
                            "FNfd5639f35bc7ed37ec3888e6aa21f657",
                            "FNdd718c6d60f0cea83962258404d4344f",
                            "FN1de18f82d03082188ee94fc4cdee17ba",
                            "FN7cb76b86d8971e13077e18bb89feac6c",
                            "FN42999daba5b5a0d7b9c51ee204919513",
                            "FN17f6565486aac66232a83c10e0419e8a",
                            "FNbc4eb029ffd24c958768a554e6d089af",
                            "FN2bcc48a257dacb2bc4a0d545d5245187",
                            "FNfce2456dc6f958b7f6ff680fa942197d",
                            "FN5ec5fecf6ac1ea9580aefeb2dbe2cae6",
                            "FNd3e6711d2defe73ec145eda287bbfc89",
                            "FN80947ea18bf825429a642216e1e5498f",
                            "FNff4c5a924381a1ce9de987cb1cc81902"
                        ],
                        "msg_queued": 27,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 35,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 411,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:18:03.037Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 4 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C403",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 688,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:07:37.177Z",
            "PresetMsg": "RECORDATORIO! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:00.589Z",
            "CampaignName": "Angels 2021 In-service2 Spanish",
            "LangId": 149,
            "FrequencyInDays": 2,
            "CampaignID": "C95",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 592,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T21:06:25.536Z",
            "PresetMsg": "REMINDER! Health Association of Niagara County: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Health Association of Niagara County",
            "campTime": "2021-12-08T17:00:02.541Z",
            "CampaignName": "HANCI H1 In-service 2021 None",
            "LangId": "null",
            "FrequencyInDays": 7,
            "CampaignID": "C115",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HANCI H1 In-Service Program 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 183,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 739,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:31:04.633Z",
            "PresetMsg": "НАПОМИНАНИЕ! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:22.679Z",
            "CampaignName": "FADMO Q3 In-service 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C146",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q3 In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 688,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:54:48.954Z",
            "PresetMsg": "REMINDER! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:00.665Z",
            "CampaignName": "Angels 2021 In-service2 English",
            "LangId": 41,
            "FrequencyInDays": 2,
            "CampaignID": "C86",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 731,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:27:23.254Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q3 - 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 4,
            "CampaignID": "C508",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston- Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 136,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 796,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:34:05.815Z",
            "PresetMsg": "Classic Homecare: complete sus lecciones antes del 20 de diciembre! La oficina tiene una computadora disponible. Más información aquí⇾https://bit.ly/HHAXENES",
            "OrgName": "Classic Home Care",
            "campTime": "2021-12-09T17:00:02.511Z",
            "CampaignName": "Classic Compliance 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C208",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compliance Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 239,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 438,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:48:44.142Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q1 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 4,
            "CampaignID": "C566",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 715,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:57:17.917Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:09.265Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q3 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 7,
            "CampaignID": "C249",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 10,
                        "msg_req_sent": 10,
                        "msg_scheduled": 0,
                        "user_add_success": 10,
                        "user_add_attempts": 10,
                        "msg_delivered": 8,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2b89c77ffb71e51dede3bd0bf4c12d4a",
                            "FN145a2344e6e38ea7d359389c264783cf",
                            "FN8ed7a1d15bbecff7f17d2c1aa1c58839",
                            "FN67a3b944c727e4c0fe37cd1c6c287a6c",
                            "FNf1a43297748135b72cfe0afabb70b964",
                            "FN47fd88eacb8b4839f14cff326a538571",
                            "FN6ca3eb230f59a998155c6ad566fa15f8",
                            "FN94729c7601e88873422a25fb7014d729",
                            "FNe69b98b7220c5cb807157b7e45845c4d",
                            "FN5301a6390cec3d7b6de7be35b5dcf691"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 10,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 651,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:52:45.903Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "RECORDATORIO! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:52:31.002Z",
            "CampaignName": "A-Plus In-service1 EN 2021 Spanish",
            "FrequencyInDays": 5,
            "LangId": 149,
            "CampaignID": "C380",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 EN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:55:31.285Z",
            "OrgName": "Angels in Your Home",
            "PresetMsg": "REMINDER! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T17:00:56.874Z",
            "CampaignName": "Angels EVV-HHAX 2021 English",
            "FrequencyInDays": 2,
            "LangId": 41,
            "CampaignID": "C87",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T15:23:51.412Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": false,
            "OrgID": 206
        },
        {
            "MaxProgress": 99,
            "ProgramID": 559,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:33:19.257Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Richmond",
            "campTime": "2021-12-09T17:00:38.588Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 7,
            "CampaignID": "C269",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 141,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 653,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T16:08:30.471Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "来的温馨提示: A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T16:06:09.380Z",
            "CampaignName": "A-Plus In-service1 RUS 2021 Chinese",
            "FrequencyInDays": 5,
            "LangId": 30,
            "CampaignID": "C390",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 RUS",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 525,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:03:25.862Z",
            "PresetMsg": "RECORDATORIO! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:02:46.491Z",
            "CampaignName": "Sophie Q4 Docs ES 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C293",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q4 Documentation & reporting ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 461,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T20:36:34.972Z",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:15.237Z",
            "CampaignName": "Assisting Hands Boston Q1 - 2021 - Haitian Creole",
            "FrequencyInDays": 7,
            "LangId": 56,
            "CampaignID": "C503",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston Q1- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 136
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 185,
                        "msg_req_sent": 185,
                        "msg_scheduled": 0,
                        "user_add_success": 185,
                        "user_add_attempts": 185,
                        "msg_delivered": 144,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN344f8da6683b342900bb2ff27d188c26",
                            "FN073acfb09c34e0f511c9c3289f6d7060",
                            "FN8063b217cd1de8cad5f6aea24273046c",
                            "FN51b99ddc4ef3abd562dcf89b30a7a1cb",
                            "FNa2a0f132e46abdc4220aa23e13c0351f",
                            "FN9a0743ce97c37eada5727405a321c848",
                            "FNa6590b48134b56fed50485000a7fd689",
                            "FN9d02270006be0d07490f394a8d6dfb60",
                            "FN25fa290a27e95ef923f8f46a37e66d65",
                            "FNca9aa5a44c7f2efb699313dcf909785b",
                            "FNda721b458741f7e0a8626570495d7f99",
                            "FN0b0e19613b2294720b8a441a8808f995",
                            "FN3c60e00944573fe05e59375efc7aeff4",
                            "FNb93dafa703193aab6d81c13917e33543",
                            "FN061a2ff51215c9bca98c3ff6c95e919e",
                            "FN292873dd58a9a4fe56348d12bcd7d6f5",
                            "FN04c71d4fc554a3a8a7f0702596eacd4a",
                            "FN4b94c6881159f4a0b4e5823228661145",
                            "FN556f99acd332a81a7dae40ed93973baf",
                            "FNc6a7eb5c905dadcc55bf433e1274835a",
                            "FNf45208b29228cf01a472aeccb5719fdd",
                            "FN197864996017db38e1fb3edd15206c3a",
                            "FNa426585cae1d19f872e8a5329891f87d",
                            "FN00acf1aca988dd0ec667ac3d5be0d6ae",
                            "FN04c1887e9b1ce5e209a4680972a55fc6",
                            "FNfa33e3a42261d85703bae4aab8b508db",
                            "FNd6a6580fdd8dc96b56da691ffa88f52d",
                            "FNe79753b942585a4972f995ec5d91ea73",
                            "FN1f51513f35f3b958202526a0acb170e7",
                            "FNf15a4a6b22d50dcb08e7887de8a3825e",
                            "FN03f3d7db3f28845dd7b7bd17494fb21f",
                            "FNabfbe5bd53c06bb54bb1e31b0eb10610",
                            "FN94df64e838b5da58893966274fb573ff",
                            "FN5ad4171790abd6cb37f530f166db1b14",
                            "FNb1e86d68ddad954291a381dd6e6a6488",
                            "FN64a01988a6ca7a519985d106e90440c0",
                            "FN8fc2502f3debdd914adc48e6f56ef6d4",
                            "FN52dec73e1ac2010cea5e7d33200e9143",
                            "FN7d8195d36676ba6642086d762d75c701",
                            "FN0dd67217d43cbca9482b0f9cd3601e9b",
                            "FN633b9156e20dd0c4b66ec740677de2c5",
                            "FN3e186681f8c49c0e08196e57ff646e82",
                            "FN3bd70ed8e124654ade2870b154c138ee",
                            "FN21248fde525b8bbc450c3a9671f6acad",
                            "FN3f2fe93c5a5526a73446b85e674f6c70",
                            "FN9fe057a02e950ce79e1e7df42a68e76b",
                            "FNa2eb004addbfa00d98b757d2216588f4",
                            "FNcf0f871f45e553531dd63ce5fb1e3bdc",
                            "FNcad6b210f8eaebcb0696f6526fcfee5b",
                            "FNe06bd9da46c3657dfb89b06668d1102e",
                            "FN9e466d7b342f4d4962883dfd41c3fd75",
                            "FN658d1298590d0430ddcf087b0a0f04e5",
                            "FN597fbad6ec2aaad151d2be33e26f7bff",
                            "FN116bb001fa79b103062aa44a248d8fdc",
                            "FNbcb819b6c212ce6fe1bdc01f66975fe2",
                            "FN6a3b73d3489d940f8154ca12b2c87f47",
                            "FN612390b57c77a0875818cffe085e6cb4",
                            "FNa783259bac08d9363dd2aeba4200afec",
                            "FNedccecc32f449255a7573c710f70387d",
                            "FN36d2d3f936366b1abcc73fa5c7e00f43",
                            "FNfbd49cb06889970798df059e18aa3fe3",
                            "FN06b3750503f0b55f7c1783b216e3aa30",
                            "FNe23530d814bdd40cafb59b154951e6db",
                            "FN2cb9e09b1fd7f941418e8810e44d7094",
                            "FNef5fb3121ce7ac8d4f5477fbfeed6a54",
                            "FNba1e783078a67229eed375560d0244f2",
                            "FN083d2e94d218d9c2fee1daae31db2fa7",
                            "FN20359ccbd34fbcca054ac9c9a53c9a0c",
                            "FNc5601d2e10da0c5eb24c1b8326d32c6a",
                            "FNcfb181a2bf47764f2305acbebbfcdf70",
                            "FN5e7de286282c92e62bfbbaaf735cf99c",
                            "FNc8562d57e598d845bcce62ad902e3036",
                            "FN762df3230e12a9d605ebe5f691799305",
                            "FN7f81f15de2ca3342656d6b0e5da0fa78",
                            "FNca49e9fb16e98ae5b3a4bf695a320003",
                            "FN08ee2a9d37695ac0f843a24686dc52a5",
                            "FNebb0707bdc42d6e354b6b79d677dc6a0",
                            "FNfbd0ab0be66551678fe199a5256d1cb8",
                            "FN116b236d28c1d1a8b13d81b1886074ca",
                            "FNcbeb1768c0c889b3cd0ddadd7fe8eb87",
                            "FN71bebc30188408376cea88cd048446e0",
                            "FNb76e8d8911a4db22cebd8bf36c9025bb",
                            "FN14594287faf45d16e355f7ddf47cc5a6",
                            "FNee2800ba3ec49fe9a23e5069bf0857b3",
                            "FNa062a4d13243d4d01385006b16de765c",
                            "FN317b0e897e2a0e83fb0863d4e21e25bd",
                            "FNa22917809621d9411737ac7b752e9bea",
                            "FNd4672c9fce402a4a027a887b1e14f2e7",
                            "FN5037d3ff5f42eadaf53f539e585f9487",
                            "FNb6ea612bb2a5159b2c2e2be272cbee61",
                            "FN544a5746ecb701364d3e535c8ff4bf6f",
                            "FN97a0dc193d4c53cfd10823f9030075bc",
                            "FN6ae01d5a3c23310a1f0e3b90d9fd6933",
                            "FN4529963480f2d27b147afac10ecb86f5",
                            "FN8b231525b6e4c2711e153dd64231f3b8",
                            "FN4530a74c3bece14e473383b7b8b9392a",
                            "FNfc43fd6be8d2761846b586d3a8d5515b",
                            "FNac01dc3ce41a90aea822ebf72fb4c658",
                            "FNe090082015ebab27f706557d57d98635",
                            "FN05d5d0fb26a77938d116edff4ac4de2d",
                            "FNc4e090442160dea1a51865a05fdbdb4a",
                            "FNaad9522917bbc90c4abddeaf07d2b385",
                            "FNa4a9d514ed5c2d86965d9d3b9c25e0ba",
                            "FN8eb1411776e2ef0a3049e7c08639d4c8",
                            "FNc3620982031675e50e740fa756a9d66e",
                            "FN235a0c016165173e7917e5715bc259e3",
                            "FNb1699d952c5414facaf805644131dc06",
                            "FN3aebbf5fbb8260736f092ed1df946a1c",
                            "FN61c5550da65c4c6974ce29b7c0376437",
                            "FNe24594ccd0c4541539fcdaab9a787cc8",
                            "FN14b9156a2e6866d8ded83587578ed372",
                            "FN4a0c7a4f58a7a7b1828f99576b4796b9",
                            "FN4f3375955df206684dcf8905d86a437c",
                            "FN82bc3607479b489c4e70ba46481b9ec2",
                            "FN270304eaceb3b59a71c0d34246ec8462",
                            "FNc1d53fa65163b282f8c1efa071300bae",
                            "FN2c874980b8972c756c0c14be2063a8b7",
                            "FNe3255053ecd4deba23b4aa73199df08e",
                            "FNb4a610f7b7d86d8a5ded1c03e6ee0404",
                            "FNc91f3036d42b21c897bc68589132dcbf",
                            "FN3fa4430fc878ee4a3c2b54654525293f",
                            "FN2852270e2c8779a417dfc1431f5866fb",
                            "FNb3bda59acb8176ab79e8b08becd148d6",
                            "FNe79e72ee04f823b76e0e56d142c93d5e",
                            "FN4ff5200a686ac1b9b7c0ff33ecad819a",
                            "FNdbd3ccfef4fbe06f98e1065bc3cca139",
                            "FN185b88d23c0f22c9494311f84b3bc556",
                            "FNaa9de17dcdf80fe91a53e9998d480f14",
                            "FN29fcb6e6b9b72a149406840f04986393",
                            "FN358d0b03f1f7f3093f48cd7edfa361c7",
                            "FN9a5ca20ebdc48e57c61bc58a9c3716e8",
                            "FN9c90002ffccd8c64044be07b01ec37e5",
                            "FNb78f66a3262f58c86e6e5e55a977801c",
                            "FNb3a0faf3be3e8fb8fa60aec93aadfca0",
                            "FNeec9f4a9c37f3f0a2cf6792e09065e9b",
                            "FNe183b12167edccc5a836bc728a73fa9b",
                            "FNe3aefba931e2b4c05ff2a2024e545bcb",
                            "FN85e3ed0d3c4a88e21801a44eea2aaf2d",
                            "FN90a648fc0b6f9493630b7609c3871430",
                            "FNa203d943203913fdde7b543b197eb7ea",
                            "FN15ddca619023adc38c26bab63c1c963c",
                            "FN86277236c7b3f96eedbee44069d3d649",
                            "FN43589512931533bad6673b8d671cdf98",
                            "FN763b88ca578bd8e9b52ca77a08cf40cf",
                            "FN99bd0549e3297ab84cb022ab6f168d0e",
                            "FN13354f3ae2cbe1c22bff77948a0ca5c6",
                            "FN71f9ae70b3444f4a4025d3b3284692a6",
                            "FN04bc4468714dc2c7d86ef41b7cdecaa4",
                            "FN920ba09b7e75e18ca20f0b9543a9cf99",
                            "FNdb1251e9f69e373d352798f4ca9b2d9b",
                            "FN3bf3a52b850b2857b2a04bf678ccd4ad",
                            "FNf362ef95f6bc4807e5db6334e28e3414",
                            "FN52b7fc6894ce3dea82cab68d322600d8",
                            "FNcba54bae547eb010bb251712033478e0",
                            "FN1132473bec386b7246e9b883100eded9",
                            "FN48c5000b0c414f72b632fe1a1da7d404",
                            "FN9e0f38aea0cad61f8370334b7bd06709",
                            "FNaf9e01e2f0e39182ea10413312774af6",
                            "FN9f24115bedaa10d225ff4c12f5f19ccd",
                            "FNe18b077c008f9eca164bcc4e5774b470",
                            "FN5fc0785f2f9a01dd087186c31d04c11e",
                            "FN2c5217c030bccf25956b50d982e3a916",
                            "FN15289fc91122cab462cdca3e926bed0e",
                            "FNf1b9b52d07e3bbeec57d8cefb4edf70d",
                            "FNb9e8e83a8195bcc23dadbe82acace2cf",
                            "FN97137a6d372d8a53fcd11eb664334f6e",
                            "FN9b150ec57ae032ff381bf9e8f1d71936",
                            "FN0e895454fb9e519b0c47701d5b212f83",
                            "FN26182f8fd6299d2300be3b39b4ab4966",
                            "FNf8f03b51f7272de825e5312bd3f3030f",
                            "FN56fd6534c989f5332911123230691f37",
                            "FN8607f49b630b9756e5ab7ac14c8e44a8",
                            "FNdfbaf30f34c153fa4fa488145cc5e4cf",
                            "FN98c65ed5eafafe68ea63dea922124e80",
                            "FN650ad862003ccc5159820e5b8010feab",
                            "FN0f0b41304258637ed0a79e12b0763902",
                            "FN2fab0858708a5ad4710eecdde2d6e37b",
                            "FN99f9e0a8954eca333f1db7c34d6abea9",
                            "FN307f1ef996c654fdca4382333970e5da",
                            "FNfdd521a4c2e19039b3196ad4a26c492d",
                            "FNc09d7bcd3416486a5b7525619568393b",
                            "FNefef4b6cd6af23cd615466fc871b9bd0",
                            "FN2c49161a5aaf1de2325ee1c52f0f1b3a",
                            "FNac48989b09f97a1159d40a91ec91d090",
                            "FNcdf3f77bf4a8eaebcb8615803e38dbf2"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 23,
                        "numOfUsers": 185,
                        "msg_undelivered": 16,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 651,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:45:17.233Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "REMINDER! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:43:21.625Z",
            "CampaignName": "A-Plus In-service1 EN 2021 None",
            "FrequencyInDays": 5,
            "LangId": "null",
            "CampaignID": "C376",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 EN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 57,
                        "msg_req_sent": 57,
                        "msg_scheduled": 0,
                        "user_add_success": 57,
                        "user_add_attempts": 57,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNd71129f888aab2435a32bd11551d6014",
                            "FN0b14179b734af41893786f3a8c8bfd88",
                            "FN93054973ca8bc052fbe0199afcefd042",
                            "FN908e3d01f436ddd4a6856b08c3dc8c46",
                            "FNcb7079015ca22d9abaf3e5b6eb6bd5d3",
                            "FNcd62fdaba8bc8e5b289f8de7065dde39",
                            "FN90bf91160d2969813d9759c836444e7b",
                            "FN908b500cf6c689c7ac5475adaf46d460",
                            "FN751e9abccef6e1425cd8a3cd747002b9",
                            "FN9c58a82151f403b0ce514f4772bb24d4",
                            "FN88652c46504ea5db5fc254fba3d5f9f2",
                            "FN8452ab46e62e2f062f9e3f2a67fd3be8",
                            "FN17e68734962bcc4614b5c32bbc34c3d2",
                            "FN81a97a03d003d6f25e825a76830f76c3",
                            "FN115cdc77b0d9225d144f1455cf5e2190",
                            "FN77e462466fd9df68add42ca7199e3c71",
                            "FNc6349612b2e531cb7e8454b333b3f67e",
                            "FN0579e87c321bd9c7e90bf88dab7d9090",
                            "FNb861f12de06f0a09f535d9c3399841c9",
                            "FN1c2bf8514277d34552c40eb8c158e0e4",
                            "FN77504a1f48c5203e76c0406758d45ac5",
                            "FN2f49f4ab81cd0e20e5c3f1ebea86cde3",
                            "FN3d229abe7dd51ecfd65e20f65e888ba1",
                            "FN5ee62e7877fd9f0879d2aaf4bcc711da",
                            "FN5ea0248a56cc826439e6f4d071e1b4b4",
                            "FNfe30bc562ad12e8ffc0bbb63ff870776",
                            "FN2e456c6e71e7ab5c53dbe6215553dacb",
                            "FN8a7ff42aabbdf421ad0e3c5582416bbb",
                            "FN267976264b7fb1b11e7686246be92a31",
                            "FN2f49a372e9ab9fd935c3d8c2b5f7b0ce",
                            "FNd8f19acdf34e2aaf701b620dce96c8a9",
                            "FN75810a8d80449fcc790a589d00aa773d",
                            "FNe7a6697465640bf8a0617a8ea2f864d5",
                            "FNcc77cc4b911f16ee543da60438ad1db0",
                            "FN425c740521c67f4252197421119a346b",
                            "FN817d8e395e5fe14420582b4c90a79785",
                            "FN2d4d88afc016e225ce0131e1a45a0787",
                            "FN3261bcd0882a8e2b0d941ca0c6566753",
                            "FN725d643756f60ade32b77bda154af439",
                            "FN1d1b3ead7055511724f9ee7bcc1e4649",
                            "FNa5298be7e50f1c60bcf8be94b8207201",
                            "FN010e233f4c49a23f475f2a57ae46e81e",
                            "FN3519014b8b032acaee7a41367715c44d",
                            "FNcfc458917c69689c2c6c52cacb8b898c",
                            "FN093740d10989ee34246f5c950fd67039",
                            "FNdea0ef85aa5e7ba3f10d217c2bd1915a",
                            "FNa0e6ea8d845c9228f3b12ed729b876fa",
                            "FN240d8a0f4c1d3349073490dc3d9c139b",
                            "FNcd1d66b278962527d83d90064937f6db",
                            "FNfdbd2c467ce7b537ec8b3123bfbb7fc5",
                            "FN6db5a370cc7394f35ac2a9bdd53d58fb",
                            "FNfd4ea2d14f832a861581a074fbf2f214",
                            "FN018e88017a513701757e846c0a53b6d8",
                            "FN9f915de08afb6c3b91e084abcee9a9b6",
                            "FN341c63dfeab21dbde166a8ed9e754700",
                            "FNe26c38e8ee1bf8fcbcd507a1dfe400b5",
                            "FNd3753b112e39e06eca6f6e4182c2afb1"
                        ],
                        "msg_queued": 56,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 57,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 678,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:57:42.743Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:44.381Z",
            "CampaignName": "2 Hours - In-Service Program - English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C424",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 520,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T21:45:58.733Z",
            "PresetMsg": "НАПОМИНАНИЕ! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:00:22.688Z",
            "CampaignName": "Sophie Q2 Safety RUS 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C281",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q2 Safety RUS 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:56:19.579Z",
            "PresetMsg": "RECORDATORIO! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:30.210Z",
            "CampaignName": "BestChoice EVV-HHAX 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C114",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 477,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:13:50.771Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:47.953Z",
            "CampaignName": "SI Homecare- PCA 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 5,
            "CampaignID": "C597",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:38:32.580Z",
            "PresetMsg": "REMINDER! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:38.699Z",
            "CampaignName": "Community Care EVV-HHAX 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C70",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 856,
            "DaysOfWk": " 1 2 3 4 5 6",
            "TimeCreated": "2021-10-19T13:54:00.910Z",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "PresetMsg": "From  {agencyName} Please complete your in-service training ASAP - Open Nevvon to proceed or get started here: https://bit.ly/reminder-VIV  ",
            "campTime": "2021-11-26T17:40:18.227Z",
            "CampaignName": "Assisting Hands Boston",
            "FrequencyInDays": 5,
            "LangId": 41,
            "CampaignID": "C17",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-26T11:42:22.131Z",
            "ProgramName": "Assisting Hands Boston Q4- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 136
        },
        {
            "MaxProgress": 99,
            "ProgramID": 528,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:14:35.001Z",
            "PresetMsg": "\"URGENTE De FADMO:\nComplete TODOS en servicio lo antes posible. Toda la capacitación vence el 31/12/2021. Empezar: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:56.405Z",
            "CampaignName": "FADMO Q1 In-service ES 2021 None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C130",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-10-19T15:27:40.344Z",
            "PresetMsg": "Sundance offers training online! Go to your HHAX app, click on the Nevvon banner (see: https://bit.ly/NevvonHHAX). Questions? click https://bit.ly/NevvonHelp  ",
            "OrgName": "Sundance Home Care",
            "CampaignName": "Sundance ",
            "LangId": "",
            "FrequencyInDays": 5,
            "CampaignID": "C18",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 225,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 861,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:12:54.591Z",
            "OrgName": "Assisting Hands Orlando",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:00.737Z",
            "CampaignName": "Assisting Hands -Orlando - Program 1 - Haitian Creole",
            "FrequencyInDays": 1,
            "LangId": 56,
            "CampaignID": "C260",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T07:10:00.057Z",
            "ProgramName": "Assisting Hands - Orlando - Program 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 248
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 62,
                        "msg_req_sent": 62,
                        "msg_scheduled": 0,
                        "user_add_success": 62,
                        "user_add_attempts": 62,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN00f88dad88222122013c5c26ec93af49",
                            "FNf1091ed2cc74c1e1bd966a3ea07ac4af",
                            "FN1676d1dbd49eb046094bd8d2f01a9adb",
                            "FN5a83a3a2a10b28b428511eb56f30f735",
                            "FNcbd6e2918a8350175ce9f4d15e4fb07e",
                            "FN5d28178a400e8dc70bea9b53d4a77549",
                            "FN0b28b7634bfb7454bb78d7887c87736f",
                            "FN0c7e76d31d186e561c2c68ea41af2982",
                            "FN49dbe5c03bc28ea023c37a5ac947d11a",
                            "FN9241edda24e42ced49f0d3b780737a5d",
                            "FNc0a0d3547a0901a16eaf7c13c34f63b2",
                            "FN7355cd9befbeedc80cebae649c573601",
                            "FN95afc1573593a63e4a835f9dde1134fb",
                            "FN738b5a4e2f8ae46b40ad1078eca0b95e",
                            "FNde582bbcdc13bf2a3849a3fdca22b437",
                            "FN772a88ead28f2c6e89e11278e1871ff4",
                            "FN3516074561576618f3a887305f1073fb",
                            "FN00b0a197ee097ef9e96a3661719d01be",
                            "FNa7b911d9d20ce2bc290ab8aa9585c3fb",
                            "FN2d7b387e88c390ff059dc483fff56b85",
                            "FN4e3a334312bb00b5ac3c84fe1d1b65cc",
                            "FNd63f4241a4248e3956d60d4d4da9d15c",
                            "FNbcc64e557e6be728e53703a7ef63df46",
                            "FN4319e82c210db7f17b206f9f6bc03aa6",
                            "FN36f3e837c78f5da587288438298eb8ed",
                            "FN5a6044773f7c6cfeb17cd4d2c7049075",
                            "FN5bc13749751d765fc177cc9567bb23da",
                            "FN8fca297ac2c4d130b23d4653f09d8f7e",
                            "FN9a949a356ade1df30be6b0b06a496313",
                            "FN055fa5620746f5acc168f2b2af175a73",
                            "FNe948de6ca8d70c9678da7ccf3d20413e",
                            "FNa4b3aa86c1584f0425e95cdcdd5de7d1",
                            "FN5f3c785ceeb92b8e0fe28c7f0768ea95",
                            "FNd6e14721a8e51751273e46de4965328f",
                            "FN1a9df1a80745e6f209d66cd9a4a0cbe3",
                            "FN00a14768a3d0aff45f8e30ab1743ca5c",
                            "FN8f4f8d0c547f9e9f31abb9f83c46481a",
                            "FNc6d6786ebc7e0887eeab74c54e8486f1",
                            "FNe66ee98fca5ec35a91ff7d52cfc826dd",
                            "FN7900f267a7eaaba761642e0c0a7dcf19",
                            "FN6ce38dd0f306536f839c9a8318c5f018",
                            "FN20a93c633212a46c660470e2998a9018",
                            "FN709525a373590629564f13be575e00e5",
                            "FNc8a203c4e40861c6594152de0cf7e592",
                            "FN006feba643a479101f39a3d2dd189123",
                            "FN1a4776fe7e931b36fbc0aa65377d0ec4",
                            "FNc2a5f4b8786809ec2c148b66d76a607d",
                            "FN2ace0633c9184b96e4cb20b2f373eef1",
                            "FNa6d6bc464ba183bccb5b5bcd6d5d3b55",
                            "FN97f23d27c4db50205a0a487435dc7900",
                            "FN0625973b8aefbe56b962325c8416da61",
                            "FNd969d6781dc0cfe4a9e058443c225820",
                            "FN20879e826fc806fadf2d789e13d0db9a",
                            "FNf5b38c7e471b830eeec3febf3b0903e3",
                            "FN06df711b86b387ee358ce098bd1f4e66",
                            "FN46be57f3c50583329291cbbb7d8cb9d2",
                            "FNb191a885255f4180b7f82c5159f78afb",
                            "FN8db396c2c425ab1090bba61a5277b992",
                            "FN086204c9c3c21e0c13d1a37b4461fa3c",
                            "FNbb36ba23a44b518970be4405879fddd9",
                            "FN80aa2554429e2c8c491747391106842a",
                            "FN8e7bc4024f58207f181e7c621b8ee6a6"
                        ],
                        "msg_queued": 62,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 62,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:20:02.571Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C445",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 100,
            "ProgramID": 607,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:29:05.712Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:53.492Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q2 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 7,
            "CampaignID": "C243",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 653,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T16:09:22.834Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "REMINDER! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T16:07:38.900Z",
            "CampaignName": "A-Plus In-service1 RUS 2021 Haitian Creole",
            "FrequencyInDays": 5,
            "LangId": 56,
            "CampaignID": "C391",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 RUS",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 469,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:53:54.589Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:46.560Z",
            "CampaignName": "JFS Care- Optional 1 2021 - RUS",
            "LangId": 134,
            "FrequencyInDays": 5,
            "CampaignID": "C550",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Optional 1 2021- RUS",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 5,
                        "user_add_attempts": 5,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNc4531867c1652598e6ca582a5e4ac49a",
                            "FN3c2e53653f869a8f628e69f95320ea5f",
                            "FN19c7f9b888ae0c76b25ad8a828568954",
                            "FNa4d1cfaae92806483816a1f327ef9d96",
                            "FN9a228866bae754960d2f71e7319181ee"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 5,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 263,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:34:48.646Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home Health Care: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 1 - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C453",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 589,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:31:47.825Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Open Door NY Home Care Services",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Door NY In-Service EN Russian",
            "LangId": 134,
            "FrequencyInDays": 2,
            "CampaignID": "C523",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Open Door NY- In-Service H1 Program- EN",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 182,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 1,
                        "msg_accepted": 0,
                        "msg_req_attempted": 69,
                        "msg_req_sent": 69,
                        "msg_scheduled": 0,
                        "user_add_success": 69,
                        "user_add_attempts": 69,
                        "msg_delivered": 27,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNec227e4ce4022a1f67366f2b35a8dec3",
                            "FN1ee3aafc6ca5789f2d5c8de1a2a35fc3",
                            "FN5eafca526b43deca1d77b41564679d64",
                            "FN964a24f72b92b53d6f370759667630af",
                            "FNd0bfacba12f7e5c0a360c419f0f692dd",
                            "FNa3d3c85b756c0f174864f4f55cea51f2",
                            "FN5deb3d2c49c4ece1418110afcc89ea0c",
                            "FN322ce431e2583ab2e9c2435fd0e5448c",
                            "FN82aeeb62b50fc66e19cef24bb7daea34",
                            "FNa48f02cfcccc420a482eb1cf4b964b72",
                            "FN4f5806afcd2eb0423dc09341d321be24",
                            "FN07428899577f8a373a7221257631036d",
                            "FN2110dcfbfed789aba27c88aedbc11e68",
                            "FN7c039f0236958480638b3546cc4de561",
                            "FN4638572150a4d8f8074f4603567cc255",
                            "FN91d650a502f6334be967222c0db4afc1",
                            "FNed15effaaa3680fbe4c250b71001707a",
                            "FN43b442a213eb9992efe05805ab3404b4",
                            "FNac6a3bd5fb21de5cc41ad843645f6d05",
                            "FNadc24c7ac5e04e143cf317e01db68a42",
                            "FN398a3786ca7c6d5af94b382de8666ec8",
                            "FN3c048bc5de1f8310ee6c6401225b99b9",
                            "FN184bb2b177a386d04d64eae984f017f8",
                            "FNb608affa038f3ef274041f4712f789a9",
                            "FNc84175eadef267e7d993942b5e82af52",
                            "FN1b464163373cc58bc1d0492e9e535bc1",
                            "FN7770be6f81f0f567a42893cf52ef020b",
                            "FNeafa1a599af1aaaf492b6fb1a8fe2542",
                            "FN4c5558ba21e2d6c0a5c640796830fe8b",
                            "FNf196e62b7c909f160d72118539a755aa",
                            "FN3affcba6363fdfaf9bf35d1ff3251c84",
                            "FN04c42d42bec196b9b48556b2b3940362",
                            "FN9534d4a5bc65527e2bf06e470f6ab16f",
                            "FNd5fdae607cbaeefa31786bba3f6f7060",
                            "FN4bd67c70564fa9cb7f6c8c789114ae5e",
                            "FN0db160dec8d602117a7e08d6b976d699",
                            "FN62fe9bf2f65398630ea4009a1df0962a",
                            "FN4f392962a6d2cb529ca87fa92e7e6ee7",
                            "FN27118c572e8e99b789e87b9734dc5698",
                            "FNea30cd8bca56f6f182afb65eb56da1bf",
                            "FN164d7638323065d7cbd44a1f68898614",
                            "FNe2cea2cbde742fa1aacea8be96d37c74",
                            "FNa7533d2ee87bf7ea2b3b8a848a34b7eb",
                            "FN9a4461dacb0e782bf03379e5495ca711",
                            "FNd03bde30e25db95c1fd451f6bb7a7f6c",
                            "FN40db92fee695a2cb796caf3cde22c303",
                            "FNda7c938f778b6e157b4a28e6312cae7f",
                            "FN78357362f278b6372018c9c3694ded91",
                            "FNec0b42d1fc9ab8da60680460509890de",
                            "FN21c638cc89de6d6fafa6e1e9c6151c45",
                            "FN6cabd28eddcc66958ba0c10c4f3dafd3",
                            "FN5a039e5ba5473098b4a6fbd6abcb6ecb",
                            "FN87de3adae0c4672ce55ef6d024f0541f",
                            "FN8e7074866760ac7128d9dba6d12ea5f3",
                            "FNdc8edb580b27ba97eef96f888513a4a7",
                            "FN2d92c22d7a75e90fb5b69116bb3f5707",
                            "FN45274e87ea4ecdd110af03d8c8432e33",
                            "FN256834e6230e1cccc8bbce541a99c70a",
                            "FNa760981500b94372ec79888e756dc08a",
                            "FN3e86171de45d4ebf7749a5bcd2ef1580",
                            "FN1ea53185c6029d42ccff4d01e2e078e5",
                            "FNf5fc61cfd04ddb8419e45ee9797036d4",
                            "FN7f5e4af1d71d1028b399315345ce880a",
                            "FN41e2bd7581fba63357241c64ecc734fb",
                            "FN178bcecaeecfb8adbfef03c8e3d0eee5",
                            "FNb9286b23d5cea542fffd6aee6c983dd9",
                            "FNbafe0a52881ed512d873c1c1328b5146",
                            "FNa73bfd10f7a3253d8575dcc446162299",
                            "FN5f88ed19db1d02ccb716b5e53651a044"
                        ],
                        "msg_queued": 38,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 69,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 679,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:09:12.439Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "6 Hours - In-Service Program - English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C436",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "6 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 282,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:02:35.395Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:37.608Z",
            "CampaignName": "BrightStar Care Program 1 - Experienced Aides - Haitian Creole",
            "FrequencyInDays": 4,
            "LangId": 56,
            "CampaignID": "C471",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 797,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:34:46.004Z",
            "OrgName": "Classic Home Care",
            "PresetMsg": "Classic Homecare: complete sus lecciones antes del 20 de diciembre! La oficina tiene una computadora disponible. Más información aquí⇾https://bit.ly/HHAXENES",
            "campTime": "2021-12-09T17:00:12.939Z",
            "CampaignName": "Classic In-service 2021 Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C209",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T17:34:54.216Z",
            "ProgramName": "In-Service Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 239
        },
        {
            "MaxProgress": 99,
            "ProgramID": 687,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:11:33.269Z",
            "PresetMsg": "НАПОМИНАНИЕ! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:54.532Z",
            "CampaignName": "Angels 2021 In-service1 Russian",
            "LangId": 134,
            "FrequencyInDays": 2,
            "CampaignID": "C100",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 862,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:02:44.361Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:46.922Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q4 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 7,
            "CampaignID": "C253",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 5,
                        "user_add_attempts": 5,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN6318db82222719a504c32b71218239bd",
                            "FNf016ea04641272b0d4df37a1c2e2995e",
                            "FN26c31940740670c5e61c1e5da7638b35",
                            "FNc365ba941f6bdab7ac904a79d314b709",
                            "FNe180d2ff6f93cdae6639365d7f634dd3"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 5,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 480,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:20:29.841Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:13.812Z",
            "CampaignName": "Recco - Class 1 Spanish 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C405",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 1 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 687,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:02:23.623Z",
            "PresetMsg": "RECORDATORIO! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:00.975Z",
            "CampaignName": "Angels 2021 In-service1 Spanish",
            "LangId": 149,
            "FrequencyInDays": 2,
            "CampaignID": "C94",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 27,
                        "msg_req_sent": 27,
                        "msg_scheduled": 0,
                        "user_add_success": 27,
                        "user_add_attempts": 27,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNd8e017198990d5456eb1dbfc5e868b26",
                            "FNe4abe3895995077a216970230396728e",
                            "FN38e8b6a35ba819dd9742cb456736618c",
                            "FN981c17310aba2f99039100a1d535df69",
                            "FN0b054c01940d39c72793d00a40477777",
                            "FNdf667fa8a5e14e6839cb419687e59441",
                            "FN3af5c7cee0fca0101155f9aa89f6dd1f",
                            "FNf3db34f080e958a90a3e3b4473650f29",
                            "FNa5ed42d3c63278adb3122a8fb954fc5e",
                            "FN39a037ee9b0e6794fedea52fb9f2d853",
                            "FN1f6382eb92e90f7484da2751f6b1683c",
                            "FN447a92cde00ac0f1fc3f6662eacc443d",
                            "FN126a4b6a8832860d2b288b1a52484ceb",
                            "FNc22c636cbffe14f273dcd336702dc3c8",
                            "FNb0401f34cbb8f486670fbc07edfea0c2",
                            "FN18ff3a8343f73d39c1d8df8c7d1e12a3",
                            "FN3bb5248acd53905f539b004f8b4dfdc6",
                            "FN57730a66017b264a06640f6ddc0f3200",
                            "FN91eb0acd500ec86ac90588de7c8dab26",
                            "FN28a27ab892109f46f68b569ca7ca4c49",
                            "FN854c4f4b4d0420a1cca34e4f812fbe77",
                            "FN4efc7aab516117a2240c19f651d68f9d",
                            "FNd3243139854abb882f9ff1d7efaca8c2",
                            "FN707a5179c24a83f594f9db2d5664a6fd",
                            "FN31b3007ef1b0a94c1f3ade4ee05d3ac7",
                            "FN314af8f9721d9052c4336bea9a7f2fdd",
                            "FN491fa839ea3700ccb25498a77cfde14d"
                        ],
                        "msg_queued": 27,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 27,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:41:44.637Z",
            "OrgName": "SelfHelp Community Services",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SelfHelp Community Services: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C421",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 242
        },
        {
            "MaxProgress": 99,
            "ProgramID": 607,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:30:17.289Z",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:19.842Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q2 2021 - Russian",
            "FrequencyInDays": 7,
            "LangId": 134,
            "CampaignID": "C244",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T06:54:05.555Z",
            "ProgramName": "Assisting Hands- Tinton Falls Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 167
        },
        {
            "MaxProgress": 99,
            "ProgramID": 317,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:21:06.112Z",
            "OrgName": "Assisting Hands Orlando",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:54.897Z",
            "CampaignName": "Thriving At Home Certification - Chinese",
            "FrequencyInDays": 1,
            "LangId": 30,
            "CampaignID": "C265",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T07:07:20.315Z",
            "ProgramName": "Thriving At Home Certification",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 248
        },
        {
            "MaxProgress": 99,
            "ProgramID": 715,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:55:50.364Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q3 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 7,
            "CampaignID": "C248",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 285,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:31:24.078Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 3 - Experienced Aides - Haitian Creole",
            "FrequencyInDays": 4,
            "LangId": 56,
            "CampaignID": "C489",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 4- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 511,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T18:47:44.065Z",
            "PresetMsg": "НАПОМИНАНИЕ! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:00:05.186Z",
            "CampaignName": "Elite NC2 Class 2 2021 Russian None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C56",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NC2- Class 2 2021 RUS",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 494,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:19:03.216Z",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:37.520Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q1 2021 - Haitian Creole",
            "FrequencyInDays": 7,
            "LangId": 56,
            "CampaignID": "C237",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 167
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 32,
                        "msg_req_sent": 32,
                        "msg_scheduled": 0,
                        "user_add_success": 32,
                        "user_add_attempts": 32,
                        "msg_delivered": 13,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb649c5093f46477f0d2d04d0d1628f5a",
                            "FNc3e93ed1ccd86196ff828e4fab67e4aa",
                            "FN51bcfbe6e898ebfc832be6a461a0b0a6",
                            "FN5d79772181664bfe650a16151a5985ae",
                            "FN3a05415ce9566fbdb5c931a37e69f434",
                            "FN6235efa7bcacf32e354d5653855df80d",
                            "FN5823fe89fa8c5d60e43ffb26f38d7b90",
                            "FN12aa0b6d87fdcf968f965903d5ed3453",
                            "FN375478086a74828e6331a700b818ea66",
                            "FN8e60ecb5772ed9c80cf00a3f560cc797",
                            "FNdf12d873aa9a8485ad3e6abf45012275",
                            "FN48a5c86352f1f9a2cebee92476bc3c09",
                            "FNe1614d2ba77cce9dabaa53f374231fd9",
                            "FN3f0fcdc499e0d63009c21b225a7e2d04",
                            "FN08faaa5db22b5efb4ec04694ae496bfa",
                            "FNca0adb236dee7014c3239ad66dba85b7",
                            "FNe565543383e14bd6847e3f3043834d83",
                            "FNfdf7b4c1c97684a04181945b68ebadf5",
                            "FNef56ba0bf01577f96d48324a046d6837",
                            "FNc0c7712ed777ce911309256ee7f2cb0f",
                            "FNaa95e9b95842ee06b843def9b427c722",
                            "FN03bf686a724f95928d2b7e28063f599c",
                            "FN5ac9b61075ef57180205b56654a0be10",
                            "FN098a24481b580e4a9a001b411116cde0",
                            "FN5bc391dcc7c42450e0498335c6cf204e",
                            "FN03ad20904351586b735c430c7cc9a68e",
                            "FNf03c906e5b794aefe763cfdd94425d5c",
                            "FNe6171128e6534f2222ccd0842eba4203",
                            "FNfc3839569edeacd2ca1473c61e764b36",
                            "FN1ca915f7ee9257241773a869bd90dc1c",
                            "FNd1934883cb8b78b01c23a38fda62e222",
                            "FN0258a4a721a99c0a9e56faa49b5175f2"
                        ],
                        "msg_queued": 16,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 32,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 561,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:54:53.919Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q3 2021 - English",
            "FrequencyInDays": 7,
            "LangId": 41,
            "CampaignID": "C358",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "ProgramID": 317,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:25:09.269Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Orlando",
            "campTime": "2021-12-09T17:00:09.171Z",
            "CampaignName": "Thriving At Home Certification - Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C268",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Thriving At Home Certification",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 248,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 861,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:15:53.624Z",
            "OrgName": "Assisting Hands Orlando",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:37.814Z",
            "CampaignName": "Assisting Hands- Orlando - Program 1 - Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C262",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T06:16:25.678Z",
            "ProgramName": "Assisting Hands - Orlando - Program 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 248
        },
        {
            "MaxProgress": 99,
            "ProgramID": 925,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:49:19.277Z",
            "PresetMsg": "来的温馨提示: Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:44.029Z",
            "CampaignName": "Community Care PA 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C81",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA Class 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 463,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:38:30.632Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:29.337Z",
            "CampaignName": "JFS Care- Mandatory 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 5,
            "CampaignID": "C542",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Mandatory 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 84,
                        "msg_req_sent": 84,
                        "msg_scheduled": 0,
                        "user_add_success": 84,
                        "user_add_attempts": 84,
                        "msg_delivered": 26,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN4db6413659399a8b39daea9ff24c45cd",
                            "FN392f7e9fad338b74b19c5110bd4a543d",
                            "FNe586bbd32591f349fa349880b16b63c9",
                            "FN809d6d26eb8d143640a257293297b783",
                            "FNaf7f6f8bf4a0d478b287c8dcc4ae09e6",
                            "FN39c302e70a804cc5c1e4639ee41478e8",
                            "FNdb0074e0d977e4def685e931299e5d9f",
                            "FNc8cf681875b8c765bc6afd128e996375",
                            "FN84ce0ce1f5b187d25f24ca65ca26149e",
                            "FN65677c266ab89bef80d2ce641dbe2f0d",
                            "FN7cdd9d6cf49f38446a156bd0e30291df",
                            "FNe557ab4d9b470becf552d9ecfdf96135",
                            "FNa2a89693a2f53a7ed7ebc7a52c0a0a9a",
                            "FNe81f0ba55ccc101d10fe00ffecd2fc1e",
                            "FN334ff91871c5e87f3f66b8fb2e671b81",
                            "FNb69b7fa5211bcab1d45eb48455aa5c52",
                            "FN9fc41d62fa51c83c92fe527064438d82",
                            "FNac1ad91daad17c5f60fa13d9d5b6d2f0",
                            "FN92a91da3df3919a0c4f5fb805213807e",
                            "FNde7ae2f4a4dfb2011a3c397ed6320f98",
                            "FNab758074262811b4f9bf6b1cb548fbab",
                            "FNa869e06bbf6e79a8f91962027794e8ac",
                            "FNbfed6697f53bd14e0a43769f5c8305bf",
                            "FN4a1cc1afbfe328767bfe148e4f2b16f7",
                            "FN6d146719ca491edc7b7d74be7edb9595",
                            "FN7b0c51adfbaf2252fcdadd2bbb567179",
                            "FNa1d70274cc812db69491418a161ec0c1",
                            "FNb62094fe45c802a7d8ad4dabbd01ba92",
                            "FN22fd7a3b51ed46233bff18b9277c6134",
                            "FN9583dffa4d34be175116f1d747a87cf9",
                            "FNe556797b57a33a18f8769eb11ae91149",
                            "FN4e964e7217785b61645f03400c52141c",
                            "FN39be7bd10423801215e3decec4c9d689",
                            "FNb1b37bbdd1cddfbbbd91d45d28d94fad",
                            "FN4876443477e2cc9e8fd816e4bd8eb9f4",
                            "FN2620791f513131eb6d960c79716c6d8e",
                            "FN0a93dce037ab87f655fa96d7053c1fa4",
                            "FNd7fcc3c8c834da475ade41c6d68fe090",
                            "FN431f1b763836f6d02cec5bf3ebdcd2b3",
                            "FN9799f0e8a91bcae98ab0bbbf5db36d0c",
                            "FN061d38cbc25137deac2b29391894f41b",
                            "FN4152e360be401ca50b6a942baa47a089",
                            "FNe0e515e09fb2ded76f67b7d07e20aa01",
                            "FNc1a61c511b3718a2970c402f604c0b35",
                            "FN3d09918954dd8c2107afc3fe5088608f",
                            "FN40e0ef2873ec00ce9221cfc090babfe0",
                            "FN40d0ed7db5dc9524f17c3feee7796615",
                            "FNefd3a67a93bfb42e0e255bfcebc6ffba",
                            "FN2ebbe1d59f1af7e63c7714219298b720",
                            "FNfc7714598c95a064ac3a4a6b670fe2a0",
                            "FN82b6628908ad8b028b475d7a93214f0f",
                            "FNb9526950018b046d6fa2a930399bbcea",
                            "FN72c17d6e599913ae1d6469e60205b518",
                            "FNc5d4ff89d2ddbb62080af4fd0d326f91",
                            "FNd77b176927abc621a0823f10c9121ed2",
                            "FN2d1cf5db16e119940a13cc865830a702",
                            "FNacbbb6716cad3bb2f5ac3a662e46e85b",
                            "FNd6e109a9341a9ede68224863d47d3ce1",
                            "FN159920a5b706302722fd25b16bb35f92",
                            "FN7beb1b46567dce316dce7bf33e51e839",
                            "FNa186599c41f4fefdd0b5342ea81f89ad",
                            "FN5377592e9ce9829422f21ac29136ce3d",
                            "FN9d512a3d15a7e43844ea464b2addf0f3",
                            "FN687d21a75229682664c0f151c26bdb76",
                            "FN97cef92ba562ab76951c4faf77b8f8d0",
                            "FNba924641c5615eb0b9e9bf15e57e5b1f",
                            "FN3a1de3cd64195ada86f6a727b95a5bec",
                            "FN2cc4348aecbe4b8c5071864153b44a4a",
                            "FN4c8f4cf1da5bc5adebdbcc4bc988b4c5",
                            "FN615d1cfe5af0d653c6565c63f01b597c",
                            "FNed0ede7d67820ece2f79d25342537027",
                            "FNe78425bf8f6459807cff370f122cbb5b",
                            "FNca8e7f5f4a216ad5b05d69889cb5585e",
                            "FNdc42a56fa55dbae90232f39d473e8a39",
                            "FN989d838e146aface8e744e8598b30205",
                            "FN10c907762bc770030f0561a4777b7419",
                            "FNd49b90f7004ad2cf2918ca1d1185bd87",
                            "FNcd890e1d97754ef7e09b6c3749395b92",
                            "FN3f3981be936f16959715d693e90e3c14",
                            "FN33e29bf5813d1cd89bc0053993f5582f",
                            "FN01d5793c4d7c7bcb15d9535d7fd0e522",
                            "FNd4dc3ba5955d7e7593de4e1bc4615b3f",
                            "FNecdfb7d67524aea66d2e10f1979651fb",
                            "FN623554f9e9ba81e4844da64802ce14ef"
                        ],
                        "msg_queued": 45,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 7,
                        "numOfUsers": 84,
                        "msg_undelivered": 5,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 409,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:09:11.141Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:58.505Z",
            "CampaignName": "Recco - Class 2 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C396",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 3,
                        "user_add_attempts": 3,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN7be8a6275ce7f3b5e0350341d0d4ab4d",
                            "FNbf87f54bcdc6cad94a8ae2ce6af3f2a8",
                            "FN872c66a1186891dc1fb2c85f7f80907d"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 680,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:05:34.882Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:41.877Z",
            "CampaignName": "4 Hours - In-Service Program - Haitian Creole",
            "FrequencyInDays": 3,
            "LangId": 56,
            "CampaignID": "C432",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "4 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 683,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:24:31.599Z",
            "OrgName": "HDA Care",
            "PresetMsg": "来的温馨提示: HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T20:30:00.917Z",
            "CampaignName": "HDA Class 3 2021 Chinese",
            "FrequencyInDays": 1,
            "LangId": 30,
            "CampaignID": "C313",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 3- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:26:34.685Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sundance Home Care",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Sundance EVV-HHAX 2021 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C200",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 225,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 703,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:43:10.846Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care- Hospice",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Hospice Universal Compliance 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 4,
            "CampaignID": "C526",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Hospice Universal compliance 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 214,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 265,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:46:34.224Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 2 - Haitian Creole",
            "FrequencyInDays": 3,
            "LangId": 56,
            "CampaignID": "C462",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 2",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 782,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:49:46.117Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Q3 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 4,
            "CampaignID": "C575",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 705,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T00:21:52.511Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "来的温馨提示: Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:21:25.936Z",
            "CampaignName": "Ultimate In-service 2021 Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C346",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-service",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:36:11.287Z",
            "PresetMsg": "来的温馨提示: Community Care: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:03.716Z",
            "CampaignName": "Community Care EVV-HHAX 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C68",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 629,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:55:58.605Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示：Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Caregiver Provider- New Hires 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C528",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- New Hires 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 875,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:56:41.677Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "PCA 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C233",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 4,
                        "user_add_attempts": 4,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN54302a8438bedab272defbece3c87a21",
                            "FN4d26b9766bf88cf13fef45b27f3b2b88",
                            "FNa9f2fadaa7a45416813e6744b18b0b7c",
                            "FNca204b7b54f72f9cc11e805afdd3caa1"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 461,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T20:33:28.993Z",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q1 - 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C501",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston Q1- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 136
        },
        {
            "MaxProgress": 99,
            "ProgramID": 712,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:58:36.275Z",
            "PresetMsg": "REMINDER! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:55.424Z",
            "CampaignName": "Compassionate Hearts Orientation 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C170",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 35,
                        "msg_req_sent": 35,
                        "msg_scheduled": 0,
                        "user_add_success": 35,
                        "user_add_attempts": 35,
                        "msg_delivered": 12,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN16ebba2b792006bf714d14e777788927",
                            "FN25e4baf9aabf0103aec5eea2501e818c",
                            "FN8c27e12fb676e494c750bf881415bce0",
                            "FNab28f99541fecb2aef7ecb3104c5607b",
                            "FNe94fe4c1f53807fd9a577111fcc0e1b1",
                            "FN28934cc16cfe3f2a0ff56f89ff1f664c",
                            "FNd2663868a114582ed41fbc041cc455eb",
                            "FN04c755090aac5fc73c2c6a128c745c78",
                            "FN791446e37b6dd64ff9b810eb55c4a4d1",
                            "FN9c6b2ad2fb4a3b75b925623eaea74ad7",
                            "FN15adae484acb7f3e6f0bda2a54d55f54",
                            "FN1f7621793bd03e8fc3d02513c6f8fcbf",
                            "FN98155ddf594cd4f2704a1bf2bed99fdb",
                            "FNac595e95c5f80ef6f4a9ffaa8b4bf600",
                            "FNfa22efebd7a14c8021546099b10328a7",
                            "FN6800b4bed25f678ea2d5ebd4b56372c4",
                            "FNab2dfc5021b68aa7f2091cecfae920e3",
                            "FNa894e337be7bdd769ead239a537b709e",
                            "FNd872ea8f20dc08b0003fcaebd5f2a1f2",
                            "FN5a332fa48c19e2c002f11920cdd7ee7e",
                            "FN307f659034f52fc942d9094aef8879b5",
                            "FNac94b54086ae4d210d33cff649312f90",
                            "FN085200d8f9932b30bf55cceaea31eabe",
                            "FN9cf53f7e2faaa6294e19252541a3a4c4",
                            "FNfe60752d58e87c309d53b18568ae4ae4",
                            "FNc4d5123319693af8d9e377be71595693",
                            "FN250de32433dfc3b148658e414f3452e0",
                            "FN207b1ee17b1621ab8a3a9ba9f369d924",
                            "FN3af5ac85d98eb7f1c983158a6c2158f7",
                            "FN295a99a00caf2fd0305979d29ac938ef",
                            "FN2250ae5638c3a468faff507977b46d79",
                            "FNc8fd69399c5e8fe410770c3ccc4452f3",
                            "FN87d1ee531f43a36a7005128c3d44383c",
                            "FN44a96b015096eeb01c15dee547b796fc",
                            "FN5259ad9561e7fe7f39553624613f1d98"
                        ],
                        "msg_queued": 18,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 5,
                        "numOfUsers": 35,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 531,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:23:57.643Z",
            "OrgName": "Bryan Skilled Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Bryan Skilled Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:26.307Z",
            "CampaignName": "Bryan Skilled Home Care - Aid Mandatory 1 EN 2021 - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C369",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T15:27:33.284Z",
            "ProgramName": "Bryan Skilled Home Care- Aide Mandatory 1 EN 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 87
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 681,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:23:19.959Z",
            "OrgName": "HDA Care",
            "PresetMsg": "来的温馨提示: HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T14:00:26.859Z",
            "CampaignName": "HDA Class 1 2021 Chinese",
            "FrequencyInDays": 1,
            "LangId": 30,
            "CampaignID": "C311",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 1- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 797,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:31:38.057Z",
            "PresetMsg": "НАПОМИНАНИЕ! Classic Homecare: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Classic Home Care",
            "campTime": "2021-12-09T17:00:02.957Z",
            "CampaignName": "Classic In-service 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C206",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 239,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 841,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:17:22.566Z",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "OrgName": "M&N Home Care",
            "campTime": "2021-12-08T17:00:48.423Z",
            "CampaignName": "M&N Sexual Harassment Training 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C62",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sexual Harassment Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 240,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:15:44.397Z",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "OrgName": "M&N Home Care",
            "campTime": "2021-12-08T17:00:39.412Z",
            "CampaignName": "M&N EVV-HHAX 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C61",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 240,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:29:42.783Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sundance Home Care",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Sundance EVV-HHAX 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C204",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 225,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 562,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:04:00.659Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:03.294Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - Haitian Creole",
            "FrequencyInDays": 7,
            "LangId": 56,
            "CampaignID": "C366",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "ProgramID": 606,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:37:01.593Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Compassionate Care Universal Compliance Program 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 4,
            "CampaignID": "C513",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compassionate Care Universal Compliance Program 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 188,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 652,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:59:39.784Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "НАПОМИНАНИЕ! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:59:46.553Z",
            "CampaignName": "A-Plus In-service1 ES 2021 Russian",
            "FrequencyInDays": 5,
            "LangId": 134,
            "CampaignID": "C384",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 ES",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 497,
            "DaysOfWk": " 4",
            "TimeCreated": "2021-11-29T20:57:12.071Z",
            "OrgName": "Wyncote Wellness",
            "PresetMsg": "From Wyncote Wellness: Please complete ALL in-service training ASAP to stay compliant. Click here to get started: https://bit.ly/reminder-VIV",
            "campTime": "2021-11-29T17:50:51.175Z",
            "CampaignName": "Wyncote Q2 2021 Incomplete",
            "FrequencyInDays": 1,
            "LangId": "",
            "CampaignID": "C28",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-29T20:58:08.462Z",
            "ProgramName": "Wyncote Wellness- Q2 In-service- 2021",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": "2021-11-29T21:01:35.745Z",
            "Active": true,
            "OrgID": 169
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 6,
                        "msg_req_sent": 6,
                        "msg_scheduled": 0,
                        "user_add_success": 6,
                        "user_add_attempts": 6,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN38e4385d93353337100680e700392aab",
                            "FN28815bad676e3641ca53b95cead5d719",
                            "FNa416d778fbbf4d9b6273f8c6b25359cc",
                            "FNe2eec5791c000ba90a8698041ec6182a",
                            "FN754a94e235b6e2497c09180d5aeaaca9",
                            "FN3f66b8d4bcfde8d63954a2c7a17eb638"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 6,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 290,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:34:17.225Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 1 - Inexperienced Aides - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C492",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1-  Inexperienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 856,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:30:13.375Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q4 - 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 4,
            "CampaignID": "C510",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston Q4- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 136,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 494,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:20:36.027Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:30.580Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q1 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 7,
            "CampaignID": "C238",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 720,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:14:22.442Z",
            "PresetMsg": "REMINDER! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:47.538Z",
            "CampaignName": "Greater Love Orientation 2021 English",
            "LangId": 41,
            "FrequencyInDays": 1,
            "CampaignID": "C191",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 71,
                        "msg_req_sent": 71,
                        "msg_scheduled": 0,
                        "user_add_success": 71,
                        "user_add_attempts": 71,
                        "msg_delivered": 24,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN920187174bfe74b591218ee61062c2e0",
                            "FNb1e9f47e44d225c6d6fed5ed498eb1e5",
                            "FN91ea55e71f6fd29681063aeff353482b",
                            "FNb16bf56d051b46957cf7639f08a06ffb",
                            "FN09aa6c39c8474d70a0c6840123c9a0aa",
                            "FN2962dc48aac2a1b93fbeddeb3e1b63fe",
                            "FNc0ea583332248c291ab54896c72d6d32",
                            "FN7cf60d80af23d5331052dd2580fbd301",
                            "FN0b35830ad962ced0b6abe9e5ccdc03c0",
                            "FN1ff80d1365be5bb8758303aeff0bc19f",
                            "FN542afbbffc682e472dcc95757fdc29d1",
                            "FN56ed49903b3b9b3c7fb9cef173d8f85e",
                            "FN92ae268fb1d5d8d94443d3840b0e23fb",
                            "FN89183f4a44dcc257259961f7b7ff076a",
                            "FN5b9e145da3d7e2a59faa2b4109b46165",
                            "FNb4a2c148391f46333dbb15ec681efb38",
                            "FN7038280d1dbf172be1953b74ff62e0f1",
                            "FN738ae735d52f8f0f7cc5653abf16764d",
                            "FNf45234036f75e4d7527cf3c9befc4925",
                            "FN4dd2fc09c8dedf2b04ce76e10e586c4a",
                            "FN540e2932415d6c42426ea2598d80fd9d",
                            "FNb5324e5885255f0e5c2339183ac6d6ae",
                            "FN57ac127cacd54806e6b891764d355fee",
                            "FN0ff48dfecd3aee50e9f17d817a273b57",
                            "FNfbaeece6e3fe2ed5cb5318f014d771d1",
                            "FN6c4f650c7254a76a9256d79d6987b7a3",
                            "FN7a07ab5d4e4fe81f81b9d7b85755ce8d",
                            "FN58e01c0081312ee55ab4e89d65624658",
                            "FN8c13fe0bbba8bb61f1349c67baf5f6c2",
                            "FN0d8c74a297a77ad5280123230e4cc3f4",
                            "FN93f1f0cef8935a9f0e78f3d7cb35dc64",
                            "FN814024743bd8f14045839aa4c31bf5fe",
                            "FN291080f03334aea25b7c8a2c260f3c0c",
                            "FNbaf7bf7df81b22b9b730b732ff49bc5d",
                            "FN67f7b5a42fb090cf86adb9a67b922681",
                            "FN016d9560c387bd38ec453d30781a3bf7",
                            "FNeae365d8b799277612c6782c86a2085e",
                            "FN2348e5f5a47ce7c3a578d6c91963b91d",
                            "FN521994651ec979588774d9cdd2880040",
                            "FNc32ec2ef5eaa7e882238ada03ab3f095",
                            "FN1f2b9c94a587393b097a6744962b30f0",
                            "FN915667aa6d3b96a07d3960745901ace7",
                            "FN4c91c085e797ecc4efabecd5d1e64f53",
                            "FN89886a436b3c44ace42bcdbde2070a46",
                            "FNe266dd0486a8279869537c7dc2d98b35",
                            "FN1bef28d228f986719435772b6ce848d5",
                            "FNedaa38f2325baa00cd8745fe8bcc7f12",
                            "FN085e2b1a96d7f248feb708f24e999d37",
                            "FN5e55e28b187c6e96aec2415ab71759ad",
                            "FN3e719be6695ec2646853c6696bca5b58",
                            "FN3a6df405f06e54e7b529849651a7a831",
                            "FN0524e4f2aa8e647970c320268cabd5b5",
                            "FNf56012b93b68e2c7329a98a1fd05548b",
                            "FNc59417baf9816e45db6d8838211c9990",
                            "FNaf5f411610b21e7660f9d3c756b67f9b",
                            "FNabb59ddb92fe9c932f0f6d09cf35496b",
                            "FN497c1e2a42c48ceb135d3ef320fda560",
                            "FNe19e67f6bae129a62deab9b1e4755786",
                            "FN6fb66c941f7d60e6b35dedbd8c7aee90",
                            "FN50b18da76b707f51f6881571a036a5b3",
                            "FN00d1967ca611a8a1fc5435e2ed93b8ac",
                            "FNdde1b5df0fe7094c65ac24a3867072f5",
                            "FN23b4f9d3fa27bbf120194c5294cbc74f",
                            "FNf8a8cb7c6238984f7691d6d255024434",
                            "FN5e4c3477e65239aa2daca383d8b7c27d",
                            "FNd10d472e1e10544a061d82fe38d0b5d2",
                            "FN655a05abd20290e57f1055fe92803546",
                            "FNc3751dabc7e541c28a3f2cf21f1e4ad5",
                            "FNa63fa5f6168d68d457a3c21cfc059593",
                            "FN641c807406fea734cd521fd006c9f70d",
                            "FN6e5c90306c7fa054cb50c036ddf37f09"
                        ],
                        "msg_queued": 40,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 71,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 410,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:13:19.441Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 3 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C399",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 710,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:54:07.515Z",
            "PresetMsg": "来的温馨提示: Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:33.767Z",
            "CampaignName": "Compassionate Hearts PA In-service1 Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C163",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 438,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:49:34.940Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q1 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 4,
            "CampaignID": "C567",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 863,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T04:00:28.171Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:27.532Z",
            "CampaignName": "Q4 2021- Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 4,
            "CampaignID": "C584",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:25:01.092Z",
            "PresetMsg": "REMINDER! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:51.489Z",
            "CampaignName": "Community Care EVV-HHAX 2021 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C67",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 561,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:58:27.241Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:35.381Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - Russian",
            "FrequencyInDays": 7,
            "LangId": 134,
            "CampaignID": "C361",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "ProgramID": 915,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:44:15.245Z",
            "PresetMsg": "REMINDER! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:24.069Z",
            "CampaignName": "Community Care 2021 LPN/RN Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C75",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 LPN/RN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 519,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T21:45:14.908Z",
            "PresetMsg": "RECORDATORIO! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:00:37.359Z",
            "CampaignName": "Sophie Q2 Safety ES 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C280",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q2 Safety ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:17:26.739Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions?https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T17:16:57.787Z",
            "CampaignName": "HDA EVV-HHAX 2021 None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C305",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 3,
                        "user_add_attempts": 3,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb867a05cd94e2d1f66b942e9b66d4475",
                            "FN37c2c6d012a5ce0c61989b8289d33528",
                            "FN8fe24c90d13ad70978193778d500d1d3"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 410,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:15:48.949Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 3 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C401",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 561,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:56:02.263Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q3 2021 - Chinese",
            "FrequencyInDays": 7,
            "LangId": 30,
            "CampaignID": "C359",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 13,
                        "msg_req_sent": 13,
                        "msg_scheduled": 0,
                        "user_add_success": 13,
                        "user_add_attempts": 13,
                        "msg_delivered": 5,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN689cf22698095fac0ae80e026cedd4d9",
                            "FNefcee6c88ac4feefeb7f1725e6590fa9",
                            "FNa2387041bc3d144f7f85828b518b3e71",
                            "FN72dbe8ad96078f392ea665d52b303b77",
                            "FN774b8e49e9ee15645e5f1a142c500e83",
                            "FN7f88f9bc416ca6581805565493ef4e39",
                            "FN8a4bc9a0ad0b56637377a0ece8e8a51c",
                            "FNedcbb866f1e636530dcbb7e3419e473f",
                            "FN398717d7d1f82d296e85775491a7ad51",
                            "FN4b9f4683881f3d4d7b4f95815e2cf064",
                            "FN812d6f16e6bf7b56422ffcf1fa0d51f5",
                            "FNfa8c9f90347b37cb3e8304ae0c3a854e",
                            "FN60a5f4c7b984bfc4572a80c382b218b1"
                        ],
                        "msg_queued": 5,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 13,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 284,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:16:16.924Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 3 - Experienced Aides - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C480",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 3- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 284,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:26:19.872Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 3 - Experienced Aides - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C485",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 3- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 510,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T17:48:43.631Z",
            "PresetMsg": "RECORDATORIO! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:46:49.497Z",
            "CampaignName": "Elite NCS Class 2 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 5,
            "CampaignID": "C47",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NC2- Class 2 2021 Spanish",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 80,
            "ProgramID": 117,
            "DaysOfWk": " 5 6",
            "TimeCreated": "2021-07-27T09:42:32.355Z",
            "OrgName": "PRECIOUS PEARLS HOME HEALTH CARE",
            "PresetMsg": " {firstName}  {programProgress}  {generalProgress}  {agencyName}  {webPortalLink}  {programName}  {userEmail} ",
            "CampaignName": "the 1st campaign",
            "FrequencyInDays": 1,
            "LangId": "",
            "CampaignID": "C1",
            "MinProgress": 23,
            "ModifiedBy": "uri@nevvon.com",
            "CampaignUrl": "/c5",
            "TimeUpdated": "2021-08-02T05:59:17.432Z",
            "ProgramName": "Precious Pearls Part 1- 2020",
            "CreatedBy": "uri@nevvon.com",
            "Deleted": "2021-08-19T21:24:47.986Z",
            "Active": true,
            "OrgID": 122
        },
        {
            "MaxProgress": 99,
            "ProgramID": 465,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:43:13.078Z",
            "OrgName": "JFS Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T22:30:07.669Z",
            "CampaignName": "JFS Care- Optional 2 2021 - None",
            "FrequencyInDays": 5,
            "LangId": "null",
            "CampaignID": "C545",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Optional 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 109
        },
        {
            "MaxProgress": 100,
            "ProgramID": 9,
            "DaysOfWk": " 0",
            "TimeCreated": "2021-10-21T15:24:48.843Z",
            "OrgName": "Triple A homecare",
            "PresetMsg": "aaa",
            "CampaignName": "DUMMY _ NOT FOR REALa",
            "FrequencyInDays": 1,
            "LangId": "",
            "CampaignID": "C19",
            "MinProgress": 0,
            "ModifiedBy": "uri@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-10-21T15:25:42.964Z",
            "ProgramName": "2019 12 hours of in service for HHAs",
            "CreatedBy": "uri@nevvon.com",
            "Deleted": "2021-10-21T15:27:17.365Z",
            "Active": true,
            "OrgID": 64
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 290,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:38:27.100Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 1 - Inexperienced Aides - Russian",
            "FrequencyInDays": 4,
            "LangId": 134,
            "CampaignID": "C496",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1-  Inexperienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 740,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:36:36.177Z",
            "PresetMsg": "\"URGENT From FADMO:\nComplete ALL In-Service ASAP. All training is due by 12/31/2021. Get started: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:05.531Z",
            "CampaignName": "FADMO Q4 In-service 2021 None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C154",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q4- In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 322,
            "DaysOfWk": " 4 5",
            "TimeCreated": "2021-10-07T15:58:43.658Z",
            "OrgName": "Customer Success Team",
            "PresetMsg": "chinese test",
            "CampaignName": "languages chinese test",
            "FrequencyInDays": 1,
            "LangId": 30,
            "CampaignID": "C15",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-10-07T16:05:44.525Z",
            "ProgramName": "English Program 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 154
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 5,
                        "user_add_attempts": 5,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb090bc07fea4fd9d89957a283e9e176a",
                            "FN3aaf8cf26b8cbd0fca90ce81b940ac7a",
                            "FN378127e3c802dcb292fddfa2c119a8e6",
                            "FN665517769de941234fe6a5d8bfcc11c8",
                            "FNaf06806845265f4234437ea7dc167911"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 5,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 316,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:50:19.817Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Spanish - Progressive Home Health Care 2020- Class 2 - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C465",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Spanish- Progressive Home Health Care 2020- Class 2",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN415a05ecb0fb9bfc1f74fc3e71d0f44d"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 263,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:41:45.390Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 1 - Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C458",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "28.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN80ba9977cb2225816cf1ab353d617cd6",
                            "FN8bf334609da45b495bf8c8fffa11b1a1",
                            "FNbec23e956d93a6097960d9e738010d20"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 178,
                        "user_update_attempts": 3,
                        "user_update_success": 3,
                        "msg_sent": 2,
                        "numOfUsers": 181,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "29.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 180,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 180,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "30.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 176,
                        "msg_req_sent": 176,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 144,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNc2cf5841dd5a80fd17a76689cd6329d5",
                            "FNaa9991b5b2e5f42506269ed06e98c9df",
                            "FN11861d47c557ea373dd06320d66beb12",
                            "FN986c1232528c1ab132c00996f888ae16",
                            "FNe9913a4e446d85026654dad49f5f6e14",
                            "FN9eac56dcab7ee94656b676ab46b2406f",
                            "FN3a45dcb4853bf0e1998c2ca500cbb8bc",
                            "FN868d90b3b4c81f9a6299023e22e7b742",
                            "FNc4d9b326af4839df08cf2955809e4e3d",
                            "FN9718f966dc46c8fb778a9c23b7d21311",
                            "FN302140768c7fc8fee2db6b7a0a1e78df",
                            "FN267be71f26ceecd27cbf6d92685b6c49",
                            "FN7ff402bf8844f821ca0240d96734ff6c",
                            "FN5a0fc87377d566be3e25882868816d3a",
                            "FNba43301cbba86f6c7b32b0820905987e",
                            "FN6c335dbb8d8d8e89a0510d918059a811",
                            "FN6c3b0e649dde098abd0592116995aece",
                            "FN2c1ef6eabc0469f5bb8cdd6ddef007a3",
                            "FNd6dd62ce296961d70ac4edbdd8495a7f",
                            "FN9ef2423c0a362f1529a2c4479b2a6722",
                            "FN957d88f0296949bcf0cc305a7c12286a",
                            "FNac5e2fc6a3b7d085c2520164b002b37c",
                            "FNf8f2c38cfc04232ec796fefd86f81edf",
                            "FN1cdff19a738205077f94aa3686ebce5e",
                            "FNb366d8f1eccb80ece58a0d486b91e588",
                            "FN12637ad5dd1d28d29003240af624158c",
                            "FNec548e77c09412f2987f26240f8e29f1",
                            "FNbeab5c7954a01024075ff84dd0dcf82d",
                            "FNb6cf6394d9bcd608b2a588acd7954dd8",
                            "FN4cd6c5be09755002949cd5bc13c5fca6",
                            "FN0fd6ebb3cb9965cbb3aaf194651778e6",
                            "FNc5cdbfb5196d7515a10ad8934df503b0",
                            "FN2f296b82acef0f2f8acdbddb48454583",
                            "FNf82040c3577965258be8a134061d8f5f",
                            "FN53675606272294329dbc75f87b6b4408",
                            "FN697e7203cd11d35bb5c8f1a88c63a03b",
                            "FNb4046103e6f9b4794d60872a1614855c",
                            "FN4bd4e7bbd030af2f2d192e682f30af4d",
                            "FN2f448bd1c40f4364ec5cfdce2ebe49d7",
                            "FN5b08404784e6cce29baa6982e3ffab41",
                            "FN7cf82dfabc0fedb79cb9d34a66529fbb",
                            "FN17c553839eb63178822e14f271c7d621",
                            "FNf8e89fa5a4ccfa915556a71146400e21",
                            "FN37aef506e2e640e43e11a9ca9b74b1e4",
                            "FN5dfd9682944f3ac4d1b48eeff6b686a4",
                            "FN481937d9cd56489e2bd298c3850fac4e",
                            "FN52960063ff55b664f784d080eb22cf19",
                            "FN31380ec60f322bd73c6fee2960175528",
                            "FN97ba46c66a4329be5559d764ab88bba0",
                            "FNcd3b0b1536310954eddcbc7df3326149",
                            "FN9bb7c1da712270b703343517443cbb29",
                            "FNe26368091d1913ba6460943727f9e83e",
                            "FN68665168ecd18b9ca6a25327af1bbe63",
                            "FN9855520d5b77d13fbef93a5f4e592547",
                            "FNca8c4981806807d33d4984a84bb484c6",
                            "FN63782ef793243c8ec3a1d327ca5a48dd",
                            "FN5ee6f31b6707c000ffe06971ba7cab47",
                            "FN144728cecc559aa4411171a3e4380d72",
                            "FN70446b12f77c17ffbfbedfe6c1907b83",
                            "FN250e405f307f307e0f70cac2cc2c5ef2",
                            "FNc64658d64578bb04da09f6620581e821",
                            "FN589d2973ce4876b8bade80ccda4571b6",
                            "FN9604185589fb74ec595adcd76ed2cfaf",
                            "FNd69075c798608871a0e1ee621f1d7ffa",
                            "FN2bc8d0c2797947ef9850266bd8ce776d",
                            "FN549928fa2996b4d9fc11fbca21025cfe",
                            "FNedaadf81d402f83cb204792188c0eb3e",
                            "FN01e4cd720c301033e827c1d389d264c3",
                            "FNa4adaacfae0e779135c778733d45a71b",
                            "FN0bb27714c67d7ed6b44cb1e82aff4544",
                            "FNea17277eb74be84afaf20116f7e1bf23",
                            "FN2b7c29dda31db67cedfd46b2e35a4adc",
                            "FNcb51663bc2e0b7001eabf931439b29bf",
                            "FN4de520c0b75c5417f03d1dcaf821d735",
                            "FN57306cefd52481228b82b934808bd1ac",
                            "FNd2085d92e9ba0135fba244fecd0539ed",
                            "FN654fc83400bc5eabacd6b6c4194e8ecc",
                            "FN7f7c9f9ec5b6a1ecbd4bda2081e32a93",
                            "FN73ea389d9749f84b5179a106317979b6",
                            "FNf1e05c64244345436c73d183ec1f909e",
                            "FN216bb7a18b8dd13e499974d26bb99938",
                            "FN0579dd24a106ed4a14b484f7ca189b3b",
                            "FNc366ba545e7ca8fd9c061e34550ac2e5",
                            "FN87c130c2cc9d713b44a8c8bc747f2d1b",
                            "FN370e9d857e0321d8915b8dbfb672f0f1",
                            "FN8c22143f9fc24e0a8150d3395aaa960a",
                            "FN3dc994f990bd558621644d7c6b78ebee",
                            "FNf24feaed8cf436a823334dd0c7d3828e",
                            "FN34f1d634e5282f942670a229a158460b",
                            "FNba19936b17358782fe997d5f033ea6e7",
                            "FN6cfad8d98947fc394b9e133422ee5df7",
                            "FN5e92076d97838351ac7471bcb93cb089",
                            "FN111f03e31fc1c71680840a989ca14b69",
                            "FNb4d09f62d309087e6d1af178c74625ed",
                            "FN397645991699ec5c064a4679ac5adc24",
                            "FN2a7e9a7426addbf06c9ecef5820e32fb",
                            "FN18715d609c90492e19955b3aa6d67ddb",
                            "FN0c2feeb2c77d0cdfc85546cca503f96f",
                            "FN0493f61c324f86f99d0aa929712a6660",
                            "FNb5b554accce041798b61839e33607bac",
                            "FN01b041fd3508f99e6f8775a9d5e50f7c",
                            "FN73f77bf4890c4cb91ef1c70c8b59276e",
                            "FN101d1c643e6190ae085fe5705ad08adf",
                            "FNa6159ec8a72894bc83099e50818277ee",
                            "FNc979d5ecf19831832dc97865c77bf89c",
                            "FN2f8b99418a6250b73be8bdaf5892986e",
                            "FN624f032cd991f3c7779009fc335669cf",
                            "FN83a9b53bb9f724ac82acb5d03ac11295",
                            "FNd199f15794eb508725c42c9d368104d0",
                            "FNe288bfaee488163f45f35ddce8159cb5",
                            "FNe4b784891eaee51dad53b43e498276e7",
                            "FNc218dbc9c14df563fb2ff484f8844158",
                            "FNd100e90daaf81c1e0d20e4b6b7875863",
                            "FN2b2d4b4818b5352eea9c54eb087bacbb",
                            "FN77547845af5b5367f61bfcd0a8aaf43b",
                            "FNd872a8c309af5ada2b36a7a034f26e37",
                            "FN04fe1c942b6cc3772330be9b872afd70",
                            "FN76eaffea0087681deb756f02243462d0",
                            "FN8ca22c5aa51af3081642e46119514fc7",
                            "FN0eee384b4e97c453d46ab0fd0984c8d4",
                            "FNab23ec71ee21e86bde82a391c8a528f7",
                            "FN69bec02e537e10c8e20ce541ec8cb8d9",
                            "FN0d99436c4be46d265d135aa6c4d3537a",
                            "FN740b0e09964ecb4b991f81521384ccff",
                            "FN67aa2d439b805233c0baef52a5fd758a",
                            "FN5006c2bd02128086c7e050d8acc055a2",
                            "FNd355fd986db94d5371c0c8d774cf7d22",
                            "FN92c0ad845a00c29f013b672b1b9fcb0e",
                            "FN9b57cd42aba86fe129a83d2bf2d3b0c2",
                            "FN8f906c0faa290b238c95bb7397e44d68",
                            "FNd37a4204c3af97f6ff773bd44dc2ac69",
                            "FN44db01da4e479bad1be8c60ec2e08e09",
                            "FN264e1f825ce6d6d35a2b8721f15344e3",
                            "FNba3073f5b812cdd6b69206a711ec83ec",
                            "FN0189fd034f766f96721d34647fb038ef",
                            "FN57a99f569444ec51cc1401d5017c68cb",
                            "FN28b492ce41ab033aa9f8262ff72e3a82",
                            "FN2c302f66379a2b52ef4c1460a6c2b7fd",
                            "FNedfbe67003de504dd01f894f9e471b98",
                            "FN2e306eb54b4c5df6b3720ef7a5ad84da",
                            "FN4be979a30e8453115936464540b465e0",
                            "FNebb1a04ebb496cf261a3a80a7c61c811",
                            "FN1f99d6b2626eebad27f4e3365ee1f95d",
                            "FNf8f324aaa5a0686c892e30822689975d",
                            "FN3316a9ca4c7e96241459f08448a1e548",
                            "FNf7d3aaf1f924c12c199bf962dcafebc6",
                            "FN27bef3ca4b689ff4a23887ef0484a690",
                            "FN7912ccdeaa890482646387802a1861e3",
                            "FNa10d6d147e8f697c5adae985c6f9a950",
                            "FN9266bfc4902e54d60efd63a4dbf287d5",
                            "FN5e692885c93d1cac1bf43a97fe01a360",
                            "FN2d36d96e2c6a2a1c78f755f75bb8b420",
                            "FNc38008427762cb799a5deede84c796c7",
                            "FN39a891cf2554e738c1b058efa1284148",
                            "FN6ad79150cf1b71ec531d261ad3a9aa5b",
                            "FNf3f531a925d83486283f335b1a1ba427",
                            "FN18b4b9c333b9a8820aee83e7c2a7fb6d",
                            "FN343ef72a5176cd4f0241feda103dda73",
                            "FNcd35bba7891f689bc7e607bfff727a2e",
                            "FNe94c7bb7c2c24ce73e5b30f8936a82cc",
                            "FNfb2f1dc3ef5fed70051ed887adeea469",
                            "FNc7e611123cf3c4695c9f74c6c1e557de",
                            "FN4024f2bd6ba6c1585b57d33d75fefc81",
                            "FNabbf091890eb75b29f576d68f02bf73e",
                            "FNbbfe0d84365c5c96f41f97f811cbcda6",
                            "FNd150462b86eea5f897751d9c2062b0a3",
                            "FNc4c4be42d2b1c3a0ddb227175798e37e",
                            "FN58fe3ef5e488b74d31ba97dffce1587b",
                            "FNeccb2faf389aa7db42306468edf87c8f",
                            "FNe66787ff9260b8c1c4bcc3630c990ab3",
                            "FNa5c357bcf471a8f6c40d9509626fb5f5",
                            "FN99da219bf2af7696d17b8f1876eb8077",
                            "FN5a9fd21c47268b10adca108418e310a9",
                            "FN9c9cf5267906c979215801e1e77ea60f",
                            "FNa975cadbddb7b03eec0150b64f937e6d",
                            "FNa889614860fa393da0d6d78cb9bd5cd3"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 3,
                        "user_update_attempts": 176,
                        "user_update_success": 176,
                        "msg_sent": 15,
                        "numOfUsers": 179,
                        "msg_undelivered": 16,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "01.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN13725e9f991c6168f4ef7c2fd638abc6"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 179,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 180,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "02.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNee0323bc9c8560b71c2448580eb35e83",
                            "FN3b79d78733f2a3a57158638c89dc0f2f",
                            "FN1891a3492b8c1ddecdf5081546935717",
                            "FN9ad7089701686dcf1a028c0194af0b16"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 177,
                        "user_update_attempts": 3,
                        "user_update_success": 3,
                        "msg_sent": 1,
                        "numOfUsers": 181,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "03.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 179,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 179,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "04.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 174,
                        "msg_req_sent": 174,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 141,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN9ea4429c93f9afed2c7be8d169e6aebc",
                            "FN3aacbb2345c9b098752f3d692f6d26c0",
                            "FN2843410a27bcfbfc3364f436db96f442",
                            "FN4a6240dabd578220326bab7884419ff6",
                            "FNa343979e0f7b42a7b569886d074c4b77",
                            "FN5150eb87146af5f9cf76e30087e8f574",
                            "FN647ad861e6f45514a9f849b23c13fa1c",
                            "FN62c57aa6f6ed8078035de4ca3d3763fc",
                            "FN845c5e7d612fa3a9fe2c5b82782efcf2",
                            "FN8d5dc7e92c3640324a63d826cc10e008",
                            "FNe4694588207e63514b818170d997c3b8",
                            "FNee7e0da2af2d3a731e1db5e3c8ff8daf",
                            "FNe0a44cca686725d284e51262e088f79c",
                            "FN98422d9e6850e37b4d7a21f85facb043",
                            "FN753dd745d2588343591494510f933f67",
                            "FN61172c1fc9b0edd4cea59794adc0de72",
                            "FN99eb2729f3788b162cca9e247e8aa6db",
                            "FN16a8df766ba40b349557f25994e7f598",
                            "FNdd8380c06e058f0faaa47b54dc6e1d10",
                            "FNa968562ca6cd7539b78c5903f157e41f",
                            "FN73e637e239a52cfb6a9e78748bccc6f1",
                            "FN8e09f3af5fe96aee41ef80d443b4d2f8",
                            "FNf99d8813d95604a178402478bd0be056",
                            "FN42236155119e91249c26c5e8c8f34326",
                            "FNbe82690285499ef75364e63ef57ed05f",
                            "FN83eede45b7ff7a1529480c8b156af993",
                            "FN4965bf265481741dbe543b0ad7c42bf2",
                            "FN63deb1fec41ae73d503f05f8f5425a2e",
                            "FNcf62620336b38320a3c7708029e0eb43",
                            "FN2305ad9df17dd44c842fae62d7e819d2",
                            "FN7c064b7604bbed59bdf4896f4a59d220",
                            "FN431fe3f4de0dface800d58a2caa6335c",
                            "FN870129303ba74da97056aac16568793d",
                            "FN5fbeef11e9b570151db4d2184fd8dc27",
                            "FN51defe998a60e748396c1e4f80fc3831",
                            "FN80dee8738875e1f5848c85ae92829113",
                            "FN7dcc243c03ff1c12d92e7231efaf6f3d",
                            "FNd28514a21dd34b2ab83e79d567da1cd3",
                            "FN7be9d1224bb40edb8c7c44cf298643f6",
                            "FNcfb27af4c469876f6aef1e89e51b968c",
                            "FNc201c3f2fea7ec409f745c92c41ee6e2",
                            "FNf13fb3861914a3a918356a2f1d7e4ecf",
                            "FN86dc6804958a96ebb45e6d890391333f",
                            "FN1ecffdb66cfe0bb23e0a9ac7b193105c",
                            "FN6beb16e7ab5989e7872088a9ea9b441b",
                            "FNe48b518136b0563b93bab09916ebd65a",
                            "FNfc4cc7bba948c06ea6e81caacfcdd600",
                            "FN558d753e4be53367cd1a0c6991d0afbf",
                            "FN5f52792520a49a913058b9910492e551",
                            "FNe6bef2334612726cdf3e3d2d43066e1c",
                            "FN98dd3fd3a04d1c65be224bdfb564b561",
                            "FNbd51a7ad81672a022b1f219b226823b6",
                            "FNebcb4d30935cb4c790d0534d2c41defe",
                            "FN04720c489a84707e24ed02f2641e139a",
                            "FN4f7ee2383b2c4effc399b22c9ccd39b3",
                            "FN8cc1ac249fd78b785892e50ec9d8c37f",
                            "FN0fb9628cd7b42a906d3fd38a69aab893",
                            "FN63616e91e9821f0ab6e41d27bcca754e",
                            "FN154e106e6f53b3f7ed5e8aafe1020176",
                            "FNa677ee7f485f35425ad8bdcff486ab69",
                            "FNfad000c5f4e0b728e15413c14bda5ed6",
                            "FNc3ccf085c23f83739dd3971614c1bd7e",
                            "FNfa5a2994a711800a05a845f38ac2250f",
                            "FN6825687e6fdde39c2d873409bda40614",
                            "FN3b40e5f4cb6378d00654feb8e7892708",
                            "FNeeb40fca83186ae90700705bbdb48f1b",
                            "FN51c8dd2024c04dabba7b2a0d2c855d93",
                            "FNf9c2fc1c972311c4ec16e42a5c444925",
                            "FNcde24e260f7fc16a6b85783838f1fccc",
                            "FNc04d7512e2bc9650976132fe95f4122d",
                            "FN19f3332331b9ff6156534cbb8fc31cb7",
                            "FNc0ef45e9f48ff691d34076133acef085",
                            "FNc01e76abab8b17ddb5e907ef45a9e52a",
                            "FNc75a9cd589fcbea6a3083727c44741b0",
                            "FN99d13b9efdd3e0785be084f36b3a1986",
                            "FN2c6c340543e4f5ef5b0e53935ed7608e",
                            "FN0a8e609da58b35ad806a97074135fd37",
                            "FN4e95cdcd86812b98ed4ea22f0c1725a7",
                            "FN308af446acc6df68cf485015a6016ab3",
                            "FNd09a3518044ee5b0750b082edd366599",
                            "FNf5a67e275153234932d0595bf637a9a9",
                            "FN9c74960a3803c26c2c0278317a9bb231",
                            "FN8adeed7606003e10a11a24a3ad861f42",
                            "FN2f86412697f767a3ddf12fe92bd952d9",
                            "FN16fb0f1e07fa58a05515133f87207dc8",
                            "FNb6a8e156bfa3561bb529e8fabfe93b53",
                            "FNc8524ac415987184a70d019b0e9c6734",
                            "FNf1154e4799e83e6be9fac2e14a241243",
                            "FNba15ed23f5cb3cba212031e7e25d8992",
                            "FN913a3a6b3072940d864d0ed80acfd55d",
                            "FNb3a81cbcf5da2392b5c03c6d0e0c30da",
                            "FNf44f13cc6b42ff0e3d1c883b96d0faa2",
                            "FNd424d7b28b1744256636e1f88f03a3c5",
                            "FN64ee4766df48694093deb5cf197fbe87",
                            "FN97b011e71acb16630d85ab9acd5a182b",
                            "FN57ece61cadd5b3787ab4615764827c9f",
                            "FN06cd6853c78ef3881ec55de9ba1576ab",
                            "FN6f6d7af8f864e92542e22313615b1ff7",
                            "FN3ad51865598f28e04d08cd5ef1400a43",
                            "FN92877a2736f02be6d13d9e1b56bece26",
                            "FN39d752a9e55e3d56e97ff5730fe9b891",
                            "FN98f752fbd80a6309a0671feaea4519b7",
                            "FNd55d600fdb6ae4f746fbddd7415a7be6",
                            "FN2188b784f3b27e69936aa4551badc2e1",
                            "FN86b62f5c367deabba104b044882d9501",
                            "FN060a436f47d800b8efae96fa14921ad5",
                            "FNf36b456d26d27b4c98db4dca9071a7fb",
                            "FN4ae11e8e522d921ae1c5d5e6e2b99ce4",
                            "FNde92a7999c2b552169bfa45fb53da6af",
                            "FN0c2135317f506dc5e73ad357af692516",
                            "FN6c6e9712cfa625f2fe9059783e5ef11e",
                            "FNe4770401635320154b3c19edcdeb8db2",
                            "FN5dbdfacc36d2c0ad233b01905cf71daa",
                            "FN6b1dad9276dd733aad793a3e7be94aa7",
                            "FN7817f725aa70265af093a7efe09ebad7",
                            "FN4aecfea65fbca0c4cc3a0de3978db046",
                            "FNc7fc1729247780d4922031cbdbe58e1a",
                            "FN55c96b89d8ef41e7f6ad7627242c1c07",
                            "FNc5980997ccbdef8a18a5d68bec5df94c",
                            "FNe85130bb0dde32ba7c6bbefba7582a9a",
                            "FNd1fba879ec5b00157304dfcd54224612",
                            "FN95242dc90c9baefac8c09f012a0574c5",
                            "FN191efcd6245739bfff442ab26d542127",
                            "FN0fee94a44c24d147bda8390ee833a89e",
                            "FNcf77aad3e1e36b0244103363c68ea3d6",
                            "FNdb71a4949543d0a989574fc156c90141",
                            "FN3e42bad26497b7f041a325d76b658d87",
                            "FNac63e809e52e4f3c011c919ab895aba3",
                            "FN8adf4bcdd07c230f40fc168e7be03e6d",
                            "FN5d93381b8eb8adcc57e259442b8f76fa",
                            "FNddfe8c8da272a34fd5f0c7d2180cb583",
                            "FN0fc334c456e5a4034f440f46679fd5d1",
                            "FNa56f5e17de18f52a1cd547b4c385de18",
                            "FNa70fb78da7d564262d08a053f2c4da5e",
                            "FN67654ce9237f64c10cfd35a23647724a",
                            "FNc9407f61285116c026923eaa6c647362",
                            "FNc7f331be84a09ff2058c49667a5b09af",
                            "FNdbc68431dd783b0ffecf90da5dd2d09f",
                            "FN0e774655222f8751b9808625bbde0363",
                            "FNa05bdc8021efe2519b0e21350a78bf92",
                            "FNd4155b7cd934cb45916280f91a260f8d",
                            "FN30d564e3c734617b63904f18820300e0",
                            "FN177b66a32a2d4cde127d6013fdcdf94d",
                            "FN49d204d4cfd9cc3889b0b828a34dee6e",
                            "FN7f9e2ba095f8c7dc5ced457310b49219",
                            "FN0d19abf5464be94858927975da775960",
                            "FN617b86766197ecf91006bb9417bee3f6",
                            "FN755a61c83cdc19bceae4d4d59d50c363",
                            "FNb9c857cebe2233fd6d97f5d777bf1c5a",
                            "FNe110de23bd9636c4826c3f897df40b82",
                            "FN9b0547ac5c4378a0acc336cd4108c0a7",
                            "FN8a157c8e406dcab091377ebaf4b879b4",
                            "FNac53a0083415423e59df24582394a88f",
                            "FNa35d8818c8a4253a5aadcf92729617c8",
                            "FN8723fac3b3c2a63e058eedaf59896ff3",
                            "FN079e246bb4e7caa9f9f7012cb6c4c3c6",
                            "FN9ecc6496519ebb9e34df4ca8db270fd1",
                            "FN43b5c0163bcc2814b90940b2c4dc87fe",
                            "FN5dc3bceaf61bbc637eeb4351ee960692",
                            "FN2b0675303fadb0cf334e341306495615",
                            "FN451b1e39f7df9eeec3555eeff975c800",
                            "FN0175bee88d446c6a8042cea22cc2f2a9",
                            "FN2337b2f68a64b88b5f131191b62ee42a",
                            "FN2f739b2a9793e86ed3dc98be4f921761",
                            "FNd0c422b73b47e7a1ce7a12294a8eea7b",
                            "FN5dbd2111e7433cf861a4aef9df681224",
                            "FNb914ed6d391227eaeff3adb0ff7f2e73",
                            "FN62402d44bc8c32375fe2d2c2d744e83c",
                            "FN3ea42ca505be47e49bb247bd08bfdaff",
                            "FNb38c9c8b442fbe9a4ebb9ccda201a242",
                            "FN6a55087a984b0b7f647f8f46f612e87b",
                            "FNa542af6ec647139189e2b92a124f5009",
                            "FN80ccb39b1a8535c5a40611e6c584d469",
                            "FN699d341bf394f08dc27c8c1a1dcbac9c"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 5,
                        "user_update_attempts": 174,
                        "user_update_success": 174,
                        "msg_sent": 15,
                        "numOfUsers": 179,
                        "msg_undelivered": 16,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "05.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 2,
                        "msg_req_sent": 2,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2bbcb5a0154e4d7aa216ae2a58c877bc",
                            "FN1fa20c604854053eda263e0caa681718"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 176,
                        "user_update_attempts": 2,
                        "user_update_success": 2,
                        "msg_sent": 0,
                        "numOfUsers": 178,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "06.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN3175c5f40b38ca13b516348ab233e6f1",
                            "FN359a79302994de6ddb31a4d1b04cdeb9",
                            "FNef4ce1455fe516e4221584eac8051472",
                            "FN2fc764d11e94c9a4fb24708cbad28b9b",
                            "FNb1e973f763f9dd600e1554a2d3a12e0c"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 173,
                        "user_update_attempts": 5,
                        "user_update_success": 5,
                        "msg_sent": 2,
                        "numOfUsers": 178,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 2,
                        "msg_req_sent": 2,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN3d4a96e03d8ca1781eda501d3b598f1b",
                            "FN4b16bb7c312393d2ef53c9cd32b65288"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 177,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 0,
                        "numOfUsers": 179,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 174,
                        "msg_req_sent": 174,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 142,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb4e5c80fa4002bf2d53305c13c106d60",
                            "FN68ee41f318869f2b75dfe745ab493e28",
                            "FN614ec69b6b2e631fa2e4234f4f7a8430",
                            "FN5cce0fcc33d5ef31a16052caa02c9185",
                            "FN30d0882dc03f65d215853305b39dbcc7",
                            "FNe3fc46ef448b53917328939f5a80737c",
                            "FN5b0295e024f8434628830a5127a39ffe",
                            "FN0fa3f44761a917a342b6db669bd0c084",
                            "FNc7c6c6c03013ed7054197ba1c2d06dd9",
                            "FN62b757ab6b8bf4ec4907e538d75e5b74",
                            "FN701df1ff9ec5c9b1b1770b90ee8e6500",
                            "FNc40b609d23ee4d738d83cde6fa35bb05",
                            "FN2571fe64b1813060b7797bd6324f6218",
                            "FN958b23e0aa4be6dac5a0dd70eb44fc05",
                            "FN0ab62e9293b18e172cd0b1263473c7e0",
                            "FN1084421f277942f7b0b0e90f8315ea8b",
                            "FN72a7ff568e8a1ef4c17ac481cbdf7bab",
                            "FNcd87e4a60398e19972ce98d1a1930f5e",
                            "FNdcf1a22e70d8230c84f6137774f34a1a",
                            "FN89fb166a6959d42a0bf2eb47c30907da",
                            "FN6e24c83efbd350349c64f3f29bf7c022",
                            "FN388fc2a2cfde63796381a4ffccb4f0e0",
                            "FN97cb4842b43c5701065c12b7b8073a10",
                            "FNa8456f23b5d8414da9f4502f72b897dd",
                            "FN862a2d27a1313ed2dd560af0684c9cff",
                            "FNe0030bc1a08e32e9eb9f055ee2ee5d21",
                            "FN02bb9b63b6db7b2f9583540e0f94d576",
                            "FNe924ec3603c972411e44d8f85f68be87",
                            "FNae5b6abffdee8d00cc54c1c944e96429",
                            "FNe97684eb242cfb6ebc4aa2c847b29a51",
                            "FN010138f6cfd11e3112873f9e2d3521ff",
                            "FN169a19ddb075c41c7db69b62310b09ff",
                            "FN566489f635d0e2287437fb5b035a65ad",
                            "FNe7655a134fdef48b32e5133af8af7b38",
                            "FN6999c33982e8f2d16acc7d2405faffff",
                            "FN31253a15e58f295ec2c7dd15c62f269e",
                            "FN3ab9f5bfcbcabaaa7e71aba4851c5880",
                            "FN2ba35f9cea5788f7d1431540f1891095",
                            "FNced5a079a39f7c6834a706d602cf226b",
                            "FN48de19e44e1597c20263f0aab7b1aa9d",
                            "FN99bab97a34743380728f41105c859932",
                            "FN7e1ca6407ae8be63b149b6e4f6a618cf",
                            "FN659f944d71bb5b0255d50c8573d1aae5",
                            "FN6aa796fd489f125789d0b06f844ab8ee",
                            "FN1aa8a469cf2d05761dff0791e7deabd4",
                            "FN792d3007eca1ff85ac196044cac81d6c",
                            "FN5d1d4818bd0b964b9efa8de241f3b07d",
                            "FN6b6b2f62a2da0a019b0617be92669aac",
                            "FN889429959ae81821575af46cd31b618f",
                            "FN60839dc7f94e5fa95684b8b028a20610",
                            "FN46044b98ee9c73fec6f1ed289a51f1c3",
                            "FNa23e46b27ba04e705ffb47ae1c3c653c",
                            "FN5cd19d04b75f14653bd9110183709232",
                            "FN981ee35885f5bc5a33842167a014db94",
                            "FN8d1d6467bdb0c0cbda5a05d7bc383dc8",
                            "FNf9ab305bdc443a339d481a86efa0fe2e",
                            "FN7626bf3f88b215e70e6275069f9a1a5f",
                            "FNb29932b584f0dfb7fd2145215464c1cd",
                            "FN62bc0191c265e64bbfdcb95371f2ea47",
                            "FN733dccfa926fe4cabaf9624e84e39ee1",
                            "FNeb3694d4de4af50120f80282d8fcc0c8",
                            "FN4821119f0f14da99021de5c89b259447",
                            "FN37cf6f483ec13ee8889d00de546900d9",
                            "FN87b408c8501b5ec24d9faadbc921b40e",
                            "FN2ad7ba4127b886771a8859547e67b9d8",
                            "FNa92588a9dd78b94e1a89ed7fe0d307bf",
                            "FNe93240439246aaf31842e2d31d8f49ed",
                            "FN5e438e67a493339293912638ad385e95",
                            "FNbd4f9b617041a3ddb042e8bec235b1bf",
                            "FNc4e3aaa737a6508eb92d10656950f9de",
                            "FNd60e5e0863e7f8451e5ae2b82f64b9d5",
                            "FNc7633e6514aad79deeafba27b73ba4c4",
                            "FN850745e38a9623c89bcc605907296e0c",
                            "FNcf0ff781baf578b18730ee7194cb3342",
                            "FN848a5bb4105ab4f38085ae0cd3068536",
                            "FN419b6b89d6a695434246b2a15d8bf533",
                            "FN6986d0fa38d0e1c5dfd53ed53e042b0f",
                            "FN5d3773d1eedeed2ab1f67473e61b9a2d",
                            "FN9f58e00e7a8a834dac4fc181017317d7",
                            "FNe46efe1246d202d08e5ec5dd20167f67",
                            "FNb2423c0fb1240f47b194affd5b8199ea",
                            "FN6169f1c8026c33f362d3377f9224a8e9",
                            "FNc3cd2e20efe50db457973441a0aedf21",
                            "FN4d5d5f5e9e368a1e788745c758d454dd",
                            "FNe57acf149ff0d51970527bc6381f4d12",
                            "FNe6717b4f5185eb302f83052ffb7a31d3",
                            "FNa1591fbd0ede20077b39899264992dbb",
                            "FN8dd61271fdaf3996ea46fe996999b684",
                            "FN004e748a2a3ca3c6e05f8514f30f8acc",
                            "FNc92fff2bcec7b5fe00ad28100f41b924",
                            "FN1ea33793499bc4bb33cecaa6fa5095c2",
                            "FN158da618019791f21ba99887bbde54e6",
                            "FN7b5aa1032d5a149b9f70b1d65775d253",
                            "FN8ea2014992585d7be4ab3903f0f886ce",
                            "FNace9c578a0fa7056c1ae23f72c18c52f",
                            "FN59f14b27cb3e66876f8aa25ebe6a383d",
                            "FN7f30041e3dc04bca5020514266da56bc",
                            "FN77a8a33e46efef53ca50c1a6aa10bc0f",
                            "FNa0f16335009d6709325f4d194c6217ee",
                            "FNd50e4f5b04c7cca765896cbeefbf608d",
                            "FNd1e5f9e311a202a2d44b76ed4d942ef0",
                            "FN7e5d612a83ee75ec7886a4006b50f069",
                            "FN636bba515b1bb543f7960caf0f0857f8",
                            "FN2dc34d026fb3f695c5378518de79b3d9",
                            "FN7b5f05ea897cbc1c3057884aeaeeda36",
                            "FN451006d7d11e3924ac95a631005a4c53",
                            "FN402b916eee4b4f5ef2b4a388cffa64ba",
                            "FN1657405e5b346f0dd1c52fe2a97297fb",
                            "FNee456c04da0aa8ff45d7c46db95f035b",
                            "FN12d18a8718cc490464c9d23f96392cfd",
                            "FNe165ebb17f5cc16310e587a56e4575fc",
                            "FNb9915e187cb7c43bbed692444d114e1d",
                            "FN268075fc8444d51957c276936dac6c16",
                            "FN1668ddd032d136e14f0ebc7fa6782f50",
                            "FN3ca857d09571fb762a51ff37b73a6449",
                            "FN2886128c4887637e0acf47d0da926bd4",
                            "FN79b783f946647bdafc82c653ec59e6f5",
                            "FN488b44a61f84020f9363de1c0578fb36",
                            "FN6b0a98c3713e6add094d79f05bd366e1",
                            "FNc5c9ac05e993bf9b8cefb736a75e9b63",
                            "FN7ef82757f5f30f8dbe30915e0a09a39a",
                            "FN4e5c05e24c92b25dbd9f093555e2b9c6",
                            "FN2770dbf37b981c052691b89401b2bb88",
                            "FN8435f88792908234118cb5b260a4a728",
                            "FNf59168efe261cfea2c52da07edcba927",
                            "FN651b2bf4306ccc9937fd963c104e9dd9",
                            "FN04e789b3db06595881db44052fb8d9f2",
                            "FN3202a9dfd6356816d4f2321d69ff848c",
                            "FN4ab94c12961216029831b7360a765899",
                            "FNa9dc83700a6423fd1c5afb209b08c456",
                            "FN1ccded47871dc8f323c74e83c75c5bad",
                            "FNbd280c4825d7269fa1f196562012d709",
                            "FNeb219c1863f7d8551f4748b9f45137fb",
                            "FN1f4f08d4280dcc7e7f186960d74f95c5",
                            "FNa77bf21716b997228f5bade94daf3222",
                            "FNa41b0a10b5e12e9ce7ca3580bf31f5ed",
                            "FNea6ba14309e0eabb41ae8021ef75b95f",
                            "FN09d9b6a0bdba2ac3d0a9e1bf30912eee",
                            "FN79a2752b0a5c18289a705585f8f012ae",
                            "FNd9629969cd4fe2c5388a53080d195daf",
                            "FN3023911ce7db566cf2236ef9944352b2",
                            "FN69c8dee5702e798f463d600c6a6934f9",
                            "FN60cbc47cef0117134a5b6aa64f787aa8",
                            "FN062921d1df52094a5142585e7a8f76e9",
                            "FNa401a1c0c214ec37342dc50c6f63b710",
                            "FN506b183bfd998332187ed390563c09c4",
                            "FNe936d8fecac8060cbd5f6ef9eb22f974",
                            "FNf7f638ca6d700b5ecbdd9ad2871a5602",
                            "FN585cd686dc3712c261465cfc73a0556f",
                            "FN6a9158c11e6d66af40dd70d4f04f0e83",
                            "FNab1eb52ace4bb19b440c441e16394712",
                            "FN7c94ee00eb5f1be4cf5fa0882c23d407",
                            "FN7aa6485fd39915380c980f58d2b6de94",
                            "FN016b6047b023d55e7d064f8082c57e2a",
                            "FN2c263025ab253f721c69cc80067aa11c",
                            "FNdf30d8d389224b46cefa0841fa15b2c5",
                            "FN5e9dac7d2306fee35b5d5c57aa146b8a",
                            "FNbe981e0c0e8143535bb18f9052da3ecf",
                            "FN38cfdab48d0bd947e58d78c1b11e8187",
                            "FNb90d110d2fd1f5abf750ecc5091fa526",
                            "FN9c8898ada1d6556645faf26d7b0e2c4d",
                            "FN43a281f1157721af28ca1f6e9d587fc6",
                            "FN8a72b39184885ddbf0a2a6f5edc546d1",
                            "FNb81248e9d14b6eeaa91319f839fc8f82",
                            "FN68e9e99b5db50f4c13efe7dee6ab71dc",
                            "FNddc5dcf40b0d39236d079ce715912cce",
                            "FN4b5cff50a33250babc7264245f0ae5aa",
                            "FN567a97ada8bd5356e8910bd7c5fea233",
                            "FN075d4b2d59fd8f8ca02100798f7778d1",
                            "FN63336c521ae2522d4492d9f4ad1aa03a",
                            "FN8c11a1b1932e96e1fbf65a7994b5a01e",
                            "FN42a2cd4173438a38e39204e8cb1c77a0",
                            "FN8027eba7fb9e25d9c2f6a3a00a1cfb6d",
                            "FN8189cbecc0d56db0759ed10edbee40a8"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 6,
                        "user_update_attempts": 173,
                        "user_update_success": 173,
                        "msg_sent": 14,
                        "numOfUsers": 180,
                        "msg_undelivered": 15,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 2,
                        "user_add_attempts": 2,
                        "msg_delivered": 4,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNda6ffd24082d5952d10ea426685f24f9",
                            "FNd89a89c82f8a80ac0031d5df273f7672",
                            "FN3110a673503aa7d0577c48b25ba09a28",
                            "FN219afdf691d3fd09d392a983e9fd611b",
                            "FNf75a01e6cceb26c5b65532d78d21fe7c"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 177,
                        "user_update_attempts": 3,
                        "user_update_success": 3,
                        "msg_sent": 1,
                        "numOfUsers": 182,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 6,
                        "msg_req_sent": 6,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNcd2dead5870f76d7591b3d6b5cd95a8c",
                            "FN43f7b8bfc220d10d2cabe4d2903f785c",
                            "FNb38a1222aa905feabc3591ce73bb5768",
                            "FN0d9b58c6632637a59e0ad8e4e9c52e8a",
                            "FNbc6b32a45c93eca01d566f31c20d1860",
                            "FNe523334ab1fe83f54ed219a0517ae620"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 176,
                        "user_update_attempts": 6,
                        "user_update_success": 6,
                        "msg_sent": 2,
                        "numOfUsers": 182,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2bd4d4cdabc802ee402903d06e328a79",
                            "FN3f0b2c11c2dcbe24766bb6f3ad778186",
                            "FNd26ce2c5afb9b92f246cdd453f9b0494"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 179,
                        "user_update_attempts": 3,
                        "user_update_success": 3,
                        "msg_sent": 0,
                        "numOfUsers": 182,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 775,
            "DaysOfWk": " 1 2 4 5",
            "TimeCreated": "2021-11-18T17:20:03.795Z",
            "OrgName": "Community Care Home Health Services",
            "PresetMsg": "Community Care offers training online! Open your HHAX app, click the Nevvon banner (see: https://bit.ly/NevvonHHAX). Questions? click https://bit.ly/NevvonHelp",
            "campTime": "2021-11-24T16:15:40.792Z",
            "CampaignName": "PCA CLASS 1",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C20",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-29T14:52:37.165Z",
            "ProgramName": "PCA Class 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 227
        },
        {
            "MaxProgress": 99,
            "ProgramID": 524,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:01:08.935Z",
            "PresetMsg": "REMINDER! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:00:27.653Z",
            "CampaignName": "Sophie Q4 Docs EN 2021 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C290",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q4 Documentation & reporting EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 703,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:22:11.941Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care- Hospice",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Hospice Universal Compliance 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 4,
            "CampaignID": "C520",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Hospice Universal compliance 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 214,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNfcc9f7149daf61b408413d3c918a2e18"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 482,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:29:44.350Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 3 Spanish 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C413",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 3 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 712,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:01:03.105Z",
            "PresetMsg": "来的温馨提示: Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:29.975Z",
            "CampaignName": "Compassionate Hearts Orientation 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C173",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 30,
                        "msg_req_sent": 30,
                        "msg_scheduled": 0,
                        "user_add_success": 30,
                        "user_add_attempts": 30,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNd1a052094bc6bc33ff6f3d38a4b1d6c1",
                            "FN744382dce9c3355169458c0f56484b1f",
                            "FN35911ebec410960ab069b0089aaaeedb",
                            "FN22f4fc0b0584db6f55aa9f3c80360cf3",
                            "FN8cd56ce93230182e6b60da153927e3b7",
                            "FN03ab404960c4d051d0fe04f6d39bfd5d",
                            "FN1b576d65d76fdccbe4e4f0156444e06e",
                            "FNc9c5387e990a4c3767b946a9f55eb2a8",
                            "FNfaa30a648539fd5aa5b4609e750bac33",
                            "FNb7e144e04a640abfde505f6d2b8331f9",
                            "FN04aeb814d0f4dcb314f5cea95b6c88e7",
                            "FNd944ffcbe895a6854e78bb322dd69a7b",
                            "FN65cd073f1ff00430d1d337c51cbfedd4",
                            "FNcb98b2a3e4beaa83b7b2be89eab2f7e1",
                            "FNa6f3b712cad4bdf9bb8c6fb4ea77e151",
                            "FN1a0d49dc752403c3515926e615869ca3",
                            "FN3fb3beb88db20d791bfb01f1a496f771",
                            "FN2976287a7fb7735f1811bbe412e6a0c2",
                            "FN8f0622a68283d3337ab497ab0fad6de2",
                            "FN05a2712832934611cf3eb187cb40a8be",
                            "FNc0ec7f8a9fcaba4ad1a2d6367c35ae45",
                            "FNf1cd9574ca516074d408a40c5857b7a4",
                            "FN6e683a002b671feb5274ed37d2ce37fc",
                            "FN4718fc97c9c044c946a3176804060cfd",
                            "FN7669dbbc97a8af679bfe0cb7181fbecd",
                            "FNd3fdb520c07c66b93d801c7e4b273219",
                            "FNcc09c3762c717b5115e2581d17dcfeb0",
                            "FN028a735ddc40a65f357553b5f5056fd1",
                            "FN3e4fb0f1db427b56c2f9ef7e91c7c1f8",
                            "FN10e6b27a7a2c0f6f03ce133a3d1de4a6"
                        ],
                        "msg_queued": 30,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 30,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 560,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:46:50.621Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:41.101Z",
            "CampaignName": "Assisting Hands Richmond - Q2 2021 - English",
            "FrequencyInDays": 7,
            "LangId": 41,
            "CampaignID": "C352",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "ProgramID": 464,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:39:57.175Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:41.422Z",
            "CampaignName": "JFS Care- Optional 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C543",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Optional 1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 625,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:27:45.884Z",
            "PresetMsg": "来的温馨提示: FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:10.788Z",
            "CampaignName": "FADMO Q2 In-service ES 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C144",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:19:32.678Z",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "OrgName": "M&N Home Care",
            "campTime": "2021-12-08T17:00:59.177Z",
            "CampaignName": "M&N EVV-HHAX 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C65",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 240,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 317,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:18:10.733Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Orlando",
            "campTime": "2021-12-09T17:00:35.945Z",
            "CampaignName": "Thriving At Home Certification - None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C263",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Thriving At Home Certification",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 248,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 527,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:01:10.092Z",
            "PresetMsg": "\"URGENT From FADMO Health and Home Care:\nComplete ALL In-Service programs ASAP. All training is due by 12/31/2021. Get started: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:24.554Z",
            "CampaignName": "FADMO Q1 In-service EN 2021 None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C123",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 925,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:47:58.729Z",
            "PresetMsg": "REMINDER! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:22.135Z",
            "CampaignName": "Community Care PA 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C79",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA Class 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 520,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T21:44:22.596Z",
            "PresetMsg": "НАПОМИНАНИЕ! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:00:25.057Z",
            "CampaignName": "Sophie Q2 Safety RUS 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C279",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q2 Safety RUS 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 25,
                        "msg_req_sent": 25,
                        "msg_scheduled": 0,
                        "user_add_success": 25,
                        "user_add_attempts": 25,
                        "msg_delivered": 22,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN57acdcc273f46bb852034323a846d0dc",
                            "FN7ff9397a9cd743d982e68b9a762f24ae",
                            "FN49f6a5710c9fe75138c1c8f58b54b31d",
                            "FN38c89cdfc922dd9c4b72b0feb9db061e",
                            "FN09a6b6765240ed4c251037875a3897e9",
                            "FN88c63b4ac40b8ace1c12d015cad5026a",
                            "FNf4c17c365cdfdb6a1bccfc8318d4d3a3",
                            "FNdf9e059a7b4d118994151c6edbab8fd2",
                            "FNe4de6b33d5eab207e00eedea75873dbe",
                            "FN48f13f227e4e4cc05da19c495cca8579",
                            "FNe750c2aeb5dc4cf6bfd5fee9a6edbea8",
                            "FNcb445534dca76c0aa566936540ddfca0",
                            "FNa065e72ad7207d4bd29753a44ee52ed7",
                            "FNc265a887c98a937a454fdc3ab248e2be",
                            "FNdd2772d04a53793eef54455bd06ac7f8",
                            "FN83a77171cc1fdec82dc4a673ed515e36",
                            "FNb5157ae890dedfbd6f965920bc7d07c4",
                            "FN97f9e34a31c0dc905c8609d18303127d",
                            "FNebd93e34c392fe3897156acbfd47b5b2",
                            "FN7c249fca3778338445f2d7024c26e7c3",
                            "FNb43d7e2b60272cf6ff6123aa53753fcf",
                            "FN3fd16b5bc0d47dc4fcbf150d7439ff48",
                            "FN13e37b5e8a0e74947d540276d05482c4",
                            "FNf5ccb71c63d9fc60e76afa5cfe3098dc",
                            "FN3e06c2a150402cbc81f62f1fb19f0e12"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 25,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 683,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:14:11.886Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions?https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T20:30:37.731Z",
            "CampaignName": "HDA Class 3 2021 None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C301",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 3- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 20,
                        "msg_req_sent": 20,
                        "msg_scheduled": 0,
                        "user_add_success": 20,
                        "user_add_attempts": 20,
                        "msg_delivered": 9,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN02ae5316d0b432047524b1646f55651b",
                            "FN4b27783660a7513929d9c5c7e39b7999",
                            "FNc0536cfa2fd16b79c7dec98fceda6fff",
                            "FNd805d27d398150851c599eb15b0075b6",
                            "FN2abb80c73f1460250c18d8f7d2ba4cb7",
                            "FN4e9e371acf8dc180450d513051a92533",
                            "FN06ebebfe523473b70141854f83fe60af",
                            "FNad97814d36fc267b89e2370d6068acf8",
                            "FN954bcc9d1ea321b2eee5cd8c11c2ce98",
                            "FNcabc2488a847e5266e460ec7f0fdc3fb",
                            "FN1973a75629d27b245e85c3502462b29f",
                            "FNfbb3094f3d26272210e12d01fbacf6ba",
                            "FN4a2a9d364d926962494e5bd7d4129604",
                            "FNb143bf0bd116e0ef0bcebd3f352b4fbe",
                            "FNdf4ad2d788b08066284a773c63b1cfc6",
                            "FN39f7e97884dfbba0dba5dbe2203c47c0",
                            "FNb7d70296c0e7084022fd09cea0e802c9",
                            "FNe16b3ae7542945b6baed6511e08f4975",
                            "FN5977bb59d7d6885cee575ceb1f72939c",
                            "FNc0ab6388d622a83ff6e8ed315efea6f2"
                        ],
                        "msg_queued": 7,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 20,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 285,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:29:15.412Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 4 - Experienced Aides - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C487",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 4- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 651,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:51:15.292Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "НАПОМИНАНИЕ! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:50:18.711Z",
            "CampaignName": "A-Plus In-service1 EN 2021 Russian",
            "FrequencyInDays": 5,
            "LangId": 134,
            "CampaignID": "C379",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 EN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 687,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:56:09.137Z",
            "PresetMsg": "REMINDER! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:35.110Z",
            "CampaignName": "Angels 2021 In-service1 None",
            "LangId": "null",
            "FrequencyInDays": 2,
            "CampaignID": "C88",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 760,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:48:56.890Z",
            "PresetMsg": "НАПОМИНАНИЕ! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:14.797Z",
            "CampaignName": "BestChoice Sexual Harassment 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C111",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NY -Sexual Harassment - Advanced Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 284,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:20:30.019Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 3 - Experienced Aides - Chinese",
            "FrequencyInDays": 4,
            "LangId": 30,
            "CampaignID": "C482",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 3- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 527,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:10:00.478Z",
            "PresetMsg": "\"URGENTE De FADMO:\nComplete TODOS en servicio lo antes posible. Toda la capacitación vence el 31/12/2021. Empezar: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:04:38.150Z",
            "CampaignName": "FADMO Q1 In-service EN 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C127",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:16:56.256Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions?https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T17:16:09.191Z",
            "CampaignName": "HDA EVV-HHAX 2021 English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C304",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 70,
                        "msg_req_sent": 70,
                        "msg_scheduled": 0,
                        "user_add_success": 70,
                        "user_add_attempts": 70,
                        "msg_delivered": 54,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN7fcde8d570075210cc95f5d47c5da5e1",
                            "FN8aed272ccccc2b70319b03de9ad4b4b0",
                            "FN361cf1514028a6cf0df42d381cb53bf5",
                            "FN21cc453e769ac56d0d82344def9e5f83",
                            "FN8d4356b52da3925ff55f92e10f9abd74",
                            "FN2b88147ce695bf8a4157dec294c05ca9",
                            "FNdc4f1839638aab3b721ae9490dfcedf3",
                            "FN61443a50d11d18b12b7073fcc408ed7d",
                            "FNb455460777f3c203e9c3cf5df5db245c",
                            "FN16a592d02ccfa6b9bc48b06576f3269a",
                            "FN534d0609096ed467ef3db0a47e65bd86",
                            "FN9ca114cba1961b1ad5b62a4474bdf4b2",
                            "FN2413fa31fef96fd769777d80242b863a",
                            "FNf8fd6300771948d511ad212de35ee6ca",
                            "FN08a49d9bb530a16ad71709951146b3be",
                            "FNb334c8cdba5f78ac14e67aeaec4e269b",
                            "FN32156ff38436fff6037f9ba45479646b",
                            "FN29dc7021380c055e0d7f055fc18da598",
                            "FNb003601841262208b873c1b83fc8f6e4",
                            "FNd877aadea9198e8f0888d12270f40e0c",
                            "FN8c7b3c50ffd4fa708d70028ef5f67f23",
                            "FNd041e9206869879f6bf8185a856597b6",
                            "FNdfe6336d79ff9521d29b647518a4bdc1",
                            "FN7ac907fc1ddfe5b97e0f72c1ded3d3dc",
                            "FN4978b592f64e34c10399497d04d1a863",
                            "FNf11fff5ff48aba855cd782dc1795d9d6",
                            "FN7a322e074e39d27ceea9d0cd015b8deb",
                            "FN86edae4c28a00c116018e3eeaf437927",
                            "FNdd0252b334c7fac0024472d793686460",
                            "FN3f49bf306587781dc7d635b61e95bdd0",
                            "FN9bde1b7a4d05622982c48d0f0a0dbf28",
                            "FN3dd5153784eb5d6a9e62606a63dad912",
                            "FNba318c51da8e6ecd6822426d58baf6a6",
                            "FN2c32c58dc812fc45ea5cbd531f5e1891",
                            "FN49e3dd4c1ea6f16267fb28daa4c7cdd5",
                            "FN910bcb80af5b83697387074566874686",
                            "FNdd59961835a2f6071cde26123425ad77",
                            "FNd5ec85373d944bb53ecb1571c07f1a21",
                            "FNb740c20189dec61767406a89adc2a695",
                            "FN5f073f5467e84c7a877a45213eda110b",
                            "FN43365fc72b30ab163ad3653223b4d087",
                            "FNee30a15a34e9d9019f1688ae10c5dbdd",
                            "FN32ecf164ce3cc543b016a7e79f74a0a5",
                            "FNc676c31790108ff89ccd2656ec7de7e9",
                            "FNd7cd687628811fee052a994589554e75",
                            "FN9373f71a20cdf97db3ff3eb18c305887",
                            "FN8f838f5e2a9e2cab15e5b0da7947ad64",
                            "FN3b00504b678e5cb792b525abd8502208",
                            "FN09ae2ee87a1810f5fa5a3c6209e408b2",
                            "FNdfcd9e190bc9f5eaf7f9e20b26c9636c",
                            "FN2dad35f93aa6032fa79c10e916473c50",
                            "FNff57e69c4900c3af6aa074843e9073c7",
                            "FN4584d1dd1394a5f60d24127e2860588b",
                            "FN8ab27876611225a85a0dec0981d68541",
                            "FNdbbdca31c8e873f82b29affd53f9f9ef",
                            "FN709d01713fa13b256243904f6a78388b",
                            "FNdecc65b6baa749f6a2473ee7bf0d5393",
                            "FN44f5eff830b5b0977e212a42449ab359",
                            "FN3a47c2e8a0074c0164aa7f59ba3caa9f",
                            "FN2bc0432417ec6bfb7ae69cf0e0e71ede",
                            "FNcbeb8d900831bcbb50b5714e18bee5c8",
                            "FN194acbafd1ede8d8cd2fe508e71ccb6c",
                            "FNed5e4de2921d47aafc2bffc03592b3ef",
                            "FNf92187431c876bb7bda579d3f98d61dd",
                            "FN00c7b6bb7fe3be7a09b9432e7c6c98a0",
                            "FNabcce082c43dec19b864614f813e88a7",
                            "FN7791cc1a0a204bc4f7915afb0ee09736",
                            "FNb096bdca447049ad4ed5443024a64ec7",
                            "FNcc906b2702983bc96a1705d73dc5dcf4",
                            "FNe9aee6bf83e0bb95c32f8a71a1482233"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 70,
                        "msg_undelivered": 13,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 682,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:12:08.228Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions?https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T17:00:13.789Z",
            "CampaignName": "HDA Class 2 2021 English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C298",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 2- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 590,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:41:30.393Z",
            "OrgName": "Open Door NY Home Care Services",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Door NY In-Service ES None",
            "FrequencyInDays": 2,
            "LangId": "null",
            "CampaignID": "C525",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T22:43:35.712Z",
            "ProgramName": "Open Door NY- In-Service H1 Program- ES",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 182
        },
        {
            "MaxProgress": 99,
            "ProgramID": 782,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:56:20.237Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Q3 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 4,
            "CampaignID": "C580",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:37:35.998Z",
            "OrgName": "Susquehanna Home Health Care",
            "PresetMsg": "REMINDER! Susquehanna: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:41.941Z",
            "CampaignName": "Susquehanna EVV-HHAX Haitian Creole",
            "FrequencyInDays": 3,
            "LangId": 56,
            "CampaignID": "C329",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 234
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 12,
                        "msg_req_sent": 12,
                        "msg_scheduled": 0,
                        "user_add_success": 12,
                        "user_add_attempts": 12,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2dc2b0807921b7c725d4a4f80e142e4e",
                            "FN57377b159fc98756e5ab331d89eb44be",
                            "FN7b4eacf8f0f0385a4884bff3a5415ef6",
                            "FN281e72ddfd027c5dbf378da8236581c9",
                            "FNb1cd2d0a22e1ec8891c2c3c6145f1ce0",
                            "FN4f7d500fa2881922dadfe97560a8661d",
                            "FN47a86c259f52e41021a253e7d8f0b0af",
                            "FN5bba4bc5c3484a62b6cb5688c2d4bca6",
                            "FN38597b7dbd71e2de93f5ced528e616b2",
                            "FNc4eab108f8543b1c5e6ae988c239f17a",
                            "FN6bee1564de8daa10158cf01e54c6ab33",
                            "FN0dc787e5b51b60db25d7f4f5d7539ff8"
                        ],
                        "msg_queued": 4,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 12,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 282,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:58:56.473Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 1 - Experienced Aides - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C468",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 439,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:47:30.263Z",
            "OrgName": "Accredited Aides-Plus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q1 2021 - Chinese",
            "FrequencyInDays": 4,
            "LangId": 30,
            "CampaignID": "C565",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q1 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 133
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 2,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 163,
                        "msg_req_sent": 163,
                        "msg_scheduled": 0,
                        "user_add_success": 163,
                        "user_add_attempts": 165,
                        "msg_delivered": 44,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN8126f92469406415d7d09d5e723bcfe8",
                            "FN2d5aabf90335a458317e16f672af3c68",
                            "FNc336cc4a94c40ac06eab5471b9ae6c77",
                            "FNe5f678b965cf23413dd8c3d507044c1f",
                            "FN6d3ee2e5d5f623840760713866265c1d",
                            "FN3a17492dfcf3a0e37e7d2a0e41ebc2e3",
                            "FN49bb6f2d4382fd5472412d6f68c9adab",
                            "FN224c5ca2afddc8f1855e89bc75fb4f81",
                            "FNde1e2b6fb42e743d66115d20fb9eb0b5",
                            "FN5845f88e92c129fc9a10ea533ce426eb",
                            "FNf1b5157f6c729aae060595dd76a0de54",
                            "FN0ebcec6b51d5086a1769dddc81b202f3",
                            "FNb7761bb8cd6855c6a24d011594f404e4",
                            "FN6eb1aaba0e33808ad6c29cbd7ab36921",
                            "FNcac6400c5f912642f0f1ff15c84a06cf",
                            "FNa4766e79fd100fa1b64154025b12c8d4",
                            "FN09bb2239606219de3502542bf447b1e6",
                            "FN289a040c63854511c783dbcd680949b3",
                            "FNfb9118ae944a1964feb0013507dd7d0c",
                            "FN406a5d06b60aaa59f6b0b6860926c242",
                            "FNbad2e1ef9237648903a462789a3419c7",
                            "FN9809fcd41e3bf95a78614d39fdd74bed",
                            "FNc225e3a3ebaf1385d36cf75d67457143",
                            "FN8f54943bf71df54f6a904231c5f0a744",
                            "FNcbeb3f46ef670a841b3bbf69b47e9890",
                            "FN8338577bc5a77369fd9684e3386e087f",
                            "FNf7b065ccc3390b53d6995cd57cece901",
                            "FN6735625b36bea32bee6e3a7bc416fa4f",
                            "FN8ca1c484c7d8d8ecdb3c25d819d4816e",
                            "FN0f627f9bceda35490a893ed26d897d7a",
                            "FN600612c860c2501d9561d1f983425c1f",
                            "FN55fc1aafcab51a1213b89bd8b6cc8095",
                            "FNe1d8aed8ca971bd04a9ee53034dddda3",
                            "FN6d38f72870529b39d73d9bdd3f8e8c1f",
                            "FN206e25ba15b3e5cff8af5e2d86822f08",
                            "FN2bcc08dfbd2b215c48ba9bd815dc9192",
                            "FN901404f9321844bea75464914842c95c",
                            "FN6e6e5960021eb8784f0c419527b76d4d",
                            "FN6540cf6dca70fa40c588d63407930179",
                            "FN9d00be615c32b91a64682e1e59ded1e1",
                            "FN037e661a949d98a503c0d2efc1a402a6",
                            "FNb5aae0a1ce5a0b6c8720f831f9c27621",
                            "FNaa30d40565dbefd6e9bb0b1222eeef70",
                            "FNe4ba22a6bd8c34f552d5edbebd9213f7",
                            "FN72fde9fca4655f2d5832f68c20b1e71c",
                            "FN1e726182e8188f476c84d912d2f8b609",
                            "FNa0fd921d4194b270a9e56642cc02c33e",
                            "FN0be1f426def7af0e546287fba52af7da",
                            "FN7e36c788c343308e0abe84bda96f8541",
                            "FNf14a64988aabd3c9f85eb1a3a36d3d21",
                            "FNf69e30e9fa05b3726f0f9a8e2b015d3a",
                            "FNdb0ea20eb11b79fb018bcad88f099178",
                            "FNfccd93b186755df4b5043c52dbc64152",
                            "FNba624954fc719a421b2e42134746ea19",
                            "FNb3d1cdc6bd983331a27d0e3dc78d23ee",
                            "FN2cf497a05d1e36739b94f99d588b3f6e",
                            "FN4d5ee6d99b35dfb576288a8df2edee5d",
                            "FNa15006601ef47b9da95729b5b860ba9d",
                            "FN7677e2b7766bec93c96b281914fbdd19",
                            "FN679a685444eddf26a2334383356bf66e",
                            "FN667623599108034c341274ff21ee28af",
                            "FN3974163dc1b5ed6e0cd471fc4521c042",
                            "FN0d02f8673e9769308739baea0c8c5c20",
                            "FNba8f9311fd4c269931020e010580035c",
                            "FN79e8e267e3a27646ba251c198928ca97",
                            "FN5a93abad9b87cca53df9b35c03fbff94",
                            "FN7fb7fe21192a551bfb88bc79fe4fbf68",
                            "FNb9ccd84347f8e2c35a091af28e4f4d61",
                            "FN0713ead250c1785da3bbcf13aec1148c",
                            "FNfa8c9f3865dce34571af18b5344b02cf",
                            "FN860b3036a488f9adc180cc171275c4b0",
                            "FNb27ad01cfb5a6f1a1f6773c304966333",
                            "FN34560144e4b1a126556d04518be602bd",
                            "FNec880884a9c0522ebbb65335d116b9d5",
                            "FNdbb6b159c7bc555b73fa5f2913362d30",
                            "FN91957f9d4cc3d70a24978cea1211d622",
                            "FN008bc0b025cfaf5c5e5f48d5c772d891",
                            "FN8b41e5fc85b6437707b77fe4838a3fe9",
                            "FN4d26ceb5fad553f527d71f1b2363b603",
                            "FN8c429438fe5ebccd41059f5f938ccfae",
                            "FN3037012d733a480ef21745439707f8fd",
                            "FN3615ed5049fe3d08020bfc46a67e3fa0",
                            "FNbbbd6e3ddc901084bc595d00ad85df69",
                            "FNe55113a23516c1403013f2a872410a00",
                            "FN62cb519ad9e240a7d7021c5d7dceca0c",
                            "FNa7e0bce3906906d1f628d4be1029d3cc",
                            "FN8a8388cdd4fb51b3575a244e136f2cb5",
                            "FN8255d7042f6cff032fb4e1d694696ba9",
                            "FN21f3fb9a4c062439c984c4e2d64d0919",
                            "FN0dac2dc30d5531cd5be7e5fb6d84fd40",
                            "FN23bd0191c7979f2abb5f1a8818d76e81",
                            "FN6ffea3d3a86e15fd6d3885edde010eb6",
                            "FNe0bbc189f13a91bfc5abb5644fd3f2a4",
                            "FN710aa2b7b866bc6648e8d723d76348fa",
                            "FN41185a9e66f8a1f022a042554039bf1d",
                            "FN0f245e2ac86945e743b26e9a8d37dc31",
                            "FNb25c5588533ef64fe0a8f028cc87c26f",
                            "FN78e9fb314b7f71bc553f98cf114ac602",
                            "FN88b85c1c4cdd1485e6f4671998baad69",
                            "FNacc7919f1dbf62f9e3ca354386fab7b8",
                            "FNec7ac2910f27e2ea033c1be00d5255bb",
                            "FN31f08ed864471ade67f25acbf97de3b6",
                            "FNce207889bd5b725624828c551912f083",
                            "FN3e92a414231d585f5f48f455e6d9c0c5",
                            "FN27584f9735a2a10ad8a505fd25730854",
                            "FNbcf46236b6d6ce52c1b267cce9925215",
                            "FN188328d43f061e53638e19952d7cbce5",
                            "FNb20ba173b10e9a4d55734657c8095da5",
                            "FNb10c631e09db56c29443e6047ed6c350",
                            "FN3b2a6a38ac7e261e96e4701d9ab1407c",
                            "FN2c9a8f5054bdd70e3c888ca94c35de8a",
                            "FN465e01d143639c87b6aec0751e8cd102",
                            "FN247c1b1d6c33643572d8e7a480f13329",
                            "FN50595a09a4114484271ffd66d4b257bc",
                            "FNac6c54ca6e40ee13abd5a496ea2f9eae",
                            "FNbfefb0fd38946c4c547a7c5e323454f8",
                            "FNb6ea4a05ae8fe0391e326c5062a163bf",
                            "FN25ec6d611a2b2a5af54c703b07d9a2f3",
                            "FNdaf8e84b941c9d02efcc8949ba9f55c1",
                            "FN8c9a6317a54b30f16d96538a4fb6952b",
                            "FN36c80d84fb0782b41b6b41758b25977e",
                            "FNd8d5e433badd99062f47f5dd3be73a1b",
                            "FN2dbb0f0a22e02e4d94dba6fcefaa408f",
                            "FNb1227d87267444ca5e3b10d29d078aa4",
                            "FN59d808bc2210cdad242d3d8e39e9c243",
                            "FNdf8dc0a0c7db2a55c63c17526384e2c2",
                            "FN760e24ba1bf9ed0911748222452722f3",
                            "FN57236ada98b0b536d642d15ed75f944d",
                            "FN9354a045dd807955a0c45e665f01decd",
                            "FNddb23d89b981e31ba7a85e25d0daff67",
                            "FN8524f14ba199fbe9d518ae451beeb8e4",
                            "FN0e7bd86897131f3f21187d70f250fa9a",
                            "FNbc13e399def94e0ef6163fdabd4e3160",
                            "FN1bdd1ee6d81f58d6334f68b1ead3b168",
                            "FNe15b8515644d9cfb5c47c9b08bfd11aa",
                            "FNb2ea0d1a95cf6c5e5a4aa4b2583a8a67",
                            "FN6d3cd0875a55d14e5233ab35032a8e79",
                            "FN9dd09a91b535255a67d7fc3b6b91af9f",
                            "FN852964b964c5b879303a85c03b1ec010",
                            "FN1b7d8c4efb533bfacdc621fc0562ea71",
                            "FNe8611db4d2fc6dd89519bac7954b1317",
                            "FNa9d66ce235eb85065d0391c8e563e91d",
                            "FNd5d9811c69f947451ef556c36112540e",
                            "FN0699fc05b3eee9ff98e0a7b64d2e9808",
                            "FNe04289a336f319d04ff5fdc800d45f6f",
                            "FNee69a807f40be2b5ab850d6d296d6848",
                            "FN21dff8efaed2293e63a4ad1906816c3e",
                            "FN794f9d638ed16cc63456dc333e8687a9",
                            "FN175b4692d019a2ca25720e047633d4d3",
                            "FN46dc3f8911e866754e7af15c430a1c7d",
                            "FN5df4efe48ed47461adb5eb0c55d128e6",
                            "FN587772ea5d7daf49a69e485669d536c8",
                            "FN45399104a15f6a548a86825e99a057a3",
                            "FN77ac0c1c9b5ec84d527d2afa7fc300ea",
                            "FN2dd5a30ae4f4b3faa86b1a1f1150414c",
                            "FNe96da2a5c212edb2d5385c3cb0bab295",
                            "FN9a90289648a7ed50cfa17614e0f65aff",
                            "FN49b4819ce1bdeddd805b1c51d07c9254",
                            "FN5ad02a33508863e0e017a29dbf59e55b",
                            "FNf2c7a444e403a03d6d8d588a554ddd53",
                            "FN8df277597b37bb1d768913a6d8267c26",
                            "FN37dfc036be99c57514d5c97b27df8d50",
                            "FN869557bfbbb16db7cbd90c81b3283fb5"
                        ],
                        "msg_queued": 107,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 7,
                        "numOfUsers": 165,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:58:05.004Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "REMINDER! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:35.020Z",
            "CampaignName": "Ultimate EVV-HHAX 2021 English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C333",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "ProgramID": 838,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:18:26.815Z",
            "OrgName": "Hands to Heart Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Hands to Heart Q3&4 In-Service 2021 None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C561",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-11T01:18:46.753Z",
            "ProgramName": "Hands to Heart- Q3&4 In-service- 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 170
        },
        {
            "MaxProgress": 99,
            "ProgramID": 509,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T17:34:43.082Z",
            "PresetMsg": "REMINDER! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:17:30.979Z",
            "CampaignName": "Elite NC2 Class 2 2021 English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C46",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NC2- Class 2 2021 English",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 624,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:21:54.888Z",
            "PresetMsg": "\"URGENTE De FADMO:\nComplete TODOS en servicio lo antes posible. Toda la capacitación vence el 31/12/2021. Empezar: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:13.909Z",
            "CampaignName": "FADMO Q2 In-service EN 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C139",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 100,
            "ProgramID": 606,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:39:16.699Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Compassionate Care Universal Compliance Program 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 4,
            "CampaignID": "C515",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compassionate Care Universal Compliance Program 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 188,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 720,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:15:31.047Z",
            "PresetMsg": "REMINDER! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:54.188Z",
            "CampaignName": "Greater Love Orientation 2021 None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C193",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 317,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:23:59.756Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Orlando",
            "campTime": "2021-12-09T17:00:44.061Z",
            "CampaignName": "Thriving At Home Certification - Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C267",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Thriving At Home Certification",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 248,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 20,
                        "msg_req_sent": 20,
                        "msg_scheduled": 0,
                        "user_add_success": 20,
                        "user_add_attempts": 20,
                        "msg_delivered": 7,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN94f849a2146a27d1fe7f49db30f14c60",
                            "FN7e716da44c34d65d06aeff047fac14d3",
                            "FN09fed4dcfefed1c1ed3fc54d5c034fb8",
                            "FN5d9d58551747ae2f4f5c007b040d419e",
                            "FN89ea5f82748a5525a85f54d13082c388",
                            "FN0a15a1cfed804fe70649b7ce4417180f",
                            "FNd4dd27e06a21933e16176078670e6dda",
                            "FNde6460f326a844723bb0867be17af39b",
                            "FNf3bd9b7d99c761e38f53c17acb45f9f0",
                            "FNe74390d3ea7970fa192c13ccff92a839",
                            "FN80aa6b3b5861e7fcfa4ae4b43833cb57",
                            "FN495b9bd3c0051df3067ceaa5c1403787",
                            "FN5726263b29a73196c9d88303b93b468b",
                            "FNced3cc2b3eec88cd54e0f7aa530089ad",
                            "FN2b8f84fddda1a8ec39a6fdd4bee797da",
                            "FNe6b3aeb0ce46df94fa5168bc2e782fd7",
                            "FN8f1439c2f6794545f1db58513c6f2036",
                            "FNa594b042e31462ec59c70b8a0eb14113",
                            "FN01f868d614f65380d83206989dd3d857",
                            "FNe0a40fb5ffb204ed78f535cf07189c05"
                        ],
                        "msg_queued": 9,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 20,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 284,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:19:10.475Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 3 - Experienced Aides - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C481",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 3- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 875,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:50:47.592Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "PCA 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C228",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:36:40.620Z",
            "OrgName": "Susquehanna Home Health Care",
            "PresetMsg": "来的温馨提示: Susquehanna: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:04.214Z",
            "CampaignName": "Susquehanna EVV-HHAX 2021 Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C328",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 234
        },
        {
            "MaxProgress": 99,
            "ProgramID": 715,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:58:49.360Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:41.291Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q3 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 7,
            "CampaignID": "C250",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 559,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:41:25.553Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - Spanish",
            "FrequencyInDays": 7,
            "LangId": 149,
            "CampaignID": "C274",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T07:12:06.952Z",
            "ProgramName": "Assisting Hands Richmond- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 4,
                        "user_add_attempts": 4,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN28b97632339a6dfdafcade0a85165730",
                            "FNa204c5800210318e2b94352b9bec6497",
                            "FN424801e02822313a7355fb793f8642da",
                            "FNffcac190e3082bf23c25a78153e4cc22"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNadea5b591398b863fed3d948e65d04df",
                            "FN2b1f49db3a7f30586c3ae1eaf8a671a3",
                            "FN5d4e06fd151baebc65cc8a09732a3c4d",
                            "FNf6334a29f29b94bca9ad8256f7e625fb"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 4,
                        "user_update_success": 4,
                        "msg_sent": 3,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNda9a2aa8c7f8a2bda6ff0566aefc916f",
                            "FN9f772e76254b1b75b4e29bf270aa4b7f",
                            "FNff0cc93119001fd547be563d26684fcf",
                            "FN64836e66031c615bc503f6528a5ce2f8"
                        ],
                        "msg_queued": 4,
                        "user_skipped": 0,
                        "user_update_attempts": 4,
                        "user_update_success": 4,
                        "msg_sent": 0,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN5ea97b2d2feea7d496362f8d7fa4d8d8",
                            "FNea0b4d29c1c68c705c27cac0134033cd",
                            "FN213cbc0876f88c43ddd0562980933174",
                            "FNa85cc737df30acb9c679757d061d2c08"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 4,
                        "user_update_success": 4,
                        "msg_sent": 4,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 788,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-11-29T19:52:46.472Z",
            "OrgName": "Metropolitan Homecare Way Inc",
            "PresetMsg": "RECORDATORIO de {agencyName}:\nPor favor complete su capacitación en servicio obligatoria lo antes posible.\nEmpieza aqui: https://bit.ly/reminder-HHAX",
            "campTime": "2021-11-29T18:00:00.000Z",
            "CampaignName": "Metropolitan Homecare - 6Hour Incomplete - Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C25",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-06T20:35:40.587Z",
            "ProgramName": "6 Hour Program",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 238
        },
        {
            "MaxProgress": 99,
            "ProgramID": 720,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:12:29.236Z",
            "PresetMsg": "来的温馨提示: Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:55.816Z",
            "CampaignName": "Greater Love Orientation 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C188",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 560,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:50:35.150Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:44.049Z",
            "CampaignName": "Assisting Hands Richmond - Q2 2021 - Russian",
            "FrequencyInDays": 7,
            "LangId": 134,
            "CampaignID": "C355",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 21,
                        "msg_req_sent": 21,
                        "msg_scheduled": 0,
                        "user_add_success": 21,
                        "user_add_attempts": 21,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNcdb5c48464112da6d2bdb49d7ded8c90",
                            "FN72d9e03b123a78ea2a0ecc1b632f612f",
                            "FN033a8b21d9292710a55600dbbfe3359f",
                            "FNb23456c7dc497b9f1e48f0e3fa7339b2",
                            "FNb65aba2a71119dbed58a7d54fe1441f9",
                            "FN0390acb53e82f1bf8b3f0ea375df5d22",
                            "FNd39f9c1e8434a7354e6d7d0aa35a0c69",
                            "FNb76c93d3dc68ca5b101dec67f79c7b1f",
                            "FNa6c6ea9ba4d7e8d7a4d309f66c4be8b2",
                            "FNc3d2c3ea4715a811ff0b19c3756941b8",
                            "FNfb470776dfe29eecf70729a1056b2c6c",
                            "FN3f016a2f7f0063807410f5c558a292ba",
                            "FNdb947c85a592285b60d165c10bfe7f4c",
                            "FN40c8f4eecd3275896a0a4227b795df85",
                            "FN1a5a83e4e61dec8b4d8e9c609554026d",
                            "FNca9b9895b575f020bf98d3971c73683a",
                            "FNff37cbb9829d167276794d28fb816344",
                            "FNb2336a27e39dccffcce98ab7a76b8b5b",
                            "FNfe7ef132c1f2a08bbfa73c405cb6b3d8",
                            "FN8bb3624d39e0798d559fcc1494618a7f",
                            "FN3542a9b4556718dd8a7ede97e0db602b"
                        ],
                        "msg_queued": 21,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 21,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 684,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:32:38.524Z",
            "OrgName": "HDA Care",
            "PresetMsg": "От HDA: срок вашего INSERVICE - вы не соответствуете требованиям. Войдите на https://bit.ly/GetNevvon или здесь: webtraining.nevvon.com. Вопросов? https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T17:00:00.346Z",
            "CampaignName": "HDA PCA 2021 Russian",
            "FrequencyInDays": 1,
            "LangId": 134,
            "CampaignID": "C324",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 494,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:17:07.675Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:02.593Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q1 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 7,
            "CampaignID": "C236",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 79,
                        "msg_req_sent": 79,
                        "msg_scheduled": 0,
                        "user_add_success": 79,
                        "user_add_attempts": 79,
                        "msg_delivered": 60,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN692520c8a7249dcf46036017524fc729",
                            "FN2fa16b6455f450d59518116695ee2937",
                            "FN2f81ad533f0fb34a3ea314ffed20372b",
                            "FNfa51c26f500f1c099bf9ad04ae68d86a",
                            "FNe5fae7b5c321b750f994258e9852ec10",
                            "FNab881ee283e77f0acdf196a55a72468e",
                            "FNfe4a7aa31a882d1ad77d67d57009626c",
                            "FN07bc4f85191e7680ae480dba17c6b5d9",
                            "FN3335409d873baedd1f86893baf01da73",
                            "FN87e5fb143214701d8c25eb0088167084",
                            "FN3fb43d9189ecbdfb7e3081fa5256cb84",
                            "FN070a8eb6b61df62e2556f06fd175a877",
                            "FNdd46467b979864f3494059897f17185c",
                            "FN993b912138c6024ca730990276a9213d",
                            "FN1cc9c82697b65c64b2547ebcff0803ed",
                            "FN6ae1167ba63461b0dbb4d92c30e9c953",
                            "FN22d17639297b14372d6c7b98e94effe2",
                            "FNc479a73f542b2981b84039ec6d3f2f8c",
                            "FNe9ec78a42567f538cdf5b8975a36a0bc",
                            "FNe3760fd89a88de17e68e82a99a2a56c4",
                            "FNf0ce5eba5a649d1d79207e76eef168a8",
                            "FN69e508528146bdc8fd18f5560e7d2e19",
                            "FN59ce95c3397fd73202cedc43d16c514b",
                            "FNb99c864f6ec2c828adfe6a63c339473d",
                            "FN740625e57d3667a36194d4084e9d43ad",
                            "FNc417e0721c5cc95446f07aa5f3133b3b",
                            "FNef10c6325c9e653f5e28437b54ad1646",
                            "FN95019e74d35a2bc49bee39f1bef47a0e",
                            "FNbec17513cd9ae9792fc9a09cba56aea2",
                            "FNef28de758c1567c8eccff0e2c9158034",
                            "FN19c510beca8450623caf5ed9221eb33e",
                            "FN20d3f13598b079a60f2ed1382f61bbc4",
                            "FN39bbf63ac1c5652ee03e33f699fd8af4",
                            "FNdaf184523505f4df7518f8136e8e3363",
                            "FN2f298b8b8af988e84b557526197cf270",
                            "FN56edf4bf3e013833b13b9104ece9a0f8",
                            "FN91e750f627a97bf4699cf386207d9332",
                            "FN674081553d12be42ce78a39cd18f6acd",
                            "FN8d8efd88d40d48e09af84cfd7b7993cb",
                            "FNb0f198a036a72f120fad9d238c148a44",
                            "FNad7b4b0c22caaa05432ce99b2d766c1f",
                            "FN37967e94aadedb91b13f2bc46fa5e433",
                            "FNbaf7dde7a0dae06bfb6f4ebbd5133eac",
                            "FN33279dd45b3ff55705cd4c73bda0717a",
                            "FN2bc0a9f25f2c948ac02200b23a28d4ac",
                            "FNabdb4e546a87d2d9735b80a5b9660300",
                            "FN1c3c1a34825d73f3e442fdb3848b0a92",
                            "FNf7bbdb4c7160326d27c38cdb2a427315",
                            "FN201faa0499e85913be9738f479df91a1",
                            "FN7434f3bcf1bbb2d033061bb5d841d18a",
                            "FN57567490ddf734a1e548ea04d00eb60c",
                            "FNf075e923c99cb7396e8abde7d98182b4",
                            "FNb0d1da2a322f907619025d3f5ee34f56",
                            "FNe6ddf93313af159534f351c711a57879",
                            "FN466ed1bdac569db628f9f8a6e4bf0fa9",
                            "FN8e87495875c5e34d212905877cc04d98",
                            "FN0fe73cd301c53a64ae622ef7ba91364f",
                            "FN86f9e604516d8b55ae655c6e505760e7",
                            "FN1b07025f5c1bf5e268e9d5032e077fab",
                            "FN18a76e580fc3d30f9bcf16d9f57cecc8",
                            "FN984ab6c0c3e8c3a8b9a99dbdad5e8708",
                            "FN55129ded4474a0b7466dde29e0f0066c",
                            "FNdf82915c5a29d439dc1bb101891eed1c",
                            "FN978b5f8af6cde5e832c21daad1b654cd",
                            "FNc0c6806dbbaeae1e4e82ffbb6d4be7be",
                            "FN9f3dedff833e81ac45c0448262995d53",
                            "FN4f7675bcbdc6508fd558d1dcf7504144",
                            "FN8a583287bdcdb0ceeddf99a2bb40e720",
                            "FNefa78614ce3ca746d5729604d96f659a",
                            "FN5bdc1821cc74e6506b2c83cd275bfe84",
                            "FNfbb337dbe01228bdc78aba91e6147781",
                            "FN0ea7041f5a503843be4809ae1097680d",
                            "FNf3479ca6c886e9cf74c0a8abf5ae6815",
                            "FN6a99f218b2aecf5fddb2db08ff7ffd12",
                            "FN70d05c8ee4cc354b3dabc7ffb88d582d",
                            "FN3405c0f6190d281ea3a1d6aa509ed836",
                            "FN6da15afe3b53503d6fa1f85806f76aa5",
                            "FN199374b083e05f400b284993e20b900a",
                            "FNfb28f73aeddbd769fe10b6039134ba38"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 11,
                        "numOfUsers": 79,
                        "msg_undelivered": 8,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 652,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:58:36.260Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "RECORDATORIO! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:58:57.415Z",
            "CampaignName": "A-Plus In-service1 ES 2021 None",
            "FrequencyInDays": 5,
            "LangId": "null",
            "CampaignID": "C383",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 ES",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T16:41:13.336Z",
            "OrgName": "Friends and Family Home Care Services",
            "PresetMsg": "REMINDER! Friends and Family: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T21:00:01.731Z",
            "CampaignName": "Friends and Family EVV-HHAX 2021 None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C41",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T16:52:45.048Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 229
        },
        {
            "MaxProgress": 99,
            "ProgramID": 740,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:37:03.406Z",
            "PresetMsg": "\"URGENT From FADMO:\nComplete ALL In-Service ASAP. All training is due by 12/31/2021. Get started: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:37.482Z",
            "CampaignName": "FADMO Q4 In-service 2021 English",
            "LangId": 41,
            "FrequencyInDays": 1,
            "CampaignID": "C155",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q4- In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 10,
                        "msg_req_sent": 10,
                        "msg_scheduled": 0,
                        "user_add_success": 10,
                        "user_add_attempts": 10,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN58ff287b69c8117baf162189fbf41416",
                            "FN921be557428b9eb7defda29bc9861b46",
                            "FN199f9f02a794fe7996b9f7fcc74b9990",
                            "FN7b02a1848eb18c7c984a5266123e3156",
                            "FN7a53d80009e18821ca39960f9557fac7",
                            "FN6b1926e30a78e0275a99ae2f8f4a1bc9",
                            "FN315c4eff45f43104ff21f4ac304037ed",
                            "FN78ad485e6710964a19910dd8734967ea",
                            "FNb0539e3018650be4cd78373eab17cb6c",
                            "FN16aa57c19027eb246ac045b7fb06b010"
                        ],
                        "msg_queued": 10,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 10,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 560,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:45:16.659Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:59.550Z",
            "CampaignName": "Assisting Hands Richmond - Q2 2021 - None",
            "FrequencyInDays": 7,
            "LangId": "null",
            "CampaignID": "C351",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNd7e514e91ded1112cc334824cc34c862"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 480,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:22:33.252Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 1 Spanish 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C407",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 1 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 510,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T18:45:36.389Z",
            "PresetMsg": "RECORDATORIO! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:00:45.487Z",
            "CampaignName": "Elite NC2 Class 2 2021 Spanish None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C54",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NC2- Class 2 2021 Spanish",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 11,
                        "msg_req_sent": 11,
                        "msg_scheduled": 0,
                        "user_add_success": 11,
                        "user_add_attempts": 11,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2c1efe8d90f3400647d8688b5ae92e0d",
                            "FN1652b644149111ddb7793f9264e97a1a",
                            "FN9f71b90c83d773e30f18988e92abad57",
                            "FN370cbedcecdbc519cdb4b6237bbe48e5",
                            "FNa495e926f8077ed3594de4579209ae20",
                            "FN061c5407f94f34e55bbd4a7058e09d27",
                            "FN1d128bad33e00f335e9670138151e86f",
                            "FNb147443f748ab29ccc9adcdf21113207",
                            "FN097c0682331da111bcc973a6fcf4e41c",
                            "FN158952f45defb7032a8922867bb3db8b",
                            "FNb41ef23bfc5357605ce485eec22f0d2b"
                        ],
                        "msg_queued": 4,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 11,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 684,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:20:14.170Z",
            "OrgName": "HDA Care",
            "PresetMsg": "De HDA: Su INSERVICE es debido, no está en cumplimiento. Empezar https://bit.ly/GetNevvon o aquí: webtraining.nevvon.com ¿Preguntas? https://bit.ly/NevvonHelp\"",
            "campTime": "2021-12-09T17:19:47.127Z",
            "CampaignName": "HDA PCA 2021 Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C309",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 562,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:05:01.096Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:08.850Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - Russian",
            "FrequencyInDays": 7,
            "LangId": 134,
            "CampaignID": "C367",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "ProgramID": 607,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:24:22.018Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:39.086Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q2 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 7,
            "CampaignID": "C240",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:29:47.227Z",
            "OrgName": "HDA Care",
            "PresetMsg": "REMINDER! HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:12.328Z",
            "CampaignName": "HDA EVV-HHAX 2021 Haitian Creole",
            "FrequencyInDays": 1,
            "LangId": 56,
            "CampaignID": "C320",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 14,
                        "msg_req_sent": 14,
                        "msg_scheduled": 0,
                        "user_add_success": 14,
                        "user_add_attempts": 14,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNec3d11dd003291b1a462ddf6fdd26990",
                            "FN031591f30dd9ada2679e21e36e05c1b6",
                            "FN30977e091a0e23ad2cc1efc124caea28",
                            "FN22dc9505ddaf1c95d32b4a5a3913f7d2",
                            "FNd84ef54206347f3c2b01ddb981078f2b",
                            "FNaf662768702ad502d2708c68e7e73397",
                            "FNbdcb924b0ebbe0ac68a2eeafee98f63c",
                            "FN8efd727c9df874c82dce46b738181de6",
                            "FN82c657d1eaa10a39bf8d1e762fe4df17",
                            "FN7509656664937168ad59ce22d3362163",
                            "FN7bce3418192a7953514c2c738b9ddea2",
                            "FN1b4d409de444b88ba1d0e5bf57395d13",
                            "FN89fd0b713f6d56252ffbaaaa041ec4d7",
                            "FNc03d29bc7839298a6df80a5eb4b107ce"
                        ],
                        "msg_queued": 14,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 14,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 678,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:56:40.342Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:27.858Z",
            "CampaignName": "2 Hours - In-Service Program - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C423",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 682,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:23:53.537Z",
            "OrgName": "HDA Care",
            "PresetMsg": "来的温馨提示: HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:21.617Z",
            "CampaignName": "HDA Class 2 2021 Chinese",
            "FrequencyInDays": 1,
            "LangId": 30,
            "CampaignID": "C312",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 2- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 440,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:42:14.945Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q2 2021 - Russian\t",
            "LangId": 134,
            "FrequencyInDays": 4,
            "CampaignID": "C573",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 283,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:12:48.950Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 2 - Experienced Aides - Russian",
            "FrequencyInDays": 4,
            "LangId": 134,
            "CampaignID": "C478",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T19:13:28.111Z",
            "ProgramName": "BrightStar Care Program 2- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 472,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T04:48:19.495Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "SI Homecare- 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 5,
            "CampaignID": "C589",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 592,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T21:10:50.500Z",
            "PresetMsg": "RECORDATORIO! Health Association of Niagara County: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Health Association of Niagara County",
            "campTime": "2021-12-08T17:00:01.785Z",
            "CampaignName": "HANCI H1 In-service 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 7,
            "CampaignID": "C120",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HANCI H1 In-Service Program 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 183,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 86,
                        "msg_req_sent": 86,
                        "msg_scheduled": 0,
                        "user_add_success": 86,
                        "user_add_attempts": 86,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN68cb3f3531c8dc8bfc74a2ccc55e02a4",
                            "FN175ae46b12544d10f344f2f3537c0ad5",
                            "FNba0e4f0e5e8e610d721bacb607139b3b",
                            "FNf27738af37978a1aee86bcf360d13ff2",
                            "FN18ef1459e8949da0d9b660a13bf37cc7",
                            "FN90a7d6df17ab2cb285880f3c1f914858",
                            "FN7d7f7cb5d1fa24cc8ec447ed05dcabda",
                            "FN285760eacafaaf4989f68a128ddf65e1",
                            "FN7b494c7f04ead1bb177dc22862baabe4",
                            "FN9e02f481d55b9235fd4b3019d9649a81",
                            "FN03868b32cd836fccc2156e4d8a8b1bb9",
                            "FN78838aea01c5fa870f8f5505a6135247",
                            "FN58e8752253b1178e744ee6f9e43b22cd",
                            "FNc8fceb15cef0a52b94250e7ea651394c",
                            "FN95c2db1f3ec9798d42c26deb385601aa",
                            "FN57d3535fdbe44917e744746b6eb99cfb",
                            "FN86c25a44da825d5ccdae9438a3da7329",
                            "FNe69cc36c57ac241e947e91cc0b13f4c3",
                            "FN1c3acd8420cc286858fceaa94965120f",
                            "FN1960c68c3cd0be33e26d69ad3ea4b917",
                            "FNcf18b3c9b9043fbc40e82c16345ec1e1",
                            "FNf23e6e51415b9cd57dca96df296555f4",
                            "FN84a3c7c5ed5d7e1bb4b89fc03f626ecd",
                            "FN757c79bf95cbd2b34276e6e24bd078fd",
                            "FN942643c8be448cc5b05ef82f2587332e",
                            "FNf96e099a0dbbfc66468c1bc6eeebd586",
                            "FN8e10cbfccca62d36879ae53c9c0f163a",
                            "FN99c23f82b39bf6b9d725ac71a44eaf39",
                            "FN201d2159026514c11947a5be2f8a01a3",
                            "FN27844a95d35ffdb8fe598ab1b6e98b06",
                            "FNc0893da1f9da3612e511ac7a0c660fc1",
                            "FNf88ba9c2080754f8626d2fb9664da0dc",
                            "FN717195ae73d6d58fddf68832009eb6e9",
                            "FN94b50fab628c5248c9ee2e15bef3b23d",
                            "FN2da25aedcce7832f782b666d725cd183",
                            "FN5412a31847bbb9cc4740c6b53c6b7829",
                            "FN619dbca9693ce195b1371ab9f5228140",
                            "FN25f94f7bc70cf53ce03d3e76dcc5af32",
                            "FN9e2cbdd91a3534bea4c120cc03608b7a",
                            "FN177ebd16946a7a08b4c9deb2193c397c",
                            "FNa93bcf747afd472e47cdc662cea6f699",
                            "FN35a3ed545268c5531237e2444647fafa",
                            "FNebd7f7cec99d1fbe14bfcba23fc1c910",
                            "FNd9dac51b510e6419d5f85550270463f3",
                            "FN77f2956fc8f786b35d4ae3c7c0003cd9",
                            "FN5605abc2cfd74a669b98c3a73bd1b687",
                            "FNe87bcf2417b2de8a7cadd28526709789",
                            "FNe303436e9add87c848542d5a9d99aea0",
                            "FN8337cf217a980923919f31355e96d97c",
                            "FN4446adce976dc5c49dbc5690d9ac9e3a",
                            "FN04937b1df8b438f2700b35eb0fa8a9a1",
                            "FNb7954df0cc484e8f986450b90616f328",
                            "FNfbb3bdba5a0b38d5b71b91d8624d9839",
                            "FN2b49e9ca0ef00f455537eed8f2e79a85",
                            "FN1421ab643540c0dd19935724b6355884",
                            "FNd9856473cd0281b1409eccaffbe81d6b",
                            "FNd81d73a271ea17034ae4f999752c55f9",
                            "FNdb0969c000d31be2d5457ddd2aa56ea6",
                            "FN96b88f9d11ddc2a8fbf17b0f58f575f7",
                            "FN556fbda3de96d7b87be55b2959d7fbf9",
                            "FNb9907c0df518f44273131e74c67e38f5",
                            "FNa8c6ecb9fd8fbb5d1b71d77703e0dc4d",
                            "FN3c421acbd3fb97b2c89cd02706d11023",
                            "FNc481b03f58f9c035a4f6333f793cce56",
                            "FN4064b3c075e160fbd60d8efa9069e1f2",
                            "FN4cbe68f18743837b2cc9bfb23787ea1d",
                            "FNa843c004bcde7347d0435b00795d7d78",
                            "FN291322dfc4a413c53421e3f41af3f2b5",
                            "FNd4876caca95b8528531e0e4395e0b29a",
                            "FN54f25dda922e61f13823730e562a8a69",
                            "FN3aa99e31e2d96f50da1b8248adb4f5a0",
                            "FNfee9b804bb2d11ccc874711c65f499fe",
                            "FNbbc7098df21861798741d2735aff1ace",
                            "FN067fe94230c489898a397414fb8ebc20",
                            "FN8436d10f500c73b85bd207d2ed91c39e",
                            "FNbaad806bf2bcef93040a4394c8f95542",
                            "FN80af451fc69aeb544369dcf174188ec1",
                            "FN48d16ac4970269a7cb7d824661327987",
                            "FNb11a845703e9e7b14c5f251225805f1b",
                            "FNfc07e4f8d9be5aac954da8df030cc964",
                            "FNb4d2335f40859c20f92fa22a08677ed5",
                            "FN1c0ca48c5beaad6d8f7d486d86f09502",
                            "FN52a149f20896569adbb374de32491edc",
                            "FNbf7854a8799e3ee549dd83b0c47c7fff",
                            "FN33cddf0dee3cc1ec6e8fa57810958c90",
                            "FNbbb60073934dd1e6c942157a873afa43"
                        ],
                        "msg_queued": 85,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 86,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 589,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:15:46.241Z",
            "OrgName": "Open Door NY Home Care Services",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Door NY In-Service EN None",
            "FrequencyInDays": 2,
            "LangId": "null",
            "CampaignID": "C444",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T18:18:06.269Z",
            "ProgramName": "Open Door NY- In-Service H1 Program- EN",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 182
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 23,
                        "msg_req_sent": 23,
                        "msg_scheduled": 0,
                        "user_add_success": 23,
                        "user_add_attempts": 23,
                        "msg_delivered": 8,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2bf8b053ebaa61fb42bd0ab2be804366",
                            "FN76c83d52efe8a86765e75925d98890d6",
                            "FN58efcc392dfb1a1cc901841dc4a53240",
                            "FN4b56efe71cd71c5299b351bbf7e0e8f7",
                            "FN20b12ed767428d3359906aabd9e9c285",
                            "FN62dfcdc31c36fbf8879e86890d6d99a6",
                            "FN853b0cca981f214e1fb18e36ab4c977b",
                            "FNcd13692d3b8d796629bc7eefa4e8dfba",
                            "FN368ae828e8354031d1c96d08b90b2207",
                            "FNfc84ce4f685a2198fd886d848a921c57",
                            "FN2e6b9991689d897a65eaddc626c2f140",
                            "FN9737708b547be297d9f2b151f3eede40",
                            "FNed22feab600c217d8b228e658ffd4f2d",
                            "FNc86b2264c7478bbf5ff59da495c8ef3e",
                            "FNf51ee2665625d89aace4ee5fc2973202",
                            "FN9076729ad012bc6d01a333ccd4d56e18",
                            "FNe5c0f9da36002431ae962641dbe6edc3",
                            "FN758e0e7f9ee06d24a2a1a6899024690f",
                            "FN9bf28d8e461acca529222a833bd857e6",
                            "FNf184307bf226a55ee2b8fa0590eb908d",
                            "FNbefd73a975424746eedfacf8ffff0af1",
                            "FNa358181e17705b65e929457f74d4da9a",
                            "FN6d1f4f0e9bc893ebfe9f47b6ba52a658"
                        ],
                        "msg_queued": 10,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 23,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 265,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:43:34.700Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 2 - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C459",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 2",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 625,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:25:05.307Z",
            "PresetMsg": "\"URGENTE De FADMO:\nComplete TODOS en servicio lo antes posible. Toda la capacitación vence el 31/12/2021. Empezar: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:32.876Z",
            "CampaignName": "FADMO Q2 In-service ES 2021 None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C141",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 100,
            "ProgramID": 191,
            "DaysOfWk": " 0 1",
            "TimeCreated": "2021-10-04T18:17:45.790Z",
            "OrgName": "AAA Health Source Inc",
            "PresetMsg": "asd as",
            "CampaignName": "URI _ TODEL",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C14",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "AAA Health Source 2020 12 Hour",
            "CreatedBy": "uri@nevvon.com",
            "Deleted": "2021-10-06T19:55:48.820Z",
            "Active": true,
            "OrgID": 130
        },
        {
            "MaxProgress": 99,
            "ProgramID": 740,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:33:25.501Z",
            "PresetMsg": "来的温馨提示: FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:42.605Z",
            "CampaignName": "FADMO Q4 In-service 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C149",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q4- In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:38:58.433Z",
            "OrgName": "Susquehanna Home Health Care",
            "PresetMsg": "RECORDATORIO! Susquehanna: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:23.628Z",
            "CampaignName": "Susquehanna EVV-HHAX Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C331",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 234
        },
        {
            "MaxProgress": 99,
            "ProgramID": 519,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T21:42:41.271Z",
            "PresetMsg": "RECORDATORIO! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:00:46.939Z",
            "CampaignName": "Sophie Q2 Safety ES 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C277",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q2 Safety ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 715,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:00:12.520Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:01.568Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q1 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 7,
            "CampaignID": "C251",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN25995590a9a43f0be384e88c2698acfc"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 483,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:32:25.949Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 4 Spanish 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C416",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 4 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 607,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:26:00.457Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:39.230Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q2 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 7,
            "CampaignID": "C241",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 441,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:45:50.780Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q2 Spanish 2021",
            "LangId": 149,
            "FrequencyInDays": 4,
            "CampaignID": "C574",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q2 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 838,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:19:50.088Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Hands to Heart Home Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Hands to Heart Q3&4 In-Service 2021 English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C562",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Hands to Heart- Q3&4 In-service- 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 170,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 230,
                        "msg_req_sent": 230,
                        "msg_scheduled": 0,
                        "user_add_success": 230,
                        "user_add_attempts": 230,
                        "msg_delivered": 86,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNf1a5d61a65e75018973bce84aa1df80d",
                            "FNea0b2ee8d6094f13252a4236843db68a",
                            "FN22d262b7c157305bd55dead1a285e8fe",
                            "FNdae2f8a2d8bc6793aa2c96c03280de8b",
                            "FN2091234ba04804643481682500a623fe",
                            "FNd58b5a20e006073ee507b34cc26df9b1",
                            "FN3ccb0edf6dd86d61547314e4389e4725",
                            "FN439617c123f779fe952b07fcab8f9dda",
                            "FN8fac9aa6eea89566d620a4cc9b345a8b",
                            "FN4e190dc8648ed6b39c136769a26cbb52",
                            "FNc27f74d9d61cec958d462ab18330560b",
                            "FNb929e997723a31c5a60f105c54207f17",
                            "FNeae622d3d3c33f2d31d9d3b6a2b37a18",
                            "FNa133fb418b8d6e36890b35dd47926db3",
                            "FNbee70cbfe4d5ce33faad43b47f5b111f",
                            "FN8dc22e7a00af78581d6a71d55d913123",
                            "FN7a48c49549017b8e5f1954092705566b",
                            "FN076d80999931de9c8c7ef74265e03041",
                            "FN4f5308e9eac5d9e84cbf19d4da3ce9b2",
                            "FN1548006242a3a0377d14a490a521edfe",
                            "FN689d0a6b8dc32a786ac830d3813e0ef1",
                            "FN9b57cb274105a33773398288a274ddd5",
                            "FNbcb3f67d5f124111eaf1166bc594fd4d",
                            "FN9889089ce2d1b70eb946a126391e6417",
                            "FNc3f262027ac0ec3253e694f0481bd16e",
                            "FN0b7a8c14768fc542c487326861568bbe",
                            "FNe25779b4b132117e828ee832c70ce212",
                            "FN8a7dafc92e00238512b8e54ee2331f9c",
                            "FNfc5d6c94a0a8b4184fcd6ff954b83747",
                            "FNaae5447ab03d5f452d282ae212febc2b",
                            "FNcdf766cf1fca68fb66f9e2866760860c",
                            "FNebbde8eeb1b5d79cd9b1554e54659e6c",
                            "FNf9ff6dca725d7c7a33e8dce8afeb61d2",
                            "FN1e245b3ed6ff2fb9734b276277cb4f9d",
                            "FNa2f486017b2c432a233f896e69b4e065",
                            "FN5a28620aa352c05281ebe69ca41b1d5f",
                            "FN187451ad4a46066d624e707f71d56853",
                            "FN3f2a588c399cb3cb7c1e4a57ded3c2d8",
                            "FNdde2e0b5e6f0de1ec229c775e1950501",
                            "FNf476734eb98de5550fd74f6547609f8e",
                            "FN421a3a29c8898e83936b5c1d417deb98",
                            "FNc11844bab00863c19c7e8cb5076bfa95",
                            "FN89313c75a9fb36b390019d82fa13b25f",
                            "FN9b2fdc218048d98060736e27a3c51df8",
                            "FN675c31bf1ae64ca6b7bcaa0dbeec35e7",
                            "FN0a7dd06e66b4492c41ef8f65baf63703",
                            "FN7d7aad3ef979292646a171c189678e1b",
                            "FN977f2cc588ec3b5605eee4510de8455c",
                            "FN0f0ade80073d2adfd279d0549befe439",
                            "FN449f6185327ceb1296e305506a1c60bb",
                            "FN65e4cf52c9e26ed4a3e150dc26816f5d",
                            "FN2ff70106701945bfdeb184c3b8a4cca3",
                            "FNb3ec9a4a255e358d09143dd7579f7ce4",
                            "FNd6c05c98812f64275e9e73305eecb79f",
                            "FNded1cd31520adaa8d6640582cf984f53",
                            "FNddf2433d9e7dab30a494cdcbbb5d12aa",
                            "FNe78cbddc800227517a2720bfb91221f6",
                            "FNaac896ee2429b36ffbdcf2eb992625d2",
                            "FNd32f24a7e627569dedc8e09f750a2316",
                            "FNe404d84aa386e5c3e0178348a9f6e9d9",
                            "FN0ab59182deb4b0f44e9990459f074fd8",
                            "FN4f96ef0be3d1e0b3247616321eaeb2f1",
                            "FN187d68f9aa106ac74a9041e2927eea3f",
                            "FN2b9d622206d516757ac1c1549d3c14f4",
                            "FNc4e5341644a7ad3b89269c1f3715f9be",
                            "FN9b80930149508a4fd8e4b9d5a86d5ff2",
                            "FNbc109e1bf704b7f1dea18bf0953595c1",
                            "FNa66ffc5446d6cad15a015572adb3e174",
                            "FN9507489e4c23e1ee078bb700a4da6320",
                            "FNe5968e051ce350dd12d9a07ee30af10d",
                            "FNf4bf52094c607e15767c43e45a240730",
                            "FN1ee1489fa25fbb78bce41085f23d0baa",
                            "FNf8e217bf62411608533e669797bec21c",
                            "FNcfb0a9315d0f6dce0a728d4375713883",
                            "FN10d9e0bfa24aff50e8b70291a0e931a5",
                            "FNf7ab794bc32c8a64e88f6896a7432ba6",
                            "FN7815f61f3a8b3a7259689943411d5d3e",
                            "FN7778cce73c6e3283378f8baeaeb991d3",
                            "FN0e44e4365511df500efb5f6c67faa201",
                            "FN6018abe93090afdd93c2635f104dd4ee",
                            "FN28d9050de00d74214115da949d4f3486",
                            "FN1a289e93620994a1a2d3578bcdde874e",
                            "FN86d0de998591e673fde361d8c704c056",
                            "FNaf382a266716e24aadd62388090ee31e",
                            "FN55ee742ef7817df718162ee4346504ef",
                            "FN0aae9ff489fd5495598db94b76a2b5fc",
                            "FN5c97f213fd6a313076c6eb927a491f89",
                            "FN52cb293ca77ced7f23ea20f086bc989f",
                            "FN6627b095054468cd04319d0c8e1a0484",
                            "FN506884759b198b53db0d1ef27f159e27",
                            "FN3663afae1062b5d51b66e8e9ea965b9e",
                            "FN7d48d0837e9219b78c4419d7f7343392",
                            "FN10e9629ba7caf373b6fc1763f9e1e9c7",
                            "FN40318e0561f5767af35c2da4ace1602c",
                            "FN8ca4d7624f6a22bb2cef65bca5e1a56d",
                            "FN8bfe0f859998959e57f7223f554d370d",
                            "FN2d6762a2bf2380164c678438c970967f",
                            "FNceab4d49bdd07d2d227485be3f9f4d85",
                            "FNb9763a9c1ca86dd6e1985a05eb945d2d",
                            "FNa5d216ebc86436536ed96e649821e9d5",
                            "FNa5bd9186390ffdd86c86733243610215",
                            "FN0dc3a675cf02de39f32696e51f6bd0a9",
                            "FN192e246fd131aa481177a0c61e9b1aca",
                            "FN3d7381e4a33a709d503cffecab1f307c",
                            "FN983dbb3c8cdbbcb9bb052f161f62f183",
                            "FN97e187d579e7f0213e6a9ed92bd935be",
                            "FN6a1779122e2bafa236fcb4f7f7a9dff2",
                            "FN8691cbf592472437ce108ee59419ffde",
                            "FNc6905439f78042bd544494c153b6f154",
                            "FNa85e5930a560702225134994a01b3ed8",
                            "FNf76b58d70b8954d405ff72585834203f",
                            "FN6a9c4be47305b81b4c0cf09971dc9b00",
                            "FN1619a18d59125392be362d72715dfb0c",
                            "FN0ec3f6f0deccf23fd869146c9101e64c",
                            "FNa2f7db71f198907ed1719a6779f30ade",
                            "FNa92b72f1013aee03c26852f5d52f2512",
                            "FN54e3ac8475023c11e563539acfbf112c",
                            "FNd56504391a7f5f204c89b76095965f5f",
                            "FN9daa659a12c624f02208aea91ae10d3e",
                            "FN16167b3e344c6815be72218889d2ea0b",
                            "FNc6543c5a5c451fcc1db80d184c44c78c",
                            "FNb6fc747b568fc5943d631c3c1fae6494",
                            "FN5cbb17dfafc1e4d030e4a21425301f75",
                            "FN3023a07ca0b8cbd4be036beaba612856",
                            "FNd37d31cdeccb89ff9f9df4c9fda75d38",
                            "FN1e2d1d18a3fe942bbca87c89dd56b9ba",
                            "FNe99c57ba9ceb1f6507f87c88c1e2d091",
                            "FN50c54be592eb1ea1c7d88d926e32db79",
                            "FN0d40422248a2c11eb45fdd9c4771ded0",
                            "FN2016c1e9e7fb43f00d7644bcb7aa4d94",
                            "FNaa7f5c4248339b970cadb1ebc67a954d",
                            "FN102ee3608dda31790d5a3de41c66011c",
                            "FNb5b2d42e75baf82767dd461dd4cdbff7",
                            "FNbcf28b7c123d25ac0190307ba4b5720f",
                            "FNad3a59b21e97af3d3d0696dfd1d77fb9",
                            "FN3102423710bd94b0721f08fbfb92e8b1",
                            "FNfcc38fa44f34a6bb90578d9395cb87dc",
                            "FNf0622bf6292da9d9a29e977db1046ec1",
                            "FN1dccdd452cdeb89e821561008f53a114",
                            "FN4a1f1e2a1f7069c5d109a2b5d9b6fbc4",
                            "FNa687cd7b847ed3f0b177c3c1cc263082",
                            "FNe3433b499c58c03edb78e44bed13d6f4",
                            "FNd87db6851fd73395e9a4a3d745d1ed1f",
                            "FNede2447523a5a9182fdfe41045017ca7",
                            "FN0957dbaa747f2cf4f8842375a5b1d792",
                            "FN1fd52d445ffd0e93f0e12063deb16294",
                            "FNbbe883c1aaf06fa6184f05244204393f",
                            "FN4ce98022ac5b1d10f59ccae22840bd4b",
                            "FNa5d917c1f61ca5bd4fce3eecad3549dc",
                            "FN35b6b0ad8548c1be2497d4f54576da7f",
                            "FNa6de6c85a92a236e03965bd2fa3395b5",
                            "FNcf64aa625da7d2070d43e9c731e0ffe0",
                            "FN5fa62b44cf13166669b1080c523e0388",
                            "FN976a2ca85108e177635902934adaf914",
                            "FN577a6ae7f152e16bceee1366a9c3f4e3",
                            "FN44c7b3e9d0cf5693afbb9f65013cfbec",
                            "FN109d0dd3db2b97764ff32fa8c73c1fbc",
                            "FN131359f35645d4f3474e3a6b54a91f05",
                            "FNa714f8f84bb3b0e388427150a5857549",
                            "FN216c851f76b125e75701c25b69f243f0",
                            "FN6514dfeb59154192401871e584785587",
                            "FN18b2b93e3a3f0a1825aad106810a5210",
                            "FN7b90648dc6b06cb9f2956d92a7df324a",
                            "FNc682376f1934d77daf9a582f20aeb3fb",
                            "FN1db84af8a7ea5371907e259f8adcc96d",
                            "FNe14f51e4ad86ba6708380cc2878c38bb",
                            "FNc9198adf2d20a0d406b1465e2b970afa",
                            "FNeb7c7650eaf023a6efaac1c4d4c968b8",
                            "FN1e5f6cb5fa2bc2c2d99a72fc1e85d9bd",
                            "FN7fd2317b32470b3e53cde17b7f54e179",
                            "FN004f506dff54a24907754bda302ad786",
                            "FN56f9be8769b5b54d0e3a64b51248760b",
                            "FN3255bfb266795a6aa834e7a2a4fcc841",
                            "FN5d004bad781956cca804130487583902",
                            "FN22388960d2752f4b696a4dd99264d4c7",
                            "FN6c1046d445221850f0b56cb679f6d61c",
                            "FNb0c78411cbf28a71844abeb6c81f9044",
                            "FNe3378cb4b757878b9ab04f73fae34ffb",
                            "FN86f4515a868ab89eb7fb18e58b17517a",
                            "FN3fb32379ddabe86616715a8d4f9e6916",
                            "FN98c6aba085a9a63cd59bd1bc92b82ee1",
                            "FN2aee87454275fc3c6c35c9732f21b288",
                            "FNca9d078d86e83b040b8a2aa26ac44461",
                            "FNa40c4e26d9f8ec799835d1cae18f1bbf",
                            "FNd968ca23cb855e755c50a942a8903965",
                            "FN590be876e2b1201a675b84602afd3cab",
                            "FN8e55aa17fc3506a395fe2aefa26c3cf8",
                            "FN216efcb1e4cc2c9911033a95a73f62d8",
                            "FN00fa524a8d7b15db04ee828ddd5a3986",
                            "FN04e26ffe814a3292962c5731209ce964",
                            "FN3d8e221f219bff245a17f7fd0dc3baae",
                            "FN79590246568a3f37d2ccdbbfc28a9ab0",
                            "FN684d97c14bf2271f07e7c36aaf026e47",
                            "FNbe0d369258dda96505e42c317b80f152",
                            "FN92b10a109adf132b6194b44884e9d284",
                            "FNc63cc9d62725c85ed8804e744321a911",
                            "FN701aa1ffdcd9b871ee821a004fbe2d83",
                            "FN746f8e0b742bff9c539a1e275eac8759",
                            "FN3a55774d168ff1d0f014c86296030063",
                            "FN29bcccd8aea7b80680f6baf8f435990f",
                            "FN7f34b81bb1c9dfcb339f00f5c04842aa",
                            "FN11a6436357f102c5b4be89ab8728c071",
                            "FN984a44b638cb71aab997e367aff3c1c2",
                            "FNd69851ace70a30cf642d19a21a5cccb6",
                            "FN6ab5d2d65188a24aacbd1dfc271af360",
                            "FNfac149f9b4387c700a016586c7c08ff9",
                            "FN542852e0534dd7074f0df8910802ca6a",
                            "FNa6d990a802e0a7aaa9e9f73994c5b95f",
                            "FNda4bc290c767068142970ec26b803b24",
                            "FN4b70c3975753508dfb2aef3792d615c9",
                            "FN63f3ea92398403863b101b1197019810",
                            "FN3579acbd26c572cedea49dedb0f60336",
                            "FNee81f0ae7f89c873f8867f16a1c2a120",
                            "FN3e173b6bef860a0d4fc11a435cdbcf62",
                            "FNfa62cb31af7611d73232d87285a97a01",
                            "FN9daf4cde507a3a932975851c0b8e84cd",
                            "FN55f31d64b832099aaf8900baea1c3d50",
                            "FN51ae59f39e55c0ba9874ee86660ba189",
                            "FNf65a6d96be6e8bad984f688accb1bf55",
                            "FNef3f6e5020955911e62bb472f53fd4d5",
                            "FN593a49186c7f1ed9a6f3c00c4067e2aa",
                            "FNac7a361d87cd597ae73bd137224d9b67",
                            "FN019c2bd65a5a3e2b1b077996aa2814c4",
                            "FN6aab5d7e9127711d6155f84e11939f81",
                            "FNc4b68035c8d3cb708f41b3bae9d10cf2",
                            "FNf3fccf63886f351164fe8dfa9f0eceff",
                            "FNef455c4e1d4092c8957b765ed31fd7c4",
                            "FNf4ec19cba2873d1eded6c741f64aea83",
                            "FNead687c394a84fb157a538893aa4872f",
                            "FN084c116114317a14480dd0bede4b245a"
                        ],
                        "msg_queued": 133,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 6,
                        "numOfUsers": 230,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:39:23.486Z",
            "OrgName": "SelfHelp Community Services",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SelfHelp Community Services: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C418",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 242
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 99,
                        "msg_req_sent": 99,
                        "msg_scheduled": 0,
                        "user_add_success": 99,
                        "user_add_attempts": 99,
                        "msg_delivered": 87,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN0c067c6ef6dc8ca8abf474b4a696c8cd",
                            "FN4fa5c2ef3820d27157bfae0cf90539cd",
                            "FN1adc9406b48bd8464e912c7f106cc57c",
                            "FNfa534cae1d60cb17eb132830413bb7f2",
                            "FN6fa4c35ba18ca44b2376440f81150b2a",
                            "FN6badba4c4d1d570e5db55e1fd53dd94f",
                            "FNc5f57516712e67416cdde31167330c02",
                            "FNb102ef15b41c48c38a52c44f93278f1a",
                            "FN741ed6f8c94a24e79fb50b07cff93e33",
                            "FNd210a964b83e410035becbb28d2005e5",
                            "FNf7734042b3862df2418b20908de338c2",
                            "FNce8542a77e845c6da669d01ef8b3c041",
                            "FN2b2e67297b33c14b5b98d8be3586f020",
                            "FN892e65477af95edc90e4d45c50d13593",
                            "FN8e4201ed1d992692b8362c85ce5ecb82",
                            "FN7b3065d7d8354c130f18edca703bd8fe",
                            "FN3366be6147e3113585475ec175f415ae",
                            "FN54331a9ff386a21ec1f6aba53cab27bb",
                            "FN1c976cd1bb14254b8569da02dce810d4",
                            "FN8c006c0007a572c1b2acad0968866d0d",
                            "FNb179ceaca189df455a87f3a69a223206",
                            "FN2315599e2ac952f7bdb464c636456d99",
                            "FNa0c481fd3063bb5c5aca5a5e5d592b40",
                            "FN7e5ed0b5eaca7a9a797e7d7d2732af2c",
                            "FNa6415a30c99178f3a43d48fd397afcd7",
                            "FN9eb5c2b7fa68b0c0a349c2808a6fd856",
                            "FN6b03bd62e797260d04382f2fe7dc6de9",
                            "FN9c666c7f83f99a62b30f64bae45bff43",
                            "FN4282a85e1fb727f0e56623b9e670a289",
                            "FN97b1764d4654062b769414d84d893e2b",
                            "FNb40e0fc9a17cc4ca490a59a8ec8eb513",
                            "FNa225cbf50907fa43149ee6acd485a56b",
                            "FN63c39e9f286bcb98d157cf79e4d48727",
                            "FNa0dfb6a50b4864d1e1494fa1427a8bd7",
                            "FN2f1b6d56a9029dfc3912b5a4b5a0eaae",
                            "FN051ac9156ca44592e9ecc0ef06cc7889",
                            "FNd2deabc31626b8ac491a15aedb3efff9",
                            "FN490e9cac72725e6e2ae4455d8705c03b",
                            "FN461c38f2d8a3dd6327a388a5dcf21822",
                            "FNaac601419df05689c5e1b681d85e580b",
                            "FN59def48bb732fbb1d4de7570648a37b7",
                            "FN751369ccb259822462b176f2c94a1380",
                            "FN22188ac9a84f0295011158098455f50c",
                            "FNff6208e4ec5dac82b3ade2b82433616a",
                            "FN26497381b755ba3ff8d5b4d1b89f07c9",
                            "FN7b5eb77d72f85034aea53f68b5f643bf",
                            "FN2602f7434886dc4b1df6b6ea44188a7a",
                            "FN5d132ee8fadb100f551e43220c3ad381",
                            "FN0c6abc524af2eefb9ef50f547eb01cac",
                            "FN9a3f25324bab5d42b7c016c529a5b55e",
                            "FN33bb299b7931c80e6af720a963e2f8dd",
                            "FN0477621189201a5f8d93446d17b7e9df",
                            "FN7947d73442dacac5d86e12d03d2193b2",
                            "FN2c44abec98145c1bb9549da5dd3c6682",
                            "FN25858857266699be265672d55f5cae1e",
                            "FN33adb981f9d61552f162073a5df200ce",
                            "FNc3153a3e5956b6051cf897e7ad031d0a",
                            "FNab816c76bae8d7c2aaf25598bf0a54d1",
                            "FNcc4632c546899c1b2a417a49f16768b4",
                            "FNa100a931e397b5269b30c893c6bb9ea8",
                            "FNcef7b9e6e0ecd5e02c527105bd525097",
                            "FN6a7029f96e1c7d18392b075c1a38de53",
                            "FN697ce1e3749579064d78fe579d77d3d0",
                            "FN3acda413168e005127a3bc38162d4877",
                            "FN3ecd64eaea6b2f118e94755ec6c019e0",
                            "FNb8ccc3e5380e31cea2c3529d2cc71958",
                            "FN4fddfd844162ad51c6de79bec7ff191e",
                            "FNaa23c14ebb33ffb1ea13b39e7bad0d69",
                            "FN50cc99197492d0de5e2147b8fcb5bb61",
                            "FN454dcaa6cd8e85a0b8dd63b2b0a649d2",
                            "FN7fbeefd04b963651985faaeb33e35439",
                            "FN759622a34aa3b41666a147ae0ce9f643",
                            "FNfa69cfe827dfbaefc27ce5aef4a1ed6d",
                            "FN7f1ea7b2c24d86f80f326d495158f39f",
                            "FN1eece6b5658f06a79a4eb2373e508bf5",
                            "FN61782f8fbeb98be1f25cbd0bb3bcdf10",
                            "FN6f82700cd90bd8c64ef05de75aa80d02",
                            "FN340d43ad51cbbb0a5f9d690eb245a218",
                            "FNa1ae6f5b44b9e0c8227e4f63cd403842",
                            "FN96690b84edd15517b498d5f372648229",
                            "FN64ee6979c1ea4f29a4e66905451971e0",
                            "FN9c3ee4094c15dc198b64a84648157684",
                            "FN96905c30d38d01a5d4197d66a78f73f6",
                            "FNe8e34ccc9cfa21cab4df10e3789b2c5b",
                            "FN86f9225ad6349e6c3d50da1ee1ecc29f",
                            "FN442dc28342204991125abd3bb2c0c58d",
                            "FNc01a5390e684878ba99b48298ba6e20c",
                            "FNe37c860a41d44d1dfe23c304f1bcb992",
                            "FNab3c9d6927f434b1ea9652534efa2e52",
                            "FN1e5f376bda02ba882da353ce031d7c39",
                            "FN1af78b2743fa230e7812aa4a6cd7845e",
                            "FN703b73d9e204aff6ab97d454aac5e1e8",
                            "FN50ee6ad8812466a17295ff018056461a",
                            "FN80746bbb1dc22fab516c21b6085ef6fe",
                            "FNae54557b80c393bb89a3220c9dad7a60",
                            "FN2e6191d7b600797c737f2c08e7b74612",
                            "FNf08c153a8dfdba9d5fbd6bed8d24b6a1",
                            "FN30fe905775aca380ead0f58be1f1e640",
                            "FN93acbfdfdd6e57948491b001ca88a178"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 6,
                        "numOfUsers": 99,
                        "msg_undelivered": 6,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 651,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:44:11.241Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "REMINDER! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:41:43.578Z",
            "CampaignName": "A-Plus In-service1 EN 2021 English",
            "FrequencyInDays": 5,
            "LangId": 41,
            "CampaignID": "C375",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 EN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 12,
                        "msg_req_sent": 12,
                        "msg_scheduled": 0,
                        "user_add_success": 12,
                        "user_add_attempts": 12,
                        "msg_delivered": 11,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN4d977b95681be1b2fd66d86d5faf519a",
                            "FN9d9ed046e7bc36726a83e25278e5d569",
                            "FNcb95bad8c1662fb48ada8f9a246a77f9",
                            "FN3884c4e3d5560b06756aaede69fcdafb",
                            "FN166eff750af9783ac9822eb36c4ec249",
                            "FN9480a25020eeec7c7bbe6cff6ee70134",
                            "FN0333a595ac17fe55ad7cc652cecdf070",
                            "FNd4bf618ecd9d53fc975b8655c5392859",
                            "FNbd330f37e8058ca2f5bc2b33995aa3de",
                            "FN2a5e98bf4e46fa01914bcb7a37f63f42",
                            "FNb7a39dad761b5f52a9c30e5738843b31",
                            "FNc127916df373bfe10b3fa6350b598c7f"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 12,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 652,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:56:11.860Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "REMINDER! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:54:15.103Z",
            "CampaignName": "A-Plus In-service1 ES 2021 English",
            "FrequencyInDays": 5,
            "LangId": 41,
            "CampaignID": "C381",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 ES",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 465,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:44:25.263Z",
            "OrgName": "JFS Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T22:30:35.830Z",
            "CampaignName": "JFS Care- Optional 2 2021 - English",
            "FrequencyInDays": 5,
            "LangId": 41,
            "CampaignID": "C546",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-11T00:44:45.795Z",
            "ProgramName": "JFS Care- Optional 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 109
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 6,
                        "msg_req_sent": 6,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNd077d6f7829db76f3b264b3b844f09ae",
                            "FN5cd4af6b2fd0a5b1ac67e6a8ab210f18",
                            "FN5ad0eea3f4e6d1e2b021b07cad0c5290",
                            "FNb62f7f144c994b375449086e7181e6d8",
                            "FN4218fdf4e55a3445a2a40c1f2c51fe9b",
                            "FN2c8a02609d7d5f053a42908ea99011dd"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 6,
                        "user_update_success": 6,
                        "msg_sent": 5,
                        "numOfUsers": 6,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "11.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 6,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 6,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "11.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 6,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 6,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "11.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 6,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 6,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "11.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN931757000655db81d5c252f31232277f"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 5,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 1,
                        "numOfUsers": 6,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 282,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-09-08T16:08:50.659Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "From Brightstar: Please complete your inservice training ASAP to stay compliant. Sign up- https://bit.ly/GetNevvon  \nQuestions? Click https://bit.ly/NevvonHelp ",
            "CampaignName": "Brightstar Prog 1",
            "FrequencyInDays": 7,
            "LangId": "",
            "CampaignID": "C10",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 795,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:20:25.625Z",
            "OrgName": "Classic Home Care",
            "PresetMsg": "CLASSIC HOMECARE: Complete training by the 20th to keep your certification! Go to the office if you need a computer! start here⇾https://bit.ly/reminder-HHAX",
            "campTime": "2021-12-09T17:00:29.716Z",
            "CampaignName": "Classic 6H 2021 None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C194",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T17:23:54.683Z",
            "ProgramName": "6 Hour Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 239
        },
        {
            "MaxProgress": 99,
            "ProgramID": 523,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T22:59:20.437Z",
            "PresetMsg": "НАПОМИНАНИЕ! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T16:58:46.415Z",
            "CampaignName": "Sophie Q3 Patient RUS 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C288",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q3 Patient Rights RUS 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 2,
                        "msg_req_sent": 2,
                        "msg_scheduled": 0,
                        "user_add_success": 2,
                        "user_add_attempts": 2,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNa511a210e5b3e3b8c15f2464e92a363e",
                            "FN6d44e10d7e86a80692061b9f198250d4"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 2,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 653,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T16:07:05.720Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "REMINDER! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T16:04:48.372Z",
            "CampaignName": "A-Plus In-service1 RUS 2021 English",
            "FrequencyInDays": 5,
            "LangId": 41,
            "CampaignID": "C389",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 RUS",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 711,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:56:31.544Z",
            "PresetMsg": "来的温馨提示: Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:03.371Z",
            "CampaignName": "Compassionate Hearts PA In-service2 Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C167",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 471,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:57:47.835Z",
            "OrgName": "JFS Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T22:30:47.583Z",
            "CampaignName": "JFS Care - Optional 2 2021 - RUS",
            "FrequencyInDays": 5,
            "LangId": 134,
            "CampaignID": "C552",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Optional 2 2021- RUS",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 109
        },
        {
            "MaxProgress": 99,
            "ProgramID": 863,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T04:02:54.005Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:46.105Z",
            "CampaignName": "Q4 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 4,
            "CampaignID": "C586",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 438,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:45:14.271Z",
            "OrgName": "Accredited Aides-Plus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q1 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C563",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 133
        },
        {
            "MaxProgress": 99,
            "ProgramID": 862,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:05:19.265Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:01.728Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q4 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 7,
            "CampaignID": "C255",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 40,
                        "msg_req_sent": 40,
                        "msg_scheduled": 0,
                        "user_add_success": 40,
                        "user_add_attempts": 40,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN34ec6c165dc5523ee73f2e581b44e6fe",
                            "FNc3dc231b9cfa88b723d8f65f98b159d7",
                            "FNcf6dc3f6c5660845e3c368141b724184",
                            "FNf710d1d120c04b06b90d951684eeb238",
                            "FN4f7e917be7b3fb5038f3771628d1c618",
                            "FNf9aad7d4979a5650c45415f6922406c6",
                            "FNa3168129ec9ecac39d58b7ba1195a826",
                            "FN4b021742559c11b2bf6b6602ef8b3e75",
                            "FN9486ac571e75d60d6292237d4d84ca58",
                            "FNc43b944cf7d822e53979974ba565c9be",
                            "FNc5fe1b3259798d4bc13cb88666fad5d7",
                            "FN3d80ffbb27227c5833a06836431a12f7",
                            "FN341cf0448a920edf27fa7a7c8e7d86e8",
                            "FN600daef6c91ebd44bfb895ad006048fc",
                            "FNf77106143606b340c2f0f5f6b63c43e4",
                            "FN6afaaf04b205a597a7c712a5f7e11b28",
                            "FNf1119c7b2f90a95630facaf82056a84d",
                            "FN615cbadd485ce3247790681264ce6545",
                            "FN49ee1e7f27fe0ddbd9febf241a68be26",
                            "FNb2157e2956c9738f6e808225aff431c3",
                            "FNc02cf41908908ea66a006f548f7e6a63",
                            "FN117910965b79b68fbf79f5235668e686",
                            "FN2a8defedde0757fa04b20ecf6dcab841",
                            "FNb698b4abdc6d17c5f05de7a4d98402fa",
                            "FN5a970d69d97cb16a8a9e17ef1a9d2d9b",
                            "FN6be36aaf2ba7a576339cee305465c14f",
                            "FN3a8dd43266c3f216ce6ac19bdd7d5754",
                            "FNf1076e522786766be0569b4cdea9a59a",
                            "FNb43dc600f420e185c38f5e69d2b10078",
                            "FN39426c6b71de2224d328cb1bbf005045",
                            "FN2c1e0f71a157b6d45f70e32af27a4abd",
                            "FNd84139357f6eff411303aaf0e9f1a76b",
                            "FNd46f92c6733e623bd171ceec5bf0e66c",
                            "FN261b9a5aa4a232e027d9c29cb4f9a716",
                            "FNe14450345b0d93054417cc4eee816c1a",
                            "FN53cb85ab04f19565f0bef269555d977d",
                            "FN2ab48f5bbbc28c9fac88188100a7d10e",
                            "FN53f9f185cf49c71602ff204e9670fa06",
                            "FNd59e14778bc88cdece0293cb10b28287",
                            "FN1933f17cf57227b62bc8269ac7560861"
                        ],
                        "msg_queued": 39,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 40,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 40,
                        "msg_req_sent": 40,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb9e8a147b1ff5048752a9f7e6af97d41",
                            "FN8c2e99a9ae40d7c07c34cc7c7f691e9f",
                            "FN7535db32b36e6ba42acd9214e57463bf",
                            "FN09acdb29819bf9f6904cb8e0d27a4229",
                            "FN918f222f915ca6e4e901be4a501554c9",
                            "FN4aaffd43138da62351295dc1c918cd72",
                            "FNc7f28a670e5ff1fa556749cb9ce87423",
                            "FNd05904ae485a8e77e02a5934be810143",
                            "FNff98082d584f1995d94c9f58010233a3",
                            "FN0724570f7aeeed0b7f7c9a10d3f25e38",
                            "FNa9c012cc724fb5e3ff997e1ca3a4101f",
                            "FNe93a7dc0d3af1e323078aef42d430a68",
                            "FN3dcacc8bfafb3b83c4f4cd9c8de67a84",
                            "FNe5286a443796d1469f16c1cff0e55bdf",
                            "FN1b570bcc5ab02d88137a5496f773e1df",
                            "FN721fe9436d7dd08e8f447e5aac5dc9ee",
                            "FN23d3c361a15f425c407632bbe0972ca0",
                            "FN2866ab34876cd675806ade3dfecfc071",
                            "FNb05b355603a9349ff6e53f9794b9bdad",
                            "FN987047d3f5c5ec4544e2a66123a8d6b4",
                            "FN842caf59acb31e0785439abc4514260d",
                            "FNbfab6428c36fb40deb163ad1b25a1718",
                            "FN3545e827d986a34cb76d3aa07d166998",
                            "FN01c665e9b4565e534ad72b5f8602cabf",
                            "FN74c70a0b62151f85061131a3be893b0c",
                            "FNbd5bc06bfbd52f2fb6895a003bdc07f7",
                            "FN9e77076f62a526a227988d8828ede053",
                            "FN29ce9fbc60f89df42bf57b7fb99bf307",
                            "FN6c7d233eafe819c5ba9b03d8af8e6e8b",
                            "FN42e74402b6c869338f6ad559af778203",
                            "FNc34f000284adca71b05acbb75e577eae",
                            "FN59057c1747742ca391700acf02f1c109",
                            "FN341fb924b4d3d1cc39903d0717cf200b",
                            "FN1a3f1fadca3ab3e1023a91ae023570de",
                            "FN1644d419fc764a42f2bc37d88ea8fd57",
                            "FN6888363d52f36072a525e5969b279ed8",
                            "FN642954ba3d9414ff36d97f66fff523fc",
                            "FNde850ed6625bf8a3fef11da6543a0132",
                            "FN58e9b89ae3a389d555680ecd525f5303",
                            "FNa589c5b0f942cc8bc859dc02e50c33b2"
                        ],
                        "msg_queued": 39,
                        "user_skipped": 0,
                        "user_update_attempts": 40,
                        "user_update_success": 40,
                        "msg_sent": 0,
                        "numOfUsers": 40,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 39,
                        "msg_req_sent": 39,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN4ac7de7ee69e26763b6b98219ba8be94",
                            "FNb9026a37143ecc10748aa41732649225",
                            "FN88153c3f6d581dbb34bc2971ccdcea08",
                            "FN5ef4ba19ba9629095505b713317d4874",
                            "FN64b0d5a849a158f5b8bb74c652b94e38",
                            "FN1f37cbfa264374b7c6374af434c0c2b1",
                            "FN2d0d974362869e45d266de8ea4ab3475",
                            "FN0eb3d28aeab9241ae3ad35b27da073ef",
                            "FN9a66a1ca6e1613a75497a6a442008082",
                            "FNa958f0af6db48d1158d261c478c10594",
                            "FNf25fa877e6b41c2a55b36cf4b2355c9a",
                            "FN21e7c5554c4a1dcb13c508d669e341b3",
                            "FNc12c6de88c6615911c2863ed7f14e2b0",
                            "FNa2ebde3733eff5eaa6bcc291f4b621f3",
                            "FNfebd9f8e3452ecc5977ffed6974db45b",
                            "FN3b87003233979ff11c4f544a9a2631dc",
                            "FNeedbb08b6612f27134ad7d3967fd0025",
                            "FNcbb3c83f01047182961e6f9751c1dc7a",
                            "FN05c409884ae2f816fd7d4a17886013fe",
                            "FNe60b00c78a689949882a12251d264d23",
                            "FN332c803f2eaf1bf39df0552b0da7b227",
                            "FNf5227aaf631bab97c4d5b0daed1f9308",
                            "FNc3c9881a9e57ee000ce6c749c75cc259",
                            "FNb9eb779f5e43ede68a3061ed49d78e42",
                            "FNe4b17f71cc9b4c4e6ca415d58bb396c5",
                            "FN0f6e2263d2c30e44aec5fa4d10907aaf",
                            "FN9276d093281ca14ca9843ad0862893c3",
                            "FN51239f355e2af454880c8de8a2615eac",
                            "FN3fe3fd56e8ccc258d0d5fc2243e83456",
                            "FNf25657012897b3e4404709b41cbbd06a",
                            "FN9271ba70bc0c5afd2ac12b8be6778817",
                            "FN765e6fd203cd988e0254c18618365caa",
                            "FN94c9d80ef72124c626d0b9f18ffc71e3",
                            "FN2a076e485e6cb6e956928f4eed4b5910",
                            "FN7a577c1397181b80e1bddfdf203e5967",
                            "FN170f1907e67ed2aa37e31ede68dc20d9",
                            "FNc6df6317da3864eae2e7442701e25c9e",
                            "FN2506ce42775ff39719b4fa449686c9ed",
                            "FN07366995fa76f957d4042176adcff45b"
                        ],
                        "msg_queued": 38,
                        "user_skipped": 0,
                        "user_update_attempts": 39,
                        "user_update_success": 39,
                        "msg_sent": 0,
                        "numOfUsers": 39,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 38,
                        "msg_req_sent": 38,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb77f3a6a0074c03789cc550df33b4d28",
                            "FN5cd8cd3340c322eac91d4d5370310c4f",
                            "FN8601117056eb41fd1ca425bccbe0d447",
                            "FN5ebd045a852ccbbfc0e6c203af775e89",
                            "FNfb75204ce6cd00d9fdbab114de1bae29",
                            "FNe6d81b7438df38e54bc553ea8d504e17",
                            "FN6ce7e6aaec7b9a6d3a176353b74016ea",
                            "FN5d3eb3814d39841ed515f394b2a61621",
                            "FNc13e738f22c3bb3d9c7bba7ca5b6b646",
                            "FN8788fec2c27ca638af07fb0ad8283ee8",
                            "FNf996d519d2737537d0803ace359e6c7c",
                            "FNa4a4af162a5becd6d812236cdee8d032",
                            "FNe49d7eebf44f55a8b15bce73cc178762",
                            "FN2bdaec48b5c7167f8df963b2b5865cad",
                            "FNcbdda738b7bd4d0704ed01e137ac5362",
                            "FN562f8ffd0c7f97059ed276876a1cdf5b",
                            "FNf5880a60a33010c0a2e867e0bf60c179",
                            "FN1cf80a5f635f09478fc715f076dc58e5",
                            "FN58f1c4a884b93cfb0660ce2921331b2b",
                            "FN1b40fb5bcb2fb8901c47cf64046ea5f8",
                            "FNef08d821dfa2b3d468b076a9a7797eba",
                            "FN7a8154b7bf99d9aae2d16fe079c7da04",
                            "FN2d17f5376e88578e3f30a5142d173774",
                            "FNd4940d72ab29209c4f3428839431e1cb",
                            "FN98333b06e4dc304694fed9d6c469d0db",
                            "FN62017bc4428fbd109721a9e7e3b98d05",
                            "FNc88f84a49e4f3f77e9942663f171e7e6",
                            "FN8c13053cc71f937e66b4078a25dcc5cc",
                            "FN7d7d459ec2747b9a3710963abb764bd3",
                            "FNbb37451bbe3862fa6255113237f3d77c",
                            "FN82dfe6d2130abee1c05b77da9028079c",
                            "FN935eafb2084847dcb203b10c0db28cdb",
                            "FNdf8d374e94eba85b37f165147d763747",
                            "FN94ffe000713f0441ba9a016cefae4b05",
                            "FN6bcce5ec664855f23b6275e6f153a8ed",
                            "FN97dbdc099328a3c7817b8afe4e41cd9f",
                            "FN6cfe7ef121b82c01181f0051fcb7fe74",
                            "FN014c38805558b6a1b9751ada1ca33323"
                        ],
                        "msg_queued": 37,
                        "user_skipped": 0,
                        "user_update_attempts": 38,
                        "user_update_success": 38,
                        "msg_sent": 0,
                        "numOfUsers": 38,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 932,
            "DaysOfWk": " 2 4",
            "TimeCreated": "2021-12-06T20:31:28.916Z",
            "OrgName": "Link Homecare",
            "PresetMsg": "URGENT from Link Homecare:\nPlease complete ALL 2021 In-service programs in Nevvon by 12/31/21. Get started here: https://bit.ly/NEV-HHAX",
            "campTime": "2021-12-06T17:15:58.342Z",
            "CampaignName": "Class 4 2021 Incompletes - Language None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C35",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 4 2021",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 94
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 17,
                        "msg_req_sent": 17,
                        "msg_scheduled": 0,
                        "user_add_success": 17,
                        "user_add_attempts": 17,
                        "msg_delivered": 14,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN90d8a8fa11833f75c7a8b7ef4c462148",
                            "FNe696b5ec7b5ad225574494ffc05858ff",
                            "FN00c634ad5f4c6c519cd46713389759cf",
                            "FNfc83fd48ac5ec517142ff82f18517ffd",
                            "FN37a6d13435a3d67daf32adcba0e10b38",
                            "FN87b8160cd626f8e1570bc78acefaff8d",
                            "FN6c405bb3ca516d42979062b914f4373a",
                            "FNcafc2d101f22af5e6de932de15aff188",
                            "FN347dad284780f8dfa565c4886a0ef814",
                            "FN71f75588b27b488aa9393f0edf54646d",
                            "FNeafe11b023ea83164fc52f6bd4657443",
                            "FNb126bf3f8f2068481218d4187fb22a94",
                            "FN46a57cffa735017ccb740e7ff963e323",
                            "FN7109cf312ead4b3dff9897a6d73d865f",
                            "FN024b83326f1df5660521dda07fe99d82",
                            "FN3e6c7ba43b613d7930f88c01140afaf3",
                            "FNb9f6d093bd528cb4019ca3ba27022d6a"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 17,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 682,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:12:49.821Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions?https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T17:00:11.595Z",
            "CampaignName": "HDA Class 2 2021 None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C299",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 2- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 522,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T22:57:52.500Z",
            "PresetMsg": "RECORDATORIO! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T16:51:14.409Z",
            "CampaignName": "Sophie Q3 Patient ES 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C286",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q3 Patient Rights ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 507,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T18:46:57.676Z",
            "PresetMsg": "НАПОМИНАНИЕ! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:00:10.028Z",
            "CampaignName": "Elite NCM Class 1 2021 Russian None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C55",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NCM- Mandatories Class 1 2021 Russian",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 862,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:03:44.680Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:55.698Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q4 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 7,
            "CampaignID": "C254",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 2,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 266,
                        "msg_req_sent": 266,
                        "msg_scheduled": 0,
                        "user_add_success": 266,
                        "user_add_attempts": 268,
                        "msg_delivered": 89,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN12e2e2a0bc296363e88ecb620e3015bc",
                            "FN12be31c006cbaa7f60737e421b4fcace",
                            "FN16604bb70288793a01926b64ce623361",
                            "FNf17e71dfc90222ee0c119c2315de10dc",
                            "FN2d2f8919b08bce13be230ffe42671163",
                            "FN0bcf4f87f5fa9b14cdc3ba34ddba4b10",
                            "FNe0a5435619b572ca58af7acd1fd1a94a",
                            "FN6c2176f154c5570681010e8c6a5ef9d6",
                            "FN5e4c50b8f348c90a77df88d0c9aca1c3",
                            "FN1e98a23d57a1b19add58d75f4b6eef99",
                            "FN4596f2513778f244aa46e6483581d137",
                            "FN5de300f7f01293eaffaddeb31a1be912",
                            "FN2b579d9c97143153462cdc4d85ff2fe7",
                            "FN6b2c771aa38bd0754dc3d17d80db9e36",
                            "FNfc0b7280b19d6992e86aed0416a61128",
                            "FNfae47764d7a150604aa9fa00a3c5e938",
                            "FN1fc8e5ef690240ba600d6896f9f0c244",
                            "FN47426d851d198972e847cd8f98639b18",
                            "FN7b963f008f7ed38abec051869112b651",
                            "FN5a1e76b979b45b6750c04786a8666368",
                            "FN9ce706a951f2de4b68105ab8666ef6cb",
                            "FN61de89b7e2a774c47d20794e3b60fb6f",
                            "FNf85d0e8a08e66addcec898e3cb164cdc",
                            "FNdf60274af72b0724173103c41878dffe",
                            "FNaf16963c469b55f69c9ed6e78c98d659",
                            "FN8317d0fbdc049e8c75ef88e2e8d240f7",
                            "FN623333809e7fc94335100d23467e79c1",
                            "FNc8109222c82c4e597c27eb06eb86efa0",
                            "FN35276249485417420bdc055c8ff6a364",
                            "FNb8fb1befab219c1643b2df8644483862",
                            "FN876e0ef1d718f2eff6c9566e0575bec3",
                            "FN54392faf91ff075fb1d3cf1bfe435a21",
                            "FNb1d7ae7f3a175af2f4e80f9dd14c900e",
                            "FNc01500ad339eeaa0a5bdc58c2034387c",
                            "FN7345b06647f3a77e7cf4f756f71bf834",
                            "FNf41741949622329d7d8230fc1af52ebe",
                            "FNfbe9700912c49b97e9d6864dbb71fde3",
                            "FNda37f2601ce905bc7d103d5fc12cb626",
                            "FN867e21ed1cdc31ff4c48e0b690738a43",
                            "FN501778c6b752e68bac2ae0e7e6ce5c5d",
                            "FNdef099a69918ee9ab1493da03fc8d7f1",
                            "FNb0f6d4f7deb10b8c21eb7e6b319fc5c0",
                            "FN5d87ef0675895d2e66c47433cc47b1d0",
                            "FN8585a71d225a6dcc60c972cbdc720a3e",
                            "FN65800f52ed1dbd8ff75b17081919dc92",
                            "FN4aa5326060d26262c8b9313ea04eb795",
                            "FNb1217b3e3455e4c4a4a380e14012db98",
                            "FN91a9efc7500c7302981c4ad51d087ec0",
                            "FN9744d0dba709addf67aaf68aa7fb0a1d",
                            "FN9314cb196f68a43f2ba93fb7de73b03d",
                            "FNa6ee3d91f2eaa9defe41c25044f693b7",
                            "FN21c645fed4cdb32352e9d682418e120d",
                            "FNb46c0b8e2f7e9f7aa6dad7f33c8d4661",
                            "FN71a0e38ecf0bca66f80f97ec2630534c",
                            "FNc9e17484468a440560474d948650b4ca",
                            "FN38a6770171922c8d9658a3bb68d512b9",
                            "FN2ba232fb945909e4ad4ab8753e9fb16e",
                            "FNc338e642d5ab06161a40440c0aaf1804",
                            "FN3e28d05d8f989b3f6352c74e8ee35d6f",
                            "FN9a882ade997b63a96a77e2659e150dc3",
                            "FN00b213f5d6d3c49f2832be947790b448",
                            "FNd757e49a683430e0ca4076c12d3edb1e",
                            "FNc0634983b5bd5f5de677a26aa7f15cf8",
                            "FN07c50dfe8458e66656d5c7610798663c",
                            "FN4b85852fef626b9cbcf5dfcca2fba3ef",
                            "FN6fa13e333524156007f1d5b6682f4b0c",
                            "FN39c8a8753a6577c676671e97223cacf0",
                            "FNc4ff87d4d36eb68bfe7a46276e5083e8",
                            "FNd74a19d854ca59a2512d087714267fb7",
                            "FN6261e88f85b3b2a19067159c9c2d4f72",
                            "FNb77617b3681b1fa4d766784b42163eff",
                            "FNf75ce9e35304bb75974144999f052b98",
                            "FN9431043aeacbc81d661821314e78cd5f",
                            "FNa51c76c5638545b5b07c38ea7b6c887d",
                            "FN02ef8b3aabdb00799ba3926b50d21421",
                            "FN917fdb161e029f3bc835b29b48ac0c0d",
                            "FNbb6138462f56846b34b53951c0dfc0f6",
                            "FN193abbd57b8ff0aa096221178a6c35f1",
                            "FNaf904acc227f1d162f8837509d2ee6d8",
                            "FNad8936acfee940b73ef88867e03a264f",
                            "FNaaff8e0c6a1ab8a4e30a36cc14bc123b",
                            "FNd2fbc3c2d7d596711a74c4a58ebbda8b",
                            "FN3622cfaf751ca59955943b4cc7e689c1",
                            "FNeb7d5b748c0d2936829664ea37110e36",
                            "FN73658b1488a35e18a14adf58096b7af8",
                            "FN45ecf50309c455e3286fbe9934ebab85",
                            "FN63df05a7e2b1f64a67bb6f52071b52e4",
                            "FNd0af5b3aca2526321c3f1f1f3a079e14",
                            "FN14f6440b220edfe2b6c1508a5f3c856b",
                            "FN648e2850fb4041520969a44072d37085",
                            "FNa75d68295edb6fa5d2ce5e24017d88d2",
                            "FN43a3de0034d95d92935f43970cbc80ab",
                            "FN4798e1919391d4dcc9f2f6f7b8f84ab1",
                            "FN6dcb0b243087f799a80c35ea165e573c",
                            "FN60f39f25f42caf41b5171b9d25bb2106",
                            "FNf00e7c8d0b2582ab8c077d01887434f7",
                            "FNa6d23b4944c678b4e327096152bc4840",
                            "FN46e512c22b442d1e1ec106f311c47da0",
                            "FN449b009f59f9cb7870c5c289f327e78e",
                            "FN7495f0119f9f4b12ff0835245b891046",
                            "FN2f808d3327888fc272b41e709e752a1e",
                            "FN5313f16f3e01a44d6e9b49656511c8da",
                            "FN9cd4bad1e5eafdb3be5bff3311b0dd33",
                            "FN1cb4d49f217f2dbd6b25c52dac85da12",
                            "FNc9597363b2d75c632b3788ac9f93af04",
                            "FNc84e2ad10c1bd2b602b1c305b14a8372",
                            "FNf65eda01a41a61b45e26cd0ccb076e78",
                            "FN076fccb07117189869a33380190f72e3",
                            "FN4875f52f9e7935d6cf9006f6b4577e76",
                            "FN83d637abdc221c6824d378efe3691757",
                            "FN936dc67ac278d1b69d722a6ab533f29b",
                            "FNb782cce613c1b26d6acc687e1b336526",
                            "FNa2dc13ded189e119482718c95d8f72fc",
                            "FNb6648b7e149160a0f881dde01c4cd142",
                            "FNab8d7f326196dae83fc022928b3dc3c5",
                            "FN09f26293cb4a5f4bf02d44ac2f9693a9",
                            "FN7700c8984310cfaa1b43367ff85504a3",
                            "FNe0441e9ec31a3b6aedb6fc90ddff9886",
                            "FN9faab9e59dc1a5d89ebdce8a5898fb60",
                            "FNefa666e974d1499c23d56bf0b090bc81",
                            "FNb6fa676c67411760c47fa5f2d202c25a",
                            "FN97ff3118d997f5e594336a73cd92c441",
                            "FN162fbe47f78ad669bf99238427b9f4a6",
                            "FN12cefe58b241b11ab803ad954281b315",
                            "FN1f4ad55409b9696b7cbed18d6bd3f48c",
                            "FN926ca8b70e9a670e724f9485e45ca12f",
                            "FN232fe099acfc89ead304c3d927a3e1c1",
                            "FN7a98be532fcfc5c4b28b61e1f6e9a373",
                            "FN74ad6c970d17b2fd760a3c9fd572a525",
                            "FNb475306f3e97193e05beb6c2c9e7768a",
                            "FN2f88ac78ec4a9672fe5713bf8ed3e0ae",
                            "FN1a910d52ab26f434a42c1ebe07831dc9",
                            "FNf2c72f2124823adbbd00d70238113ed8",
                            "FN034742200aa3eb62c2e64506d4b54d8f",
                            "FN68554cf3d24c9480072d5c7fda72f7b4",
                            "FN5cff403e03c0e1d0d382f7110213b6cb",
                            "FNe247bc83ac0e50169cf5fa6ba473a68f",
                            "FNecb4586a96f623a613089043df735728",
                            "FN8ce1fcf066a7cb7e558e566ef439498f",
                            "FN6245babc8dc5df304896eb7520d23330",
                            "FN9ffdf86b6cbd0e600652a8441e2f4f24",
                            "FNa0039aa00cd85eeaac3fb27d1a2b52d5",
                            "FNd4b866cf10c0c34bbb2f422d4907e0af",
                            "FN30f8d8b8bfede3f0dcd7f49caaa85ab3",
                            "FN652daddd040e914c98c4e1c8840cae9f",
                            "FNc55f63c732cac870faeaf8694a8e13cb",
                            "FNaa03179f63a84dbe08675393021d72d4",
                            "FN053dad6c16daa38fffe0b19e28838119",
                            "FN2f12fec59feab6db04235ee4cf8a42c9",
                            "FN0cbc709dfef33750b05d489c19b54796",
                            "FNcd9d2b1b78d2235989ff2e448dc6a90f",
                            "FNd630975a576a813809607d4197a02de4",
                            "FN527970b47d81b0458ca24e8f45bb6582",
                            "FN676c39a99b3698d7f36e869ce7454fd1",
                            "FN35883454040d34af1469c16ba94273da",
                            "FN35517fddb8074214f11313dc8de8a3fe",
                            "FN62b22e8e1990318dc164a6fd0e0ef189",
                            "FNe2d29ef8c85b4f8d374ebb41d774bc60",
                            "FN68ce39f8a806d845cf0aa1880c4dd570",
                            "FNafe63c6f516496044df6f40936a438e8",
                            "FNb795384117a12a2475f596124a9c06af",
                            "FN81db05ddab32dc8e49a7c30536a99e99",
                            "FNbf38957004d491ebaf4576e73193ce7b",
                            "FNd6cdf3949483b91675ec50a5c976ab84",
                            "FN4dc3e761dec904128f9423f224cab940",
                            "FNef51f8bc907489ba1a940c33918f8438",
                            "FN28b562fca3c21e6a17bfd3e9629f554e",
                            "FN87b44ce92168750c34261b82c5b36c4a",
                            "FNe7b045076162561e9243586d4b5c0b1b",
                            "FN012473418fdf0e4960141be5f149708d",
                            "FN97890cc7c67284cc85a29e201063c762",
                            "FN02b2ff91345e1d16e1e6665e50415363",
                            "FN62d59762005613bc207831174c137090",
                            "FN47e360aab73fea2bcd0ec2326fbb9f34",
                            "FN4e8db43396732b926486dd2a35cb80b6",
                            "FN2081304983781ac8457326687da0e5f0",
                            "FN7e8a3d928d801b1e33ecaf8587971cb3",
                            "FN3735831fd8b9dad283b4c9959d4414bd",
                            "FN4d76da1633edf9f75c8bb570f35b64c2",
                            "FN387006ca390ca600580d47bc1275ab2a",
                            "FN760482a1030b481627af91e3b598e565",
                            "FN617e6ed7e8a01191793d821aeb9d8ecc",
                            "FNbb089cdeb19d0e230ac1e96ad3d38983",
                            "FN6d1ebc8f9a4557448a81a3cdb5c8d145",
                            "FN1d580a8b4b63842e503ec25200df009a",
                            "FN2ea7088c2522f52ebc31ff8b4be9dc12",
                            "FN0453e147c3ade9ed2fe4beac561d5b80",
                            "FN0e12a9bff4bcc221337af0aeb9ad9cda",
                            "FNd5fdc77a8a766c94487aed104361df52",
                            "FNb1255b333e8f268707d9091ebee1edf5",
                            "FN2dc60fb1da3adac87de68c749d9365d6",
                            "FNa1dd29d1196c63e00a9b3f6ea5822444",
                            "FNcc2ffd563e34e2446917ad2c38b83b8b",
                            "FNee5585995a24b3958acb4e4cc7e924b1",
                            "FN11d4a1b2c22c1ce4b4fe16c8cb11de2f",
                            "FNa73225cb6c9b1a2161a66097dfdf0850",
                            "FN0e8948fc0018e216a594178ae36d6a21",
                            "FN5fe80a0bce4cf8852476076b4b9bcdf4",
                            "FNe6e6441835d3613dc43bc2b5ca844586",
                            "FN6bc669ab94361f63f2bffdc62022162a",
                            "FNa258f70ccd5131b37e4b40af776b64e6",
                            "FNdf9e8184e7cef2a9d3428a34b1cfbc5c",
                            "FNf6354c89e96a53cb25dfb490918103ab",
                            "FN888c2c133ce42c205b504ad0d39ba071",
                            "FNd05591c8a641e6ab7605b491924396df",
                            "FN27816d60337c1b36651804191027f7ff",
                            "FN3ed5214a3d3bd3629e499a4a160575e7",
                            "FNc347b785d81412150b43616cb1c28210",
                            "FN0203909ea6f8b5d8f8961bb3c15a1fcd",
                            "FNca4e9f4caec4ca4d144c199af133ec54",
                            "FN527b414235375cab716598a843b1eb65",
                            "FNadef2f0ab348bf13a4ce11ebc5917070",
                            "FN5b9f2288d148f964ca7cf552fa540b07",
                            "FNea51b73cb94172300eb71a04a37f3950",
                            "FN495d68506d7b78041cfb43fa33e42f66",
                            "FN1c73fbfba1e3b4a2a5aa6b1ea9a354e4",
                            "FN75cc11c1435764e2be5e3ce82ee6e4c6",
                            "FN8d27c9bc1f33f2c26197f02ded596b20",
                            "FN67bae865b3d27ee199243e1178f846ad",
                            "FN868c2fda612f4c35ed86d43c863a5232",
                            "FN145834891aa7a6ccd10378cab7a3795f",
                            "FN671cff90bcad5037d19b0dce849effbc",
                            "FNed7094a9a0c146a6ea9107bb6385a516",
                            "FN07ad29b0e8b7ff828321a1c6830b2a85",
                            "FN3ead1e06ce7986ea9ad5108ef78630a9",
                            "FNa9d57b192245cf2fecbab4c43635b343",
                            "FN4f4d58ada71f12a127a7c7d76115836f",
                            "FN2aecdcc7b1edfb806be537648c937999",
                            "FNfe61d25bd5d61a1fc2ff4c7443be9eb2",
                            "FNd9a62991fd5280c458824e62314a2e58",
                            "FN253a3af27de63e4128e0ac6a858cd2b6",
                            "FN5405ff511186a39ae77cd499ad7b5243",
                            "FNd120587c6a2edf81e73a6f6d68b12faf",
                            "FN7d7229258837e0a212cca8b5e09c359c",
                            "FN4217023b3c2ea7db37a455883e40a6a6",
                            "FNd48d8504dd8153a2df693b2959409b79",
                            "FNcbd7229a1688b278074a236ed0aa3c32",
                            "FNdbc33086dca6ac0576a0448e2f6db6bf",
                            "FN9392a3278df2f7dff42fecd3ff25bcb1",
                            "FN2a06da39740b8588437fd4f690c273af",
                            "FN5dc484c442a6bd8fe5ece91e8878970b",
                            "FN53d2a4f6c2055dcaf6b4c203d3010ea3",
                            "FNa268611babc49b32d4bee2479ac73355",
                            "FN304a257af953ff3a370e7494e32dfc49",
                            "FN6c3bc36d4ad2873151c82045b8802946",
                            "FNb5b7e5c671127c9260fb0f127733f14e",
                            "FN301c2327bf6d5f69ebc4f690c6bd0f2f",
                            "FNc2ec824a6c0f71fce98be3d5f78e0ddc",
                            "FNc8bda9956326d3df537560fda519edbf",
                            "FN3ddd783eb78f35ff6d1a7304eb9c924e",
                            "FNf46c8c3ce7e93a87fa69b7ae6de20969",
                            "FN6f57f7d8999cdd84bcdeb5b818235ba1",
                            "FN79d6474f3c9eadafc789bbe865be29eb",
                            "FNb806055416fc5d56ef7145e3615a2f3b",
                            "FN67ad6b1a62e4143372d173c6fb4deb22",
                            "FN01a99e8f3ec5cb9abdf4446964fa93c2",
                            "FN832401d3c02575bde779a20036f424e4",
                            "FNc17d7371263dc86237d5d98f8a22a4d4",
                            "FN79ac5b54e02320b69253224b33733a8f",
                            "FN4ceef20a63f66b5879f935dd88325c02",
                            "FNb2fd785e350a696846dc32f792a01145",
                            "FN430d9a574923a8ba2ebf952acd97d9b4",
                            "FN588ce6e64b9bc3189f46207aac888b6c",
                            "FN9bbfc808b4d6212bc44d899773ee5118",
                            "FN780cc9ff3259a7768c2b210ec50a7776",
                            "FN372f0eae6e7b754fa1f97380277cb449"
                        ],
                        "msg_queued": 161,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 6,
                        "numOfUsers": 268,
                        "msg_undelivered": 10,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 705,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T00:21:17.822Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "REMINDER! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:20:46.396Z",
            "CampaignName": "Ultimate In-service 2021 English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C345",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-service",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "ProgramID": 463,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:35:48.010Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:16.943Z",
            "CampaignName": "JFS Care- Mandatory 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C540",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Mandatory 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 5,
                        "user_add_attempts": 5,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN35a522f30ee4bf50435c802d18408351",
                            "FNb7b94c1997ffa699ce14e10c4e4ff2a2",
                            "FNebe6d7c1cc364796e90ea8437f840d16",
                            "FNb9146436a47897542f48e07fb7ac5418",
                            "FNfc2430864832926e65ccfbc135818d93"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 5,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 482,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:28:34.745Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": " Recco - Class 3 Spanish 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C412",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 3 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 629,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:54:26.485Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示：Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:40.410Z",
            "CampaignName": "Caregiver Provider- New Hires 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C527",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- New Hires 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 263,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:40:31.052Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 1 - Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C457",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:44:34.869Z",
            "PresetMsg": "REMINDER! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:02.078Z",
            "CampaignName": "BestChoice EVV-HHAX 2021 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C106",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:33:03.264Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C220",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 712,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:02:33.316Z",
            "PresetMsg": "НАПОМИНАНИЕ! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:57.971Z",
            "CampaignName": "Compassionate Hearts Orientation 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C175",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 841,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:20:15.432Z",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "OrgName": "M&N Home Care",
            "campTime": "2021-12-08T17:00:34.676Z",
            "CampaignName": "M&N Sexual Harassment Training 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C66",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sexual Harassment Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 240,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 5,
                        "user_add_attempts": 5,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN6fa59864662ab642a49476436bb6e1cf",
                            "FN1102f3745fef479b469ff25da09162c2",
                            "FN554befae19af668079b799fd32fbd7cc",
                            "FN731f9a9682282cf50d0c98b9e89c6f74",
                            "FNe6e572f1718666f4268cce8b45d85e08"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 5,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 411,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:19:04.343Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 4 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C404",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 472,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T04:57:23.317Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:15.363Z",
            "CampaignName": "SI Homecare- 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 5,
            "CampaignID": "C591",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 49,
                        "msg_req_sent": 49,
                        "msg_scheduled": 0,
                        "user_add_success": 49,
                        "user_add_attempts": 49,
                        "msg_delivered": 42,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN53fc969b4d0ef4d009a12672fbed4c12",
                            "FNef21e0020149b95ebb859e1de70a5db1",
                            "FNd64fb3946411c2b37a43a8304137bb92",
                            "FN144d085fa3cb6da2ee54a3589f5f6bf8",
                            "FN11c81c0054d28d074321e521782046b1",
                            "FN3f5d29738a5aa86982521f85b165b200",
                            "FNcba558e61d1368fd7a017510933aeb5d",
                            "FN337767af37decb66f5249de97a43c507",
                            "FN8d4196c831038ba22d297cb30fa9cdf2",
                            "FN614697387d5d3e2f9112e65257711d94",
                            "FNb1355f17adcf9d434defff4a868e5926",
                            "FN0aa7a32166a9c147cbcfdbe71fbe52ce",
                            "FNa16af2b226f625aea7d96f51a9f18076",
                            "FN7be6304c0e0cc05a86e1aff115ce1ba5",
                            "FNfee211de2c28f6a9c8a8f5670df35219",
                            "FN5c3fb20cbec4d8a6b8e2f9f9d9df2e13",
                            "FNcd8ab9513e4809626aeeaaab6b710c5d",
                            "FN7888b57ddcd6f9e868d322e2333b62a9",
                            "FNea5d5d3312c56be3021c04f2213cfa78",
                            "FN3be9aa20c69664e998374070b19b4d1d",
                            "FN288fb5276d477847dbdbebf4fafc93c5",
                            "FN37f290a49d3b4bd7d7cc9b441ddf5a25",
                            "FNf08bae4465f682f397462b361dc50031",
                            "FN7dd001d4b54e7b27feed967bbbaa69f5",
                            "FN9444ed925668aa77cde26c010ecfc4c0",
                            "FNafecd481fd3f06e7077cbcc4d30c873f",
                            "FNa7a948003a492705653fd19bd7f1cfb6",
                            "FN17249411af74c3cfd69cc7000f86ede3",
                            "FNbae8ed8fc20cfcb02604dd1a0b1a1e75",
                            "FNad1ea91d0ded390bddcca7cb1ff79f87",
                            "FNda408895c0d962326665eaccd61e2758",
                            "FN847a61d9d54f2bcb1b460e332f6aa53d",
                            "FNa37ec805f4039819e86890f977059583",
                            "FN1643ba77b9d0a5f9a68e2183851b7c7c",
                            "FNc473c3425b852df6c2b4759caea7f043",
                            "FN050525ecad96e9d0a9f419d2747aa922",
                            "FN287c917eccac89669955b8fdb16c1365",
                            "FN0c3e7230ed7187eee2227435736dfc96",
                            "FN390c696b4e3411b2d6d180593210de99",
                            "FNf4d1dad6a95e922558d7a070a6c43631",
                            "FN858e8c9a655a92ab2757847cd9dea304",
                            "FN66a791cc4f53a73942cb91f30f1fc002",
                            "FN3a328ffc013f2b408d9686c5cd3938cd",
                            "FN805d03848c58b537e39812efc6c9acae",
                            "FNb34095ddcd6a47519d938f748d3a1d56",
                            "FN104a08bc8cb79cd7217707113d379f85",
                            "FN8270698e5b91388825446e10420cc022",
                            "FN9122f5adb11c728e0a2727128e75ae9b",
                            "FN6cda6141e05a87eae2a6cce96403dc8c"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 49,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 681,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:08:09.317Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions?https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T14:00:21.163Z",
            "CampaignName": "HDA Class 1 2021 English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C297",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 1- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T16:35:41.585Z",
            "OrgName": "Friends and Family Home Care Services",
            "PresetMsg": "НАПОМИНАНИЕ! Friends and Family: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T21:00:11.906Z",
            "CampaignName": "Friends and Family EVV-HHAX 2021 Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C39",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T16:52:33.710Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 229
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 226,
                        "msg_req_sent": 226,
                        "msg_scheduled": 0,
                        "user_add_success": 226,
                        "user_add_attempts": 226,
                        "msg_delivered": 44,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN6f7cb845ea3c542112c427c95697bab3",
                            "FNee561f990a703d3031826629fa13bcab",
                            "FN3eb64e05816f26769eb28c23e52be597",
                            "FN09dad364d2c5e2028d64a2df464bbbf2",
                            "FNd5ea7d7b1a313ea982db5b5e40f52923",
                            "FN8ade8ee68dabcea9dfd749db476d116f",
                            "FN413a742c331d928bec1e6dde4e612fe3",
                            "FN1134bdba0e1672e4ca8b8ccc9b7080ba",
                            "FNad52bdf45f536e93f17f3c01e82078e2",
                            "FN3d211b17314ce9c749c3ddb4b3a0d49b",
                            "FN144a992f8e3023e55f93c4efb9d4bd01",
                            "FNfdbe603268e8a9b0f511f70e9a0624fa",
                            "FNef1eaef7cfd5c5b8238afdb24ec9cba2",
                            "FNd9336dbde26b48beb321a5b976fda15a",
                            "FN4357be6918c28e5ad4dbf6826bf3ca78",
                            "FN074028178ea67c46def3f3fc03573332",
                            "FNd4c0ce104b0f7cce94c16583d87226e5",
                            "FNbe8e6ee9eeb742ca00f29b51531d1cac",
                            "FN73e5df4000a8b248fc5f6ceb9cd6f9f0",
                            "FN84829cb6e3136c3a549e6b0887a2b28c",
                            "FN419f1e8ae9a69a971233864bf708d2f2",
                            "FNb22487d4a686e96f1bc655c3eb69f90f",
                            "FN6e9d4cd4d6585836021ab043a783cc87",
                            "FN1b661c6d8ff51d804082a68b4866709f",
                            "FNb391df270596302554fdb102898a9243",
                            "FNd94382bd77a371b6fc45754caa3d219a",
                            "FNa5a82c225cc87558a12b2f771b55eb86",
                            "FN4aa11361606e3c0699463d342e5a3bb4",
                            "FN0e5bdd0668f802286cab6cf27afb5df5",
                            "FNb279c1c57cd980fa3aa8a263cad3feb9",
                            "FN25b3e4c26ca675a445b26712d258ad4f",
                            "FN6e991ed4480ccb83b3b8da60650d856f",
                            "FNe7f845907c22c59bfd85d8034b11ffa0",
                            "FNbcd60106387b9f163cf5bfe02c65aada",
                            "FN37d610120e5b5dde817e122b05f20356",
                            "FN7e4b9152776e1c1c5af3bf1d8b25537a",
                            "FNda7b93b630a988a3031f5f22950a7d6e",
                            "FN53f33c01860d89c2be91b3c417654687",
                            "FN2086499dd862a406fcfe0699d1963625",
                            "FNa3dbd39847757e62495f5262f9c1fe03",
                            "FN867e6a4448e54cba1e7b6a20331f2139",
                            "FN28abaf3c293c5dd566e5dfeb8d50ca56",
                            "FN93695b2afd953bbfb37a680ecd7d2e17",
                            "FN2851f5b3b324ec8288c12a35019c2467",
                            "FNd3353be9db4907b04147bfc25486be63",
                            "FN6e31f6f697387fc5e154351c53b16dcb",
                            "FNaa4b9aa697793d16fb632ae05d61fb59",
                            "FN8082ad9251e60837cde2a7f45421aa9c",
                            "FN063fc12083eb921ba179f12e10b31d35",
                            "FN45c5b4e0760db6faab47ed74ed513e83",
                            "FNc5b4e7450e1c04ca5d6fa2b8d486b2b1",
                            "FN8def1409b129e9bd0eafdbb4ad741b30",
                            "FN3eb5574f5e3e4779179285f28d7b36d1",
                            "FN623060523c91fd6123ad3cee4365925d",
                            "FN38065194db256300725d61482bc7c1f9",
                            "FN1ca74ca1c947cc6349ebac742d387426",
                            "FN3694700caa24378e069e8e5932ae604a",
                            "FN27fe1cabf79f558ab5a43802e5ce9d58",
                            "FNe2e7eba3c01a887fae318d457665ee06",
                            "FN3a16ae5de3c0d1da7a71441151840658",
                            "FN1b10cc8adcbdc3704144e401eaf1f4cf",
                            "FN1530519e30cd3cf7d98f29b324c289f6",
                            "FN983e1e39517addcddb9a33512509b906",
                            "FNa16e4de27a3be5cc7ca9ee88728c94c6",
                            "FN746dc28072f2921552b55f4ac9b21b4c",
                            "FN86c90c59626bd8a5fb9a8e2a8d614080",
                            "FNb24d3f974a0ef4d6042bac2540a97e73",
                            "FNdd7b0c6fd53da46fde588d4b562662fe",
                            "FNa30d6e40cbf3f56ff112ff54950090fc",
                            "FN079edc1b75c087cef8479d023f0f0b29",
                            "FN523da9d0df14abf16d263c4b60169727",
                            "FNf8e06db4efb9716116da7e61f668d9c6",
                            "FN2e44acaf21f13eea9a17582b334b1427",
                            "FN5c3d63fea5bbb1c52b743a0c17b4c749",
                            "FN8bf0eecf5431e9bc13ca48c77d297f1a",
                            "FNc58bebcd52a1199e63b28b5c5abfd9b0",
                            "FNc7556ce2288cb22d0ba955c2152ad6fb",
                            "FNb9b2b7b6afc676aac4ac4a215ddb66b6",
                            "FN186be4454f09ab8258b271796cd94623",
                            "FN220931f29760a1f35a42f12f628b4e39",
                            "FNa108a0e3dcca3ae37e0a15c5378a51ab",
                            "FN8063afd81194afbea7f3cbdee49435eb",
                            "FN74a56359c37a758ede87cb7a7c276bc2",
                            "FN42e19e7dd78e362c95a733ef87c7d60b",
                            "FN318a550ad8bf2c4abbe9fa193c929c15",
                            "FN699c1e1b66b9fc12145a801dc47bb12a",
                            "FN3343a228b9ce26584505a15945e30019",
                            "FNeea1a6de00b419b85fbdb23239a7ab3d",
                            "FN95c22719225e9b6908895e55ab65ed10",
                            "FN5963c79c93e2ff8780261c9dd05c7f1e",
                            "FN510472a5f30b4ab38f87d9a3aab8b043",
                            "FN6cd837973cdf5c2f3769472ab87d1acc",
                            "FNaa825dfaae03c003c8f37344f35df1c9",
                            "FNc768eacafab008f5679a4c8410601d48",
                            "FNe13762a407769f9f7dddc4273c49c610",
                            "FN3e0617e73bcdd6558fe41626f3790b68",
                            "FN9d102011aa4b69b11b81800942d920c0",
                            "FN0e0a4a2926b85fba6a859fbdcae28b66",
                            "FN6ee4209565e814e7e0712337f4b8604e",
                            "FN553f47b3d3cbc6cd71ca15aeef03df01",
                            "FNeb33b8c99f19aceeb408583bbc305015",
                            "FN21208e1a07cfda43308caf91354cf664",
                            "FNba137645521c7bcbbdd06adbfcfd85e9",
                            "FNe15ff3718d3dcda442427576b30dc84a",
                            "FNe464be4756741a08430955dc9798f4ff",
                            "FNb1f17fef00a042023b68f9d1ed277e86",
                            "FN26bd3cf110a763e18f6b9a0da26ca2b0",
                            "FN872cadfa87323ca444be79303d50614a",
                            "FN9928cf6716f8b8976a3c1240ce2b4d12",
                            "FN1c85a57215397d0072ecf57061479bcc",
                            "FNd736533624326304e82b949c673bf392",
                            "FN80e8bb9bd6b4eb30cebe339b8c31c594",
                            "FN0beab679caa9c95e0602e64b5e42af0e",
                            "FN88b862adaab2dd944fba5792bc32a7ca",
                            "FNd77f3a7e8e19e5d867b97afa62390578",
                            "FNc4f3cc2daffccdf3b15b820c7ca892e0",
                            "FN805a5afa0ffa64bba356498be1d6a44c",
                            "FN1404a581a0ef06478f70ccd259852bcc",
                            "FN545d53291eaf847d8b4c67e416c39c5c",
                            "FNca6dc273a0f913c5df3c2a6f0b0228b1",
                            "FN1e79f5ffc369b4a83f531cf665bf26a4",
                            "FN8f0257aa989ee1bece90b7d50671fa95",
                            "FN32f2164485df4fa60a5915eb41ac273d",
                            "FN0cbd1e546a3fdab6de3aeb82cdbfba9e",
                            "FN91344950008b714266da62f66ade20b0",
                            "FNab44844b143a3c9fbe294daece85ff54",
                            "FN49a789cdc3949e3389eecdce3118620e",
                            "FN15ce35869d0dd5819d8386d00f7c0068",
                            "FN853838262f446541b71a77274d361634",
                            "FN3c6dd79a51d8458d465db0ec636b84af",
                            "FNc5945434545e0b41eb5c819a3fa1cab8",
                            "FNa532a1a4cb883cd5b9b94e906910bdec",
                            "FN7bf1f152681a9314c4f48d0ec4bca159",
                            "FN23ac2170753bc52fc18598fad689d2bc",
                            "FN4898a230e0d73cffc89bfc0323e0674f",
                            "FN0662c84e4a8bbc598bbaf63f632f5266",
                            "FN065eb03c9d65277e31ef8410bf77d4da",
                            "FNcbf77dca6a108f2c5aca74447cc87cda",
                            "FN1664cfe6add5e14259a45577162837a5",
                            "FN7a5cab51cbed05cc785e46f30fdfe16d",
                            "FNf45c0e7212850ddeffebc75791166202",
                            "FN9b28cd021a3e7770757ebaa7b39f12db",
                            "FNe472cd7bb1dc241c78d52fa036e26fca",
                            "FNfb774601c3d91128e500bd190d90f6e2",
                            "FN11c8e8fcc4d75dc1978e80b664318a80",
                            "FN1de30801b7abca27f81f36f38e4b000e",
                            "FN0ee99885bfce8197b80a9eb0960c379b",
                            "FNadb4712ce57255a0980bf6fe0465912a",
                            "FN8f30cac156fd8e3d9e60402f5aad8e20",
                            "FN61fc8135fa90ecd694714940f2c64d6c",
                            "FNf532bb28b4c1c977b395b640d198bdd2",
                            "FNbe22cc5b04f99e4f1ad6f3277509071a",
                            "FNaeb10ad5a0b0e58e9219ccfa340a0176",
                            "FNb053b4071b443a787c1183fb251fd64e",
                            "FNbd5865b24830125eab4c54f711231cfa",
                            "FN0957609cab702bfc690cae98a10f672f",
                            "FN2056c83f8e7026b976436d4215325b94",
                            "FN9cc2d5f9060b1cf41367f2e65bb03f53",
                            "FN44a6db2497187086b91030d66fde8ec1",
                            "FN31ec84e25a9220aa639074175befa2af",
                            "FNd80c97776338e759c6b4dcddf203da79",
                            "FN2a9d74782e3ac145986b866db3d41d13",
                            "FN8d1ad8eb40c119371fc81b8adb6640ba",
                            "FN912367ef41eebbc265df0c52d7a8f8d4",
                            "FN88f1abcb87583140f68a7c60deefc780",
                            "FN112fe8e24f5db21309042224539cc4b3",
                            "FN3318ae378fc5788709c329dde99cbdfa",
                            "FNf9180247dede74296a9db06d1a00e7f7",
                            "FN006496c2301afcdad32e8cc80535f4e3",
                            "FN1004ab411726229a4ec8dc094670636c",
                            "FN722f7deb2db92a3aca760ca40ca30ae8",
                            "FNb895cafd8b158e14077c48b325f61c85",
                            "FN958984f5a26e3e252a72aa1b3f24d949",
                            "FN3085745ef2d84e2e03a022e60ae8a42c",
                            "FNb369ede7379e26602a6e91563ab8e338",
                            "FN7bbcafaf5fd0acf3c3259df7e9ccb623",
                            "FN43b862e7bc794609446cb076a88c22d3",
                            "FNf26c93e7a2286e277a9de163c9d8c47c",
                            "FN7d6455bd68d7bb8e5294a44145f45b7b",
                            "FN8e35b53a70a8420dead97f35bdfcc88c",
                            "FNb87797e2c2c49434f8f412ed00c5be17",
                            "FN1e57f2006110035aba18dd7d43802209",
                            "FN2e6865ad13a0be7b5d52e64b3cd90192",
                            "FN6990865876cdf88fbe67e392ce856c6f",
                            "FN8a4f44b8bd8f886e7f80b9940eafed7f",
                            "FN32c22c149958d83722c3b5a194563f24",
                            "FN399205000799766a613bf562ac8de9af",
                            "FNd5548d48413381e1b5053b95197ed6d9",
                            "FN56d06df5a40464850de4ef2aad452085",
                            "FN74108ef635a10335b5ea171a89159369",
                            "FN595c29bd2a78f1ae00e1097c2402ec26",
                            "FNe9af379a0c26c3bba1b5fb6daf482729",
                            "FN8434c0e54610f5e2426992e81dd6cbd5",
                            "FN2721b79d0a99a1c6511d4a0fe1615480",
                            "FN36cd2c5502cdebe21a8cfdde8d0f3387",
                            "FNe6fcd0871ee27dd010d7fcd126fda971",
                            "FN47d923dbed5cc9039b9edc10f258f48b",
                            "FN2e62a878eef3845c624c27ef646f35e6",
                            "FNbce1c431ae1a1f1ab217168f4f9b9a0a",
                            "FN21cb5f10fae1c6a33b26793866a283c4",
                            "FN22bea175b6c763bec89b47a6077e601c",
                            "FN2a42391e065d541550bd3e698abac7be",
                            "FN1d19bb7eb2f5db301b1ec04e4b3e4e95",
                            "FNa8ebae9b14f63a8c29374451a9a6ee95",
                            "FN45cd63ffe82af719b9d3aede80ca4783",
                            "FNedd880bf63417b9d82dfa64f5ee6d1ad",
                            "FN5e3ba62d04048966761dd0c22925163f",
                            "FNe9f0b834eba41b8c2126394d96560fd5",
                            "FNad67750795025938bade82432a8f5925",
                            "FN8ac1bd490ca1b7428917f0e948d74561",
                            "FNc2fb9030cd5ec054b098ec09a094f447",
                            "FNbefb70c96f6e0478ecd3b33e9e81ec44",
                            "FN4f8cc21a6093d1f968b76e303d973810",
                            "FNefcebff9c8b56d46f3469af9c892c98c",
                            "FN5d5502ccd86223513cad8ebf576ccc25",
                            "FN6efe5d2cc484bf00263a4802a82486b2",
                            "FN1f52c1663ba359c880060ac531c3759f",
                            "FN084912303d757c94a45002fff7ccf9c9",
                            "FN54b55df1b79108734080a9dc786d0f97",
                            "FNdbf15ed4779eada918dde0cce5ce5f35",
                            "FN37d936e9f486ce074464797b1821671d",
                            "FNad7c8a1bb7a3250b3db15d81884e00f1",
                            "FN7f634d2316cf0a6f8c84334bee7922e5",
                            "FNbddb1fa3d6b19bd97f8cb0791e164560",
                            "FNef746c5f7565329fbce528e6c5697114",
                            "FN6fda75412ec88ddb9d15520af5f9d5fb"
                        ],
                        "msg_queued": 169,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 9,
                        "numOfUsers": 226,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 1,
                        "msg_accepted": 0,
                        "msg_req_attempted": 223,
                        "msg_req_sent": 223,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 41,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN096eb66711b3add5805cfbdc24636f9a",
                            "FN5d3ff12b8f2f23a5ca611313a1bd7728",
                            "FNf1d3862dad035daae5c07736de786107",
                            "FN8878b79fd22da2f835c6e6b8b1820ce0",
                            "FN4a6ed12f77339b7337bc169aa8cbfc40",
                            "FN64afaba3bbc2916bd5f2f26180a715df",
                            "FNacce9b63207dc09be766b54ef309e3b7",
                            "FNc92f49adbf2a53dc9ecb69c8acec6a41",
                            "FNca08c1169d45f4ac82b50f7a66276373",
                            "FN4044070c73c7de500a3956558404d99f",
                            "FN33edf52946be4bc96ab910ac2d08f1b4",
                            "FNd94cf0436c5235980024748e213e66cc",
                            "FN19dd2d18f19fc2570c68aca8416e04f3",
                            "FNfeef4823efa05141f47cf03dbf8488dd",
                            "FN70e94007f78426c9e30ed3648e0e369f",
                            "FN438062e819dfe380db7759efe130173c",
                            "FNa18c09d80f4eaae21d1b486c4a50ea64",
                            "FN4ad9c9c4c41a96edcdb4b63c67c2843f",
                            "FNde54663f044865c8804e6afcd0fba717",
                            "FNe7832374f7133851cc51f945c47e0d5a",
                            "FNc78fb486311217a45302862ad411f2d4",
                            "FN42a96eaee22f1802e04639863d9ac9aa",
                            "FNd2536529d1cdf4ef985f9628a317a28a",
                            "FN14935c1a4cc5e936ceeffff9f492e4fa",
                            "FNc395ea874054f9b48306768e60b302a0",
                            "FNa80ab13769a59e255add959a9d0814ee",
                            "FN97bfb26c9a7f529c6da0071ba3b6b730",
                            "FN4bc4965e87fa9270b9e0347fb9f0b7e9",
                            "FN0586970e72e24c059ee793449928a95e",
                            "FN0ebddfd84718d74e46a3dff825cd9bd3",
                            "FNa420600844a9e92dfe67fb7159b5ce08",
                            "FN78e3571a748576d13080f53aa070647f",
                            "FNf876641dd8448c118bafdcf5dc561d8a",
                            "FN5515e90725cac201f368a6af9cdd2710",
                            "FN300d253f9ee400d3e937fb4853f5e59b",
                            "FN1f619f710b675d9fc3e8f18a0dfbb212",
                            "FN1c2dc15ee2f58ef14a758ee672b3d23b",
                            "FNf2d437a7199f2333189b6687baebf46a",
                            "FNa2b3ad5804ab359020e6d7161cf52158",
                            "FN1d590bbd2cb724fa175e7f8e18f5fe47",
                            "FN702f467aa154c66ee0847368eb72e6ce",
                            "FNcd81b97d45a8b5ae5a98ff59c99c75c2",
                            "FN01cae902f2aa77e76c8f07e4d28fa6ed",
                            "FN2f5d076b9b03b5be04e1a858fad8af55",
                            "FNb6f19526a2efd1424936a8075b139aaf",
                            "FNb9e1a66659cbdd2528b43bbd65e9c0db",
                            "FNa07f6544d58662e296533bac6e41efd5",
                            "FNef8bcae9118823e0a105b58600302af3",
                            "FNe6801b437bdd9286ed830466ec04536b",
                            "FNbe57c622f135f86f014f7c7e04b6a035",
                            "FN3d34e438a3ca42969ce2d5d73c49a215",
                            "FN0f88a4c9c794089e703326d2e3929692",
                            "FNa60ed09c3d0ee40254a3aa1c23850a4a",
                            "FN5d64bbcce7af66c967ec0f392d07c308",
                            "FN9e726fd57b6acdbbde9066e80c0f7001",
                            "FN24a55c088ace70fe62dcf85df52abacf",
                            "FN913fc920d3cfc3898313b937bb137704",
                            "FN121570966d3057843507f668f4edc360",
                            "FN3fa08767d7adf3dc1a12fc3756b1fe5f",
                            "FNf78c1d7c3da5c8434f76aa2fadb99872",
                            "FN2c111e0cdda1dc3e760b68ad5278f394",
                            "FN07929c525f63d766d94e063f8d8aeae8",
                            "FN4a0eb1dab9aa04962555c62059688289",
                            "FN369ecbf98f1e2cee91ad7c43c9b10754",
                            "FN6d51d2998092e2c2bcbe548676455ced",
                            "FNe30bc33da32a54ca8fb31e3d61db0a0d",
                            "FN700f1692b23edd4ee05881319b5d4db7",
                            "FNdcee8468e62844a6f49f22f2ad98a03a",
                            "FN07b110365403f5c5ca7f94a4817d0321",
                            "FN34e6a1fd0327b31596a6d42e3ca77835",
                            "FN9668ca6d7402ed18ba921f6effafbfe3",
                            "FN6dd2d357d04bf759c5fe4d3a39228cff",
                            "FN13cfba246f2cca2657dcae3301eb205f",
                            "FN6b46f94aa327bda62def7b8af83f2037",
                            "FN4809cf7af6b1787b6e8d203aa466c95e",
                            "FN1c3018d51ad0f8a5ef246c33e281fa09",
                            "FNf26a7cc5c95ba429b1d1a0824dfb2c05",
                            "FN9c4ebc2e2ee518b694789c23c3d221b4",
                            "FN615904547a3d488de075135554fe6b01",
                            "FN4675404e8a6a6c4a9b19289f6e084c80",
                            "FN62172f69385c89bbad24aa3b397990c7",
                            "FN13b35c018c65b3240ed8f4ceb24e9525",
                            "FN9eb283ff0302a27779c41ecc5042b214",
                            "FN15f9229be9586c9295d7ecb7320d1c33",
                            "FN436f57b561b3d776bed40036582693e3",
                            "FNfe0c3cb2048ebc144a63ad1e210b7364",
                            "FNb8a22cc2e96874b288f0ea5db68c197e",
                            "FNc86067bd5b76986f32c39e1d83787990",
                            "FN183a04648b4d073bb57644d65a8b9c64",
                            "FN6d532b40aed997dc2ebc5d73f9e52275",
                            "FN6243013e6b6e9155778585b44d7edf2e",
                            "FN849521e5387f9e8b5c62aaba658dbd07",
                            "FN69980d57b298c499929ec434dfb919fe",
                            "FN3bf5e56d1f5e8e4095d091e07dfc6e1f",
                            "FN8e3e0d4651b5c924251d4dfcdee21a4a",
                            "FN5bcedfcfe7a5faa4af1321b88c6c2a99",
                            "FNe35c5ee99b817ee0958cdce03c364db7",
                            "FNd173d77e96c70950b49c4812dec9c301",
                            "FNb09275c9c310efe720bf6dcd153622b2",
                            "FN725af6dce4f744bf7c388827ddd68ee5",
                            "FN356ee5b7c2a0da1df2136a28a3841610",
                            "FNcacebafb696dbaf4e5585283a8afb528",
                            "FN0bee8b7d7b08af7a9810d87bb21ff3ce",
                            "FN4e9a3c53cadf5577f4bdd09aabc099f7",
                            "FNe7767abc82ce4fafbbe66a9ac9dae512",
                            "FN930f15b548d62e959025d13fd15502d3",
                            "FN2d336bdc50b8aa18e0f77f60041bc8d7",
                            "FNaa79b8e97bb47204c4d484986c110d5b",
                            "FN3d643b25ebc683395555ce8b018f7b0a",
                            "FN9d0930eb52e33c7c917f325408c04e80",
                            "FN9a3ecd36972854e6fa9353f90025e141",
                            "FN0979e430b82fa3a81aa625133cba4f03",
                            "FNa2c6c69ee6233b4d4506cf9a73c0d499",
                            "FNa3af8e64deeadb31ede1f5f3010a0afb",
                            "FN4c5bf71e0c234abeaab7bfa3cf28b421",
                            "FN46cfce511e1276a24de29ef5ed0a0354",
                            "FNc22b8f326ebc039dcc81cf8a62139d7f",
                            "FNfbdace2605f84fa4cca812ec3cf3ab86",
                            "FN246ab7ae970e6f07694eeb862b34d11e",
                            "FNa8e94f703518a625ace2d630e8e6dbf8",
                            "FNf5868b8ec4902b6c7e195134f8a99d64",
                            "FN87780ed3ff877dad05a8c1c9b8b5cb62",
                            "FN9fd1324e3b6d63cef42223e658417d22",
                            "FN0641722c899422396e3d13609ae714af",
                            "FN4b5df9bfc13b2e056db06f4056c6eb4d",
                            "FNe43311c60cec8107b639209f4d419655",
                            "FN810550e3c62e7e22e2ab0703368ea4e0",
                            "FN4b7ee940b321348c6229ca7d67f21c3c",
                            "FNd5c1ef7b2a0b13f45f089c9da97cb801",
                            "FN407287f55d10636f620a5d3995350136",
                            "FN133fcae20db54d3e0c61d7dae9a2a252",
                            "FNdeec0fc03f6762b260506088662c345d",
                            "FN2aaf0ec28553b0f1e320ac0cea3ea131",
                            "FN438829165b74e7acf6b28d1bb7455350",
                            "FN696ba04a4e145b8bd47b81d61dad8e43",
                            "FN1b54a958aa0a3edd8b6b67f912447229",
                            "FN32e133b453205e2c4eb4df0806cc061c",
                            "FNdc76af60872e7d1eede18bb165d3dc2d",
                            "FNeef45746d4438e16aa327531d8930854",
                            "FNa031fbb7483a81410d87040bccf8b42b",
                            "FN8df0368eaace0f9ed4bc84e51f7ddd2a",
                            "FNec189ccddf708a61b70fda1b5c28987f",
                            "FN92f7d7b9f73a96e0388bbcc7ba34b3bf",
                            "FN2d463766523f14b5b96000c6f0edd60d",
                            "FNb2674119204ca42dfe3af77123aba140",
                            "FNf3a83b76b0b637ab9d0e89975d44f96b",
                            "FN0b3d4526254c7bd2af723b3e96ff30a2",
                            "FNe9e18fcd09de23b70239b6c64346cbee",
                            "FN3859a06ac1da605d9826a1fc3d9df11f",
                            "FN8cb9e761ed0155ed6e9deafa6c007d7e",
                            "FN7a400f62d95366a4709265e5cc78a010",
                            "FN05630bac0051d070d7ed4892c8636743",
                            "FN919415821cc4686328439716cb6e9309",
                            "FNe7c1e286e02a81a709d845d591f12b45",
                            "FN35533dc3b21b4ca6c34901ef88d2df9d",
                            "FNbc85047c64f51fc186dc045f6d6e5fcf",
                            "FNe64d38d444afe565fa56e02d37a0ea32",
                            "FNe208517263e637d7efc0595aaea34861",
                            "FNeafa423980c01f8b4b3c8a250aac7172",
                            "FN465bef57f84de179a766d6baa1262de9",
                            "FN46a756f91710e696df15fa2483dccd86",
                            "FN225c38d0bc0e58090031abd8e8265347",
                            "FNe33df86386dad32053ce21b5e87d1594",
                            "FNdaa14c69136cf356e0fb5d7d69170acd",
                            "FNd0069f6f927365882a7e8541f71644c7",
                            "FN2c6c2ccd79bef756c80564c2695a3f0f",
                            "FNed0ef188665c1c116cbae0f74d745471",
                            "FNd46e5f88d17bb1dedf2f0a943b6be4ce",
                            "FN31aeca19a52f700017d7200cac98ef57",
                            "FN1daaa5229e572ed7d59aebc62f76a793",
                            "FN206baa756ba4b1fbd50c1d3f0f05faf0",
                            "FNfb4140183d3a0b80b2770d80dfe3f72d",
                            "FNd6009f9b0a810acba590dff766d8a633",
                            "FN1d966bce9bf762447b4601b078a6447d",
                            "FN6b19d43f85ad1a36934264ab469ce38a",
                            "FN05d965683ffef54f6b0d47776ba23d36",
                            "FNb7b68d9f2b33c9687b50cef57f425705",
                            "FN0eb7249050f172d42ae43854ed50dbe4",
                            "FN1cbf112a2cf1394261230d0c7adb0d1b",
                            "FN14f508df84c1eea59537ce5f6cfbd348",
                            "FN37936b63ac3b62ae1f93e3ea1c4da311",
                            "FN60013f72dfa4a9879509aa47895684d1",
                            "FN4a27645aab473baca701785bbe6fc9d5",
                            "FNc648eb28eee514108bf0fd7f522065cb",
                            "FN1a1837f60221ff7f3ebe76eff33b54b2",
                            "FNd261b863c19a8f5cc5a4dd4ec8d70a77",
                            "FN1c560455aff64aae5335eedaa30d3aed",
                            "FNcb67a4fa8aca2bedaaa0361aa147e6e0",
                            "FN656434c80f8442b5cbb63a851665f92d",
                            "FN09df377b1018e17068e76961493d7ac6",
                            "FN11d0e9bcb0f8220635290810478d46fa",
                            "FNc71d0e6b9d9c2ca51179f342d4434286",
                            "FNfa6ec5a26ccedb637e2a6ae81ad4a735",
                            "FN818b6805f699074f228599cf857149fd",
                            "FNceabc94e71b83db281cb88cd77233b16",
                            "FN9048fdbc731b279d13ceb0eb13495047",
                            "FN5cb7479f7773a397cb789377ab30cdc9",
                            "FN6a7c785b1936646097b69582d893249c",
                            "FN25f4f6aa4c158f71d85a3a77adffbdeb",
                            "FNb24d89f740378f3bd9f7272d14147af0",
                            "FNd192a82f2362996804f7b22fc46e4fc6",
                            "FN013843fe97f070276081a48cc67a18fa",
                            "FN433a3b7c14ad71d02f84d94e90beee59",
                            "FN9af95fdb18cdef40fb3db23ae6864762",
                            "FN7f0484afd95de2b469f50b8d6fe2bcf3",
                            "FN21a75116a37a3a206493b49aa10f8871",
                            "FNf86bcb4855086ffa8c8c80d47aa141b5",
                            "FNeceb3e847136593aff9a95436a375d54",
                            "FN0026c5b958057f434418d9f3fd8b61fd",
                            "FNf85e9303b7a9a3071247bf71182f05e0",
                            "FN9cae87418df07aa002eeb550d6246727",
                            "FN49a817645f608d14692b0de484b990e5",
                            "FN9c8401eb748e9c69852d34a3b241ba0a",
                            "FN953bb789976637fafbf17411f49e3ef8",
                            "FNd0b43528ae6648641ac8a1f527c8ff26",
                            "FNd85cc28bc4d4bda3234aeed29e556712",
                            "FN26d003abf27159969bc6a2cf86dcb15e",
                            "FN569bb1105c7a86b637881df579767ca9",
                            "FN2963440156ebb1853fdaf6e8b0461986",
                            "FNf4a635a5c264b024119e553bbede15d9",
                            "FN951a46ad0c196686c4bbd6fc6cd6a434",
                            "FNd913e9c573ba742460953baf8873eea5",
                            "FN530ea66ef2d0b123a44c2ed95505d089"
                        ],
                        "msg_queued": 172,
                        "user_skipped": 0,
                        "user_update_attempts": 223,
                        "user_update_success": 223,
                        "msg_sent": 5,
                        "numOfUsers": 223,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 219,
                        "msg_req_sent": 219,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 41,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNcec80ae9be081a7aa7c5ecfcc9268c4a",
                            "FN7e4db9e641d0c381b66298f8f94b85a7",
                            "FN337f095b3e65783c6d29970d11368b73",
                            "FN14058749d10f2ea43af18a09f051a523",
                            "FN4be05dda7a9c39bc471ba649b6c4bbc4",
                            "FN9d11daf31bcfefbcbf5413ff1d646a72",
                            "FN9d867fb2f91f0b170f5e415113f6133f",
                            "FN5b48f44b13922e49584ea62e29561990",
                            "FN309db41f692cd8e0c96789edb4386750",
                            "FN3fa5a55f049bdc4819596bd57f9b0236",
                            "FN68069c488584884ceacd1830729dbe31",
                            "FNf3275309c6da8519f1113602cc778c33",
                            "FN5a9b0b206aef4e4f7137714b0a03bb07",
                            "FN864d626cd273dcfb6928df6bf580d24b",
                            "FN2eb85955b0a02040365668203be4f34f",
                            "FN732c01d4b65db6a8bfae463f1bef1062",
                            "FNee4be9a1acd18d89da7c67034977d3bd",
                            "FN26dfb7302f30a895522fc4f309ed27d6",
                            "FN79b1d64d1a5ea22d2d129bf7c06860c1",
                            "FN7c2d84407d87d477987159fca3f5fd0f",
                            "FN0b4244a0020dd9345ca9d352a3b9903a",
                            "FN77ef6d769733a97ff8b9ff2cb423ae6a",
                            "FNce50cf581bd9dcb38430427eb774d521",
                            "FN755cd998d819f582348391754f172f15",
                            "FN150f49cae815577fc9ca47b60710b14e",
                            "FN7d2beb43ff49f2f9eb623662d79be701",
                            "FNdbc3e1d8da5aff994a3f9062a065109c",
                            "FN34c705f9f32fc6b6e596804f9f883026",
                            "FN7a0bd7c395a708121046f8d45aa8c574",
                            "FN52eb49733d11fe29eec740231b1314aa",
                            "FN3f28cbf81d30de9228cb0cb55c7b85ce",
                            "FN7b7bbd25650fcc18cf0e94608038a40d",
                            "FNd64e56cc22e20f217b0b54c2e526b3c7",
                            "FN1dcc61456c7115af1654228a72490a63",
                            "FNbebbd2dd46b090413e059291d113b749",
                            "FN4623678576a7944e8b1e383312a0c463",
                            "FNfba22d146d112aff8e77da1a90258b17",
                            "FN8baef0f9c4ffab892534a3d156011bf3",
                            "FNaca36e9f4459c27a48c24bc01da0d5d3",
                            "FN88a15a6224ea825352035b69cffd90da",
                            "FN8076c71dfc53c704f23979c7701e7f5d",
                            "FNaa836ed22fe697b9a57560ce964d43a7",
                            "FN8240f9ccdc81a00ccc202fc917c83175",
                            "FNc500e7e2235ef51c5eb97564f62de8d5",
                            "FNae2f906e025c9f2e878dc4d8b3ff96a0",
                            "FN203b8cce00773f4fd75ebbb798699a57",
                            "FN13b3d52f538127db5c14aeb3e6b04765",
                            "FN95201fd3924dee8ee344a25871500636",
                            "FN00ac1500a8a7ef8693595199fa7512e8",
                            "FNcc4f97a2fcfc3fe2821868d8a346caec",
                            "FN553ebc6deebd60d2213eb0779c0d1e16",
                            "FN340e88928ec9addc987229ede8e37925",
                            "FNce9dbe4e1a573ba3ae5aa3a9a86a2260",
                            "FN6dac13e97b5af70c9325989d7d9f8b9c",
                            "FNdae694b5dff05ad6acd6ce0bd5f85e4b",
                            "FN3b852c8b102c629c39c0081cb675f0d4",
                            "FNd5cb6c1bc2879dd0d5701cd4e22a0aaf",
                            "FN661ca8fa7f1441953932d24edb4bf177",
                            "FN6513a739fa055ad088364761e3ba0a2c",
                            "FNb714107e721aecb13a9c8fac020318b4",
                            "FN0d8f05aea0685bd45adcfce146bed09b",
                            "FN2b4ad3a7da24a5c1b33557b5c4697644",
                            "FN2bd1d0f7c09b65a27595e3a544d6a517",
                            "FN28c045c619455fe3ca1610bf3c486fb2",
                            "FN68445b4fa6c9c0b6186fc8dcce71e03b",
                            "FN879e9f07d656b47ce7944b478a960996",
                            "FNfc989f7153fc12c6fdb7e6cdf1cad7e5",
                            "FNfae80eeb4e797f9ec4d1ad0c9f3a4044",
                            "FN679ecdd0ad247db046ea65eed6504a00",
                            "FNce83459f292d5f2bd341c6b09c1d8592",
                            "FN50d33d4c476b9f5c28934af65f21934c",
                            "FNc759f2f8696facd36e9d27425198bb4a",
                            "FNaf9fb75eba3659af50d3a4b6e48941ef",
                            "FNdbf81f0c40b391972ae19c046ae261dc",
                            "FN2762585f162ccb98ddcd81d45cb62bbf",
                            "FNb64c7102882d8f8657981735a0ce2884",
                            "FN64703c14ef2a27062ba7a54a688af3be",
                            "FNdd62774546e96935ea1853e1d9a8289c",
                            "FN5a128313860c30a42508957bda36b7b5",
                            "FNba850c67fea23196413af22ff10d2154",
                            "FNffeb1a82c183550fd3e1636b20160a77",
                            "FN6deb38f646faa63fcd1310a1aadd2834",
                            "FNd1fcd05af47033358c94da935539b23b",
                            "FN3686dfcf3e2f217c87383dc03bdaa9df",
                            "FN8555fa94e81780d47b3076cf3a8e8a80",
                            "FN200c0a7508ed6d95586b12a885cfd6eb",
                            "FN6249bc9b44399f8acabb4e0063c87ce9",
                            "FN1732c29a5efe1f5b5e2da8b0b4316d46",
                            "FN376956119c487b226e4fb0a5809d035e",
                            "FNf5152e92af7d27a4884891ed219e3096",
                            "FN721ecb4437227cee1382203b774747af",
                            "FN36c9b48c933a27a206a8054dce0ec952",
                            "FN9a32c8f69606bce187fa740542cc79b5",
                            "FNf9235968b849c287398189b53a1a7d87",
                            "FNf2b959d8caa587641f3f32bddc11563d",
                            "FN944f841afbe13896321a445ec95fdca6",
                            "FN4954a0a208d43a32bc74751e0de557c5",
                            "FNcdde79ead8fb0b6ac5a277abaf87f85f",
                            "FN16e6be0e8f8991930e8e60e538467d81",
                            "FN53d101f91d8c2f671de206e48cebb00b",
                            "FN10b5282717e5368379d7d92977a68ba2",
                            "FN58d3d5518b424457c07c4a0591277a42",
                            "FN42dff3de9a19dfe2b327ad1a01622c43",
                            "FN87ed54764c15d3082d3833b79e858dcd",
                            "FNb6c714c39d22f5943748d2582ad2dbfb",
                            "FN2865aa5759c355c04384ffa9a1b1d4d9",
                            "FNd04875983ac0707f5a98ef8ced33663a",
                            "FNf616534f8828c6fc86de6aa95dafa220",
                            "FN849a5b33f4066225f6db434802825dd0",
                            "FN4c2e96fde01ce113a24cdc6cd2fe0bd0",
                            "FN1466fc91de3aceba3030278910bd3d28",
                            "FNa36a8571a5a0a96a4874029c172adc39",
                            "FN48a5874c491011cfafdaf832bc13655f",
                            "FN49b187866d370908201bd0a0d7fa8968",
                            "FN730edd58fee323e2385aaf0bc1d47ee0",
                            "FNd946617056250d331e776ebaa8dc0eac",
                            "FN67937b582f71ac305a0d7fb5c0e8ad74",
                            "FNddd557812781ee2d077326277f6b7075",
                            "FN6451cd94771e8ab67d578028cff0a01d",
                            "FNe621f354a71e80e000f8bdaee7951d8d",
                            "FN925aa11e2cb3574d0e0d734ab84004fb",
                            "FN6a7776ee7713d3d28d29de43967ad453",
                            "FNd124c823d15e0bd0f993928bebf2b5c3",
                            "FN8d7d21ddc84513d14959e671d7ea1ae7",
                            "FNa0935b0e47f8b2cf672a7c70c725f6c6",
                            "FNbd8f35c11c03626dd8857f13b2727738",
                            "FN5a0e5450fe78f10c74d63d33c3797c13",
                            "FNf035b7f8184984006940dc5a21597964",
                            "FNa3aafce7053b82651b7b6d0a563a736f",
                            "FN81a04b26013c40a2dc6ff9e88a3c77f1",
                            "FN6bfebf3299986c73c4941a9f0529e3c1",
                            "FNac30b864d310e92653d9bb33986b8ed5",
                            "FN0629a4334c3a0268f3d9edfa55a0821c",
                            "FN4c3cb38585054a59b4805bd29a64491e",
                            "FN4b27e6188e5b918c47c59c323a45e762",
                            "FN822c7d2b6af201f8fa9261b0b589fd74",
                            "FN472cf93582559a9cc7798174626e940d",
                            "FNe824d5c3a68c3eab3d8ced8cb88caadb",
                            "FNbd17c2d8e74c1efdef2a9c51e26d4c47",
                            "FNaf459001c95231060b77e67edba3f9a9",
                            "FN2192413fb8a7e60b43e7be0ccebe7f89",
                            "FN2143fe2d5fb2fda2b13373a90acf335e",
                            "FNab7c75d6e87d54a57369e48e211cba83",
                            "FN66fd71fdeba9d9fa2cab80bc3284618f",
                            "FN01a12551d59f717897e21086779fa209",
                            "FNa29d57ab54a4b239eae31be7e8bc117b",
                            "FN3300cc6e7634d7b7c07cf5f302a3599f",
                            "FNc29726f2fcb88659fa723f1c8cab81ba",
                            "FNa061e6e97ba1ce88ad0416e949037914",
                            "FNd57071bbc731a2381f02ce815097eaf9",
                            "FN0abe755754a41ae72937bf9761a14cd8",
                            "FNe0fccb2d2a020933a7a938b09eee6cdd",
                            "FN1e1a72d78d6230bc08d06fa94d6bce4a",
                            "FN47ce1a4db08015f3ded9ff22f11cf77a",
                            "FN4d7d1124685e2bea555b26ed2b235a63",
                            "FN12f8590287875e9229a754fb2f1b97f2",
                            "FN48e61bd498a83497765711f10a97710b",
                            "FNda3d4d0dcbfa5f53a8f89a07102e2fcc",
                            "FNe811bfd2f5782ff6dd241843c404dc70",
                            "FN0f71a92ebfad2a61e4f0f70df093fb04",
                            "FN6087296856c9a654e1adaef05ab83252",
                            "FN5381a2efda347f3e45394c7d45a23f45",
                            "FNec340cf78ae2776811d77ba6a7014ccc",
                            "FNa611c1911379eb2a066d2bab8b67ed2c",
                            "FNa38afc4471c5e4d4e88d99922538dc3e",
                            "FN068e0cfebbd25002e916980d44d43f1c",
                            "FN928aa927829835c0fe5e8c5535e66643",
                            "FN83f846d533a318707b39d8a50151b324",
                            "FNa0ad1efd038206b199a5cbdd0362f9bc",
                            "FN6fdc2376510b5690cb07f139333058d9",
                            "FN8ed7d7cea2a588ae3a7b193037fbe725",
                            "FNdc98184edc3c9415ffd40a8206a02357",
                            "FN17371785ba6950cc072b8d8b5b8b8d56",
                            "FNf63cfeed2374a46d8624e4be55508b98",
                            "FN512cc960dad1a235fc8973e9b3a81762",
                            "FNedef75d14bb58ea04fc776486f7d20b2",
                            "FN3ed32cca9f274a47768734058344e1f6",
                            "FNd275255df60fda8a0f38c319e0f531c7",
                            "FN256043d7ca069dc1fe35991e4e3ad6b3",
                            "FNed0f25daf113719c9ae03b5e1b6dfff9",
                            "FN2515ce9b82700c4eb300419f57274a30",
                            "FNb886cf243032cda5f4c05f3372a8374a",
                            "FNaa2f915a65bb9004396573fd01ef7a03",
                            "FN51706d89113f2cdd6cded64e17a2b5b0",
                            "FN33a85ccc851ae97fbb45bd43ac37342f",
                            "FNd92e81198aa9fdf644d5c34c5a6234ed",
                            "FN26e7e34f6ef83ee9f48454f915d2e07b",
                            "FNfc53dcb33a17b3c7a4de211c19d8c818",
                            "FN45591e8cb21eeff4d07cb40524c34c6e",
                            "FNd777398725a4fc702dfd881450f2e0be",
                            "FNf4435869406028479ba07b1aa267aafc",
                            "FNdc11b4e78cab71fe02d9d1d6b8b9298f",
                            "FN4a21aed7b9fe4756eda099c913d1e812",
                            "FN43436c200445f89f21b50378a8285b7f",
                            "FNe5e68706c77265f7293c21b70e3dd310",
                            "FN6439bd0e1adb84ea9270c37bec781747",
                            "FN79b082cc80c9e43ad95494a485fa2aa4",
                            "FN030d4ce9ad20ecee13a508e9093de39a",
                            "FNc79ae6b2d64777557235cad54091773e",
                            "FN49f755b2a8c005f87e3631a02d8a0bc3",
                            "FN0deedbc9d7df7d586aa98a52d5fea6fc",
                            "FNf5346877c33fcd9797ca4a3ae21dae78",
                            "FN97ed4959b2293a48a2cc279961f19562",
                            "FN94d4944b5b909562e22c4bcf5fa2cdb5",
                            "FN3606c6520d53089f25c041ff0164f653",
                            "FN6c8761803eb8488df9e2266df131bd67",
                            "FN7dd1bffa5d967d7b7803965080e24e1a",
                            "FN52d9948d4cd42e0893f0ad81c0d934d6",
                            "FN8bf0c3a87c541ab6aa29fba1c538f0d9",
                            "FNb0cf827e8ede827626e892224aea1ee2",
                            "FN7c440f8ade2c868ec05f8d9e0bb4cb8e",
                            "FN243e877e29f949c84d633561623a2dac",
                            "FNb2c8f5c542229b83d5b616d894c57e89",
                            "FN7df1981795c1b027695984356e241bed",
                            "FN61c8cb66fbfeeda4f9ab6694c8c5fd85",
                            "FN40fdaa95d09e6db331bab39d5f6c8eac",
                            "FNb8e715224e078d3e5f4ca95b0468fc6f",
                            "FN018a7e841aa53fdfc66b8de2ed757a10",
                            "FNf9183fa7ebdc2e88ac81f755702d458a"
                        ],
                        "msg_queued": 165,
                        "user_skipped": 0,
                        "user_update_attempts": 219,
                        "user_update_success": 219,
                        "msg_sent": 9,
                        "numOfUsers": 219,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 1,
                        "msg_accepted": 0,
                        "msg_req_attempted": 218,
                        "msg_req_sent": 218,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 43,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNd5b4598ad1a80d5e6c74f98ddebcf331",
                            "FNd9568da18d1412c27beaca53fd0386b0",
                            "FNb9315cffb7fc5812b45f322040b7aa8e",
                            "FNf7f8ea2be1886b656ada6b979c82ec84",
                            "FN07cc72623daca4ea68697b3cd2016bf7",
                            "FNcf7e46b99c1154858062a5503301d14b",
                            "FN878109375e9a26d4c7b71258edb59dbe",
                            "FN763c09da7e1633f25186c5a4755e931a",
                            "FN57f430d07d83512f5793a1e29e3db4f8",
                            "FN8d5b341501e1921b249cd4e0a19bbf28",
                            "FN64775775e76eb6bed2952515fa2ab7e4",
                            "FN0ed86dc9d2b699172613f466b1a2818c",
                            "FN48e3babdf5692b7a5c79c2dbcf69447b",
                            "FN185c7bf1ceb5d28e3c1fc1de45be7add",
                            "FNb2ba34ec809cbeca619c6ca2244a9856",
                            "FN8d15fec4c5da0b9b511eef36f236f465",
                            "FN09785b03defd2e82650ab80e1fd979a4",
                            "FN9d156fd5da246482b5fde31c72343e9d",
                            "FN5a8a18cec983bf8fd29bd9674d4fd990",
                            "FNfbaec266f189a806fb82279e85905532",
                            "FN646d3d7e21fb5ed70b9506c5cd88d2d2",
                            "FNcec8fd815381f4d0e9bbadd04929d798",
                            "FNfbd68447621db7899c612954427ab3bc",
                            "FN57555e4a19b67ca735d03b889735a9a3",
                            "FNf84ac477a8d02708dc05143b7991dbb9",
                            "FN4c2ea582c2d95e81e0b4eef070cc6f86",
                            "FN555fdb0f26d629a5ffdf1641dce53b58",
                            "FN94417129a90b56547f14c2de20197dfb",
                            "FN4f5166f872972af756ec4ac711dc51b4",
                            "FN0f692b07b7a3aea5ef856f7c479f05c5",
                            "FN51ad77e4dfab795d32decefd32367a63",
                            "FN5c8d1d27080b674bfb9ac41a38462ea0",
                            "FN9e69fe161173bf0b7cc95a23e3432d74",
                            "FN088551db3b53d3a6634144bbb2c077f8",
                            "FN4642c1815bcc3064352238ba18e99de5",
                            "FN29e46853a62f35576b41e75c02890bdc",
                            "FN55b4ed7bbae5dd5bee57bb5081afdf4e",
                            "FN9a91b6526d89356fc9b5271ea81ae470",
                            "FN0f9c3f90726a31e0a28713afe9c1bf51",
                            "FNea14eda5ea0fec23b2e4f05c1e9f2a40",
                            "FN8087e8b6490b431486317bb34d378afd",
                            "FNe031166f95ed263cf5c84fce1ec5a53c",
                            "FNc847329c3baf8469d4da281e71715b95",
                            "FN1004aed104abc29ba386121cac2bb84f",
                            "FNc374eee98f3f6f4469b2d61582360f6b",
                            "FNf6d2a9552cd793c3e45c81542caeb8e4",
                            "FN314aa9f68f5547d7702f6ce8abe03723",
                            "FN606c20bb6d8f76e9e48204846f71cf7a",
                            "FN3f19dbb266a80e3dd3ff91bd1467bb22",
                            "FNee9e2ccb8fc7984b003fc6c19207c9f6",
                            "FNf85cd52d1ff50dca80f46bc0e3d8b747",
                            "FN5eff785f4357b2b982c68dda520d0b07",
                            "FN30dd3e318f5054b733bf8ced4bd742e0",
                            "FNced96e9367e6dec8c63faf1e3fd23ea4",
                            "FN35dab185e108cd602591d837701bdfbf",
                            "FN2ea63fa3bf86c231f42254a43edbcbb4",
                            "FN40e3e6ab93ed4ac9f762013292b4e479",
                            "FN5e4794c91681691723d9a0fbd80d1bf0",
                            "FNd9eb186097526a0c5c8b6ec85a2262f1",
                            "FN9bcae475e753180dd032c622dc772d91",
                            "FN1696a4b3b3f90f89a93d147589b29fe1",
                            "FNb0c73b0b694a3e42f9fdc2df42df2433",
                            "FN79dcf9fd3d1fd18c4bcdecb6cb4614e9",
                            "FN3ffe7c138ecbac3fe507857b71fd37b3",
                            "FNd66a4505e53c2996e5666ff122dce80d",
                            "FN802a7400a0f018450a4cc8685a8a4838",
                            "FN4afee05bfd88460174f79c693577ccbb",
                            "FNb6029b6aff9edf950dc04ad7d6a56bb9",
                            "FNc3966f905225d3cc786643e5fcdadd22",
                            "FN2552c627554e7b091ce7d1ecb8df1be6",
                            "FNa69d97be4f165c596d05a66b6477d2b3",
                            "FNa199ca215f58d7629113c380048c511c",
                            "FN0d71dea55e625f718b9611e450a57dfc",
                            "FN952cccf567b14dfc818118776ada5a10",
                            "FNcf97f9bd314411605ba2fd79f23a1349",
                            "FN7b24db93f2d6470293dea13bd37efabd",
                            "FNff85b9d1d82f9288e2049410ffd4113e",
                            "FN1dc9a9bc53b842ca72bfca66d9d7ca99",
                            "FN5fb7986f33d7ec2d4168911095e046f6",
                            "FNaaf7bedd2c606574abc3db1f475c2bd9",
                            "FN4010e980a5806b0eff29e2acbfb30319",
                            "FNa4c36eb6370f1a172dbc37d8373f97d1",
                            "FN0fa06a0beaf3f5b7b07d81f3c20c9957",
                            "FNc9c7960b0111dcd92374edc78e0253b6",
                            "FN4fa317df28d184e1e2beb9566a09aaba",
                            "FN70d55335a859c77cce7982e7d3159517",
                            "FNc47523dc0b6971cd58da7e27efed113d",
                            "FN00ca204583b5461860f5414048f582b9",
                            "FNb2715b82280d814cc984a48ef66d4a5e",
                            "FN612299dc712c86be0dfc228510c2f292",
                            "FNe4cce2239c637a2bccdf3606dfdb4a37",
                            "FNffe495a9fea1774fb2dacf32d33672cb",
                            "FNbb559e6f57f87b5d061e6bc3bed7687f",
                            "FNb5e14b4fde78917b8b1bebe7640f03af",
                            "FN7efa9007f1083eceba0123fcd8f6af88",
                            "FN8764b70d9e007d585d220001a686eb72",
                            "FN067950739964b6e1820dd19e2cccc239",
                            "FNb0924bdf13c33959cf3e6cf17ded96d9",
                            "FN5910b60d173c32c8173d672df9e591a1",
                            "FN100f3c6ee544f1a05a88b5647fcca404",
                            "FN1583c158ee696d56dce7af3edeb6168e",
                            "FNf71d2e5b19e9a7a6fa726adb2d751ef4",
                            "FNfafbe7a50500772caaa38afa4be1e577",
                            "FN776fafa8cc3024e1637588c2dc0aa252",
                            "FN52ffc6635e8234424f92344b1b7cea3e",
                            "FN72367c700ef363985f88b3a08fe50b71",
                            "FN31fca61ad7dfdf76017266d2e1542d63",
                            "FN709d15d68e62c88d15bf8ad58324b129",
                            "FN017849172e7a9ae7701c953d54a993a8",
                            "FN799240f3941ec9d8af9d35c52e7fcda0",
                            "FN98c5ddae7c0e75c2a46730c91b347241",
                            "FN8259690c3bd65e6d3fef90194a8dc111",
                            "FN836bd2db6260f8c270fd197b36718915",
                            "FNa541dc1b787cc958d24330366a76a434",
                            "FN83a827933189f0ab342c17abf3bc746d",
                            "FN654d1d3ac9f205b810c69073f6fefea7",
                            "FNc967919fdde45b0be72b9101ae257b4c",
                            "FN97b6aa95cb46d0739836f45c036f603e",
                            "FN5ebbc28cc56f2321568c386fc2435186",
                            "FNaf2830689ad4482104d96251f437b4c4",
                            "FN3e20c30d272bd08de885fc10884f8766",
                            "FN35a01ff268bc15bd6e7e99d2af515d2e",
                            "FNa1652361f6f8434280ddd5ae53768ae9",
                            "FNe62d527465563d65b024be26095f3bf1",
                            "FN22b521fde218124dd422907537ba8b92",
                            "FN8feab73bd7b76847522c69328083d55b",
                            "FN26f5a1d0c59a79a3554b7dee5ccd7b30",
                            "FN56b9b4180bb7ab64d0b6908cde58b7c4",
                            "FN882da02b68936b2ca71709f0cb2b15b9",
                            "FNfacd640d8e3d770847d7ae5afa0742c4",
                            "FN95cb57140424773308bb304dd86c1147",
                            "FNc3d04a78ec178f569b1fbfb78772590b",
                            "FN2ceb8431f5f1e27824680df9c226e445",
                            "FNd22953e9e8e8d9b6f605e7952a11ed19",
                            "FNbc27d646726298a85d8ae092d262a3af",
                            "FN89a74a19eb29942b759d1cc921c0be53",
                            "FN023fa29cbee20df01bbcfa5d3765b684",
                            "FN95f22327d0b5868f10bd30b249db2996",
                            "FN8f15c8ca75fb758cdc13dab09915c961",
                            "FNdaccce9dbc6ff0dcec6b43ec333d5d5e",
                            "FN85d20fe818c5401a9ee5fea8cbd472b6",
                            "FNa88afe3d011bc6157724eea9e156fe52",
                            "FNf339bef7cad2bd2e20580cc8ca830f41",
                            "FN00857f41dd6eb209af59ada4af5d8cb6",
                            "FN43f036c43a7b9d6069008285f716da86",
                            "FN45ae17f779bc38e9a5079a0cd665fe81",
                            "FN680339a662373e6c0a9eafcb02b32a0d",
                            "FNeb954ec11964b34770f18e4c07ad5f69",
                            "FN232334680e5d6f5a831d6279872da391",
                            "FN4310e2c0fe14843a6545d4dd4ee299a4",
                            "FN8ed64d35c514448fbf11620d408912f8",
                            "FN4129fff32c6a81c4ffafa167b9d8fef1",
                            "FN319cd9f864a0e82dc0631d2c9bb76a32",
                            "FN64125ebfcce7e0fc319068c2f503be3a",
                            "FNbd91cd756cd7473964897f9b9d5d1f6b",
                            "FN3ee1802d2b5d23b99ab63ca24ced7701",
                            "FNff5131226b5fc040a5c80ba060c5e6a7",
                            "FN9e494473370605bcf9041eb6637dbbae",
                            "FNec96c41c0f1d8b8fcfad7518129ac2c4",
                            "FNad086bfcc857c40555a2365b2774d15c",
                            "FNcdc960c9cc7959435936441727ba7e07",
                            "FN638241f01151b21a0b9b99a8b6f0976e",
                            "FN71f2d6ee5f323ae5447888c266a96bd0",
                            "FN2687fbf3552ea9ad0d699ede0af6be07",
                            "FN0dd12032a44284613bc188e57e3ff754",
                            "FN383e426a2e90fd5b64e40ee202ae8ddf",
                            "FN9a67c872e2d7e40b42ad5a5aec9afedd",
                            "FN22dc11698c6ab086f1f83b7039bac01e",
                            "FNa15e7e63253ad1fbc6cd8828aba45e3c",
                            "FN2f45a51f26c5b85646d82c9f593049dc",
                            "FN082a52bf16dcbcb193c79aeabd060dbf",
                            "FN0a9f53fb3e3147ea6ee27ca0617768d1",
                            "FN0d138b685a58c3b332809484ebc2b008",
                            "FNf5c333455dc41288f23971e11cb89dc5",
                            "FN7e7373f2a07114b0266effcd8390e26a",
                            "FN562758ecccec9866b35654c8b7253f34",
                            "FNdfdb72c61c08f260abd789c48a4bfa7e",
                            "FN48b84b900004fd0145e0a5844bf4d3a2",
                            "FN2ad418ea4d864856ff9969563585e78e",
                            "FNc8d19e6f2324551b6f74a15b8b12ba43",
                            "FN0d8143d4c3ea15d72bce21aa499c226f",
                            "FNec8735cb5fdc12ee702792064d457c52",
                            "FN5da4c3ffa14dd2590785feee689ef2a1",
                            "FN6e8cc1375fbaabeeb1773a3d2683a347",
                            "FN011509bf9720ca938a24cfd4108b5803",
                            "FN27c32de5359f89b28f6a64c6b7d090c8",
                            "FN49cc7f324d648c3f258001f0cba5dc3b",
                            "FN0653911995f9b5d431b77e0e3d0da8f1",
                            "FN3d5d26edc0d4e862635910d7f80ee39e",
                            "FNaaafacda1c82dbb9dd6d989d80b1f3bd",
                            "FN6afe6622fc9ba1f4277c355c8edf1dec",
                            "FNa59307a9ad175a30f5438badfc72aaa7",
                            "FN92a2e40a835d0c508ca6de5036f09b5d",
                            "FNb3d3f1033580b908c9b72b21be70352f",
                            "FN4e55b77dff23e15ae28ef4c69b336b65",
                            "FNc8218de4fc8a2f9908b0d7f571502ce7",
                            "FNf2e50e7fd4ec7afe70a52c3fe064c24c",
                            "FNdd56a4572dab8f309b6ad44c1adee813",
                            "FNe5233374d852e78eb1014f4d2a9f83b0",
                            "FN9bdc489bf90bb6425cacbd401b472b28",
                            "FN685366540813f12c773f0848ea6ed475",
                            "FNe486b4cf3b7b2ddd764e592951d1a93c",
                            "FN8f63194cf3c633c6f8c69437bd88b225",
                            "FNe9a630d3ed358f26a0ec27e6216bf15c",
                            "FN98ef7013ebfdde49702bdf963a5111c6",
                            "FNf64582056a9dc2ff3d5fbd95aa4a02f4",
                            "FNaa581f7dc32a8d6df5cbbadeaafe1984",
                            "FN6a29c13d139a680be7c09f1e6ebaa4cf",
                            "FN7a3e87170efd51b0e668ae3ae549996e",
                            "FNe94bae569aeecaaf32e7120779937f1e",
                            "FNfd2119022b1fa53037b51a50870ca915",
                            "FN9d3b57d43e5806810430e7f1ee2e319e",
                            "FN99a14d69dc18f2429346212e6530871c",
                            "FNea717d3cbe941fba0563d4e145916496",
                            "FN395a7a2c0b2b6f27bd371828f2d6027f",
                            "FNe43b841b8fb6eabceaa96cbab64fdb3b",
                            "FNdc27a6620795ac63aa68c3a437ff2780",
                            "FN6b167de9b335b5d50d1a36fcad3f123e"
                        ],
                        "msg_queued": 162,
                        "user_skipped": 0,
                        "user_update_attempts": 217,
                        "user_update_success": 217,
                        "msg_sent": 8,
                        "numOfUsers": 218,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 932,
            "DaysOfWk": " 2 4",
            "TimeCreated": "2021-11-29T21:16:54.499Z",
            "OrgName": "Link Homecare",
            "PresetMsg": "URGENTE de Link Homecare:\nPor favor complete TODOS los programas en servicio de 2021 en Nevvon antes del 31/12/21. Empiece aquí: https://bit.ly/NEV-HHAX",
            "campTime": "2021-11-29T17:15:54.604Z",
            "CampaignName": "Link Class 4 2021 incomplete - Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C34",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-11-29T21:17:35.236Z",
            "ProgramName": "Class 4 2021",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 94
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 21,
                        "msg_req_sent": 21,
                        "msg_scheduled": 0,
                        "user_add_success": 21,
                        "user_add_attempts": 21,
                        "msg_delivered": 18,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNa05d3584301a13a9b6619453844daddd",
                            "FN5380fee2e077f56d7f39aba941872e18",
                            "FNbb531cd688d2423dc52e96c35ed19f6a",
                            "FNe807e8920a76af7f5e9d94641c5afbea",
                            "FNadf323a24615046713906b8e0bf1069f",
                            "FN33ad6ba43080bc60122178c5da5e5851",
                            "FN40dc164a34e58e661d4ac323a7c48954",
                            "FN04905abe1a00b2253adf26241aaf1ea0",
                            "FN13618c56d88bbf337d00478436ae7995",
                            "FN4b9fc0d910588cfdd7703594053d8d18",
                            "FN002895bbff507cb966bd05cb2f569a38",
                            "FN5a2802ef66e1110a7382eb5219f4de5a",
                            "FN748ecdd9618467b9a253116fc81edb09",
                            "FN2329fba527faa087f17ec0aed8923816",
                            "FNe04b846b90dd0af9e82998f6ac32c65e",
                            "FNcfdfa5339d6b68725e3c53457cddda80",
                            "FN1bf83ba715d1046cdf60f58be048764e",
                            "FNd6d929fa83321923e10c26c42cf8770b",
                            "FNf52b2692346990b4f6fb5bf90888917d",
                            "FNc6d1420635a8f23561a73b76a866f31d",
                            "FN8a3bfd8e18935210188341fcfe1eccea"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 21,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 681,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:07:12.997Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T14:00:47.402Z",
            "CampaignName": "HDA Class1 2021 None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C296",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 1- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 740,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:38:08.205Z",
            "PresetMsg": "\"URGENTE De FADMO:\nComplete TODOS en servicio lo antes posible. Toda la capacitación vence el 31/12/2021. Empezar: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:39.938Z",
            "CampaignName": "FADMO Q4 In-service 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C157",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q4- In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 526,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:04:48.948Z",
            "PresetMsg": "НАПОМИНАНИЕ! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:04:13.130Z",
            "CampaignName": "Sophie Q4 Docs RUS 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C295",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q4 Documentation & reporting RUS 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 718,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:06:32.215Z",
            "PresetMsg": "来的温馨提示: Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:03.206Z",
            "CampaignName": "Greater Love In-service1 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C179",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN336b2343674974387fb090b7ded25493"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 651,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:48:38.290Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "来的温馨提示: A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:46:38.340Z",
            "CampaignName": "A-Plus In-service1 EN 2021 Chinese",
            "FrequencyInDays": 5,
            "LangId": 30,
            "CampaignID": "C377",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 EN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN5647816bd6518617d5ac7d719523821c"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 705,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T00:22:40.647Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "НАПОМИНАНИЕ! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:17:01.305Z",
            "CampaignName": "Ultimate In-service 2021 Russian",
            "FrequencyInDays": 3,
            "LangId": 134,
            "CampaignID": "C347",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-service",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "ProgramID": 317,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:19:46.642Z",
            "OrgName": "Assisting Hands Orlando",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:34.758Z",
            "CampaignName": "Thriving At Home Certification - English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C264",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T07:07:50.964Z",
            "ProgramName": "Thriving At Home Certification",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 248
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 13,
                        "msg_req_sent": 13,
                        "msg_scheduled": 0,
                        "user_add_success": 13,
                        "user_add_attempts": 13,
                        "msg_delivered": 4,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNc2015c771888a92f92c1ad4cab27fbc1",
                            "FNf5d5062c80b6183cdf1304c6c61f92b6",
                            "FN7b4eedf207255d4df149813968214500",
                            "FNb6b148762eda88233b2763026eb14af3",
                            "FN4a0e94f7699d629b8ff129834a9150ff",
                            "FN7b85e456257e698f4fd7e57158f9b08a",
                            "FN24a2ef3c822dab1f212002125af57cf7",
                            "FNcc4ea2dca6fe8a2344a4fe11144c7f97",
                            "FNcc60ed5da76860335857a92a8f8dc079",
                            "FNe84cd37bdd8db20e891c92661b19d7c2",
                            "FNfb6ed3205ddd147e1a2204fedb92d7b6",
                            "FN646eeabef9971a1e5c7e0aba78e0a638",
                            "FN2a768142b69d92bf022d91509e8dfcdf"
                        ],
                        "msg_queued": 5,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 13,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 914,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:42:18.177Z",
            "OrgName": "LifeWorx Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: LifeWorx Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "12 Hrs. 2021 - None",
            "FrequencyInDays": 7,
            "LangId": "null",
            "CampaignID": "C498",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "12 Hrs 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 108
        },
        {
            "MaxProgress": 99,
            "ProgramID": 718,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:07:09.954Z",
            "PresetMsg": "НАПОМИНАНИЕ! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:38.649Z",
            "CampaignName": "Greater Love In-service1 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C180",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 687,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:09:09.531Z",
            "PresetMsg": "来的温馨提示: Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:28.026Z",
            "CampaignName": "Angels 2021 In-service1 Chinese",
            "LangId": 30,
            "FrequencyInDays": 2,
            "CampaignID": "C97",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 739,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:31:49.055Z",
            "PresetMsg": "来的温馨提示: FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:11.482Z",
            "CampaignName": "FADMO Q3 In-service 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C147",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q3 In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 624,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:20:19.815Z",
            "PresetMsg": "REMINDER! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:46.191Z",
            "CampaignName": "FADMO Q2 In-service EN 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C137",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 710,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:50:16.716Z",
            "OrgName": "Compassionate Hearts Personal Care",
            "PresetMsg": "REMINDER! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:06.566Z",
            "CampaignName": "Compassionate Hearts PA In-service1 2021 None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C158",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T17:02:53.183Z",
            "ProgramName": "PA In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 220
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 35,
                        "msg_req_sent": 35,
                        "msg_scheduled": 0,
                        "user_add_success": 35,
                        "user_add_attempts": 35,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNaf9e6a35c5e48928a7315aa5832a7256",
                            "FNdfbf8c621ef1902b201711f73a81fa6a",
                            "FN0e760e515118c4a305545e8e42e2f22a",
                            "FN6b24167b969c74fd2130620b3eea2862",
                            "FNdb90cd115de59d31ff6cfe0945ae1311",
                            "FN2147439f2f0dc368fac64c7ee7aed869",
                            "FN1755a66a83e1b1b9e828d31011dbc5c8",
                            "FN9676aa1ece5fd6f7b8d883236ed71197",
                            "FN5d06c691fd596c2cf01427ce9a3c92d5",
                            "FNb7499663856f22758fe360faab1fdf13",
                            "FN9dd32a7669908094e9c6d702602b1fb3",
                            "FNc794b3ca65d7c7ab5dcc79bf8143f879",
                            "FNbcbcfa2f92398220275f22556de697a5",
                            "FN9a515365123448a113a90a27a17155fe",
                            "FNb9ba60de5e2adc7a08733ddaf12cec5e",
                            "FN94dbad8a507a1d76e5f395f037ebef59",
                            "FN993c8fe298e679b3dd98f25f8ca3ecbc",
                            "FN3af38efb3b420582f11878afee7fa58f",
                            "FNa2716524c5fa53c5b7d7c90c788f770c",
                            "FNcd97aa31ae40ef930e2b4a69ecf4cb4c",
                            "FNa7789cdf930f94886b87457250154dda",
                            "FN7b693cd3a6c4b5448a1ecdd5e49f3e25",
                            "FNa1744c8fed3f87dd892bdf75c3be8f5a",
                            "FN26c9f8950849f9fc88c7996a200be192",
                            "FNf54c149f62390d7939acfe2f9c6423aa",
                            "FN309ccda465487e8dbf05e4e2e5e3395a",
                            "FN1de3e8c33cbaf1bb26c084d65c6fa8da",
                            "FNb4031f2abb05a20f433e3cf639941dfa",
                            "FN4d78661f578ccbf5650f8b349b602459",
                            "FNa43c8d2168c838ad8bb23b1253a1f31a",
                            "FNc751129834e308395e2c5f8243aa8a36",
                            "FNcdde6bec0fce5a719b41ba9ba298fde8",
                            "FN26968a9e0462b20ad8aecf9a81abdde3",
                            "FNa4fcc3a70b91674e37bdff3e8b0e575b",
                            "FN63a8f14ffeb665ffc1939d4db74035e8"
                        ],
                        "msg_queued": 35,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 35,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 409,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:10:19.163Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:24.601Z",
            "CampaignName": "Recco - Class 2 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C397",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T17:12:10.969Z",
            "ProgramName": "Recco- Class 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 874,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:35:43.539Z",
            "OrgName": "Alternate Staffing",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "HHA 2021 - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C222",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T20:38:04.062Z",
            "ProgramName": "HHA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 246
        },
        {
            "MaxProgress": 99,
            "ProgramID": 740,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:33:57.861Z",
            "PresetMsg": "REMINDER! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:26.864Z",
            "CampaignName": "FADMO Q4 In-service 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C150",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q4- In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 440,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:40:07.219Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Accredited Aid-Plus- Q2 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 4,
            "CampaignID": "C572",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Accredited Aides-Plus- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 874,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:37:47.100Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "HHA 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C223",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HHA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 528,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:17:02.039Z",
            "PresetMsg": "НАПОМИНАНИЕ! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:22.607Z",
            "CampaignName": "FADMO Q1 In-service ES 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C133",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 712,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:01:49.714Z",
            "PresetMsg": "RECORDATORIO! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:11.843Z",
            "CampaignName": "Compassionate Hearts Orientation 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C174",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 5,
                        "user_add_attempts": 5,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN521061c70c930330432a1047c9e1323d",
                            "FN62a1d55e6aa30fbf17ee837bac33e754",
                            "FN177073b99ace4636c8eceec35bc42751",
                            "FN62a570d22e61d88fe861131ada9640c5",
                            "FNcf884804aed2786cfda591f135b6bf24"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 5,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 532,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:39:18.677Z",
            "OrgName": "Bryan Skilled Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Bryan Skilled Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Bryan Skilled Home Care - Aid Mandatory 1 ES 2021 - English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C373",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Bryan Skilled Home Care- Aide Mandatory 1 ES 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 87
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 560,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:49:31.404Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q2 2021 - Haitian Creole",
            "FrequencyInDays": 7,
            "LangId": 56,
            "CampaignID": "C354",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:40:06.633Z",
            "OrgName": "SelfHelp Community Services",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SelfHelp Community Services: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C419",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 242
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 862,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:42:32.418Z",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:50.926Z",
            "CampaignName": "Assisting Hands- Tinton Falls - Q4 2021 - Spanish",
            "FrequencyInDays": 7,
            "LangId": 149,
            "CampaignID": "C350",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 167
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 682,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:27:22.651Z",
            "OrgName": "HDA Care",
            "PresetMsg": "REMINDER! HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:41.858Z",
            "CampaignName": "HDA Class 2 2021 Haitian Creole",
            "FrequencyInDays": 1,
            "LangId": 56,
            "CampaignID": "C317",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 2- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 521,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T22:42:27.643Z",
            "PresetMsg": "REMINDER! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:11:41.280Z",
            "CampaignName": "Sophie Q3 Patient EN 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C282",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q3 Patient Rights EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:25:53.645Z",
            "OrgName": "HDA Care",
            "PresetMsg": "来的温馨提示: HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:14.850Z",
            "CampaignName": "HDA EVV-HHAX 2021 Chinese",
            "FrequencyInDays": 1,
            "LangId": 30,
            "CampaignID": "C315",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 15,
                        "msg_req_sent": 15,
                        "msg_scheduled": 0,
                        "user_add_success": 15,
                        "user_add_attempts": 15,
                        "msg_delivered": 5,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNdf387dfbaa3005b019336a6c47a81696",
                            "FN73f644bca3d2e0e1e317a4a6420a4ffe",
                            "FNe1b10a0d7cdd09bf4b6ba975a00c0482",
                            "FNc1d4e9bc7fa0a104044ced4569270811",
                            "FN3e6577a2ea40224e24d08855d9f7364c",
                            "FN32cbd5e8897ce7020a52df5757bac20a",
                            "FNce9c90b67ae43d67ba36f4a9d315f95f",
                            "FN56031b292b755b62547e6896062278a5",
                            "FNdc2d38b9451c6bf49ae7268bc31fbba8",
                            "FN54ef24257884dc54c5b04ecd7b4f71e1",
                            "FN677fc70913febea2af20d6513cca72d6",
                            "FN48475f9ff47e68ae038c3c5ce010ac06",
                            "FN68a21b20ea29dc7b5660dcac230e2aec",
                            "FNa7ecd87a614c8be0655bc57d89e71ad2",
                            "FN6f86c9290a37712e7fb11feb36da38db"
                        ],
                        "msg_queued": 6,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 15,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 263,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:36:06.967Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 1 - English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C454",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 560,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:48:10.878Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q2 2021 - Chinese",
            "FrequencyInDays": 7,
            "LangId": 30,
            "CampaignID": "C353",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 77,
            "ProgramID": 160,
            "DaysOfWk": "6",
            "TimeCreated": "2021-07-27T15:09:13.654Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "c2c2c2",
            "CampaignName": "2md",
            "FrequencyInDays": 3,
            "LangId": "",
            "CampaignID": "C2",
            "MinProgress": 7,
            "ModifiedBy": "uri@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-07-28T08:41:47.950Z",
            "ProgramName": "Recco- 2020- HHA-8",
            "CreatedBy": "uri@nevvon.com",
            "Deleted": "2021-08-19T21:24:47.986Z",
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T17:53:51.730Z",
            "OrgName": "M&N Home Care",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "campTime": "2021-12-08T17:00:22.810Z",
            "CampaignName": "M&N EVV-HHAX 2021 English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C49",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T19:16:42.048Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 240
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 42,
                        "msg_req_sent": 42,
                        "msg_scheduled": 0,
                        "user_add_success": 42,
                        "user_add_attempts": 42,
                        "msg_delivered": 29,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNadff7c1192d1f138cd31f2c5624db3bd",
                            "FN9baaffe4ada129797b8edf0e2abcff45",
                            "FN3715f8183b72bdb32293303878d1e90b",
                            "FNe528c662a77a0259857953b8d1689149",
                            "FN85bc6838ba9458bc1772e77b56a63822",
                            "FN500de147effd0def9b5bfd22a5f6327c",
                            "FN04a869b94859eeec8de0e91b12884558",
                            "FN59aaf21d265584cdde953f6b1c082fdb",
                            "FN28fb0fa36694dbcb1713408318cd6b15",
                            "FN1820fd2f20bbe125dc9c7d0705cf3d5a",
                            "FN222c84d51bcd13a07dda1ea27f92c63e",
                            "FN44ebc8794c8be0f387079e1e833de967",
                            "FN538d5f3028d68b21198746f8ca30a54f",
                            "FN08b9469b8c7afd98c7de169c093902bc",
                            "FN8778ba02690a37fc5cd558dfe526777e",
                            "FN504bf2f72ea4a02a140fc3fdd1caa080",
                            "FN005c825641aae14938078025a80e8dea",
                            "FNde900161fe8a94da07d457b89a6c52ba",
                            "FN5a36e2dc57371b2b26a3d6a2b5f978ed",
                            "FNa613e4a7f711253d5c49f3fa6f134231",
                            "FN4ec06268d30766e9bf23f47ea50257bd",
                            "FN2ecf259fd745683b81c86fddedbc017e",
                            "FN9e1a94f63334f03eff50ef6e75a83b4a",
                            "FN48076f26350b7e42f9da15d81e88a116",
                            "FNa8068b1dbe14654fbae1cf067ab7789c",
                            "FN9e9f716dababf92853eb6144d0245c4f",
                            "FN1e35c0a760f0bd45cb8f687513f7b429",
                            "FN5f83a86348b4bf3b3436ca3ffcf26a61",
                            "FNfcb4564558ebb19565c9147f5f298d75",
                            "FNef38156defffa629711c50402720f9a3",
                            "FNdc738790c1351b67bf147c5d06d033c8",
                            "FNc785fe449c0225b3e55a3fe864b43a16",
                            "FNfd011f3dc4fa863e4674a29b0bfb1f7f",
                            "FNea9ae8fd409077d8d0ff546b09640ddb",
                            "FN97427e8e6d687f9dce7cb7989429a16c",
                            "FNce9a8e66043c76ee6efa7e010393d468",
                            "FNc7fa0d4e1d16cea190cb89560a5e117b",
                            "FNcc5c87aca5ee197b86f6596791ae90fa",
                            "FN7b91e18af32c0246c816032027f839cf",
                            "FN66a52604527caf73049595d47ceb75f1",
                            "FN2a3fa0c7590e7f920b96c02643d01b6f",
                            "FN5cc58df6acf9fb0d330bcb27ce4cb727"
                        ],
                        "msg_queued": 6,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 5,
                        "numOfUsers": 42,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 1,
                        "msg_accepted": 0,
                        "msg_req_attempted": 41,
                        "msg_req_sent": 41,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 27,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNd78d2c4aa9a9dad1949e0bf19bba524a",
                            "FN677a926fe0056449f8f83faa8bcb5351",
                            "FN6e647a2402fe20e573150cfdd2c121af",
                            "FN18775dbf28668400fb30388ba00e8121",
                            "FN0c91c8365b639295c8506cd2f1aedbb2",
                            "FN21a058d1b6e57b26798761933a41281b",
                            "FN37856619df362056b11191ccfc05f592",
                            "FN91338759e4df3e6b600152c77a7f77bd",
                            "FNdf106e6f52247f57295a664feded9f8c",
                            "FN2afb87ff38220a149c0987866040123b",
                            "FN0e38d1fff48bb7b1551614420bba5020",
                            "FNfd1090c79c25b2d167fb0e27c2ac57b2",
                            "FNddc3cd80bf31fdce7b5f4cf64e9c51f8",
                            "FNc9a067b7141a7205ff84b10bfa924055",
                            "FN7a0b95a92ab19278e8081f6fdfafd8f8",
                            "FN6f497e9623fa4e56d0cb77178b5ab56c",
                            "FNdd2c68cf6c5b36f78db12bd11412c9a0",
                            "FN77b53939c5010db91c4bb13565b21a60",
                            "FN814e52c9ed53b7351912a3fdbd4e8889",
                            "FN29942bf9e86ded544bb77010998ebe21",
                            "FN997f1d5a39bc40f5db15c5d11bfa55e1",
                            "FN1493f66eee6e183a6727768e454a4a63",
                            "FN98fe967111f4960e0684ff9562429e9f",
                            "FNb25030508ee9c11ac4234cae4f3f0065",
                            "FN9611a761924af150902ed5c7a369afd1",
                            "FN26a1304f5450e33d73e2fb516351318b",
                            "FN0d87bd8355371fb835eaa71c4da23913",
                            "FN122b6f65541afddc08e3fedb68858862",
                            "FN6579a41f42ce267420bf0713d122e8be",
                            "FNd13127ba43267f2c6df68e2051120ce3",
                            "FNfc96c38dd71f2eefe0a10fb178ca2805",
                            "FN443e36b33f07828d81de57ce85a9e3d8",
                            "FNcbbdcceb5af76d966357c160ef3efcdc",
                            "FN181f6c15d800a83f85edba52546fcfd0",
                            "FN02eb6e184b8fcb0f9676a91b29f0e8e4",
                            "FNa779706c9f0682d7a3caeffe7ec8e5dd",
                            "FN118124fbb8f9232ade0fbaf26f28c226",
                            "FN17781c28539143aa0d8fc712a83aaca7",
                            "FNac581a3a9a78fa444473248e463b09d1",
                            "FNd2c27d15d2907ab56fff4771b21c8dac",
                            "FNb78c3e0bce83b154705a38fc51577759"
                        ],
                        "msg_queued": 6,
                        "user_skipped": 0,
                        "user_update_attempts": 41,
                        "user_update_success": 41,
                        "msg_sent": 5,
                        "numOfUsers": 41,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 41,
                        "msg_req_sent": 41,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN52e99b6dd85938c7ede97bb9c4ec8393",
                            "FN5158e46d4e52020828179bdbfca90971",
                            "FNbbaf5215e2fac7414f208f72c1d570af",
                            "FN7871ad844951b64ac5fecddba02d0a78",
                            "FN8a14c4402e403c80b367f71d271467de",
                            "FNe2d8f79fd2a5d746930727be041c3943",
                            "FNd5f8d207e77b1f77d23139432360fa8f",
                            "FN5daa40d9ea2f94462f94cc7a97a12634",
                            "FNef9012c3985825811ce77e29da41f952",
                            "FNaa06daba36ad5f2f1ffed764690e95b0",
                            "FN2a61cd26d3b654f6c8dbed90568cea6f",
                            "FN7c48adc4bf394e17ecb7d038228f789c",
                            "FNf6c59d008bc59e7707bbb3c1fbdb19a6",
                            "FN5d79322adf546ba6150e7dd5ee3817a4",
                            "FN6c8af78f0f9faf9a9f7c17990749f476",
                            "FN0b136d532f15f4ff62afb62c2372a073",
                            "FN9dcea2fc9f8728cdc4c526018fcec9f7",
                            "FN45ae5cbf1be9852de7600d379aca3c7f",
                            "FNc8bed9e9818fe252c84a172848ac129b",
                            "FNf2fcb0c389573e2f6f47577b1be0d1b7",
                            "FN471e56a9f044d3992041454bfa03487f",
                            "FNcf56e6ab578aeff20f8552eb84516e04",
                            "FNdccf1c10dd3a84d0adbd06f96041ab3d",
                            "FNa5e24c5914eda5a82429dc562373c048",
                            "FN2b64bf82b8491296d7efa01421568f00",
                            "FN0221ec43aa447f0a01c5f4589d1496f4",
                            "FNbb202d88852a64665e23966b0e11ab86",
                            "FN535e21b166d9516f40d8902c2b617c95",
                            "FN1779ad8c3af32ae1dea3afd0957b3aa3",
                            "FNc49d0df0b27db6c5751eb1c2b839f1c3",
                            "FNbc54893a2d33966cfc9d4510aa977c36",
                            "FN79c185e10498932df8d1f80d421addec",
                            "FNcdbe1b196e8caa23bcad5c60c5a39099",
                            "FN63d85c331da53fc5153ed4acbad36b5a",
                            "FN8f61e18b9b4734ca57d8ab6613d9365f",
                            "FNf5f158bf4ecdedc2a01bdf201d83b928",
                            "FN0fc7f4759db3a4fc14d97e035a657f42",
                            "FN75dd1bec449b02948609afa31c3dc4a1",
                            "FN799df1a7a88f9111d23d4bf1560bab23",
                            "FN5c660830bb0f03034a51064fd4a1808c",
                            "FN7379b25fc8d934cfbf588b86ecb36df4"
                        ],
                        "msg_queued": 39,
                        "user_skipped": 0,
                        "user_update_attempts": 41,
                        "user_update_success": 41,
                        "msg_sent": 0,
                        "numOfUsers": 41,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 42,
                        "msg_req_sent": 42,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 26,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNbecb9ac420d3446e22071aec2528459b",
                            "FNc58e5c9c008160909ec1180d6f4701b8",
                            "FN58b463a719dd3c1965dc8e96ae1cde1e",
                            "FNed239cf6a904be63865cf68842b9475d",
                            "FNc6a9ab23ea5d7f501955802062c78552",
                            "FNc8b8ba04dde050539cd38d2aa03011c2",
                            "FN1fc4d90cf99903ed06cde560e341e2e2",
                            "FN9d6e56315515efbdd0a7e23650a107da",
                            "FN2e3a9585c49bf2cd8befc5210441a816",
                            "FNa661de5bb82638b5323710b601570e1a",
                            "FN5a04338e18dc4d2f8ff315aedbf2e80c",
                            "FN02fad721ea1ec3288d6d863f96ecc798",
                            "FN4611640ebecfb9dfa636f61305ce0689",
                            "FN940cdf797f6b5e99a5bb14862e6bdf9c",
                            "FN5ea7650746ac82dfa5539e9f9477a2f1",
                            "FN04d3dd17fb5428425396a041e0a83ce4",
                            "FN1cbc05dc8b342b148ec460b1d1b75b07",
                            "FNe7c78e4d30ceba98ee0fa38a3a55be81",
                            "FN7837b490d2b1caea00c23d9d4546167a",
                            "FN2cd76dd6081f346f47588355ddfb5fd3",
                            "FNdd2679553b4c5e261494cf6dc5cd1111",
                            "FNdaaa0f526f225386118aa855b0f3c9ce",
                            "FN750bee91d82d08be72fee6b0dc89b28d",
                            "FNf416cc6a109523f8cd00041a573d06f9",
                            "FNc2997d720c7bd84fee52d304d5227877",
                            "FNd656bba06e26a636b67f468c4839bfdb",
                            "FNc279b0eeb52addde57952b451a47d1d5",
                            "FN74b01e0493d7a5ccd007e37715cda860",
                            "FN56d6aca67099d423b74396122dfd95cc",
                            "FN50076d184b168155596bb69239971d28",
                            "FNa191364256e7859924b2c61b8a5c1379",
                            "FNb7851e29fa9dac866e07b9e40dfe8e4e",
                            "FNd32bf658569ffd80a1ff82e92f53ea98",
                            "FN670e0617f2cd5cce1a40f9a6b192614a",
                            "FNe98bc3c630e94ba325ad1848400ceea9",
                            "FN95878754d1fcf30018e7ccb3fc0e1ae0",
                            "FNd7be123c463147cfcb69be7e132e605c",
                            "FNa5d16363c3b051ffbc964fc2a296d017",
                            "FN19115c4b32e359401f0cf54fdfeb8960",
                            "FNda6197cdd6890ef12185c2d97125c479",
                            "FN3386c95625c54795177026ccc19ccd3d",
                            "FN2f8910a316ea268e58cce465552ee7ca"
                        ],
                        "msg_queued": 5,
                        "user_skipped": 0,
                        "user_update_attempts": 41,
                        "user_update_success": 41,
                        "msg_sent": 9,
                        "numOfUsers": 42,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 788,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-11-29T19:50:01.480Z",
            "OrgName": "Metropolitan Homecare Way Inc",
            "PresetMsg": "REMINDER from {agencyName}:\nPlease complete your mandatory inservice training ASAP to stay compliant.\nGet started here: https://bit.ly/reminder-HHAX ",
            "campTime": "2021-11-29T18:00:00.000Z",
            "CampaignName": "Metropolitan Homecare - 6Hour Incomplete - English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C24",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "6 Hour Program",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 238
        },
        {
            "MaxProgress": 99,
            "ProgramID": 711,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:55:21.298Z",
            "PresetMsg": "RECORDATORIO! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:44.635Z",
            "CampaignName": "Compassionate Hearts PA In-service2 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C165",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 353,
                        "msg_req_sent": 353,
                        "msg_scheduled": 0,
                        "user_add_success": 353,
                        "user_add_attempts": 353,
                        "msg_delivered": 55,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNfc23f2d8afe2f3426660f72a30227718",
                            "FNf69be34d3b36596a81bdba02acc6900e",
                            "FN241eb686869f7176a417ce987bce3094",
                            "FNd320631c5054c44b1121daba6138ea7c",
                            "FN8097369ed40e1e21325f4e47b57b7bc1",
                            "FN9ca40bccfba7ea38af48202d59354715",
                            "FN1e8f11ec8828a697127db376e54a8b38",
                            "FN4395b79f53e7403641da0915455cf447",
                            "FN46724e90e2bd5b3b0f5b3733fac75faa",
                            "FNe6546414f69d8cec94546f431d5b38a6",
                            "FN415cb6d40f6c89bf480ac17a24ef3c90",
                            "FN8c6fe324a09ca5500325ace3f93282a6",
                            "FN1a4456d404ff72933ffd57f34f3a8c92",
                            "FN13175eb808ccc5445818ce6e6cc14064",
                            "FN966acfb029ded3929065d5104a21f893",
                            "FN68cf695c2a8ce309ec3bfddfc8ca2b65",
                            "FNf36cd09636215f89816efcb6f5ccf194",
                            "FN58924745a7f6d043cb25f1379753ce7a",
                            "FN3de8892599fdea056c709289dcb63668",
                            "FN6281f2d6b30d10cb7664ab40eaae534e",
                            "FNf0bfa7d4271aaf34f42fc1c45d90a90e",
                            "FN42ebd418818292f34e8cc6a07999bdab",
                            "FN5e2aa57bbb0582660353795ca2531177",
                            "FN96f1a50a85349fadd89642362bc8ce4c",
                            "FN655a5454ca11b44575396d125e1b6d36",
                            "FNe759b7d5843c683f1c0519db1d15eae5",
                            "FNaffc026124993ed3bea63f0d6364b6ee",
                            "FN2132fd15d05c5b4defca5d1b26d177e3",
                            "FN374e5f7f3c52c534ede6a5172b087972",
                            "FN0c49c55541640804952f3862d4b67def",
                            "FN638e464250fca881462af4aeecd740b6",
                            "FN5380c846c69d7a8d08094d04850d8015",
                            "FN279d81f8844a7351ebb0480c7da5bf50",
                            "FN71b6251dc7b1c06cf1944a6d04c012f0",
                            "FN2061f45698c56a788131392cbb9a36aa",
                            "FN5d4b823e5c93813adb9b81c166b468ce",
                            "FN644eff5d357870732f22d60fa30a78e7",
                            "FNef574ba66b59506a2ba0b305ad6759f5",
                            "FNeffe42464bd22831db83e88b46bc0bca",
                            "FN2be636bbc8b60b27ce3cdfe6c1a16fa1",
                            "FNe54c1bb6bc7955f38347f7f695b345c1",
                            "FN4f9c9cccd2dbf620f4b5df00470621b9",
                            "FNacee307ce4e5b2b10343573791c97bfd",
                            "FN4743cf84b327ae721600d5df29fd9b9d",
                            "FN019253bfc0327e339a204cb5b7389967",
                            "FNb65f089f75ee596788819cec8f703bf8",
                            "FN376b07c9bf5c35955a95f9b6d18058b9",
                            "FNcd14b2308eb03c32e3ffb1422b8cd4a7",
                            "FNb11a1d0ec7a219103bd7bae70b76a16b",
                            "FN479f04d3cd07ac127b958a49456ae300",
                            "FN0d1ce1ed21ed1739f38798180d6ec49d",
                            "FN36f270565460c6668319d359f69f2d4c",
                            "FNf804b2a6baed33cae5c46d37716ec252",
                            "FN80f685e29a79e4d991f1a5390f7c5a7f",
                            "FN871e8451d0931392d3ae7b6c7fb1d3ca",
                            "FN2229d52c3d25552aab4644f4b71d4f3a",
                            "FN062239dceee2c9148eb48d9cf65c0603",
                            "FNd67f47fa67d114f3c4c129ed91895de4",
                            "FN99d676fe0f34ebef8e5b0bb966de5591",
                            "FN39113b9d5bb73af489030a96a363a7ff",
                            "FN80d4c25aec5af79356270784f02ce5bf",
                            "FNac4e4da3012f4cd47c8fd7d10ad9af86",
                            "FN82eba7b56bcd391acd15db796e4e08ab",
                            "FN6ad99239649418afcbb6646b7c0580b0",
                            "FN0968b2c42ac0b2e48f2cd3f4406a7180",
                            "FN75f94ddf0bc7a3d8914a6fcc0d0dcc8b",
                            "FN70689b67065b14f7379329cf3ae81c16",
                            "FN2998b71f42242bbc04629f8529009e90",
                            "FNc8deb6966726f741b24bf5a487874cc0",
                            "FN635fc3df0e171e9e50cce1756f61a40f",
                            "FNb9b1748e16f33b1ac3e2837a00a790f4",
                            "FNe0d4b3a062fd0428a82d556f49e08d0b",
                            "FNd5d70f64eb202e15c598e8c2143aa280",
                            "FN7a1f13529f7c2584e975f22522a9da62",
                            "FN4f9175fd662a7c3b3ab01b700bdf0467",
                            "FNe9c83fec7f323d3cdc2fdcb88b4ce03c",
                            "FN2cea5b7c6d788c78847259451e4ed4f9",
                            "FNdb3546a0fa62de2568efe12ece4d8b53",
                            "FN023c10200f9f08573e4cfca8d00ee40a",
                            "FNd8fe1fd37f9c21fdbd4efbce6af4d3ea",
                            "FN4f4f2d996d2ae7f37ef6f4df32fb5cb6",
                            "FN5b984a88a01c6fbabff9b7537e083431",
                            "FNc87775c7b418cf02c4cb10f628e622aa",
                            "FNb5549f973b81a85e7fe875750d208242",
                            "FNe357370209f4cb3511f5799504636477",
                            "FNa72921571360d6368c09551fcc499cdb",
                            "FN5f2e7fb3f1c558657a837a816141192e",
                            "FNb887477731786808bb5a8389a4ee99fa",
                            "FN50337f902a2ba572801227db6dcc178f",
                            "FN4a1849ded0e90f950e79a6c18c9c27ce",
                            "FNf654df03a0986abcdbc93add1ee656e3",
                            "FNc4d7e2f9df12dc5639985524c5127c2e",
                            "FN3f78a42a89d7ee153ecb867a6c4059bb",
                            "FNf79b54640274944149edf722839276a0",
                            "FN67d56d717264ba9eac666679090b86a4",
                            "FN0e82c4f397403cdd980219ead3bff955",
                            "FNf3e0ca1d97eccc8c4575be94a02c15e2",
                            "FN634e6d3b410fa0e72811ae2bc0b7520f",
                            "FN17698169d171d6fc289eb0c6746bcf12",
                            "FN983bcbaaa4ca9c9cb8900694b362175b",
                            "FNfdf84ca7d5998b6bd8827a0dad54bdf4",
                            "FN0464fc65da27688ac39bb29f37718253",
                            "FNc2afd1808728f8dc19ae121ad1489af5",
                            "FN1520709b9aabba5575d21a7dc566402f",
                            "FNcd6a69d2b13022f12f5f9fa4a0df7c94",
                            "FNfe422fd52307a526ecd8a350d2fb0686",
                            "FNfa7eb9a932677555ff2f0321567600d4",
                            "FN03bbb4e9b7970f353bbcb7ba4a89d3c4",
                            "FNef981f50e6cbbe3fdc87b8d64e8726d5",
                            "FN9b4a1c95b3386c01189ffa6402eaaa96",
                            "FNedbed6c953a94acbe76de0c0a577802f",
                            "FN4a2fad2eadf9d6c589ca53de99f8126b",
                            "FN5f0e62df86daf8e404d485d3769e3331",
                            "FNb117e3afd9f9bbb38ea486ba7917adfc",
                            "FNc7d081beda5cec07332902ab5ddb271c",
                            "FN5a7471e59d1eacc5488ac97bd61ce0c7",
                            "FN8ebd58f4ae02892aef26c8641f5c6ab6",
                            "FN674d18cd49007e23f99f81825db4886b",
                            "FN9194cc113131373ad42bc6cc7963f7fd",
                            "FNe3be0503475624da6b99fb097dc268a4",
                            "FN841fc451ea95ffd8d3a2ad2f11469c7d",
                            "FN15b9dc243f655c540d3ed987caa9a57d",
                            "FNc85a13d7d850c57e3bca1ce69dc93960",
                            "FN8efd60c3b8c35f334f1f4d51c731ce44",
                            "FN31286a20778abc39ca2abb83f13a6537",
                            "FN6ae9f62e7ee9026b6122258b175bb5df",
                            "FN4a506d3a31601d5180b4e2f014332473",
                            "FN6905a5db0532214639862da36de8a916",
                            "FN67f35023eeb2c3c3e2269f32fd4ead72",
                            "FN7023c9cd48f8307329050a34d4710c38",
                            "FNa3a35bad32278eabec6227d54a7dad1c",
                            "FNb1242b2d1e122cf0391d9616fbd462da",
                            "FN57fd3259c8c58dbc4050bf8d9c7e6782",
                            "FNdd64735f3723c520576a2e7ebdefa77c",
                            "FNbe8df6adf799b7d0bd39e64002860411",
                            "FNf9aa91d875b717ad255bf6b8b307c41c",
                            "FN770fd11db008298b27895f2826a4f9e1",
                            "FNfdfea4e310e817f2eea9f9a798554830",
                            "FNc5a20ea972899f8ffb8091e42c346e6f",
                            "FNd4171f9af68f9affe3e0d10d8ff71ff2",
                            "FN7c6630a70dbbe6a31baeb456d26f391a",
                            "FNb57f29bbf0d823da293cefff5d8fc172",
                            "FNfa5032b2d5d75379b6b1c206564e6942",
                            "FNaa0224fbefa9577029dde59de80f7440",
                            "FNcebbfa2dbc9ccbb474aa1f2fd3b0a623",
                            "FN01ca1bcb86c8faccd9cf6cf882eca691",
                            "FNf047d70d083659b454a929ec1bda196f",
                            "FNf6660761082230128647b6f465fb3e36",
                            "FN7b647727d264527537e200cc982ad615",
                            "FN8123feea29d7aa1ce2ca4195d26beead",
                            "FN13fcdbbf363da73643599b847ffc041a",
                            "FN95bbf0989689caf7b8c124ec29b1f1ad",
                            "FNc265d8cf72f4e2e8c55c1dd42f08eb4a",
                            "FN8d786fe1e75a09d919e34f9088ce10ad",
                            "FN17c416ff6715037a4e9ac60242be6e91",
                            "FN7a8ef5f8fdb284577925cc108130e4a5",
                            "FN4aa29cc6e34c0e43bc2467f6fa049515",
                            "FN80c8970ef0425c6d105e1ca9dea70416",
                            "FN03366952f9b099aa3c7fb7e43825e382",
                            "FNbd53d2fb6e78ffb145c37eb05399da0f",
                            "FN37b73a5504d804daf7565c1e65e35c0f",
                            "FNb5fa0fd7a939f6e42d7dedfe2ddb85c4",
                            "FN3560c887441e19d0aa344f0149d4d088",
                            "FNbe636b7e34d753b62e4e40ba283c2f0c",
                            "FNbf99bd9037ab3cfae40d3aa1f1c6f36c",
                            "FNec808b1e14aa0a44902abb8fd261db48",
                            "FN5ebf7a02bd85d68016d638fbd915703a",
                            "FNc9281be70595f1d838c3645aca61bc48",
                            "FNf5b9ae883abe6d8a16d945bea59ccef5",
                            "FN6db949fd61945d44eeba618c8b8afc24",
                            "FN6111aff27ff803ce422d4fe8c2985242",
                            "FNa693b546ac2c3ff4cbb589cd7786fef6",
                            "FN8dca6b45c5c0057420156f03de7f0575",
                            "FN7c5dc3f171ebe306b17825eb7c77e3fd",
                            "FN3946767687b9c231cb4acfe6883d54fb",
                            "FNb9ebb144f041e051d7017b1760e2352e",
                            "FNdc2a8e61e9473e680942806e6e11365d",
                            "FN40c03c9f03eb1bc680849813eb4b6fb9",
                            "FN350a658715bfdca0e1f95a287bb7f275",
                            "FNd8b89d43b6ab3c2c32cbc37924abef76",
                            "FN7d3ae2284db94240ef88bdfc0da8daf2",
                            "FNb75cfbb28624576014da125464d26984",
                            "FN3c822d3b699771006f8c692ec59f6c2a",
                            "FNb504686ba49121c42017a7c02e36745d",
                            "FNa523920dfecaf09b169f3154bc4b5992",
                            "FNf34fe3638c4f6fa53cbaa25b8b0383f9",
                            "FNf88c3bf78a06d292dc4a993074ff9708",
                            "FNd67b2a52b8c2988af5c8e801b0c6ad66",
                            "FNc9edbc4ce275298f379452d851435d52",
                            "FNd89823fd9944c4d662e8c380bf6210a5",
                            "FN865b1642ffecc1be5222e2881557d8e8",
                            "FN6534cd1bea1b3d7707d2559b25f4e5b2",
                            "FNf54cb22661b5b75277424e9d6fb56004",
                            "FNe9ca2a5bddd35074e0c9cedaab66dfe4",
                            "FNd9a35a8c66868f74f272770d9dc8d12b",
                            "FN4739a334ece67cbf6f356a07f4d4419d",
                            "FNe8f1b530a3ac9a14999026722814e9ba",
                            "FN4f0e2841fcbae7512cee7cbac335de37",
                            "FN92f474ea0e2fcb431289c1e551ebf126",
                            "FNf4030a4adeed4c4f012cb2b7b18c8646",
                            "FNceeaa177a16fd972e993ca05fad6b519",
                            "FNb17ebe9e3dc0705da31556d950a2169d",
                            "FN4f155806a95a4f2669b99d0282a812fd",
                            "FN109727d8ae85fabebf28b95282ae3583",
                            "FN902bec71490d7b7738a84ceed9f86630",
                            "FN03599a5c42c1a4a747edbb3a6a6b8312",
                            "FN040b910623ededacc34fab3a410c8a56",
                            "FN840ce1ca8a55e6b59f147f373daa70e4",
                            "FNd28ee36c57d89597232f8b2087641b7f",
                            "FN9277d00769b0299c337c6d068a94f503",
                            "FNfc4504bbe45a57e0ba2f034e804dae4a",
                            "FN63805fba2abdde541a4a0fc46f868940",
                            "FN5d8f97f7b1f0a9f5f4007a0f6d4c5f4d",
                            "FN54948bf66f3c733368085726f0ccdf77",
                            "FN549f7d6f2d3dbbcec5afc11d35ca3722",
                            "FN002284c34a1224ef75e65a2edf56303c",
                            "FN03e6af806f76239bb5be091f9016218e",
                            "FNa36a33baa1039a37de66ee3877e72a90",
                            "FN2005b824489cdab3760e12ce54e00a0d",
                            "FN8df11927514a6c56b86be0bd535b81df",
                            "FN47b9ddf499609cdc556f6660146163ba",
                            "FNa3b77eb37b49da9e9baa486964d871ea",
                            "FN974a937b38e4f41599196c50ece67cf1",
                            "FNdfcca74748119060da10fc73792769f9",
                            "FNf39a024533d417c00163bf6d6bdac74e",
                            "FNfc6d30f121f229c24942e2710f019731",
                            "FNffb510f74ad6ccd06378feb5b5419723",
                            "FNdff442906fc4af60b799747a97c9080a",
                            "FNc8399356d0c7564ae3a67d1e70429d57",
                            "FNf2518e8fc5f04288d8488d71d1a9f928",
                            "FN738e96c5c1cbbed473ee31e34eeedc9c",
                            "FN31754f85a224093394dc260bd5418493",
                            "FN83b69078297917dc23bfe40a0c874133",
                            "FN0e59587505bc3fcf6b5c898939776ecf",
                            "FN8dc1ec27e224b2fc9490987e0239cdaa",
                            "FNcba9ab207744fa5836d2efef1172b890",
                            "FN2cea8b315cd1e36c87ea72227fc6da42",
                            "FN2aefede95c134bbb4da1a816d4406225",
                            "FN0210915e71164fce80290a16f1b046ca",
                            "FNfbe4d1946d8d7da1441355c8f8d26375",
                            "FN4725ac60dfe403b6055a5d401b68974e",
                            "FN10333e5a2137c12688e2b037b90c8477",
                            "FN277dadee9c2b75ecfab587cbfb1cb992",
                            "FN677b7f407db0cbcea909a7065f72fff4",
                            "FNb9ae5a99c771294323194070059a74a8",
                            "FNfaa5742b8e0a9d050ce53adb7ff56cb8",
                            "FNd956f1dad5f74354dbfd823a5d8ce772",
                            "FNb704ff29fd8acad380765db93d9a8cfb",
                            "FN418adbe2eb4a9828afc5c01aa828731e",
                            "FNfba8779898e9b624327eb7719a2237e0",
                            "FNe477e9f8e598cfe956fb849ca5d76243",
                            "FNa7047ea16da49d981da5062afea54684",
                            "FN025dac5b44635e2e40cc2671249b8bd0",
                            "FN650a0095b92437a1f90f122636d9dbcc",
                            "FN7ff58b1dd909421eaaae0f12aee1fc5d",
                            "FNfb0bc1aa6b3ba5c69e4a387c6b5ddb48",
                            "FN3bf2e9b60c4e0d00f4242b806e985539",
                            "FN6c1ab7760abb47764ba943ff141dafe0",
                            "FN5d5c1adc963a8d3d3a1d63557c48f116",
                            "FNdcd505d5df065d2d72add3e22f878263",
                            "FN8c23ea1c9cdd31b39d08e677e9f637b8",
                            "FNea2fe08c74454bb6894139f7d5219950",
                            "FNec193a765e91b17a35ea12b50934eef6",
                            "FNf8238d737e8899ef61ff9a25f0aae8ce",
                            "FN6aab94711bd9b0694e01d034e5e87e12",
                            "FN7473e5695a6c8daf5ee861933fe82bc4",
                            "FN1ea1af1200219c144dc407adc63446b6",
                            "FNc2514663392ac8540acf9c42465d3d84",
                            "FN03982e68939f118bd6717d00ba39cdb1",
                            "FN1693985aa9e15b4e2e4e6efe5e62e04f",
                            "FN57b81045b88a1647de3bcc287213d744",
                            "FNb8ba2e24c43827acef11abb6a2ae5706",
                            "FN6aee5ba62a1932599d399945715472ca",
                            "FNff961c6c7339112e2025596cce5ebe7d",
                            "FN97affe663a67fa59a71af30badb1f449",
                            "FNaae5f0aec70d649f9d84017af734e093",
                            "FNb2c39460fc7876f1a79702441fe0db7f",
                            "FNbf4dac8cc62d2114ec4b88dc315169a7",
                            "FNe52168ded24bf28a0426f655f226fae8",
                            "FNafa58d42c71a90dae3b9a1832513ea55",
                            "FNee8877c15a0ec1869e14f3ed1aff9557",
                            "FN58975f2ee365f5d75f9d5cac3c7da1c6",
                            "FN2b0b7555763dd7dc2da16172f4f5baad",
                            "FN08ef93b8620ebf4fb0e5542fb8f23344",
                            "FN34e97e8a3c5429ae1f8c855e405892b1",
                            "FN5771f40db5e37258c5a15ea4016b4217",
                            "FN548f60ff226498002eab9f106c20b10b",
                            "FN5c0f77c699d24fd6cd86fd03f77e6f2c",
                            "FN903ecf17b81e9730e245cbe483cff8e1",
                            "FN17c73a5bd50100933cd3bcf6fbcf4eca",
                            "FNedd1b8c0fc34c848ffdc0df2608fd38f",
                            "FN225de23734a93e65c7c0e95312495522",
                            "FN228c3f9b53e321986d8202ac32af6077",
                            "FNa365da1f43d5208f2e5b0a73315f2ee4",
                            "FN8baaf31ff21e458cfdde3fbfd790e734",
                            "FN2ff365e5a6a8b736e168153ec5314498",
                            "FNd2ee2e9c29a75fba0810b9794e6b3efb",
                            "FN07de5401e098eaefd21d0a0cf381e77c",
                            "FN72f437d06445dd20fb3b45d6d72ef862",
                            "FNd019252349e7f33e86fb5373f5a46045",
                            "FNeda85b9be9cae6c255b7347ee651035d",
                            "FN4d84201e5fa587ceb72920d3ada12969",
                            "FN3740a2f82cf9acbdc8772bcdee703eda",
                            "FN1be8761e21e76fc49fe015919959fc98",
                            "FN2b64a14e1c791dcf6a25a689d04a43bc",
                            "FNbff71974a61f4eeac87014e86774bc04",
                            "FNfd4eee83bbf9388f196aac2e451aace0",
                            "FNec03d60f5fe5c104b8a1d5a8834929d3",
                            "FN7fd7d22b466445c828ed730ff33c04c3",
                            "FNe7f40db54132ff0d99d59e039ea53b5d",
                            "FN07a1ff209039d7e4ad8db16137077b86",
                            "FN45eed9718738e8a8009c719cf79c00c8",
                            "FNc32b5d23e95b09a20719a8e95540e218",
                            "FNf3eac6193f22b6e4e3b968727f750258",
                            "FN2d7525392036addea25e219b1055a3da",
                            "FN5a5d992ddf66a4fc85d6eda4dfe2b644",
                            "FN49896a47860a6cf6c9c7c0674fd82d4f",
                            "FN2d8e8df40fa65e6aa102a42fa0df2ea9",
                            "FN94eac68658456936f3770972297105d5",
                            "FNf18a066d3aa864c28a79382bee46bcce",
                            "FN2b8038921cdd82ebbbd26e38b4fe5d24",
                            "FN47f519fc31d0e9026b6281350f186846",
                            "FN2498805da2b4f00ff5adbffc171f1de4",
                            "FNf02bc139b24945ee57d77c214048c20f",
                            "FNfd74a091652db1b64ebb8565c8372736",
                            "FNc9ae83238175413f05329dfa123130c7",
                            "FN44b70e950c8d59b33397031af08346f5",
                            "FNb46b309316b800f4ed1a4a71c20d3d99",
                            "FN72e1a90de2778940097b552a8057b112",
                            "FNc8824bd722ab5f40c7321583cdc474d9",
                            "FN6ab7ec6b68cd84ab4f542ff4f6c2c6b9",
                            "FN287a7613f7c8cc04d7399e8a0d58e68c",
                            "FN08c9717735d47df2965f6b6aa4d2888c",
                            "FNa455180bb3feb7c74a1d00d3433a3d6f",
                            "FN2adfb914f60bd0bb003ca6fba7d58b0e",
                            "FN426d34c47995195602da6be96c398f4e",
                            "FN8105089778379306feed6537dd910d87",
                            "FNea0edd51907e38732a3bb432a085dfa9",
                            "FN611a869588bb9766fb790789b11b7534",
                            "FNa3fa1bc08f77baf552d4c386be112d2b",
                            "FN6d4fad38f62a8bc1f022d61161253600",
                            "FNdba2705346be402c90d915fcb28ff270",
                            "FN006a6c72d3e44e69178ed9e7c9114c15",
                            "FN93bbf6b1435f1a752e795244b73bca86",
                            "FN71f3895573b3ee799649bcedbd15dd84",
                            "FNccc7a93d1071134856c01406381c712f",
                            "FN43f729717df1049ab2a207793a9604c2",
                            "FN488380d75dc3efec071f912bd18c30a2",
                            "FN6bec15fc2f5cc7e1506b03c1521edda6",
                            "FNeecdce4920f0707b4304f0f7c00d7903",
                            "FNf85b7f5a3b5008d4db0f7bbe450de4db",
                            "FNda21d542aab0f9daa7b0e32a0bd78f15",
                            "FNf9e49b81b373047ffb19fd0614813c1c"
                        ],
                        "msg_queued": 268,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 21,
                        "numOfUsers": 353,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 705,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T00:24:44.928Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "RECORDATORIO! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:24:05.445Z",
            "CampaignName": "Ultimate In-service 2021 Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C349",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-service",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "ProgramID": 841,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:06:26.440Z",
            "OrgName": "M&N Home Care",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "campTime": "2021-12-08T17:00:57.453Z",
            "CampaignName": "M&N Sexual Harassment Training 2021 None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C58",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T19:16:15.974Z",
            "ProgramName": "Sexual Harassment Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 240
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 3,
                        "user_add_attempts": 3,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNc103202b253a18511a7a6484fb23ed5b",
                            "FN8c59d358118caf1940cb8e708a49dd2c",
                            "FN0a204dcc570a66509d043e0bf2e63745"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2e9d5e0b9820dda4867e840f238f4386",
                            "FN622cfe2ac6084507d0a832aadd0fb757",
                            "FN7f7352b1b955f00d77d54a8ab78b338e"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 3,
                        "user_update_success": 3,
                        "msg_sent": 2,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN95e5f60de34059bc92b349dc9b0e4bd5",
                            "FN972b5273f946bc041a636864e6de38df",
                            "FN789d9ad02870c872c2342a7b8eb09094"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 3,
                        "user_update_success": 3,
                        "msg_sent": 3,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNff19cfbf14bd587624e7f40ce155cae7",
                            "FNd76955de4830f1f4e0fb4861de3cec92",
                            "FN988ecf97d072e46b858bc7e8ea12c349"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 3,
                        "user_update_success": 3,
                        "msg_sent": 3,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 788,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-11-29T19:54:09.138Z",
            "OrgName": "Metropolitan Homecare Way Inc",
            "PresetMsg": "НАПОМИНАНИЕ от {agencyName}:\nПожалуйста, завершите обязательную производственную подготовку как можно скорее.\nНачать: https://bit.ly/reminder-HHAX",
            "campTime": "2021-11-29T18:00:00.000Z",
            "CampaignName": "Metropolitan Homecare - 6Hour Incomplete - Russian",
            "FrequencyInDays": 1,
            "LangId": 134,
            "CampaignID": "C26",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "6 Hour Program",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 238
        },
        {
            "MaxProgress": 99,
            "ProgramID": 862,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:01:32.702Z",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:28.324Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q4 2021 - None",
            "FrequencyInDays": 7,
            "LangId": "null",
            "CampaignID": "C252",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 167
        },
        {
            "MaxProgress": 99,
            "ProgramID": 688,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:09:56.071Z",
            "PresetMsg": "来的温馨提示: Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:20.136Z",
            "CampaignName": "Angels 2021 In-service2 Chinese",
            "LangId": 30,
            "FrequencyInDays": 2,
            "CampaignID": "C98",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 494,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:11:44.501Z",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:40.967Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q1 2021 - English",
            "FrequencyInDays": 7,
            "LangId": 41,
            "CampaignID": "C235",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 167
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 4,
                        "user_add_attempts": 4,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN5c165a5b19978e3dad60e5067e22cbd1",
                            "FN66d77c836fea734ba3dc4cfb0e1376cd",
                            "FNa3839e432d3fc83d86468d9af1cf3556",
                            "FN3c7ce43ce0e9ea09b94117125129aecb"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 483,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:30:34.943Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 4 Spanish 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C414",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 4 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 88,
            "Stats": [
                {
                    "date": "28.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNdf998e0b735c6ae19ea32bab14a9fe4c"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "29.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN7e7a6e00c22df47e51c4319bfef82c71"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "30.11.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN8ec04fadc92812f4389cf7c54c16cb19"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "01.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN1c236d84ae8bcaf8e0fbd226e6019c67"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "02.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN21d0949325321e12d608c29e013f7916"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "03.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN156e4fbb455359aafb5b9464d2c68611"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "04.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNd5f9223f53210b547f54dd5b8ab66faf"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 1,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "05.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNecda2423dd1700e1363ec6b0e008f74c"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "06.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN33e709db1ea72095da264c865c554765"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 1,
                        "user_update_success": 1,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 642,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-08-19T21:48:48.135Z",
            "OrgName": "QA Nevvon",
            "PresetMsg": "Hi  {firstName} !\nPlease complete  {programName} ( {programProgress} )",
            "campTime": "2021-11-22T15:00:26.751Z",
            "CampaignName": "NY- COVID-19- 2019 Novel _ TEST URI ONLY!",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C4",
            "MinProgress": 24,
            "ModifiedBy": "uri@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-06T17:52:34.225Z",
            "ProgramName": "Test Program 2021",
            "CreatedBy": "uri@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 123
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 562,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:09:05.696Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:20.185Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - Spanish",
            "FrequencyInDays": 7,
            "LangId": 149,
            "CampaignID": "C368",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "ProgramID": 874,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:46:42.039Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "HHA 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C227",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HHA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 719,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:11:45.879Z",
            "PresetMsg": "REMINDER! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:11.168Z",
            "CampaignName": "Greater Love In-service2 2021 None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C187",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 725,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:38:15.436Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV Training - Spanish",
            "LangId": 149,
            "FrequencyInDays": 5,
            "CampaignID": "C606",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV Training",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 2,
                        "msg_req_sent": 2,
                        "msg_scheduled": 0,
                        "user_add_success": 2,
                        "user_add_attempts": 2,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNf629acaa765e0cc16ba0209dc87f3008",
                            "FN2afc5e8fcf55aabda9baa71cd326ebad"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 2,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 681,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:26:39.776Z",
            "OrgName": "HDA Care",
            "PresetMsg": "REMINDER! HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T14:00:00.460Z",
            "CampaignName": "HDA Class 1 2021 Haitian Creole",
            "FrequencyInDays": 1,
            "LangId": 56,
            "CampaignID": "C316",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 1- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 796,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:29:24.790Z",
            "PresetMsg": "REMINDER! Classic Homecare: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Classic Home Care",
            "campTime": "2021-12-09T17:00:48.896Z",
            "CampaignName": "Classic Compliance 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C203",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compliance Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 239,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 782,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:54:02.753Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Q3 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 4,
            "CampaignID": "C578",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 3,
                        "user_add_attempts": 3,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2db30cf57f3ddba9160846dc9d9d0f82",
                            "FN747c5705a5f9dcb6e0ac28a3e347c4c3",
                            "FN9f3205aab766805a757b4170272f6534"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 679,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:10:48.733Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "6 Hours - In-Service Program - Haitian Creole",
            "FrequencyInDays": 3,
            "LangId": 56,
            "CampaignID": "C438",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "6 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 861,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-12-10T06:11:33.044Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Orlando",
            "campTime": "2021-12-09T17:00:43.286Z",
            "CampaignName": "Assisting Hands- Orlando - Program 1 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C259",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands - Orlando - Program 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 248,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 13,
                        "msg_req_sent": 13,
                        "msg_scheduled": 0,
                        "user_add_success": 13,
                        "user_add_attempts": 13,
                        "msg_delivered": 11,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb8460be6361a28317c9afbe32a024362",
                            "FNb1f80905ae0ef76676a44e0a6d9ccf81",
                            "FNab9465a2dd4395e49593715375417a6b",
                            "FNc9d90dba19f547365c2b4770cc12e48a",
                            "FN803b0e17a61ab09390eeef7958a4e8f6",
                            "FNec5d8f288db3f6889117c75b6a1f953b",
                            "FN1d92834dd9359dae9c7512d69873dd44",
                            "FN17ac931ef782f4ffb8c102bea73ee477",
                            "FNdfc43db70a005f91582414959b6ddb44",
                            "FN9fee914771413edf381cadb4ac0b7ae2",
                            "FN753a2d952f0e9945d8a943ef2ddc169e",
                            "FNe08751d43284a4f334ed2bc9b66f92b3",
                            "FNd4f418ac369b93e1db63d69e9efdd9f2"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 13,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 684,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:16:01.139Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions?https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T17:15:34.467Z",
            "CampaignName": "HDA PCA 2021 None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C303",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 607,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:27:21.164Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:14.906Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q1 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 7,
            "CampaignID": "C242",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 718,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:05:18.915Z",
            "PresetMsg": "REMINDER! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:42.017Z",
            "CampaignName": "Greater Love In-service1 2021 English",
            "LangId": 41,
            "FrequencyInDays": 1,
            "CampaignID": "C177",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 15,
                        "msg_req_sent": 15,
                        "msg_scheduled": 0,
                        "user_add_success": 15,
                        "user_add_attempts": 15,
                        "msg_delivered": 6,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN49993863e8a6a5777826bc3f6867ff10",
                            "FN1198a24576cb7e1e648ec0b34afeece5",
                            "FN04f85c7a01385d15b80bdaa996d946f9",
                            "FN35d346fcd6260b97cefa29df2fd68015",
                            "FN46410e6565679e5f910f251d2e3d6ece",
                            "FNb99f333e372f39b5d48ee33e907ec2a6",
                            "FN3288e2be3003e39042e52a2d0775bb75",
                            "FN643934808298fa05ce3c147a0d9cbc26",
                            "FN5f82afbac70edc4c34406be4aacda0b1",
                            "FNe210d8c36ce0c50d8080507d723aef68",
                            "FN8d9612bdd125820b20e845db80f0988a",
                            "FNeff65ae851fa2de2cf35b5814404328a",
                            "FN6e9acaffb3e2dd77d25fc1abef8fc5b3",
                            "FN46b2991eaee5936619fe268cf5e58a37",
                            "FNa91fb4d71d9c60de720f66c11a5931c6"
                        ],
                        "msg_queued": 5,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 15,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 265,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:44:29.512Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 2 - English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C460",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 2",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 559,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T06:37:19.781Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Richmond",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 7,
            "CampaignID": "C272",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 141,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 720,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:13:06.419Z",
            "PresetMsg": "RECORDATORIO! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:30.644Z",
            "CampaignName": "Greater Love Orientation 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C189",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 782,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:51:27.636Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Q3 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 4,
            "CampaignID": "C576",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 718,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:04:37.822Z",
            "PresetMsg": "REMINDER! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:50.720Z",
            "CampaignName": "Greater Love In-service1 2021 None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C176",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 6,
                        "msg_req_sent": 6,
                        "msg_scheduled": 0,
                        "user_add_success": 6,
                        "user_add_attempts": 6,
                        "msg_delivered": 1,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNef320bf33ebd4e3cd5366dd1ec9a0abb",
                            "FNdd5893b0b3ae57b56072893334661fb8",
                            "FN055cb3d69347314b0015864fd8e26769",
                            "FNc1a17df4236016ca2613016be37acc8b",
                            "FNf2d1d642886361783100726efab8dd49",
                            "FNadfd917dba7d53ba68671599996ad426"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 6,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 316,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:49:04.980Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Spanish - Progressive Home Health Care 2020- Class 2 - Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C464",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T18:50:45.739Z",
            "ProgramName": "Spanish- Progressive Home Health Care 2020- Class 2",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 14,
                        "msg_req_sent": 14,
                        "msg_scheduled": 0,
                        "user_add_success": 14,
                        "user_add_attempts": 14,
                        "msg_delivered": 5,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2e649e7e6b38aa7a298abfbc0917b04e",
                            "FNfb81498d3e69c5273767dd488f8ee667",
                            "FN857c76f0666c423aeeae49b696518f4b",
                            "FNd889b0299cec622096d7dbfc95117def",
                            "FN27f3d26977e49f856ef5eaf4dff3affb",
                            "FNfbb7a85b0a86183434f8090d62207d6d",
                            "FNaaa8dd614f47a1d4fbb2783a923a79a9",
                            "FNfe1fe85c8940e621790aed7e3132a174",
                            "FN1edbe86c51bca4b86f3f4a976c0ccf1c",
                            "FN754dd50d83f1d82e21eb627bf2fbf676",
                            "FNd8d2bb164b7bcc4581b53e3b00a9913f",
                            "FN2911bbbd7df5c445cc75376fbadb60b6",
                            "FN8675052f1e6151d3716131cae6583802",
                            "FN3ba59e4f5a3593f6f1191507e8ba3fdb"
                        ],
                        "msg_queued": 6,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 14,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 561,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:53:39.084Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q3 2021 - None",
            "FrequencyInDays": 7,
            "LangId": "null",
            "CampaignID": "C357",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "ProgramID": 796,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:30:54.099Z",
            "PresetMsg": "НАПОМИНАНИЕ! Classic Homecare: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Classic Home Care",
            "campTime": "2021-12-09T17:00:14.121Z",
            "CampaignName": "Classic Compliance 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C205",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compliance Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 239,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 629,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:59:04.447Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:47.000Z",
            "CampaignName": "Caregiver Provider- New Hires 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C529",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- New Hires 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 795,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:22:52.970Z",
            "OrgName": "Classic Home Care",
            "PresetMsg": "CLASSIC HOMECARE: Complete training by the 20th to keep your certification! Go to the office if you need a computer! start here⇾https://bit.ly/reminder-HHAX",
            "campTime": "2021-12-09T17:00:59.159Z",
            "CampaignName": "Classic 6H 2021 English",
            "FrequencyInDays": 3,
            "LangId": 41,
            "CampaignID": "C195",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T17:24:01.034Z",
            "ProgramName": "6 Hour Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 239
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:34:11.753Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C221",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 727,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:09:44.822Z",
            "OrgName": "Addus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: ADDUS: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:13.349Z",
            "CampaignName": "Addus 2021- Q4 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C211",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T20:26:41.943Z",
            "ProgramName": "2021- Q4",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 165
        },
        {
            "MaxProgress": 99,
            "ProgramID": 712,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:00:21.986Z",
            "PresetMsg": "REMINDER! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:48.350Z",
            "CampaignName": "Compassionate Hearts Orientation 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C172",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:59:35.862Z",
            "PresetMsg": "REMINDER! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:59.966Z",
            "CampaignName": "Angels EVV-HHAX 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 2,
            "CampaignID": "C93",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 22,
                        "msg_req_sent": 22,
                        "msg_scheduled": 0,
                        "user_add_success": 22,
                        "user_add_attempts": 22,
                        "msg_delivered": 5,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNc087ab730f03224c0b3c8aa34056697f",
                            "FNd81db403b172040eb564600010d900b8",
                            "FN8e224c0ddcda2978589f763800130341",
                            "FNaf8c6d912169b5531d426e3cee6ba499",
                            "FNe94178b24cb0f0063d318cf6e4b798e6",
                            "FN5cfbd3599d34dc828b3e86c955375e80",
                            "FNaca81eb601a5ba3498fbd19146b437c1",
                            "FNe92ee35850f179683418b87098ac069a",
                            "FNd6c7eb631b27d6f3c68e514f47097264",
                            "FN63da0c2c18c9ebe09e282864a3169b38",
                            "FN2318e99a7ad382fc746639ff6f82c5df",
                            "FN45aa4d6200c996c7887d38af71d82b86",
                            "FNb00603d0894cf736ebd4b56c253e5c8e",
                            "FN53449e2e8ed73d7b747613827ea5af86",
                            "FN4d4bd6c0e40742a6497da2e7a07631d5",
                            "FN70f2519eb170d9b386f7b3d16b4ad6cd",
                            "FN71d6aa434d5138325ed17a83a25960f3",
                            "FN3757010f641f1eb6cd6c1a340809fa0f",
                            "FN6a0570d9c97b54852d56cc3f948f6bee",
                            "FNa62b5f27c7238c288ea82a1064ab1a1d",
                            "FN9fab1d2c7f4b8fbe44cecc9d5b8c38ef",
                            "FN8b014604954e17a2a97a1c12dd4b3268"
                        ],
                        "msg_queued": 10,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 22,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 461,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T20:32:19.128Z",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q1 - 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C500",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston Q1- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 136
        },
        {
            "MaxProgress": 99,
            "ProgramID": 521,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T22:43:12.158Z",
            "PresetMsg": "REMINDER! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:00:33.668Z",
            "CampaignName": "Sophie Q3 Patient EN 2021 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C283",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q3 Patient Rights EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 739,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:36:04.150Z",
            "PresetMsg": "\"URGENT From FADMO:\nComplete ALL In-Service ASAP. All training is due by 12/31/2021. Get started: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:31.201Z",
            "CampaignName": "FADMO Q3 In-service 2021 None",
            "LangId": "null",
            "FrequencyInDays": 1,
            "CampaignID": "C153",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q3 In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 562,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:02:55.485Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:11.572Z",
            "CampaignName": "Assisting Hands Richmond - Q4 2021 - Chinese",
            "FrequencyInDays": 7,
            "LangId": 30,
            "CampaignID": "C365",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 13,
                        "msg_req_sent": 13,
                        "msg_scheduled": 0,
                        "user_add_success": 13,
                        "user_add_attempts": 13,
                        "msg_delivered": 5,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN59263ae0d4099627ba8ca78a461fae10",
                            "FNcc498a5ad555e72ee36748ded701d719",
                            "FNec0b5918e29c1d7eff870b5875f546e7",
                            "FN9fffb605735789c46a4f26699751b875",
                            "FN7400988e2cf3aad10f35facd2368c514",
                            "FN29be85318bd7cf55f0b4872f11c87920",
                            "FNb7624067ce39967a03dd43115fd45f35",
                            "FN908a7ce2ee8fd3e865fbe915760f6318",
                            "FN8385c36e463ea3bb34cbe72b76c1d1ba",
                            "FNe3b39838cff55096d127b5301b95e60c",
                            "FN256a03deb8a99f2de4a12e8b21f33b20",
                            "FN226574785a3dc88f11ec176a9908f818",
                            "FN82f3781ab83c2def16485e4b03ed2229"
                        ],
                        "msg_queued": 5,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 13,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 285,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:28:13.104Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 4 - Experienced Aides - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C486",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 4- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 925,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:50:02.284Z",
            "PresetMsg": "НАПОМИНАНИЕ! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:26.710Z",
            "CampaignName": "Community Care PA 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C82",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA Class 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 725,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:32:32.073Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV Training - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 5,
            "CampaignID": "C604",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV Training",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 782,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:54:54.124Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Q3 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 4,
            "CampaignID": "C579",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 796,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:24:44.074Z",
            "PresetMsg": "CLASSIC HOMECARE: Complete training by the 20th to keep your certification! Go to the office if you need a computer! start here⇾https://bit.ly/reminder-HHAX",
            "OrgName": "Classic Home Care",
            "campTime": "2021-12-09T17:00:06.869Z",
            "CampaignName": "Classic Compliance 2021 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C197",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compliance Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 239,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 494,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:09:08.931Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Assisting Hands - Tinton Falls Q1 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 7,
            "CampaignID": "C234",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 472,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T04:55:35.840Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "SI Homecare- 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 5,
            "CampaignID": "C590",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 841,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:18:55.317Z",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "OrgName": "M&N Home Care",
            "campTime": "2021-12-08T17:00:11.056Z",
            "CampaignName": "M&N Sexual Harassment Training 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C64",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sexual Harassment Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 240,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 874,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:43:03.222Z",
            "OrgName": "Alternate Staffing",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "HHA 2021 - Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C224",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T20:43:20.603Z",
            "ProgramName": "HHA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 246
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 1,
                        "msg_accepted": 0,
                        "msg_req_attempted": 82,
                        "msg_req_sent": 82,
                        "msg_scheduled": 0,
                        "user_add_success": 82,
                        "user_add_attempts": 82,
                        "msg_delivered": 26,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2cd674b2c73a60990c851494b29e29c0",
                            "FN5f9d46cf44c3469ba170b6c9b750afd9",
                            "FNc387f924bdb6d71967112b42c829e6bc",
                            "FN6bf9fd3bdd6d53fc1208a97add3e2ec3",
                            "FNb1c49b5c87e39f595ef12410004c74df",
                            "FNc78e7eb67000db37df05e185889e6125",
                            "FN8ff9302712fdd58ad9db5d2d9ab9d7ab",
                            "FN4ce7d5bd94e77166920a0c0fb531dd82",
                            "FNff60e1e316c2058d1c7d12e333a605cd",
                            "FN8380915753df9bfef2619590254f72cd",
                            "FN5965cb0cb9c1db5d2e2ed980b8431007",
                            "FNd0b3ddefb6e5d0f57314a021af818e81",
                            "FN996ecbf6502803838279e95d38f3c38a",
                            "FN63bbad2fa62381eed06f81e7166212d1",
                            "FN6c5581c87d0930229d31b8d4e78ec4d8",
                            "FNce369e98c9d3672a0c7e6d39d21b9db8",
                            "FNd597feb441fa6987fa895df5635c2094",
                            "FN42f7e82b89ac8c2b3e42bc46bbcc392b",
                            "FN302d6bd9164f1596f016835f7d7b3493",
                            "FN6106cd623172fd4a13c3dfd9eb54864f",
                            "FN80eb6df32522a6ae180049509876db7f",
                            "FN1f732584ba055f16128553a75fa623dd",
                            "FNcc9eb2641f0fd33ed0485cf9f30843ac",
                            "FNddf612c5fb0b7276764263de7c1c9cc3",
                            "FN3077dc702ece822e0043458aad0730f6",
                            "FN9ba02a1a2ac2a1d69f260c86d8bc86aa",
                            "FN9bacd3d9501ed810b593b44376a71e7c",
                            "FN00e23472a87ca540a54ed6eff714ee72",
                            "FNe4f56182119ce55d828a86d4eaa4346c",
                            "FN15de75299b558cb384a6cf729a1949e8",
                            "FN1e7ea54ac6d3c282d39fe03fa1c52d0e",
                            "FNe5b20d000e94191168ad0877cbcfae66",
                            "FN8f9c53386e3b1f0fd71b10bfac872065",
                            "FN9795a3321f7e77a99babb895bcac4e8e",
                            "FN2b6bc3bb3acc3580e8d5fbd9ef0fd204",
                            "FN93379f693701bf7aa6beae15359c448a",
                            "FNa940db6e068cac3aedc193c29dd02ee7",
                            "FN8490cf1c04b40dd2ae0ca189a18ce4e7",
                            "FNe71344653aab4c816822d45d3bfd2e04",
                            "FN5e635f6db997691f2f971456862d09c4",
                            "FN0657c95f718350af76a25a3f09d79ad4",
                            "FNcc7b978eeb01eb31eeb42563e78a0f31",
                            "FN86d62a1c93f63809f3a6130551dfdf85",
                            "FN32a7828d051541637d0d0fb2d9f51a33",
                            "FNb6abe030e3d550aac9047cad102a2089",
                            "FNe00fa4e35a3800f06511ad1ce89b6731",
                            "FN523e9a707eaed869944abade8f9c4113",
                            "FNbea80b4333e5be3bd26ef9d3bcab453a",
                            "FN00ca88ff54a1454948f5b67fa7a4c214",
                            "FN072177bb16d356dd65f21635f5a0b3a1",
                            "FN0cf98411aee90a94b211188ed71e10ae",
                            "FN5d5a583c3df7da62a73debf2bbcd714a",
                            "FN6d8aaa893f389eaa0658c7dcd37abb95",
                            "FN258600d2eaec347a196ca9f9280b338d",
                            "FN9c7fae076d4209bf9989f00d21ca29ff",
                            "FNacbab7590ad434e377a0d09823e63c47",
                            "FN521af460e4640700ef78b01569eede5a",
                            "FN6a6e8c6cb30b9544c61a843f621d7ff8",
                            "FN3fd596d36b5c7ee6adfb62df4fe20f5f",
                            "FN6db710486352f914b4b9868128a1d1d8",
                            "FNaca1cf98df607311ad42a25e243bb136",
                            "FN72038917f42a1c1cc17b7e7825e7d4ec",
                            "FNaafd800732138f89fbc39450b7a9a195",
                            "FNe3dfb0fbf55a60f1fdac7551d5fa3190",
                            "FNa1dddc509fea378aef1d5aa3380953f4",
                            "FN39e5dc88b6bc84c9a0bc7803f8df1785",
                            "FN7d24877fdf1c7ebc00bdbc92c4e869c0",
                            "FN34b5bb03412272f679bf691d6777155d",
                            "FN8e509cbb7f760464af2055a3c2a32d8a",
                            "FN34462cc6df14bd18c88fdfa3289ceeb9",
                            "FN22f062b3f874b6640fd5d84f76438c88",
                            "FN264e9ba4cfa15f60e9b2165881dea657",
                            "FN4ddaadbc23e02d297069f27858a93fbd",
                            "FN993cc4ebed0b117dea1a1d389ce935fd",
                            "FNf2142d3ea99e7c34a82ae52acddd20d8",
                            "FN091a4a8e255b70220d86bc622d94a9c4",
                            "FN3590a42ce141429cb026153eb00f4c74",
                            "FNff0f9698a8e861171f6e38fa9ff8a0ef",
                            "FNda1d761ba5ddcc799aba840ad5ded565",
                            "FNddabd3a317e78e98cb023de191e4cf46",
                            "FN465c348300d1d3dd7a650a4ca4682557",
                            "FN47b96b4bcb85dca78db6bf65a8b24731"
                        ],
                        "msg_queued": 42,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 7,
                        "numOfUsers": 82,
                        "msg_undelivered": 5,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 408,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:02:15.539Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:44.715Z",
            "CampaignName": "Recco - Class 1 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C393",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 1 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 148,
                        "msg_req_sent": 148,
                        "msg_scheduled": 0,
                        "user_add_success": 148,
                        "user_add_attempts": 148,
                        "msg_delivered": 43,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN0785bbcce7b3606792f607d3549ab8ad",
                            "FN565325c118515f6480535056bf589388",
                            "FNb1e47992d407bdf50ac74cbf1cec3fc5",
                            "FN74bbbe8a8549601cbfdf3d52c3d82b75",
                            "FN2bb7ed401950ab9c82cd28958a96bdf3",
                            "FN7229b4d190fdeea3f9e84531f6bd04bf",
                            "FNd3f55c7f1764262d0a93094ed9974e55",
                            "FN34f4c17c6edabb2c3506d729a8cd270d",
                            "FN17e08c56b52bb13beebca32963d4206f",
                            "FN0fe9a4e047022b09bf6bfc568042f98a",
                            "FNcd79b9b2bfb12a2a148e726fc363acd0",
                            "FN26b630967b233e99acd1f2e550b85b66",
                            "FNfe3cecd9d7f9acaebb7cd6e91b69ac63",
                            "FN4b711f30265c96b40fcb82f16dcdae8a",
                            "FN2f54b37b3ecded283360b500e828604f",
                            "FNecee267fd65d6772f4d1c61fc5956506",
                            "FN93dca95e0c03db1c94ed7fb43074f4c7",
                            "FNc360b2357cbd011975a29d447fc6c589",
                            "FN0912f6e38d070a4cbd1a387ba12a6866",
                            "FNd4bfb5d73d166b40773f72125ea304b4",
                            "FNee6f8fec5a3d72653a41010ac425a243",
                            "FNfea1adca0b546383412e1f921673cbe1",
                            "FN481d518939ffa21a5a4b955f4b51fdfe",
                            "FN4321ac5031810bd75573e227cb32f6bb",
                            "FNd1c58441cfd7b90dd404b1d5ed132129",
                            "FN7b976dd26c281361c3f3915e4c3b6a84",
                            "FNbbaa4cdb15e7012ac5fc415402a0dd76",
                            "FN9dfce4f2cddbc7527d43114a5b5e9a84",
                            "FN8b447aefa2f65c2e5c628df990402e00",
                            "FN62708d7ac5898cb1fe741b2a75cd4912",
                            "FN9b0d3f6198b298a232d64f32f51a33ec",
                            "FN097ca3a7067b5d6d4be20b275bf8e1c5",
                            "FN3c9ee115fc1dbdf12fd9eff0908ba9e9",
                            "FN1264cc0c59ce810a087025068dd6c011",
                            "FN6ce8e39e434d114aa02b5b3aafed08fc",
                            "FNc5d833ee8cbce7ca92206ff1f3221f44",
                            "FNc7df29795875e8c67c9acf836071db2e",
                            "FNdc998c22bb870c6a7ce356d675d7718c",
                            "FN442404be7aa61fc3abff25c5b8508b0a",
                            "FN3b52abca9bcbe71a8b6785d885da3cf3",
                            "FNa5d8c29643348cc2ec592dc4030b8c59",
                            "FN82d5656f58da7b1be5469656598153d5",
                            "FNd8a16b5ddafde73678b2f8ed55aa6a94",
                            "FN09ba325e8ae4c823a643adc899bbce2e",
                            "FN06099d93010d29b7e8a875ec2ce3c839",
                            "FN430475072f37c0ac681bb1c394b088cb",
                            "FN4c0d1508e062780cff2df14927992462",
                            "FNcca445f1f1516ddf663d3c7530d464ef",
                            "FNa80535bd233b88bc73b24feea9e49551",
                            "FN86b1397008d7ebee91d8c526e1335284",
                            "FN92e0e70c6703d842fb6f22bfc04a91bc",
                            "FN669e4bc08a7c7226a0d355b104d50a2c",
                            "FN5f83f6455d26e9f23f54c07e4fdbe971",
                            "FN24368452973e3f906bbb380160cfe13b",
                            "FNd3caf5aae2205e26c1307bf0a02148be",
                            "FNa64c0435702237568653fbd05f6d6385",
                            "FN5873b93f836ef6ebd6fc7f53c1f728d1",
                            "FN41bd279e1f797b06cc1b9518bf9d29d0",
                            "FN0c2c8f14f630f51c869fc593ce92439e",
                            "FNc750d6d8987a441a583eadcf1747746b",
                            "FNd0158c9795ca7e75305794b40946e36d",
                            "FN3ce6b0bd98046fa32bd039c758c46b91",
                            "FN931762270932cbd614c7b794d25e2e80",
                            "FN9f62341ac0899e64f5a814a0f7489627",
                            "FN1c95c53a01b0a3cd086de3c726de1856",
                            "FNaa12c67f24ba58a81269ad84b8f57a11",
                            "FNeaae56465084bca48e215a886ddb3f09",
                            "FN22c0b961b79a23fab085d81e134a95a8",
                            "FNd96d63077d8171969e4a753c8897db99",
                            "FNd25835252e774bee6ca60418d7d6fac0",
                            "FN953f606a66f60b23f1e54386e64f9d63",
                            "FN31442a996aaf66c46685fa5c49808b6b",
                            "FNc96cb29fa4f67cc5106e46e320238b9e",
                            "FNa7ea482bddee0e545083fef1e7367447",
                            "FNbff04d2a2aa725ef8d3af47136ff9687",
                            "FN94a0faa8ec698fef7a145db6bcf5f2b2",
                            "FN41027ebdedb8e864e4954ece49dcca8f",
                            "FN2c66eef54e178ec4b88f6440d321fa13",
                            "FN7bc902195745c1d6be2566ce7095d024",
                            "FNe0a936178f8213521c04e250668d4439",
                            "FN13538a471e37c9cfbc7877dfcddeebb5",
                            "FNd22731d2cd4cca30aaeb24612bb9dd13",
                            "FN20ae7b4cc4a2f64e4a725ee80f33c588",
                            "FN95f19e1ad08a2f4e79a20b5297482254",
                            "FN11574aada3ef87daf267c792d6e1cb00",
                            "FNae866742afe7cc4473ff9e4e37868019",
                            "FNe88bddcc421c0b69bc478e30fa343c0f",
                            "FNb725a1a6504106b47390a7538579bf5b",
                            "FN6a66552ac9f34026fdd80588de2699ab",
                            "FN3e6bcb4f60ee5298e98aa242ebb84eff",
                            "FN8eac6f9080bc387f66bca0e060f2cd9e",
                            "FNb927dcfcd1ce504148d30fcba0499744",
                            "FNf2f33e7ae15ea8e9f5730f544b666598",
                            "FNe43750233c352cf3fa60458e3eb2be14",
                            "FN1cce91cd65e453bcbc2168c413263394",
                            "FN53a41660e75b1e7244d4350e91d14ae3",
                            "FN9e47de8e9f8e18030e7c22133627ad84",
                            "FNfd5f8db412f679dae9f75a7adf8a38a2",
                            "FN57383fecddd30213d826e264a0f87bb6",
                            "FN90f5032c2c12e31ec92c495aeb03d37c",
                            "FN5b3eaee7d14a15779b4c799b751eb9e0",
                            "FN9e752cceda0ef497a55e4cabebdb0dda",
                            "FN60b42308c243ded892819d4563c55f00",
                            "FNa3db065340173d91e38713c17fc7f78f",
                            "FN3bdafaed926692d8c7c17f0e2d7d2190",
                            "FNe6faf723e8693aeb1e7fe0aa04ef8e2a",
                            "FNe227dc1f7e50205f9253cf959979af02",
                            "FN94c9b8509fbf8aae6c5703b556ef762a",
                            "FN7c42279f77d2e1e9d5a9340368203b28",
                            "FNa5db2a1764a4702417e8c1eeda7288a6",
                            "FN519460f218648d6221ab0b52c8e223ef",
                            "FN1acc72b836e77f5c7fc84747bd69633a",
                            "FN44b9d4e9b332d508c8bd047537680d99",
                            "FN0c0f7308dacbca80d2c760451fce86cc",
                            "FNe4d563c6424e7a7483869e4e6099815d",
                            "FN20adfbaead97a666e84769bb113eefbc",
                            "FNd8f546ba79e92a2368b776f280f8acca",
                            "FN37cc3877e11946b978632b72938a568f",
                            "FN99d27d5584654c81e558bc7db39e60df",
                            "FNc99492044fa92351c56d852e039ccd00",
                            "FN9946e57b437349aefaf86b7311aac3bc",
                            "FN662f4357903af1d0d0f10850825df535",
                            "FNf6fe76ea9b14f460bad977ecdbe5a4fa",
                            "FN85e9f287825f90c7d1105871ebee53ce",
                            "FNb7ba87f0978e7d9ae2b42622124195a0",
                            "FNff3e99628b9da40e77c06988f112039e",
                            "FNb1af0bb54a3a6d05a827f998880c1981",
                            "FN5806e992a1041e069f9453b7fd4d7539",
                            "FN332438a719f93fc0518d4b9d1b76bf56",
                            "FNbdb901c11612323e04c81027f7737287",
                            "FN6360ef4c33c5056c3794fb55e1a6615f",
                            "FN05bc38a987800bc77f3774ba56a54237",
                            "FNeb7b73cf559ada14c50a13f000adacfe",
                            "FNc87591a0c4745961a767bbc7fa1666e9",
                            "FN92db90a5778805f6b4195f2174df9696",
                            "FNea5d092568bb40a0643c3408c138fbd7",
                            "FNc14cb78263f985923d2aa950293fecfb",
                            "FNad8c1e611d9941ff004b535f5ee68f46",
                            "FN9d54669fbd18b5593ed51b71b75ea039",
                            "FNb7b4b222a6b049b82da36999ea2e37a9",
                            "FN0a0c87371fc975c93649f58c448171b3",
                            "FNcabb3317d763bd0a2496337e1b3ca8d3",
                            "FNffad38d5fd51331ce422e06c5fd60476",
                            "FN29c393507f210132b14faa45dc240bfb",
                            "FNff1a4486ec7adc6c93c84095395b3c27",
                            "FN61097f171343ca48b8f0bd1b2774f3a7",
                            "FN10e5459d5f864744cce29c82c9b345c1",
                            "FN726de259dbe763c02c18fa5040310aa3"
                        ],
                        "msg_queued": 88,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 13,
                        "numOfUsers": 148,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 963,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:11:49.656Z",
            "OrgName": "Open Arms Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Arms 2021 Q3&Q4 inservices None",
            "FrequencyInDays": 2,
            "LangId": "null",
            "CampaignID": "C441",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T22:18:21.976Z",
            "ProgramName": "2021 Q3&Q4 inservices",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 187
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 285,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:30:27.140Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 4 - Experienced Aides - Chinese",
            "FrequencyInDays": 4,
            "LangId": 30,
            "CampaignID": "C488",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 4- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 521,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T22:51:12.004Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T16:50:14.976Z",
            "CampaignName": "Sophie Q3 Patient EN 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C285",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q3 Patient Rights EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 874,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:44:21.883Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "HHA 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C225",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HHA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 925,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:51:54.163Z",
            "PresetMsg": "REMINDER! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:14.608Z",
            "CampaignName": "Community Care PA 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C84",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA Class 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 282,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:01:31.409Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:34.123Z",
            "CampaignName": "BrightStar Care Program 1 - Experienced Aides - Chinese",
            "FrequencyInDays": 4,
            "LangId": 30,
            "CampaignID": "C470",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:43:11.751Z",
            "PresetMsg": "REMINDER! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:31.528Z",
            "CampaignName": "BestChoice EVV-HHAX 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C104",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 37,
                        "msg_req_sent": 37,
                        "msg_scheduled": 0,
                        "user_add_success": 37,
                        "user_add_attempts": 37,
                        "msg_delivered": 14,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNaf6f43853d74231bf187987f61284f4e",
                            "FNdfb0220afb7f397e716b65ca47595019",
                            "FN8a20ea0c3cfe076e883549bf8894dba7",
                            "FNb4a244fed5167daa71836b2249ba11de",
                            "FN026a78bfc2a0d60c01a6df7405d5897c",
                            "FN7f0f58be784f877f68c12b02491d5f04",
                            "FN63f8e32fb7b3c7ac7beb539009ea8d6e",
                            "FNc8c12b6b4c227f1cd44c54bb81ebb662",
                            "FNc4f47e7d2c871045a4b50688439916f7",
                            "FNf8c83c56fbecab53cbd0df7db65b6d00",
                            "FNbac966e4df69208c449ab00c25f08fc0",
                            "FN0c1b84dd3227b60895a701eed18c3cdb",
                            "FN731cf2732100a82ef952c40525ff8a06",
                            "FN5087c99ebf7c4364b97bb8559bf34323",
                            "FN75b6926737e5dd7e539ca9efb018bfde",
                            "FN0f772715a9f00088941b095261b9fe55",
                            "FN5921fbda7371495bdc488443cb571573",
                            "FN9b5f54d28e7b6923d70964230d771afb",
                            "FNfe81c6668ed7d24cc9bb23cb2e3c7cae",
                            "FN41357af8038b238a2db5b4a30c4291da",
                            "FN67dbd727f2cdc3e78a53684a8ebcac4c",
                            "FNffe50ff9d9e158b7b6509cd7a3f68768",
                            "FN6b7d738b1d7fc11adb952b078fd2ec14",
                            "FN7f215dd7d65f6266912a93b13d837a5e",
                            "FNe34381c1973ac3bab609f3e4f078a79f",
                            "FNb3fdff1f1192267d793ddc6cc38f0cf3",
                            "FNd019c2c8403ba6f642adcffd7fdfa0de",
                            "FN1cc29b5e067e097323128a911c2a23a5",
                            "FN97e11ee347041dd1e0b72eabf95b5c33",
                            "FN15ca15782ad62e73900f2b9e7ac6dee0",
                            "FN50f53c6fbfa0c632070743e7e13f2eaf",
                            "FN2ffafd1be8465c829dc5ef76a065576f",
                            "FN4045462ef8c96a771842b5a412d16858",
                            "FN9502692aefdb1e371d2ac90d7b82af6f",
                            "FN3c37ba21c927115c792baa8dca8b1750",
                            "FN72b032a81ecab07c3bcdf6915fc20b26",
                            "FNe1c1029483150d56148aeb1842b38185"
                        ],
                        "msg_queued": 17,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 6,
                        "numOfUsers": 37,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 681,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:31:50.773Z",
            "OrgName": "HDA Care",
            "PresetMsg": "От HDA: срок вашего INSERVICE - вы не соответствуете требованиям. Войдите на https://bit.ly/GetNevvon или здесь: webtraining.nevvon.com. Вопросов? https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T14:00:16.955Z",
            "CampaignName": "HDA Class 1 2021 Russian",
            "FrequencyInDays": 1,
            "LangId": 134,
            "CampaignID": "C323",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 1- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 742,
            "DaysOfWk": " 2 5",
            "TimeCreated": "2021-12-09T16:09:04.116Z",
            "OrgName": "S&A Unified Home Care",
            "PresetMsg": "URGENTE S&A Unified Home Care:\nPor favor complete su Parte 2 - Programa en servicio 2021 lo antes posible.\nClic aquí: https://bit.ly/reminder-HHAX",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "S&A Part 2-Inservice 2021 Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C122",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T15:37:40.444Z",
            "ProgramName": "S&A Unified Home Care- Part 2 In-Service 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 164
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 284,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:24:14.472Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 3 - Experienced Aides - Haitian Creole",
            "FrequencyInDays": 4,
            "LangId": 56,
            "CampaignID": "C483",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 3- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 683,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:28:19.075Z",
            "OrgName": "HDA Care",
            "PresetMsg": "REMINDER! HDA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T20:30:50.682Z",
            "CampaignName": "HDA Class 3 2021 Haitian Creole",
            "FrequencyInDays": 1,
            "LangId": 56,
            "CampaignID": "C318",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 3- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 875,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:55:41.366Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "PCA 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C232",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 727,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:15:38.619Z",
            "OrgName": "Addus",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: ADDUS: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "Addus 2021 - Q4 - Russian",
            "FrequencyInDays": 4,
            "LangId": 134,
            "CampaignID": "C214",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T20:28:00.102Z",
            "ProgramName": "2021- Q4",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 165
        },
        {
            "MaxProgress": 99,
            "ProgramID": 528,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:13:29.905Z",
            "PresetMsg": "\"URGENT From FADMO:\nComplete ALL In-Service ASAP. All training is due by 12/31/2021. Get started: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:44.874Z",
            "CampaignName": "FADMO Q1 In-service ES 2021 English",
            "LangId": 41,
            "FrequencyInDays": 1,
            "CampaignID": "C129",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:57:40.495Z",
            "PresetMsg": "REMINDER! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:59.246Z",
            "CampaignName": "Angels EVV-HHAX 2021 None",
            "LangId": "null",
            "FrequencyInDays": 2,
            "CampaignID": "C90",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 652,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T16:00:16.101Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "REMINDER! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T16:00:41.326Z",
            "CampaignName": "A-Plus In-service1 ES 2021 Haitian Creole",
            "FrequencyInDays": 5,
            "LangId": 56,
            "CampaignID": "C385",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 ES",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 712,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:59:32.240Z",
            "PresetMsg": "REMINDER! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:47.404Z",
            "CampaignName": "Compassionate Hearts Orientation 2021 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C171",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 624,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:19:44.536Z",
            "PresetMsg": "来的温馨提示: FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:49.624Z",
            "CampaignName": "FADMO Q2 In-service EN 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C136",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 875,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:52:02.541Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "PCA 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C229",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 719,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:09:58.536Z",
            "PresetMsg": "来的温馨提示: Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:23.522Z",
            "CampaignName": "Greater Love In-service2 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C184",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 795,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:26:38.567Z",
            "OrgName": "Classic Home Care",
            "PresetMsg": "Classic Homecare: complete sus lecciones antes del 20 de diciembre! La oficina tiene una computadora disponible. Más información aquí⇾https://bit.ly/HHAXENES",
            "campTime": "2021-12-09T17:00:27.941Z",
            "CampaignName": "Classic 6H 2021 Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C199",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T17:35:13.165Z",
            "ProgramName": "6 Hour Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 239
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 33,
                        "msg_req_sent": 33,
                        "msg_scheduled": 0,
                        "user_add_success": 33,
                        "user_add_attempts": 33,
                        "msg_delivered": 10,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNddcf2149492729d33f392976d748612e",
                            "FN46cb5a19a79ad35907853b50672a8011",
                            "FNf38e64f6fea00ab4fa5f462627bebb57",
                            "FN34ada907b1f645ef9f07ba617a264ef6",
                            "FN977c1349330557f92f1ab173706b429c",
                            "FN7d26b88febfa2772d99b02e70c230100",
                            "FN30ab857947bf67dd9994bdbb59bb4cad",
                            "FNe3978c98040f1d759e5db161f4bc57e7",
                            "FNafcf509be92338a57023a5ea2561e280",
                            "FN8df5035fc4e39d366505b2a47a64b00f",
                            "FN8fb09e3a8a2c2e2b54475d228bfbb950",
                            "FNc7c9c00f91175302a4454b7a23c0ae41",
                            "FN644c8e5e27ce4c86596f346f8915c367",
                            "FN6a5056dc521a37796697ea594f228b64",
                            "FNa83c0787aa2538f0b31c8c4c19398a04",
                            "FNa93cb671f4c439133323a11bbef87b9d",
                            "FN0bd1db1c9764e2fcfe54ff2098222ad0",
                            "FNc20dfebbcd6cb25c0719395705955cc0",
                            "FN5b2194a1ad7aeae354fe55346b2b9aa7",
                            "FN9e3d597cf4841517380b59533b416d52",
                            "FN899a424ee7376db026210f5e65957ea5",
                            "FN8527c90ad7eb01f60eac43f761ecd3a6",
                            "FNee341830c87d94a5c1c971a49ff8fc8b",
                            "FN0926ed3aa81da715e8bac52c4d9d6b95",
                            "FN0396866de3eb94bd0480ccbc82e4b2d4",
                            "FN17c7c85459efb41e840e735023737d77",
                            "FN0b953963cee4be073af7f53a18d60a4d",
                            "FN03b42e92166ac15884f47692b248fd3e",
                            "FN457dfbd737bc60aafa7662c07c69e86e",
                            "FN77be4765669b139fcfe2b0a34149e7fb",
                            "FNbc8321afca621eca675acd5b1a0dcd94",
                            "FNa0f5fb88f8e25a8343b9b4b5b6e724b3",
                            "FN43fe9bcff5f1550889d6759d87468956"
                        ],
                        "msg_queued": 16,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 33,
                        "msg_undelivered": 3,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 623,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:16:56.612Z",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q2 - 2021 - None",
            "FrequencyInDays": 4,
            "LangId": "null",
            "CampaignID": "C506",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 136
        },
        {
            "MaxProgress": 99,
            "ProgramID": 875,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:53:22.909Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "PCA 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C230",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 718,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:06:01.351Z",
            "PresetMsg": "REMINDER! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:21.872Z",
            "CampaignName": "Greater Love In-service1 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 1,
            "CampaignID": "C178",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 718,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:08:00.015Z",
            "PresetMsg": "RECORDATORIO! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:19.384Z",
            "CampaignName": "Greater Love In-service1 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C181",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 719,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:08:45.031Z",
            "PresetMsg": "RECORDATORIO! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:07.333Z",
            "CampaignName": "Greater Love In-service2 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C182",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 630,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T23:11:37.565Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:28.945Z",
            "CampaignName": "Caregiver Provider- Annual 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C538",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- Annual 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 528,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:15:35.377Z",
            "PresetMsg": "来的温馨提示: FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:39.399Z",
            "CampaignName": "FADMO Q1 In-service ES 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C131",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 740,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:34:40.176Z",
            "PresetMsg": "НАПОМИНАНИЕ! FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:04.603Z",
            "CampaignName": "FADMO Q4 In-service 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C151",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q4- In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 263,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:37:55.587Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 1 - Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C455",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 1",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 719,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:09:18.900Z",
            "PresetMsg": "НАПОМИНАНИЕ! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:46.222Z",
            "CampaignName": "Greater Love In-service2 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C183",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 653,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T16:10:06.402Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "RECORDATORIO! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T16:08:25.733Z",
            "CampaignName": "A-Plus In-service1 RUS 2021 Spanish",
            "FrequencyInDays": 5,
            "LangId": 149,
            "CampaignID": "C392",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 RUS",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:47:22.748Z",
            "PresetMsg": "来的温馨提示: Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:49.766Z",
            "CampaignName": "BestChoice EVV-HHAX 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C110",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 915,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:42:37.006Z",
            "PresetMsg": "REMINDER! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:48.215Z",
            "CampaignName": "Community Care 2021 LPN/RN English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C73",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 LPN/RN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 33,
                        "msg_req_sent": 33,
                        "msg_scheduled": 0,
                        "user_add_success": 33,
                        "user_add_attempts": 33,
                        "msg_delivered": 8,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN290add83f9242325da2001ab7dc61702",
                            "FN38fdf88b0493c093598a4141ac88473b",
                            "FN3fc6c0b8b22f32f1618df89f4e215b56",
                            "FNaa77457f253dec61c81319e39dbb2c08",
                            "FNe7c83ae9785b40fda7c8d34e31a9a0a0",
                            "FN86846333528dbe6e5e58287882fa3fda",
                            "FN1625ad9ddcbf645bc6ed37bdf6926083",
                            "FNe765c716012464b574775af4ed28712c",
                            "FN014c5105a158d1b3b70427b1874707cd",
                            "FN7ec5f5f016b1e4647ee29a0c6a034c80",
                            "FN67909db80e5b4283d020afe4a37a19c0",
                            "FNe67568115d0e4846d305307863535fc8",
                            "FNa609b43e0e424e3c33a7a99250abf778",
                            "FN363fe058beb600465cc91c881bf72e51",
                            "FNcffbd1fb4dca4d4614a441dc934dd610",
                            "FNe4d53c35279713ca7c9fe1ba696668ca",
                            "FNb88b581845ffc58ce0742e8fa9a7efb1",
                            "FNb72736254077a48dfa545b6f41dab5a2",
                            "FNdc645b81bb3f491ba391e5ab80062abd",
                            "FN04713e19b9e4b17809e4fbe9514a4e47",
                            "FNfe2c3621fe170e51c4d388742740bcf2",
                            "FN5de57b92934283b91a8d12008d29aa9c",
                            "FNcc0ece79b2f6c4d3c9973bff248f60e8",
                            "FNec20d7c9dc125879d4788045bf1546ec",
                            "FN76f0e53bc956a44cbfc70fc7c8b90971",
                            "FN440f4402dba52b229350f563a0a709fd",
                            "FN822be3a58ddbbe68eb8125727b23287c",
                            "FN68a94083c885f0c77dc03eaa7d8ae4ba",
                            "FN5d984149f1bde617db118e2c71046fde",
                            "FN7fae812534da60b3bc81617f00e259a7",
                            "FN1b30aa80d0d36c9c510f2209318e019f",
                            "FN727b4cf0e92aa254a50d077e44baf758",
                            "FN32f935364338f831e89b03bd1df5c3a4"
                        ],
                        "msg_queued": 16,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 5,
                        "numOfUsers": 33,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 683,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:19:43.927Z",
            "OrgName": "HDA Care",
            "PresetMsg": "De HDA: Su INSERVICE es debido, no está en cumplimiento. Empezar https://bit.ly/GetNevvon o aquí: webtraining.nevvon.com ¿Preguntas? https://bit.ly/NevvonHelp\"",
            "campTime": "2021-12-09T20:30:10.191Z",
            "CampaignName": "HDA Class 3 2021 Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C308",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 3- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 523,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T22:59:57.961Z",
            "PresetMsg": "НАПОМИНАНИЕ! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T16:59:22.713Z",
            "CampaignName": "Sophie Q3 Patient RUS 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C289",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q3 Patient Rights RUS 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "07.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 16,
                        "msg_req_sent": 16,
                        "msg_scheduled": 0,
                        "user_add_success": 16,
                        "user_add_attempts": 16,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN0bd8e4c05ac4dd6fbd150e83bbca9c79",
                            "FNaa855006cc36882bcd45ea0a874e2e33",
                            "FN7c00b9b521b0b489b85bddf8349720ff",
                            "FN57f0a87729bef442020147e1eebecc4d",
                            "FNed3892b36c6ba3a800f2a36933283d8b",
                            "FN958cb115c6f4741544bc0528a6abf922",
                            "FN32ecaf069ec9f5df91108bb020c5a92f",
                            "FNa1f27711bd7d8e10986211f60ed90936",
                            "FN32ffde9e74fecb49758d299c87870b40",
                            "FN27a686e6bfd25fba5f1bd6910e0fb850",
                            "FNe85bc114ad01e12dd00f9379436fa980",
                            "FN4f1c4b3718c8252f396f628fb621a934",
                            "FN33ce3be500cb8d8940d0d56387886fae",
                            "FNb55282e6552b630dd1fc440d23a40cf4",
                            "FN515ded03b03459b365fb53ef6dcaa838",
                            "FN8a27badefa2a0299d2baad88c81cf02f"
                        ],
                        "msg_queued": 8,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 16,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "08.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 16,
                        "msg_req_sent": 16,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN5a74c90ae04dd9dfabe972b4a8afe690",
                            "FN96385e5a8412dee110c733dfd19de611",
                            "FNcb8ca0ea2b0b3dcc1926e3c215a2052c",
                            "FN396dd47f00385453eaad0fad8071a6cf",
                            "FNdd19050da82d959d9682bf88a7069a9b",
                            "FN037767f90bf6462e05cdff481b7fc2d2",
                            "FNc89337d6689f5c56c5dab2634a622df2",
                            "FN4bca56490d8f36ad5a9987143d346072",
                            "FNb97294dc62dac399bc8026506db5c11a",
                            "FN2fa6a11cba438fb93c7ba8c7ed286737",
                            "FNf48db7b6c54720f258abc8704801daa6",
                            "FN442ddf62aa287cabb28bb9e41548f441",
                            "FN9c9e1da5412e07853bd40985e12952ac",
                            "FN6e1e6cd511085d2961cf6e1df79f531c",
                            "FN7936ce0c9b1038d9a4d9ecd0c771bba5",
                            "FN483bc0e8dca9e24a581df8526880c346"
                        ],
                        "msg_queued": 8,
                        "user_skipped": 0,
                        "user_update_attempts": 16,
                        "user_update_success": 16,
                        "msg_sent": 4,
                        "numOfUsers": 16,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "09.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 15,
                        "msg_req_sent": 15,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN231ca98872470f27c686715a896260fd",
                            "FN6e8b35084e35da15ee444ebac69222b1",
                            "FN9a6451967a191e69bebbe1b3bec8fa75",
                            "FN9bddd5595664b091ea84a6f205f6114d",
                            "FN79af4007d67588a1216a5468143c6f55",
                            "FNd28016b96c58165c91b179523f4fd72c",
                            "FN5eaebe3126b7266b2cd0726abf94afc9",
                            "FN965366b68e40846388e32765bbe4c98e",
                            "FN7b08356e78895c2bc6a847113d2f8d1a",
                            "FNef05c40657b4db6d5037d284a16afc61",
                            "FN9e7bf0fb418f9301a2eea717e4ec3723",
                            "FNc2b6a22a23c2094fe1bdd9aa9764e5e6",
                            "FN08b96ba80b9f9d9bd5f928ac042d6189",
                            "FN9f0a5cb6fe388d7d19843d7444683fa9",
                            "FNa3eab641417bc9e68d9d6793937d7d8b"
                        ],
                        "msg_queued": 8,
                        "user_skipped": 0,
                        "user_update_attempts": 15,
                        "user_update_success": 15,
                        "msg_sent": 3,
                        "numOfUsers": 15,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                },
                {
                    "date": "10.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 14,
                        "msg_req_sent": 14,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNea97fc6c467c9dcdcb641d712f83ba31",
                            "FN99c704aac696e945937d432ebcfbe1e0",
                            "FNcff43da29888ab4924ca239493d894a5",
                            "FN5cd12ec2f505889c82d67df759781d4c",
                            "FN63f3b025295725edfc527867d32394cd",
                            "FNfb07bb84ceefb2005560d68e9dccee37",
                            "FN41eaa763ff0abc4c56282100e06c47bb",
                            "FN5c389b07f73023e9ea74d81aac81b5ca",
                            "FNc4a9cd4d592fdae020b2f2b13d2eb13f",
                            "FN3ce7c818dbb211d6edb37a1e149bb8a5",
                            "FN4b77e30a5ab32cd1105c20a3a069ff45",
                            "FN6b0e35d96c674aafc2f9965927aec129",
                            "FN95b790b3d070c2928282e815b7dda452",
                            "FNcd9b69477c4cd19e711e9143d9fcf4f1"
                        ],
                        "msg_queued": 6,
                        "user_skipped": 0,
                        "user_update_attempts": 14,
                        "user_update_success": 14,
                        "msg_sent": 4,
                        "numOfUsers": 14,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 279,
            "DaysOfWk": " 2 4",
            "TimeCreated": "2021-11-29T21:13:59.400Z",
            "OrgName": "Link Homecare",
            "PresetMsg": "URGENTE de Link Homecare:\nComplete sus 12 horas de 2020 en servicio en Nevvon antes del 31/12/21. Si no lo completa antes de esta fecha, no se le permitirá trabajar hasta que lo complete. Empiece aquí: https://bit.ly/NEV-HHAX",
            "campTime": "2021-11-29T17:15:18.845Z",
            "CampaignName": "Link 2020 12HR incomplete - Spanish",
            "FrequencyInDays": 1,
            "LangId": "",
            "CampaignID": "C32",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-06T20:33:55.862Z",
            "ProgramName": "2020 LINK HOMECARE 12HOUR INSERVICE- Spanish",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 94
        },
        {
            "MaxProgress": 99,
            "ProgramID": 711,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:54:43.538Z",
            "PresetMsg": "REMINDER! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:09.022Z",
            "CampaignName": "Compassionate Hearts PA In-service2 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C164",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 521,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T22:50:03.697Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:00:18.750Z",
            "CampaignName": "Sophie Q3 Patient EN 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C284",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q3 Patient Rights EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 651,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:49:17.084Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "REMINDER! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:49:39.972Z",
            "CampaignName": "A-Plus In-service1 EN 2021 Haitian Creole",
            "FrequencyInDays": 5,
            "LangId": 56,
            "CampaignID": "C378",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 EN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 22,
                        "msg_req_sent": 22,
                        "msg_scheduled": 0,
                        "user_add_success": 22,
                        "user_add_attempts": 22,
                        "msg_delivered": 8,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN4a1ee9aec2bc3e8a58163e369d26b974",
                            "FNb4c9f9820d436a385f1a63b7929e3093",
                            "FNc5010aefc6f85d4222f88a53f298584b",
                            "FNbf0f281293ae5575457dbedcdb875d90",
                            "FNfa81d3bfb3122ec521ebf10b14259a7a",
                            "FN0d0f44108f3821dc3623af0d5b834e54",
                            "FN7cf04eb817dbf9638a4eb1976c48ac76",
                            "FN277b97b3ff86c049665bbab4518419cc",
                            "FNab7e268a311bef09fb310b447da2fee4",
                            "FN871eab7512024cd92ba7c39ad92f3ada",
                            "FNd36605fc04b334cf298fbbf7c857ad9b",
                            "FN41aab9d3bf748d3fd9de258540f5674e",
                            "FN94c3f9e7f6ac480827bb7713e260bdb9",
                            "FN9cdeb2d956b7508ec5353fe86ccb95f4",
                            "FN2ba9d371127634fe23172ab656a9845d",
                            "FN7f705e4132c956632976acf32420b842",
                            "FN97718fe78b8c6cb8bee32ae518c58960",
                            "FN5eb4e7126c1a469fc47189cf3d44c08a",
                            "FN9b2baeeef902fa533aa32498efdc205d",
                            "FN94fbb3d8771b7c0233eb6af0a72593d8",
                            "FNd1db047ad279910675dcba3fc407c5c6",
                            "FN39a90b64be8deb019280df6f0a566680"
                        ],
                        "msg_queued": 12,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 22,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 679,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:12:30.285Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "6 Hours - In-Service Program - Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C440",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "6 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 863,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T03:59:17.649Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Accredited Aides-Plus : Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Accredited Aides-Plus",
            "campTime": "2021-12-10T17:00:33.797Z",
            "CampaignName": "Q4 2021 - Chinese",
            "LangId": 30,
            "FrequencyInDays": 4,
            "CampaignID": "C583",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Q4 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 133,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 511,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T17:49:36.478Z",
            "PresetMsg": "НАПОМИНАНИЕ! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:47:49.507Z",
            "CampaignName": "Elite NCS Class 2 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 5,
            "CampaignID": "C48",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NC2- Class 2 2021 RUS",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 290,
            "DaysOfWk": " 1 2 3 4 5",
            "TimeCreated": "2021-09-08T16:10:17.872Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "From Brightstar: Please complete your inservice training ASAP to stay compliant. Sign up- https://bit.ly/GetNevvon  \nQuestions? Click https://bit.ly/NevvonHelp ",
            "CampaignName": "Brightstar Inexperienced",
            "FrequencyInDays": 9,
            "LangId": "",
            "CampaignID": "C11",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1-  Inexperienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 739,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:37:38.559Z",
            "PresetMsg": "\"URGENTE De FADMO:\nComplete TODOS en servicio lo antes posible. Toda la capacitación vence el 31/12/2021. Empezar: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:09.138Z",
            "CampaignName": "FADMO Q3 In-service 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 1,
            "CampaignID": "C156",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q3 In-Service 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 472,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T04:38:47.191Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "SI Homecare- 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C587",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 517,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T21:40:26.839Z",
            "PresetMsg": "RECORDATORIO! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:17:44.513Z",
            "CampaignName": "Sophie Q1 Infection RUS 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C275",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q1 Infection Control RUS 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 11,
                        "msg_req_sent": 11,
                        "msg_scheduled": 0,
                        "user_add_success": 11,
                        "user_add_attempts": 11,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNa0c421a88008936c501f8e81567e1bd7",
                            "FN86c4a1a77f2a5061d9e1535614b65ff1",
                            "FN4b10831e25deb0ecaf2d6217a20ce0b0",
                            "FNbb1e33fc1075375e04b0c04d256f37f2",
                            "FNc99e39c60a1ae575bbdc3beeff666491",
                            "FN7863554add107d4af53b6de9191d3c81",
                            "FN1662198b8954e42fe6e751da63f3fa11",
                            "FN952676dc3ca0da9a2cd1a0c89a107274",
                            "FNead2a2cfcf74df1e0879f71a4d2618c2",
                            "FN421203f624f45f9f95e59cb1352202f9",
                            "FN3e8610a51de2e46aac188c61926b1a68"
                        ],
                        "msg_queued": 11,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 11,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 678,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:58:32.094Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:54.678Z",
            "CampaignName": "2 Hours - In-Service Program - Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C425",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-08T19:05:44.664Z",
            "OrgName": "M&N Home Care",
            "PresetMsg": "Reminder from M&N: Login to your Nevvon account and complete your informative training to stay compliant! Click here for help: https://bit.ly/NevvonHelp",
            "campTime": "2021-12-08T17:00:25.446Z",
            "CampaignName": "M&N EVV-HHAX None",
            "FrequencyInDays": 1,
            "LangId": "null",
            "CampaignID": "C57",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T19:16:22.308Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 240
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 14,
                        "msg_req_sent": 14,
                        "msg_scheduled": 0,
                        "user_add_success": 14,
                        "user_add_attempts": 14,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN82fa938f9253559169117d55e5b7ce30",
                            "FN4cf805dbd493fb8a1edb3bf156b5204b",
                            "FNb921104399ca3e03e62499a5f025c67d",
                            "FN31cc954fdd7f67d30f54342bdfd9ae59",
                            "FNabf5926c0d296671937a2da9ff1c1401",
                            "FN6e87adf6b78a141ca2039b11a8cb9909",
                            "FNc01a5fbd3140c710fddeebbbf2815859",
                            "FN4fc059bb3bcb3f966585c6f5be7f28b1",
                            "FNb356ea0be701c00b336091b7b8333c72",
                            "FN282b59bf08d38975051a010f3af7c83f",
                            "FN76ae2f9ea3fc091bdd0dd49560f3ad51",
                            "FNfa3457208150cb11c3191c2ffa8996c3",
                            "FNccbb4d36f6ee00914aac744bdcd6c53e",
                            "FN551ecf898b42cf162951b848d32576e7"
                        ],
                        "msg_queued": 6,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 14,
                        "msg_undelivered": 3,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 682,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:19:01.725Z",
            "OrgName": "HDA Care",
            "PresetMsg": "De HDA: Su INSERVICE es debido, no está en cumplimiento. Empezar https://bit.ly/GetNevvon o aquí: webtraining.nevvon.com ¿Preguntas? https://bit.ly/NevvonHelp\"",
            "campTime": "2021-12-09T17:00:28.516Z",
            "CampaignName": "HDA Class 2 2021 Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C307",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 2- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 589,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:30:51.573Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Open Door NY Home Care Services",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Door NY In-Service EN Chinese",
            "LangId": 30,
            "FrequencyInDays": 2,
            "CampaignID": "C522",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Open Door NY- In-Service H1 Program- EN",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 182,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 607,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T05:31:30.012Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Assisting Hands Home Care - Tinton Falls",
            "campTime": "2021-12-09T17:00:32.291Z",
            "CampaignName": "Assisting Hands- Tinton Falls Q2 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 7,
            "CampaignID": "C245",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands- Tinton Falls Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 167,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 592,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T21:09:56.326Z",
            "PresetMsg": "НАПОМИНАНИЕ! Health Association of Niagara County: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Health Association of Niagara County",
            "campTime": "2021-12-08T17:00:05.918Z",
            "CampaignName": "HANCI H1 In-service 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 7,
            "CampaignID": "C119",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HANCI H1 In-Service Program 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 183,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 113,
                        "msg_req_sent": 113,
                        "msg_scheduled": 0,
                        "user_add_success": 113,
                        "user_add_attempts": 113,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNff5809eed388ce421f6bf01f5560c152",
                            "FN9efc2c0002dff61770a82463ed53801f",
                            "FNb8792d4c30b1465ea90ee2f901008344",
                            "FN49d55f0e4334a0a090bc42c3a0624c42",
                            "FN5b91ad8b5c64669470cbc247478f768b",
                            "FNf35752c718a6664c6014efc4506d74b3",
                            "FN3cfa50b35dc096f846f4c4d9f6fc2d04",
                            "FNb9414a630ca75ad620974b1daa65503b",
                            "FN09c870ff1b2a32e2ea69951515aab1e8",
                            "FN5782e91141c946e454ade5ec627dee45",
                            "FN6621cc073c1adb0d9aa9be7cd5c732e0",
                            "FNc0f9977fe0a5b037fb37897758b70617",
                            "FN31c7fc2671a9c0f1147c86ec3bc75557",
                            "FNd18ab1c1526b7c9ac5d06d1ac40a017d",
                            "FNd5843b01480b421c7c1ca91dbc0d3a79",
                            "FNf7a636a1331cfd7b37eab5ffd09b9f9f",
                            "FN665e3bbed6b27533fd974679f5029fde",
                            "FN3116227cc9a5a778c1a23b430142f1f1",
                            "FN0fdee39804d2e8deba24460f35935552",
                            "FN47e1b7f49b59a08d45bcabe322ace7fe",
                            "FN0624c7985b787f9f8cb0fb06e209827a",
                            "FNb8535bd5c8dd33cda278a009b859fd98",
                            "FN5db82029ff5b7615a6e2f3b4d9c22e79",
                            "FN6b8ed6cd779210e4750f66d7d04e49ad",
                            "FNca30b69c3f1fde0c459e39d7e272f439",
                            "FN528b53ce0391dff8c76f2d79daf66bfe",
                            "FN195d1fe4e1fdd4a92f08af5aba6895f0",
                            "FN31c70fd12cd526ddb7773c78f797e7c8",
                            "FN35a46fdbe4ab80e1079e690572df1e67",
                            "FN2ae08b6fb4a6648206a71606b76ec9e2",
                            "FN09f42c6b8c6605aad7f62fb0c3d4098e",
                            "FNc0af1783e8e6e898bead80f731666754",
                            "FN842f9f6032ced8f0850d5eca9352aa0d",
                            "FNa9dfc7e1d66005387b7148448e3cfcc1",
                            "FN34521e9e87ab424e05dcfd92221a169d",
                            "FNf2b45bb593d939238c73a862b2b0dc5c",
                            "FN7e5dd1e21d518ee816a0ea01a509c218",
                            "FN8cc55a964456cc941963d31979d24428",
                            "FN68c4f30dd4ab3840f26ee39f5a1539bd",
                            "FN6ed6a9e5d371d257dde7bb46f2790ad8",
                            "FN454395de738d24bde553fa9ffe55f0d5",
                            "FN51d5f66652247fe752342844996d33ae",
                            "FNf2d68ce309d85c07da1e36e66de40f56",
                            "FN797fa5673e6ce600f57a65b3418c134c",
                            "FNe235fc832cedb397e88932c23af3bb32",
                            "FN54d0acfe7c2cf698501a1b91fa990b3b",
                            "FN141ab5447a9b41eee332f274f1f17ef1",
                            "FN9785b8b9a4eee27af80a6e326b4c4e3e",
                            "FNea3978fae04e6bc240df7fba9b5560de",
                            "FN5e2188d190a2c7002915db6009e00a1f",
                            "FN2fa9f61884a0efac2f253531044d7887",
                            "FN92fb45ce4704eec0c981af040de3d821",
                            "FNca2b935884c38872c4ed6fd4d76ca9ad",
                            "FN2b78b1e7f1043825ed33f8b175161eef",
                            "FN2b2b586769479be856d2496a288b621f",
                            "FN4308c1931c4d180c6cbd19b14f4c0e3a",
                            "FN8295d892cdca013f464c193b351d241e",
                            "FNe2ebd4ae0775311090b6e879deb0c477",
                            "FN01d4260345969cced371cf24812a8737",
                            "FN5225d699430d4808bf7972a5445f93b1",
                            "FNe288af08a6fbce3089dc4a770a8dcd7a",
                            "FN1302c31849753f705d3b5f1e38bb8366",
                            "FNbb03deebb40bf3c8ceb62196a09530a0",
                            "FN5df64d91629a38b7ae18a3d69851d7a2",
                            "FNe5de72765f2a06e8287cd942675bab68",
                            "FNbf86b39ee5d0ace22806548b4ba4fd6e",
                            "FNd06a58ffc86bef33ba7e892b14ac2846",
                            "FN315932ac32e3ad3522cfa5d802cd8652",
                            "FN91e7cc5ad322275ad27bfaece0ad37c0",
                            "FNd16b761746d8365535e4887b92dc3252",
                            "FN0f4b1e697dce50faf75746f0d793ae6a",
                            "FNe2e40ff5b0e027bafb9a6402e84c215f",
                            "FN23cfbb2673345c7df307b3b77175e777",
                            "FNbf7f5e6cb3055a75c1a9420dcd5d42bc",
                            "FNe61a961b21902c85f3b0abc6f0ac9a10",
                            "FN193b915d1f86511f7c0171e41ba2e287",
                            "FN2ce4a5e647700160f70d4ecb071eb429",
                            "FNdfd38267cf882273619904897e3a6c53",
                            "FN13828122457eb53a3a1e5eac894fffba",
                            "FN9b408ca45523514f35d2be192671f8ac",
                            "FNc2994c846ac008a732e5f73a37b7ca4c",
                            "FN5012a784f4dd931b14a10fe632b9e543",
                            "FNd39e49ee9435ea355b803321505d0bdb",
                            "FNe5f0f4f714c23e3ceb2d2a3b6c35aef8",
                            "FNfb4cc2bc8cbc1e4c0bf8ff098b726b77",
                            "FN37598cee3301b253398e2424bab2dd80",
                            "FN7d7551eea26aad57286d2989b90b44cc",
                            "FN8ebbd7439c32a1cfa6178a9ed7d57ca8",
                            "FN9fa46720eea1daea1033492a4b1130e5",
                            "FN2a3e25ac67493cc60566d85865c61393",
                            "FNba999ef19b55a62686b92b68f5d7e0aa",
                            "FN701e31803db08023f88765000dccdf4e",
                            "FN1defd118e6a4749e5ecd9cfbdd280a24",
                            "FNc2342745dc66b9a89687f35d600d9443",
                            "FNdcabd7b8452e9cf99d297d0af413207e",
                            "FNc2ffcf9dc1ea94e6c00fe2ae430d1d4c",
                            "FNb7d9ab8a6e5efae1676956e011ea5911",
                            "FNdfabcd02bbf721840dd17568551014b0",
                            "FNde0ae1dc562c5ccac37c9899c451d501",
                            "FN767dc41dfa4e5b1a66dd18e92119489b",
                            "FN78093c934f190c6b1cbedf6e4989cc57",
                            "FNa1436734cfbc9336b012fb8c21fb2e5d",
                            "FN2b73d94985b3527194cf40e0034424ef",
                            "FN2e26be3a9753ca7dc4ccf56a38b805e0",
                            "FNa03882b5a70e1ac6a4ea1c0232072888",
                            "FNd46e92ca9bae311ec552f538c5af7e02",
                            "FN128fc2152c1df5f30862c88263230f29",
                            "FN2870be778d2e76929ab1613771aa0794",
                            "FNedf99bd5ea376c234b2d95b4bfb61a4f",
                            "FN2dedcd678702abc5848a82afeaf6a793",
                            "FN6f0d43841ab3c3cc070c0b07d905a891",
                            "FN440e60f1a882c6e558dd55b8cff1b8e6",
                            "FN9df891965d52867010629271776a65fd"
                        ],
                        "msg_queued": 112,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 113,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 963,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:13:17.257Z",
            "OrgName": "Open Arms Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Arms 2021 Q3&Q4 inservices English",
            "FrequencyInDays": 2,
            "LangId": 41,
            "CampaignID": "C442",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T22:18:05.497Z",
            "ProgramName": "2021 Q3&Q4 inservices",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 187
        },
        {
            "MaxProgress": 99,
            "ProgramID": 630,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T23:09:10.626Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:14.813Z",
            "CampaignName": "Caregiver Provider- Annual 2021 - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C536",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- Annual 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 466,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:46:45.377Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:57.345Z",
            "CampaignName": "JFS Care- Mandatory 2021 - Spanish",
            "LangId": 149,
            "FrequencyInDays": 5,
            "CampaignID": "C547",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Mandatory 2021- Spanish",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 479,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:23:57.007Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "SI Homecare- Support Staff- Medicaid Compliance 2021 - English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C600",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "SI Homecare- Support Staff- Medicaid Compliance 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 518,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T21:41:45.085Z",
            "PresetMsg": "REMINDER! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:00:46.554Z",
            "CampaignName": "Sophie Q2 Safety EN 2021 English ",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C276",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q2 Safety EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 760,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:42:26.842Z",
            "OrgName": "Best Choice Home Health Care",
            "PresetMsg": "REMINDER! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T17:00:59.856Z",
            "CampaignName": "BestChoice Sexual Harassment 2021 None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C103",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T20:48:07.352Z",
            "ProgramName": "NY -Sexual Harassment - Advanced Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 113
        },
        {
            "MaxProgress": 99,
            "ProgramID": 915,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:43:18.448Z",
            "PresetMsg": "来的温馨提示: Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:42.861Z",
            "CampaignName": "Community Care 2021 LPN/RN Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C74",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 LPN/RN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 710,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:51:57.324Z",
            "PresetMsg": "RECORDATORIO! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:16.039Z",
            "CampaignName": "Compassionate Hearts Pa In-service1 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C160",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 1",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 55,
                        "msg_req_sent": 55,
                        "msg_scheduled": 0,
                        "user_add_success": 55,
                        "user_add_attempts": 55,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNde1a4cd47b112a49790af8d654577f2e",
                            "FN1bd6b7c3b5b7b225d5926ec12d170c12",
                            "FN6de68f0cdc21548777e0cff51075f4ea",
                            "FNc1a1f825c2b2857fcd860ee7e20f23f8",
                            "FNaaaee332017c6d0ffee7a6b959e0e599",
                            "FN0d00d0767caf437defaa6dc3dd6cef12",
                            "FN33b4790d381c7ee2b97fa4459efa2d3e",
                            "FNb71ebd44c4ace9d3a46eaa67f1bb0f84",
                            "FNad79734d910f4d9f0eb4c343d398698a",
                            "FN2b269294c1384f209b10a186df76e657",
                            "FN3467a2731566d5372d1d0516644616f0",
                            "FNa4de4651d2f5de049ea929ebe90b6e4d",
                            "FN3c04b0a5bf168ffc6ff58a2deb16dd3c",
                            "FN702313ddcafdccadd91a3f421413dfe8",
                            "FNf573545770d469e359268669bbb2ccb0",
                            "FN8c956cdccd64211f6a38360e1a5708e0",
                            "FNb72e1990951b5ede45508c69d9bef264",
                            "FN834998ff22f4fc10d928958198c7beb3",
                            "FN197fcdcb79071f4fb03a94d92bdbe611",
                            "FN27a60cc91332db992e8585596a5168b0",
                            "FN828f7ac209f0ef07c8cb48d52033ea29",
                            "FN2fa0a86872f5f4df0b4e8c3300fb016d",
                            "FN5664f89419e1f2d6d4e398c764567af8",
                            "FN65345f5ff9c78186a4858adcadd2980a",
                            "FNb986fefcabc4032c87f0965c8ea28f73",
                            "FNf64c7bc1d259242b6804850df0f23c3b",
                            "FN01d5cd93b218f05c8cdfc6f869547bf9",
                            "FN68190618aed2073b34e5b42fb5072897",
                            "FN2296bb32006f7cd40cd3a936fd2565ac",
                            "FNf8d6f0b7380e5625ec8e8c2b94a53f71",
                            "FN7a92d19ac099b67d4830a57210c0ed7d",
                            "FN295eb246704b1fd17b9066b5b482908b",
                            "FN8ec8c3a0566c1f47098446406a495fd2",
                            "FNda8d44bf1293dee4268411ac5ba7f7ce",
                            "FNc6e5b044f147ab00e54b171e942baf0e",
                            "FN6b3703e82694845372668012c1745358",
                            "FNcea253785f303f7362e657bc3979001e",
                            "FN25d9e36af88ea2b978be03dbbf5b3ee0",
                            "FN0ec9404d041adf83b99abe7d792abd65",
                            "FNde42be44bc186d888a856f5a289878aa",
                            "FN0ea9af619d1162367b336ac4ed21f700",
                            "FNce5b3a74db9312e1384a415a47d4d828",
                            "FN7988a64b8364fd0d70ca59a32100dfa9",
                            "FNce7e47fadb14492381fcda4b44259dd7",
                            "FN093c8bff27073602d8aa3d0d1706792c",
                            "FN785bc3e6f161e5e6951be2ffa19cf4ff",
                            "FNd86cce8a0e15d762544292f3265079f5",
                            "FN9b84bed3a1e4fa5342362738e9f697f4",
                            "FNb86052952652aa349a82c82c413e6cce",
                            "FN705cc285eae55c8294d2eebb1d83d95e",
                            "FN0fdb8b98d2964a7de0a241cde883d7e3",
                            "FN8bab60021584dc0e2ea23bddfec7f784",
                            "FN2c57d03040255133c8eb358c3caf754c",
                            "FNb3570b79b660b3a3a6efacdf697ea28b",
                            "FN8175f1d151db71abad7f589bed81340b"
                        ],
                        "msg_queued": 55,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 55,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 791,
            "DaysOfWk": " 1 3 5",
            "TimeCreated": "2021-12-10T00:03:11.371Z",
            "OrgName": "Paramount Home Care Agency Inc.",
            "PresetMsg": "REMINDER! PCA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:02:37.976Z",
            "CampaignName": "Paramount PCA 2021 English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C339",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Paramount PCA Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 260
        },
        {
            "MaxProgress": 99,
            "ProgramID": 616,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:13:37.321Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Penn Home Health Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Penn Universal Program 3 2021 English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C558",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Penn Home Health Care- Universal Program 3 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 190,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 526,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:04:10.094Z",
            "PresetMsg": "НАПОМИНАНИЕ! Sophie's Health Care Services: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sophie's Health Care Services",
            "campTime": "2021-12-09T17:03:35.115Z",
            "CampaignName": "Sophie Q4 Docs RUS 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C294",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Sophie's Health Care Services- Q4 Documentation & reporting RUS 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 171,
            "Active": true
        },
        {
            "MaxProgress": 100,
            "ProgramID": 616,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:11:38.760Z",
            "OrgName": "Penn Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Penn Universal Program 3 2021 None",
            "FrequencyInDays": 5,
            "LangId": "null",
            "CampaignID": "C557",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-11T01:12:44.389Z",
            "ProgramName": "Penn Home Health Care- Universal Program 3 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 190
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 5,
                        "msg_req_sent": 5,
                        "msg_scheduled": 0,
                        "user_add_success": 5,
                        "user_add_attempts": 5,
                        "msg_delivered": 2,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN2715bd0810acd8c6eeb12b78c1f18a55",
                            "FN6568b5a05ba880d284e68ed8806550cb",
                            "FNf42619c35c0a55df2e6746f881ba5c41",
                            "FN24733581b62124a05efd40f51c881c10",
                            "FNce5faf011e7ece7976ac613f08e4c8fb"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 5,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 483,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:31:35.743Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 4 Spanish 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C415",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 4 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 13,
                        "msg_req_sent": 13,
                        "msg_scheduled": 0,
                        "user_add_success": 13,
                        "user_add_attempts": 13,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNb0a15d7b911b35f5eaaf97173dcc9fc8",
                            "FN6132174b2d51fdef663a435962f0b822",
                            "FN905308b44a389b48e3c771409ac5e0b6",
                            "FN4212939eda99ad20f80cf87a9bb6422d",
                            "FN1a488b07acc1d140271fbe1f42553f94",
                            "FN4f03b1a1ab7fddf273bd1ba6f4ded5a3",
                            "FNf49bbbd7437a0d98c44c433be07ea9f0",
                            "FNbb108e3ef5fc54f5d4b8e69114ce993e",
                            "FN9aa7b337042f1a9db851c0dd321aed20",
                            "FN9f2cbb452e10b8cf86498585993c3ac9",
                            "FN12695416d9327dc231bdb690b6ccbd7a",
                            "FN08a7a4354f712d00e908b24813ab0223",
                            "FN836c7b643354f2d0bff06305079d19a9"
                        ],
                        "msg_queued": 6,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 13,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 680,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:04:38.763Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:35.663Z",
            "CampaignName": "4 Hours - In-Service Program - Chinese",
            "FrequencyInDays": 3,
            "LangId": 30,
            "CampaignID": "C431",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "4 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 703,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:43:58.443Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care- Hospice",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Hospice Universal Compliance 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 4,
            "CampaignID": "C518",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Hospice Universal compliance 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 214,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 720,
            "DaysOfWk": " 1 5",
            "TimeCreated": "2021-12-09T17:13:42.872Z",
            "PresetMsg": "НАПОМИНАНИЕ! Greater Love: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Greater Love",
            "campTime": "2021-12-09T17:00:09.275Z",
            "CampaignName": "Greater Love Orientation 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 1,
            "CampaignID": "C190",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Orientation",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 226,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:27:56.292Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Sundance Home Care",
            "campTime": "2021-12-09T17:00:52.819Z",
            "CampaignName": "Sundance EVV-HHAX 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C202",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 225,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 925,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:48:35.921Z",
            "PresetMsg": "REMINDER! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:00.432Z",
            "CampaignName": "Community Care PA 2021 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C80",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA Class 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 630,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T23:10:15.896Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Caregiver Provider: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Caregiver Provider",
            "campTime": "2021-12-10T17:00:20.531Z",
            "CampaignName": "Caregiver Provider- Annual 2021 - Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C537",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Caregiver Provider- Annual 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 192,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:12:46.106Z",
            "OrgName": "Angels in Your Home",
            "PresetMsg": "НАПОМИНАНИЕ! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T17:00:09.519Z",
            "CampaignName": "Angels EVV-HHAX 2021 Russian",
            "FrequencyInDays": 2,
            "LangId": 134,
            "CampaignID": "C102",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-09T15:12:19.667Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": false,
            "OrgID": 206
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 560,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:52:40.915Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Richmond - Q2 2021 - Spanish",
            "FrequencyInDays": 7,
            "LangId": 149,
            "CampaignID": "C356",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "ProgramID": 527,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:02:32.754Z",
            "PresetMsg": "来的温馨提示: FADMO Health and Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:34.654Z",
            "CampaignName": "FADMO Q1 In-service EN 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 1,
            "CampaignID": "C124",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q1 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 703,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T22:41:01.788Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care- Hospice",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Hospice Universal Compliance 2021 - Haitian creole",
            "LangId": 56,
            "FrequencyInDays": 4,
            "CampaignID": "C524",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Hospice Universal compliance 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 214,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:37:29.445Z",
            "PresetMsg": "НАПОМИНАНИЕ! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:17.127Z",
            "CampaignName": "Community Care EVV-HHAX 2021 Russian",
            "LangId": 134,
            "FrequencyInDays": 3,
            "CampaignID": "C69",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 615,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:10:50.320Z",
            "OrgName": "Penn Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Penn Universal Program 2 2021 English",
            "FrequencyInDays": 5,
            "LangId": 41,
            "CampaignID": "C556",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-11T01:12:32.860Z",
            "ProgramName": "Penn Home Health Care- Universal Program 2 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 190
        },
        {
            "MaxProgress": 100,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 735,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:28:44.677Z",
            "OrgName": "Gurwin Homecare Agency",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Gurwin Homecare Agency: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Part 2 2021 - Haitian Creole",
            "FrequencyInDays": 4,
            "LangId": 56,
            "CampaignID": "C450",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Part 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 107
        },
        {
            "MaxProgress": 99,
            "ProgramID": 606,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T21:35:42.089Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Compassionate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Compassionate Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Compassionate Care Universal Compliance Program 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 4,
            "CampaignID": "C512",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Compassionate Care Universal Compliance Program 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 188,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 506,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T18:44:41.447Z",
            "OrgName": "Elite Home Health Care",
            "PresetMsg": "RECORDATORIO! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T17:00:42.893Z",
            "CampaignName": "Elite NCM Class 1 2021 Spanish None",
            "FrequencyInDays": 5,
            "LangId": "null",
            "CampaignID": "C53",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T18:46:02.442Z",
            "ProgramName": "NCM- Mandatories Class 1 2021 Spanish",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 77
        },
        {
            "MaxProgress": 99,
            "ProgramID": 760,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:43:59.538Z",
            "PresetMsg": "REMINDER! Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:18.441Z",
            "CampaignName": "BestChoice Sexual Harassment 2021 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C105",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NY -Sexual Harassment - Advanced Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 742,
            "DaysOfWk": " 2 5",
            "TimeCreated": "2021-12-09T15:46:30.256Z",
            "OrgName": "S&A Unified Home Care",
            "PresetMsg": "URGENT - S&A Unified Home Care:\nPlease complete your Part 2 - Inservice 2021 program ASAP & stay compliant.\nClick here: https://bit.ly/reminder-HHAX",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "S&A Part 2-Inservice 2021 English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C121",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T15:37:24.756Z",
            "ProgramName": "S&A Unified Home Care- Part 2 In-Service 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 164
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 4,
                        "msg_req_sent": 4,
                        "msg_scheduled": 0,
                        "user_add_success": 4,
                        "user_add_attempts": 4,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN41f725f47c9fd9be61848a7fb24d61ac",
                            "FN22147162c38fd64e547c259cd837c0fd",
                            "FN4b1ef4f6e5d55394abf5ce1158882028",
                            "FN75fa3f20fe20d36f7f09c614ba9fe8ed"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 481,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:24:58.437Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 2 Spanish 2021 - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C409",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 2 Spanish 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "ProgramID": 322,
            "DaysOfWk": " 1 2 4",
            "TimeCreated": "2021-08-30T17:29:10.825Z",
            "OrgName": "Customer Success Team",
            "PresetMsg": " {firstName},  {agencyName}  needs you to finish  {programName} , as you are only at  {programProgress} completion",
            "CampaignName": "TestJess01",
            "FrequencyInDays": 1,
            "LangId": "",
            "CampaignID": "C6",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-08-30T17:36:07.629Z",
            "ProgramName": "English Program 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 154
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 461,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T20:34:58.031Z",
            "OrgName": "ASSISTING HANDS BOSTON NW",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Assisting Hands Boston Q1 - 2021 - Chinese",
            "FrequencyInDays": 4,
            "LangId": 30,
            "CampaignID": "C502",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Boston Q1- 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 136
        },
        {
            "MaxProgress": 99,
            "ProgramID": 711,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T16:57:10.849Z",
            "PresetMsg": "REMINDER! Compassionate Hearts: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Compassionate Hearts Personal Care",
            "campTime": "2021-12-09T17:00:39.000Z",
            "CampaignName": "Compassionate Hearts PA In-service2 English",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C168",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PA In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 220,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 284,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:25:09.704Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 3 - Experienced Aides - Russian",
            "FrequencyInDays": 4,
            "LangId": 134,
            "CampaignID": "C484",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 3- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 1,
                        "msg_accepted": 0,
                        "msg_req_attempted": 15,
                        "msg_req_sent": 15,
                        "msg_scheduled": 0,
                        "user_add_success": 15,
                        "user_add_attempts": 15,
                        "msg_delivered": 4,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNc2dea3f2f03ca4ff3a934a67ee9b6150",
                            "FNcdb773aff28ed2bd34128a115eb73f34",
                            "FN3cc25c846a8a4c92afbdf28bd7bdbf0d",
                            "FN0c94ff89d0f1776d58469a8570c6c196",
                            "FN20fb6073b39aa177f29889d5ecfcb1b7",
                            "FN622070990767ccbeadfe55e905c90b1d",
                            "FNf534a14a49f56489a9ef88ef738333a2",
                            "FN89cb7032a48826f3c57bb0f855473177",
                            "FNb93e5c19e48b9ad4bc1c0acbaadeceff",
                            "FNf02e89c067d78ba882e9ef9aa65d0693",
                            "FN298a6086ea8a3073d2b4096e0aba7b0b",
                            "FN367a9cbd0f30b9d742592b8b50be74a7",
                            "FN791ba50ce2aed1b60e6d3124d4e87b43",
                            "FN7ed3c758fee113cd8d1a7b3b95f56597",
                            "FN2961af58aa2729038cfb519a3de792c2"
                        ],
                        "msg_queued": 5,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 15,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 681,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:18:26.172Z",
            "OrgName": "HDA Care",
            "PresetMsg": "De HDA: Su INSERVICE es debido, no está en cumplimiento. Empezar https://bit.ly/GetNevvon o aquí: webtraining.nevvon.com ¿Preguntas? https://bit.ly/NevvonHelp\"",
            "campTime": "2021-12-09T14:00:37.431Z",
            "CampaignName": "HDA Class 1 2021 Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C306",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Class 1- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 1,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 3,
                        "user_add_attempts": 4,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNce793892cdc87da575553b8540c6fa67",
                            "FNffd61f87bdf04e5e0234a5c54025ad7a",
                            "FN48ef4c73846384e9d064e828f6a36b29"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 4,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 531,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:26:59.643Z",
            "OrgName": "Bryan Skilled Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Bryan Skilled Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Bryan Skilled Home Care - Aid Mandatory 1 EN 2021 - Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C371",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T15:36:12.773Z",
            "ProgramName": "Bryan Skilled Home Care- Aide Mandatory 1 EN 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 87
        },
        {
            "MaxProgress": 99,
            "ProgramID": 505,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T18:42:41.108Z",
            "PresetMsg": "REMINDER! Elite Home Health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Elite Home Health Care",
            "campTime": "2021-12-08T17:00:26.168Z",
            "CampaignName": "Elite NCM Class 1 2021 English None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C51",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NCM- Mandatories Class 1 2021 English",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 77,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:31:47.684Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C219",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 561,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T14:57:25.128Z",
            "OrgName": "Assisting Hands Richmond",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Assisting Hands: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:19.842Z",
            "CampaignName": "Assisting Hands Richmond - Q1 2021 - Haitian Creole",
            "FrequencyInDays": 7,
            "LangId": 56,
            "CampaignID": "C360",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Assisting Hands Richmond- Q3 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 141
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T00:00:58.535Z",
            "OrgName": "Ultimate Care",
            "PresetMsg": "REMINDER! Ultimate Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:00:26.359Z",
            "CampaignName": "Ultimate EVV-HHAX 2021 Haitian Creole",
            "FrequencyInDays": 3,
            "LangId": 56,
            "CampaignID": "C337",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 215
        },
        {
            "MaxProgress": 99,
            "ProgramID": 592,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T21:07:56.010Z",
            "PresetMsg": "来的温馨提示: Health Association of Niagara County: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Health Association of Niagara County",
            "campTime": "2021-12-08T17:00:21.631Z",
            "CampaignName": "HANCI H1 In-service 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 7,
            "CampaignID": "C117",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HANCI H1 In-Service Program 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 183,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 165,
                        "msg_req_sent": 165,
                        "msg_scheduled": 0,
                        "user_add_success": 165,
                        "user_add_attempts": 165,
                        "msg_delivered": 54,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN4c1d6157ca3b0abf3dd38402c7c7149d",
                            "FNf3279a940f28125f413da2387bee12fa",
                            "FN7bb50cc11ae485aa864b8f97c38bf4a5",
                            "FNb7ad6da6bbcd6c453cac4309784165a4",
                            "FNe49bd6b1fb82996f3c5673578532eb7b",
                            "FNaf532aef74c33fad3eb7a10f8f3cb34f",
                            "FNd9a59dae151f6c0e412f474c94b69de5",
                            "FN9c32a957e094a43f250357610e218c1d",
                            "FNef2eb24282fce71568b2a5bbdce8f202",
                            "FN76af48ad1882361770f469e06f7938d0",
                            "FN489d85a14000d1d6cccdb46461bdc362",
                            "FN78ffe656a7d4aa46e1aa6db3ca81fee6",
                            "FN5d9ee940dc952350c3ed48e971a4de08",
                            "FN942bf4ac518d0350d8fba52a6fead4a2",
                            "FNce94f22058d1778de1270ee04225bd03",
                            "FNa1beae4fabcf21ff06c84bccaf630fe0",
                            "FN0d8a90de48896a73609dcca1bd914112",
                            "FN8f78f6656f70d946b53599e13fa92bf7",
                            "FN83cc5d3e6838fe5a5cc027e74a758df4",
                            "FNc86e4b1b65097239ab30adffb1dae16e",
                            "FN4422e9dd6f7f6c54f7438ccb73af5f42",
                            "FN01e52c950019e9ae9e5b28958a0580fe",
                            "FNb7ae3f79cb0f1d9bbf62d627e2a653fd",
                            "FN32c40a48bcf9582d2cf0f235ab3ea8a4",
                            "FNf87fa234ad4753b7954322283ba3263d",
                            "FNcb3ee68fed55d4e03e0c868a676f2ae0",
                            "FN11fd827f5875251d985e033a162c6fc8",
                            "FN85d198a8c413aa17f0b314829de048b9",
                            "FN42c08c32dec7cc2cf6ccd56b11a1126a",
                            "FN9069ce9a1e33a719908e3cc4089b4bc0",
                            "FN771098152778be9b6585d0145479fea1",
                            "FNcb86dd09117b7823d201cf199c6f3269",
                            "FNbd4fa32561c563a4d671cc289d2b5d04",
                            "FN4cd5005a376d84881ff4303f1b3711df",
                            "FNe2404e4adc0a19bdc02bba92c2e7756c",
                            "FN9be65490db6328d3759d071af4ae2c3c",
                            "FN71ffb9125e04520535fd6d6c57d7f2f2",
                            "FN8435608d97e119cc85ba4d37804bba98",
                            "FN590174a7d8589f19b06d16eb955c9e87",
                            "FN50bf0ce43af7b54da6fee66bce5bac15",
                            "FNad6f605333c82e3de6d8159ad90c6567",
                            "FNf987333fd01e0c69990a93e16b6f9c30",
                            "FN2e0840d6ee63d3eaa69beae8e14b6978",
                            "FN7624d63ba3204a0170e238ff14805402",
                            "FNa6abebec17581f2cf535a24b08591ef0",
                            "FNea3eff9341536af620d70a700e0e8e7f",
                            "FN80595ce1fcca54f2afdce0dc2e617f06",
                            "FNb5b9d4e8902d3a8a834478909d7d7beb",
                            "FN35dc6bf23c6046162b191044bfcc5b75",
                            "FNd6ab4b1189f9e49a2c7057a1ffb3f3ac",
                            "FN638b6a6798418c8248edab51b2aee17f",
                            "FN545ec1b438f31e949583a52810a32876",
                            "FNe59498cc85ae05896330ea8cf560817b",
                            "FNb875e354a6daf0a7258e2262547d553c",
                            "FN3eece6b65ee12ccdd2579b9dbf6466b6",
                            "FNab5458994556b3a90307378f91656174",
                            "FN5e50999625a79f2fe831a6a325a3e3c2",
                            "FN65db1b1096e5aef7dcf1e69a7d12f1ba",
                            "FNa5843a998ed83152c1f909fca7cad2b0",
                            "FN54952f5bc3b3f90b461ad5a40c7ea9de",
                            "FN55b9d0de84d10857324f3bfe277ba800",
                            "FN918dad182c2a25e37c465f7566012931",
                            "FN127b2ed39af9dcff34c61bfb3a217d99",
                            "FNb8eb981f54a802f86820500b29e5d5c8",
                            "FN06bfd2f6885a6c3f6af6635982509f7d",
                            "FN9dae25fe78d101fcfe3f5876968634e5",
                            "FN74233104ecccf64b04ca7861fe37466e",
                            "FN8921fc76ad161f7aeef8ad035cdd7805",
                            "FNf812a821c9ba5b75db54412bcd7eef15",
                            "FNc8a65714d45983a548a7737d6b957933",
                            "FNddad6a6d4d28ac9b670207cb37c52f44",
                            "FNd806eca943d2c72f6a06c45d6fd7f622",
                            "FN238cc4042e108029118ff907b47d5f5c",
                            "FN8a68c3f3b0fad25473e5e25821088541",
                            "FN869f8a6b5596761f14cb41eb096a489e",
                            "FNd6e2b2aff753a6cedb37301d2a827de1",
                            "FN21ce6cc6bdd90dee549f888023f23562",
                            "FNe9927ef11a17881ecd132bdb21dfb339",
                            "FN5ba2449104965db399b583f128ad0d2c",
                            "FN98cbb05a4793ce9d86ab1915f16b1efb",
                            "FN854a1c7b1ddaf0118c5f50be006f5f9a",
                            "FNacc0de8c1959490ae0f7a4e75e42a59e",
                            "FN41a9557ee6161e08228a8a17bb54135b",
                            "FN1fb730a05305dbe5032ad8df56fc94fb",
                            "FNdb0b86056883b95bd8555081d19a2378",
                            "FNa3efa897d1e5ad3d2cb3e84fcfab9d36",
                            "FN431c670354162cb8c87ea2b5f87bf132",
                            "FN1f73f55a17b95237c0b594cd84c0a181",
                            "FN8d747f558e6028aaeb11582fb1b0574f",
                            "FN1afa5a34424f9b8cc969dfc8f35ec93d",
                            "FN4d31cf653c69678fa2e244fbae2049e7",
                            "FNe70d39a6e96e42cce0435a1faece0e36",
                            "FN1354d598e99ddc30c9f7846ae833dde5",
                            "FN87c4f44e5e6cf77f36360f1fba382d8c",
                            "FN6d0685d16f1be5a03ae3f67a854c4111",
                            "FNd1236ccce875ab61bc119de67abc2422",
                            "FN212c80e4e93dd38dfe6435d1fa80e067",
                            "FN8a56d350e74cb40f99090d201a6d3bcd",
                            "FNae3dabb8a1b68922889bb6b9ac4b6776",
                            "FN1b6032bc184fc2552f66b774c03bfdba",
                            "FNbe87b9b07a981f90f00dda3c1eaac248",
                            "FNcdbe9cf9eba33bc5ac37266bcb6e85e0",
                            "FNfa57db545cabce0dae048726bd4503d0",
                            "FN86ef8446f35663a8b778c56dfbf2b6d9",
                            "FN17ec6a19a33346074f284079bde8e695",
                            "FNb5706cc6db5ee9e337aa5081ecc3e05d",
                            "FN52fae424cc6fac68fd3b110e8072aea4",
                            "FNbe99442fac25da0f55d36f3a093dddb4",
                            "FNc140bfe80b428c46777b5c261be35467",
                            "FNf252084a4dd0ba5d8552c4e9bb15e99e",
                            "FN1f6672b87c5906461462c9a1d2de5a2d",
                            "FN5531bcb3c0e2216cb0525c61e3d02ed7",
                            "FN56f79dc2267a6c5130b7d042e347d386",
                            "FN34397b108cd483f96279ae336162128d",
                            "FN63e54970a3c1db832236d956d4932179",
                            "FN4a0d5b399fc5b0feaf77af5a22c85e1d",
                            "FN4e8e398968150691b578bd7d3bc85786",
                            "FN3b6cac0e0a46792055d7042bf4b647d6",
                            "FNe1fd7fa77c4bae4155c900a2800af92e",
                            "FN8a1cbe9a089919968d5e12a256ee1726",
                            "FN60092e2d8ab85af07392b531659de5e4",
                            "FNb636fe66a2b16615a90f065fcf1c1f64",
                            "FN2466a37d6b1a505bea15ee38491d9274",
                            "FN2691461410081d00e9ad9aa715502101",
                            "FNed09fcbe7d6c5f4e79d6cc46943a5799",
                            "FN7ec7b7b584b0ac516c2e987caea5ae25",
                            "FN88450913a8bc129b848761d8b4842896",
                            "FNa1ef706ae431916514c26cbe04c827c3",
                            "FNa721453b2e9fc0a0022ab1225fd535d4",
                            "FNd30d61a3968e5ce4954f43e29557dd64",
                            "FN5b2a71522e2eb6b8957c187b23cbc908",
                            "FN199be7cc379f12c95c17360ff5d1e9ab",
                            "FNf1a5aa106912cd1aefef3e68ec1c9ccf",
                            "FNf6a65ef513465b843dc4a2f8793ddbca",
                            "FNc1578ccf5ed137f1f3338e16eab67b02",
                            "FN4fb4361bbf8ed458432d9848648e7b35",
                            "FNf8593981b7472d64f9c9c291705ec683",
                            "FN3723ed340c923f106840bed053ac0fde",
                            "FNa62b8c4c541ed25d215cb921e6faee68",
                            "FN1cd9bbd4ee867736d805dec577306111",
                            "FN3d2ca69199a1d115e5358b49505de96b",
                            "FN9cf8a18b07b499f13e1567c08a1db994",
                            "FNc716da3345703e2b5829b3376d645647",
                            "FN9ffa5ace20b571ee77ef9bfceb9ea827",
                            "FN92ba95af80762382aaf080b63e7abe43",
                            "FNa86fe82687350f36f34a0c79120f4f0c",
                            "FN3fd5e7ae86b6d820505e02c474eb0780",
                            "FN1c9dca21f14e345912ec736c13d1f6ee",
                            "FNf874e9890fd82b389d271c88f25dbed6",
                            "FN1502b048fdb594348fb6b40ba11099fa",
                            "FN11e57b6f127a95db1a66e194b16d9e51",
                            "FN9f4504a662d81daf76d62cda21b8f311",
                            "FNcc7b6333f3e6581e4e9b6eda4bc8a274",
                            "FN19ea869ab2749238d280e55ed5c66bbe",
                            "FNb770b61ef3e8b9f5e3e132018e71c70c",
                            "FNa6fdf8ffa81a6ccf6acd27c4319c159a",
                            "FNf135624741f8e15f24796195cde4700a",
                            "FN4227971ef666b29ba54618d59477dd90",
                            "FNcb4b2f0fd25309d204c842c3f6d452f7",
                            "FN8e8c5827d41932794652548710c8e37c",
                            "FN4076bc69099dc769955913458890ea36",
                            "FNa5275156c0c00fd549310df6ca9ed463",
                            "FN8f9add5d05f3626de229b00ccc8177a1",
                            "FN91ea0e757d8a971aa8e51f170aeaf2d8",
                            "FN4f483d1cadac438c02c05c14fc95b823"
                        ],
                        "msg_queued": 96,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 4,
                        "numOfUsers": 165,
                        "msg_undelivered": 10,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 742,
            "DaysOfWk": " 2 5",
            "TimeCreated": "2021-12-10T15:41:53.014Z",
            "OrgName": "S&A Unified Home Care",
            "PresetMsg": "СРОЧНО - S&A Unified Home Care:\nПожалуйста, завершите свою Часть 2 - Программа Inservice 2021 как можно скорее.\nНажмите здесь: https://bit.ly/reminder-HHAX",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "S&A Inservice 2021 part 2 - Russian",
            "FrequencyInDays": 1,
            "LangId": 134,
            "CampaignID": "C363",
            "MinProgress": 0,
            "ModifiedBy": "freya@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T15:42:15.437Z",
            "ProgramName": "S&A Unified Home Care- Part 2 In-Service 2021",
            "CreatedBy": "freya@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 164
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 602,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:10:28.560Z",
            "OrgName": "Open Arms Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Open Arms 2021 In-Service EN Spanish",
            "FrequencyInDays": 2,
            "LangId": 149,
            "CampaignID": "C439",
            "MinProgress": 0,
            "ModifiedBy": "madhav@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T22:03:54.681Z",
            "ProgramName": "Open Arms H1- In-Services 2021 EN",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 187
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 0,
                        "msg_req_sent": 0,
                        "msg_scheduled": 0,
                        "user_add_success": 0,
                        "user_add_attempts": 0,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 0,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 290,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:39:21.989Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "BrightStar Care Program 1 - Inexperienced Aides - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C497",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "BrightStar Care Program 1-  Inexperienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 688,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:56:52.997Z",
            "PresetMsg": "REMINDER! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:18.224Z",
            "CampaignName": "Angels 2021 In-service2 None",
            "LangId": "null",
            "FrequencyInDays": 2,
            "CampaignID": "C89",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 797,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T17:32:19.495Z",
            "PresetMsg": "来的温馨提示: Classic Homecare: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Classic Home Care",
            "campTime": "2021-12-09T17:00:43.267Z",
            "CampaignName": "Classic In-service 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C207",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 239,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 7,
                        "msg_req_sent": 7,
                        "msg_scheduled": 0,
                        "user_add_success": 7,
                        "user_add_attempts": 7,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNbe93d3b2177f822680880a57ff55825d",
                            "FN5870dc93d0f2246c159237f7cc8bd9ec",
                            "FN645309d54fc4e6b72c00e9edcb121d05",
                            "FNc016bd3ce7f886acf97ad647b2fe6b92",
                            "FNf6e12ad091799220d5e22b710bbd6228",
                            "FN43434d296b5beb072361b61f81b7917c",
                            "FN04c60b2fdbd1aa99a31fb96e04068b5e"
                        ],
                        "msg_queued": 2,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 5,
                        "numOfUsers": 7,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 315,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:55:42.907Z",
            "OrgName": "Progressive Home Health Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Progressive Home health Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Progressive Home Health Care 2020- Class 1 - Spanish - Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C467",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Progressive Home Health Care 2020- Class 1- Spanish",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 144
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:41:40.504Z",
            "PresetMsg": "RECORDATORIO! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:20.802Z",
            "CampaignName": "Community Care EVV-HHAX 2021 Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C72",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 73,
                        "msg_req_sent": 73,
                        "msg_scheduled": 0,
                        "user_add_success": 73,
                        "user_add_attempts": 73,
                        "msg_delivered": 63,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN4bfe2c680fe99519f16defeaac3cb923",
                            "FNb1de6838df65ed91aae9269b8b72ebd6",
                            "FN11340b3e8b63aac8126847dde59a51eb",
                            "FN64a97003e706e8a44d275058e56b8430",
                            "FNb792ecf3355d94aaf6a4de43434b4183",
                            "FN69131d9ecc9931fbf58bb8dfe578df58",
                            "FN7a2e4f6fe99d565c7eacb6dddb584d0b",
                            "FN4e2af12808f021aca1cae27c68f7bbcc",
                            "FNd183f66ac883d95fe8b54decba2168bd",
                            "FN21a8e131a98a150c7f3238cf3478a051",
                            "FN23877d4533cc61940ae03cc429a4e1fd",
                            "FN7703447786a828df70abbd164c8c239b",
                            "FN970db98059bc1d1d4939b869bca7219c",
                            "FNf15d0a233666c15808510f0cfc6519b2",
                            "FNa07bd151e68b70e82e29a6f3597c80aa",
                            "FNa514beff8100c7b6802c647df3a7df5e",
                            "FN8d75ee55636e4b19122b8515ee191695",
                            "FNc11e5eb68d4c35b221bfa48c6e9da9ae",
                            "FN16c9ea121bf28bdabd1fa5412c4fc28a",
                            "FN091423501e1b2d8ea5eddd870424c9b2",
                            "FN6f532ed319f911d62ea0befff5d5cf2a",
                            "FN2f60dc0e9b328168d3bad00814c24099",
                            "FNd385298ac1f66548f56abbf82511607f",
                            "FN4ef56e6d5dc8b716d2fd72a3af16a549",
                            "FNa0efa2adbf726fcd69c14c722319863d",
                            "FN143066ac8e3552bbdcba71e7bd2c3f39",
                            "FN36f6a8b7bd874bfa61fc9bcac184cfad",
                            "FN50ca5a685db457ba67be6834ba2d61f0",
                            "FNc0fba20c05a0f1703d97dfe71f3b1d7b",
                            "FN549fa5494e720b4e73a3d4f52efc66a5",
                            "FNc8f9bf1ef0bf1926a6a1ffd5237401d0",
                            "FNf3e25eeb4d6d9030ebc6e3b563c9754a",
                            "FNa86bd3670ab7d5f28d74a01c1bac135d",
                            "FN5fb80aef43f0190f06215dc5681538d8",
                            "FNddd9480ba2104a7ab84e4bacd74ef9b1",
                            "FN70424375da3aadf5dd24f43a085b9a9d",
                            "FNb8b0880b9818e2286240ebaceeac60eb",
                            "FNa550d8c5fcdec4417082673e60dc5e1c",
                            "FN9ebc13f8ed57b7cd92dbccadd58e73c5",
                            "FN6a1a1f456eeb186d56b2fc0f92e9c36d",
                            "FNc5e0b7ca32b38684f8acce5e2c515e54",
                            "FN365344772b18ca58c8eec95ffaaa37be",
                            "FNefec9a8d8a027d8b25c6734334eb1288",
                            "FN876db6b388a6e4171ff8eca687270332",
                            "FNf91e5f7106f5ad3776793284d54db373",
                            "FN5ad5913f584dcc867fac00e967f4cd3e",
                            "FN286029cb30a2d51e85154ec0f660630e",
                            "FN7ad726dd0c17e76f5c67d2e5f1de7d58",
                            "FN8e05e5b843692113d8208dc917afad8f",
                            "FN7588e699d418421ea6166b0547c4e345",
                            "FNb3d0741e9367942e96e0905ba6d88509",
                            "FN16ea8fa806d5287039aba7afa42723d6",
                            "FN50410838cf68796127e6732566ba377f",
                            "FN65ca155a5876f1838b02d2f55938e70c",
                            "FNa2891637619aecfd51dc7f1932fe6ea7",
                            "FN10cd21da02b9102dc6107e3ff751f330",
                            "FN2a4c0ee022be29b9c1f011894f3cda6a",
                            "FN0591751f7dc24723fbb61597b8910986",
                            "FNb3871b21f96deae75e26d7cb930ebec7",
                            "FN13830699528ff3f5cc62832ae011a083",
                            "FNff9a2f76aa9e758b76ff296001c3d0d2",
                            "FNccd5fed7283041e62570aa6a011ffdd7",
                            "FNfe5164061046d4e6280f9359c9def9f7",
                            "FN5bc5b3932be5f02dcdbca25228150cfb",
                            "FNe63631158cc111d4d4500e1e3751d246",
                            "FN238d841ebc1756150a81546628f3aaa6",
                            "FN1d528f69e98361035032608e326dd71a",
                            "FN397f6fbc0ddd7e68ec67de3f16e63dae",
                            "FN5d7e41a19c653ae75496b9e91cfff400",
                            "FNfdf62f5ad7306f7c1881dec8579e15c8",
                            "FN09ebb35269e1b5e2f291ecff8788ece2",
                            "FN7c0f2e207e5063c59fbf391d140816e9",
                            "FNcd02e2c73378fb836591ab1e7664cfa4"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 6,
                        "numOfUsers": 73,
                        "msg_undelivered": 4,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 652,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T15:57:53.964Z",
            "OrgName": "A-Plus Care",
            "PresetMsg": "RECORDATORIO! A-Plus Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-VIV",
            "campTime": "2021-12-10T15:57:14.519Z",
            "CampaignName": "A-Plus In-service Part 1 ES 2021 Spanish",
            "FrequencyInDays": 5,
            "LangId": 149,
            "CampaignID": "C382",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "In-Service Part 1 ES",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 196
        },
        {
            "MaxProgress": 99,
            "ProgramID": 688,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:58:58.454Z",
            "PresetMsg": "REMINDER! Angels in Your Home: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Angels in Your Home",
            "campTime": "2021-12-08T17:00:26.040Z",
            "CampaignName": "Angels 2021 In-service2 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 2,
            "CampaignID": "C92",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 In-Service Part 2",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 206,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 16,
                        "msg_req_sent": 16,
                        "msg_scheduled": 0,
                        "user_add_success": 16,
                        "user_add_attempts": 16,
                        "msg_delivered": 6,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN6ec8acd0687fe69dad48fff2084fe72d",
                            "FNf2478b4e943f9bb5d7610ef3e7cc890c",
                            "FN18bd8b7c18528accf19425fe598e2c61",
                            "FNa00f37d50974c04af4fc1aaaed733174",
                            "FNdb29bbd9076c39c7926783e39409aa08",
                            "FN33cd3d9e804300cafa63853ceabdea9f",
                            "FNa79d3006255aeacc8d1bb02a7f4a4229",
                            "FN953b002adef9993df762257627933fcd",
                            "FNe3b31f3c7a92ef456808c658cb2bb85d",
                            "FNf2c573b838937c44282dde713f7f4b79",
                            "FNa41cc3e13d2890a35c9bf7e43e474b5c",
                            "FN843d9c3353e9c29210ce5d119f3c0e79",
                            "FN466b0839ab4c6f8bf2499fd12c79017d",
                            "FN16ba5881c40a90003e947e769b57fe4f",
                            "FN8523072b3f69abc43b44978ffce10783",
                            "FN7341bd3473cea207e3deed5d70cdaa6e"
                        ],
                        "msg_queued": 7,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 16,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 283,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T19:06:56.152Z",
            "OrgName": "BrightStar Care of the North Hills",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: BrightStar Care of the North Hills: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:46.741Z",
            "CampaignName": "BrightStar Care Program 2 - Experienced Aides - English",
            "FrequencyInDays": 4,
            "LangId": 41,
            "CampaignID": "C475",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-10T19:07:14.668Z",
            "ProgramName": "BrightStar Care Program 2- Experienced Aides",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 145
        },
        {
            "MaxProgress": 99,
            "ProgramID": 915,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:46:29.656Z",
            "PresetMsg": "RECORDATORIO! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:43.995Z",
            "CampaignName": "Community Care 2021 LPN/RN Spanish",
            "LangId": 149,
            "FrequencyInDays": 3,
            "CampaignID": "C77",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "2021 LPN/RN",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 875,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T20:54:47.938Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Alternate Staffing: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-12-09T17:00:00.000Z",
            "CampaignName": "PCA 2021 - Haitian Creole ",
            "LangId": 56,
            "FrequencyInDays": 3,
            "CampaignID": "C231",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 592,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T21:08:56.323Z",
            "PresetMsg": "REMINDER! Health Association of Niagara County: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Health Association of Niagara County",
            "campTime": "2021-12-08T17:00:04.811Z",
            "CampaignName": "HANCI H1 In-service 2021 Haitian Creole",
            "LangId": 56,
            "FrequencyInDays": 7,
            "CampaignID": "C118",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HANCI H1 In-Service Program 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 183,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 3,
                        "msg_req_sent": 3,
                        "msg_scheduled": 0,
                        "user_add_success": 3,
                        "user_add_attempts": 3,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN34200cbe9014bb70ee4618e7f66f5029",
                            "FN9731a30cf3a101ae3ebbeee10f95ec4c",
                            "FN2d653594afd03cb23065c1aecebbbe97"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 3,
                        "numOfUsers": 3,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 409,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:11:39.062Z",
            "OrgName": "Recco Home Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: Recco Home Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Recco - Class 2 2021 - Spanish",
            "FrequencyInDays": 4,
            "LangId": 149,
            "CampaignID": "C398",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Recco- Class 2 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 115
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 1,
                        "msg_req_sent": 1,
                        "msg_scheduled": 0,
                        "user_add_success": 1,
                        "user_add_attempts": 1,
                        "msg_delivered": 0,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNc6207710079f4b68e9aa73bca19056a5"
                        ],
                        "msg_queued": 1,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 1,
                        "msg_undelivered": 0,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 791,
            "DaysOfWk": " 1 3 5",
            "TimeCreated": "2021-12-10T00:05:15.749Z",
            "OrgName": "Paramount Home Care Agency Inc.",
            "PresetMsg": "RECORDATORIO! PCA: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-09T17:04:45.986Z",
            "CampaignName": "Paramount PCA 2021 Spanish",
            "FrequencyInDays": 1,
            "LangId": 149,
            "CampaignID": "C343",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Paramount PCA Program",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 260
        },
        {
            "MaxProgress": 99,
            "ProgramID": 874,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-11-26T11:35:16.121Z",
            "PresetMsg": "From Alternate Staffing\nComplete your inservice ASAP. Download the mobile app ---> https://bit.ly/GetNevvon  \nQuestions? Click https://bit.ly/NevvonHelp ",
            "OrgName": "Alternate Staffing",
            "campTime": "2021-11-26T16:30:35.948Z",
            "CampaignName": "Alternate English HHA",
            "LangId": 41,
            "FrequencyInDays": 3,
            "CampaignID": "C22",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HHA 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 246,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 725,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T05:28:15.458Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SI Homecare Agency: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-NEV",
            "OrgName": "SI Homecare Agency",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV Training - None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C601",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV Training",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 124,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 30,
                        "msg_req_sent": 30,
                        "msg_scheduled": 0,
                        "user_add_success": 30,
                        "user_add_attempts": 30,
                        "msg_delivered": 28,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN88546a630a13aac8807d8bc68ff4a302",
                            "FNeca1f3b347108598917f289d1ff8294e",
                            "FNca598a5cb85937dededebf37d049f25d",
                            "FN32a6a3f589dc1363f03cf81add932860",
                            "FN92c5b39e868a8835ac409b17f725d823",
                            "FNa9920bbf6f04aa2233f2e5bd5b4376b9",
                            "FN103fea5c5710038602613ee79153d1d0",
                            "FN057824d86ba9f16cd2ecd909e3007e53",
                            "FN87769aca58b13d17184d09b48b1e814f",
                            "FN89f5dcb2ead0912b12ca7a13d5bcd680",
                            "FN6279338882f6c264d3260feb8a268569",
                            "FN0aa49491276b3eccd49951ddce5e1c7a",
                            "FN14c3a1172f619169b5810f91eece833e",
                            "FN494836a99620734b93ac5cddf20e10f3",
                            "FNc51b4c4337e09cef4085cd973a88d904",
                            "FN02decd9b9a04f0f063ed74ef9efb5c7b",
                            "FN361f81594ab05864e533f5c0c4fe83ef",
                            "FN6bbf2497c85749807666d8bf420e7536",
                            "FN1dcb64642f75962643036d301e4e7257",
                            "FN9e38a126bf248ea302f653425720396c",
                            "FN613c38f71246971e6a091a3845cf9296",
                            "FN38089bb9b6967b6ddbc7c3c299bf6837",
                            "FN01662b7b929742ecf4192a9ee90dc571",
                            "FN18560f48a12acf16727cf46023085b90",
                            "FNe57c0ca42e546afc11f10904293512af",
                            "FN0a3ed08e9c96da18b7e445e3135ec2e3",
                            "FNa0f0640b836090f0c5bade68b037686a",
                            "FN8f6c6f421210d2fd23e045a0108d70ce",
                            "FN840159c8ced6e4c33cd7b3dd8aef871d",
                            "FN58b992f0759c83ab361ccdc65e628b46"
                        ],
                        "msg_queued": 0,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 0,
                        "numOfUsers": 30,
                        "msg_undelivered": 2,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 684,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-09T23:15:26.318Z",
            "OrgName": "HDA Care",
            "PresetMsg": "From HDA: Your INSERVICE is due - you are out of compliance. Login https://bit.ly/GetNevvon or here: webtraining.nevvon.com. Questions?https://bit.ly/NevvonHelp",
            "campTime": "2021-12-09T17:00:20.754Z",
            "CampaignName": "HDA PCA 2021 English",
            "FrequencyInDays": 1,
            "LangId": 41,
            "CampaignID": "C302",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "PCA- 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 146
        },
        {
            "MaxProgress": 99,
            "ProgramID": 760,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T20:46:47.601Z",
            "PresetMsg": "来的温馨提示: Best Choice: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Best Choice Home Health Care",
            "campTime": "2021-12-08T17:00:05.372Z",
            "CampaignName": "BestChoice Sexual Harassment 2021 Chinese",
            "LangId": 30,
            "FrequencyInDays": 3,
            "CampaignID": "C109",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "NY -Sexual Harassment - Advanced Training 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 113,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T19:40:16.265Z",
            "PresetMsg": "REMINDER! Community Care: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Community Care Home Health Services",
            "campTime": "2021-12-08T17:00:32.330Z",
            "CampaignName": "Community Care EVV-HHAX 2021 None",
            "LangId": "null",
            "FrequencyInDays": 3,
            "CampaignID": "C71",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 227,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 592,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T21:07:13.048Z",
            "PresetMsg": "REMINDER! Health Association of Niagara County: Complete your mandatory training ASAP. Click here: https://bit.ly/4LangHHAX",
            "OrgName": "Health Association of Niagara County",
            "campTime": "2021-12-08T17:00:35.027Z",
            "CampaignName": "HANCI H1 In-service 2021 English",
            "LangId": 41,
            "FrequencyInDays": 7,
            "CampaignID": "C116",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "HANCI H1 In-Service Program 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 183,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 614,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T01:08:31.613Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示： AGENCY NAME: Complete your mandatory training ASAP.Click here: https://bit.ly/reminder-VIV",
            "OrgName": "Penn Home Health Care",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "Penn Universal Program 1 2021 English",
            "LangId": 41,
            "FrequencyInDays": 5,
            "CampaignID": "C554",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Penn Home Health Care- Universal Program 1 2021",
            "CreatedBy": "madhav@nevvon.com",
            "Deleted": false,
            "OrgID": 190,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 198,
                        "msg_req_sent": 198,
                        "msg_scheduled": 0,
                        "user_add_success": 198,
                        "user_add_attempts": 198,
                        "msg_delivered": 45,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FNa7639af672f2d0acae0860eca5ea9c32",
                            "FN55462a134f5f4d7adf9de83f0886ae55",
                            "FN91ff6f56f5dc8106a3933dc1f1b43eae",
                            "FNfac55eec8b6697a853c3391a181fdbe4",
                            "FN58cc2283893e55f858fb9bc8ada064bc",
                            "FN212f3c4e1d5d3f82d6c5fdac97bc2103",
                            "FN9472bef730e4949ab6353a246e92e2ca",
                            "FN1ae380e8d62dd2762dcb457819d67091",
                            "FNbcd4178b8d32aaf921a901c23a4dc654",
                            "FN2103913b7c6c8802e637af945068b13f",
                            "FNbe5f2c212e6b95d5c1fd6c6eaf043b52",
                            "FN56032ec3381e7d1f62e2dc62283a48ff",
                            "FN23ff6eacc88445f781fd3f85eafb4abf",
                            "FNb444022ef2c266ae3857b7fbf397a413",
                            "FNaeebfad49081098de5fdd3e1d135447a",
                            "FNb0d9071739210f6ba02ac0fd58252012",
                            "FNde5b5598b468f7332f0a106281eab4d4",
                            "FN082616badfba32fe299ca2f4e0d5308d",
                            "FNee7cc6189000682288b262842b9f9238",
                            "FN737179f6cae9075fff0acbb24e485023",
                            "FNf125109c41d540a28f707036054e16f5",
                            "FN9d272a381c79c6e363fde821d5a6ec6c",
                            "FN64ac8ae1476f4a4baa2f23b4b2d8ee4d",
                            "FN0bfa09f1717757e3e739d7921057b4ea",
                            "FN7502e0fb7d4de0da56694569dd85bc95",
                            "FN2b0f149a4efc6142586e659b6652c0d7",
                            "FNaa99f5227babbbfa0c966e31e0cb9adb",
                            "FN49794f0c590b8c2f1b541cbcad68f37d",
                            "FNb5d165e9217b966ca9557c316fa7e1b6",
                            "FNcd30f5c98891ff241ea1af60538f5481",
                            "FNf8656fd2d065f70e3ad31f733984cfbc",
                            "FN72765d4d12f8fb6e9ccd82412b40fdea",
                            "FN8d6e0d7800f14a38d4714fdf80428e26",
                            "FN2485dba151545ae29de25cb3c3d15790",
                            "FN5ebc07b203ac71c39a4c4a843cfd70a8",
                            "FNa31e135f35f1abcd5ec41347acda99ee",
                            "FN16f0b32af2e21e4e4118e5c7463bac17",
                            "FN570113ae1441cebaf4abce184f889f48",
                            "FNdb355c0f638d7a0c2b6a49cf42aa7e64",
                            "FN02346165c6fcdfd0286308af5bce1954",
                            "FN07ba9a4a8d2d0978da34ba93f59c3c1e",
                            "FN813e0082a82dac6eecf69e495ef53ba7",
                            "FNebcdeda4ef82b2d65605bec2c0d87a2e",
                            "FN12ff9cddacef5e0d93cec242deef08fe",
                            "FNd8129d63a96096f036604deeaf2cd8ab",
                            "FNe44007738c150607b6aa39fcb466b9b5",
                            "FN19a827e6382762c323ba9d5ea0bfb169",
                            "FN505e5294861e6c56262fd1e812cf265c",
                            "FN622ecc09dc1c742fc98bd53426a31518",
                            "FN7982f452a3f9041b6b99a89858ce8639",
                            "FN5f4e109a387f6b58c921408ef786d4c0",
                            "FN101663d4a05c43f644687a5603b1a4dd",
                            "FNc4cd14190438c6b713d5949ae2b1ea45",
                            "FN2160a10ec6858c261f6ed94d37e4e47c",
                            "FN4196478cdbb3a36c4a04a1fea6fd247c",
                            "FNf4333d04545c637e52056f13b79c3ca6",
                            "FN91f763adc2dd5bad0e8d936574ad2ee2",
                            "FN2972ce1ecd87e50cdc3436f7033b121a",
                            "FN375d9ae998e211045f13ade77326110c",
                            "FN13887f802f8b82c4773a69175ddad8ae",
                            "FN9b225ccc11b4ab45cf5631481d37300e",
                            "FNc30ee5f0f14ea439665b9b2cebd49ad2",
                            "FN62085027db86458d579158c81b4b7aef",
                            "FN04b6661b7164f9d9903c521c8277530e",
                            "FNc9c72d0a703947909bd319fb479bc1d9",
                            "FNac265246d950aafaffdb4299a5d5b1c9",
                            "FNd1f91e41d3f007e412272f8eec43256e",
                            "FN8ff1744efc76ace049b682f743601292",
                            "FN115946fac4b65dabda1288982f2b4e61",
                            "FN2fed77816e5b3e21f4813fafb7a109e6",
                            "FNdf511c580bada3807653d4747e824130",
                            "FNcc1bbf622fb5c3c8e7668651252657de",
                            "FNe08da3b4736d6221e995365dedf47a71",
                            "FN960ed12d7f3680d0e5944996baa6e0e9",
                            "FNcde3f9c0b2f18b6949e31d37155f9d42",
                            "FN2c413635c1630e2673dada0a29128ee6",
                            "FNe2354c9e555f766ca9053bd6265cad85",
                            "FNc32a631406eefac19905d4eb47df2083",
                            "FNbd6f6f3d87b2a4258152f6e6a2913a66",
                            "FN463d6752b4df7edfbd78c3392d201abb",
                            "FNa715706cab7c38c964e18ade94c1c92a",
                            "FN6cb312a5b825c5245bde0d80e90c0715",
                            "FNe3be77a0efe41daa7cd7dd2cffea85f0",
                            "FNb2e7f8db70d2fc7f7f13bc81105a9ff6",
                            "FN5da5b777539483de47f616cbe508ae17",
                            "FNa54e215a50d6bb9bc918c7735ff525bc",
                            "FNe96d83de012a4014161dbcb5b952b4fb",
                            "FN5d7e1a13f22c727cb0473adab4380f62",
                            "FNf8968702c2b15f3b086bd9640658c0e9",
                            "FN66a2aeaabebe1dbd4deab3db997b51d9",
                            "FN7170e15dc82f3836b1a42b1dd1c6eff6",
                            "FN42d53575b9ffd8976331e1ae0627ff88",
                            "FNa7a50c0f7462dc68ee65d47b4588d1b6",
                            "FNc928aab197d6c9fcf80ba0aeb9214f3f",
                            "FN65654439acea6dbfc5e31c8a4a365521",
                            "FNfa820191635e4f345ec2751e2fe02caa",
                            "FNeeb08e53fd8ee8058108c8952d03039d",
                            "FN650ea8c10745eeaac7b260523ee05137",
                            "FN39b707ee5a1940bbb46609d817f14a11",
                            "FN0864c50d97e25bc4acb891a8a4e6e4d3",
                            "FN6c890ef8ba3d1cd6b89016cc187cb5cf",
                            "FN298ababefb50a3ab1888e5edb9162d0b",
                            "FNe5f185d9c015c9280a0ded7b8f3a1081",
                            "FN6663fbec8332dca16d2a82848ac9697b",
                            "FNc9f4e9ee235c27cd293d15ac078e9e9e",
                            "FNcfdf67f538d6e4e8956a6e8b4c93f7b7",
                            "FNd471b101b9a2c4d5088148a43088e263",
                            "FN009f7047a76823826451240a142ff87c",
                            "FN66e4ed638b4409a51fbe4e81a8b6650a",
                            "FNa533321db2e401759f6ce38eff81b431",
                            "FN6ef4e344aec7df278b87ebbee8d9fb31",
                            "FN3bb5f268fb4c0773c0b60778a943164c",
                            "FN1f2221eb9a0575a709f169309ac197ae",
                            "FN1a14fea7dbeeacbca6dc20d80f2effa0",
                            "FN96b32d5e8cef06cead5f02f31d7db4f1",
                            "FN90979c1ce40c3407604bcf7b7f989a8f",
                            "FNc7e3dc8a961fdbffba302f13bdb98486",
                            "FN1a69b4c4336932f9076f227eacd94c2f",
                            "FNa6a9db22d8a062a28cfe9f8f079494ee",
                            "FNe74f2e9f9751108d226ab43ac7cbc374",
                            "FN5f602304d0edb76a7fda44c0385c1a3d",
                            "FN24f6cd95066c381203f24439b965f181",
                            "FNcd83f7ba41ecaf2a0add44376494303c",
                            "FNee34f4822a36000a6fe16cc9c5a418e2",
                            "FNacec7fdd0a4b0ca8f12e48abe92e7c90",
                            "FNe02249ae526ca5936629741f951b77da",
                            "FN7fb038cfb0dfb439cecfaa5ba7fde814",
                            "FNa5cd76b6b7f35fc643f41a262a0c1948",
                            "FN4ceb84b74fb07cc19e81a315aacfca0b",
                            "FN094b5d439735f63a700be163516b809b",
                            "FN70a5ecb63370edeef661d535ae948b69",
                            "FN6d614ee7b719074f3d0cdd5905c2ad5a",
                            "FN2d84048b3bc63cf5fd4c3a1de3e7ba4a",
                            "FNbde8bec6e2cee08185c457a18e18d8f1",
                            "FNd854a0d3cad20cdbd2b5276783fe18be",
                            "FN8b821fb2fc492e283bcb7a527e4eee7b",
                            "FN570e476ec94c5320da24448ceb6dda89",
                            "FN5b885a05c95b9af428cb01973d3b26fb",
                            "FN97ffd56c06e824bdd5cdebaa1d173ca1",
                            "FN2de79b3e4e3b1432d5dce5e99ccc8d9b",
                            "FN4204f2e4586e1ae2fc9ff820af70e6e2",
                            "FNafb0eeb7c0298fa13e36a088ca370b1c",
                            "FN52ee30866d75a65611dff19ae190bbf8",
                            "FN4871939a57532ec4c03003b08a7def05",
                            "FNa24a97cd0243425d44c20f53090c8a01",
                            "FNc4f253de7b19e017317b8c23dd43bb37",
                            "FN55f0d59b6997bfb317b01b75482f8dfb",
                            "FN4c4b314027b6697abb20edaf518c8146",
                            "FN1bcafa2a1590a8d8436e2b6a39fd9ee7",
                            "FNb2471dabba26cf39d6bde68dba9c7454",
                            "FNefec29111f86e97abf49e368582e3b91",
                            "FN7cc05dc517de19e948b8f21ea6c0eaf9",
                            "FNbd3f54dc206ad3300f43c2ea654b2c77",
                            "FNb4fa62845002796149fa0c7bb4f03cc1",
                            "FNc4b4f03dd11972809b68b88270180b3f",
                            "FN4ccb488372dd2e5691352d50fd217377",
                            "FNf199e04e12607d7f9f4ad3487b39efa4",
                            "FNfd049962195863931d2d80640d089d61",
                            "FN5488c81df25151501a6fbd58e80ab79b",
                            "FN5a3f946e1450fc8962083475568701e6",
                            "FN00b441b84b0e9ce69c320ef4d64c7e7c",
                            "FN794d2ad7cda1872914ba9d81619dd446",
                            "FN2fc006c184d1105e2431bb9dad578423",
                            "FN35fe71e95b295570803bb4c8bfece060",
                            "FNfb38cd4cae18f7f127273f14d7702bc0",
                            "FNfecaa008f3b6d4300e583feee07be8b7",
                            "FNc2c6c58ec9c88553c859af66546141cf",
                            "FN718be2dd180363c710886d1624a8144e",
                            "FN024095a1da507b4669d20882e3661059",
                            "FNd24cd869d5c32cc99e376f0667f2c479",
                            "FNe1763702d73f292548eaef71e87080ae",
                            "FN639a8e37209015991f5b57d4d8f44dd6",
                            "FNe1934cf6a744c60e06e84955055e0f42",
                            "FN7bf7655c521d8815674cd93fe02401d3",
                            "FN84374ceccf9af191aa1046f0c58f964a",
                            "FN27f58c70978daf955a25cc1cce9b61b1",
                            "FN633caf58aa3bd60eca2914045d30646e",
                            "FNb386a2ec718b7cb524df317b717aa194",
                            "FNbe6d41ce7f81179dd0729ea9f39e7d44",
                            "FNd2aba9bf5708c02a95426cdfc014428b",
                            "FNba61a11563ec90d88907d3ac1e31099a",
                            "FN65d6c6b71c95053eb44a4fd1e39a28e0",
                            "FN8f70148408c7608638d30e23e6e5915c",
                            "FNa2171a4b4d7b63b93ea0e370f561877c",
                            "FN92079b35d9f2764afcc4d5d94ef6b4b9",
                            "FNa9aa1a0942c89e0a9f1d709bfe1cd243",
                            "FNa0d12d8aeb893ea9523549a3ed103ba5",
                            "FN8ca42eb7443954f88baf86a451a4100c",
                            "FNfb46e086a968e94ef0fe40bd0d5b414e",
                            "FNfc0f8654b597b3d29971548352074c91",
                            "FN2ff4574fa8effc693f213f4e14185876",
                            "FN1bdbfddf3a812582b8513adf1113cc12",
                            "FNb0cc3f2dbbf57fef5667aea278683478",
                            "FNb7947b87cf67e7067582044488689ad6",
                            "FN3a1cfd6dfc839dcd9a79af6c31ab6774",
                            "FNe49eafdfd307af2211e849c22e003068",
                            "FNd6d5d3c27835a84f8b3bd04b61a9dfce",
                            "FNd2b73fc2da6c86b1d9ec5d3c9b4a7f79"
                        ],
                        "msg_queued": 138,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 13,
                        "numOfUsers": 198,
                        "msg_undelivered": 1,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T17:42:34.839Z",
            "OrgName": "SelfHelp Community Services",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: SelfHelp Community Services: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "EVV - HHAX - Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C422",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 242
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 5",
            "TimeCreated": "2021-09-08T15:06:19.334Z",
            "OrgName": "Community Care Home Health Services",
            "PresetMsg": "Please complete your mandatory training! Open the HHAX app, click the Nevvon banner (see https://bit.ly/NevvonHHAX). Questions? click https://bit.ly/NevvonHelp ",
            "CampaignName": "Community EVV",
            "FrequencyInDays": 2,
            "LangId": "",
            "CampaignID": "C8",
            "MinProgress": 0,
            "ModifiedBy": "jess@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-09-13T20:29:32.648Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 227
        },
        {
            "MaxProgress": 99,
            "Stats": [
                {
                    "date": "11.12.2021",
                    "details": {
                        "user_query_fail": 0,
                        "user_add_fail": 0,
                        "msg_sending": 0,
                        "msg_accepted": 0,
                        "msg_req_attempted": 15,
                        "msg_req_sent": 15,
                        "msg_scheduled": 0,
                        "user_add_success": 15,
                        "user_add_attempts": 15,
                        "msg_delivered": 3,
                        "user_update_fail": 0,
                        "msgFlowSidArr": [
                            "FN0c5eb19f25b38caef029e3d0f406c54f",
                            "FNb869229fed9980a57e15a950f53df2df",
                            "FN7cfc8b2651ec5e2ee88e12efe8c11ce0",
                            "FN5407ab45084026fd6bae9d3c61f4f35b",
                            "FN82b32a2a0ad3a8a93fb3743513e8ee24",
                            "FNc0ed3a459aa203f8a08515b091c9eb3a",
                            "FN04e0c8f22184d9232e5a1e678c1c7c09",
                            "FNf71b20f7c0845888310e821082995e2c",
                            "FN54882539d305137ba2f8e03b49f34086",
                            "FN79865fd856d415ce79a942c2907c082a",
                            "FN0558e82f46639f2c14a77cd3dd51acb1",
                            "FN89458946e95f554abc6d7f6fa20f6f13",
                            "FN4fc9c8d91a64e8cc84be6a6e720e7e21",
                            "FNa4025df4f3869925541bfd34116ebaa1",
                            "FN5bb9ce3259377a80d9313633e18ec848"
                        ],
                        "msg_queued": 7,
                        "user_skipped": 0,
                        "user_update_attempts": 0,
                        "user_update_success": 0,
                        "msg_sent": 2,
                        "numOfUsers": 15,
                        "msg_undelivered": 3,
                        "msg_failed": 0,
                        "msg_req_failed": 0
                    },
                    "code": "CAMPAIGN_RUN_SUCCESS"
                }
            ],
            "ProgramID": 679,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-10T18:08:23.752Z",
            "OrgName": "Pella Care",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: PELLA CARE: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "campTime": "2021-12-10T17:00:00.000Z",
            "CampaignName": "6 Hours - In-Service Program - None",
            "FrequencyInDays": 3,
            "LangId": "null",
            "CampaignID": "C435",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "6 Hours - In-Service Program",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 200
        },
        {
            "MaxProgress": 99,
            "ProgramID": 624,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:18:05.473Z",
            "PresetMsg": "\"URGENT From FADMO:\nComplete ALL In-Service ASAP. All training is due by 12/31/2021. Get started: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:07.582Z",
            "CampaignName": "FADMO Q2 In-service EN 2021 English",
            "LangId": 41,
            "FrequencyInDays": 1,
            "CampaignID": "C134",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service EN 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 671,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-08T16:27:41.130Z",
            "OrgName": "Friends and Family Home Care Services",
            "PresetMsg": "RECORDATORIO! Friends and Family: Complete your mandatory training ASAP.Click here: https://bit.ly/4LangHHAX",
            "campTime": "2021-12-08T21:00:18.892Z",
            "CampaignName": "Friends and Family EVV-HHAX 2021 Spanish",
            "FrequencyInDays": 3,
            "LangId": 149,
            "CampaignID": "C38",
            "MinProgress": 0,
            "ModifiedBy": "jeffrey@nevvon.com",
            "CampaignUrl": "",
            "TimeUpdated": "2021-12-08T16:52:26.658Z",
            "ProgramName": "EVV- HHAX",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "Active": true,
            "OrgID": 229
        },
        {
            "MaxProgress": 99,
            "ProgramID": 625,
            "DaysOfWk": " 1",
            "TimeCreated": "2021-12-09T16:26:01.796Z",
            "PresetMsg": "\"URGENT From FADMO:\nComplete ALL In-Service ASAP. All training is due by 12/31/2021. Get started: https://bit.ly/reminder-HHAX\"",
            "OrgName": "FADMO Health and Home Care Agency",
            "campTime": "2021-12-09T17:00:14.624Z",
            "CampaignName": "FADMO Q2 In-service ES 2021 English",
            "LangId": 41,
            "FrequencyInDays": 1,
            "CampaignID": "C142",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "Fadmo Q2 In-Service ES 2021",
            "CreatedBy": "jeffrey@nevvon.com",
            "Deleted": false,
            "OrgID": 172,
            "Active": true
        },
        {
            "MaxProgress": 99,
            "ProgramID": 463,
            "DaysOfWk": " 0 1 2 3 4 5 6",
            "TimeCreated": "2021-12-11T00:34:01.311Z",
            "PresetMsg": "REMINDER! RECORDATORIO! НАПОМИНАНИЕ! 来的温馨提示: JFS Care: Complete your mandatory training ASAP. Click here: https://bit.ly/reminder-NEV",
            "OrgName": "JFS Care",
            "campTime": "2021-12-10T22:30:47.747Z",
            "CampaignName": "JFS Care- Mandatory 2021 - None",
            "LangId": "null",
            "FrequencyInDays": 5,
            "CampaignID": "C539",
            "MinProgress": 0,
            "ModifiedBy": null,
            "CampaignUrl": "",
            "TimeUpdated": null,
            "ProgramName": "JFS Care- Mandatory 2021",
            "CreatedBy": "jess@nevvon.com",
            "Deleted": false,
            "OrgID": 109,
            "Active": true
        }
    ],
    "eventObj": {
        "resource": "/campaigns",
        "path": "/campaigns",
        "httpMethod": "GET",
        "headers": {
            "Accept": "*/*",
            "Accept-Encoding": "gzip, deflate, br",
            "Host": "hp349afqk1.execute-api.us-east-2.amazonaws.com",
            "Postman-Token": "a43b92aa-276e-49e9-a719-f4c7ab5c1bf6",
            "User-Agent": "PostmanRuntime/7.28.4",
            "X-Amzn-Trace-Id": "Root=1-61b5a6cf-3a935cbf34ef53a00cde47dc",
            "x-api-key": "shqT8xQKJy379wY5vMp5r9GBb4lxqJ9u1w0wovtZ",
            "X-Forwarded-For": "117.217.182.75",
            "X-Forwarded-Port": "443",
            "X-Forwarded-Proto": "https"
        },
        "multiValueHeaders": {
            "Accept": [
                "*/*"
            ],
            "Accept-Encoding": [
                "gzip, deflate, br"
            ],
            "Host": [
                "hp349afqk1.execute-api.us-east-2.amazonaws.com"
            ],
            "Postman-Token": [
                "a43b92aa-276e-49e9-a719-f4c7ab5c1bf6"
            ],
            "User-Agent": [
                "PostmanRuntime/7.28.4"
            ],
            "X-Amzn-Trace-Id": [
                "Root=1-61b5a6cf-3a935cbf34ef53a00cde47dc"
            ],
            "x-api-key": [
                "shqT8xQKJy379wY5vMp5r9GBb4lxqJ9u1w0wovtZ"
            ],
            "X-Forwarded-For": [
                "117.217.182.75"
            ],
            "X-Forwarded-Port": [
                "443"
            ],
            "X-Forwarded-Proto": [
                "https"
            ]
        },
        "queryStringParameters": null,
        "multiValueQueryStringParameters": null,
        "pathParameters": null,
        "stageVariables": null,
        "requestContext": {
            "resourceId": "508yu1",
            "resourcePath": "/campaigns",
            "httpMethod": "GET",
            "extendedRequestId": "KOcAZFSyCYcFa3A=",
            "requestTime": "12/Dec/2021:07:37:51 +0000",
            "path": "/prod/campaigns",
            "accountId": "832032001584",
            "protocol": "HTTP/1.1",
            "stage": "prod",
            "domainPrefix": "hp349afqk1",
            "requestTimeEpoch": 1639294671144,
            "requestId": "43d26ead-cc52-4f1b-9516-1b4b5b7f0a7e",
            "identity": {
                "cognitoIdentityPoolId": null,
                "cognitoIdentityId": null,
                "apiKey": "shqT8xQKJy379wY5vMp5r9GBb4lxqJ9u1w0wovtZ",
                "principalOrgId": null,
                "cognitoAuthenticationType": null,
                "userArn": null,
                "apiKeyId": "gjzvng1qed",
                "userAgent": "PostmanRuntime/7.28.4",
                "accountId": null,
                "caller": null,
                "sourceIp": "117.217.182.75",
                "accessKey": null,
                "cognitoAuthenticationProvider": null,
                "user": null
            },
            "domainName": "hp349afqk1.execute-api.us-east-2.amazonaws.com",
            "apiId": "hp349afqk1"
        },
        "body": null,
        "isBase64Encoded": false
    }
}

let mockCampaigns = getAllCampaignsResponse.campaigns;
const transformedCampaigns = mockCampaigns.map(({ 
    CampaignID, OrgID, OrgName, ProgramID, ProgramName, CampaignName, Deleted, Active, DaysOfWk, 
    FrequencyInDays, PresetMsg, CampaignUrl, MinProgress, MaxProgress, campTime, LangId, CreatedBy, 
    TimeCreated, ModifiedBy, TimeUpdated, Stats, Programs, MultiMsg, MultiLangId }) => ({
    CampaignID: CampaignID,
    OrgID: OrgID,
    OrgName: OrgName,
    ProgramID: ProgramID,
    ProgramName: ProgramName,
    CampaignName: CampaignName,
    Deleted: Deleted,
    Active: Active,
    DaysOfWk: DaysOfWk,
    FrequencyInDays: FrequencyInDays,
    MultiMsg: MultiMsg,
    MultiLangId: MultiLangId,
    PresetMsg: PresetMsg,
    CampaignUrl: CampaignUrl,
    MinProgress: MinProgress,
    MaxProgress: MaxProgress,
    Time: campTime,
    LangId: LangId,
    CreatedBy: CreatedBy,
    TimeCreated: TimeCreated,
    ModifiedBy: ModifiedBy,
    TimeUpdated: TimeUpdated,
    Stats: Stats,
    Programs: Programs
}))

export let dummyCampaigns = transformedCampaigns

// let mockCampaigns = getAllCampaignsResponse.campaigns;
// const transformedCampaigns = mockCampaigns.map(({ 
//     CampaignID, OrgID, OrgName, ProgramID, ProgramName, CampaignName, Deleted, Active, DaysOfWk, 
//     FrequencyInDays, PresetMsg, CampaignUrl, MinProgress, MaxProgress, campTime, LangId, CreatedBy, TimeCreated, ModifiedBy, TimeUpdated, Stats, Programs }) => ({
//     CampaignID: CampaignID,
//     OrgID: OrgID,
//     OrgName: OrgName,
//     ProgramID: ProgramID,
//     ProgramName: ProgramName,
//     CampaignName: CampaignName,
//     Deleted: Deleted,
//     Active: Active,
//     DaysOfWk: DaysOfWk,
//     FrequencyInDays: FrequencyInDays,
//     PresetMsg: PresetMsg,
//     CampaignUrl: CampaignUrl,
//     MinProgress: MinProgress,
//     MaxProgress: MaxProgress,
//     Time: campTime,
//     LangId: LangId,
//     CreatedBy: CreatedBy,
//     TimeCreated: TimeCreated,
//     ModifiedBy: ModifiedBy,
//     TimeUpdated: TimeUpdated,
//     Stats: Stats,
//     Programs: Programs
// }))

// export let dummyCampaigns = transformedCampaigns