import logo from './logo.svg';
import './App.css';
import React, {Component, useState, useEffect, useRef} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//import CampaignList from './components/ListAllCampaigns';
import AllCampaigns from './components/ListAllCampaigns';
import CustomTabs from './components/CustomTabs'
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
// import AuthStateApp from './components/auth/AuthStateApp';
import ExecutionLogs from './components/ExecutionLogs';
import LoginComponent from './components/Login';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faLaptopHouse, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ProgressSpinner } from 'primereact/progressspinner';
import useToken from './useAuthToken';
import axios from "axios";



library.add(faEdit,faTrash);
const config = require("./config.json");

function App(){
  const isMounted = useRef(false);
  const {token, setToken} = useToken();
  const [signedInStatus, setSignedInStatus] = useState(undefined);
  const DEBUG_MODE = false;
  // const token = getToken();
  // const [token, setToken] = useState();

  function handleSignedInStatusChange(newStatus) {
    setSignedInStatus(newStatus);
  }

  const autoSignIn = async () => {
    const url = config.authApi.invokeUrl;
    let axiosConfig = {
      method: "get",
      url: url+`/login`, 
      withCredentials: true, 
      headers: {
        "x-api-key": config.api.apiKey,  
      },
    };

    await axios(axiosConfig)
    .then(response => {
      console.log(response);
      if(response.data.msg === 'SESSION_EXIST') {
        let currentUser = response.data.queryResult.Item.userId;
        let userToken = {
          token: 'REAL_TOKEN',
          user: currentUser,
        }    
        localStorage.setItem(
          "userToken", JSON.stringify(userToken)
        );
        setSignedInStatus(response.data.msg);
      }
      else if(response.data.msg === 'NO_SESSION_EXIST') {
        try{
          // let tokenString = localStorage.getItem("userToken");
          localStorage.removeItem("userToken");
          setSignedInStatus(response.data.msg);
          
        }
        catch {
          console.log("oops")
          setSignedInStatus(response.data.msg);
        }
      }
      else {
        // SOMETHING REALLY BAD COULD HAVE HAPPENED
        // TODO: SHOW SOME ERROR MESSAGE NOTIFICATION
        console.log("Something unexpected occured.");
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  useEffect(() => {
    isMounted.current = true;
    autoSignIn();
    // // console.log(window.location.href);
  }, []);


  // while the isSignedIn state is undefined, show loding symbol or something.

    if(signedInStatus === undefined && !DEBUG_MODE) {
      return (
        <>
          <div className="App">
            <ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="4" fill="#FFFFFF"/>
            <br/>
            Loading...
          </div>
        </>
      ) 
    }
    else if(signedInStatus === 'NO_SESSION_EXIST' && !DEBUG_MODE) {
      // SHOW THE USUAL LOGIN FORM
      return (
        <>
          <div className="App">
            <LoginComponent setToken={setToken} onStatusChange={handleSignedInStatusChange}/>
          </div>
        </>
      );
    }
    else if(signedInStatus === 'SESSION_EXIST' || DEBUG_MODE) {
      return (
        // <AuthStateApp/>
        <div className="App">
          <Router >
            <div>
              {/* <Navbar authProps={authProps}/> */}
              <Navbar onStatusChange={handleSignedInStatusChange}/>
              {/* <AmplifyGreetings username={user.username}/> */}
              <Switch>
                {/* <Route exact path="/" render={(props)=> <LandingPage {...props} auth={authProps}/>} /> */}
                <Route exact path="/" component={LandingPage}/>
                {/* <Route exact path="/campaigns" component={CampaignList} /> */}
                <Route exact path="/campaigns" component={AllCampaigns} />
                <Route exact path="/execution-logs" component={ExecutionLogs} />
                <Route exact path="/add" component={LandingPage} />
                <Route exact path="/login" component={LandingPage} />
              </Switch>
              <Footer/>
            </div>  
          </Router>
        </div>
      );
    }
}



export default App;
