import React, { Component, Fragment, useState } from "react";
import {Button, Container} from 'react-bootstrap';
import axios from "axios";
import ReactTable, {useTable} from 'react-table';
const Spacer = require('react-spacer');
const config = require("../config.json");

// const accountSid = config.twilio.TWILIO_ACCOUNT_SID;
// const authToken = config.twilio.TWILIO_AUTH_TOKEN;
// const client = require('twilio')(accountSid, authToken);

export default class LandingPage extends Component {

    state = {
        somethingFromTwilio: null
    }
    componentDidMount() {
    }

    render() {
        return(
           <>   
                <Container fluid>

                    <h1 className="display-3 py-5">Available services</h1>

                    <Button variant="outline-info" className="my-5" href="/campaigns">
                        Campaign Dashboard
                    </Button>   
                    <Spacer/>
                    {/* <Button variant="outline-info" className="my-5" href="/execution-logs">
                        Execution logs (Twilio)
                    </Button > */}
                </Container>
                
           </> 
        )
    }

}