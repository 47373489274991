import React, { Component, Fragment, useState } from "react";
import {
  Container,
  Jumbotron,
} from "react-bootstrap";
import TableComponent from './CRUDCampaignTable';
import "./indexClone.css";

export default function AllCampaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsLength, setCampaignsLength] = useState(0);
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorDesc, setErrorDesc] = useState("");

  // state = {
  //   campaigns: [],
  //   campaignsLength: 0,
  //   errorOccured: false,
  //   errorDesc: "",
  // };

  return (
    <Fragment>
      <Container fluid>
        {/* <Jumbotron>
          <h1>Campaign dashboard</h1>
        </Jumbotron> */}
        <br/>
        <TableComponent campaignData={campaigns} />
      </Container>
    </Fragment>
  );
}

// export default class AllCampaigns extends Component {
//   state = {
//     campaigns: [],
//     campaignsLength: 0,
//     errorOccured: false,
//     errorDesc: "",
//   };

//   render() {
//     return (
//       <Fragment>
//         <Container fluid>
//           {/* <Jumbotron>
//             <h1>Campaign dashboard</h1>
//           </Jumbotron> */}
//           <br/>
//           <TableComponent campaignData={this.state.campaigns} />
//         </Container>
//       </Fragment>
//     );
//   }
// }
