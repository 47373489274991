// just some template objects used in campaign table ui

export let emptyCampaign = {
  CampaignID: null,
  Org: {
    OrgID: "",
    OrgName: "",
  },
  Program: {
    ProgramID: "none",
    ProgramName: "none",
  },
  CampaignName: "",
  Active: true,
  DaysOfWk: {
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thurs: false,
    fri: false,
    sat: false,
  },
  FrequencyInDays: 0,
  MultiMsg: {},
  PresetMsg: "",
  CampaignUrl: "",
  Deleted: false,
  MinProgress: 0,
  MaxProgress: 100,
  Programs: null,
  MultiLangId: [],
  LangId: 0,
  Time: null,
  DaysBefore: "",
  Signup: 1,
};

export const columns = [
  { field: "CampaignID", header: "ID" },
  { field: "OrgID", header: "Org ID" },
  { field: "OrgName", header: "Org name" },
  { field: "ProgramID", header: "Program ID" },
  { field: "ProgramName", header: "Program" },
  { field: "CampaignName", header: "Campaign name" },
  { field: "MultiLangId", header: "Lang ID" },
  { field: "Active", header: "Active" },
  { field: "DaysOfWk", header: "Scheduled days" },
  { field: "Time", header: "Time" },
  { field: "FrequencyInDays", header: "Messaging freq." },
  //      { field: "PresetMsg", header: "Template msg" },
  // { field: "MinProgress", header: "MinProgress"},
  // { field: "MaxProgress", header: "MaxProgress"},
  { field: "MaxProgress", header: "Progress range" },
  // { field: "CampaignUrl", header: "Url" },
  // { field: "Deleted", header: "Deleted" },
  { field: "DaysBefore", header: "Days Before" },
  { field: "Signup", header: "Signed Up"},
];

export const msgFrequencyDays = [
  { value: 1, itemText: "1 day" },
  { value: 2, itemText: "2 days" },
  { value: 3, itemText: "3 days" },
  { value: 4, itemText: "4 days" },
  { value: 5, itemText: "5 days" },
  { value: 6, itemText: "6 days" },
  { value: 7, itemText: "7 days" },
  { value: 8, itemText: "8 days" },
  { value: 9, itemText: "9 days" },
  { value: 10, itemText: "10 days" },
  { value: 11, itemText: "11 days" },
  { value: 12, itemText: "12 days" },
  { value: 13, itemText: "13 days" },
  { value: 14, itemText: "14 days" },
];

export const msgSignup = [
	{ value: 1, itemText: "All"},
	{ value: 2, itemText: "Signed Up"},
	{ value: 3, itemText: "Not Signed Up"},
];

//Haitian Creole changed to Creole
export const msgLangIds = [
  { value: "null", itemText: "None" },
  { value: 30, itemText: "Mandarin" },
  { value: 41, itemText: "English" },
  { value: 56, itemText: "Creole" },
  { value: 86, itemText: "Korean" },
  { value: 127, itemText: "Polish" },
  { value: 134, itemText: "Russian" },
  { value: 149, itemText: "Spanish" },
  { value: 185, itemText: "Cantonese" },
  { value: 7, itemText: "Arabic" },
];

export const templateMsgDetailOptions = [
  { title: "User name (full)", templetePlaceholder: " {fullName} " },
  { title: "First name", templetePlaceholder: " {firstName} " },
  { title: "Last name", templetePlaceholder: " {lastName} " },
  { title: "Course progress", templetePlaceholder: " {programProgress} " },
  // {title: "General progress", templetePlaceholder: " {generalProgress} "},
  { title: "Campaign name", templetePlaceholder: " {campaignName} " },
  { title: "User email", templetePlaceholder: " {userEmail} " },
  { title: "Agency name", templetePlaceholder: " {agencyName} " },
  // { title: "Program name", templetePlaceholder: " {programName} " },
  { title: "Store link", templetePlaceholder: " {storeLink} " },
  { title: "Web portal", templetePlaceholder: " {webPortalLink} " },
];
