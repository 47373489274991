import { useEffect, useState } from "react";
import axios from "axios";
const config = require("../config.json");

export function renderRowExpanded(findIndexById, campaigns, StatsTabPanel) {
  return data => {

    const DEBUG_MODE = config.DEBUG_MODE;

    if (DEBUG_MODE) {
      let specificCampaignIndex = findIndexById(data.CampaignID, campaigns);
      var specificCampaign = campaigns[specificCampaignIndex];
      let createdTime = new Date(specificCampaign.TimeCreated).toString();
      let updatedTime = new Date(specificCampaign.TimeUpdated).toString();
      console.log("Internal stats");
      console.log(specificCampaign.Stats);
      let hasStats = specificCampaign.Stats;
      let statProps = {
        stats: hasStats
      };
      return (
        <div>
          <h3 className="text-center"><b>{specificCampaign.CampaignID}:</b> Campaign {specificCampaign.CampaignName} </h3>
          <div className="p-d-flex p-jc-between">
            <div className="p-mt-6 p-mx-4">
              <h5>Created by: <b>{specificCampaign.CreatedBy}</b></h5>
              <h5>Created on: <b>{createdTime}</b></h5>
              <h5>Last modified by: <b>{specificCampaign.ModifiedBy === null ? "Nothing to see here." : specificCampaign.ModifiedBy}</b></h5>
              <h5>Last time updated: <b>{specificCampaign.TimeUpdated === null ? "Nothing to see here" : updatedTime}</b></h5>
            </div>
            <div className="p-grid p-mx-auto">
              {hasStats ? <div>
                <div className="p-col-fixed" style={{ width: '66vw' }}>
                  <h5 className="text-center">Campaign history</h5>
                  <StatsTabPanel {...statProps} />
                </div>
              </div> : <h5 className="p-text-bold p-mt-6">No Stats recorded</h5>}
            </div>
          </div>
        </div>
      );

    } else {

      const [data, setData] = useState({});
      const [stats, setStats] = useState({});
      const [statsAvailable, setStatsAvailable] = useState({});
      const config = require("../config.json");

      useEffect(() => {
        if (!data) {
          getData();
        }
      }, [])

      const getData = async () => {
        var axiosConfig = {
          method: "get",
          url: `${config.api.invokeUrl}/campaigns/${data.CampaignID}`,
          withCredentials: true,
          headers: {
            "x-api-key": config.api.apiKey,
          },
        };

        await axios(axiosConfig)
          .then((response) => {
            let resObj = JSON.parse(JSON.stringify(response));
            const campaignResponse = resObj.data;

            let createdTime = new Date(campaignResponse.TimeCreated).toString();
            let updatedTime = new Date(campaignResponse.TimeUpdated).toString();
            setStatsAvailable(campaignResponse.Stats);
            setStats({ stats: campaignResponse.Stats });


            // return (
            //   <div>
            //     <h3 className="text-center"><b>{specificCampaign.CampaignID}:</b> Campaign {specificCampaign.CampaignName} </h3>
            //     <div className="p-d-flex p-jc-between">
            //       <div className="p-mt-6 p-mx-4">
            //         <h5>Created by: <b>{specificCampaign.CreatedBy}</b></h5>
            //         <h5>Created on: <b>{createdTime}</b></h5>
            //         <h5>Last modified by: <b>{specificCampaign.ModifiedBy === null ? "Nothing to see here." : specificCampaign.ModifiedBy}</b></h5>
            //         <h5>Last time updated: <b>{specificCampaign.TimeUpdated === null ? "Nothing to see here" : updatedTime}</b></h5>
            //       </div>
            //       <div className="p-grid p-mx-auto">
            //         {hasStats ? <div>
            //           <div className="p-col-fixed" style={{ width: '66vw' }}>
            //             <h5 className="text-center">Campaign history</h5>
            //             <StatsTabPanel {...statProps} />
            //           </div>
            //         </div> : <h5 className="p-text-bold p-mt-6">No Stats recorded</h5>}
            //       </div>
            //     </div>
            //   </div>
            // );


            setData(campaignResponse);
            // console.log(campaigns);
          })
          .catch((error) => {
            console.log(error);
            // setNothingMsg("An error occurred.");
            // setErrorMsg(`${error.name}: ${error.message}\n Verbose: \n${JSON.stringify(error)}`);
            // setErrorOccured(true);
          });
      }

      return (
        <div>
          <h3 className="text-center"><b>{data.CampaignID}:</b> Campaign {data.CampaignName} </h3>
          <div className="p-d-flex p-jc-between">
            <div className="p-mt-6 p-mx-4">
              <h5>Created by: <b>{data.CreatedBy}</b></h5>
              <h5>Created on: <b>{data.TimeCreated}</b></h5>
              <h5>Last modified by: <b>{specificCampaign.ModifiedBy === null ? "Nothing to see here." : specificCampaign.ModifiedBy}</b></h5>
              <h5>Last time updated: <b>{specificCampaign.TimeUpdated === null ? "Nothing to see here" : data.TimeUpdated}</b></h5>
            </div>
            <div className="p-grid p-mx-auto">
              {statsAvailable ? <div>
                <div className="p-col-fixed" style={{ width: '66vw' }}>
                  <h5 className="text-center">Campaign history</h5>
                  <StatsTabPanel {...stats} />
                </div>
              </div> : <h5 className="p-text-bold p-mt-6">No Stats recorded</h5>}
            </div>
          </div>
        </div>
      );
    }
  };
}

