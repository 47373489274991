import React, { Component, Fragment, useState } from "react";
import {Button, Container, Table, Modal} from 'react-bootstrap';
import axios from "axios";
import {CopyBlock, atomOneLight, CodeBlock, Code} from 'react-code-blocks';
import ReactJson from 'react-json-view'
import "./indexClone.css";

export default class ExecutionLogs extends Component {

    state = {
        logs: null,
        errMsg: null,
        isRetrieved: false,
        logCount: 0,
        logExecutionContext: null,
        didRetrieveExecutionContext: false,
        isDetailedMode: false,
    }

    getExecutionContext = async (contextLink) => {
        
        console.log(contextLink);

        var data = JSON.stringify({
            "executionContextUrl": contextLink
          });
          
        var config = {
            method: 'post',
            url: 'http://localhost:4000/logs',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
        
        axios(config)
        .then(response => {
            console.log(response.data);
            this.setState({
                logExecutionContext: response.data,
                didRetrieveExecutionContext: true
            });
        })
        .catch(error => {
            console.log(error);
            this.setState({

            })
        });
    }

    getLogs = async () => {
        let axiosConfig =  {
                method: 'get',
                url: `http://localhost:4000/get-flow-logs`
            };

        await axios(axiosConfig)
        .then(response => {
            this.setState({
                logs: response.data,
                logCount: response.data.retrievedResponse.count,
                isRetrieved:true
            })
        })
        .catch(err => {
            this.setState({
                errMsg: err,
                isRetrieved: true
            })
        })
    }


    generateCodeBlock = () => {

        let responseStr = `retrieved`

        return (
            <>
            <ReactJson src={this.state.logExecutionContext.retrievedResponse} />
            <CodeBlock
            text={"dsad"}
            language="json"
            showLineNumbers={true}
            theme={atomOneLight}
            />
            </>
            
            
        )
    }

    showExecutionLogDetails = () => {

        const closeDetails = () => {
            this.setState({
                isDetailedMode: false,
                didRetrieveExecutionContext: false
            });
        }
        let obj = {
            "url" : 3213,
            "dsad": 123
        }
        let objekt = `"${obj}"`;

        return (
            <>
                <Modal
                    show={this.state.isDetailedMode}
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="my-modal"
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.didRetrieveExecutionContext ? (`Execution details: ${this.state.logExecutionContext.retrievedResponse.execution_sid}`) : "Loading execution details..." }
                        </Modal.Title>
                        <Button onClick={closeDetails} variant="outline-info">
                            Close
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                        {this.state.didRetrieveExecutionContext ? (
                            <p className="h5">{
                                (this.state.logExecutionContext.retrievedResponse.context.widgets.function_3 === undefined) ? (
                                    <>
                                        <p className="h5">Run did not succesfully complete</p>
                                        <br/>
                                        <p className="h4">Details: </p>
                                    </>
                                )
                                 : 
                                (
                                    <>
                                        <p className="h5">Run completed and messages sent out</p>
                                        <br/>
                                        <p className="h4">Details: </p>
                                    </>
                                )
                            }</p>
                        ) : (
                            <br/>
                        )  
                        }
                        {(this.state.didRetrieveExecutionContext 
                        && !(this.state.logExecutionContext.retrievedResponse.context.widgets.check_user_db === undefined) 
                        && (this.state.logExecutionContext.retrievedResponse.context.widgets.function_3 === undefined)
                        && (this.state.logExecutionContext.retrievedResponse.context.widgets.check_user_db.body === "does not exist")) ? (
                            <p className="h5">{
                               "The appropriate campaign table to add the users was not created or found."
                            }</p>
                        ) : (
                            <br/>
                        )  
                        }
                        

                        <p>{this.state.didRetrieveExecutionContext ? (
                            <>
                                <ReactJson 
                                src={this.state.logExecutionContext.retrievedResponse}
                                collapseStringsAfterLength={20} />
                            </>
                             
                        ) : (
                            <p>Loading</p>
                        )
                        }</p>
                        </>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={closeDetails} variant="outline-info">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )

    }

    componentDidMount = () => {
        this.getLogs();
    }

    dateConverter = (dateObj) => {
        let convertedDate = new Date(dateObj);
        return convertedDate.toString();
    }

    render() {

        return (
            <>
            <Container fluid>
                <this.showExecutionLogDetails/>
                {this.state.isRetrieved ? (
                    <>
                        <p className="display-5 mt-3 pt-4">{this.state.logs.title}</p>
                        <Table striped bordered hover responsive className="mt-3">
                            <thead>
                                <tr className="subtitle is-6">
                                    <th>Number</th>
                                    <th>Execution ID</th>
                                    <th>Status</th>
                                    <th>Date created</th>
                                    <th>Date updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.logs.retrievedResponse.setOfLogs && this.state.logs.retrievedResponse.setOfLogs.length > 0 ? (
                                    this.state.logs.retrievedResponse.setOfLogs.map((execution, index) => (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>
                                                    <a href="#" className="link-primary" role="button" onClick={
                                                        () => { 
                                                            this.getExecutionContext(execution.executionContext);
                                                            this.setState({
                                                                isDetailedMode: true
                                                            });
                                                        }
                                                    }>{execution.sid}</a>
                                                </td>
                                                <td>{execution.status}</td>
                                                <td>
                                                {
                                                    this.dateConverter(execution.dateCreated)
                                                }
                                                </td>
                                                <td>{this.dateConverter(execution.dateUpdated)}</td>
                                            </tr>
                                    ))
                                ) : (
                                    <>
                                        <tr className="subtitle is-3">
                                            <td colSpan="4">No previous runs found 🤔</td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </>

                ):(
                    <>
                        <p className="display-3">Loading</p>
                    </> 
                )}
            </Container>
            </>
        )

    }

}

